import React from "react";
import { TouchableOpacity, Image } from "react-native";

import closeSvg from "../../assets/svg/close.svg";
//styles
import styles from "./styles";

const CloseButton = (props) => {
  const { style, ...rest } = props;
  return (
    <TouchableOpacity style={[styles.buttonStyle, style]} {...rest}>
      <Image source={closeSvg} style={styles.image} />
    </TouchableOpacity>
  );
};

export default CloseButton;
