import { StyleSheet } from "react-native";
import { $background } from "../../../../generalStyles";
import { PixelRatio } from "react-native";

export default StyleSheet.create({
  container: {
    position: "sticky",
    top: 0,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  col: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cell: {
    width: "47%",
    marginBottom: 24,
  },
  formWrapper: {
    padding: 16,
  },
  field: {
    marginBottom: 16,
  },
  submitButton: {
    marginTop: 32,
  },
  header: {
    position: "relative",
    zIndex: 2,
    paddingHorizontal: 15,
    paddingTop: 20,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 2,
  },
  headerDescription: {
    fontSize: 12,
    color: "#748582",
  },
  fromWrapper: {
    padding: 16,
    backgroundColor: $background,
  },
  coverButton: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
    zIndex: 10,
  },
  textAreaWrapper: {
    marginVertical: 16,
  },
  textArea: {
    backgroundColor: $background,
    borderBottomWidth: 0,
    borderRadius: 5,
    paddingTop: 16,
    paddingHorizontal: 16,
    minHeight: 94,
  },
  submitButtonDisabled: {
    backgroundColor: "#f7b900",
  },
  gridHalf: {
    width: "45%",
    padding: 10,
    flexGrow: 1,
    maxWidth: 1100 / PixelRatio.get(),
    minWidth: 600 / PixelRatio.get(),
  },
  gridLeft: {
    width: "30%",
    padding: 10,
    flexGrow: 1,
    height: "100%",
    overflow: "hidden",
    // maxWidth: 1100 / PixelRatio.get(),
    // minWidth: 600 / PixelRatio.get()
  },
  gridRight: {
    width: "65%",
    padding: 10,
    flexGrow: 1,
    // maxWidth: 1100 / PixelRatio.get(),
    // minWidth: 600 / PixelRatio.get()
  },
});
