import Actions from '../../RouteActions';

import { notification } from '../../helperFunctions';
import {
    GET_NUMBER_CONFIRMATION_SUCCESS,
    CONFIRM_NUMBER,
    GET_NUMBER_CONFIRMATION
} from '../../constants/actions/auth';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import * as appActionsConstants from '../../constants/actions/appActions';

import * as authService from '../../services/authService';

export function getNumberConfirmation(formData, setButtonActive) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.getNumberConfirmation(formData).then((res) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            if(res.data.response_code === 0) {
                dispatch({ type: GET_NUMBER_CONFIRMATION_SUCCESS, payload: formData });
                notification.ref.show({ message: 'Confirmation code sent' });
                Actions.confirmCode();
            }
            else {
                notification.ref.show({message: res.data.error, isError: true});
            }
            setButtonActive();
        });
    };
}

export function sendSkipMobileVerification() {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.skipMobileVerification().then((response) => {
            if(response.data.response_code === 0) {
                dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                Actions.signUpLocation();
                notification.ref.show({ message: 'Mobile Verification Skipped' });
            }
            else {
                notification.ref.show({message: res.data.error, isError: true});   
            }
        });
    }
}

export function updateStepsOnBackButton(params) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.updateStepsOnBackButton(params).then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
            Actions.signUp();
        })
    }
}
