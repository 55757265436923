import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    cantRememberWrapper: {
        marginTop: 16,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
    },
    cantRememberText: {
        fontSize: 14,
        marginRight: 5
    },
    cantRememberButtonText: {
        fontSize: 14,
        color: $green
    }
});