import React, { Component } from 'react';
import { connect } from 'react-redux';
import CSVReader from 'react-csv-reader';
import {
    View,
    ImageBackground,
    Platform,
    TouchableOpacity
} from 'react-native';

//components
import Text from '../../components/Text/Text';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

//images
import importCSVIcon from '../../assets/img/import_csv_icon.svg';

//styles
import styles from './styles';

//actions
import { closeDrawer } from '../../DrawerActions.js';
import * as actions from './actions';

//services
import { withGetScreen } from 'react-native-getscreen';
import { notification } from '../../helperFunctions';
import { $green, formLayout } from '../../generalStyles';

class ImportCSVContacts extends Component {
    parseOptions = {
        dynamicTyping: true,
        skipEmptyLines: true,
    };

    state = {
        source: '',
        filename: '',
        csvUploaded: false,
        requestData: {},
        validFile: false,
        showFields: false,
        csvData: {},
        selectedFirstNameVal: '',
        selectedLastNameVal: '',
        selectedEmailVal: '',
        selectedPhoneVal: ''
    }

    componentDidMount = () => {
        this.props.csvSources();
        if ((this.props.drawerProps && this.props.drawerProps.source && this.props.drawerProps.source.length > 0)) {
            this.setState({ source: this.props.drawerProps.source })
        }
    }

    onSourceSelect = (e) => {
        this.setState({ source: e[0].name })
    }

    importHandler = () => {
        const { csvData, source, selectedFirstNameVal, selectedLastNameVal, selectedEmailVal, selectedPhoneVal } = this.state
        let finalData = {
            csvData: csvData,
            source: source,
            selectedFirstNameVal: selectedFirstNameVal,
            selectedLastNameVal: selectedLastNameVal,
            selectedEmailVal: selectedEmailVal,
            selectedPhoneVal: selectedPhoneVal,
            resync: (this.props.drawerProps && this.props.drawerProps.source && this.props.drawerProps.source.length > 0) ? true : false
        }
        this.props.uploadCSV(finalData);
    }

    handleCSVData = (data, fileInfo, file) => {
        if (data.length === 0) {
            notification.ref.show({ message: 'Given file is empty', isError: true });
        }
        else if (this.state.source != '' && fileInfo.name != undefined) {
            if (fileInfo.type === 'text/csv') {
                if (data[0][0] == "Notes:") {
                    data = data.slice(2);
                }
                this.setState((state) => {
                    state.requestData = {
                        csvData: data,
                        source: state.source
                    }
                    state.filename = fileInfo.name
                    state.csvData = data
                    state.csvUploaded = true
                    state.validFile = true
                    return state;
                })
            }
            else {
                notification.ref.show({ message: 'Please upload files with .csv extension, only CSV files are allowed.', isError: true });
            }
        }
        else
            notification.ref.show({ message: 'CSV source is required!.', isError: true });
    };

    onHandleFirstName = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedFirstNameVal = opt
            return state
        })
    }

    onHandleLastName = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedLastNameVal = opt
            return state
        })
    }

    onHandleEmail = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedEmailVal = opt
            return state
        })
    }

    onHandlePhone = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedPhoneVal = opt
            return state
        })
    }

    render() {
        const web = Platform.OS === 'web';
        const { sources, drawerProps} = this.props;
        const dataOptions = []
        this.state.csvData[0] && this.state.csvData[0].map((item) => {
            dataOptions.push({ label: item, value: item })
        })
        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Import .csv Contacts' />
                <View style={{ width: '82%', alignSelf: 'center' }}>
                    <View style={styles.elementWrapper}>
                        <Text style={styles.text}>Drag and drop the .csv file and Boon will add your contacts.</Text>
                    </View>
                    <View style={[{ width: '100%', zIndex: 1000, paddingTop: 20 }]}>
                        <OrganizationStatus
                            onChange={(e) => this.onSourceSelect(e)}
                            searchable={true}
                            clearable={true}
                            create={true}
                            name='source'
                            placeholder='Source'
                            selected={drawerProps.source || ''}
                            dropdownValues={sources}
                            styles={{ borderRadius: 5 }}
                            labelField={'name'}
                            valueField={'name'}
                        />
                    </View>
                    <View style={[styles.imageWrapper, { zIndex: -1 }]}>
                        {!this.state.csvUploaded ?
                            <ImageBackground style={[styles.image, { border: 'none' }]} source={importCSVIcon} >
                                <CSVReader
                                    label={this.state.filename}
                                    onFileLoaded={this.handleCSVData}
                                    parserOptions={this.parseOptions}
                                    inputStyle={{ height: 180, opacity: 0 }}
                                />
                            </ImageBackground>
                            :
                            <View style={styles.fileContainer}>
                                <Text style={{ color: $green, marginLeft: 25, fontWright: '500' }}>{this.state.filename}</Text>
                                <TouchableOpacity onPress={() => this.setState({
                                    filename: '',
                                    csvUploaded: false,
                                    requestData: {},
                                    validFile: false,
                                    showFields: false,
                                    csvData: {},
                                    selectedFirstNameVal: '',
                                    selectedLastNameVal: '',
                                    selectedEmailVal: '',
                                    selectedPhoneVal: ''
                                })}>
                                    <Text style={{ color: 'rgba(0, 0, 0, 0.2)', marginRight: 15 }}>X</Text>
                                </TouchableOpacity>
                            </View>
                        }
                        <View style={styles.csvButton}>
                            <PrimaryButton small onPress={() => this.state.validFile ? this.setState({ showFields: true }) : null} disabled={!this.state.validFile}>
                                Next
                            </PrimaryButton>
                        </View>
                        {(this.state.csvData.length > 0 && this.state.showFields) &&
                            <Text style={styles.descriptionText}>
                                Please define fields mapping according to uploaded file to create contacts accordingly.
                    </Text>
                        }
                    </View>
                    {(this.state.csvData.length > 0 && this.state.showFields) &&
                        <View>
                            <View style={[styles.fieldsWrapper]}>
                                <View style={[formLayout.row, { zIndex: 1000 }]}>
                                    <View style={styles.labelContainer}>
                                        <Text>First name:</Text>
                                    </View>
                                    <View style={{ width: '65%' }}>
                                        <OrganizationStatus
                                            onChange={(e) => this.onHandleFirstName(e)}
                                            name='first_name'
                                            dropdownValues={dataOptions}
                                            styles={[formLayout.fullWidth, styles.dropDownFieldStyle]}
                                            labelField={'label'}
                                            valueField={'value'}
                                            customColor={'#2DB581'}
                                        />
                                    </View>
                                </View>
                                <View style={[formLayout.row, { zIndex: 999 }]}>
                                    <View style={styles.labelContainer}>
                                        <Text>Last name:</Text>
                                    </View>
                                    <View style={{ width: '65%' }}>
                                        <OrganizationStatus
                                            onChange={(e) => this.onHandleLastName(e)}
                                            name='last_name'
                                            dropdownValues={dataOptions}
                                            styles={[formLayout.fullWidth, styles.dropDownFieldStyle]}
                                            labelField={'label'}
                                            valueField={'value'}
                                            customColor={'#2DB581'}
                                        />
                                    </View>
                                </View>
                                <View style={[formLayout.row, { zIndex: 998 }]}>
                                    <View style={styles.labelContainer}>
                                        <Text>Email:</Text>
                                    </View>
                                    <View style={{ width: '65%' }}>
                                        <OrganizationStatus
                                            onChange={(e) => this.onHandleEmail(e)}
                                            name='email'
                                            dropdownValues={dataOptions}
                                            styles={[formLayout.fullWidth, styles.dropDownFieldStyle]}
                                            labelField={'label'}
                                            valueField={'value'}
                                            customColor={'#2DB581'}
                                        />
                                    </View>
                                </View>
                                <View style={[formLayout.row, { zIndex: 997 }]}>
                                    <View style={styles.labelContainer}>
                                        <Text>Phone:</Text>
                                    </View>
                                    <View style={{ width: '65%' }}>
                                        <OrganizationStatus
                                            onChange={(e) => this.onHandlePhone(e)}
                                            name='phone'
                                            dropdownValues={dataOptions}
                                            styles={[formLayout.fullWidth, styles.dropDownFieldStyle]}
                                            labelField={'label'}
                                            valueField={'value'}
                                            customColor={'#2DB581'}
                                        />
                                    </View>
                                </View>
                            </View>
                            <View style={[styles.csvButton, { width: '100%', marginTop: 150, zIndex: -1 }]}>
                                <PrimaryButton
                                    onPress={() => (this.state.validFile === true && this.state.selectedEmailVal === '' || this.state.selectedLastNameVal === '' || this.state.selectedFirstNameVal === '' ) ? null : this.importHandler()}
                                    disabled={(this.state.selectedEmailVal === '' || this.state.selectedLastNameVal === '' || this.state.selectedFirstNameVal === '')}
                                >
                                    Continue
                            </PrimaryButton>
                            </View>
                        </View>
                    }
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        sources: state.organizationsReducer.sourcesDropdownValues,
        drawerProps: state.drawerReducer.drawerProps
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
        uploadCSV: (data) => {
            dispatch(actions.uploadCSVSource(data));
        },
        csvSources: () => {
            dispatch(actions.getCSVSources());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ImportCSVContacts));
