import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    wrapper: {
        marginLeft: 35,
        marginTop: 20,
        marginBottom: 20,
        width: 315
    },
    searchBlock: {
        padding: 10,
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 2,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 2,
    },
    searchBlockBack: {
        marginRight: 24,
        marginLeft: -16
    },
    searchBlockInputWrapper: {
        position: 'relative',
        flexGrow: 1
    },
    searchBlockInput: {
        fontSize: 16,
        fontFamily: 'Roboto-Regular',
        paddingLeft: 15
    },
    searchBlockClearButton: {
        position: 'absolute',
        top: 5,
        right: 0
    },
    searchBlockClearButtonImage: {
        height: 16,
        width: 16
    },
    resultsWrapper: {
        shadowOffset: {
            height: 0,
            width: 1
        },
        shadowRadius: 1,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        flexShrink: 1
    },
    spinner: {
        marginTop: 16
    },
    emptyListWrapper: {
        alignItems: 'center',
        marginTop: 150
    },
    emptyListText: {
        color: '#828587',
        fontSize: 16
    },
    searchStyle: {
        height: 20,
        width: 20,
        paddingRight: 10
    }
});
