import Actions from '../../RouteActions';
import * as redirectStepActions from '../../services/stepsService'
import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import _ from 'lodash';
import * as organizationsActionsConstants from '../../constants/actions/organizationsActions';
import * as organizationService from '../../services/organizationsService';
import * as userService from '../../services/usersService';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';

export function getIndustriesList() {
    return dispatch => {
        dispatch({ type: organizationsActionsConstants.GET_INDUSTRIES });
        return organizationService.getIndustries().then(response => {
            if (response.data.response_code === responseCodes.OK) {
                dispatch({ type: organizationsActionsConstants.GET_INDUSTRIES_SUCCESS, payload: response.data.payload.industries });
                return response.data.payload.industries;
            } else {
                notification.ref.show({
                    message: response.data.error, isError: true
                });
            }
        });
    };
}

export function submitForm(formValues) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return userService.location(formValues).then(response => {
            //remaining check for mobile permissions
            dispatch({ type: authActionsConstants.SIGN_UP_SUCCESS, payload: response.data.payload });
            dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            if (response.data.payload.current_incomplete_step === 'confirm_email') {
                dispatch({type: authActionsConstants.SET_MAGIC_LINK_DATA, payload: {response_code: 0}});
                Actions.magicLink({
                    email: response.data.payload.user.email,
                    skipInitialSending: true,
                    confirmationFlow: true
                });
            } else {
                redirectStepActions.redirectToCurrentStep(response.data.payload.current_incomplete_step);
            }
        }).catch((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            notification.ref.show({ message: response.data.error, isError: true });
        });
    };
}
