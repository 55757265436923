import {StyleSheet} from 'react-native';

export default StyleSheet.create({

    wrapperContainer: {
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        borderRadius: 4,
        position: 'relative',
        borderWidth: 1,
        marginBottom: 16,
        paddingHorizontal: 20,
        paddingVertical: 10,
    },
    wrapperContainerName: {
        fontSize: 12,
        fontWeight: 500,
        marginBottom: 8
    },
    wrapperContainerEmail: {
        fontSize: 12,
        fontWeight: 400,
        color: '#959595',
        marginBottom: 8
    },
    inImage: {
        height: 22,
        width: 26,
        marginRight: 4,
    },

    wrapperContainerLinks: {
        display: 'flex',
        flexDirection: 'row'
    },

    wrapperContainerText: {
        fontSize: 12,
        fontWeight: 400
    },

    strengthContainer: {
        marginBottom: 39
    },
    strengthText: {
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 19,
        marginBottom: 8
    },

    questionContainer: {
        marginBottom: 18
    },

    questionText: {
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 19,
        marginBottom: 16
    },

    strengthTextSub: {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 18,
        color: '#444444',
        marginBottom: 8,
        display: 'flex',
        justifyContent: 'space-between',
        
    },
    percentValue: {
        color: '#0FBC71'
    },

    placeHolderText: {
        position: 'absolute',
        bottom: '90%',
        fontSize: 12,
        fontWeight: 400,
        color: '#959595',
        backgroundColor: '#fff',
    },
    engagedContainer: {
        backgroundColor: "transparent",
        height: "calc(100% - 45px)",
        display: "flex",
        justifyContent: "space-between",
    },
    darnItInfo: {
        display: "flex",
        alignItems: "center",
        marginTop: "25%",
    },
    darnItHeader: {
        fontWeight: 600,
        fontSize: 24,
        marginTop: 28,
        lineHeight: "50px",
        letterSpacing: "-0.458182px",
        color: "#444444",
    },
    darnItText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 24,
        textAlign: "center",
        color: "#444444",
        paddingLeft: 10,
        paddingRight: 10,
    },
    actionButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "32px",
        paddingHorizontal: 20,
    },
    backgroundImage: {
        width: "100%",
        height: "240px",
        display: 'flex',
        justifyContent: 'end',
    },
    modalWrapper :{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#E7E7E796',
        marginTop: 22
    },
    modalChildWrapper:{
        backgroundColor: "white",
        minWidth: 375,
        borderRadius: 5,
        padding: 35,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    modalCloseButton:{
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer'
    },
    modalBodyWrapper: {
        marginTop: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        width: 270,
    },
    modalBodyText:{
        fontFamily: 'Roboto-Regular',
        fontSize: '16px',
        fontWeight: 400,
        textAlign: 'center',
        color: '#444444',
        marginTop: '15px',
    }
    
});
