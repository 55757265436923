import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formWrapper: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        margin: 'auto',
        paddingTop: 60,
        paddingHorizontal: 20,
        paddingBottom: 40
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20
    },
    headerImage: {
        paddingBottom: 25
    },
    heading: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '126%',
        color: '#0FBC71',
        paddingBottom: 25
    },
    secondaryText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    formFieldWrapper: {
        marginTop: 25
    },
    bottomWrapper: {
        paddingTop: 20,
        display: 'flex'
    },
    fieldContainer: {
        paddingBottom: 20
    },
    fullWidth: {
        width: '100%'
    },
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        fontWeight: 400
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    bottomText: {
        color: '#FFFFFF'
    },
    bottomTextLink: {
        textDecorationLine: 'underline'
    },
    backButtonWrapper: {
        paddingTop: 10
    }
});
