import baseService from './baseService.js';

export function generalInfo(params) {
    return baseService.post('users/general_info', params);
}

export function checkUserEmployer() {
    return baseService.get('users/check_user_organization');
}

export function createInvitationStep() {
    return baseService.post('users/update_step');
}

export function location(params) {
    return baseService.post('users/location', params);
}

export function role_step() {
    return baseService.post('users/role_step');
}

export function pending_referrals_step(params) {
    return baseService.post('users/pending_referrals_step', params);
}

export function sync_network_step() {
    return baseService.post('users/sync_network_step');
}

export function undo_sync_network_step() {
    return baseService.post('users/undo_sync_network_step');
}

export function uploadCSV(data) {
    return baseService.post(`/users/upload_csv`, { csv_data: data });
}

export function getUserByEmail(id) {
    return baseService.post(`/users/get_user_by_email`, { id: id });
}

export function getInviteData(token) {
    return baseService.get(`/invitations/invitation_data_by_token`, { params: { invitation_token: token } });
}

export function importFields(data) {
    return baseService.post(`/users/import_fields_mapping`, data);
}

export function setUserStatus(user, organization){
    return baseService.post(`/users/update_status_on_invite_accept`, { user_id: user, organization_name: organization });
}

export function setOrgAccessRequest(data){
    return baseService.post(`users/organization_access_requests`, {...data});
}

export function cancelOrgAccessRequest(data){
    return baseService.post(`users/organization_access_requests/handle_cancelled_request`, data);
}

export function getOrgIdByName(name){
    return baseService.get(`/organizations/${name}/general_settings/general_signup`);
}

export function loginRequest(email){
    return baseService.post('/login_request',{email});
}
