import React from 'react';
import {Field} from 'redux-form';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import FormInput  from '../../components/FormInput/FormInput';

const componentForm = {
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    country: 'short_name',
    postal_code: 'short_name'
};

class GooglePlacesAutocomplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: ' ' };
    }

    handleChange = address => {
      if (address && address.length >= 3) {
        this.setState({ address });
      }
    };

    handleSelect = address => {
        this.setState({address: address});
        geocodeByAddress(address)
            .then(results => {
              this.props.onSelectPress(results[0], address);
            })
            .catch(error => console.error('Error', error));
    };

    render() {
        const { styles, inputStyle } = this.props;

        return (
          <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
          >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className='web-address'>
                      <Field
                          component={FormInput}
                          type="text"
                          name='search_address'
                          className='addressInput'
                          value={this.state.address}
                          inputStyle={inputStyle}
                          {...getInputProps({
                              onFocus: this.props.textInputProps.onFocus,
                              onBlur: this.props.textInputProps.onBlur
                          })}
                      />

                      <div className="autocomplete-dropdown-container">
                          {suggestions.map(suggestion => {
                              const className = 'suggestion-item';

                              return (
                                  <div
                                      {...getSuggestionItemProps(suggestion, {
                                          className
                                      })}
                                  >
                                      <span>{suggestion.description}</span>
                                  </div>
                              );
                          })}
                      </div>
                  </div>
              )}
          </PlacesAutocomplete>
        );
    }
}

export default GooglePlacesAutocomplete;
