import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    View
} from 'react-native';

//components
import Text from '../../../../components/Text/Text';

//styles
import { listElement, $green } from '../../../../generalStyles';
import style from '../ArchivedStatusLabel/styles';


class InterviewStatusLabel extends Component {
    render() {
        const { opportunity: { referrals }, direction } = this.props;
        const candidateStatus = referrals[0].candidate_status;
        const stages = this.props.opportunity.interview_stages;
        let stage, label;
        if (stages) {
            stage = stages.find(stage => stage.value === candidateStatus);
            if (stage) {
                label = stage.label;
            }
        }

        return (
            <View style={[listElement.rightBlock]}>
                {label &&
                    <View style={[style.statusLabelWrapper, {backgroundColor: $green}]}>
                        <Text style={style.statusLabelTitle}>{label}</Text>
                    </View>
                }
            </View>
        );
    }
}

export default (InterviewStatusLabel);
