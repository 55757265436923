import * as activityActionsConstants from '../constants/actions/activityActions';
import * as rewardsTermsAndConditionsConstants from '../constants/actions/rewardsTermsAndConditionsActions';

import { findIndex } from 'lodash-es';

const initialState = {
  selectedOrganization: { name: 'Dashboard' },
  activityList: [],
  activityListLoading: false,
  isLastActivityPage: false,
  leaderBoardList: [],
  leaderBoardListLoading: false,
  termsAndConditions: {},
  termsAndConditionsLoading: false,
};

const activityReducer = (state = initialState, action) => {
  let activityList = [];
  let likedObjectIndex = null;

  switch (action.type) {
    case activityActionsConstants.CHANGE_SELECTED_ORGANIZATION_HOME:
      return {
        ...state,
        selectedOrganization: action.payload,
      };
    case activityActionsConstants.GET_ACTIVITY_LIST:
      return {
        ...state,
        activityListLoading: !action.payload.isPagination,
        activityList: action.payload.isPagination ? state.activityList : [],
      };
    case activityActionsConstants.GET_ACTIVITY_LIST_SUCCESS:
      activityList = action.payload.isPagination
        ? state.activityList.concat(action.payload.activities)
        : action.payload.activities;
      activityList.map((activity) => {
        if (activity.matching_users && activity.matching_users.length != 0) {
          activity.matching_users.map((matching_user) => {
            matching_user.job = activity.job;
            matching_user.organization = activity.organization;
          });
        }
      });
      return {
        ...state,
        activityList,
        isLastActivityPage: action.payload.activities.length < 10,
        activityListLoading: false,
      };
    case activityActionsConstants.GET_ACTIVITY_LIST_ERROR:
      return {
        ...state,
        activityListLoading: false,
      };
    case activityActionsConstants.GET_LEADER_BOARD_LIST:
      return {
        ...state,
        leaderBoardList: [],
        leaderBoardListLoading: true,
      };
    case activityActionsConstants.GET_LEADER_BOARD_LIST_SUCCESS:
      return {
        ...state,
        leaderBoardList: action.payload.records,
        leaderBoardListLoading: false,
      };
    case activityActionsConstants.GET_LEADER_BOARD_LIST_ERROR:
      return {
        ...state,
        leaderBoardListLoading: false,
      };
    case activityActionsConstants.LIKE_ACTIVITY:
      activityList = state.activityList.slice();
      likedObjectIndex = activityList.findIndex((activity) => activity.id === action.payload);
      activityList[likedObjectIndex].liked = true;
      activityList[likedObjectIndex].likes++;

      return {
        ...state,
        activityList,
      };
    case activityActionsConstants.LIKE_ACTIVITY_ERROR:
      activityList = state.activityList.slice();
      likedObjectIndex = activityList.findIndex((activity) => activity.id === action.payload);
      activityList[likedObjectIndex].liked = false;
      activityList[likedObjectIndex].likes--;

      return {
        ...state,
        activityList,
      };
    case activityActionsConstants.UNLIKE_ACTIVITY:
      activityList = state.activityList.slice();
      likedObjectIndex = activityList.findIndex((activity) => activity.id === action.payload);
      activityList[likedObjectIndex].liked = false;
      activityList[likedObjectIndex].likes--;

      return {
        ...state,
        activityList,
      };
    case activityActionsConstants.UNLIKE_ACTIVITY_ERROR:
      activityList = state.activityList.slice();
      likedObjectIndex = activityList.findIndex((activity) => activity.id === action.payload);
      activityList[likedObjectIndex].liked = true;
      activityList[likedObjectIndex].likes++;

      return {
        ...state,
        activityList,
      };
    case activityActionsConstants.PASS_RECOMMENDATION_SUCCESS:
      activityList = state.activityList.slice();
      const passedRecommendationIndex = findIndex(
        activityList[0].data,
        (recommendation) => recommendation.id === action.payload
      );

      activityList[0].data.splice(passedRecommendationIndex, 1);

      if (!activityList[0].data.length) {
        activityList.splice(0, 1);
      }

      return {
        ...state,
        activityList,
      };
    case activityActionsConstants.HIDE_RECOMMENDATIONS:
      activityList = state.activityList.slice();
      activityList.splice(0, 1);

      return {
        ...state,
        activityList,
      };
    case rewardsTermsAndConditionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS:
      return {
        ...state,
        termsAndConditions: {},
        termsAndConditionsLoading: true,
      };
    case rewardsTermsAndConditionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS_SUCCESS:
      return {
        ...state,
        termsAndConditions: action.payload,
        termsAndConditionsLoading: false,
      };
    case rewardsTermsAndConditionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS_ERROR:
      return {
        ...state,
        termsAndConditionsLoading: false,
      };
    default:
      return state;
  }
};

export default activityReducer;
