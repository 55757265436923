import Actions from '../../RouteActions';

import * as appActionsConstants from '../../constants/actions/appActions';
import * as authService from '../../services/authService';
import { notification } from '../../helperFunctions';

export const enableGoogleSSO = (payload) => (dispatch) => {
    authService.enableGoogleSSO(payload).then((response) => {
        if(!!response.data.redirect_uri){
            dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
            localStorage.setItem('LOGGED_IN_VIA_GOOGLE_SSO', true)
            window.location.href = response.data.redirect_uri;
        }else{
            notification.ref.show({ message: response.data.error.message, isError: true });
        }
    });
  };
