import EStyleSheet from 'react-native-extended-stylesheet';
import { $green } from '../../../../generalStyles';

export default EStyleSheet.create({
    wrapper: {
        position: 'relative',
        marginBottom: 8,
        borderRadius: 5,
        shadowOffset: {height: 2, width: 0},
        shadowRadius: 4,
        shadowColor: '#000',
        shadowOpacity: 0.5,
        overflow: 'hidden'
    },
    backgroundImageWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: -1
    },
    backgroundImage: {
        height: '100%',
        width: '100%',
    },
    backgroundImageColorCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: $green,
        opacity: 0.8,
        zIndex: 0
    },
    info: {
        padding: 16,
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#fff'
    },
    description: {
        fontSize: 16,
        color: '#fff',
        marginTop: 3,
        marginBottom: 6
    },
    avatarsWrapper: {
        flexDirection: 'row-reverse',
        justifyContent: 'flex-end',
        marginBottom: 16
    },
    avatar: {
        height: 40,
        width: 40,
        borderWidth: 1,
        borderColor: '#fff',
        borderRadius: 20,
        marginRight: -20
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 9
    },
    reviewButton: {
        backgroundColor: '#fff',
        width: 132,
        height: 30
    },
    dismissButton: {
        color: '#fff'
    }
});