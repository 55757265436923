import React, {Component} from 'react';
import { View, Text, PixelRatio } from 'react-native';
import PhoneInput from '../../wrappers/phoneNumber/phoneNumberInput';

import styles from './styles';

class FormInputPhone extends Component {
    constructor(props) {
        super(props);
        this.state = { isFocused: false };
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    onFocus(e) {
        const { input } = this.props;
        this.setState({
            ...this.state,
            isFocused: true
        });
        input.onFocus(e);
    }

    onBlur(e) {
        const { input } = this.props;
        this.setState({
            ...this.state,
            isFocused: false
        });
        input.onBlur(e);
    }

    render() {
        const {
            input,
            wrapperStyle,
            inputStyle,
            mask,
            initialCountry,
            labelStyle,
            placeholder,
            placeholderToLabel,
            showPlaceholder,
            ...inputProps
        } = this.props;

        const { isFocused } = this.state;

        const onChange = text => {
            input.onChange(text);
        };

        const labelStyles = [
            styles.labelStyle,
            labelStyle,
            { marginLeft: 20 / PixelRatio.get() }
        ];

        if (isFocused) {
            labelStyles.push(styles.labelStyleFocused);
        }
        return (
            <View style={[styles.wrapperStyle, wrapperStyle]}>
                <PhoneInput
                    textStyle={[styles.inputStyle, inputStyle]}
                    ref={inputProps.inputRef}
                    value={input.value}
                    onChangePhoneNumber={onChange}
                    autoFormat={true}
                    variant="outlined"
                    autoFocus={inputProps.autoFocus}
                    label={placeholder}
                    textProps={{...inputProps,
                        onBlur: this.onBlur, onFocus: this.onFocus,
                        placeholder: placeholder, keyboardType: "phone-pad"}}
                    initialCountry={initialCountry}
                />
            </View>
        );
    }
}

export default FormInputPhone;
