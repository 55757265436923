import React, { useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import { View, Image, Dimensions, TouchableOpacity, Keyboard, Platform, ScrollView } from 'react-native';
import { Drawer, List } from '@ant-design/react-native';
import { withGetScreen } from 'react-native-getscreen';
import { isEmpty } from 'lodash-es';

//components
import DashboardBase from '../../../components/DashboardBase/DashboardBase';
import Text from '../../../components/Text/Text';
import RewardUsage from '../../../components/Reward/RewardUsage/RewardUsage';
import Reward from '../../../components/Reward/Reward/Reward';
import EmployerProfileTopSection from '../components/EmployerProfileTopSection/EmployerProfileTopSection';
import Spinner from 'react-native-loading-spinner-overlay';

// Styles
import styles from './styles';
import { common as commonStyles, drawerElement } from '../../../generalStyles';

import { locationObjToStr } from '../../../helperFunctions';
import defaultOrgAvatar from '../../../assets/img/default_org_avatar.png';
import arrowLeftSVG from '../../../assets/svg/arrow-left.svg';
import arrowRightSVG from '../../../assets/svg/arrow-right.svg';

import * as actions from '../actions';

const EmployerProfileOverview = (props) => {
  const {
    currentUser,
    employerProfile,
    employerProfileLoading,
    isMobile,
    isTablet,
    width,
    customizableName,
    getEmployer,
  } = props;
  const web = Platform.OS === 'web';
  const tabScreen = !web || isMobile() || isTablet();
  const scrollRef = useRef(null);
  const drawerRef = useRef(null);
  const clickChildRef = useRef(null);

  useEffect(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search));
    let userId = queryParams['user_id'];
    let subjectEmail = queryParams['subject_email'];
    let fromTracker = queryParams['from_tracker'];
    if (userId) localStorage.setItem('userId', userId);
    if (subjectEmail) localStorage.setItem('subjectEmail', encodeURIComponent(subjectEmail));
    if (fromTracker) localStorage.setItem('fromTracker', fromTracker);

    getEmployer(customizableName);
  }, [customizableName]);

  const handleOpenChange = () => {
    if (drawerRef.current) {
      drawerRef.current.openDrawer();
    }
  };

  const renderSidebar = useMemo(() => {
    return (
      <ScrollView style={[drawerElement.scrollElement]}>
        <List>
          {['Gmail', 'Phonebook', 'Connections', 'Cancel'].map((action, i) => (
            <List.Item key={i} onPress={() => handleDrawerItemClick(i)} style={drawerElement.listItemStyle}>
              <Text style={drawerElement.itemTextStyle}>{action}</Text>
            </List.Item>
          ))}
        </List>
      </ScrollView>
    );
  }, []);

  const renderLeftHeader = useMemo(() => {
    if (isEmpty(currentUser)) {
      const avatar = employerProfile.logo_image_thumb ? { uri: employerProfile.logo_image_thumb } : defaultOrgAvatar;
      return (
        <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.leftHeader]}>
          <Image source={avatar} style={{ width: 30, height: 30, marginRight: 12 }} resizeMethod="contain" />
          <Text
            style={{
              color: '#9B9B9B',
              fontSize: 14,
              fontWeight: '500',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {employerProfile.name}
          </Text>
        </View>
      );
    }
    return null;
  }, [currentUser, employerProfile]);

  const handleDrawerItemClick = (i) => {
    if (drawerRef.current) {
      drawerRef.current.closeDrawer();
    }
    if (clickChildRef.current) {
      clickChildRef.current(i);
    }
  };

  return (
    <Drawer
      sidebar={renderSidebar}
      open={false}
      drawerRef={(e) => (drawerRef.current = e)}
      onOpenChange={handleOpenChange}
      drawerBackgroundColor="#fff"
      drawerWidth={width}
    >
      {employerProfileLoading && <Spinner visible />}
      <DashboardBase isProtectedRoute={false} title="Employer Profile" leftHeader={renderLeftHeader}>
        <ScrollView ref={scrollRef}>
          <View style={styles.root}>
            <View style={[styles.section, { marginTop: 0 }]}>
              <EmployerProfileTopSection />
            </View>
            <View style={styles.section}>
              <View style={styles.sectionHead}>
                <Text style={styles.subTitleText}>ABOUT</Text>
              </View>
              <View
                style={{
                  paddingHorizontal: 30,
                  paddingTop: 8,
                  paddingBottom: tabScreen ? 80 : 26,
                }}
              >
                <Text
                  id="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: employerProfile.overview || '',
                  }}
                ></Text>
                <View>
                  {Boolean(employerProfile.industry && employerProfile.industry.name) && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>INDUSTRY</Text>
                      <Text style={styles.text}>{employerProfile.industry.name}</Text>
                    </View>
                  )}

                  {Boolean(employerProfile.organization_size && employerProfile.organization_size.name) && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>COMPANY SIZE</Text>
                      <Text style={styles.text}>{employerProfile.organization_size.name}</Text>
                    </View>
                  )}

                  {Boolean(employerProfile.organization_status && employerProfile.organization_status.name) && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>STATUS</Text>
                      <Text style={styles.text}>{employerProfile.organization_status.name}</Text>
                    </View>
                  )}

                  {/* {Boolean(
                    employerProfile.organization_headquarter &&
                      employerProfile.organization_headquarter.full_address
                  ) && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>HEADQUARTERS</Text>
                      <Text style={styles.text}>
                        {employerProfile.organization_headquarter.full_address}
                      </Text>
                    </View>
                  )} */}
                  {Boolean(employerProfile.locations && employerProfile.locations.length > 0) && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>LOCATION</Text>
                      <Text style={styles.text}>{locationObjToStr(employerProfile.locations[0])}</Text>
                    </View>
                  )}

                  {/* <View style={styles.subSection}>
                    <Text style={styles.subTitleText}>WEBSITE</Text>
                    <Text style={styles.text}>http://www.a16z.com</Text>
                    <Text style={styles.space}></Text>
                  </View> */}
                  {employerProfile.tags && employerProfile.tags.length > 0 && (
                    <View style={styles.subSection}>
                      <Text style={styles.subTitleText}>PERKS &amp; BENIFITS</Text>
                      <View style={styles.tagsWrapper}>
                        {employerProfile.tags.map((tag, index) => (
                          <View key={`tag-${index}`} style={styles.tag}>
                            <Text style={styles.tagText}>{tag.name}</Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              </View>
            </View>

            {/* <View
              style={[
                styles.section,
                { paddingHorizontal: 30, paddingVertical: 20 },
              ]}
            >
              <Text style={styles.subTitleText}>REWARDS</Text>
              <Text>How it works</Text>
              <View style={{ marginTop: 12 }}>
                <RewardUsage />
              </View>
              <View
                style={[
                  commonStyles.row,
                  commonStyles.alignItemsCenter,
                  commonStyles.justifyContentCenter,
                  { padding: 20, backgroundColor: "#EEEEEE", marginTop: 20 },
                ]}
              >
                <Image
                  source={arrowLeftSVG}
                  style={{ height: 12, width: 8 }}
                  resizeMethod="contain"
                />
                <Text
                  style={{
                    color: "#444444",
                    fontSize: 14,
                    fontWeight: "700",
                    textAlign: "center",
                    width: 225,
                  }}
                >
                  Org Members
                </Text>
                <Image
                  source={arrowRightSVG}
                  style={{ height: 12, width: 8 }}
                  resizeMethod="contain"
                />
              </View>
              <View>
                <Reward />
              </View>
            </View> */}
          </View>
        </ScrollView>
      </DashboardBase>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    width: state.drawerReducer.width,
    employerProfile: state.organizationsReducer.organizationDetails,
    employerProfileLoading: state.organizationsReducer.organizationDetailsLoading,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEmployer: (id) => {
      dispatch(actions.getEmployer(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(EmployerProfileOverview));
