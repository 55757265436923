import React from 'react';
import { CheckBox, View, Image, TouchableOpacity, Modal } from 'react-native';
import CloseButton from '../../../components/CloseButton/CloseButton';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import Text from '../../../components/Text/Text';
import Warning from '../../../assets/svg/warning';
import styles from './styles';

const SkipReferUserModal = ({ onCloseModal, onSuccess }) => {
  return (
    <Modal transparent={true} visible={true} onRequestClose={onCloseModal}>
      <View style={styles.modalWrapper}>
        <View style={styles.modalChildWrapper}>
          <CloseButton style={styles.modalCloseButton} onPress={onCloseModal} />
          <View style={styles.modalBodyWrapper}>
            <Warning />
            <Text style={styles.modalBodyText}>Are you sure you want to exit?</Text>
            <Text style={styles.modalBodyText}>
              Your referral will be sent but you will be able to answer these questions later on for this referral.
            </Text>
            <View style={styles.actionButtons}>
              <PrimaryButton
                white
                style={{ height: '37px', width: '49%', border: '1px solid #0FBC71' }}
                onPress={onCloseModal}
              >
                No
              </PrimaryButton>
              <PrimaryButton style={{ height: '37px', width: '49%' }} onPress={onSuccess}>
                Continue
              </PrimaryButton>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SkipReferUserModal;
