import moment from 'moment';
import React, { Component } from 'react';
import Countdown from 'react-countdown';
import { Image, Linking, Platform, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import countDownIcon from '../../assets/img/count_down_icon.svg';
import csvIcon from '../../assets/img/csv-contact.svg';
import googleIcon from '../../assets/img/google-contact.svg';
import linkedinIcon from '../../assets/img/linkedin-contact.svg';
import notifyIcon from '../../assets/img/notify_icon.svg';
import outlookIcon from '../../assets/img/outlook-contact.svg';
import vCardIcon from '../../assets/img/vcard-contact.svg';
import * as AsyncStorage from '../../AsyncStorage';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
//components
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import Text from '../../components/Text/Text';
import {
    closeDrawer, openConnectContactsDrawer, openConnectLinkedInDrawer,
    openCSVContactsDrawer,
    openImportContactsDrawer,
    openUploadLinkedInContactsDrawer
} from '../../DrawerActions';
import { listElement } from '../../generalStyles';
import { importLinkedInContacts } from '../../globalActions/networkActions.js';
import { googleSSOUrl } from '../../helperFunctions';
import { apiSeverUrl } from '../../services/baseService';
import { urls } from '../../urls.js';
import Alert from '../../wrappers/Alert/Alert';
import * as settingActions from '../Settings/actions';
import * as actions from './actions';
//styles
import styles from './styles';

class ConnectContacts extends Component {
    constructor(props) {
        super(props);

        this._scrollToInput = this._scrollToInput.bind(this);
        this.scroll = null;
    }

    _scrollToInput(reactNode) {
        this.scroll.props.scrollToFocusedInput(reactNode)
    }

    handleDownloadLinkedInFileButton = () => {
        this.props.openUploadLinkedInContactsDrawer();
    }

    handleButtonPress = (e) => {
        e.preventDefault();
        this.props.openConnectContactsDrawerWithCountDown(this.props.currentUser.id);

        window.open(
            urls.linkedinConnect,
            '_blank'
        );
    }

    checkLinkedIn = () => {
        window.open(
            urls.linkedinConnect,
            '_blank'
        );
    }

    renderer = ({ minutes, completed }) => {
        let icon, text, buttonHandler, buttonText;
        if (completed) {
            icon = notifyIcon;
            text = 'Your LinkedIn export should be ready!';
            buttonHandler = this.handleDownloadLinkedInFileButton;
            buttonText = 'Download';

        } else {
            icon = countDownIcon;
            text = 'Estimated Time Remaining';
            buttonHandler = this.checkLinkedIn;
            buttonText = 'Go to LinkedIn';
        }
        return (
            <View style={{ display: 'inline', marginTop: 20 }}>
                <View style={styles.leftAlign}>
                    <View style={{ display: 'inline' }}>
                        <View style={[styles.col, styles.textLeft]}>
                            <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                <Image style={[listElement.image, { height: 22, width: 22 }]} source={icon} resizeMode='contain' />
                            </View>
                        </View>
                        <View style={[listElement.infoWrapper, styles.textRight, { paddingTop: 0 }]}>
                            <Text style={styles.linkedInText} numberOfLines={1}>{text}</Text>
                            {
                                !completed &&
                                <Text style={[styles.linkedInText, { color: '#303030' }]} numberOfLines={1}>~{minutes} Minutes</Text>
                            }
                        </View>
                    </View>
                </View>
                <View style={styles.textRight}>
                    <ListElementButton
                        style={styles.checkLinkedInbutton}
                        onPress={buttonHandler}
                    >
                        <Text style={styles.checkLinkedInbuttonText}>{buttonText}</Text>
                    </ListElementButton>
                </View>
            </View>
        );
    }

    handleOutlookConnectPress = () => {
        const { outlookConnected, unsubscribeOutlook } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (outlookConnected) {
                    Alert.alert('Disconnect from Outlook?', 'You won\'t be able to see your new Outlook connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeOutlook(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Outlook', url: `${apiSeverUrl}/sign_in_outlook?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/microsoft_graph_auth?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    handleGoogleConnectPress() {
        const { googleConnected, unsubscribeGoogle } = this.props;
        AsyncStorage.getAccessToken().then(accessToken => {
            if (googleConnected) {
                Alert.alert('Disconnect from Google?', 'You won\'t be able to see your new Google connections on Boon anymore', [
                    { text: 'Cancel', style: 'cancel', onPress: () => null },
                    {
                        text: 'Disconnect',
                        onPress: () => unsubscribeGoogle(accessToken)
                    }
                ]);
            } else {
                googleSSOUrl(accessToken);
            }
        });
    }

    render() {
        const web = Platform.OS === 'web';
        const { showCountDown, minutesElapsed, userSources, outlookConnected, googleConnected, openCSVContactsDrawer } = this.props;

        const timeDelta = 21 - minutesElapsed;

        return (
            <KeyboardAwareScrollView style={[{ flex: 1 }, !web && { marginTop: 30 }]} keyboardShouldPersistTaps="always" enableAutomaticScroll extraScrollHeight={20} innerRef={ref => { this.scroll = ref }}>
                <DrawerHeader title={'Sync your contacts'} />
                <View styles={{ backgroundColor: '#E5E5E5' }}>
                    <View style={{ padding: 15, marginBottom: 15 }}>
                        <Text>
                            Syncing your contacts enables our AI to identify potential referral opportunities that exist within their network for them.
                        </Text>
                        <Text style={{ marginTop: 15 }}>
                            We will not share your data with anyone and we will never solicit any contact without your explicit approval. We treat your data with respect.
                        </Text>
                    </View>
                    <View style={{ padding: 15, backgroundColor: '#fff' }}>
                        <View style={{ display: 'inline' }}>
                            <View style={styles.leftAlign}>
                                <View style={{ display: 'inline' }}>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                            <Image style={listElement.image} source={linkedinIcon} resizeMode="contain" />
                                        </View>
                                    </View>
                                    <View style={[listElement.infoWrapper, styles.textRight]}>
                                        <Text style={styles.title} numberOfLines={1}>LinkedIn</Text>
                                        <Text style={{ color: '#303030' }} numberOfLines={1}>Add your connections</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.textRight}>
                                <ListElementButton
                                    style={styles.linkedinImportButton}
                                    onPress={this.props.openUploadLinkedInContactsDrawer}
                                >
                                    Import LinkedIn
                                </ListElementButton>
                            </View>
                        </View>
                        <View>
                            <Text>
                                Exports include the following information:
                            </Text>
                        </View>
                        <View style={{ display: 'inline', marginTop: 15 }}>
                            <View style={[styles.linkedinTags, { marginLeft: 0 }]}>
                                <Text style={{ color: '#505050' }}>
                                    Name
                                </Text>
                            </View>
                            <View style={styles.linkedinTags}>
                                <Text style={{ color: '#505050' }}>
                                    Current Job
                                </Text>
                            </View>
                            <View style={styles.linkedinTags}>
                                <Text style={{ color: '#505050' }}>
                                    Current Company
                                </Text>
                            </View>
                        </View>

                        {
                            ((minutesElapsed > 0 && minutesElapsed <= 20) || showCountDown) ?
                            <View>
                                <Countdown
                                    date={Date.now() + timeDelta * 60000 || Date.now() + 720000}
                                    intervalDelay={60000}
                                    precision={3}
                                    autoStart={showCountDown}
                                    renderer={this.renderer}
                                />
                            </View>
                            :
                            <View style={{ display: 'inline', marginTop: 20 }}>
                                <View style={styles.textRight}>
                                    <ListElementButton
                                        style={styles.checkLinkedInbutton}
                                        onPress={this.handleButtonPress}
                                    >
                                        <Text style={styles.checkLinkedInbuttonText}>Go to LinkedIn</Text>
                                    </ListElementButton>
                                </View>
                            </View>
                        }
                    </View>
                    <View style={{ padding: 15, backgroundColor: '#fff', marginTop: 1 }}>
                        <View style={{ display: 'inline' }}>
                            <View style={styles.leftAlign}>
                                <View style={{ display: 'inline' }}>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                            <Image style={listElement.image} source={googleIcon} resizeMode="contain" />
                                        </View>
                                    </View>
                                    <View style={[listElement.infoWrapper, styles.textRight]}>
                                        <Text style={styles.title} numberOfLines={1}>Google</Text>
                                        <Text style={{ color: '#303030' }} numberOfLines={1}>Add your contacts</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.textRight}>
                                <ListElementButton
                                    style={[styles.linkedinImportButton, { backgroundColor: '#fff' }]}
                                    onPress={() => this.handleGoogleConnectPress()}
                                >
                                    {googleConnected ? 'Disconnect' : 'Connect'}
                                </ListElementButton>
                            </View>
                        </View>
                    </View>
                    <View style={{ padding: 15, backgroundColor: '#fff', marginTop: 1 }}>
                        <View style={{ display: 'inline' }}>
                            <View style={styles.leftAlign}>
                                <View style={{ display: 'inline' }}>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                            <Image style={listElement.image} source={outlookIcon} resizeMode="contain" />
                                        </View>
                                    </View>
                                    <View style={[listElement.infoWrapper, styles.textRight]}>
                                        <Text style={styles.title} numberOfLines={1}>Outlook</Text>
                                        <Text style={{ color: '#303030' }} numberOfLines={1}>Add your contacts</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.textRight}>
                                <ListElementButton
                                    style={[styles.linkedinImportButton, { backgroundColor: '#fff' }]}
                                    onPress={() => this.handleOutlookConnectPress()}
                                >
                                    {outlookConnected ? 'Disconnect' : 'Connect'}
                                </ListElementButton>
                            </View>
                        </View>
                    </View>
                    <View style={{ padding: 15, backgroundColor: '#fff', marginTop: 1 }}>
                        <View style={{ display: 'inline' }}>
                            <View style={styles.leftAlign}>
                                <View style={{ display: 'inline' }}>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                            <Image style={listElement.image} source={vCardIcon} resizeMode="contain" />
                                        </View>
                                    </View>
                                    <View style={[listElement.infoWrapper, styles.textRight]}>
                                        <Text style={styles.title} numberOfLines={1}>Import vCard</Text>
                                        <Text style={{ color: '#303030' }} numberOfLines={1}>Import your ios contacts</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.textRight}>
                                <ListElementButton
                                    style={styles.linkedinImportButton}
                                    onPress={null}
                                >
                                    Import File
                                </ListElementButton>
                            </View>
                        </View>
                    </View>
                    <View style={{ padding: 15, backgroundColor: '#fff', marginTop: 1 }}>
                        <View style={{ display: 'inline' }}>
                            <View style={styles.leftAlign}>
                                <View style={{ display: 'inline' }}>
                                    <View style={[styles.col, styles.textLeft]}>
                                        <View style={listElement.imageWrapper, { width: 'fit-content' }}>
                                            <Image style={listElement.image} source={csvIcon} resizeMode="contain" />
                                        </View>
                                    </View>
                                    <View style={[listElement.infoWrapper, styles.textRight]}>
                                        <Text style={styles.title} numberOfLines={1}>Import .CSV</Text>
                                        <Text style={{ color: '#303030' }} numberOfLines={1}>Import your contacts</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={styles.textRight}>
                                <ListElementButton
                                    style={styles.linkedinImportButton}
                                    // onPress={() => this.props.onImportContacts()}
                                    onPress={openCSVContactsDrawer}
                                >
                                    Import File
                                </ListElementButton>
                            </View>
                        </View>
                        <View>
                            {userSources.length > 0 &&
                                userSources.map((source) => {
                                    return (
                                        <Text style={{ marginTop: 10 }}>{source.name} . Synced {source.updated_at} .&nbsp;
                                            <TouchableOpacity
                                                onPress={() => openCSVContactsDrawer({ source: source.name })}
                                            >
                                                <Text style={{ fontWeight: 'bold' }}>
                                                    Resync
                                                </Text>
                                            </TouchableOpacity>
                                        </Text>
                                    );
                                })
                            }
                        </View>
                    </View>
                </View>
            </KeyboardAwareScrollView>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.applyJobForm && state.form.applyJobForm.values ? state.form.applyJobForm.values : {},
        referredJobData: state.refersReducer.referredJobData,
        referUsers: state.refersReducer.referUsers,
        showCountDown: state.drawerReducer.showCountDown,
        minutesElapsed: state.drawerReducer.minutesElapsed,
        userSources: state.authReducer.userSources,
        googleConnected: state.authReducer.currentUser.sync_google_people_contacts,
        outlookConnected: state.authReducer.currentUser.outlook_authenticated,
        googleConnectionLoading: state.authReducer.googleConnectionLoading,
        currentUser: state.authReducer.currentUser
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        openConnectLinkedInDrawer: () => dispatch(openConnectLinkedInDrawer()),
        openUploadLinkedInContactsDrawer: () => dispatch(openUploadLinkedInContactsDrawer()),
        openConnectContactsDrawerWithCountDown: (id) => {
            dispatch(closeDrawer());
            dispatch(importLinkedInContacts(id))
            dispatch(actions.setDownloadTime(moment()));
            dispatch(openConnectContactsDrawer(true));
        },
        unsubscribeGoogle: accessToken => {
            dispatch(settingActions.unsubscribeGoogle(accessToken));
        },
        unsubscribeOutlook: accessToken => {
            dispatch(settingActions.unsubscribeOutlook(accessToken));
        },
        subscribeGoogle: () => {
            dispatch(settingActions.subscribeGoogle());
        },
        onImportContacts: () => {
            dispatch(closeDrawer());
            dispatch(openImportContactsDrawer());
        },
        openCSVContactsDrawer: (drawerProps) => dispatch(openCSVContactsDrawer(drawerProps)),
    }
};

ConnectContacts = reduxForm({ form: 'applyJobForm', enableReinitialize: true })(ConnectContacts);

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ConnectContacts));
