import React, { Component } from 'react';
import {
    Image,
    View
} from 'react-native';

import { MediaQuery } from 'react-native-responsive-ui/src';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { withGetScreen } from 'react-native-getscreen';

import styles from './styles';
import respStyles from '../../responsiveStyles';

//components
import GlobalGradient from "../GlobalGradient/GlobalGradient";

//images
import logo from '../../assets/img/logo.png';
import BackButton from '../BackButton/BackButton';

class OnboardingBase extends Component {
    render() {
        const mobile = !this.props.isMobile();
        const {children, logoWrapperStyles, childrenWrapperStyles, hasBackButton, skipNavOnBackPress, onBackButtonPress, width} = this.props;
        const gradients = mobile ? ['#32CF6C', '#2AAA89'] : ['#47e5a8', '#0FBC71']
        const angle = mobile ? '90deg' : '180deg'
        return (
            <GlobalGradient gradients={gradients} angle={angle}>
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={[styles.keyboardAwareContainer, respStyles.flexCenter]}
                    style={{width: '100%'}}>
                    <View style={[styles.logoWrapper, logoWrapperStyles, mobile && respStyles.mb20]}>
                        {hasBackButton && <BackButton
                            onPress={onBackButtonPress}
                            skipNavOnBackPress={skipNavOnBackPress}
                            style={styles.backButton}/>}
                        <Image style={styles.logo} source={logo}/>
                    </View>
                    <View style={[styles.childrenWrapper, childrenWrapperStyles, mobile && (width || respStyles.wide800)]}>
                        {children}
                    </View>
                </KeyboardAwareScrollView>
            </GlobalGradient>
        );
    }
}

export default withGetScreen(OnboardingBase);
