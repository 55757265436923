import Actions from '../../RouteActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import { SET_SPINNER_VISIBLE, SET_SPINNER_HIDDEN} from '../../constants/actions/appActions';
import * as authActionsConstants from '../../constants/actions/auth';
import { getDeviceFingerprint, setAccessToken } from '../../AsyncStorage';
import _ from 'lodash';
import * as authService from '../../services/authService';
import * as usersService from '../../services/usersService';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';
import baseService from '../../services/baseService';

export function submitForm(formValues, existingUserEmail) {
    return dispatch => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        getDeviceFingerprint().then(deviceFingerprint => {
            authService.signUpThroughPublicJobApp({ ...formValues, 'device_fingerprint': deviceFingerprint }).then(response => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                baseService.addAuthToken(response.data.payload.access_token).then(() => {
                        dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                    if (!existingUserEmail || existingUserEmail == undefined) {
                        dispatch({ type: authActionsConstants.SIGN_IN_SUCCESS, payload: response.data.payload });
                        if (response.data.payload.access_token){
                            setAccessToken(response.data.payload.access_token);
                        }
                        if (response.data.payload.organizations.length > 0){
                            let currentSelectedOrg = { label: response.data.payload.organizations[0].organization.name, value: response.data.payload.organizations[0].organization.name, id: response.data.payload.organizations[0].organization.id, logo: response.data.payload.organizations[0].organization.logo_image_thumb}
                            dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                        }
                        }
                        if (response.data.payload.public_refer_signup) {
                            notification.ref.show({ message: 'You have successfully applied for this job, please see your email to continue.', isError: false });
                            if (response.data.payload.current_incomplete_step === 'create_password') {
                            Actions.resetForgetPassword({ initialValues: {email: response.data.payload.user.email} });
                            }
                            else if (existingUserEmail && existingUserEmail != undefined) {
                            baseService.removeAuthToken().then(()=>{
                                    Actions.signInEmail({ publicReferSignup: true });
                                })
                            }
                        }
                        else {
                            dispatch({ type: authActionsConstants.SET_MAGIC_LINK_DATA, payload: {response_code: 0} });
                            Actions.magicLink({ email: response.data.payload.user.email, referSignup: true });
                        }
                });
            }).catch((response) => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                notification.ref.show({ message: response.data.error, isError: true });
            });
        });
    };
}

export function getUserByEmail(id) {
    return dispatch => {
        usersService.getUserByEmail(id).then((response) => {
            dispatch({ type: authActionsConstants.GET_USER_BY_EMAIL, payload: response.data.payload });
        })
    };
}
