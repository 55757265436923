import * as termsAndConditionsActionsConstants from '../../../../constants/actions/rewardsTermsAndConditionsActions';
import * as rewardsTermsAndConditionsService from '../../../../services/rewardsTermsAndConditionsService';

export function getTermsAndConditions(organizationId) {
  return (dispatch) => {
    dispatch({ type: termsAndConditionsActionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS });
    rewardsTermsAndConditionsService
      .getTermsAndConditions(organizationId)
      .then((response) => {
        dispatch({
          type: termsAndConditionsActionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS_SUCCESS,
          payload: response.data.payload,
        });
      })
      .catch(() => {
        dispatch({ type: termsAndConditionsActionsConstants.GET_REWARDS_TERMS_AND_CONDITIONS_ERROR });
      });
  };
}
