import moment from 'moment';
import { Platform } from 'react-native';
import baseService from './baseService';
import imageTypes from '../constants/imageTypes';

export function uploadImage(file, type) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const data = new FormData();
    data.append('image_type', imageTypes[type]);

    let fileObj = file;

    if(Platform.OS !== 'web') {
      fileObj = {
        name: file.filename ? file.filename : `Image_${moment().unix()}`,
        type: file.mime,
        uri: file.path
      };
    }
    data.append('image', fileObj);

    return baseService.post('/images', data, config);
}

export function getCSVSources() {
  return baseService.get('/csv_sources');
}

export function uploadSourceWithCSV(data) {
  return baseService.post('/users/import_csv_contacts_with_source', data);
}
