import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
import { connect } from 'react-redux';
import Actions from '../../RouteActions';
import styles from './styles';

//components
import Text from '../../components/Text/Text';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SignUpButton from '../../components/SignupButton/SignupButton';
//images
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

import * as organizationsActions from '../../globalActions/organizationsActions';
import { $green } from '../../generalStyles';
import { find as l_find } from 'lodash';
import userRoles from '../../constants/userRoles';
import * as actions from './actions';
import SignUpBase from '../SignUpBase/SignUpBase';
import { formLayout } from '../../generalStyles';
import { withGetScreen } from 'react-native-getscreen';
import * as redirectActions from '../../globalActions/redirectActions';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import InvitationDetails from './InvitationDetails';

const Invitations = ({
    userCurrentStep,
    getOrganizationsInvites,
    redirect,
    createInvitationStep,
    invitations,
    submitInvite,
    invitationsLoading,
    requestAccess,
    currentUser,
    organizationName,
    setLoader,
    ...props
}) => {
    const [invitationData, setInvitationData] = useState([]);

    useEffect(() => {
        if (userCurrentStep != 'invite') {
            redirect();
        }
        getOrganizationsInvites();
    }, []);

    useEffect(() => {
        if (invitations.length) {
            setInvitationData(invitations);
            setLoader(false);
        } else {
            setLoader(true);
        }
    }, [invitations]);

    const submitForm = (e) => {
        e.preventDefault();
        createInvitationStep();
    };

    return (
        <AuthLayout>
            <View style={styles.container}>
                <View style={styles.formWrapper}>
                    <View style={styles.header}>
                        <Text style={styles.heading}>
                            You've been added to the following organizations:
                        </Text>
                    </View>
                    <InvitationDetails invitationData={invitationData} />
                    <View style={styles.buttonWrapper}>
                        <View>
                            <SignUpButton onClick={submitForm}>
                                Next
                            </SignUpButton>
                        </View>
                    </View>
                </View>
            </View>
        </AuthLayout>
    );
};

const mapStateToProps = (state) => {
    return {
        invitations: state.organizationsReducer.userOrganizations,
        invitationsLoading: state.organizationsReducer.userOrganizationsLoading,
        currentUser: state.authReducer.currentUser,
        organizationName: state.organizationsReducer.mostRecentEmployer,
        userCurrentStep: state.stepsReducer.step
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOrganizationsInvites: () => {
            dispatch(organizationsActions.getOrganizationsInvites());
        },
        submitInvite: (organizationName, inviteIndex) => {
            dispatch(
                organizationsActions.submitInvite(
                    organizationName,
                    inviteIndex,
                    'userOrganizations'
                )
            );
        },
        createInvitationStep: () => {
            dispatch(actions.createInvitationStep());
        },
        setLoader: (flag = false) => {
            dispatch(actions.setLoader(flag));
        },
        redirect: () => {
            dispatch(redirectActions.redirect());
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withGetScreen(Invitations));
