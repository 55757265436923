import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { View, Text, TouchableOpacity } from 'react-native';

const RenderMultipleEmails = ({ item }) => {
    const [checkedEmail, setCheckedEmail] = useState();

    useEffect(() => {
        setCheckedEmail(item.email);
    }, [item]);

    const CheckboxCont = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 5,
        marginBottom: 6
    };

    const CheckboxStyles = {
        padding: 0,
        color: '#0FBC71',
        backgroundColor: 'white',
        marginRight: 7,
    };

    const changeCheckedEmail = (newEmail) => {
        setCheckedEmail(newEmail);
        item.email = newEmail;
    };

    return item.emails.map((eachEmail) => (
        <View
            onStartShouldSetResponder={(event) => true}
            onTouchEnd={(e) => {
                e.stopPropagation();
            }}
        >
            <TouchableOpacity
                onPress={() => changeCheckedEmail(eachEmail)}
                style={CheckboxCont}
            >
                <Checkbox
                    style={CheckboxStyles}
                    color="secondary"
                    id={eachEmail}
                    checked={eachEmail === checkedEmail}
                    onChange={() => changeCheckedEmail(eachEmail)}
                />
                <Text>{eachEmail}</Text>
            </TouchableOpacity>
        </View>
    ));
};

export default RenderMultipleEmails;
