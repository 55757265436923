import React, { Component } from 'react';
import Spinner from 'react-native-loading-spinner-overlay';

import {
    View,
    TouchableOpacity
} from 'react-native';
import Actions from '../../RouteActions';

//components
import OnboardingBase from '../../components/OnboardingBase/OnboardingBase';
import Text from '../../components/Text/Text';
import * as generalStyles from '../../generalStyles';

//styles
import style from './styles';

class SignIn extends Component {
    render() {
        let { signInLoading, resetPassword, signupProcess, setPassword, signedIn, publicReferSignup } = this.props;
        if (publicReferSignup) {
            signedIn = false
        }
        return (
            <OnboardingBase
                hasBackButton
                childrenWrapperStyles={{ justifyContent: 'space-between' }}>
                {signupProcess &&
                    <View style={[generalStyles.whiteBlock]}>
                        <Text style={style.pageTitle}>Congrats!</Text>
                        <Text style={{ paddingVertical: 15 }}>
                            You've received an exclusive invite to Boon!
                        </Text>
                        {this.props.children}
                    </View>
                }
                {resetPassword &&
                    <View style={[generalStyles.whiteBlock]}>
                        <Text style={style.pageTitle}>Forgot your password?</Text>
                        <Text style={{ paddingVertical: 15 }}>
                            Don't worry. Tell us your email address and we'll get you back on track in no time.
                        </Text>
                        {this.props.children}
                    </View>
                }
                {setPassword &&
                    <View style={[generalStyles.whiteBlock]}>
                        <Text style={style.pageTitle}>Set your password</Text>
                        {this.props.children}
                    </View>
                }
                {signedIn &&
                    <View style={[generalStyles.whiteBlock]}>
                        <Text style={style.pageTitle}>Sign in</Text>
                        {this.props.children}
                    </View>
                }
                {publicReferSignup &&
                    <View style={[generalStyles.whiteBlock]}>
                        <Text style={style.pageTitle}>Success!</Text>
                        <Text style={{ paddingVertical: 15 }}>
                            Your application has been submitted, login to see if you have any other pending referrals.
                        </Text>
                        {this.props.children}
                    </View>
                }
                <View style={style.newToBoonWrapper}>
                    <Text style={style.newToBoonText}>New to Boon?</Text>
                    <TouchableOpacity onPress={() => Actions.base()}>
                        <Text style={style.newToBoonButtonText}>Sign up</Text>
                    </TouchableOpacity>
                </View>
                <Spinner visible={signInLoading} />
            </OnboardingBase>
        );
    }
}

export default SignIn;
