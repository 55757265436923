import React, { useRef, useEffect, useState } from 'react';
import { View, Image, TouchableOpacity, Keyboard } from 'react-native';

import { apiSeverUrl } from '../../services/baseService';

import googleIcon from '../../assets/svg/google-icon1.svg';

import styles from './styles';

const GoogleSSOButton = () => {
    const googleSsoOauth =() => {
        let form = document.createElement('form');
        form.method = 'POST';
        form.action = `${apiSeverUrl}/auth/google_oauth2?sso_signup=${true}`;
        document.body.appendChild(form);
        form.submit();
    }
    return (
          <TouchableOpacity
            style={styles.iconButton}
            onClick={() => googleSsoOauth()}
        >
            <Image
                source={googleIcon}
                resizeMode="contain"
                style={styles.ssoButtonStyle}
            />
          </TouchableOpacity>            
  );
};

export default GoogleSSOButton;
