import * as connectionsActionsConstants from '../constants/actions/connectionsActions';
import {findIndex} from 'lodash-es';

const initialState = {
    networkLoading: false,
    network: [],
    isLastPage: false,
    selectedUser: {},
    selectedUserLoading: false,
    requestedConnectionId: null
};

const connectionsReducer = (state = initialState, action) => {
    let network = [];
    let changedConnectionIndex = null;
    switch (action.type) {
        case connectionsActionsConstants.GET_USER_CONNECTIONS:
            return {
                ...state,
                networkLoading: !action.payload.isPagination,
                isLastPage: false,
                network: action.payload.isPagination ? state.network : [],
            };
        case connectionsActionsConstants.GET_USER_CONNECTIONS_SUCCESS:
            network = action.payload.isPagination ? state.network.concat(action.payload.network) : action.payload.network;
            return {
                ...state,
                network: network,
                isLastPage: action.payload.network.length !== 50,
                networkLoading: false
            };
        case connectionsActionsConstants.GET_USER_CONNECTIONS_ERROR:
            return {
                ...state,
                networkLoading: false
            };
        case connectionsActionsConstants.CLEAR_USER_CONNECTIONS:
            return {
                ...state,
                network: []
            };
        case connectionsActionsConstants.GET_USER_BY_ID:
            return {
                ...state,
                selectedUser: {},
                selectedUserLoading: true
            };
        case connectionsActionsConstants.GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                selectedUser: action.payload,
                selectedUserLoading: false
            };
        case connectionsActionsConstants.GET_USER_BY_ID_ERROR:
            return {
                ...state,
                selectedUserLoading: false
            };
        case connectionsActionsConstants.REQUEST_CONNECTION_SUCCESS:
            network = state.network.slice();

            changedConnectionIndex = findIndex(network, contact => {
                return (contact.to_user_id === action.payload) && contact.status
            });

            if (changedConnectionIndex !== -1) {
                network[changedConnectionIndex] = {...network[changedConnectionIndex], status: 'requested'};
            }

            return {
                ...state,
                network,
                selectedUser: state.selectedUser.id ? {...state.selectedUser, status: 'requested'} : {}
            };
        case connectionsActionsConstants.ACCEPT_CONNECTION_SUCCESS:
            network = state.network.slice();

            changedConnectionIndex = findIndex(network, contact => {
                return (contact.id === action.payload) && contact.status
            });

            if (changedConnectionIndex !== -1) {
                network[changedConnectionIndex] = {...network[changedConnectionIndex], status: 'connected'};
            }

            return {
                ...state,
                network,
                selectedUser: state.selectedUser.id ? {...state.selectedUser, status: 'connected'} : {}
            };
        default:
            return state;
    }
};

export default connectionsReducer;
