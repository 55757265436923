import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    elementWrapper: {
        backgroundColor: $green,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    boonLogo: {
        position: 'absolute',
        width: 100,
        height: 41,
        top: 0,
        left: 0,
        marginTop: 30,
        marginLeft: 40
    },
    contentCont: {
        backgroundColor: 'white',
        borderRadius: 4,
        paddingTop: 60,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
        width: 375
    },
    heading: {
        fontWeight: 600,
        fontSize: 20,
        color: '#0FBC71',
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: 'center'
    },
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        fontWeight: 400
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green,
        marginTop: 30,
        marginBottom: 25
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2',
        marginTop: 30,
        marginBottom: 25
    },
    inputWrapper: {
        marginTop: 20
    },
    mobileBoonLogo: {
        position: 'absolute',
        width: 100,
        height: 41,
        top: 0,
        marginTop: 30
    },
    confirmPasswordWrapper: {
        marginTop: 12
    },
    inputError: {
        borderWidth: 2,
        borderBottomWidth: 2,
        borderColor: '#EA5506',
        borderBottomColor: '#EA5506'
    },
    errorMessageCont: {
        width: '100%'
    },
    errorMessage: {
        fontSize: 12,
        color: '#EA5506',
        textAlign: 'left',
        marginLeft: 13,
        marginTop: 5
    },
});
