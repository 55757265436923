import React, { Component } from 'react';
import {connect} from 'react-redux';
import Alert from '../../wrappers/Alert/Alert';
import { View, Image, Platform } from 'react-native';
import { reduxForm, Field } from 'redux-form';

//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import FormInput from '../../components/FormInput/FormInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';

//images
import styles from './styles';

//actions
import * as actions from './actions';
import { closeDrawer, openSettingsDrawer } from '../../DrawerActions.js';

//utils
import {notification} from '../../helperFunctions';

class ChangePassword extends Component {
    render() {
        const { handleSubmit, goBack } = this.props;
        const web = Platform.OS === 'web';

        return (
            <View style={[styles.formWrapper, !web && {marginTop: 30}]}>
                <DrawerHeader title='Change Password' />
                <View>
                    <Field
                        placeholder="New password"
                        name={'password'}
                        component={FormInput}
                        wrapperStyle={styles.field}
                        secureTextEntry
                    />
                    <Field
                        placeholder="Confirm new password"
                        name={'confirm_password'}
                        component={FormInput}
                        wrapperStyle={styles.field}
                        secureTextEntry
                    />
                    <PrimaryButton onPress={handleSubmit} style={styles.submitButton}>
                        Save new password
                    </PrimaryButton>

                    <PrimaryButton onPress={goBack} style={styles.backButton}>
                        Go Back
                    </PrimaryButton>
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: data => {
            if (data.password !== data.confirm_password) {
                notification.ref.show({message: 'Passwords are not the same', isError: true});
            } else {
                dispatch(actions.changePassword(data.password));
            }
        },
        goBack: () => {
            dispatch(closeDrawer());
            dispatch(openSettingsDrawer());
        }
    }
};

ChangePassword = reduxForm({ form: 'changePassword' })(ChangePassword);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
