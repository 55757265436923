import React, { Component } from 'react';
import {connect} from 'react-redux';
import {View, ScrollView, RefreshControl} from 'react-native';

import styles from './styles';

//components
import Text from '../../../../components/Text/Text';

//utils
import {normalizePrice, numberWithCommas} from '../../../../helperFunctions';

//actions
import * as actions from './actions';

class Stats extends Component {
    render() {
        const {
            currentUserStats, getCurrentUserStats,
            currentUserStatsLoading, withoutOrganization
        } = this.props;

        if (!currentUserStats.referrals)
            return null

        return (
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={currentUserStatsLoading}
                        onRefresh={getCurrentUserStats}
                    />
                }
                style={styles.content}
            >
                <View>
                    <View style={styles.row}>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>REFERRALS SENT</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}
                                adjustsFontSizeToFit>
                                {currentUserStats.referrals.month}
                            </Text>
                        </View>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>HIRES</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}
                                adjustsFontSizeToFit>
                                {currentUserStats.referrals.year}
                            </Text>
                        </View>
                    </View>
                </View>
                {currentUserStats.earnings &&
                <View>
                    <View style={styles.row}>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>POTENTIAL EARNINGS</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}
                                adjustsFontSizeToFit>
                                ${numberWithCommas( currentUserStats.earnings.month)}
                            </Text>
                        </View>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>EARNED/BANKED</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}
                                adjustsFontSizeToFit>
                                ${numberWithCommas(currentUserStats.earnings.year)}</Text>
                        </View>
                    </View>
                </View>
                }
                {currentUserStats.organizations && !withoutOrganization &&
                <View>
                    <Text style={styles.categoryText}>ORGANIZATIONS</Text>
                    <View style={styles.row}>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>FOLLOWING</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}
                                adjustsFontSizeToFit>
                                {currentUserStats.organizations.following}
                            </Text>
                        </View>
                        <View style={styles.element}>
                            <Text style={styles.elementTitle}>MEMBER OF</Text>
                            <Text
                                style={styles.elementValue}
                                numberOfLines={1}>
                                {currentUserStats.organizations.member_of}
                            </Text>
                        </View>
                    </View>
                </View>
                }
            </ScrollView>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUserStats: state.authReducer.currentUserStats,
        currentUserStatsLoading: state.authReducer.currentUserStatsLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentUserStats: () => {
            dispatch(actions.getCurrentUserStats());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
