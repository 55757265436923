import AsyncStorage from '@callstack/async-storage';
import Cookies from 'js-cookie';
import moment from 'moment/moment';
import DeviceInfo from 'react-native-device-info';

class AccessTokenPubSub {
    static subscribers = [];

    static addSubscriber(f) {
        this.subscribers.push(f);
    }

    static callSubscribers(args) {
        this.subscribers.forEach((item, _) => item(args));
    }
}

export const getAccessToken = async () => {
    try {
        return await AsyncStorage.getItem('@AsyncStore:accessToken');
    } catch (error) {
        return false;
    }
};

export const setAccessToken = async (accessToken) => {
    try {
        let ret = await AsyncStorage.setItem('@AsyncStore:accessToken', accessToken);
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);
        Cookies.set('expirationDate', expirationDate);
        const env = process.env.REACT_APP_ENVIRONMENT || 'development';
        Cookies.set(`X-User-Token-${env}`, accessToken, { domain: env === 'development' ? 'localhost' : '.goboon.co' });
        getDeviceFingerprint().then((deviceFingerprint) => {
            Cookies.set('device_fingerprint', deviceFingerprint, { domain: '.goboon.co' });
        });
        AccessTokenPubSub.callSubscribers(accessToken);
        return ret;
    } catch (error) {
        return false;
    }
};

export const addAccessTokenObserver = async (observer) => {
    AccessTokenPubSub.addSubscriber(observer);
};

export const clearAccessToken = async () => {
    try {
        let ret = await AsyncStorage.removeItem('@AsyncStore:accessToken');
        AccessTokenPubSub.callSubscribers(undefined);
        return ret;
    } catch (error) {
        return false;
    }
};

export const getDeviceFingerprint = async () => {
    try {
        return await AsyncStorage.getItem('@AsyncStore:deviceFingerprint').then(response => {
            if (response) {
                return response;
            } else {
                const deviceId  = DeviceInfo.getDeviceId()
                const userAgent = DeviceInfo.getUserAgent()
                // Fall-back to userAgent for web, as deviceId will be blank on web
                const fingerPrint = `${deviceId !== "" ? deviceId : userAgent}_${moment().unix()}`;
                setDeviceFingerprint(fingerPrint);
                return fingerPrint;
            }
        });
    } catch (error) {
        return false;
    }
};

export const setDeviceFingerprint = async deviceFingerprint => {
    try {
        return await AsyncStorage.setItem('@AsyncStore:deviceFingerprint', deviceFingerprint);
    } catch (error) {
        return false;
    }
};

export const getPushToken = async () => {
    try {
        return await AsyncStorage.getItem('@AsyncStore:pushToken');
    } catch (error) {
        return false;
    }
};

export const setPushToken = async (pushToken) => {
    try {
        return await AsyncStorage.setItem('@AsyncStore:pushToken', pushToken);
    } catch (error) {
        return false;
    }
};

export const checkHidedRecommendations = async () => {
    try {
        return await AsyncStorage.getItem('@AsyncStore:hideRecommendations');
    } catch (error) {
        return false;
    }
};

export const hideRecommendationsForToday = async () => {
    try {
        return await AsyncStorage.setItem('@AsyncStore:hideRecommendations', moment().format('YYYY DD MM mm'));
    } catch (error) {
        return false;
    }
};

export const clearRecommendationsHiding = async () => {
    try {
        return await AsyncStorage.removeItem('@AsyncStore:hideRecommendations');
    } catch (error) {
        return false;
    }
};
