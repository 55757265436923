import { StyleSheet, PixelRatio, Dimensions } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  searchBlock: {
    marginHorizontal: 3,
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: "#fff",
    position: "relative",
    zIndex: 2,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 6,
    borderColor: "#CCCCCC",
    borderWidth: 0.5,
    width: "30%",
  },
  searchIcon: {
    marginRight: 20,
    height: 20,
    width: 20,
    opacity: 0.5,
  },
  searchBlockInputWrapper: {
    position: "relative",
    flex: 1,
  },
  searchBlockInput: {
    fontSize: 16,
    fontFamily: "Roboto-Regular",
    borderBottomColor: "transparent",
    borderBottomWidth: 2,
    paddingVertical: 5,
    width: "88%",
  },
  searchBlockInputActive: {
    borderBottomColor: $green,
  },
  searchBlockClearButton: {
    position: "absolute",
    top: 5,
    right: 0,
  },
  searchBlockClearButtonImage: {
    height: 16,
    width: 16,
  },
  filterButton: {
    position: "absolute",
    left: 20,
    bottom: 30,
    height: 56,
    width: 56,
    borderRadius: 28,
    backgroundColor: $green,
    alignItems: "center",
    justifyContent: "center",
    shadowOffset: { height: 2, width: 0 },
    shadowRadius: 4,
    shadowColor: "#000",
    shadowOpacity: 0.5,
  },
  filterButtonImage: {
    height: 16,
    width: 24,
  },
  gridHalf: {
    width: "100%",
    flexGrow: 1,
    flexDirection: "row",
  },
  gridHalfMobile: {
    width: "100%",
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 100,
  },
  googleWrapper: {
    width: "auto",
    alignSelf: "center",
    marginHorizontal: 10,
    padding: 15,
    backgroundColor: "transparent",
    alignItems: "center",
    justifyContent: "center",
  },

  googleTextWrapper: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },

  googleText: {
    color: $green,
    fontSize: 14,
    fontWeight: "500",
  },
  listHeader: {
    width: "70%",
    backgroundColor: "#ffffff",
    height: 50,
    borderBottomColor: "#cccccc",
    borderBottomWidth: 0.5,
    flexDirection: "row",
    zIndex: -1,
  },
  listHeaderMobile: {
    width: "100%",
    backgroundColor: "#ffffff",
    height: 50,
    borderBottomColor: "#cccccc",
    borderBottomWidth: 0.5,
    flexDirection: "row",
    zIndex: -1,
  },
  contactContainer: {
    marginLeft: 50,
    justifyContent: "center",
    width: "39%",
  },
  contactContainerMobile: {
    marginLeft: 20,
    justifyContent: "center",
    width: Math.round(Dimensions.get("window").width) / 2,
  },
  contact: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#808080",
  },
  employerContainer: {
    justifyContent: "center",
    width: "25%",
  },
  employer: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#808080",
  },
  infoContainer: {
    justifyContent: "center",
  },
  infoContainerMobile: {
    justifyContent: "center",
    paddingLeft: Math.round(Dimensions.get("window").width) / 6,
    // borderColor: 'black',
    // borderWidth: 1,
  },
  info: {
    fontWeight: "bold",
    fontSize: 12,
    color: "#808080",
  },
  buttonsContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    width: "39.5%",
  },
  buttonsContainerMobile: {
    justifyContent: "space-between",
    flexDirection: "row",
    width: "17%",
  },
  addContacts: {
    borderRadius: 4,
    height: 50,
    width: 150,
    backgroundColor: "#0FBC71",
    marginLeft: 15,
  },
  filter: {
    borderRadius: 4,
    height: 35,
    width: 150,
    marginTop: 13,
    backgroundColor: "#D1D8DC",
    flexDirection: "row-reverse",
    justifyContent: "space-evenly",
  },
  emptyTitle: {
    fontSize: 16,
    fontWeight: "500",
    color: "#505050",
    marginTop: 28,
    marginBottom: 5,
  },
  emptyImage: {
    marginTop: 50,
    width: 150,
    height: 150,
  },
  searchBlockMobile: {
    marginHorizontal: 3,
    marginBottom: 10,
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: "#fff",
    position: "relative",
    zIndex: 2,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 6,
    borderColor: "#CCCCCC",
    borderWidth: 0.5,
    width: "100%",
  },
  dropdownStyleMobile: {
    backgroundColor: "#D1D8DC",
    paddingRight: 10,
    borderRadius: 5,
    fontSize: 16,
    color: "#505050",
    fontWeight: "500",
  },
  shareIconMobile: {
    width: 25,
    height: 25,
    marginTop: 12,
    marginLeft: 22,
    marginBottom: 12,
  },
  searchIconMobile: {
    width: 20,
    height: 20,
    marginTop: 15,
    marginLeft: 22,
    marginBottom: 12,
  },
});
