import * as connectionsActionsConstants from '../../constants/actions/connectionsActions';
import * as connectionsService from '../../services/connectionsService';

export function getUserById(userId) {
    return (dispatch) => {
        dispatch({type: connectionsActionsConstants.GET_USER_BY_ID});
        connectionsService.getUserById(userId).then(response => {
            let additionalData = {
                user_connections_count: response.data.payload.user_connections_count,
                user_referrals_sent_count: response.data.payload.user_referrals_sent_count,
                user_referrals_received_count: response.data.payload.user_referrals_received_count,
                user_companies_count: response.data.payload.user_companies_count
            };
            if (response.data.payload.connection) {
                additionalData = {
                    ...additionalData,
                    status: response.data.payload.connection.status,
                    connectionId: response.data.payload.connection.id
                }
            }
            dispatch({type: connectionsActionsConstants.GET_USER_BY_ID_SUCCESS, payload: {...response.data.payload.user, ...additionalData}});
        }).catch(() => {
            dispatch({type: connectionsActionsConstants.GET_USER_CONNECTIONS_ERROR});
        });
    };
}
