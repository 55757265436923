import * as appActionsConstants from '../constants/actions/appActions';

const initialState = {
    spinnerVisible: false,
    notify: false
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case appActionsConstants.SET_SPINNER_VISIBLE:
            return {
                ...state,
                spinnerVisible: true
            };
        case appActionsConstants.SET_SPINNER_HIDDEN:
            return {
                ...state,
                spinnerVisible: false
            };
        case appActionsConstants.SET_NOTIFY_TRUE:
            return {
                ...state,
                notify: true
            };
        case appActionsConstants.SET_NOTIFY_FALSE:
            return {
                ...state,
                notify: false
            };
        default:
            return state;
    }
};

export default appReducer;
