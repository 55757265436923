import { PixelRatio } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { $background } from '../../../../generalStyles';

export default EStyleSheet.create({
    manageAccountsWrapper: {
        width: "100%",
        display: 'flex',
        padding: 20,
        maxWidth: 1600 / PixelRatio.get(),
    },
    manageAccounts: {
        backgroundColor: 'white'
    },
    syncNetworkContainer: {
        padding: 20
    },
    syncNetworkText: {
        paddingVertical: 20,
        fontSize: 22,
        borderTopWidth: 0.5,
        borderColor: $background
    },
    buttonsWrapper: {
        paddingLeft: '25%',
        paddingRight: '25%',
    },
    icon: {
        height: 25,
        width: 25,
        marginVertical: 16,
        marginRight: 32
    },
    elementTitle: {
        fontSize: 16,
        color: '#999999',
    },
    elementTitleConnected: {
        color: '#000000'
    },
    button: {
        backgroundColor: 'rgb(153, 153, 153);',
        width: '20%',
        marginTop: '5%',
        marginBottom: '5%',
    },
    disconnectButton: {
        backgroundColor: '#0FBC71',
    },
    mobileButton: {
        width: '40%',
    }
});
