export const GET_ACTIVITY_LIST = 'GET_ACTIVITY_LIST';
export const GET_ACTIVITY_LIST_SUCCESS = 'GET_ACTIVITY_LIST_SUCCESS';
export const GET_ACTIVITY_LIST_ERROR = 'GET_ACTIVITY_LIST_ERROR';

export const GET_LEADER_BOARD_LIST = 'GET_LEADER_BOARD_LIST';
export const GET_LEADER_BOARD_LIST_SUCCESS = 'GET_LEADER_BOARD_LIST_SUCCESS';
export const GET_LEADER_BOARD_LIST_ERROR = 'GET_LEADER_BOARD_LIST_ERROR';

export const LIKE_ACTIVITY = 'LIKE_ACTIVITY';
export const LIKE_ACTIVITY_SUCCESS = 'LIKE_ACTIVITY_SUCCESS';
export const LIKE_ACTIVITY_ERROR = 'LIKE_ACTIVITY_ERROR';

export const UNLIKE_ACTIVITY = 'UNLIKE_ACTIVITY';
export const UNLIKE_ACTIVITY_SUCCESS = 'UNLIKE_ACTIVITY_SUCCESS';
export const UNLIKE_ACTIVITY_ERROR = 'UNLIKE_ACTIVITY_ERROR';

export const CHANGE_SELECTED_ORGANIZATION_HOME = 'CHANGE_SELECTED_ORGANIZATION_HOME';

export const PASS_RECOMMENDATION = 'PASS_RECOMMENDATION';
export const PASS_RECOMMENDATION_SUCCESS = 'PASS_RECOMMENDATION_SUCCESS';
export const PASS_RECOMMENDATION_ERROR = 'PASS_RECOMMENDATION_ERROR';

export const HIDE_RECOMMENDATIONS = 'HIDE_RECOMMENDATIONS';