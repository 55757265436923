import {
    GET_NUMBER_CONFIRMATION,
    GET_NUMBER_CONFIRMATION_SUCCESS
} from '../../constants/actions/auth';

import * as authService from '../../services/authService';
import {notification}   from '../../helperFunctions';

export function getNumberConfirmation(formData, isResend) {
    return dispatch => {
        authService.getNumberConfirmation(formData).then(() => {
            dispatch({type: GET_NUMBER_CONFIRMATION_SUCCESS, payload: formData});
            notification.ref.show({message: 'Confirmation code sent'});
        });
    };
}
