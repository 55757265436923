import { View } from 'react-native';
import React, { Component } from 'react';
import {connect} from 'react-redux';

//components
import UserProfile from '../../../../components/UserProfile/UserProfile';

//utils
import { getUserFormParams } from '../../../../helperFunctions';

// Styles
import styles from './styles';


class Profile extends Component {
    render() {
        const { currentUser } = this.props;

        return (
            <View style={styles.profileWrapper}>
                <View style={styles.profile}>
                    <UserProfile
                        selectedUser={currentUser}
                        userFormParams={getUserFormParams(currentUser)}
                    />
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
