import React, { Component } from 'react';
import {
    View,
    TouchableOpacity, Keyboard
} from 'react-native';
import { Field, reduxForm } from 'redux-form';
import Actions              from '../../RouteActions';
import {connect}            from 'react-redux';

//components
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput     from '../../components/FormInput/FormInput';
import Text          from '../../components/Text/Text';
import SignIn        from '../../components/SignIn/SignIn';

//styles
import sighInStyles from '../../components/SignIn/styles';
import styles       from './styles';

//actions
import * as actions from '../SignInEmail/actions';
import { redirectToCurrentStep } from '../../services/stepsService';

//util
import {notification} from '../../helperFunctions';
import { getAccessToken } from '../../AsyncStorage';

class SignInPassword extends Component {
    constructor() {
        super();
        this.state = {
            isSubmitting: false
        }
    }
    componentDidMount() {
        const { initialValues, onSubmit, step } = this.props;

        if (step && !initialValues.confirm_email_param ) {
            redirectToCurrentStep(step);
        }

        if (initialValues && initialValues.magic_token) {
            this.setState({ isSubmitting: true });
            const params = initialValues.magic_token && initialValues.public_job_application ? { public_job_application: true } : {}
            onSubmit(initialValues, params);

            // getAccessToken().then(token=>{
            //     console.log({abc: initialValues, token: token })

            //     if(!token){
            //         const params = initialValues.magic_token && initialValues.public_job_application ? { public_job_application: true } : {}
            //         onSubmit(initialValues, params);
            //     }
            // });
        }
    }

    resetPassword = () => {
        const { initialValues } = this.props;
        const email = initialValues.email;

        if ( email && email.length >0) {
            Actions.resetPassword({email: email.replace(' ', '+')})
        }
        else {
            notification.ref.show({message: 'Please submit email first.', isError: true});
        }
    }

    render() {
        const { handleSubmit, signInLoading, formValues, initialValues } = this.props;
        const { isSubmitting } = this.state;

        if (isSubmitting) {
            return <h1>Signing you in...</h1>;
        }

        if (!initialValues || !initialValues.email) return Actions.pop();

        return (
            <SignIn signInLoading={signInLoading} signedIn={true}>
                <Field
                    placeholder="Password"
                    name={'password'}
                    component={FormInput}
                    wrapperStyle={sighInStyles.inputWrapper}
                    autoFocus={true}
                    secureTextEntry
                    onSubmitEditing={handleSubmit}
                />
                <PrimaryButton onPress={handleSubmit}>
                    Continue
                </PrimaryButton>
                <View style={styles.cantRememberWrapper}>
                    <Text style={styles.cantRememberText}>
                        Can’t remember?
                    </Text>
                    <TouchableOpacity onPress={() => Actions.magicLink({email: formValues.email})}>
                        <Text style={styles.cantRememberButtonText}>
                            Get a magic link
                        </Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.cantRememberWrapper}>
                    <Text style={styles.cantRememberText}>
                        Forgot password?
                    </Text>
                    <TouchableOpacity onPress={() => this.resetPassword()}>
                        <Text style={styles.cantRememberButtonText}>
                            Reset your password
                        </Text>
                    </TouchableOpacity>
                </View>
            </SignIn>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.signInPassword && state.form.signInPassword.values ? state.form.signInPassword.values : {},
        signInLoading: state.authReducer.signInLoading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: (values, params) => {
            Keyboard.dismiss();
            if (!values.password && !values.magic_token) {
                notification.ref.show({message: 'Password is required', isError: true});
            } else {
                dispatch(actions.signIn(values, params));
            }
        }
    };
};

SignInPassword = reduxForm({ form: 'signInPassword' })(SignInPassword);

export default connect(mapStateToProps, mapDispatchToProps)(SignInPassword);
