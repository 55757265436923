import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as JsSearch from 'js-search';
import {
    View,
    TextInput,
    Platform,
    Image
} from 'react-native';

import * as actions from '../../../actions';

//images
import search from '../../../../../assets/img/search_icon.png';

//styles
import styles from './styles';

class TrackerSearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.initialValue ? props.initialValue : ''
        };

        this.debounce = null;

        this.onInputChange = this.onInputChange.bind(this);
    }

    onInputChange(newValue) {
        const { trackerListsData, setFilteredData, direction, getJobsTracker } = this.props;
        this.setState({
            inputValue: newValue
        });
        let filteredData = JSON.parse(JSON.stringify(trackerListsData))
        clearTimeout(this.debounce)
        if (newValue == '' || newValue.length == 0) {
            getJobsTracker({ direction: direction, status: 'new' })
            getJobsTracker({ direction: direction, status: 'applied' })
            getJobsTracker({ direction: direction, status: 'interview' })
            getJobsTracker({ direction: direction, status: 'archived' })
            return
        }


        this.debounce = setTimeout(() => {

            var search = new JsSearch.Search('id');
            search.addIndex('title');
            search.addIndex(['referrals', 0, 'full_name']);
            search.addIndex('organization_name')
            search.addDocuments(trackerListsData.appliedList)
            filteredData.appliedList = search.search(newValue);


            var search1 = new JsSearch.Search('id');
            search1.addIndex('title');
            search1.addIndex(['referrals', 0, 'full_name']);
            search1.addIndex('organization_name')
            search1.addDocuments(trackerListsData.newList)
            filteredData.newList = search1.search(newValue);

            var search2 = new JsSearch.Search('id');
            search2.addIndex('title');
            search2.addIndex(['referrals', 0, 'full_name']);
            search2.addIndex('organization_name')
            search2.addDocuments(trackerListsData.interviewList)
            filteredData.interviewList = search2.search(newValue);

            var search3 = new JsSearch.Search('id');
            search3.addIndex('title');
            search3.addIndex(['referrals', 0, 'full_name']);
            search3.addIndex('organization_name')
            search3.addDocuments(trackerListsData.archivedList)
            filteredData.archivedList = search3.search(newValue);

            setFilteredData(filteredData)
        }, 1200);
    }


    render() {
        const { inputValue } = this.state;
        let placeholderValue = ''
        if (this.props.direction === 'my')
            placeholderValue = 'your'
        else
            placeholderValue = 'sent'
        const web = Platform.OS == 'web';

        return (
            <View style={styles.wrapper}>
                <View style={styles.searchBlock}>
                    <View style={[web && styles.searchBlockInputWrapper, { flexDirection: 'row' }]}>
                        <Image source={search} style={[styles.searchStyle]} />
                        <TextInput style={styles.searchBlockInput}
                            onChangeText={this.onInputChange}
                            value={inputValue}
                            placeholder={`Search ${placeholderValue} jobs`} />
                    </View>
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        trackerListsData: state.trackerReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFilteredData: filteredData => {
            dispatch(actions.setFilteredData(filteredData));
        },
        getJobsTracker: params => {
            dispatch(actions.getJobsTracker(params));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackerSearchBar);
