import React from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import Actions from '../../RouteActions';

//components
import AutoSuggestComponent from '../../components/AutoSuggestComponent/AutoSuggestComponent';
import Text from '../../components/Text/Text';
import JobListElement from '../../components/JobListElement/JobListElement';
import OrganizationListElement from '../../components/OrganizationListElement/OrganizationListElement';

//styles
import styles from './styles';
import { listElement } from '../../generalStyles';
import ContactListElement from '../../components/ContactListElement/ContactListElement';

//utils
import { extendWithConnections } from '../../helperFunctions';

//constants
const typeTitles = {
    organizations: 'ORGANIZATIONS',
    jobs: 'JOBS',
    users: 'USERS'
};

const Organizations = ({ data }) => {
    return (
        <>
            {data.map((org) => (
                <OrganizationListElement
                    onPress={() =>
                        Actions.goEmployerProfile({
                            employerProfileLink: org.employer_profile_link
                        })
                    }
                    key={`org_${org.id}`}
                    organization={org}
                />
            ))}
        </>
    );
};

const Jobs = ({ data }) => {
    return (
        <>
            {data.map((job) => (
                <JobListElement
                    key={job.id}
                    job={job}
                    listItem={true}
                    onPress={() => Actions.browseJobDetail({ id: job.id })}
                />
            ))}
        </>
    );
};

const Users = ({ data }) => {
    const usersData = extendWithConnections(
        data.user_contacts,
        data.connections
    );

    return (
        <>
            {usersData.map((user) => (
                <ContactListElement user={user} key={user.id} />
            ))}
        </>
    );
};

const GlobalSearch = (props) => {
    const { searchModel, placeholder, filters, onChangeQuery, hideNoResults, width, addMarginRight, addMarginBottom } =
        props;

    const renderSuggestElement = ({ item }) => {
        if (!searchModel) {
            return (
                <View style={listElement.groupWrapper}>
                    <Text style={listElement.groupTitle}>
                        {typeTitles[item.type]}
                    </Text>
                    {item.type === 'organizations' ? (
                        <Organizations data={item.data} />
                    ) : (
                        <>
                            {item.type === 'jobs' ? (
                                <Jobs data={item.data} />
                            ) : (
                                <Users data={item.data} />
                            )}
                        </>
                    )}
                </View>
            );
        }
    };

    return (
        <View style={{...styles.wrapperStyle, width: width || '100%', marginBottom: addMarginBottom ? '15px': 0 }}>
            <AutoSuggestComponent
                suggestElement={renderSuggestElement}
                searchModel={searchModel}
                service="globalSearch"
                suggestionsKey="results"
                keyField="type"
                wrapperStyle={styles.wrappedStyle}
                searchType="globalSearch"
                placeholder={placeholder}
                filters={filters}
                onChangeQuery={onChangeQuery}
                hideNoResults={hideNoResults}
            />
        </View>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
