import React, {Component} from 'react';
import {
    TouchableOpacity,
    Image
} from 'react-native';

import Actions from '../../RouteActions'


//components

import chevronWhite from '../../assets/img/chevron_left_white.png';
import chevronGrey from '../../assets/img/chevron_left_grey.png';

//styles
import {dashboardButton} from '../../generalStyles';

export default class BackButton extends Component {
    render() {
        const { grey, style, onPress, skipNavOnBackPress } = this.props;

        const img = grey ? chevronGrey : chevronWhite;

        return (
            <TouchableOpacity
                onPress={() => {
                    if (onPress) {
                        return onPress()
                    }
                    if (!skipNavOnBackPress) Actions.pop();
                }}
                style={[dashboardButton.button, style]}
            >
                <Image source={img} style={dashboardButton.image} />
            </TouchableOpacity>
        );
    }
}
