import React, { useMemo } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import moment from 'moment';
import Actions from '../../RouteActions';

//styles
import styles from '../../screens/GlobalSearch/styles';
import { jobListElement, common as commonStyles } from '../../generalStyles';

//components
import Text from '../Text/Text';
import ListElementButton from '../ListElementButton/ListElementButton';

//utils
import { CURRENCIES } from '../../constants/currenciesConstants';
import { locationObjToStr, normalizePrice } from '../../helperFunctions';

//images
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.svg';
import peopleGreyIcon from '../../assets/svg/people-grey.svg';
import peopleIcon from '../../assets/svg/people.svg';
import winnerCupSvg from '../../assets/svg/winner-cup.svg';

const DepartmentListElement = (props) => {
  const { department, onPress, currentDepartmentId, isLast, showViewJob, refCreatedAt, listItem, lineStyles, page } =
    props;
  const image = department.logo_image_url ? { uri: department.logo_image_url } : defaultOrgAvatar;
  let style = [jobListElement.listItem, page === 'refer' ? lineStyles : jobListElement.elementWrapperBackground];
  let activeClassName = '';
  if (listItem) {
    const activeJob = currentDepartmentId === department.uuid;
    style = activeJob ? [jobListElement.listItem, jobListElement.activeListElement] : jobListElement.listItem;
    if (activeJob) {
      activeClassName = 'job-list-active';
      setTimeout(() => {
        const activeListItem = document.querySelector('.job-list-active');
        if (activeListItem && activeListItem.scrollIntoViewIfNeeded) {
          activeListItem.scrollIntoViewIfNeeded();
        }
      }, 500);
    }
  }

  const renderRewards = useMemo(() => {
    if (!department || !department.reward_templates) return null;

    const { currency_id = 'USD', experiential_reward = '', monetary_reward = 0 } = department.reward_templates;

    if ((experiential_reward.length || monetary_reward) > 0) {
      return (
        <>
          <View style={jobListElement.hr}></View>
          <View style={jobListElement.referralRewardsWrapper}>
            <View>
              <Image style={jobListElement.winnerIcon} source={winnerCupSvg} resizeMode="contain" />
            </View>
            {monetary_reward > 0 && (
              <View style={[jobListElement.referralReward, jobListElement.referralRewardPrice]}>
                <Text style={jobListElement.referralRewardText}>
                  {CURRENCIES[currency_id]}
                  {monetary_reward}
                </Text>
              </View>
            )}
            {experiential_reward.length > 0 && (
              <View style={commonStyles.flex1}>
                <View style={jobListElement.referralReward}>
                  <Text style={[jobListElement.referralRewardText, commonStyles.textEllipsis]}>
                    {experiential_reward}
                  </Text>
                </View>
              </View>
            )}
          </View>
        </>
      );
    }
    return null;
  }, [department.reward_templates]);

  return (
    <TouchableOpacity style={[style, { marginBottom: 10 }]} className={activeClassName} onPress={onPress}>
      <View style={jobListElement.listItemBody}>
        <TouchableOpacity
          style={jobListElement.imageWrapper}
          onPress={() => {
            Actions.goEmployerProfile({
              employerProfileLink: department.employer_profile_link,
            });
          }}
        >
          <Image style={jobListElement.image} source={image} resizeMode="contain" />
        </TouchableOpacity>
        <View style={[commonStyles.row, commonStyles.flex1]}>
          <View style={[jobListElement.jobInfoWrapper]}>
            {department.title && (
              <Text style={[jobListElement.title, styles.jobTitle]} numberOfLines={1}>
                {department.title}
                <Image
                  style={{
                    width: 13,
                    height: 12,
                    marginLeft: 8,
                    top: 1,
                  }}
                  source={peopleIcon}
                  resizeMode="contain"
                />
              </Text>
            )}
            {Boolean(department.company_name) && (
              <TouchableOpacity
                style={commonStyles.linkWrapper}
                onPress={() => {
                  Actions.goEmployerProfile({
                    employerProfileLink: department.employer_profile_link,
                  });
                }}
              >
                <Text style={{ ...jobListElement.description, ...jobListElement.organization_name }}>
                  {department.company_name}
                </Text>
              </TouchableOpacity>
            )}
            {Boolean(department.location) && (
              <View style={commonStyles.linkWrapper}>
                <Text style={jobListElement.description} numberOfLines={1}>
                  <span style={{ color: '#959595' }}>{locationObjToStr(department.location).slice(0, 34)}</span>
                </Text>
              </View>
            )}
            {Boolean(department.is_salary_range_public && department.min_salary && department.max_salary) && (
              <Text
                style={[
                  jobListElement.description,
                  {
                    fontSize: 12,
                    color: '#959595',
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'inline-block',
                  },
                ]}
                numberOfLines={1}
              >
                <Text>
                  {department.currency}
                  <Text>{department.min_salary}</Text>
                </Text>
                {' \u2013 '}
                <Text>
                  {department.currency}
                  <Text>{department.max_salary}</Text>
                </Text>
              </Text>
            )}
          </View>
          <View style={[commonStyles.alignItemsEnd, commonStyles.justifyContentEnd]}>
            {showViewJob && (
              <ListElementButton
                onPress={() => Actions.browseJobDetail({ id: department.id })}
                style={styles.viewJobButton}
              >
                View Job
              </ListElementButton>
            )}
            {refCreatedAt && (
              <Text style={jobListElement.timestamp}>referred on {moment(refCreatedAt).format('MMM DD')}</Text>
            )}
          </View>
        </View>
        <View style={jobListElement.imageWrapper}>
          <Image style={jobListElement.waterMark} source={peopleGreyIcon} resizeMode="contain" />
        </View>
      </View>
      <View style={[jobListElement.tagsWrapper, { paddingLeft: 0 }]}>
        {Boolean(department.job_type_name) && (
          <View style={jobListElement.tag}>
            <Text style={jobListElement.tagText}>{department.job_type_name}</Text>
          </View>
        )}
        {Boolean(department.job_experience_name) && (
          <View style={jobListElement.tag}>
            <Text style={jobListElement.tagText}>{department.job_experience_name}</Text>
          </View>
        )}
        {Boolean(department.remote_type) && (
          <View style={jobListElement.tag}>
            <Text style={jobListElement.tagText}>{department.remote_type}</Text>
          </View>
        )}
        {Boolean(department.job_urgency_type_name == 'Urgent') && (
          <View style={jobListElement.tag}>
            <Text style={{ ...jobListElement.tagText, display: 'flex' }}>
              <TimerOutlinedIcon style={{ fontSize: 12, marginRight: 3 }} />
              {department.job_urgency_type_name}
            </Text>
          </View>
        )}
      </View>
      {renderRewards}
    </TouchableOpacity>
  );
};

export default DepartmentListElement;
