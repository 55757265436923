import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formWrapper: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        margin: 'auto',
        paddingTop: 60,
        paddingHorizontal: 20,
        paddingBottom: 40
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    heading: {
        fontWeight: 500,
        fontSize: 20,
        lineHeight: '126%',
        color: '#0FBC71'
    },
    secondaryText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 24,
        letterSpacing: 0.15,
        color: 'rgba(0, 0, 0, 0.6)'
    },
    organizationContainer: {
        maxHeight: 230,
        overflow: 'auto',
        marginVertical: 20
    },
    organizationBlockContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 30,
        marginBottom: 20
    },
    organizationListContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 30,
        marginBottom: 20
    },
    organizationImageContainer: {
        alignItems: 'center',
        justifyContent: 'center'
    },
    organizationtextContainer: {
        paddingLeft: 15
    },
    organizationHeading: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: 19,
        color: '#000000'
    },
    companyAvatar: {
        height: 30,
        width: 30,
        borderRadius: 5,
        marginRight: 10
    }
});
