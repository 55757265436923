import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import {
    View,
    Text,
} from 'react-native';
import Actions from '../../RouteActions';
import { connect } from 'react-redux';
import * as actions from './actions';
import * as jobPostActions from '../JobPost/actions';
import routeActions from '../../RouteActions';
import qs from 'qs';

//component
import SignUpBase from "../SignUpBase/SignUpBase";
import JobSummaryDetails from '../JobPost/JobSummaryDetails/JobSummaryDetails';
import PublicJobApplicationChild from '../PublicJobApplicationChild/PublicJobApplicationChild';

//styles
import styles from './styles';
import { withGetScreen } from 'react-native-getscreen';

//utils
import { notification } from '../../helperFunctions';

class PublicJobApplication extends Component {
    componentDidMount = () => {
        const { getData, referredJobData } = this.props;
        const urlInfo = this.props.location.search.split('?');
        const urlParams = qs.parse(urlInfo[1]);
        if (urlParams.get_refer_data === 'true' && referredJobData.job === undefined) {
            if (urlParams.refer_token) {
                getData(urlParams.refer_token, true);
            } else if (urlParams.refer_token) {
                getData(urlParams.refer_token, false);
            }
        }
        // if (!refer_request || refer_request === undefined) {
        //     routeActions.base();
        // } else {
        //     getUserByEmail(refer_request.id);
        // }
    }

    handleBackButton = () => {
        Actions.pop();
    }

    submitForm = (formValues, file) => {
        const { onSubmit, userExist } = this.props;
        formValues.refer_request_id = this.props.referredJobData.refer_request.id;
        formValues.file = file
        onSubmit(formValues, true);
    }

    render() {
        const mobile = this.props.isMobile();
        const { signUpLoading, refer_request, referredJobData, showCreds } = this.props;
        const subHeading = 'Please fill the following public job application form.'


        return (
            <SignUpBase mobile={mobile} signUpLoading={signUpLoading} subHeading={subHeading}>
                <View>
                    <Text style={styles.title}>Job Summary </Text>
                    <JobSummaryDetails hideMap={true} jobDetails={referredJobData && referredJobData.job} employer={referredJobData && referredJobData.organization}></JobSummaryDetails>
                    <Text style={styles.title}>Application Form </Text>
                    <PublicJobApplicationChild submitForm={this.submitForm} handleBackButton={this.handleBackButton} showCreds={showCreds ? showCreds : true} refer_request={refer_request} referredJobData={referredJobData}></PublicJobApplicationChild>
                </View>
            </SignUpBase>
        );
    }
}

PublicJobApplication = reduxForm({ form: 'publicJobApplicationForm' })(withGetScreen(PublicJobApplication));

const mapStateToProps = state => {
    return {
        formValues: state.form.publicJobApplicationForm && state.form.publicJobApplicationForm.values ? state.form.publicJobApplicationForm.values : {},
        signUpLoading: state.authReducer.signUpLoading,
        userExist: state.authReducer.userExist,
        currentUser: state.authReducer.currentUser,
        referredJobData: state.refersReducer.referredJobData,
        userCurrentStep: state.stepsReducer.step,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('publicJobApplicationForm', field, value));
        },
        onSubmit: (formValues, userExist) => {
            formValues.public_job_application = true
            dispatch(actions.submitForm(formValues, userExist.email));
        },
        // getUserByEmail: (id) => {
        //     dispatch(actions.getUserByEmail(id));
        // },
        getData: (token) => {
            dispatch(jobPostActions.getData(token, false));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(PublicJobApplication));
