import React, { useEffect } from 'react';
import PdfIcon from '../.././assets/svg/pdf-icon.jsx';

const ReferStrengthDetails = ({
  strengthQuestions: {
    skills,
    candidateRating,
    workWithClient,
    openToNewOppertunities,
    isDiversityHire,
    filesUploaded,
    filesUploadedLinks,
    userLinksArray,
    licenseAvailable,
    knowThePerson,
    isGoodFit,
  },
  strengthQuestionsSettings: {
    attach_file,
    attach_url,
    diversity_hire,
    know_the_person_level,
    open_for_new_opportunities_status,
    worked_with_person,
    how_good_are_they,
    license_available,
    know_the_person_level_radio,
    why_are_they_good_fit,
  },
}) => {
  const userFiles = [...filesUploaded, ...filesUploadedLinks];

  return (
    <div className="Strengthen-refer-form-container ">
      <div className="question-text">
        {attach_file && userFiles.length > 0 && (
          <span className="pdf-files-parent">
            <h6>Attached files</h6>
            <div className="pdf-child">
              {userFiles.map((file) => {
                return (
                  <span className="pdf-item">
                    <PdfIcon />
                    {file.name.length > 22 ? `${file.name.substring(0, 22)}...` : file.name}
                  </span>
                );
              })}
            </div>
          </span>
        )}
      </div>
      <div className="question-text">
        {attach_url && userLinksArray.length > 0 && userLinksArray[0].value !== '' && (
          <span className="submitted-question">
            Additional URLs&nbsp;
            <span className="answer">
              {userLinksArray.map((link, i) => (i === 0 ? `${link.value}` : `, ${link.value}`))}
            </span>
          </span>
        )}
      </div>
      <div className="question-text">
        {how_good_are_they && skills.length > 0 && (
          <span className="submitted-question">
            What are they good at?&nbsp;
            <span className="answer">{skills.map((skill, i) => (i === 0 ? `${skill.name}` : `, ${skill.name}`))}</span>
          </span>
        )}
      </div>

      <div className="question-text">
        {know_the_person_level && candidateRating && (
          <span className="submitted-question">
            How well do you know this person? <span className="answer">{candidateRating} </span>
          </span>
        )}
      </div>

      <div className="question-text">
        {worked_with_person && workWithClient && (
          <span className="submitted-question">
            Have you ever worked directly with this person? <span className="answer">{workWithClient} </span>
          </span>
        )}
      </div>

      <div className="question-text">
        {open_for_new_opportunities_status && openToNewOppertunities && (
          <span className="submitted-question">
            How open are they to new opportunities?&nbsp;
            <span className="answer">{openToNewOppertunities}</span>
          </span>
        )}
      </div>
      <div className="question-text">
        {know_the_person_level_radio && knowThePerson !== null && (
          <span className="submitted-question">
            Do you know this person professionally or personally?&nbsp;
            <span className="answer">{knowThePerson}</span>
          </span>
        )}
      </div>
      <div className="question-text">
        {why_are_they_good_fit && isGoodFit !== null && (
          <span className="submitted-question">
           Why do you think they are a good fit?&nbsp;
            <span className="textWrap">{isGoodFit}</span>
          </span>
        )}
      </div>
      <div className="question-text">
        {diversity_hire && isDiversityHire && (
          <span className="submitted-question">
            Would you consider yourself a diversity hire?&nbsp;
            <span className="answer">{Boolean(isDiversityHire) ? 'Yes' : 'No'}</span>
          </span>
        )}
      </div>
      <div className="question-text">
        {license_available  && licenseAvailable !== null &&(
          <span className="submitted-question">
            Do you have a license?&nbsp;
            <span className="answer">{licenseAvailable}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default ReferStrengthDetails;
