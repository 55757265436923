import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    pageWrapper: {
        justifyContent: 'space-between'
    },
    signUpForm: {
        padding: 70,
        paddingVertical: 35,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boonHeading: {
        fontSize: 40,
        fontFamily: 'Roboto-Regular',
        marginBottom: 10,
        fontWeight: 'bold',
    },
    signupText: {
        fontSize: 14,
        marginBottom: 10
    },
    body: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 90
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 8,
        position: 'relative'
    },
    signUpButton: {
        marginTop: 20,
        width: '47%',
    },
    loginButton: {
        marginTop: 20,
        width: '47%',
        backgroundColor: '#d5dbda',
        color: '#7d8d8a',
        marginRight: 20
    },
    logoWrapper: {
        flexShrink: 1,
        flexGrow: 0,
        position: 'relative',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    logo: {
        width: 350,
        height: 40,
        flexGrow: 0,
        flexShrink: 1,
        resizeMode: 'contain'
    },
    inputWrapper: {
        marginVertical: 32,
    },
    errorFields: {
        color: 'red',
        borderBottomColor: 'red',
    },
    forgotPassword: {
        color: '#d3d4d3',
        fontSize: 17,
        paddingVertical: 20,
        fontWeight: 'bold'
    },

});
