import Contacts from '../wrappers/contacts/contacts';

import * as connectionsActionsConstants from '../constants/actions/connectionsActions';
import * as authConstants from '../constants/actions/auth';
import * as connectionsService from '../services/connectionsService';
import { notification, extendWithConnections } from '../helperFunctions';

export function postUserContacts() {
    return dispatch => {
        Contacts.getAll((err, contacts) => {
            const contactsArray = err ? [] : contacts.map(contact => {
                return {
                    first_name: contact.givenName,
                    last_name: contact.familyName,
                    emails: contact.emailAddresses,
                    phone_numbers: contact.phoneNumbers,
                }
            });

            connectionsService.postUserContacts(contactsArray);
        })
    }
}

export function getUserConnections(params) {
    return dispatch => {
        dispatch({
            type: connectionsActionsConstants.GET_USER_CONNECTIONS,
            payload: {
                isPagination: Boolean(params && params.offset)
            }
        });
        const service = params && params.filter_type === 'connections' ? connectionsService.getUserConnections : connectionsService.getUserContacts;

        return service(params).then(response => {
            const responseNetwork = params && params.filter_type === 'connections' ? response.data.payload.connections : extendWithConnections(response.data.payload.user_contacts, response.data.payload.connections);

            dispatch({
                type: connectionsActionsConstants.GET_USER_CONNECTIONS_SUCCESS,
                payload: {
                    network: responseNetwork,
                    offset: response.data.payload.offset,
                    isPagination: Boolean(params && params.offset)
                }
            });
            return response;
        });
    };
}

export function clearConnections() {
    return dispatch => {
        dispatch({
            type: connectionsActionsConstants.CLEAR_USER_CONNECTIONS
        });
    };
}

export function requestConnection(connectionId) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.REQUEST_CONNECTION});
        connectionsService.requestConnection(connectionId).then(() => {
            dispatch({
                type: connectionsActionsConstants.REQUEST_CONNECTION_SUCCESS,
                payload: connectionId
            });
            notification.ref.show({message: 'Connection requested'});
        }).catch(responce => {
            dispatch({type: connectionsActionsConstants.REQUEST_CONNECTION_ERROR});
        });
    };
}

export function inviteUsers(userContacts, invertedSelection) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.INVITE_USERS});
        return connectionsService.inviteUsers(userContacts, invertedSelection).then(response => {
            dispatch({type: connectionsActionsConstants.INVITE_USERS_SUCCESS});
            notification.ref.show({message: 'Invitation sent'});
            return response
        });
    };
}

export function setUserConnectedToGoogle() {
    return dispatch => {
        dispatch({type: authConstants.SUBSCRIBE_GOOGLE_SUCCESS});
    };
}

export function acceptConnection(connectionId) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.ACCEPT_CONNECTION});
        return connectionsService.acceptConnection(connectionId).then(response => {
            dispatch({type: connectionsActionsConstants.ACCEPT_CONNECTION_SUCCESS, payload: connectionId});
            return response;
        }).catch(() => {
            dispatch({type: connectionsActionsConstants.ACCEPT_CONNECTION_ERROR});
        });
    };
}

export function rejectConnection(connectionId) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.REJET_CONNECTION});
        return connectionsService.rejectConnection(connectionId).then(response => {
            dispatch({type: connectionsActionsConstants.REJET_CONNECTION_SUCCESS});
            return response;
        }).catch(() => {
            dispatch({type: connectionsActionsConstants.REJET_CONNECTION_ERROR});
        });
    };
}

export function importLinkedInContacts(userId) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.IMPORT_LINKEDIN_CONTACTS});
        return connectionsService.checkLinkedInContactsDataArchive(userId).then(response => {
            dispatch({type: connectionsActionsConstants.IMPORT_LINKEDIN_CONTACTS_SUCCESS});
            return response
        });
    };
}
