import { unset } from 'lodash-es';
import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    formWrapper: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        margin: 'auto',
        paddingTop: 50,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingBottom: 20
    },
    headerText: {
        color: '#0FBC71',
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: '0.8px',
        fontWeight: 500
    },
    formFieldWrapper: {},
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF'
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)'
    },
    iconImg: {
        width: 16,
        height: 16
    },
    checkboxWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 41,
        marginBottom: 19,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 280
    },
    checkbox: {
        borderRadius: 4
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2'
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: 12
    },
    disabledButtonText: {
        color: '#808080',
        fontSize: 12
    },
    termAndConditionsParent: {
        marginLeft: 12,
        flexDirection: 'row'
    },
    termAndConditionsChild: {
        color: '#666666',
        fontSize: 12,
        width: 158,
        lineHeight: '18px'
    },
    termAndConditionsText: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
    },
    temp: {
        width: '100%',
        paddingVertical: 10,
        borderRadius: 4,
        backgroundColor: $green,
        boxShadow: '0px 2px 4px rgba(147, 157, 177, 0.3)',
        textTransform: 'none'
    },
    buttonText: {
        fontFamily: 'Roboto-Regular',
        fontSize: 13,
        fontWeight: '500',
        color: 'rgba(0, 0, 0, 0.5)'
    },
    dropDownStyle: {
        fontFamily: 'Roboto-Regular',
        fontSize: '16px',
        borderColor: '#818181',
        borderWidth: 2,
        borderRadius: 4
    },
    textPadding: {
        paddingBottom: 15,
        zIndex: 10
    },
    buttonStyle: {
        justifyContent: 'center',
        zIndex: -9
    },
    buttonWrapper: {
        width: '100%',
        paddingTop: 8,
        paddingBottom: 20
    }
});
