import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { Field } from 'redux-form';

//styles
import {formLayout} from '../../generalStyles';
import styles from './styles';

// components
import FormSelect from '../FormSelect/FormSelect';
import FormInput from '../FormInput/FormInput';
import Text from '../Text/Text';
import ListElementButton from '../ListElementButton/ListElementButton';

//images
import addIcon from '../../assets/img/add_icon.png';

//constants
import linksTypes from '../../constants/linksTypes'

export default class Links extends Component {
    render() {
        const {fields} = this.props;
        return (
            <View>
                {fields.map((field, index) =>
                    <View key={field} style={styles.linkWrapper}>
                        <Field
                            component={FormSelect}
                            name={`${field}.type`}
                            options={linksTypes}
                        />
                        <Field component={FormInput} name={`${field}.value`} wrapperStyle={[formLayout.fullWidth]}/>
                        <TouchableOpacity small onPress={() => fields.remove(index)} style={styles.deleteButton}>
                            <Text style={styles.deleteButtonText}>&times;</Text>
                        </TouchableOpacity>
                    </View>
                )}
                <ListElementButton small onPress={() => fields.push({type: 'ln'})} style={styles.addButtonStyle} icon={addIcon}>Add link</ListElementButton>
            </View>
        )
    }
}