import React, { Component } from 'react';
import {
    View,
    Image
} from 'react-native';
import moment from 'moment';

//components
import Text from '../../../../components/Text/Text';

//images
import checkIcon from '../../../../assets/img/check_icon.png';
import declinedIcon from '../../../../assets/img/declined_icon.png';
import passedIcon from '../../../../assets/img/passed_icon.png';


//styles
import {listElement, $greenish, $greish} from '../../../../generalStyles';
import style from './styles';


class ArchivedStatusLabel extends Component {
    render() {
        const { opportunity: {referrals, bounty_amount, archived_at }, direction} = this.props;
        const statuses = {
            hired: {
                title: 'Hired',
                color: $greenish
            },
            hired_and_archived: {
                title: 'Hired',
                color: $greenish
            },
            declined: {
                title: 'Declined',
                color: $greish
            },
            expired: {
                title: 'Expired',
                color: $greish
            },
            passed: {
                title: 'Passed',
                color: $greish
            },
            not_your_refer: {
                title: 'Referral Declined',
                color: $greish,
            }
        };

        const candidateStatus = referrals[0].candidate_status;

        return (
            <View style={[listElement.rightBlock]}>
                <View style={[style.statusLabelWrapper, {backgroundColor: statuses[candidateStatus].color}]}>
                    {candidateStatus === 'hired' && 
                    <Text style={{color: '#fff'}}>✓ </Text>
                    }
                    <Text style={style.statusLabelTitle}>{statuses[candidateStatus].title}</Text>
                </View>
            </View>
        );
    }
}

export default ArchivedStatusLabel;
