import * as connectionsActionsConstants from '../../constants/actions/connectionsActions';
import * as connectionsService from '../../services/connectionsService';

export function requestConnection(userIds, invertedSelection) {
    return dispatch => {
        dispatch({type: connectionsActionsConstants.REQUEST_USER_CONNECTIONS});
        return connectionsService.requestUserConnections(userIds, invertedSelection).then(response => {
            dispatch({type: connectionsActionsConstants.REQUEST_USER_CONNECTIONS_SUCCESS});
            return response.data.payload;
        }).catch(() => {
            dispatch({type: connectionsActionsConstants.REQUEST_USER_CONNECTIONS_ERROR});
        });
    };
}
