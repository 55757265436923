import { StyleSheet } from 'react-native';
import { $greish } from '../../../../generalStyles';

export default StyleSheet.create({
    emptyBox: {
        borderWidth: 1.5,
        borderColor: $greish,
        borderRadius: 5,
        borderStyle: 'dashed',
        alignContent: 'center',
        marginTop: 5,
        paddingTop: 35,
        paddingBottom: 35,
        height: 100,
        width: 220
    },
    emptyTitle: {
        fontSize: 12,
        color: $greish,
        textAlign: 'center',
        justifyContent: 'space-evenly'
    },
    trackerListContainer: {
        paddingRight: 5,
        maxHeight: 'calc(100vh - 250px)',
    }
});
