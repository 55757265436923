import React, {useMemo} from 'react';
import styles from './styles';
import { View, Text, TouchableOpacity } from 'react-native';
import useWindowSize from '../../hooks/useWindowResize';

const MagicLinkSuccess = ({ email, handleBack }) => {

    const size = useWindowSize();

    const getMagicContStyles = useMemo(() => {
        if (size.width > 550) {
            return styles.magicContWeb;
        } else {
            return styles.magicContMobile;
        }
    }, [size]);

    return (
        <View style={getMagicContStyles}>
            <Text style={styles.magicHeading}>Magic link sent!</Text>
            <Text style={styles.magicText}>
                We just sent an email to{' '}
                <Text style={styles.email}>{email}</Text>. It contains a link
                that’ll sign you in super quick!
            </Text>
            <View style={styles.backToLoginCont}>
                <TouchableOpacity
                    onClick={handleBack}
                    style={styles.backToLogin}
                >
                    <Text style={styles.backToLoginText}>Back to login</Text>
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default MagicLinkSuccess;
