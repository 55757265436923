import * as companiesActionsConstants from '../../constants/actions/organizationsActions';
import * as companiesService from '../../services/organizationsService';
import { notification } from '../../helperFunctions';

export function getOrganizationDetails(organizationId) {
    return dispatch => {
        dispatch({type: companiesActionsConstants.GET_ORGANIZATION_DETAILS});
        companiesService.getOrganizationDetails(organizationId).then(response => {
            if(response.data.payload.message) {
                notification.ref.show({message: response.data.payload.message, isError: true});
            }else {
                dispatch({
                    type: companiesActionsConstants.GET_ORGANIZATION_DETAILS_SUCCESS,
                    payload: response.data.payload
                });
            }
        }).catch(() => {
            dispatch({
                type: companiesActionsConstants.GET_ORGANIZATION_DETAILS_ERROR
            })
        });
    };
}

export function getOrganizationJobs(organizationId, params) {
    return dispatch => {
        dispatch({type: companiesActionsConstants.GET_ORGANIZATION_JOBS});
        companiesService.getOrganizationJobs(organizationId, params).then(response => {
            dispatch({
                type: companiesActionsConstants.GET_ORGANIZATION_JOBS_SUCCESS,
                payload: response.data.payload
            });
        }).catch(() => {
            dispatch({
                type: companiesActionsConstants.GET_ORGANIZATION_JOBS_ERROR
            })
        });
    };
}

export function getOrganizationActiveJobs(organizationId, params) {
    return dispatch => {
        dispatch({type: companiesActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS});
        companiesService.getOrganizationActiveJobs(organizationId, params).then(response => {
            dispatch({
                type: companiesActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS_SUCCESS,
                payload: response.data.payload
            });
        }).catch(() => {
            dispatch({
                type: companiesActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS_ERROR
            })
        });
    };
}
