import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';
import {getUserConnections} from '../../globalActions/networkActions';

export function unsubscribeGoogle(accessToken) {
    return (dispatch) => {
        dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE});
        authService.unsubscribeGoogle(accessToken).then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE_SUCCESS});
            dispatch(getUserConnections({filter_type: 'connections'}));
        }).catch(error => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE_ERROR});
        });
    };
}

export function subscribeGoogle() {
    return (dispatch) => {
        dispatch({type: authActionsConstants.SUBSCRIBE_GOOGLE});
    };
}

export function getUserSyncedHistory(data) {
    return (dispatch) => {
        authService.getUserSyncedHistory(data).then((response) => {
            dispatch({type: authActionsConstants.SET_USER_SOURCES, payload: response.data.payload.user_sources});
        }).catch(error => {});
    };
}

export function showSearchBlock(data) {
    return (dispatch) => {
        dispatch({ type: authActionsConstants.SHOW_SEARCH_BLOCK, payload: data })
    };
}
