import React from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';
import { connect } from 'react-redux';

//components
import Text from '../../components/Text/Text';

//styles
import styles from './styles';
import { common, $green } from '../../generalStyles';

const scrollElementStyle = {
  flex: 1,
  borderRadius: 0,
};
const scrollReferStyle = {
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderColor: '#c3c3c3',
};

const AutoSuggestSearchResult = (props) => {
  const {
    keyField,
    suggestions,
    loading,
    suggestElement,
    resultsWrapperStyle,
    page,
  } = props;
  return (
    <View style={styles.contactsList}>
      {loading ? (
        <ActivityIndicator size="large" color={$green} style={styles.spinner} />
      ) : (
        <>
          {!suggestions.length && (
            <View style={styles.emptyListWrapper}>
              <Text style={styles.emptyListText}>No results found.</Text>
            </View>
          )}
        </>
      )}
      <View
        style={[
          scrollElementStyle,
          page === 'refer' && suggestions.length > 0 ? scrollReferStyle : null,
        ]}
      >
        <KeyboardAwareFlatList
          keyboardShouldPersistTaps="always"
          data={suggestions}
          renderItem={suggestElement}
          keyExtractor={(item, index) => `${item[keyField || 'name']}_${index}`}
        />
      </View>
    </View>
  );
};

const mapStateToProps = (state, props) => {
  return {
    suggestions: state.suggestionsReducer['networkSuggestions'].suggestions,
    suggestionsLoading:
      state.suggestionsReducer['networkSuggestions'].suggestionsLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoSuggestSearchResult);
