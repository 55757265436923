import React, { Component } from 'react';
import { View, Image } from 'react-native';

//images
import successBg from '../../assets/img/success_screen_bg.png';
import successIcon from '../../assets/img/success_icon.png';

//components
import Text from '../../components/Text/Text';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Actions from '../../RouteActions';

//styles
import styles from './styles';

class SuccessScreen extends Component {
    render() {
        const { title, description, primaryBtnText, onPrimaryPress, secondaryBtnText, onSecondaryPress } = this.props;
        return (
            <View style={styles.screenWrapper}>
                <Image source={successBg} style={styles.bgImage}/>
                <Image source={successIcon} style={styles.successIcon}/>
                <View>
                    <Text style={styles.successTitle}>{title}</Text>
                    <Text style={styles.successDescription}>{description}</Text>
                </View>
                <View>
                    <PrimaryButton onPress={() => Actions.jump('home')}>{primaryBtnText}</PrimaryButton>
                    {
                      secondaryBtnText && 
                      <PrimaryButton 
                        white 
                        onPress={()=> Actions.pop()} 
                        style={styles.secondaryBtn}>{secondaryBtnText}
                      </PrimaryButton>
                    }
                </View>
            </View>
        );
    }
}

export default SuccessScreen;