import { StyleSheet } from 'react-native';
import { $greish } from '../../generalStyles';

export default StyleSheet.create({
    csvButton: {
        marginTop: 32,
        marginLeft: 100,
    },
    csvUploadContainer: {
        fontFamily: 'Roboto-Regular',
        textAlign: 'center',
        padding: 15,
        margin: '10px auto',
        borderColor: $greish,
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 20,
        marginRight: 20,
        marginLeft: 20,
    },
    fieldsWrapper: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10
    },
    labelContainer: {
        width: '30%', paddingTop: 25
    },
    descriptionText: {
        padding: 20,
        textAlign: 'center'
    }
});
