import baseUrl from './apiServer';

const clusters = {
    clusterDevelopment: 'us3',
    clusterStaging: 'us3',
    clusterProduction: 'us3'
};

const keys = {
    keyDevelopment: '05733fe99cbc5b6d6d41',
    keyStaging: '05733fe99cbc5b6d6d41',
    keyProduction: '2fb00f6c3fae5bbea430'
};

export const getPusherCluster = () => {
    let cluster = '';
    if (baseUrl === 'https://api.goboon.co') {
        cluster = clusters.clusterProduction;
    }
    if (baseUrl === 'https://boon-staging.herokuapp.com') {
        cluster = clusters.clusterStaging;
    }
    if (baseUrl === 'http://localhost:3000') {
        cluster = clusters.clusterDevelopment;
    }

    return cluster;
};

export const getPusherKey = () => {
    let key = '';
    if (baseUrl === 'https://api.goboon.co') {
        key = keys.keyProduction;
    }
    if (baseUrl === 'https://boon-staging.herokuapp.com') {
        key = keys.keyStaging;
    }
    if (baseUrl === 'http://localhost:3000') {
        key = keys.keyDevelopment;
    }

    return key;
};
