import React, { Component } from 'react';
import {View, Image, Dimensions, TouchableOpacity} from 'react-native';
import Carousel from 'react-native-snap-carousel';
import {findIndex} from 'lodash-es';
import Actions from '../../RouteActions';
import {connect} from 'react-redux';

//images
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
import shareIcon from '../../assets/img/share_icon_white.png';
import emptyRecommendationsIcon from '../../assets/img/empty_recommendations_icon.png';

//components
import Text from '../../components/Text/Text';
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import ListElementButton from '../../components/ListElementButton/ListElementButton';

//styles
import styles from './styles';

//actions
import * as actions from './actions';

//utils
import {locationObjToStr} from '../../helperFunctions';

const windowWidth = Dimensions.get('window').width;

class Recommendations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data
        };

        this.renderItem = this.renderItem.bind(this);
        this.handlePassPress = this.handlePassPress.bind(this);
    }


    handlePassPress(id) {
        const data = this.state.data.slice();
        const index = findIndex(data, element => element.id === id);
        data.splice(index, 1);
        this.props.passRecommendation(id);
        this.setState({data});
    }

    renderItem ({item}) {
        const avatar = item.user.image_url ? {url: item.user.image_url} : defaultUserAvatar;
        const logo = item.organization.image_url ? {url: item.organization.image_url} : defaultOrgAvatar;

        const referInitValues = {
            user_id: item.user.id,
            name: item.user.name,
            email: item.user.email,
            phone_number: item.user.phone_number,
            job_id: {
                id: item.job.id,
                title: item.job.name,
            },
            organization_id: {
                id: item.organization.id,
                name: item.organization.title,
                logo_image_url: item.organization.image_url,
            }
        };

        return (
            <View style={styles.slide}>
                <View style={styles.slideColumn}>
                    <View style={styles.info}>
                        <Image source={logo} style={styles.image} resizeMode="contain"/>
                        <Text style={styles.title}>{item.organization.title}</Text>
                        <Text style={styles.description}>{item.job.name}</Text>
                        <Text style={styles.description}>{locationObjToStr(item.job.location)}</Text>
                        <TouchableOpacity onPress={() => Actions.browseJobDetail({id: item.job.id})}>
                            <Text style={styles.link}>View more</Text>
                        </TouchableOpacity>
                    </View>
                    <PrimaryButton style={styles.button} small disabled onPress={() => this.handlePassPress(item.id)}>Pass</PrimaryButton>
                </View>
                <View style={styles.slideColumn}>
                    <View style={styles.info}>
                        <Image source={avatar} style={[styles.image, styles.imageRounded]} resizeMode="contain"/>
                        <Text style={styles.title}>{item.user.name}</Text>
                        <Text style={styles.description}>{item.user.company_position}</Text>
                        <Text style={styles.description}>{item.user.city}, {item.user.state}</Text>
                        <TouchableOpacity onPress={() => Actions.contactProfile({userId: item.user.id})}><Text style={styles.link}>View more</Text></TouchableOpacity>
                    </View>
                    <PrimaryButton small style={styles.button} icon={shareIcon} onPress={() => Actions.refer({
                        initialValues: referInitValues
                    })}>Refer</PrimaryButton>
                </View>
            </View>
        );
    }

    render () {
        return (
            <DashboardBase hasBackButton title="Review Recommendations">
                <View style={styles.container}>
                    {Boolean(this.state.data.length) && <Carousel
                        layout={'tinder'}
                        data={this.state.data}
                        renderItem={this.renderItem}
                        sliderWidth={windowWidth}
                        itemWidth={windowWidth - 32}
                        containerCustomStyle={styles.carouselContainer}
                        removeClippedSubviews={false}
                    />|| <View style={styles.emptyWrapper}>
                        <Image source={emptyRecommendationsIcon} style={styles.emptyImage}/>
                        <Text style={styles.emptyText}>All caught up for now. We’ll let you know when we {`\n`}have more recommendations</Text>
                        <ListElementButton style={styles.emptyButton} onPress={Actions.pop}>Back to Activity</ListElementButton>
                    </View>}
                </View>
            </DashboardBase>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        passRecommendation: recommendationId => {
            dispatch(actions.passRecommendation(recommendationId))
        }
    }
};

export default connect(null, mapDispatchToProps)(Recommendations);
