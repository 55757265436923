import * as organizationsActionsConstants from '../constants/actions/organizationsActions';
import * as appActionsConstants from '../constants/actions/appActions.js';
import * as refersActionsConstants from '../constants/actions/referActions';
import * as companiesService from '../services/organizationsService';
import * as refersService from '../services/refersService';
import { notification } from '../helperFunctions';
import { SET_CURRENT_SELECTED_ORG } from '../constants/actions/auth';

export function getOrganizationsInvites() {
  return (dispatch) => {
    // dispatch({type: organizationsActionsConstants.GET_ORGANIZATIONS_INVITES});
    return companiesService.getOrganizationsList().then((response) => {
      dispatch({
        type: organizationsActionsConstants.GET_ORGANIZATIONS_INVITES_SUCCESS,
        payload: response.data.payload.organizations,
      });
      return response;
    });
  };
}

export function submitInvite(organizationId) {
  return (dispatch) => {
    dispatch({
      type: organizationsActionsConstants.SUBMIT_ORGANIZATION_INVITE,
    });
    return companiesService.submitInvite(organizationId).then((response) => {
      dispatch({
        type: organizationsActionsConstants.SUBMIT_ORGANIZATION_INVITE_SUCCESS,
        payload: {
          organizationId,
          userOrganizations: response.data.payload.organizations,
        },
      });
      return response;
    });
  };
}

export function requestAccess() {
  return (dispatch) => {
    return companiesService.requestAccess().then((response) => {
      notification.ref.show({
        message: 'Your request has been submitted successfully.',
      });
      return response;
    });
  };
}

export function getOrganizationsTab() {
  return (dispatch) => {
    dispatch({ type: organizationsActionsConstants.GET_ORGANIZATIONS_TAB });
    return companiesService.getOrganizationsTab().then((response) => {
      return dispatch({
        type: organizationsActionsConstants.GET_ORGANIZATIONS_TAB_SUCCESS,
        payload: response.data.payload.organizations,
      });
    });
  };
}

export function followOrganization(organizationName) {
  return (dispatch) => {
    dispatch({ type: organizationsActionsConstants.FOLLOW_ORGANIZATION });
    companiesService.followOrganization(organizationName).then(() => {
      dispatch({
        type: organizationsActionsConstants.FOLLOW_ORGANIZATION_SUCCESS,
        payload: organizationName,
      });
    });
  };
}

export function unfollowOrganization(organizationId) {
  return (dispatch) => {
    dispatch({ type: organizationsActionsConstants.UNFOLLOW_ORGANIZATION });
    companiesService.unfollowOrganization(organizationId).then(() => {
      dispatch({
        type: organizationsActionsConstants.UNFOLLOW_ORGANIZATION_SUCCESS,
        payload: organizationId,
      });
    });
  };
}

export function getOrganizationsList(setShouldChangeOrg = () => {}, orgID, successCallBack = () => {}) {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    dispatch({ type: organizationsActionsConstants.GET_ORGANIZATIONS });
    companiesService.getOrganizationsList().then((response) => {
      dispatch({
        type: organizationsActionsConstants.GET_ORGANIZATIONS_SUCCESS,
        payload: response.data.payload,
      });

      const query = new URLSearchParams(window.location.search);
      const orgUuid = orgID ? orgID : query.get('orgUuid');

      if (orgUuid) {
        const orgToSelect = response.data.payload.organizations.find((e) => e.organization.uuid === orgUuid);
        if (orgToSelect) {
          const org = {
            label: orgToSelect.organization.name,
            value: orgToSelect.organization.name,
            id: orgToSelect.organization.id,
            uuid: orgToSelect.organization.uuid,
            name: orgToSelect.organization.name,
            logo: orgToSelect.organization.logo_image_thumb,
          };

          dispatch({
            type: SET_CURRENT_SELECTED_ORG,
            payload: org,
          });

          setShouldChangeOrg(false);

          successCallBack();

          window.history.replaceState({}, document.title, location.pathname);
        }
      }
    });
    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
  };
}

export function getUserDetailsForRefererProvider(id) {
  return (dispatch) => {
    refersService.getUserDetailsForRefererProvider(id).then((response) => {
      dispatch({
        type: refersActionsConstants.SET_USER_REFERER,
        payload: response.data.payload.user,
      });
    });
  };
}
