import { StyleSheet } from "react-native";
import { $green } from "../../../../generalStyles";

export default StyleSheet.create({
  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    maxWidth: 375,
  },
  title: {
    color: "#18332F",
    fontSize: 20,
    fontWeight: "700",
    lineHeight: 23,
  },
  filtersWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 5,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  filter: {
    fontSize: 11,
    fontWeight: "500",
    color: "#AAAAAA",
  },
  emptyText: {
    color: "#444444",
    fontSize: 16,
    padding: 10,
    textAlign: "center",
    maxWidth: 376,
    margin: "auto",
  },
  listWrapper: {
    paddingTop: 10,
    zIndex: -1,
  },
  listItem: {
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#EEEEEE",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    flexDirection: "row",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 13,
    paddingRight: 17,
    alignItems: "center",
  },
  avatarWrapper: {
    position: "relative",
  },
  avatarLarge: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  avatarSmall: {
    width: 28,
    height: 28,
    borderRadius: 14,
  },
  currentUserAvatar: {
    borderWidth: 1,
    borderColor: $green,
  },
  emptyBackgroundColor: {
    backgroundColor: "#F2F2F2",
  },
  listItemBody: {
    paddingHorizontal: 16,
    flex: 1,
    justifyContent: "center",
  },
  lastMonthWinner: {
    position: "absolute",
    width: 20,
    height: 20,
    right: -3,
    bottom: -3,
  },
  listItemRight: {
    width: 40,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
  },
  listItemMore: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: 18,
  },
  roundSmall: {
    backgroundColor: "#C2C2C2",
    borderRadius: 3,
    width: 7,
    height: 7,
    margin: 2,
  },
  winnerPlaceText: {
    fontSize: 16,
    color: $green,
    fontWeight: "bold",
    zIndex: 1,
  },
  winnerPlaceTextWhite: {
    color: "#fff",
  },
  elementWrapper: {
    backgroundColor: "#fff",
    opacity: 0.8,
  },
  elementWrapperLeader: {
    backgroundColor: "#fff",
  },
  polygon: {
    position: "absolute",
    height: 48,
    width: 48,
  },
  positionWrapper: {
    flex: 0,
    borderRadius: 20,
    height: 40,
    minWidth: 40,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  positionText: {
    fontSize: 16,
    color: $green,
    fontWeight: "bold",
    zIndex: 1,
  },
  infoWrapper: {
    paddingTop: "auto",
    alignSelf: "center",
  },
  rightBlock: {
    paddingVertical: 16,
  },
});
