import * as suggestionsActionsConstants from '../../constants/actions/suggestions';
import * as suggestionsService from '../../services/suggestionsServices';
import {extendWithConnections} from '../../helperFunctions';
import * as browseActionConstants from '../../constants/actions/browseActions';
import * as organizationsActionsConstants from '../../constants/actions/organizationsActions';
import { getAllOrganization } from '../FollowCompaniesComponent/actions'
import { getAll } from '../../screens/Browse/components/Browse/actions'

export function getSuggestions(query, service, suggestionsKey, showAllOnLoad, params, type, searchModel = null, sortBy = null, filters = {}) {
    return dispatch => {
        if (searchModel) {
            dispatch({type: suggestionsActionsConstants.SET_QUERY_STRING, payload: query});
            if (searchModel === 'jobs') {
                return
            }
        }
        dispatch({type: suggestionsActionsConstants.GET_SUGGESTIONS, payload: {type: type}});
        if (query === '' && !showAllOnLoad) {
            if (searchModel) {
                if (searchModel === 'jobs') {
                    dispatch(getAll({ filters, type: 'job' }, null, true));
                } else if (searchModel === 'organizations') {
                    dispatch(getAllOrganization());
                }
            }
            dispatch({type: suggestionsActionsConstants.GET_SUGGESTIONS_SUCCESS, payload: {type: type, suggestions: []}});
        } else {
            suggestionsService[service](query, params, searchModel, sortBy, filters).then(response => {
                let suggestions = response.data.payload[suggestionsKey];
                switch (type) {
                    case 'globalSearch':
                        if(searchModel !== 'user_sources') {
                            suggestions = suggestions.filter(suggestion => suggestion.data.length || (suggestion.data.user_contacts && suggestion.data.user_contacts.length));
                        }
                        if (searchModel) {
                            if (searchModel === 'jobs') {

                                dispatch({type: browseActionConstants.GET_BROWSE_ALL_SUCCESS, payload });
                            } else if (searchModel === 'organizations') {
                                dispatch({type: organizationsActionsConstants.GET_FOLLOW_ORGANIZATIONS_SUCCESS, payload: suggestions[0].data});
                            }
                        }
                        break;
                    case 'trackerSuggestions':
                        suggestions = suggestions.filter(suggestion => suggestion.data.length);
                        break;
                    case 'networkSuggestions':
                        suggestions = extendWithConnections(response.data.payload.user_contacts, response.data.payload.connections);
                        break;
                }

                dispatch({type: suggestionsActionsConstants.GET_SUGGESTIONS_SUCCESS, payload: {type: type, suggestions}});
            }).catch(() => {
                dispatch({type: suggestionsActionsConstants.GET_SUGGESTIONS_ERROR, payload: {type: type}});
            })
        }
    }
}

export function clearSuggestions(type) {
    return dispatch => {
        dispatch({type: suggestionsActionsConstants.CLEAR_SUGGESTIONS, payload: {type: type}});
    };
}

export function setNetworkSuggestionsLoading(type) {
    return dispatch => {
        dispatch({type: suggestionsActionsConstants.SET_NETWORK_SUGGESTIONS_LOADING, payload: {type: type}});
    };
}
