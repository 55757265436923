export const REFER = 'REFER';
export const REFER_SUCCESS = 'REFER_SUCCESS';
export const REFER_ERROR = 'REFER_ERROR';
export const REFER_AGAIN = 'REFER_AGAIN';
export const REFER_CLOSE = 'REFER_CLOSE';

export const GET_REFER_JOB_ERROR = 'GET_REFER_JOB_ERROR';
export const GET_REFER_JOB_SUCCESS = 'GET_REFER_JOB_SUCCESS';

export const SET_REFERRER_STATUS = 'SET_REFERRER_STATUS';

export const REFER_REASONS_SUCCESS = 'REFER_REASONS_SUCCESS';
export const REFER_REASONS_ERROR = 'REFER_REASONS_ERROR';

export const REFER_USERS_SUCCESS = 'REFER_USERS_SUCCESS';
export const REFER_USERS_ERROR = 'REFER_USERS_ERROR';

export const REFER_DECLINE_FORM_SUCCESS = 'REFER_DECLINE_FORM_SUCCESS';
export const REFER_DECLINE_FORM_ERROR = 'REFER_DECLINE_FORM_ERROR';

export const REFER_INVITE_SUCCESS = 'REFER_INVITE_SUCCESS';
export const REFER_INVITE_ERROR = 'REFER_INVITE_ERROR';

export const SET_REFER_INVITE_STATUS = 'SET_REFER_INVITE_STATUS';
export const SET_API_CALL_STATUS = 'SET_API_CALL_STATUS';

export const SET_REFER_FEEDBACK_STATUS = 'SET_REFER_FEEDBACK_STATUS';

export const SET_USER_REFERER = 'SET_USER_REFERER'
