import { StyleSheet } from 'react-native';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('window');
const isSmallScreen = width <= 950;

export default StyleSheet.create({
    wrappedStyle: {
        paddingTop: 30
    },
    wrapperStyle: {
        marginVertical: 16,
        marginHorizontal: 0
    },
    mainDiv: {
        flexDirection: 'row'
    },
    organizationName: {
        fontSize: 12,
        opacity: 0.6,
        fontWeight: 600,
    },
    jobTitle: {
        fontWeight: 'bold',
        fontSize: 16, 
        marginBottom: 2
    },
    viewJobButton: {
        height: 40,
        padding: '0px 20px',
        width: '137px',
        marginBottom: 5,
        marginRight: 5,
    },
    applyButton: {
      height: 40,
      padding: '0px 20px',
      width: '137px',
      marginBottom: 5,
    },
    declineButton: {
      borderWidth: 0,
      padding: '0px 20px',
      width: '137px',
    },
    flex: {
        flexDirection: isSmallScreen ? 'column' : 'row',
        flex: 1
    },
    buttonDiv: {
      paddingTop: isSmallScreen ? '10px' : null,
      justifyContent: "flex-end",
    },
    customIconButton: {
        top: '-60px',
        position: "absolute",
        right: 0 
    }
});
