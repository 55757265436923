import { change } from 'redux-form';
import Actions from '../../RouteActions';

import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as redirectStepActions from '../../services/stepsService'
import { notification } from '../../helperFunctions';
import {
    CONFIRM_NUMBER,
    CONFIRM_NUMBER_SUCCESS,
    GET_NUMBER_CONFIRMATION
} from '../../constants/actions/auth';

import * as authService from '../../services/authService';

export function confirmNumber(formData, successCallback) {
    return dispatch => {
        dispatch({ type: CONFIRM_NUMBER });
        authService.confirmPhone(formData).then(response => {
            if(response.data.response_code === 12) {
                notification.ref.show({ message: response.data.error, isError: true });
                Actions.confirmCode();
            } else {
                dispatch(change('signUpPhoneForm', 'phone_number', formData.phone_number));
                dispatch(change('signUpPhoneForm', 'confirmation_code', formData.confirmation_code));
                dispatch({ type: CONFIRM_NUMBER_SUCCESS });
                dispatch({ type: authActionsConstants.SIGN_UP_SUCCESS, payload: response.data.payload });
                dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                notification.ref.show({ message: 'Phone number confirmed successfully.', isError: false });
                if (successCallback) successCallback();
                redirectStepActions.redirectToCurrentStep(response.data.payload.current_incomplete_step)
            }
        }).catch((response) => {
            notification.ref.show({ message: response.data.error, isError: true });
        })
    };
}

export function sendSkipMobileVerification() {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.skipMobileVerification().then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            if(response.data.response_code === 0) {
                dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                notification.ref.show({ message: 'Mobile Verification Skipped' });
                Actions.signUpLocation();
            }
            else {
                notification.ref.show({message: res.data.error, isError: true});
            }
        });
    }
}
