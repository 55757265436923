import * as organizationActionsConstants from '../../constants/actions/organizationsActions';
import * as globalService from '../../services/globalService';
import { notification } from '../../helperFunctions';
import { closeDrawer } from '../../DrawerActions.js';
import responseCodes from '../../constants/responseCodes';

export function uploadCSVSource(data) {
    return (dispatch) => {
        globalService.uploadSourceWithCSV(data).then((response) => {
            dispatch(closeDrawer());
            if (response.data.response_code === responseCodes.OK)
                notification.ref.show({ message: 'Contacts will be created shortly.', isError: false });
        }).catch(error => {
            console.error(error);
        });
    };
}

export function getCSVSources() {
    return (dispatch) => {
        globalService.getCSVSources().then((response) => {
            dispatch({ type: organizationActionsConstants.GET_SOURCES_DROPDOWN_VALUES_SUCCESS, payload: response.data.payload });
        }).catch(error => {
            notification.ref.show({ message: error, isError: true });
        });
    };
}
