import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { View, TouchableOpacity, Modal } from 'react-native';
import {connect} from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { withGetScreen } from 'react-native-getscreen';
import TextField from '@material-ui/core/TextField';

//components
import Text from '../../../../components/Text/Text';
import FormInput from '../../../../components/FormInput/FormInput';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import slackIcon from '../../../../assets/svg/slackIcon.svg';
import newMemberAppUrl from '../../../../services/newMemberAppUrl';

//utils
import { notification } from '../../../../helperFunctions';

//Actions
import * as actions from './actions';

// Styles
import styles from './styles';


let Settings = ({ handleSubmit, currentSelectedOrg, userOrganizations, isMobile }) => {
    const web = !isMobile();

    const slackAuthoriztaion = useMemo(() => {
        if (currentSelectedOrg.label !== 'Boon') {
            const { organization } = userOrganizations.find((org) => org.organization.id === currentSelectedOrg.id);
            if (organization) {
                return organization.slack_authorization;
            }
        }
        return false;
    }, [userOrganizations, currentSelectedOrg]);

    return (
        <View style={styles.settingsWrapper}>
            <View style={styles.settings}>
                <View style={styles.changePasswordContainer}>
                    <View style={[web && styles.formWrapper, !web && {marginTop: 30}]}>
                        <Text style={styles.changePasswordText}>Change Password</Text>
                        <Field
                            placeholder="New password"
                            name={'password'}
                            component={FormInput}
                            wrapperStyle={styles.field}
                        />
                        <Field
                            placeholder="Confirm new password"
                            name={'confirm_password'}
                            component={FormInput}
                            wrapperStyle={styles.field}
                        />
                        <PrimaryButton onPress={handleSubmit}>
                            Save new password
                        </PrimaryButton>
                    </View>
                </View>
                {slackAuthoriztaion && (
                    <View style={styles.integrationsContainer}>
                        <Text style={styles.integrationHeading}>Integrations</Text>
                        <View style={styles.slackIntegrationContainer}>
                            <TouchableOpacity
                                onPress={() => window.location.href = `${newMemberAppUrl}/account/integrations/slack`}
                                style={styles.addToSlackBtn}
                            >
                                <img
                                    src={slackIcon}
                                    style={{ height: '22px' }}
                                />
                                <Text style={styles.addToSlackText}>Add to Slack</Text>
                            </TouchableOpacity>
                        </View>
                        <a
                            style={{
                                fontFamily: 'Roboto',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: 14,
                                textDecoration: 'underline',
                                color: '#0F73BC'
                            }}
                            target='_blank'
                            href='https://goboon.co/slack-app'
                        >
                            Learn more.
                        </a>
                    </View>
                )}
            </View>
        </View>
    );
};

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        userOrganizations: state.organizationsReducer.userOrganizations,
        currentSelectedOrg: state.authReducer.currentSelectedOrg,
        slackUserId: state.authReducer.slackUserId,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: data => {
            if (data.password !== data.confirm_password) {
                notification.ref.show({message: 'Passwords are not the same', isError: true});
            } else {
                dispatch(actions.changePassword(data.password));
            }
        }
    }
};

Settings = reduxForm({ form: 'changePassword' })(Settings);

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Settings));
