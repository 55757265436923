import React, { Component } from 'react';
import {connect} from 'react-redux';
import Actions from '../../RouteActions';

//components
import NetworkList from '../../components/NetworkList/NetworkList';

//actions
import * as actions from './actions';
import * as networkActions from '../../globalActions/networkActions';

class YourNetworkConnect extends Component {
    render() {
        const { requestConnection, clearConnections } = this.props;
        return (
            <NetworkList
                title="Your network"
                selectAllTitle="Connect with people you know"
                submitButton={() => 'Request connection'}
                onSkipPress={() => {
                    Actions.yourNetworkInvite();
                    clearConnections();
                }}
                onSubmitSelected={requestConnection}
                filter_type="not_connected_without_contacts"
                connect
            />
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        requestConnection: (connectionsArray, invertedSelection) => {
            dispatch(actions.requestConnection(connectionsArray, invertedSelection)).then(() => {
                dispatch(networkActions.clearConnections());
                Actions.yourNetworkInvite();
            });
        },
        clearConnections: () => {
            dispatch(networkActions.clearConnections())
        }
    }
};

export default connect(null, mapDispatchToProps)(YourNetworkConnect);