import { StyleSheet } from 'react-native';
import { $grey2, $green } from '../../generalStyles';

export default StyleSheet.create({
    bodyStyles: {
        width: 180,
        paddingRight: 5,
        minHeight: 100
    },
    newWrapperStyles: {
        marginBottom: 7,
        borderRadius: 5,
        marginTop: 5
    },
    salaryStyleHired: {
        fontSize: 12
    },
    salaryStyleNonHired: {
        fontSize: 12,
        fontWeight: 'bold',
        color: '#5C5C5C'
    },
    countStyles: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: 14,
        color: $green
    },
    countWrapper: {
        alignItems: 'center',
        width: 20,
        height: 20,
        borderRadius: 8,
        borderColor: $grey2,
        borderWidth: 1
    },
});
