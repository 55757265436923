import React, { useEffect, useState } from 'react';
import { Image } from "react-native";

const ImageWithFallback = ({ imageSource, fallbackSrc, resizeMode, imageStyles }) => {
  const [imgSrc, setImgSrc] = useState(imageSource);

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };

  return (
    <div>
      <Image
        source={imgSrc}
        resizeMode={resizeMode}
        style={imageStyles}
        onError={handleError}
      />
    </div>
  );
};

export default ImageWithFallback;
