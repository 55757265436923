import { PixelRatio } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
    profileWrapper: {
        width: "100%",
        display: 'flex',
        padding: 20,
        maxWidth: 1600 / PixelRatio.get(),
    },
    profile: {
        backgroundColor: 'white'
    }
});
