import { StyleSheet } from 'react-native';
import { $green, $greish } from '../../generalStyles';

export default StyleSheet.create({
    wrapperStyle: {
        paddingTop: 15,
        display: 'flex',
        flexDirection: 'row'
    }
});
