import Actions from '../../RouteActions';

import * as authActionsConstants from '../../constants/actions/auth';
import * as suggestionsActionsConstants from '../../constants/actions/suggestions';
import * as authService from '../../services/authService';

export function getCurrentUser(redirect = true) {
  return (dispatch) => {
    dispatch({ type: authActionsConstants.GET_CURRENT_USER });
    authService.getCurrentUser().then((response) => {
      dispatch({ type: authActionsConstants.GET_CURRENT_USER_SUCCESS, payload: response.data.payload });
      if (!response.data.payload.user.permissions_passed && redirect) {
        Actions.grantPermissions();
      }
    });
  };
}

export function clearSuggestions() {
  return (dispatch) => {
    dispatch({ type: suggestionsActionsConstants.CLEAR_SUGGESTIONS, payload: { type: 'globalSearch' } });
  };
}

export function setLocation(location) {
  return (dispatch) => {
    dispatch({ type: suggestionsActionsConstants.SET_LOCATION, payload: location });
  };
}
