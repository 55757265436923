import React from 'react';
import { View, Image } from 'react-native';
import styles from './styles';

//components
import Text from '../../components/Text/Text';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

const InvitationDetails = ({ invitationData = [] }) => {
    return (
        invitationData.length > 0 && (
            <View style={styles.organizationContainer}>
                {invitationData.map((invitation) => {
                    const { organization } = invitation;
                    const image = organization.logo_image_thumb
                        ? { uri: organization.logo_image_thumb }
                        : defaultOrgAvatar;

                    return (
                        <View
                            style={
                                invitationData.length <= 1
                                    ? styles.organizationBlockContainer
                                    : styles.organizationListContainer
                            }
                        >
                            <Image
                                source={image || defaultOrgAvatar}
                                style={styles.companyAvatar}
                                resizeMode="contain"
                            />
                            <Text style={styles.organizationHeading}>
                                {organization.name}
                            </Text>
                        </View>
                    );
                })}
            </View>
        )
    );
};

export default InvitationDetails;
