import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import qs from 'qs';
import { View, FlatList, TouchableOpacity, Image, StyleSheet } from 'react-native';
import Actions from '../../RouteActions';
import { urls } from '../../urls';

//components
import CandidateProgressBar from './CandidateProgressBar/CandidateProgressBar';
import Text from '../../components/Text/Text';
import JobListElement from '../../components/JobListElement/JobListElement';
import IneligibleJobListElement from '../../components/IneligibleJobListElement/IneligibleJobListElement';
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Spinner from 'react-native-loading-spinner-overlay';
import { openJobApplyDrawer } from '../../DrawerActions';
import WarningModal from './WarningModal';
//styles
import { listElement } from '../../generalStyles';
import styles from './styles';

//images
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import { openDeclineJobDrawer } from '../../DrawerActions';

//actions
import * as browseActions from '../../globalActions/browserActions';
import { getOrganizationsList } from '../../globalActions/organizationsActions';
import { resendReferralRequest, getReferUsers } from '../../globalActions/refersActions';

class OpportunityDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedReferralProviderId: null,
      strengthenProgress: 20,
      showWarningModal: false,
      anchorEl: null,
    };

    this.handleApplyPress = this.handleApplyPress.bind(this);
    this.renderReferredBy = this.renderReferredBy.bind(this);
    this.renderStatus = this.renderStatus.bind(this);
    this.declineJob = this.declineJob.bind(this);
    this.updateStrengthQuestionSettings = this.updateStrengthQuestionSettings.bind(this);
    this.calculateStrength = this.calculateStrength.bind(this);
    this.employerProfileLink = this.employerProfileLink.bind(this);
  }

  componentDidMount() {
    const searchParams = qs.parse(window.location.search.replace('?', ''));
    const { jobId, referralId, requestId, direction, status } = searchParams;

    const { getTrackerDetails, getReferralQuestions, getUsers, opportunityDetails } = this.props;

    if (direction === 'my') {
      getUsers(jobId);
    }
    getTrackerDetails({
      referral_id: referralId,
      request_id: requestId,
      job_id: jobId,
      direction,
    });
    if (direction === 'their' && status !== 'hired' && status !== 'archived') {
      getReferralQuestions(requestId, true);
    }
  }

  componentDidUpdate(prevProps) {
    const searchParams = qs.parse(window.location.search.replace('?', ''));
    const { direction, status } = searchParams;
    if (direction === 'their' && status !== 'hired' && status !== 'archived') {
      const { strengthenQuestionsData } = this.props;
      if (strengthenQuestionsData !== prevProps.strengthenQuestionsData) {
        this.calculateStrength();
      }
    }
  }

  resendReferral = (id) => {
    const { resendReferralRequest } = this.props;
    if (id) {
      resendReferralRequest(id);
    }
  };

  handleApplyPress() {
    const { selectedReferralProviderId } = this.state;
    const { opportunityDetails, currentUser, onGetOrganizationsList } = this.props;
    if (opportunityDetails.external_job_link) {
      window.open(opportunityDetails.external_job_link, '_blank');
      return;
  }

    if (opportunityDetails.category === 'general') {
      onGetOrganizationsList(opportunityDetails.organization_uuid);
    } else {
      const applyInitialValues = {
        job_id: opportunityDetails,
        main_referrer_id: selectedReferralProviderId,
        links_json: currentUser.links_json ? JSON.parse(currentUser.links_json) : [{ type: 'ln' }],
      };
      this.props.openJobApplyForm(applyInitialValues);
    }
  }

  renderStatus() {
    const { direction, opportunityDetails, firstReferral } = this.props;
    const referralSubject = opportunityDetails.referrals[0];
    const statusObjects = {
      offermy: {
        title: 'Offer',
        description: `Congratulations,${'\n'}an offer is coming your way!`,
        color: '#F9B74E',
      },
      offertheir: {
        title: 'Offer',
        description: `Good news,${'\n'}an offer has been sent to ${referralSubject && referralSubject.short_name}`,
        color: '#F9B74E',
      },
      hiredmy: {
        title: 'Hired',
        description: `You are now a new employee!${'\n'}See emails for more info.`,
        color: '#64CE92',
      },
      hired_and_archivedmy: {
        title: 'Hired',
        description: `You are now a new employee!${'\n'}See emails for more info.`,
        color: '#64CE92',
      },
      hiredtheir: {
        title: 'Hired',
        description: `Good news: ${
          referralSubject && referralSubject.short_name
        } has been hired,${'\n'}you should be notified soon via email for your Bounties.`,
        color: '#64CE92',
      },
      hired_and_archivedtheir: {
        title: 'Hired',
        description: `Good news: ${
          referralSubject && referralSubject.short_name
        } has been hired,${'\n'}you should be notified soon via email for your Bounties.`,
        color: '#64CE92',
      },
      passedmy: {
        title: 'Passed',
        description: `The organization passed on this application`,
        color: '#E8838F',
      },
      passedtheir: {
        title: 'Passed',
        description: `The organization passed on this application`,
        color: '#E8838F',
      },
      declinedmy: {
        title: 'Declined',
        description: `You declined${'\n'}during the recruiting process.`,
        color: '#8DB7E8',
      },
      declinedtheir: {
        title: 'Declined',
        description: `The applicant declined${'\n'}during the recruiting process.`,
        color: '#8DB7E8',
      },
      expiredmy: {
        title: 'Expired',
        description: `This refer has been expired.`,
        color: '#8DB7E8',
      },
      expiredtheir: {
        title: 'Expired',
        description: `This refer has been expired.`,
        color: '#8DB7E8',
      },
      not_your_refertheir: {
        title: 'Referal declined',
        description: `Unfortunately the user${'\n'}has chosen another referral provider.`,
        color: '#8DB7E8',
      },
    };

    const requiredObj = statusObjects[firstReferral.candidate_status + direction];
    return (
      <View style={[styles.statusWrapper, { backgroundColor: requiredObj && requiredObj.color }]}>
        <Text style={styles.statusTitle}>{requiredObj && requiredObj.title}</Text>
        <Text style={styles.statusDescription}>{requiredObj && requiredObj.description}</Text>
      </View>
    );
  }

  renderReferredBy({ item }) {
    const { selectedReferralProviderId } = this.state;
    const { direction, firstReferral } = this.props;
    const image = item.avatar_thumb ? item.avatar_thumb : defaultUserAvatar;

    return (
      <View style={styles.referWrapper}>
        <TouchableOpacity
          style={[listElement.elementWrapper, { backgroundColor: 'white' }]}
          onPress={item.id ? () => Actions.contactProfile({ userId: item.id }) : null}
        >
          <View style={listElement.imageWrapper}>
            <Image style={[listElement.image, listElement.imageRounded]} source={image} resizeMode="contain" />
          </View>
          <View style={[listElement.elementBody, listElement.last]}>
            <View style={listElement.infoWrapper}>
              <Text style={listElement.title} numberOfLines={1}>
                {item.full_name || item.name}
              </Text>
              <Text style={listElement.description} numberOfLines={1}>
                {item.email}
              </Text>
            </View>
            {direction === 'my' && (
              <View>
                {((selectedReferralProviderId === item.id || firstReferral.candidate_status !== 'new') &&
                  (Boolean(item.showSelected) || selectedReferralProviderId === item.id ? (
                    <ListElementButton style={{ marginLeft: 20 }} secondary disabled>
                      Selected
                    </ListElementButton>
                  ) : (
                    <Text />
                  ))) || (
                  <ListElementButton
                    style={{ marginLeft: 20 }}
                    onPress={() => this.setState({ selectedReferralProviderId: item.id })}
                  >
                    Select
                  </ListElementButton>
                )}
              </View>
            )}
          </View>
        </TouchableOpacity>
        {item.referral_message && (
          <View style={styles.messageBlock}>
            <Text style={styles.messageBlockTitle}>
              {direction === 'my' ? 'Message from' : 'Your message to'} {item.short_name}
            </Text>
            <View style={styles.messageTextWrapper}>
              <View style={styles.messageTextQuoteBlock} />
              <Text style={styles.messageText}>{item.referral_message}</Text>
            </View>
          </View>
        )}
      </View>
    );
  }

  updateStrengthQuestionSettings(strengthQuestionsSettings) {
    const renamedSettings = {
      know_the_person_level: 'know_the_person_level',
      worked_with_person: 'worked_with_person',
      open_for_new_opportunities_status: 'open_for_opportunities_status',
      how_good_are_they: 'skill_ids',
      attach_file: 'pdf_files',
      attach_url: 'links',
      diversity_hire: 'diversity_hire',
      license_available: 'license_available',
      know_the_person_level_radio: 'know_the_person_level_radio',
      why_are_they_good_fit: 'why_are_they_good_fit',
    };

    const renamedQuestionsSettings = {};

    for (const key in renamedSettings) {
      if (renamedSettings.hasOwnProperty(key)) {
        renamedQuestionsSettings[renamedSettings[key]] = strengthQuestionsSettings[key];
      }
    }

    return renamedQuestionsSettings;
  }

  calculateStrength = () => {
    const { strengthenQuestionsData } = this.props;
    if (_.isEmpty(strengthenQuestionsData) || !strengthenQuestionsData) return;

    const { strength_questions_settings, strength_questions_data } = strengthenQuestionsData;
    const renamed_strength_questions_settings = this.updateStrengthQuestionSettings(strength_questions_settings);
    const settingsLength = Object.values(renamed_strength_questions_settings).filter(Boolean).length;
    let progress = 20;

    for (let [key, value] of Object.entries(strength_questions_data)) {
      if (renamed_strength_questions_settings[key]) {
        if (Array.isArray(value) && value.length > 0) {
          progress += 80 / settingsLength;
        } else if (!Array.isArray(value) && value !== null && value !== undefined) {
          if (key === 'diversity_hire' && value === false) continue;
          progress += 80 / settingsLength;
        }
      }
    }
    this.setState({ strengthenProgress: ~~progress });
  };

  declineJob() {
    const { opportunityDetails, firstReferral, direction, currentUser } = this.props;
    this.setState({ anchorEl: null });
    if (opportunityDetails.referrals.length > 0 && opportunityDetails.referrals[0].id != currentUser.id) {
      const referInitialValues = opportunityDetails
        ? {
            job_id: {
              id: opportunityDetails.id,
              title: opportunityDetails.title,
            },
            organization_id: {
              id: opportunityDetails.organization_id,
              name: opportunityDetails.organization_name,
              logo_image_url: opportunityDetails.logo_image_thumb,
            },
            from_tracker: true,
          }
        : {};
      this.setState({ showWarningModal: false });
      this.props.openDeclineJobDrawer(referInitialValues);
    } else {
      this.setState({ showWarningModal: true });
    }
  }

  handleBackBtn = (direction) => {
    if (direction === 'my') {
      window.open(urls.tracker, '_self');
    } else {
      window.open(urls.sentJobs, '_self');
    }
  };

  updateAnchorEl = (newValue) => {
    this.setState({ anchorEl: newValue });
  };

  handleOnPress = (archivedStatuses, firstReferral, handleClose) => {
    archivedStatuses.indexOf(firstReferral.candidate_status) !== -1 ? null : this.declineJob();
    handleClose();
  };

  employerProfileLink = () => {
    const { opportunityDetails } = this.props;
    const regex = /\/p\/(.+)/;
    return opportunityDetails.employer_profile_link.match(regex)[1];
  };

  employerProfileLink = () => {
    const { opportunityDetails } = this.props;
    const regex = /\/p\/(.+)/;
    return opportunityDetails.employer_profile_link.match(regex)[1];
  };

  handleOpportunityPress = () => {
    const { opportunityDetails, onGetOrganizationsList } = this.props;
    if (opportunityDetails.category === 'department') {
      Actions.browseDepartmentDetail({ id: opportunityDetails.uuid });
    } else if (opportunityDetails.category === 'job') {
      Actions.browseJobDetail({ id: opportunityDetails.uuid });
    } else {
      onGetOrganizationsList(opportunityDetails.organization_uuid);
    }
  };

  render() {
    const {
      declineJob,
      opportunityDetails,
      opportunityDetailsLoading,
      currentUser,
      firstReferral,
      strengthenQuestionsData,
      candidate,
    } = this.props;
    const { refer_request_data } = strengthenQuestionsData || {};

    const searchParams = qs.parse(window.location.search.replace('?', ''));
    const { direction, status, requestId } = searchParams;
    const showRefsList = firstReferral.id !== currentUser.id;
    const { showWarningModal } = this.state;

    const handleDeclineJob = (opportunityId, candidateStatus, direction) => {
      this.setState({ showWarningModal: false });
      declineJob(opportunityId, candidateStatus, direction);
    };

    return (
      <DashboardBase
        hasBackButton
        onBackButtonPress={() => this.handleBackBtn(direction)}
        title={`${direction === 'my' ? 'Application' : 'Referral'} to ${
          opportunityDetails && opportunityDetails.organization_name ? opportunityDetails.organization_name : '...'
        }`}
      >
        {opportunityDetailsLoading && <Spinner visible />}
        {opportunityDetails && opportunityDetails.id && (
          <View style={styles.opportunityDetailsContainer}>
            {['new', 'applied', 'interview'].indexOf(firstReferral.candidate_status) === -1 && this.renderStatus()}
            <JobListElement
              job={opportunityDetails}
              tracker="true"
              isLast
              anchorEl={this.anchorEl}
              showViewJob={direction === 'my'}
              updateAnchorEl={this.updateAnchorEl}
              handleOnPress={this.handleOnPress}
              direction={direction}
              refCreatedAt={opportunityDetails.referrals[0] && opportunityDetails.referrals[0].referral_created_at}
              firstReferral={firstReferral}
              handleApplyPress={this.handleApplyPress}
              declineJob={declineJob}
              resendReferral={this.resendReferral}
            />
            {showWarningModal && (
              <WarningModal
                isOpen={showWarningModal}
                hideModal={() => {
                  this.setState({ showWarningModal: false });
                }}
                onSave={() => handleDeclineJob(opportunityDetails.id, firstReferral.candidate_status, direction)}
              />
            )}
            {direction === 'their' &&
              refer_request_data &&
              refer_request_data.tracker_status !== 'archived' &&
              refer_request_data.tracker_status !== 'hired' &&
              refer_request_data.strength_question_enable && (
                <View style={styles.strengthenWrapper}>
                  <View style={styles.strengthenText}>
                    <span>Referral Strength</span>
                    <span style={{ color: '#0FBC71' }}>{this.state.strengthenProgress}%</span>
                  </View>
                  <View style={styles.progressBar}>
                    <View
                      style={[
                        StyleSheet.absoluteFill,
                        { borderRadius: 8, backgroundColor: '#0FBC71', width: `${this.state.strengthenProgress}%` },
                      ]}
                    />
                  </View>
                  <View style={styles.strengthenButton}>
                    <PrimaryButton
                      style={styles.secondaryButton}
                      small
                      disabled={this.state.strengthenProgress === 100}
                      onPress={() => {
                        window.open(
                          `${
                            this.props.adminAppUrl
                          }/company/${this.employerProfileLink()}/referral/${requestId}?orgUuid=true`,
                          '_self'
                        );
                      }}
                    >
                      Strengthen Referral
                    </PrimaryButton>
                  </View>
                </View>
              )}

            {direction === 'my' && <CandidateProgressBar data={candidate} />}
          </View>
        )}
      </DashboardBase>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
    opportunityDetails: state.trackerReducer.opportunityDetails,
    strengthenQuestions: state.trackerReducer.strengthenQuestions,
    strengthenQuestionsData: state.trackerReducer.strengthenQuestionsData,
    adminAppUrl: state.authReducer.adminAppUrl,
    opportunityDetailsLoading: state.trackerReducer.opportunityDetailsLoading,
    candidate: state.trackerReducer.candidateStrengthenQuestionsData,
    firstReferral:
      state.trackerReducer.opportunityDetails &&
      state.trackerReducer.opportunityDetails.referrals &&
      state.trackerReducer.opportunityDetails.referrals.length
        ? state.trackerReducer.opportunityDetails.referrals[0]
        : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrackerDetails: (params) => {
      dispatch(browseActions.getTrackerDetails(params));
    },
    getReferralQuestions: (referRequestId, reStrengthenReferral = false) => {
      dispatch(browseActions.getReferralQuestions(referRequestId, reStrengthenReferral));
    },
    clearOpportunityDetails: () => {
      dispatch(browseActions.clearOpportunityDetails());
    },
    declineJob: (jobId, status, direction) => {
      dispatch(browseActions.declineJob(jobId, status, direction));
    },
    openDeclineJobDrawer: (data) => {
      dispatch(openDeclineJobDrawer(data));
    },
    resendReferralRequest: (id) => {
      dispatch(resendReferralRequest(id));
    },
    openJobApplyForm: (data) => {
      dispatch(openJobApplyDrawer(data));
    },
    onGetOrganizationsList: (orgUuid) => {
      dispatch(
        getOrganizationsList(
          () => {},
          orgUuid,
          () => Actions.browseAll()
        )
      );
    },
    getUsers: (job_id, job_uuid) => {
      return dispatch(getReferUsers(job_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetails);
