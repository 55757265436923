import { find, findIndex } from 'lodash-es';

import * as suggestionsActionsConstants from '../constants/actions/suggestions';
import * as connectionsActionsConstants from '../constants/actions/connectionsActions';
import * as organizationsActionsConstants from '../constants/actions/organizationsActions';

const initialState = {
  queryString: null,
  location: {},
  globalSearch: {
    suggestions: [],
    suggestionsLoading: false,
  },
  organizationsSuggestions: {
    suggestions: [],
    suggestionsLoading: false,
  },
  jobSearch: {
    suggestions: [],
    suggestionsLoading: false,
  },
  networkSuggestions: {
    suggestions: [],
    suggestionsLoading: false,
  },
  trackerSuggestions: {
    suggestions: [],
    suggestionsLoading: false,
  },
  contactSourcesSuggestions: {
    suggestions: [],
    suggestionsLoading: false,
  },
};

const suggestionsReducer = (state = initialState, action) => {
  let suggestions = [];
  switch (action.type) {
    case suggestionsActionsConstants.SET_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
      };
    case suggestionsActionsConstants.SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };
    case suggestionsActionsConstants.GET_SUGGESTIONS:
      return {
        ...state,
        [action.payload.type]: {
          suggestions: [],
          suggestionsLoading: true,
        },
      };
    case suggestionsActionsConstants.CLEAR_SUGGESTIONS:
      return {
        ...state,
        queryString: '',
        [action.payload.type]: {
          suggestions: [],
        },
      };
    case suggestionsActionsConstants.GET_SUGGESTIONS_SUCCESS: {
      let data = action.payload.suggestions;
      if (action.payload.type === 'jobSearch' && action.payload.suggestions.length === 0) {
        data = [{ jobs: [] }];
      }
      return {
        ...state,
        [action.payload.type]: {
          suggestions: data,
          suggestionsLoading: false,
        },
      };
    }
    case suggestionsActionsConstants.GET_SUGGESTIONS_ERROR:
      return {
        ...state,
        [action.payload.type]: {
          suggestionsLoading: false,
          suggestions: [],
        },
      };
    case connectionsActionsConstants.REQUEST_CONNECTION_SUCCESS:
      suggestions = state.globalSearch.suggestions.slice();
      if (suggestions[2]) {
        const changedConnectionIndex = findIndex(suggestions[2].data.connections, (contact) => {
          return contact.id === action.payload.connectionId;
        });

        if (changedConnectionIndex !== -1) {
          suggestions[2].data.connections[changedConnectionIndex] = {
            ...suggestions[2].data.connections[changedConnectionIndex],
            status: action.payload.status,
          };
        }
      }

      return {
        ...state,
        globalSearch: {
          suggestions,
        },
      };
    case organizationsActionsConstants.FOLLOW_ORGANIZATION_SUCCESS:
      suggestions = state.globalSearch.suggestions.slice();
      if (suggestions.length) {
        const changedOrg = find(suggestions[0].data, {
          name: action.payload,
        });

        if (changedOrg) {
          changedOrg.is_following = true;
        }
      }

      return {
        ...state,
        globalSearch: {
          suggestions,
        },
      };
    case organizationsActionsConstants.UNFOLLOW_ORGANIZATION_SUCCESS:
      suggestions = state.globalSearch.suggestions.slice();
      if (suggestions.length) {
        const changedOrg = find(suggestions[0].data, {
          id: action.payload,
        });

        if (changedOrg) {
          changedOrg.is_following = false;
        }
      }

      return {
        ...state,
        globalSearch: {
          suggestions,
        },
      };
    case suggestionsActionsConstants.SET_NETWORK_SUGGESTIONS_LOADING:
      return {
        ...state,
        [action.payload.type]: {
          suggestions: [],
          suggestionsLoading: true,
        },
      };
    default:
      return state;
  }
};

export default suggestionsReducer;
