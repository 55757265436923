import React from 'react'
import defaultOrgAvatar from "../../../assets/img/default_org_avatar.png";
import StrengthBar from '../StrengthBar';


const ReferUserDetails = ({
    formValues : {
    candidate_message,
    email,
    employer_message,
    first_name ,
    last_name,
    phone_number,
    job_id : {title : jobName},
    organization_id: {name : orgName},
    linkedin_link,
  },
    progressValues
  }) => {
  return (
    <>
        <div className="candidate-info-top">Candidate Information</div>
        <div className="candidate-info">
          <div className="name">{first_name.concat(" ", last_name)}</div>
          <div className="email">{email}</div>
          <div className="email">{phone_number}</div>
          <div className="email">{linkedin_link}</div>
        </div>
        <div className="candidate-info-top">Opportunity Info</div>
        <div className="org-info candidate-info">
          <img src={defaultOrgAvatar} alt="in" />
          <div className='org-detail'>
          <div className="name">{jobName}</div>
          <div className="email">{orgName}</div>
          </div>

        </div>
        <div className="employer-message-top">Message to Individual</div>
        <div className="employer-message">{candidate_message}</div>
        <div className="employer-message-top">Message to Employer</div>
        <div className="employer-message">{employer_message}</div>
        <StrengthBar progressValues={progressValues}/>
  </>
  )
}

export default ReferUserDetails
