import moment from 'moment';

import * as activityActionsConstants from '../../../../constants/actions/activityActions';
import * as activityService          from '../../../../services/activityService';
import * as AsyncStorage             from '../../../../AsyncStorage';

export function getActivityList(params) {
    return dispatch => {
        dispatch({
            type: activityActionsConstants.GET_ACTIVITY_LIST,
            payload: {
                isPagination: Boolean(params && params.offset)
            }
        });
        activityService.getActivityList(params).then(response => {
            const isPagination = Boolean(params && params.offset);
            let activities = response.data.payload.activities;
            AsyncStorage.checkHidedRecommendations().then(hidedAt => {
                const shouldHideRecommendations = hidedAt === moment().format('YYYY DD MM mm');

                if (response.data.payload.recommendations.length && !shouldHideRecommendations) {
                    activities = [{id: 0, action: 'recommendations', data: response.data.payload.recommendations}, ...activities];
                    AsyncStorage.clearRecommendationsHiding();
                }

                dispatch({
                    type: activityActionsConstants.GET_ACTIVITY_LIST_SUCCESS,
                    payload: {
                        activities,
                        isPagination
                    }
                });
            });
        }).catch(() => {
            dispatch({type: activityActionsConstants.GET_ACTIVITY_LIST_ERROR});
        });
    };
}

export function likeActivity(activityId) {
    return dispatch => {
        dispatch({
            type: activityActionsConstants.LIKE_ACTIVITY,
            payload: activityId
        });
        activityService.likeActivity(activityId).then(() => {
            dispatch({
                type: activityActionsConstants.LIKE_ACTIVITY_SUCCESS,
                payload: activityId
            });
        }).catch(() => {
            dispatch({type: activityActionsConstants.LIKE_ACTIVITY_ERROR, payload: activityId});
        });
    };
}

export function unlikeActivity(activityId) {
    return dispatch => {
        dispatch({
            type: activityActionsConstants.UNLIKE_ACTIVITY,
            payload: activityId
        });
        activityService.unlikeActivity(activityId).then(() => {
            dispatch({
                type: activityActionsConstants.UNLIKE_ACTIVITY_SUCCESS,
                payload: activityId
            });
        }).catch(() => {
            dispatch({type: activityActionsConstants.UNLIKE_ACTIVITY_ERROR, payload: activityId});
        });
    };
}

export function hideRecommendations() {
    return dispatch => {
        AsyncStorage.hideRecommendationsForToday().then(() => {
            dispatch({type: activityActionsConstants.HIDE_RECOMMENDATIONS});
        });
    }
}
