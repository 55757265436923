import React, {Component} from 'react';
import { View, Animated } from 'react-native';
import { getSecret }      from '../../helpers/secureKeyStore';
import { GOOGLE_PLACES_API_KEY }    from '../../constants/secureKeyStore';
import GooglePlacesAutocomplete  from '../../wrappers/googlePlaces/googlePlaces';
import styles, { addressStyle }     from './styles';
export default class GoogleAddress extends Component {
    constructor (props) {
        super(props);
        this.state = {
            apiKey: '',
            isFocused: false
        };

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.animatedFontSize = new Animated.Value(14);
        this.animatedPosition = new Animated.Value(22);
        this.animatedColor = new Animated.Value(0);
    }

    componentDidMount() {
        getSecret(GOOGLE_PLACES_API_KEY).then(key => {
            this.setState({ apiKey: key });
        });
    }

    shouldComponentUpdate(_nextProps, nextState) {
        return this.state.apiKey !== nextState.apiKey;
    }

    parsePlace = (place, full_address) => {
        let address_components = place.address_components;

        let locationObj = {
            street_number: '',
            route: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            full_address: full_address
        }

        let componentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'long_name',
            country: 'short_name',
            postal_code: 'short_name'
        };

        for (var i = 0; i < address_components.length; i++) {
            var addressType = address_components[i].types[0];
            if (componentForm[addressType]) {
              let val = address_components[i][componentForm[addressType]];
              locationObj[addressType] = val;
            }
        }

        this.props.handleChange(locationObj);
        this.props.input.onChange(full_address);
    }

    onBlur = (e) => {
        const { input } = this.props;
        if (!input.value) {
            Animated.timing(this.animatedFontSize, {
                toValue: 14,
                duration: 200
            }).start();
            Animated.timing(this.animatedPosition, {
                toValue: 22,
                duration: 200
            }).start();
            Animated.timing(this.animatedColor, {
                toValue: 0,
                duration: 200
            }).start();
        }
        input.onBlur(e);
    };

    onFocus = (e) => {
        const { input } = this.props;
        Animated.timing(this.animatedFontSize, {
            toValue: 10,
            duration: 200
        }).start();
        Animated.timing(this.animatedPosition, {
            toValue: 0,
            duration: 200
        }).start();
        Animated.timing(this.animatedColor, {
            toValue: 1,
            duration: 200
        }).start();
        input.onFocus(e);
    };

    render() {
        const { apiKey } = this.state;
        const { full_address, input, placeholder, labelStyle, wrapperStyle, inputStyle } = this.props;
        const labelStyles = [
            styles.labelStyle,
            labelStyle,
            {
                fontSize: this.animatedFontSize,
                top: this.animatedPosition,
                color: this.animatedColor.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#c1c1c1', '#0FBC71']
                })
            }
        ];

        if (input.value || full_address) {
            labelStyles.push(styles.labelStyleFocused);
        }

        return (
            <View style={[styles.wrapperStyle, wrapperStyle]}>
                { placeholder && <Animated.Text style={labelStyles}>{placeholder}</Animated.Text> }

                <GooglePlacesAutocomplete
                    placeholder={''}
                    inputStyle={inputStyle}
                    minLength={3}
                    returnKeyType={'default'}
                    fetchDetails={true}
                    listViewDisplayed='auto'
                    currentLocation={false}
                    styles={addressStyle}
                    listViewDisplayed={false}
                    debounce={3}
                    onSelectPress={this.parsePlace}

                    query={{
                        key: apiKey,
                        language: 'en'
                    }}

                    onPress={(data, details = null) => {
                        this.parsePlace(details, data.description)
                    }}

                    textInputProps={{
                        onBlur: this.onBlur,
                        onFocus: this.onFocus
                    }}

                    getDefaultValue={() => full_address ? full_address : ''}
                />
            </View>
        )
    }
}
