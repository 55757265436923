import { StyleSheet, PixelRatio } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  publicHeader: {
    height: 65,
    backgroundColor: "#FFF",
    boxShadow: "0px 2px 4px rgba(147, 157, 177, 0.1)",
    width: 1440,
    marginTop: 0,
    marginRight: "auto",
    marginBottom: 0,
    display: "grid",
    gridTemplateColumns: "15% 70% 15%",
    marginLeft: "auto",
  },
  loginButton: {
    borderColor: $green,
    borderWidth: 1,
  },
  commonButton: {
    width: "90px",
  },
  buttonsContainerFlex: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  logoImage: {
    height: 30,
    width: 30,
    marginRight: 12,
    borderRadius: 3,
    borderWidth: 0.5,
    borderColor: "#EBF0F3",
  },
  organizationDetailsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  organizationName: {
    height: 16,
    fontFamily: "Roboto-Regular",
    fontWeight: "500",
    fontSize: 14,
    color: "#9B9B9B",
  },
});
