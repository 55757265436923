import React, { Component } from 'react';
import {
    View,
    TouchableOpacity,
    Keyboard,
    Image
} from 'react-native';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withGetScreen } from 'react-native-getscreen';

//components
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput from '../../components/FormInput/FormInput';
import SignUpBase from "../SignUpBase/SignUpBase";
import Text from '../../components/Text/Text';
import { LOGIN_FORM_ERROR, SET_VIEW_SIGN_IN_FORM } from '../../constants/actions/auth';

//styles
import styles from './styles';

//actions
import * as forgotPasswordActions from '../ForgetPassword/actions';
import * as emailActions from '../SignInEmail/actions';

//util
import { notification } from '../../helperFunctions';

//images
import logo from '../../assets/img/logo.png';
import GlobalGradient from '../../components/GlobalGradient/GlobalGradient';

class SignInPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewSignInForm: true
        };
    }

    componentDidMount() {
        const { resetSignInForm } = this.props;
        resetSignInForm(true);
    }

    handleFocus = () => {
        const { loginFormError, resetFieldError } = this.props;
        if (loginFormError) {
            resetFieldError();
        }
    }

    resetPasswordForm = () => {
        const { resetSignInForm } = this.props;
        resetSignInForm(false);
    }

    resetPassword = () => {
        const { formValues, requestResetPassword } = this.props;
        const email = formValues.email;

        if (email && email.length > 0) {
            requestResetPassword({ email: email })
        }
        else {
            notification.ref.show({ message: 'Please submit email first.', isError: true });
        }
    }

    renderMobileForm = () => {
        const { handleSubmit, loginFormError, viewSignInForm } = this.props;
        const mobile = this.props.isMobile();
        if (viewSignInForm) {
            const mainHeading = 'Welcome back!'
            const subHeading = 'Please Sign in with your work email below.'

            return (
                <SignUpBase mobile={mobile} mainHeading={mainHeading} subHeading={subHeading}>
                    <View>
                        <Field
                            placeholder="Work Email"
                            name={'email'}
                            component={FormInput}
                            wrapperStyle={styles.inputWrapper}
                            keyboardType="email-address"
                            autoFocus={true}
                            inputStyle={loginFormError && styles.errorFields}
                            onSubmitEditing={this.handleContinue}
                            onFocus={() => this.handleFocus()}
                        />
                        <Field
                            placeholder="Password"
                            name={'password'}
                            component={FormInput}
                            wrapperStyle={styles.inputWrapper}
                            inputStyle={loginFormError && styles.errorFields}
                            secureTextEntry
                            onSubmitEditing={handleSubmit}
                        />
                        <PrimaryButton onPress={handleSubmit}>
                            Sign In
                        </PrimaryButton>
                        <View>
                            <TouchableOpacity onPress={() => this.resetPasswordForm()}>
                                <Text style={styles.forgotPassword}>
                                    Forgot your password?
                            </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </SignUpBase>
            )
        } else {
            const mainHeading = 'Forgot your password?'
            const subHeading = "Don't worry. Tell us your email address and we'll get you back on track in no time. "

            return (
                <SignUpBase mobile={mobile} mainHeading={mainHeading} subHeading={subHeading}>
                    <View>
                        <Field
                            placeholder="Email Address"
                            name={'email'}
                            component={FormInput}
                            wrapperStyle={styles.inputWrapper}
                            keyboardType="email-address"
                            autoFocus={true}
                            inputStyle={loginFormError && styles.errorFields}
                            onSubmitEditing={this.handleContinue}
                            onFocus={() => this.handleFocus()}
                        />
                        <PrimaryButton onPress={this.resetPassword}>
                            Reset Password
                        </PrimaryButton>
                    </View>
                </SignUpBase>
            )
        }
    }

    renderWebForm = () => {
        const { handleSubmit, loginFormError, viewSignInForm } = this.props;

        if (viewSignInForm) {
            const mainHeading = 'Welcome back!'
            const subHeading = 'Please Sign in with your work email below.'

            return (
                <View style={styles.signUpForm}>
                    <Text style={styles.boonHeading}>{mainHeading}</Text>
                    <View>
                        <Text style={styles.signupText}>{subHeading}</Text>
                    </View>
                    <Field
                        placeholder="Work Email"
                        name={'email'}
                        component={FormInput}
                        wrapperStyle={styles.inputWrapper}
                        keyboardType="email-address"
                        autoFocus={true}
                        inputStyle={loginFormError && styles.errorFields}
                        onSubmitEditing={this.handleContinue}
                        onFocus={() => this.handleFocus()}
                    />
                    <Field
                        placeholder="Password"
                        name={'password'}
                        component={FormInput}
                        wrapperStyle={styles.inputWrapper}
                        inputStyle={loginFormError && styles.errorFields}
                        secureTextEntry
                        onSubmitEditing={handleSubmit}
                    />
                    <PrimaryButton onPress={handleSubmit}>
                        Sign In
                    </PrimaryButton>
                    <View>
                        <TouchableOpacity onPress={() => this.resetPasswordForm()}>
                            <Text style={styles.forgotPassword}>
                                Forgot your password?
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            )
        } else {
            const mainHeading = 'Forgot your password?'
            const subHeading = "Don't worry. Tell us your email address and we'll get you back on track in no time. "

            return (
                <View style={styles.signUpForm}>
                    <Text style={styles.boonHeading}>{mainHeading}</Text>
                    <View>
                        <Text style={styles.signupText}>{subHeading}</Text>
                    </View>
                    <Field
                        placeholder="Email Address"
                        name={'email'}
                        component={FormInput}
                        wrapperStyle={styles.inputWrapper}
                        keyboardType="email-address"
                        autoFocus={true}
                        inputStyle={loginFormError && styles.errorFields}
                        onSubmitEditing={this.handleContinue}
                        onFocus={() => this.handleFocus()}
                    />
                    <PrimaryButton onPress={this.resetPassword}>
                        Reset Password
                    </PrimaryButton>
                </View>
            )
        }
    }

    render() {
        const { isMobile } = this.props;
        return (
            <View>
                {isMobile() ? this.renderMobileForm() :
                    <View style={{ flex: 1, flexDirection: 'row' }}>
                        <View style={{ width: '30%', height: '100%', backgroundColor: 'white' }} >
                            {this.renderWebForm()}
                        </View>
                        <View style={{ width: '70%', height: '100%' }} >
                            <GlobalGradient gradients={['#47e5a8', '#0FBC71']} angle='180deg'>
                                <View style={styles.logoWrapper}>
                                    <Image style={styles.logo} source={logo} />
                                </View>
                            </GlobalGradient>
                        </View>
                    </View>
                }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.signInPassword && state.form.signInPassword.values ? state.form.signInPassword.values : {},
        signInLoading: state.authReducer.signInLoading,
        loginFormError: state.authReducer.loginFormError,
        viewSignInForm: state.authReducer.viewSignInForm
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSubmit: values => {
            Keyboard.dismiss();
            if (!values.password && !values.magic_token) {
                notification.ref.show({ message: 'Password is required', isError: true });
            } else {
                values.oldAccount = 1;
                dispatch(emailActions.signIn(values));
            }
        },
        resetFieldError: () => {
            dispatch({ type: LOGIN_FORM_ERROR, payload: false });
        },
        resetSignInForm: (payload) => {
            dispatch({ type: SET_VIEW_SIGN_IN_FORM, payload: payload });
        },
        requestResetPassword: email => {
            dispatch(forgotPasswordActions.resetPassword(email, "login"));
        },
    };
};

SignInPassword = reduxForm({ form: 'signInPassword' })(SignInPassword);

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(SignInPassword));
