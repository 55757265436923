import * as authService from '../../services/authService';
import { notification } from '../../helperFunctions';

export function signIn(formData, params) {
  return () => {
    authService.ssoLogin(formData).then((response) => {
      window.location.href = response.data.url;
    })
    .catch((err) =>{
      notification.ref.show({
        message: 'User does not exist with this PSID',
        isError: true
    });

    });
  };
}
