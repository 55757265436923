import * as browseActionConstants from '../constants/actions/browseActions';

const initialState = {
    activeType: 'all', // all | job | department
    list: {
        page_info: {
            count: 0,
            page: 1,
            pages: 1,
            prev: null,
            next: null
        },
        result: []
    },
    listLoading: false,
    jobDetails: null,
    departmentDetails: null,
    detailsLoading: false,
    employer: {},
    candidateData: null,
    filters: null
};

const browseReducer = (state = initialState, action) => {
    switch (action.type) {
        case browseActionConstants.GET_BROWSE_ALL:
            return {
                ...state,
                activeType: action.payload.type,
                list: action.payload.init || action.payload.type !== state.activeType? initialState.list : state.list,
                listLoading: true
            };
        case browseActionConstants.GET_BROWSE_ALL_SUCCESS:
            return {
                ...state,
                list: action.payload,
                listLoading: false
            };
        case browseActionConstants.GET_BROWSE_ALL_ERROR:
            return {
                ...state,
                listLoading: false
            };
        case browseActionConstants.GET_BROWSE_DETAILS:
            return {
                ...state,
                detailsLoading: true,
                jobDetails: action.payload.type === 'job' ? null : state.jobDetails,
                departmentDetails: action.payload.type === 'department' ? null : state.departmentDetails,
                employer: {}
            };
        case browseActionConstants.GET_BROWSE_DETAILS_SUCCESS:
            return {
                ...state,
                jobDetails: action.payload.type === 'job' ? action.payload.details : state.jobDetails,
                departmentDetails: action.payload.type === 'department' ? action.payload.details : state.departmentDetails,
                employer: action.payload.employer,
                detailsLoading: false
            };
        case browseActionConstants.GET_BROWSE_DETAILS_ERROR:
            return {
                ...state,
                detailsLoading: false
            };
        case browseActionConstants.GET_CANDIDATE_BROWSE_DATA:
            return {
                ...state,
                candidateData: {},
                detailsLoading: true
            };
        case browseActionConstants.GET_CANDIDATE_BROWSE_DATA_SUCCESS:
            return {
                ...state,
                candidateData: action.payload,
                detailsLoading: false 
            };
        case browseActionConstants.GET_CANDIDATE_BROWSE_DATA_ERROR:
            return {
                ...state,
                candidateData: {},
                detailsLoading: false
            };
        case browseActionConstants.UPDATE_CANDIDATE_BROWSE_DATA:
            return {
                ...state,
                candidateData: {applied:true, declined: false}
            };
        case browseActionConstants.SET_BROWSE_FILTERS:
            return {
                ...state,
                filters: action.payload || null
            };
        case browseActionConstants.CLEAR_BROWSE_FILTERS:
            return {
                ...state,
                filters: null
            };
        default:
            return state;
    }
};

export default browseReducer;
