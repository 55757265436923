import baseService from './baseService.js';

export function getActivityList(params) {
    return baseService.get('/applicants/activities', {params});
}

export function getLeaderBoardList(params) {
    return baseService.get('/applicants/leaderboard', {params});
}

export function likeActivity(activityId) {
    return baseService.post(`/applicants/activities/${activityId}/like`);
}

export function unlikeActivity(activityId) {
    return baseService.post(`/applicants/activities/${activityId}/unlike`);
}

export function passRecommendation(recommendationId) {
    return baseService.post(`/applicants/recommendations/pass`, {recommendations: [recommendationId]});
}
