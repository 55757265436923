import React from 'react';
import BoonTextField from '../Basic/BoonTextField';

export default function FormInputNew(props) {
  const { input, ...inputProps } = props;

  return (
    <BoonTextField
      {...inputProps}
      onChange={input.onChange}
      value={input.value}
      onBlur={(e)=> {props.setNameWarning && props.setNameWarning(e.target.value.length === 0)}}
    />
  );
}
