import { StyleSheet } from "react-native";
import { $green, $gre, $greish } from "../../../generalStyles";

export default StyleSheet.create({
  title: {
    fontSize: 15,
    fontWeight: "bold",
  },
  detailsWrapper: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#e7e7e7",
  },
  description: {
    fontSize: 14,
  },
  full_addressMobile: {
    width: "50%",
    marginTop: 10,
  },
  full_address: {
    width: "100%",
    marginTop: 10,
  },
  mapMobile: {
    width: "100%",
    height: 200,
    marginTop: 10,
  },
  map: {
    width: "100%",
    height: 300,
    marginTop: 10,
  },
  locationText: {
    fontSize: 16,
  },
  image: {
    width: 60,
    height: 60,
    marginRight: 12,
    borderRadius: 5,
  },
  tagsWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  tag: {
    marginBottom: 8,
    paddingTop: 10,
    alignSelf: "stretch",
    width: "auto",
    paddingHorizontal: 8,
  },
  skillTag: {
    width: 74,
    borderRadius: 3,
    paddingVertical: 5,
    margin: 4,
    backgroundColor: "#EBF0F3",
  },
  skillTagText: {
    fontSize: 12,
    color: "#444444",
    textAlign: "center",
  },
  company: {
    fontSize: 23,
    fontFamily: "Roboto-Regular",
  },
  detailsContainer: {
    width: "90%",
    padding: 5,
  },
  detailsContainerMobile: {
    width: "80%",
    padding: 5,
  },
});
