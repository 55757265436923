import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const BoonTextField = withStyles({
  root: (props) => {
    const backgroundColor = props.labelBg || 'transparent';

    return {
      '& label.Mui-focused': {
        background: backgroundColor,
        color: '#CCCCCC',
      },
      '& label.Mui-focused.Mui-error': {
        color: 'red',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#CCCCCC',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#CCCCCC',
        },
        '& fieldset.Mui-error': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: '#CCCCCC',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#818181',
        },
        '&.Mui-focused.Mui-error fieldset ': {
          borderColor: 'red',
        },
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        '&:hover fieldset': {
          borderColor: 'red',
        },
      },
    };
  },
})(TextField);

export default BoonTextField;
