import React from 'react'
import ReferSelection from '../../components/ReferSelection/ReferSelection'
import SelectCompany from '../SelectCompany/SelectCompany'
import SelectPosition from '../SelectPosition/SelectPosition'
import SelectUser from '../SelectUser/SelectUser'

const ReferDropDownSelections = (
    {showSelection : {title, type}, orgId, onSelectOpening, onSelectCompany, onEndUserSelect, clearState, selectingOrganization}) =>
    <ReferSelection
        title={title}
        onClose={clearState}
    >
        {type === "opening" &&
            <SelectPosition
            selectFullObj={true}
            onSelectPosition={onSelectOpening}
            skipNavOnBackPress
            emptySearch={true}
            organizationId={ orgId && orgId.id }
            referSearch={true}
            referDrawer={false}
            showAllOnLoad={true}
            page={"refer"}
            />}
        {type === "organization" &&
            <SelectCompany
            selectFullObj={true}
            onSelectCompany={onSelectCompany}
            emptySearch={selectingOrganization}
            showAllOnLoad={true}
            page="refer"
            />}
        {type === "contact" &&
            <SelectUser
            onEndUserSelect={onEndUserSelect}
            skipNavOnBackPress
            referDrawer={true}
            customPlaceholder="Search contacts"
            autoFocus
            showAllOnLoad={true}
            page="refer"
            clearState={clearState}
            />}
    </ReferSelection>


export default ReferDropDownSelections
