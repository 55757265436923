import React, { Component } from 'react';
import {
    View,
    Image,
    Keyboard,
    ScrollView,
    TouchableOpacity
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GlobalGradient        from "../../components/GlobalGradient/GlobalGradient";
import styles               from '../SignUp/old-styles';
import ownStyles               from './styles';
import logo     from '../../assets/img/logo.png';
import Text                  from '../../components/Text/Text';
import Spinner     from 'react-native-loading-spinner-overlay';
import { connect }          from 'react-redux';
import { withRouter }       from "react-router";
import Actions from '../../RouteActions';
class SignUpBase extends Component {
    render() {
        const { mobile, signUpLoading, mainHeading, subHeading, firstHeading, currentUser } = this.props;
        return (
          <ScrollView>
            <GlobalGradient  gradients={['#32CF6C', '#2AAA89']} angle='90deg'>
              {currentUser && !mobile && window.Appcues && window.Appcues.identify(currentUser.id)}
              <KeyboardAwareScrollView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={[styles.pageWrapper]}
                enableAutomaticScroll>
                <View style={[styles.body]}>
                  {subHeading ? <View style={styles.flexCenter}>
                    <View style={mobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
                        <Image source={logo} style={mobile ? styles.mobileLogo : styles.logo}/>
                    </View>
                  </View> :
                  <View>
                    <View style={mobile ? styles.mobileLogoWrapper : ownStyles.logoWrapper}>
                        <Image source={logo} style={mobile ? styles.mobileLogo : ownStyles.logo}/>
                    </View>
                  </View>
                  }
                  <View style={styles.flexCenter}>
                  </View>
                  <View style={styles.flexCenter}>
                    <View style={mobile ? styles.mobileSignUpForm : styles.signUpForm}>
                      {this.props.children}
                    </View>
                  </View>
                  <TouchableOpacity style={[styles.flexCenter, {marginTop: '16px'}]} onPress={() => Actions.signInEmail()}>
                    <Text style={{color:'white'}}>Already have an account? <Text style={{textDecorationLine: 'underline'}}>Login</Text></Text>
                  </TouchableOpacity>
                </View>
                <Spinner visible={signUpLoading} />
              </KeyboardAwareScrollView>
            </GlobalGradient>
          </ScrollView>
        )
    }
}

const mapStateToProps = state => {
  return {currentUser: state.authReducer.currentUser}
};

const mapDispatchToProps = () => {
  return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUpBase));
