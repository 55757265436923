import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import {
    View,
    Image,
} from 'react-native';

import { connect }              from 'react-redux';

//component
import PrimaryButton                    from '../../components/PrimaryButton/PrimaryButton';
import FormInput                        from '../../components/FormInput/FormInput';
import Text                             from '../../components/Text/Text';
import GlobalGradient                   from "../../components/GlobalGradient/GlobalGradient";
import { KeyboardAwareScrollView }      from 'react-native-keyboard-aware-scroll-view';
import Spinner                          from 'react-native-loading-spinner-overlay';
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';
import * as refersActions from '../../globalActions/refersActions';
import FormInputCheckbox from '../../components/FormInputCheckbox/FormInputCheckbox';

//images
import { withGetScreen } from 'react-native-getscreen';
import logo              from '../../assets/img/logo.png';

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';

//utils
import routeActions from '../../RouteActions';
import { notification } from '../../helperFunctions';

class PublicJobDecline extends Component {
    componentDidMount() {
        const { getReasons, formValues, refer_token } = this.props;
        if (!refer_token){
            routeActions.home();
        }
        getReasons();
        formValues.share_refer = true;
    }

    submitForm = (e) => {
        e.preventDefault();
        const { formValues, refer_token } = this.props;
        formValues.refer_token = refer_token;
        if (!formValues.reason || !formValues.comments) {
            notification.ref.show({ message: 'Please fill all mandatory fields', isError: true });
        } else {
            this.props.onSubmit(formValues)
        }
    }

    createAccount = (e) => {
        e.preventDefault();
        const { refer_token } = this.props;
        this.props.createAccount({ refer_request_token: refer_token })
    }

    thankYouForm(){
        const mobile = this.props.isMobile();
        const firstHeading = 'Thank You!'
        const secondHeading = 'Your feedback has been received.'
        const thirdHeading = 'Refer a friend, Get rewarded'
        const fourthHeading = 'Create your own Boon account and get paid for referring great opportunities to people you know.'
        return (

            <GlobalGradient  gradients={['#32CF6C', '#2AAA89']} angle='90deg'>
              <KeyboardAwareScrollView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={[styles.pageWrapper]}
                enableAutomaticScroll>
                <View style={[styles.body]}>
                  <View style={styles.flexCenter}>
                    <View style={mobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
                        <Image source={logo} style={mobile ? styles.mobileLogo : styles.logo}/>
                    </View>
                    <Text style={mobile ? styles.mobileBoonHeading : styles.secondHeading}>{firstHeading}</Text>
                    <View>
                        <Text style={mobile ? styles.mobileSignupText : styles.thirdHeading}>{secondHeading}</Text>
                    </View>
                    <View>
                        <Text style={mobile ? styles.mobileSignupText : styles.secondHeading}>{thirdHeading}</Text>
                    </View>
                    <View>
                        <Text style={mobile ? styles.mobileSignupText : styles.fourthHeading}>{fourthHeading}</Text>
                    </View>

                  </View>
                    <View style={[styles.flexCenter, {zIndex: 200}]}>
                        <View style={mobile ? styles.mobileSignUpForm : styles.signUpForm}>
                            {/* <View style={[formLayout.row, { justifyContent: 'center' }]}>
                                <Text style={styles.thankyouText}>Refer a friend, Get rewarded</Text>
                            </View> */}
                            <View style={[formLayout.row, { justifyContent: 'center' }]}>
                                <PrimaryButton onPress={this.createAccount}>
                                    Create Account
                                </PrimaryButton>
                            </View>
                            <View style={[formLayout.row, { justifyContent: 'center' }, styles.noThanksButton]}>
                                <PrimaryButton onPress={()=> routeActions.base()} style={ styles.noThanksText } >
                                    Close
                                </PrimaryButton>
                            </View>
                        </View>
                    </View>
                </View>
              </KeyboardAwareScrollView>
            </GlobalGradient>
        );
    }

    onHandleReason = (e) => {
        this.props.setFieldValue('reason', e)
    }

    renderReferDeclineForm() {
        const mobile = this.props.isMobile();
        const { signUpLoading , formValues } = this.props;
        const firstHeading = 'Help us help you'
        const secondHeading = 'Where was the mismatch?'
        const thirdHeading = 'Your feedback improves the quality and relevance of future referrals.'
        const reasons = this.props.declineReasons;
        return (

            <GlobalGradient  gradients={['#32CF6C', '#2AAA89']} angle='90deg'>
              <KeyboardAwareScrollView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={[styles.pageWrapper]}
                enableAutomaticScroll>
                <View style={[styles.body]}>
                  <View style={styles.flexCenter}>
                    <View style={mobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
                        <Image source={logo} style={mobile ? styles.mobileLogo : styles.logo}/>
                    </View>
                    <View>
                        <Text style={mobile ? styles.mobileSignupText : styles.firstHeading}>{firstHeading}</Text>
                    </View>
                    <Text style={mobile ? styles.mobileBoonHeading : styles.secondHeading}>{secondHeading}</Text>
                    <View>
                        <Text style={mobile ? styles.mobileSignupText : styles.thirdHeading}>{thirdHeading}</Text>
                    </View>
                  </View>
                    <View style={[styles.flexCenter, {zIndex: 200}]}>
                        <View style={mobile ? styles.mobileSignUpForm : styles.signUpForm}>
                        <View>
                        { reasons &&
                            <View style={formLayout.row}>
                                <OrganizationStatus
                                    selected={reasons[0]}
                                    onChange={this.onHandleReason}
                                    name = 'reasons'
                                    placeholder = 'Reason(s) *'
                                    dropdownValues={reasons}
                                    styles={[formLayout.fullWidth, { marginTop: 9 }]}
                                    formSubmitted={() => {}}
                                    labelField={'label'}
                                    valueField={'value'}
                                    multi={true}
                                    customColor={'#2DB581'}
                                />
                            </View>
                        }

                        <View style={[formLayout.row , {zIndex: -9}]}>
                            <Field
                                component={FormInput}
                                name='comments'
                                placeholder='Comments *'
                                wrapperStyle={[formLayout.fullWidth , {zIndex: -9}]}
                                onSubmitEditing={() => this.lastNameRef.focus()}
                                returnKeyType="next"
                                autoFocus
                            />
                        </View>
                    </View>
                    <View style={[formLayout.row, { justifyContent: 'center', zIndex: -9 }]}>
                        <PrimaryButton onPress={this.submitForm}>
                            Submit
                        </PrimaryButton>
                    </View>
                    </View>
                  </View>
                </View>
                <Spinner visible={signUpLoading} />
              </KeyboardAwareScrollView>
            </GlobalGradient>
        );
    }

    render() {
        return (this.props.thankYouForm ? this.thankYouForm() : this.renderReferDeclineForm());
    }
}

PublicJobDecline = reduxForm({ form: 'decline_referral', enableReinitialize: true })(withGetScreen(PublicJobDecline));

const mapStateToProps = state => {
    return {
        formValues: state.form.decline_referral && state.form.decline_referral.values ? state.form.decline_referral.values : {},
        declineReasons: state.refersReducer.declineReasons,
        thankYouForm: state.refersReducer.thankYouForm
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('decline_referral', field, value));
        },
        onSubmit: formValues => {
            dispatch(refersActions.submitDeclineReferral(formValues));
        },
        getReasons: () => {
            return dispatch(refersActions.getDeclineReasons());
        },
        createAccount: (data) => {
            dispatch(refersActions.createUserAccount(data));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicJobDecline);
