import React, {Component, Fragment, useEffect} from 'react';
import {Provider}                   from 'react-redux';
import { PersistGate }              from 'redux-persist/integration/react';
import { View, Platform }           from 'react-native';
import EStyleSheet                  from 'react-native-extended-stylesheet';
import Spinner                      from 'react-native-loading-spinner-overlay';
import {connect}                    from 'react-redux';
import LogRocket from 'logrocket';

import factory                      from './store.js';
import Notification                 from './components/Notification/Notification';

import TabBarComponent from './components/TabBarComponent/TabBarComponent';

import Routes from './Routes';

import { notification }  from './helperFunctions';
import * as AsyncStorage from './AsyncStorage';


import { mainBody }      from './generalStyles'
import SurvicateTracker from './SurvicateTracker.js';

EStyleSheet.build({ $green: '#0FBC71' });
const {store, persistor} = factory();

class SpinnerWrapper extends Component {
    render() {
        const { spinnerVisible, listLoading, detailsLoading, applyJobLoading } = this.props;
        return (
            <Spinner visible={spinnerVisible || listLoading || detailsLoading || applyJobLoading}/>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        spinnerVisible: state.appReducer.spinnerVisible,
        listLoading: state.browseReducer.listLoading,
        detailsLoading: state.browseReducer.detailsLoading,
        applyJobLoading: state.trackerReducer.applyJobLoading
    };
};

const SpinnerWrapperComp = connect(
    mapStateToProps,
    null
)(SpinnerWrapper);

const VideoLogs = ({ currentUser }) => {
    useEffect(() => {
        if (process.env.REACT_APP_ENVIRONMENT === 'production' || 'demo') {
            LogRocket.init('baapnq/boon');

            if (!!currentUser.id) {
                LogRocket.identify(currentUser.id || currentUser.user.id, currentUser.user || currentUser);
            }
        }
    }, [currentUser]);

    return null;
}

const LogWrapper = connect(mapStateToProps, null)(VideoLogs);

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
            tokenIsLoading: true
        };

        this.updateToken = this.updateToken.bind(this);
        AsyncStorage.addAccessTokenObserver(this.updateToken);
    }

    updateToken(token) {
        if (token !== this.state.token) {
            this.setState({
                token: token,
                tokenIsLoading: false
            });
        }
    }

    componentWillMount() {
        AsyncStorage.getAccessToken().then(response => this.updateToken(response));
    }

    componentDidUpdate(prevProps) {
        // NOTE: in order to have access to this information, you will need
        // to wrap this component in the `withRouter` HOC

        //const { location: { pathname } } = this.props;
        //const previousLocation = prevProps.location.pathname;

        // if (pathname !== previousLocation) {
        if (Platform.OS === 'web') {
            if (window.Appcues) {
                // Ad-blockers may prevent Appcues from loading
                window.Appcues.page();
            }
        }
        // }
    }

    // _handleOpenURL(event) {
    //     console.warn('Handle "SPECIAL LINKS" on web');
    //     console.log(event.url);

    //     const url       = event.url.replace('boon://', '');
    //     const urlInfo   = url.split('?');
    //     const urlType   = urlInfo[0];
    //     const urlParams = qs.parse(urlInfo[1]);

    //     processSpecialUrls(urlType, urlParams);
    // }

    // processSpecialUrls(url, params) {
    //     console.log(urlType);
    //     console.log(urlParams);

    //     switch (urlType) {
    //         case 'google_contacts_sync_success':
    //             Actions.base({ googleConnectedSuccess: true });
    //             break;
    //         case 'confirm-email-tokens':
    //             Actions.signInPassword({
    //                 initialValues: {
    //                     magic_token:     urlParams.magic_token,
    //                     email:           urlParams.email,
    //                     is_confirmation: urlParams.is_confirmation
    //                 }
    //             });
    //             break;
    //         default:
    //             Actions.base();
    //             break;
    //         }
    //     //     case 'linkedin':
    //     //         switch (urlParams.type) {
    //     //             case 'error':
    //     //                 Alert.alert(urlParams.message);
    //     //                 break;
    //     //             case 'new_user':
    //     //                 const params = _.extend({}, urlParams);
    //     //                 delete params.type;
    //     //                 params.follow_company_name = {name: params.follow_company_name};
    //     //                 Actions.pop();
    //     //                 Actions.signUp({initialValues: params});
    //     //                 break;
    //     //             case 'existing_user':
    //     //                 baseService.addAuthToken(urlParams.access_token).then(() => Actions.initialScreen());
    //     //                 break;
    //     //         }
    //     //         break;
    //     // }
    // }


    render() {
        const {token, tokenIsLoading} = this.state;
        const tokenPresent = !!token && !tokenIsLoading;
        const state = store.getState();
        return (
            <View style={mainBody}>
                <Notification ref={ref => notification.ref = ref}/>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <SurvicateTracker />
                        <Fragment>
                            <SpinnerWrapperComp />
                            <LogWrapper />
                            <View style={{flex : 1}}>
                                {!tokenIsLoading && <Routes tokenPresent={tokenPresent} step={state.stepsReducer.step}/>}
                            </View>
                        </Fragment>
                    </PersistGate>
                </Provider>
            </View>
        );
    }
}
