import { StyleSheet } from 'react-native';
import { $background, $green } from '../../generalStyles';

export default StyleSheet.create({
  header: {
    position: 'relative',
    zIndex: 2,
    paddingHorizontal: 15,
    paddingTop: 20,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 2,
  },
  headerDescription: {
    fontSize: 14,
    color: '#000',
  },
  fromWrapper: {
    padding: 16,
    backgroundColor: '#fff',
  },
  row: {
    position: 'relative',
  },
  coverButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
  textAreaWrapper: {
    marginVertical: 16,
  },
  textArea: {
    backgroundColor: $background,
    borderBottomWidth: 0,
    borderRadius: 5,
    paddingTop: 16,
    paddingHorizontal: 16,
    minHeight: 94,
  },
  submitButtonDisabled: {
    backgroundColor: '#B5B5B5',
  },
  actionButtons: {
    backgroundColor: '#fff',
    color: $green,
    borderColor: '#c3c3c3',
    borderWidth: 1,
    borderRadius: 5,
  },
  inputWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderRadius: 2,
    borderColor: 'rgb(204, 204, 204)',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  inputStyle: {
    fontSize: 16,
    padding: 0,
    flex: 1,
  },
  backButtonStyle: {
    width: 24,
    height: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    borderColor: '#CCCCCC',
    borderStyle: 'solid',
    borderWidth: 2,
    cursor: 'pointer',
  },
  phoneRefer: {
    borderRadius: 4,
    borderColor: 'rgb(204, 204, 204)',
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 16,
    position: 'relative',
  },
  phoneIcon: {
    position: 'absolute',
    right: 15,
    height: 20,
    width: 20,
    top: 11
  },
  divider: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
  },
  leftDivider: {
    flex: 1,
    height: 1,
    backgroundColor: '#EEE',
  },
  dividerText: {
    width: 50,
    textAlign: 'center',
    color: '#C2C2C2',
  },
  rightDivider: {
    flex: 1,
    height: 1,
    backgroundColor: '#EEE',
  },
});
