import { StyleSheet, Dimensions, PixelRatio } from 'react-native';
import { $green } from '../../generalStyles';
const isMobile = window.screen.width < 500;

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  tabBar: {
    flexDirection: 'row',
    width: 350 / PixelRatio.get(),
  },
  tabButtonStyle: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 4,
    paddingHorizontal: 8,
    padding: 0,
    marginVertical: 15,
    marginHorizontal: 4,
    borderWidth: 1,
    borderColor: '#D1D8DC',
    borderRadius: 5,
    flexGrow: 1,
    flexShrink: 0,
  },
  tabButtonMobileStyle: {
    width: Dimensions.get('window').width / 3.25,
    backgroundColor: '#fff',
  },
  tabButtonActive: {
    borderColor: $green,
  },
  tabButtonTextStyle: {
    color: '#B7B7B7',
    fontSize: 14,
    textAlign: 'center',
  },
  searchIcon: {
    height: 20,
    width: 20,
  },
  noResultsText: {
    color: '#000000',
    fontSize: 16,
  },
  emptyListWrapper: {
    alignItems: 'left',
    marginTop: 12,
  },
  searchCont: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    zIndex: 9999,
    justifyContent: 'space-between',
    paddingRight: 2,
  },
});
