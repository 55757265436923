import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    formWrapper: {
        padding: 16
    },
    buttonsContainer: {
        flexDirection: 'row',
    },
    button: {
        width: '40%',
        marginLeft: '5%',
        marginRight: '5%',
    },
    clearFilters: {
        backgroundColor: '#D1D8DC',
    },
    jobFilterHeader: {
        backgroundColor: '#EAF0F3',
        padding: 5,
    },
    jobFilterHeaderText: {
        fontSize: 17,
    },
});
