import React from 'react';
import { View, Image } from 'react-native';
import { withRouter } from 'react-router';
import { withGetScreen } from 'react-native-getscreen';
import { ScrollView } from 'react-native-gesture-handler';

//components
import Text from '../../components/Text/Text';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

//helpers
import { normalizePrice, locationObjToStr } from '../../helperFunctions';

//styles
import { listElement } from '../../generalStyles';
import responsiveStyles from '../../responsiveStyles';
import styles from './styles';

const jobDetailStyle = {
    image: {
        width: 60,
        height: 60,
        marginRight: 12,
        borderRadius: 5
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    header: {
        fontSize: 14,
        paddingBottom: 3,
        fontWeight: 'bold'
    },
    description: {
        fontSize: 14
    },
    salary: {
        fontSize: 30,
        fontWeight: 'bold'
    },
    tagsWrapper: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    tag: {
        marginBottom: 8,
        alignSelf: 'stretch',
        width: 'auto',
        paddingHorizontal: 8
    },
    skillTag: {
        width: 74,
        borderRadius: 3,
        paddingVertical: 5,
        marginRight: 8,
        backgroundColor: '#EBF0F3'
    },
    skillTagText: {
        fontSize: 12,
        color: '#AAAAAA',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    buttonsWrapper: {
        flexDirection: 'row',
        marginVertical: 5
    },
    detailsWrapper: {
        padding: 24,
        borderBottomWidth: 1,
        borderBottomColor: '#e7e7e7'
    },
    map: {
        width: '100%',
        height: 300,
        marginTop: 10
    },
    mapMobile: {
        width: '100%',
        height: 200,
        marginTop: 10
    },
    locationText: {
        fontSize: 16
    },
    full_address: {
        width: '15%',
        marginTop: 10
    },
    full_addressMobile: {
        width: '50%',
        marginTop: 10
    },
    jobButtonGroup: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '2%'
    }
};

const mainContainerStyle = {
    backgroundColor: 'white',
    flex: 1
};
const height500 = {
    height: 500
};

const PublicReferredJobDetails = (props) => {
    const { referralObj, isMobile: isMobileFunc } = props;
    const details = referralObj && referralObj.job;
    const image =
        details && details.logo_url
            ? { uri: details.logo_url.image_url }
            : defaultOrgAvatar;
    const isMobile = isMobileFunc();

    const renderBasicInfo = () => {
        if (!details) return null
        const jobTypeName = details.job_type && details.job_type.name;
        const jobExperienceName =
            details.job_experience && details.job_experience.name;
        const jobUrgencyTypeName =
            details.job_urgency_type && details.job_urgency_type.name;
        const jobIndustryName =
            details.job_industry && details.job_industry.name;
        if (
            !jobTypeName &&
            !jobExperienceName &&
            !jobUrgencyTypeName &&
            !jobIndustryName
        )
            return null;

        return (
            <>
                <View>
                    <Text style={jobDetailStyle.title}>Basic Info</Text>
                </View>
                <View style={[jobDetailStyle.detailsWrapper, { flex: 1 }]}>
                    {Boolean(jobTypeName || jobExperienceName) && (
                        <View style={styles.row}>
                            {Boolean(jobTypeName) && (
                                <View style={styles.cell}>
                                    <Text style={jobDetailStyle.header}>
                                        Type
                                    </Text>
                                    <Text style={jobDetailStyle.description}>
                                        {jobTypeName}
                                    </Text>
                                </View>
                            )}
                            {Boolean(jobExperienceName) && (
                                <View style={styles.cell}>
                                    <Text style={jobDetailStyle.header}>
                                        Required Experience
                                    </Text>
                                    <Text style={jobDetail.description}>
                                        {jobExperienceName}
                                    </Text>
                                </View>
                            )}
                        </View>
                    )}
                    {Boolean(jobUrgencyTypeName || jobIndustryName) && (
                        <View style={styles.row}>
                            {Boolean(jobUrgencyTypeName) && (
                                <View style={styles.cell}>
                                    <Text style={jobDetailStyle.header}>
                                        Urgency
                                    </Text>
                                    <Text style={jobDetailStyle.description}>
                                        {jobUrgencyTypeName}
                                    </Text>
                                </View>
                            )}
                            {Boolean(jobIndustryName) && (
                                <View style={styles.cell}>
                                    <Text style={jobDetailStyle.header}>
                                        Job Industry
                                    </Text>
                                    <Text style={jobDetailStyle.description}>
                                        {jobIndustryName}
                                    </Text>
                                </View>
                            )}
                        </View>
                    )}
                </View>
            </>
        );
    };

    const renderRequirements = () => {
        if (!details) return null
        const skills =
            (details.skills &&
                details.skills.length &&
                details.skills.filter((s) => Boolean((s.name || '').trim()))) ||
            [];
        const jobEducationName =
            details.job_education && details.job_education.name;
        const jobRemoteWorkTypeName =
            details.job_remote_work_type && details.job_remote_work_type.name;

        return (
            <>
                <View>
                    <Text style={jobDetailStyle.title}>Job Requirements</Text>
                </View>
                <View style={jobDetailStyle.detailsWrapper}>
                    {skills.length > 0 && (
                        <View>
                            <Text style={jobDetailStyle.header}>Skills</Text>
                            <View style={jobDetailStyle.tagsWrapper}>
                                {skills.map((skill) => (
                                    <View
                                        style={[listElement.tag, jobDetail.tag]}
                                        key={skill.id}
                                    >
                                        <Text
                                            style={[
                                                listElement.tagText,
                                                jobDetailStyle.skillTagText
                                            ]}
                                        >
                                            {skill.name}
                                        </Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                    )}
                    <View style={[styles.row, { paddingTop: 10 }]}>
                        {Boolean(jobRemoteWorkTypeName) && (
                            <View style={styles.cell}>
                                <Text style={jobDetailStyle.header}>
                                    Remote work
                                </Text>
                                <Text style={jobDetailStyle.description}>
                                    {jobRemoteWorkTypeName}
                                </Text>
                            </View>
                        )}
                        {Boolean(jobEducationName) && (
                            <View>
                                <Text style={jobDetailStyle.header}>
                                    Education
                                </Text>
                                <Text style={jobDetailStyle.description}>
                                    {jobEducationName}
                                </Text>
                            </View>
                        )}
                        <View style={styles.cell}>
                            <Text style={jobDetailStyle.header}>Visa</Text>
                            <Text style={jobDetailStyle.description}>
                                {details.help_with_visa ? 'Yes' : 'No'}
                            </Text>
                        </View>
                    </View>
                </View>
            </>
        );
    };

    const renderCompensation = () => {
        if (!details) return null
        const jobCompensationTypeName =
            details.job_compensation_type && details.job_compensation_type.name;
        const salary = details.display_with_post && details.salary;

        return (
            <>
                <View>
                    <Text style={jobDetailStyle.title}>Compensation</Text>
                </View>
                <View style={jobDetailStyle.detailsWrapper}>
                    <View style={styles.row}>
                        {Boolean(jobCompensationTypeName) && (
                            <View style={styles.cell}>
                                <Text style={jobDetailStyle.header}>
                                    Compensation type
                                </Text>
                                <Text style={jobDetailStyle.description}>
                                    {jobCompensationTypeName}
                                </Text>
                            </View>
                        )}
                        {Boolean(salary) && (
                            <View style={styles.cell}>
                                <Text style={jobDetailStyle.header}>
                                    Salary
                                </Text>
                                <Text style={jobDetailStyle.description}>
                                    {normalizePrice(salary)}
                                </Text>
                            </View>
                        )}
                    </View>
                    <View style={styles.row}>
                        <View style={styles.cell}>
                            <Text style={jobDetailStyle.header}>Equity</Text>
                            <Text style={jobDetailStyle.description}>
                                {details.equity ? 'Yes' : 'No'}
                            </Text>
                        </View>
                        <View style={styles.cell}>
                            <Text style={jobDetailStyle.header}>
                                Paid relocation
                            </Text>
                            <Text style={jobDetailStyle.description}>
                                {details.paid_relocation ? 'Yes' : 'No'}
                            </Text>
                        </View>
                    </View>
                </View>
            </>
        );
    };

    const renderLocation = () => {
        if (!details) return null
        return (
            <>
                <View>
                    <Text style={jobDetailStyle.title}>Location</Text>
                </View>
                {details.location && details.location.full_address ? (
                    <View
                        style={
                            isMobile
                                ? jobDetailStyle.full_addressMobile
                                : jobDetailStyle.full_address
                        }
                    >
                        <Text style={jobDetailStyle.description}>
                            {details.location.full_address}
                        </Text>
                    </View>
                ) : (
                    <Text style={jobDetailStyle.locationText}>No location</Text>
                )}
            </>
        );
    };

    if (!details) return null;

    return (
        <ScrollView style={height500}>
            <View style={responsiveStyles.justifyCenter}>
                <View style={[mainContainerStyle]}>
                    <View style={[listElement.elementWrapperTopAligned]}>
                        <View style={styles.col}>
                            <View style={listElement.imageWrapper}>
                                <Image
                                    style={jobDetailStyle.image}
                                    source={image}
                                    resizeMode="contain"
                                />
                            </View>
                        </View>
                        <View style={listElement.infoWrapper}>
                            <Text
                                style={[jobDetailStyle.title]}
                                numberOfLines={1}
                            >
                                {details.title}
                            </Text>
                            {Boolean(details.company_name) && (
                                <Text
                                    style={[jobDetailStyle.description]}
                                    numberOfLines={1}
                                >
                                    {details.company_name}
                                </Text>
                            )}
                            {Boolean(details.location) && (
                                <Text
                                    style={jobDetailStyle.description}
                                    numberOfLines={1}
                                >
                                    {locationObjToStr(details.location)}
                                </Text>
                            )}
                            <View style={listElement.tagsWrapper}>
                                {Boolean(details.job_type_name) && (
                                    <View style={listElement.tag}>
                                        <Text style={listElement.tagText}>
                                            {details.job_type_name}
                                        </Text>
                                    </View>
                                )}
                                {details.remote_type === 'Yes' && (
                                    <View style={listElement.tag}>
                                        <Text style={listElement.tagText}>
                                            Remote
                                        </Text>
                                    </View>
                                )}
                                {details.remote_type === 'Allowed' && (
                                    <View style={listElement.tag}>
                                        <Text style={listElement.tagText}>
                                            Remote allowed
                                        </Text>
                                    </View>
                                )}
                            </View>
                        </View>
                        <View
                            style={[
                                listElement.rightBlock,
                                { paddingTop: 5, paddingRight: 5 }
                            ]}
                        >
                            {Boolean(details.bounty_amount) && (
                                <Text style={jobDetailStyle.salary}>
                                    {details.bounty_amount}
                                </Text>
                            )}
                        </View>
                    </View>

                    <View style={{ alignItems: 'center' }}>
                        <View
                            style={{
                                paddingTop: 30,
                                borderBottomColor: '#dadada',
                                borderBottomWidth: 1,
                                width: '100%'
                            }}
                        />
                    </View>
                    <View style={{ padding: 30 }}>
                        {Boolean(details.description) && (
                            <View>
                                <Text style={jobDetailStyle.title}>
                                    Description
                                </Text>
                                <Text style={styles.description}>
                                    {details.description}
                                </Text>
                            </View>
                        )}
                        <View style={{ paddingTop: 30 }}>
                            {renderBasicInfo()}
                            {renderRequirements()}
                            {renderCompensation()}
                            {renderLocation()}
                        </View>
                    </View>
                </View>
            </View>
        </ScrollView>
    );
};

export default withRouter(withGetScreen(PublicReferredJobDetails));
