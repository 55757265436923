import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Platform } from 'react-native';
import CSVReader from 'react-csv-reader';

//components
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';
import Text from '../../components/Text/Text';

//images
import styles from './styles';
import { formLayout } from '../../generalStyles';

//actions
import * as actions from './actions';

//utils
import { notification } from '../../helperFunctions';

class UploadCSVContacts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            csvUploaded: false,
            validFile: false,
            showFields: false,
            csvData: {},
            selectedFirstNameVal: '',
            selectedLastNameVal: '',
            selectedEmailVal: '',
            selectedPhoneVal: ''
        };
    }

    handleCSVData = (data, fileInfo, file) => {
        if (fileInfo.type === 'text/csv') {
            this.setState({ csvData: data, csvUploaded: true, validFile: true });
        }
        else {
            notification.ref.show({ message: 'Please upload files with .csv extension, only CSV files are allowed.', isError: true });
        }
    };

    papaParseOptions = {
        // header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        // transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
    };

    onHandleFirstName = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedFirstNameVal = opt
            return state
        })
    }

    onHandleLastName = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedLastNameVal = opt
            return state
        })
    }

    onHandleEmail = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedEmailVal = opt
            return state
        })
    }

    onHandlePhone = (e) => {
        const opt = this.state.csvData[0] && this.state.csvData[0].find((op) => { return (op === e[0].value) });
        this.setState((state) => {
            state.selectedPhoneVal = opt
            return state
        })
    }

    importHandler = () => {
        this.props.importFields(this.state);
    }

    render() {
        const web = Platform.OS === 'web';
        const dataOptions = []
        this.state.csvData[0] && this.state.csvData[0].map((item) => {
            dataOptions.push({ label: item, value: item })
        })

        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Upload Contacts via CSV' />
                {web &&
                    <View style={styles.csvUploadContainer}>
                        <CSVReader
                            label={this.state.csvUploaded ? "File selected" : "Please upload a CSV"}
                            onFileLoaded={this.handleCSVData}
                            parserOptions={this.papaParseOptions}
                            inputStyle={{ paddingLeft: 75 }}
                        />
                    </View>
                }
                <View style={styles.csvButton}>
                    <PrimaryButton small onPress={() => this.state.validFile ? this.setState({ showFields: true }) : null} disabled={!this.state.validFile}>
                        Next
                    </PrimaryButton>
                </View>
                {(this.state.csvData.length > 0 && this.state.showFields) &&
                    <Text style={styles.descriptionText}>
                        Please define fields mapping according to uploaded file to create contacts accordingly.
                    </Text>
                }
                {(this.state.csvData.length > 0 && this.state.showFields) &&
                    <View>
                        <View style={[styles.fieldsWrapper]}>
                            <View style={[formLayout.row, { zIndex: 1000 }]}>
                                <View style={styles.labelContainer}>
                                    <Text>First name:</Text>
                                </View>
                                <View style={{ width: '65%' }}>
                                    <OrganizationStatus
                                        onChange={(e) => this.onHandleFirstName(e)}
                                        name='first_name'
                                        dropdownValues={dataOptions}
                                        styles={[formLayout.fullWidth, { marginTop: 9 }]}
                                        labelField={'label'}
                                        valueField={'value'}
                                        customColor={'#2DB581'}
                                    />
                                </View>
                            </View>
                            <View style={[formLayout.row, { zIndex: 999 }]}>
                                <View style={styles.labelContainer}>
                                    <Text>Last name:</Text>
                                </View>
                                <View style={{ width: '65%' }}>
                                    <OrganizationStatus
                                        onChange={(e) => this.onHandleLastName(e)}
                                        name='last_name'
                                        dropdownValues={dataOptions}
                                        styles={[formLayout.fullWidth, { marginTop: 9 }]}
                                        labelField={'label'}
                                        valueField={'value'}
                                        customColor={'#2DB581'}
                                    />
                                </View>
                            </View>
                            <View style={[formLayout.row, { zIndex: 998 }]}>
                                <View style={styles.labelContainer}>
                                    <Text>Email:</Text>
                                </View>
                                <View style={{ width: '65%' }}>
                                    <OrganizationStatus
                                        onChange={(e) => this.onHandleEmail(e)}
                                        name='email'
                                        dropdownValues={dataOptions}
                                        styles={[formLayout.fullWidth, { marginTop: 9 }]}
                                        labelField={'label'}
                                        valueField={'value'}
                                        customColor={'#2DB581'}
                                    />
                                </View>
                            </View>
                            {this.state.csvData[0].includes('phone') &&
                                <View style={[formLayout.row, { zIndex: 997 }]}>
                                    <View style={styles.labelContainer}>
                                        <Text>Phone:</Text>
                                    </View>
                                    <View style={{ width: '65%' }}>
                                        <OrganizationStatus
                                            onChange={(e) => this.onHandlePhone(e)}
                                            name='phone'
                                            dropdownValues={dataOptions}
                                            styles={[formLayout.fullWidth, { marginTop: 9 }]}
                                            labelField={'label'}
                                            valueField={'value'}
                                            customColor={'#2DB581'}
                                        />
                                    </View>
                                </View>
                            }
                        </View>
                        <View style={[styles.csvButton, ((this.state.selectedEmailVal === '') && this.state.showFields) && { marginTop: 150 }]}>
                            <PrimaryButton
                                small
                                onPress={() => (this.state.validFile === true && this.state.selectedEmailVal === '' || this.state.selectedLastNameVal === '' || this.state.selectedFirstNameVal === '') ? null : this.importHandler()}
                                disabled={(this.state.selectedEmailVal === '' || this.state.selectedLastNameVal === '' || this.state.selectedFirstNameVal === '')}
                            >
                                Import
                            </PrimaryButton>
                        </View>
                    </View>
                }
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {}
};

const mapDispatchToProps = dispatch => {
    return {
        importFields: (data) => {
            dispatch(actions.importFields(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadCSVContacts);
