
import { notification } from '../helperFunctions';
import responseCodes from '../constants/responseCodes';
import * as stepsService from '../services/stepsService';
import * as stepsActionsConstants from '../constants/actions/stepsActions';

export function getCurrentStep() {
    return dispatch => {
        dispatch({ type: stepsActionsConstants.GET_STEP });
        return stepsService.getCurrentStep().then(response => {
            if (response.data.response_code === responseCodes.OK) {
                dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload });
                return response.data.payload;
            } else {
                notification.ref.show({ message: response.data.error, isError: true });
            }
        });
    };
}
