import React, {Component} from 'react';
import { TouchableOpacity, Image } from 'react-native';

import styles from './styles';

import checkedIcon from './img/checkbox_checked.png';
import uncheckedIcon from './img/checkbox_unchecked.png';

class Checkbox extends Component {
    render() {
        const { checked } = this.props;

        const icon = checked ? checkedIcon : uncheckedIcon;

        return (
            <TouchableOpacity
                {...this.props}
            >
                <Image source={icon} style={styles.icon} />
            </TouchableOpacity>
        );
    }
}

export default Checkbox;