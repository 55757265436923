import * as notificationsActionsConstants from '../../constants/actions/notificationsActions';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as notificationsService          from '../../services/notificationsService';
import * as authService from '../../services/authService';
import { setAccessToken } from '../../AsyncStorage';
import { SET_ANY_ORG_ADMIN } from '../../constants/actions/auth';

export function getNotificationsList(params) {
    return dispatch => {
        dispatch({type: appActionsConstants.SET_SPINNER_VISIBLE});
        dispatch({
            type: notificationsActionsConstants.GET_NOTIFICATIONS_LIST,
            payload: {
                isPagination: Boolean(params && params.offset)
            }
        });
        notificationsService.getNotificationsList(params).then(response => {
            dispatch({type: appActionsConstants.SET_SPINNER_HIDDEN});
            dispatch({type: SET_ANY_ORG_ADMIN, payload: response.data.payload.is_any_org_admin });
            dispatch({
                type: notificationsActionsConstants.GET_NOTIFICATIONS_LIST_SUCCESS,
                payload: {
                    notifications: response.data.payload.notifications,
                    unreadNotifications: response.data.payload.unread_notifications,
                    isPagination: Boolean(params && params.offset)
                }
            });
        }).catch(() => {
            dispatch({type: appActionsConstants.SET_SPINNER_HIDDEN});
            dispatch({type: notificationsActionsConstants.GET_NOTIFICATIONS_LIST_ERROR});
        });
    };
}

export function setRead(ids) {
    return dispatch => {
        dispatch({type: notificationsActionsConstants.SET_READ});
        notificationsService.setRead(ids).then(() => {
            dispatch({
                type: notificationsActionsConstants.SET_READ_SUCCESS,
                payload: ids
            });
        }).catch(() => {
            dispatch({type: notificationsActionsConstants.SET_READ_ERROR});
        });
    };
}
