import Actions from '../../../../RouteActions';
import {urls} from '../../../../urls'


import * as authService from '../../../../services/authService';
import * as userService from '../../../../services/usersService';
import * as authActionsConstants from '../../../../constants/actions/auth';
import {notification} from '../../../../helperFunctions';

export function changePassword(password) {
    return dispatch => {
        dispatch({type: authActionsConstants.CHANGE_PASSWORD});
        authService.changePassword(password).then(() => {
            Actions.replace(urls.myProfile);
            notification.ref.show({
                message: 'Your password has been changed.'
            });
        });
    };
}
