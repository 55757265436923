import React, { Component } from 'react';
import { WebView } from 'react-native';
import DashboardBase from '../../components/DashboardBase/DashboardBase';

export default class Web extends Component {
    render() {
        const { title, url } = this.props;
        return (
            <DashboardBase title={title} hasBackButton>
                <WebView
                    source={{uri: url}}
                    thirdPartyCookiesEnabled={false}
                />
            </DashboardBase>
        );
    }
}
