import axios   from 'axios';
import Actions from '../RouteActions';
import Cookies from 'js-cookie';
import { Platform } from 'react-native'

import * as AsyncStorage from '../AsyncStorage';
import responseCodes from '../constants/responseCodes';
import {notification, permissionsInterval} from '../helperFunctions';
import { logout } from '../screens/Settings/actions'
import { getStore } from './store'

import apiUrl from './apiServer'
export const apiSeverUrl = apiUrl;
const apiService = () => {
    const instance = axios.create({
        baseURL: `${apiSeverUrl}/api/v10`,
        headers: {'from-web-app': Platform.OS == 'web'}
    });
  
    instance.interceptors.request.use((config) => {
        const store = getStore();
        
        const expiryDate = Cookies.get('expirationDate');
        if(expiryDate && new Date(expiryDate) < new Date() && !!store && !config.url.includes('/sign_in')){
            store.dispatch(logout());
        }
        return config;
    }
    );
  
    return instance;
};

const baseService = apiService();

baseService.addAuthToken = (accessToken) => {
    return AsyncStorage.setAccessToken(accessToken).then(() => {
        baseService.defaults.headers.common['X-User-Token'] = accessToken;
        return new Promise((resolve => {
            setTimeout(() => {
                resolve(accessToken);
            });
        }));
    });
};

baseService.removeAuthToken = () => {
    return AsyncStorage.clearAccessToken().then(() => {
        delete baseService.defaults.headers.common['X-User-Token'];
        Actions.signInEmail();
        window.location.reload();
        clearInterval(permissionsInterval.interval);

        return {status: 'success'};
    });
};

baseService.interceptors.response.use(response => {
    
    if (response.data.response_code === responseCodes.OK) {
        if(response.data.message === 'Referral sent successfully' || response.data.message === 'Waiting for strengthening questions to be answered' || response.data.message === "Email Sent! Please check your inbox for an email from Boon with your magic link") return response
        if (response.data.message) {
            notification.ref.show({message: response.data.message, isError: false});
        }
        return response;
    } else if (response.data.response_code === responseCodes.INVALID_ACCESS_TOKEN) {
        notification.ref.show({message: response.data.error, isError: true});
        const env = process.env.REACT_APP_ENVIRONMENT || 'development';
        Cookies.remove(`X-User-Token-${env}`);
        throw baseService.removeAuthToken().then(() => {
            throw response;
        });
    } else {
        const isError = response.data.response_code && response.data.response_code !== responseCodes.ACCOUNT_IS_NOT_CONFIRMED &&
        response.data.response_code !== responseCodes.PHONE_NUMBER_REQUIRED
        if (response.data.error === "Pdf You are not allowed to upload \"png\" files, allowed types: pdf, Pdf can't be blank") {
            response.data.error = 'Pdf file allowed only; Pdf cannot be blank';
        }
        return response;
    }
},  
error => {
    if (error.response.status === responseCodes.TOO_MANY_REQUESTS) {
        notification.ref.show({message: 'Too many requests. Please try again after some time.', isError: true});
    }
    return Promise.reject(error);
});

// TODO: Fix token leaking, currently it is same as on admin web app
// Related: https://github.com/axios/axios/issues/1383#issuecomment-377870814
AsyncStorage.getAccessToken().then(accessToken => {
    if(accessToken) {
        baseService.defaults.headers.common['X-User-Token'] = accessToken;
    }
});

export default baseService;
