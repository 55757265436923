export default [
    {
        label: 'LinkedIn',
        value: 'ln',
    },
    {
        label: 'Portfolio',
        value: 'po',
    },
    {
        label: 'Personal Website',
        value: 'pw',
    },
    {
        label: 'Resume',
        value: 're',
    }
]