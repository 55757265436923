import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';

export function submitPermissions() {
    return dispatch => {
        dispatch({type: authActionsConstants.SUBMIT_PERMISSIONS});
        return authService.submitPermissions().then(() => {
            dispatch({type: authActionsConstants.SUBMIT_PERMISSIONS_SUCCESS});
        });
    };
}
