import React, {Component} from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import  Actions  from '../../../../RouteActions';

//images
import demoCoverImage from '../../../../assets/img/demo_cover_image.png';
import defaultUserAvatar from '../../../../assets/img/default_user_avatar.png';

//components
import Text from '../../../../components/Text/Text';
import ListElementButton from '../../../../components/ListElementButton/ListElementButton';

//styles
import styles from './styles';

class RecommendationsBanner extends Component {
    render() {
        const { data, hideRecommendations } = this.props;
        return (
            <View style={styles.wrapper}>
                <View style={styles.backgroundImageWrapper}>
                    <Image style={styles.backgroundImage} source={demoCoverImage} resizeMode="cover" />
                </View>
                <View style={styles.backgroundImageColorCover}/>
                <View style={styles.info}>
                    <Text style={styles.title}>Your Recommendations</Text>
                    <Text style={styles.description}>Boon's suggestions for today</Text>
                    <View style={styles.avatarsWrapper}>
                        {data.map((recommendation, index) => {
                            const avatar = recommendation.user.image_url ? {url: recommendation.user.image_url} : defaultUserAvatar;
                            return (<Image key={index} style={styles.avatar} source={avatar}/>);
                        })}
                    </View>
                    <View style={styles.buttonsWrapper}>
                        <ListElementButton style={styles.reviewButton} onPress={() => Actions.recommendations({data})}>Review</ListElementButton>
                        <TouchableOpacity onPress={hideRecommendations}>
                            <Text style={styles.dismissButton}>
                                Dismiss for now
                            </Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        )
    }
}

export default RecommendationsBanner;