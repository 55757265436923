import React, { useMemo, useEffect, useState } from 'react';
import { isPhoneNumberValid, notification, validateEmail, validateUrl } from '../../helperFunctions';
import { View, TouchableOpacity, ImageBackground, Platform, TextInput, Image, Text } from 'react-native';
import { Field } from 'redux-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import UserFormInput from '../../components/UserFormInput/UserFormInput';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import FormInputNew from '../../components/FormInputNew/FormInputNew';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import PhoneInput from 'react-phone-input-2';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import BusinessIcon from '@material-ui/icons/Business';
import logo from '../../assets/img/default_org_avatar.png';
import PositionFormInput from '../../components/PositionFormInput/PositionFormInput';
import contactCardIcon from '../../assets/svg/contact_card.svg';
import contactCardGreenIcon from '../../assets/svg/contact_card_green.svg';
import OrganizationFormInput from '../../components/OrganizationFormInput/OrganizationFormInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import sendReferralIcon from '../../assets/img/send_referral_icon.svg';
import PhoneIcon from '../../assets/svg/phone-icon.svg';
import { formLayout } from '../../generalStyles';
import styles from './styles';

const ReferUserForm = ({
  formValues,
  currentSelectedOrg,
  defaultOrganizationSettings,
  setFieldValue,
  onOpeningSelectDropDown,
  checkCandiadateAlreadyRefered,
  currentSelectedOrgLabel,
  onOpenOpeningSelect,
  generalReferrals,
  showSelection,
}) => {
  const [showFirstNameWarning, setFirstNameWarning] = useState(false);
  const [showLastNameWarning, setLastNameWarning] = useState(false);
  const [showEmailWarning, setEmailWarning] = useState(false);

  const isOpeningRequired = () => {
    if (!generalReferrals && job_id) {
      return false;
    } else if (generalReferrals) {
      return false;
    }
    return true;
  };

  const requiredFields = (orgSettingPermission) => {
    const feilds = ['first_name', 'last_name', 'email', 'organization_id'];
    const { linkedIn_url_required, phone_number_required } = orgSettingPermission;
    if (linkedIn_url_required) feilds.push('linkedin_link');
    if (phone_number_required) feilds.push('phone_number');
    if (isOpeningRequired()) feilds.push('job_id');
    return feilds;
  };

  const handleChangeUserName = (label, value = '') => {
    if (label === 'first_name') {
      setFirstNameWarning(value.length === 0);
    } else if (label === 'last_name') {
      setLastNameWarning(value.length === 0);
    }

    setFieldValue(label, value.trim());
  };

  const { email, phone_number, linkedin_link, job_id } = formValues;
  let isContinueDisabled = useMemo(() => {
    let isButtonDisabled =
      requiredFields(defaultOrganizationSettings).some((value) => !Boolean(formValues[value])) ||
      (!email && !phone_number && !linkedin_link);
    if (!defaultOrganizationSettings.phone_number_required && !defaultOrganizationSettings.linkedIn_url_required) {
      if (email && formValues.first_name && formValues.last_name && validateEmail(email)) {
        isButtonDisabled = false;
        if (linkedin_link && !validateUrl(linkedin_link, 'ln')) {
          isButtonDisabled = true;
        }
      } else {
        isButtonDisabled = true;
      }
    } else {
      if (linkedin_link && !validateUrl(linkedin_link, 'ln')) {
        isButtonDisabled = true;
      }

      if (email && !validateEmail(email)) {
        isButtonDisabled = true;
      }
    }
    return isButtonDisabled;
  }, [formValues, defaultOrganizationSettings, email, phone_number, linkedin_link, requiredFields]);

  const InputLabelProps = { style: { marginTop: 2, backgroundColor: '#fff', paddingRight: 6 } };
  const InputStyleProps = { fontSize: 14, height: 44 };

  const validateUrlError = () => {
    if (!formValues.linkedin_link) {
      return true;
    }
    return validateUrl(formValues.linkedin_link, 'ln');
  };

  const emailValidation = () => {
    if (showEmailWarning) {
      return true;
    }
    if (!formValues.email) {
      return false;
    }
    return !validateEmail(formValues.email);
  };

  const orgLogo =
    formValues.organization_id && formValues.organization_id.logo_image_url
      ? formValues.organization_id.logo_image_url
      : logo;

  return (
    <View
      style={[
        styles.fromWrapper,
        {
          height: 'calc(100% - 45px)',
          padding: 0,
          justifyContent: 'space-between',
          backgroundColor: 'transparent',
        },
      ]}
    >
      <View style={{ padding: 16 }}>
        <View style={{ width: '100%', marginBottom: 16, backgroundColor: '#fff' }}>
          <TouchableOpacity
            onPress={() => {
              onOpeningSelectDropDown('contact', 'Select Contact');
            }}
          >
            <Field
              component={UserFormInput}
              label="Search contacts"
              variant="outlined"
              size="small"
              name={formValues.name}
              InputProps={{
                style: { fontSize: 14 },
              }}
            />
          </TouchableOpacity>
        </View>
        <View style={styles.divider}>
          <View style={styles.leftDivider} />
          <View>
            <Text style={styles.dividerText}>or</Text>
          </View>
          <View style={styles.rightDivider} />
        </View>
        <View style={{ width: '100%', marginBottom: 16, backgroundColor: '#fff' }}>
          <Field
            component={FormInputNew}
            label="Candidate First Name*"
            variant="outlined"
            size="small"
            name="first_name"
            labelBg="white"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: '#CCCCCC' }}>
                  <PersonOutlineIcon style={{ color: formValues.first_name ? '#0FBC71' : '' }} />
                </InputAdornment>
              ),
              style: { fontSize: 14 },
            }}
            error={showFirstNameWarning}
            setNameWarning={setFirstNameWarning}
            onChange={(e) => {
              handleChangeUserName('first_name', e.target.value.trim());
            }}
          />
        </View>
        <View style={{ width: '100%', marginBottom: 16, backgroundColor: '#fff' }}>
          <Field
            component={FormInputNew}
            label="Candidate Last Name*"
            variant="outlined"
            size="small"
            name="last_name"
            labelBg="white"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: '#CCCCCC' }}>
                  <PersonOutlineIcon style={{ color: formValues.last_name ? '#0FBC71' : '' }} />
                </InputAdornment>
              ),
              style: { fontSize: 14 },
            }}
            error={showLastNameWarning}
            setNameWarning={setLastNameWarning}
            onChange={(e) => {
              handleChangeUserName('last_name', e.target.value.trim());
            }}
          />
        </View>

        <View
          style={[
            formLayout.row,
            formLayout.zIndexedL1,
            { fontWeight: '400', color: '#7e7e7e', backgroundColor: '#fff' },
          ]}
        >
          <Field
            component={FormInputNew}
            name="email"
            label="Email Address*"
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: '#CCCCCC' }}>
                  <EmailIcon style={{ color: formValues.email ? '#0FBC71' : '' }} />
                </InputAdornment>
              ),
              style: InputStyleProps,
            }}
            InputLabelProps={InputLabelProps}
            error={emailValidation()}
            setNameWarning={setEmailWarning}
            onChange={(e) => {
              setEmailWarning(e.target.value.length === 0);
            }}
          />
        </View>

        <View style={styles.phoneRefer} nativeID="referPhoneNo">
          <PhoneInput
            name="phone_number"
            specialLabel={
              defaultOrganizationSettings.phone_number_required ? '*Phone Number' : 'Phone Number (optional)'
            }
            autoFocus
            value={formValues.phone_number || '+1'}
            country="us"
            onChange={(phone) =>
              setFieldValue('phone_number', phone.length > 0 && phone[0] !== '+' ? '+' + phone : phone)
            }
          />
          <Image style={styles.phoneIcon} source={PhoneIcon} resizeMode="contain" />
        </View>

        <View
          style={[
            formLayout.row,
            formLayout.zIndexedL3,
            { fontWeight: '400', color: '#7e7e7e', backgroundColor: '#fff' },
          ]}
        >
          <Field
            component={FormInputNew}
            error={!validateUrlError()}
            name="linkedin_link"
            label={defaultOrganizationSettings.linkedIn_url_required ? '*LinkedIn URL' : 'LinkedIn URL'}
            variant="outlined"
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" style={{ color: '#0E76A8' }}>
                  <LinkedInIcon />
                </InputAdornment>
              ),
              style: InputStyleProps,
            }}
            value={formValues.linkedin_url || ''}
            InputLabelProps={InputLabelProps}
          />
        </View>

        <View style={{ width: '100%', marginBottom: 16, backgroundColor: '#fff' }}>
          <TouchableOpacity
            onPress={() => {
              currentSelectedOrgLabel === 'Boon'
                ? onOpeningSelectDropDown('organization', 'Select Organization')
                : null;
            }}
          >
            <Field
              component={OrganizationFormInput}
              label="Employer"
              name="organization_id"
              variant="outlined"
              size="small"
              fullWidth
              disabled={currentSelectedOrg && currentSelectedOrg.id != null}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" style={{ color: '#0FBC71' }}>
                    <BusinessIcon style={{ color: formValues.organization_id ? '' : '#cccccc' }} />
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={orgLogo} style={{ height: '22px', width: '22px' }} />
                  </InputAdornment>
                ),
                style: { fontSize: 14, color: 'rgba(0, 0, 0, 0.87)', ...InputStyleProps },
              }}
              InputLabelProps={InputLabelProps}
            />
          </TouchableOpacity>
        </View>

        <View style={{ width: '100%', marginBottom: 16, backgroundColor: '#fff' }}>
          <TouchableOpacity
            onPress={() => {
              onOpenOpeningSelect();
            }}
          >
            <Field
              component={PositionFormInput}
              fullWidth
              id="outlined-basic"
              variant="outlined"
              label={generalReferrals ? 'Opening' : '*Opening'}
              type="text"
              name="job_id"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment style={{ color: '#CCCCCC' }} position="end">
                    <img src={formValues.job_id ? contactCardGreenIcon : contactCardIcon} style={{ height: '18px' }} />
                  </InputAdornment>
                ),
                style: InputStyleProps,
              }}
              InputLabelProps={InputLabelProps}
            />
          </TouchableOpacity>
        </View>

        <View style={[formLayout.row, { marginTop: 16, backgroundColor: '#fff' }]}>
          <Field
            component={FormInputNew}
            name="candidate_message"
            label="Message to Individual"
            page="refer"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            maxLength={280}
            inputProps={{ style: { fontSize: 12 } }}
          />
        </View>
        <View style={[formLayout.row, { marginTop: 8, backgroundColor: '#fff' }]}>
          <Field
            component={FormInputNew}
            name="employer_message"
            label="Message to Employer"
            page="refer"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            maxLength={280}
            inputProps={{ style: { fontSize: 12 } }}
          />
        </View>
      </View>
      <ImageBackground
        source={require('../../assets/img/city-line-illustration.png')}
        style={{ width: '100%', height: '240px' }}
      >
        <View style={{ paddingLeft: 16, paddingRight: 16, paddingBottom: 48 }}>
          {showSelection.type !== 'opening' && showSelection.type !== 'contact' && (
            <PrimaryButton
              style={(isContinueDisabled || isOpeningRequired()) && styles.submitButtonDisabled}
              onPress={() =>
                isContinueDisabled || !formValues.referrer_email || isOpeningRequired()
                  ? null
                  : checkCandiadateAlreadyRefered()
              }
              icon={sendReferralIcon}
            >
              {`    Send Referral`}
            </PrimaryButton>
          )}
        </View>
      </ImageBackground>
    </View>
  );
};

export default ReferUserForm;
