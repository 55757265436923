import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';

const BoonSelectField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'red',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'red',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: '#CCCCCC',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
})(Select);

export default BoonSelectField;
