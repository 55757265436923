import React from 'react';
import Select from 'react-dropdown-select';
import {
  View
} from 'react-native';
class OrganizationStatus extends React.Component {
  handleChange = (value) => {
    this.props.onChange(value)
  }
  render() {
    const { formSubmitted, selected, dropdownValues, name, labelField, valueField, placeholder, styles, multi, customColor, searchable, create, dropdownStyles } = this.props;
    const color = formSubmitted && selected == null ? 'red' : '#aeaeae';
    let selectedObj = {}
    if (selected) {
      selectedObj = dropdownValues.filter((obj) => {
        return obj[valueField] === selected;
      });
    }

    return (
      <View style={[styles]}>
        <Select
          options={dropdownValues}
          labelField={labelField ? labelField : 'name'}
          valueField={valueField ? valueField : 'id'}
          values={(selectedObj && selectedObj[0]) ? [selectedObj[0]] : []}
          searchBy='name'
          onChange={(value) => {
            this.handleChange(value)
            // formValues.status = value[0].name
            // this.setState({ organization_status_id: value[0].id });
          }}
          color={customColor}
          name={name}
          placeholder={placeholder}
          searchable={searchable}
          create={create}
          createNewLabel='Add new {search}'
          dropdownHeight="200px"
          style={dropdownStyles ? dropdownStyles :
            {
              fontFamily: 'Roboto-Regular',
              borderBottomWidth: 1,
              borderBottomColor: color,
              borderTop: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              fontSize: 16
            }
          }
          multi={multi}

        />
      </View>
    )
  }
}
export default OrganizationStatus;
