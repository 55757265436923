import { StyleSheet } from 'react-native';
import { $greish, $background } from '../../generalStyles';

export default StyleSheet.create({
    smallIcon: {
        height: 16,
        width: 16,
        marginVertical: 16,
        marginRight: 32
    },
    lockIcon: {
        height: 20,
        width: 14,
        marginVertical: 16,
        marginRight: 32
    },
    titleWrapper: {
        alignSelf: 'center',
        paddingTop: 12
    },
    elementTitle: {
        fontSize: 16,
        color: '#999999',
    },
    elementTitleConnected: {
        color: '#000000'
    },
    chevron: {
        transform: [{ rotate: '180deg' }],
        height: 15,
        width: 9
    },
    logoutButton: {
        marginTop: 32,
        marginHorizontal: 20,
    }
});
