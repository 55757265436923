import * as userService from '../../services/usersService';
import * as authActionConstants from '../../constants/actions/auth';
import {
    SET_SPINNER_VISIBLE,
    SET_SPINNER_HIDDEN
} from '../../constants/actions/appActions';
import { notification } from '../../helperFunctions';
import history from '../../RouteHistory';
import { urls } from '../../urls.js';

export function getInviteData(token) {
    return (dispatch) => {
        dispatch({ type: authActionConstants.GET_USER_INVITE_DATA });
        if (token) {
            dispatch({ type: SET_SPINNER_VISIBLE });
            userService
                .getInviteData(token)
                .then((response) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_SUCCESS,
                        payload: response.data.payload
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_ERROR
                    });
                    notification.ref.show({
                        message: e.data.error,
                        isError: true
                    });
                })
                .finally(() => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                });
        }
    };
}

export function updateUserStatus(userId, orgName) {
    return (dispatch) => {
        if (userId && orgName) {
            userService
                .setUserStatus(userId, orgName)
                .then((response) => {
                    if (response.data.payload.active) {
                        history.push(urls.signIn);
                    } else {
                        history.push(urls.signUp);
                    }
                })
                .catch((e) => {
                    notification.ref.show({
                        message: e.data.error,
                        isError: true
                    });
                });
        }
    };
}

export function getOrgIdByName(orgName) {
    return (dispatch) => {
        if (orgName) {
            userService
                .getOrgIdByName(orgName)
                .then((response) => {
                    if(response.data.payload.errors){
                        notification.ref.show({
                            message: response.data.payload.errors,
                            isError: true
                        });;
                        return
                    }
                    dispatch({
                        payload: {...response.data.payload, organization_name: orgName},
                        type:"SET_ORGANIZATION_ID"
                    })
                }).catch((e) => {
                    notification.ref.show({
                        message: "No Organization name found",
                        isError: true
                    });
                
                });
        }
    };
}
