import React, { Component } from 'react';

import {reduxForm}   from 'redux-form';
import {connect}            from 'react-redux';
import {
    View,
    Image,
    ImageBackground,
    Keyboard
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

//components
import Text          from '../../components/Text/Text';
import respStyles from '../../responsiveStyles';
import logo from '../../assets/img/logo.png';
import checkMark from '../../assets/img/click_with_white_background.png';

// Actions
import { redirect } from '../../globalActions/redirectActions';

//styles
import styles      from './styles';
import * as generalStyles from '../../generalStyles';

//other
import backgroundBoxImage from "../../assets/img/bg-invite-image.svg";

class WaitForAccountApproval extends Component {
    componentDidMount() {
        this.props.redirect();
    }
    render() {
        return (
            <ImageBackground source={backgroundBoxImage} style={styles.elementWrapper}>
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={[styles.keyboardAwareContainer, respStyles.flexCenter]}
                    style={{width: '100%'}}>
                    <View style={[styles.logoWrapper, respStyles.mb20]}>
                        <Image style={styles.logo} source={logo}/>
                    </View>
                    <View style={[styles.childrenWrapper]}>
                        <View style={generalStyles.whiteBlock}>
                            <Image style={styles.checkMark} source={checkMark}/>
                            <View style={{alignItems: 'center', height: 200}}>
                                <Text style={styles.textLines}>
                                    Thanks for your interest in joining Boon's growing community.
                                </Text>
                                <Text style={styles.textLines}>
                                    You'll be notified via email as soon your account
                                </Text>
                                <Text style={[styles.textLines, {marginTop: 0}]}>is available to access.</Text>
                            </View>
                        </View>
                    </View>
                </KeyboardAwareScrollView>
            </ImageBackground>

        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        redirect: () => {
            dispatch(redirect());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WaitForAccountApproval);
