import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    pageTitle: {
        fontSize: 24
    },
    newToBoonWrapper: {
        justifyContent: 'center',
        flexDirection: 'row'
    },
    newToBoonText: {
        color: '#fff',
        fontSize: 14,
        marginRight: 5
    },
    newToBoonButtonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    inputWrapper: {
        marginVertical: 32
    }
});