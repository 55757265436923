import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  FlatList,
  View,
  Image,
  TouchableOpacity,
  Platform,
  Dimensions,
} from "react-native";
import Select from "react-dropdown-select";
import Actions from "../../../../RouteActions";
import { withGetScreen } from "react-native-getscreen";
import ImageWithFallback from "../../../../components/ImagewithFallback";
import defaultUserImg from '../../../../assets/img/default_profile_image.svg';

//components
import Text from "../../../../components/Text/Text";

//actions
import * as leaderBoardActions from "./actions";
import * as statsActions from "../Stats/actions";

//styles
import styles from "./styles";
import {
  listElement,
  flatListContainer,
  common,
} from "../../../../generalStyles";

//images
import defaultUserAvatar from "../../../../assets/img/default_user_avatar.png";
import polygonImg from "../../../../assets/img/polygon.png";
import lastMonthWinner from "../../../../assets/img/last_month_winner.png";
import polygonSVG from "../../../../assets/svg/polygon.svg";

const FILTERS = [
  { label: "THIS WEEK", value: "week" },
  { label: "THIS MONTH", value: "month" },
];

const EmptyListItem = ({ isLastMonthWinner }) => {
  return (
    <View style={[styles.listItem]}>
      <View style={styles.avatarWrapper}>
        <View style={[styles.avatarLarge, styles.emptyBackgroundColor]} />
        {isLastMonthWinner && (
          <Image
            style={styles.lastMonthWinner}
            source={lastMonthWinner}
            resizeMode="contain"
          />
        )}
      </View>
      <View style={styles.listItemBody}>
        <View
          style={[
            styles.emptyBackgroundColor,
            {
              width: "100%",
              maxWidth: 103,
              height: 18,
              borderRadius: 4,
              marginBottom: 3,
            },
          ]}
        ></View>
        <View
          style={[
            styles.emptyBackgroundColor,
            {
              width: "100%",
              maxWidth: 73,
              height: 10,
              borderRadius: 4,
              marginTop: 3,
            },
          ]}
        ></View>
      </View>
      <View style={styles.listItemRight}>
        {isLastMonthWinner ? (
          <Image
            style={styles.avatarLarge}
            source={polygonSVG}
            resizeMode="contain"
          />
        ) : (
          <View style={[styles.avatarSmall, styles.emptyBackgroundColor]} />
        )}
      </View>
    </View>
  );
};

const Leaderboard = (props) => {
  const {
    getLeaderBoard,
    selectedOrganization,
    currentSelectedOrg,
    leaderBoardList,
    leaderBoardListLoading,
    currentUserId,
  } = props;
  const [filter, setFilter] = useState('week');
  useEffect(() => {
    if (getLeaderBoard && currentSelectedOrg && currentSelectedOrg.id) {
      getLeaderBoard({
        organization_id: currentSelectedOrg.id,
        filter: filter
      });
    }
  }, [currentSelectedOrg, filter]);

  const renderEmptyListItems = useMemo(() => {
    if (leaderBoardList.length >= 8) {
      return null;
    }
    const emptyListItems = new Array(8 - leaderBoardList.length).fill(null);
    return (
      <>
        {leaderBoardList.length === 0 && (
          <EmptyListItem isLastMonthWinner={true} />
        )}
        {emptyListItems.map((item, index) => {
          if (index === emptyListItems.length - 5)
            return (
              <View key={`empty-list-item-more`} style={styles.listItemMore}>
                <Text style={styles.roundSmall} />
                <Text style={styles.roundSmall} />
                <Text style={styles.roundSmall} />
              </View>
            );
          return <EmptyListItem key={`empty-list-item-${index}`} />;
        })}
      </>
    );
  }, [leaderBoardList]);

  const renderListItem = ({ item, index, isCurrentUser }) => {
    const userAvatar = item.user.avatar_thumb
      ? { uri: item.user.avatar_thumb }
      : defaultUserAvatar;
    const winnerPlaceStyles = [styles.winnerPlaceText];

    if (!index) {
      winnerPlaceStyles.push(styles.winnerPlaceTextWhite);
    }

    return (
      <TouchableOpacity
        style={[styles.listItem]}
        onPress={() => Actions.contactProfile({ userId: item.user.id })}
      >
        <View style={styles.avatarWrapper}>
          <ImageWithFallback
            imageSource={userAvatar}
            fallbackSrc={defaultUserImg}
            resizeMode="contain"
            imageStyles={styles.avatarLarge}
          />
          {item.last_winner && (
            <Image
              style={styles.lastMonthWinner}
              source={lastMonthWinner}
              resizeMode="contain"
            />
          )}
        </View>
        <View style={styles.listItemBody}>
          <View
            style={[
              {
                width: "100%",
                height: 18,
                marginBottom: 3,
              },
            ]}
          >
            <Text style={listElement.title}>
              {item.user.full_name && item.user.full_name.substring(0,25)}
              {isCurrentUser && " (me)"}
            </Text>
          </View>
          <View
            style={[
              {
                width: "100%",
                height: 10,
                marginTop: 3,
              },
            ]}
          >
            <Text style={listElement.description}>
              {item.referrals_sent} referrals sent
            </Text>
          </View>
        </View>
        <View style={styles.listItemRight}>
          {index === 0 && (
            <Image
              style={[
                styles.avatarLarge,
                { position: "absolute", top: 0, left: 0 },
              ]}
              source={polygonImg}
              resizeMode="contain"
            />
          )}
          <Text style={winnerPlaceStyles}>{index + 1}</Text>
        </View>
      </TouchableOpacity>
    );
  };

  const tabScreen = Dimensions.get("window").width < 750;

  return (
    <View style={styles.root}>
      <View>
        {!tabScreen ? (
          <View>
            <Text style={styles.title}>Leaderboard</Text>
          </View>
        ) : (
          <View />
        )}
        <View style={styles.filtersWrapper}>
          <View>
            <Text style={styles.filter}>MEMBERS</Text>
          </View>
          <View>
            <Select
              className="leader-board-select"
              options={FILTERS}
              labelField="label"
              valueField="value"
              multi={false}
              onChange={(value) => {
                setFilter(value[0] && value[0].value);
              }}
              color="#AAAAAA"
              placeholder="THIS WEEK"
              searchable={false}
              style={{
                borderBottom: "none",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                color: "#AAAAAA",
                minHeight: 12,
                height: 12,
                padding: 0,
                fontFamily: "Roboto-Regular",
                fontSize: 11,
                fontWeight: "500",
                flexDirection: "row-reverse",
              }}
            />
          </View>
        </View>
        {(!leaderBoardList || !leaderBoardList.length) && (
          <View style={{ zIndex: -1 }}>
            <Text style={styles.emptyText}>
              Your employees are becoming leaders and you will be seeing them
              here... soon
            </Text>
          </View>
        )}
        <View style={styles.listWrapper}>
          <FlatList
            style={Platform.OS === "web" && flatListContainer}
            onRefresh={() =>
              getLeaderBoard({ organization_id: selectedOrganization.id, filter: filter })
            }
            refreshing={leaderBoardListLoading}
            data={leaderBoardList}
            renderItem={({ item, index }) =>
              renderListItem({
                item,
                index,
                isCurrentUser: item.user.id === currentUserId,
              })
            }
            keyExtractor={(item, index) => `${item}_${index}`}
          />
          {renderEmptyListItems}
        </View>
      </View>
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    leaderBoardList: state.activityReducer.leaderBoardList,
    leaderBoardListLoading: state.activityReducer.leaderBoardListLoading,
    selectedOrganization: state.activityReducer.selectedOrganization,
    currentUserId: state.authReducer.currentUser.id,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLeaderBoard: (params) => {
      dispatch(statsActions.getCurrentUserStats());
      dispatch(leaderBoardActions.getLeaderBoardList(params));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGetScreen(Leaderboard));
