import React, { useRef } from "react";
import { View, Image } from "react-native";

//components
import Text from "../Text/Text";
import chevronLeftGreen from "../../assets/img/chevron_left_green.svg";

//styles
import styles from "./styles";
import { formLayout, drawerElement } from "../../generalStyles";

const ReferSelection = ({ title, description, onClose, children }) => {
  const scrollRef = useRef(null);

  return (
    <View
      style={styles.root}
      //   innerRef={(ref) => (scrollRef.current = ref)}
    >
      <View style={styles.header}>
        <View
          style={[
            drawerElement.drawerHeader,
            formLayout.row,
            { backgroundColor: "unset", justifyContent: "flex-start" },
          ]}
        >
          <Text style={styles.backButtonStyle} onPress={() => onClose()}>
            <Image style={{ width: 8, height: 10 }} source={chevronLeftGreen} />
          </Text>
          <Text style={[drawerElement.headerText, { marginLeft: 16 }]}>
            {title}
          </Text>
        </View>
        {Boolean(description) && (
          <Text style={styles.headerDescription}>{description}</Text>
        )}
      </View>
      <View style={[styles.fromWrapper]}>{children}</View>
    </View>
  );
};

export default ReferSelection;
