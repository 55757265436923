import { StyleSheet } from 'react-native';
import { $green } from '../../../../generalStyles';

export default StyleSheet.create({
    content: {
        paddingVertical: 16,
        paddingHorizontal: 20
    },
    categoryText: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#18332F',
        marginBottom: 12
    },
    row: {
        flexDirection: 'row',
        marginBottom: 16
    },
    element: {
        paddingLeft: 14,
        paddingTop: 12,
        backgroundColor: '#fff',
        borderRadius: 5,
        width: 170,
        height: 100,
        marginRight: 16,
    },
    elementTitle: {
        fontSize: 10,
        fontWeight: 'bold',
        color: '#000000',
        opacity: 0.5,
        marginBottom: 12
    },
    elementValue: {
        fontSize: 30,
        fontWeight: 'bold',
        color: $green
    }
});
