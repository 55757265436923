import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View } from 'react-native';
import { List } from '@ant-design/react-native';
import { reduxForm, Field, change } from 'redux-form';
import FormInput from '../FormInput/FormInput';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import shareIconWhite from "../../assets/img/share_icon_white.png";
import styles from './styles';
import FormInputCheckbox from '../FormInputCheckbox/FormInputCheckbox';
import Text from '../Text/Text';
import { formLayout } from '../../generalStyles';
import { closeDrawer } from '../../DrawerActions';
import DrawerHeader from './drawerHeader.js';

// Actions
import { setBrowseFilter, clearBrowseFilter } from '../../screens/Browse/actions'

class BrowseFilters extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            activeSections: [0],
        };
        this.onChange = activeSections => {
            this.setState({ activeSections });
        };
    }


    render() {
        const { handleSubmit, formValues, clearFilters } = this.props;
        return (
            <View style={{ flex: 1 }}>
                <DrawerHeader title='Filters' />
                <View style={[styles.fromWrapper]}>
                    <View style={formLayout.row}>
                        <Field
                            component={FormInput}
                            name="company"
                            placeholder="Company"
                            wrapperStyle={[formLayout.fullWidth]} />
                    </View>
                    <View style={{ marginTop: 10, marginBottom: 10 }}>
                        <List>
                            <List.Item style={formLayout.accordianListItem}>
                                <View style={styles.jobFilterHeader}>
                                    <Text style={styles.jobFilterHeaderText}>
                                        Job Type
                                    </Text>
                                </View>
                            </List.Item>
                            <List.Item style={formLayout.accordianListItem}>
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='full_time'
                                        checked={formValues.full_time}
                                        onValueChange={() => {
                                            this.props.setFieldValue('full_time', !formValues.full_time)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Full-Time</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='part_time'
                                        checked={formValues.part_time}
                                        onValueChange={() => {
                                            this.props.setFieldValue('part_time', !formValues.part_time)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Part-Time</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='contract'
                                        checked={formValues.contract}
                                        onValueChange={() => {
                                            this.props.setFieldValue('contract', !formValues.contract)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Contract</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                        </List>
                        <List>
                            <List.Item style={formLayout.accordianListItem}>
                                <View style={styles.jobFilterHeader}>
                                    <Text style={styles.jobFilterHeaderText}>
                                        Experience Level
                                    </Text>
                                </View>
                            </List.Item>

                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='entry_level'
                                        checked={formValues.entry_level}
                                        onValueChange={() => {
                                            this.props.setFieldValue('entry_level', !formValues.entry_level)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Entry Level</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='associate'
                                        checked={formValues.associate}
                                        onValueChange={() => {
                                            this.props.setFieldValue('associate', !formValues.associate)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Associate</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='mid_senior_level'
                                        checked={formValues.mid_senior_level}
                                        onValueChange={() => {
                                            this.props.setFieldValue('mid_senior_level', !formValues.mid_senior_level)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Mid-Senior Level</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='director'
                                        checked={formValues.director}
                                        onValueChange={() => {
                                            this.props.setFieldValue('director', !formValues.director)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Director</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                            <List.Item  style={formLayout.accordianListItem} >
                                <View style={formLayout.row}>
                                    <Field
                                        component={FormInputCheckbox}
                                        name='executive'
                                        checked={formValues.executive}
                                        onValueChange={() => {
                                            this.props.setFieldValue('executive', !formValues.executive)
                                        }}
                                        textComponent={() => {
                                            return (
                                                <Text>Executive</Text>
                                            );
                                        }}
                                    />
                                </View>
                            </List.Item>
                        </List>
                    </View>
                    <View style={styles.buttonsContainer}>
                        <PrimaryButton
                            onPress={clearFilters}
                            style={[styles.button, styles.clearFilters]}
                        >
                            Clear Filters
                        </PrimaryButton>
                        <PrimaryButton
                            onPress={handleSubmit}
                            icon={shareIconWhite}
                            style={styles.button}
                        >
                            Apply Filters
                        </PrimaryButton>
                    </View>
                </View>
            </View >
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.browsefilters && state.form.browsefilters.values ? state.form.browsefilters.values : {},
        initialValues: state.browseReducer.filters || {},
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('browsefilters', field, value));
        },
        onSubmit: (data) => {
            setBrowseFilter(data)
            dispatch(closeDrawer());
        },
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
        clearFilters: (type) => {
            clearBrowseFilter();
            dispatch(closeDrawer());
        },
    }
};

BrowseFilters = reduxForm({ form: 'browsefilters' })(BrowseFilters);

export default connect(mapStateToProps, mapDispatchToProps)(BrowseFilters);
