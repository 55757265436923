import Axios from 'axios';
import baseService, { apiSeverUrl } from './baseService.js';
import { Platform } from 'react-native';

export function refer(data) {
  if (!!data.isFileUploaded) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const requestData = new FormData();
    data.pdf_files.forEach((e, index) => {
      requestData.append(`pdf_files[]`, e);
    });
    delete data.pdf_files;
    delete data.isFileUploaded;
    requestData.append('refer_strength_data', JSON.stringify(data));
    return baseService.post('/refer_requests/', requestData, config);
  }
  return baseService.post('/refer_requests/', data);
}
export const updateReferral = (id, data) => {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const requestData = new FormData();
  data.pdf_files.forEach((e, index) => {
    requestData.append(`pdf_files[]`, e);
  });
  delete data.pdf_files;
  delete data.isFileUploaded;
  requestData.append('refer_strength_data', JSON.stringify({ ...data, number_of_links: data.links }));
  delete data.links;
  return baseService.put(`/refer_requests/${id}`, requestData, config);
};

export const updateAttentionStatus = (id, data) => {
  return baseService.patch(`/refer_requests/${id}/update_attention_status`, data);
};

export function getReferData(token) {
  return baseService.get('/refer_requests/refer_data', { params: { token: token } });
}

export function getReferDataById(id) {
  return baseService.get('/refer_requests/refer_data', { params: { refer_request_id: id } });
}

export function declineReasons(data) {
  return baseService.get('/decline_reasons', data);
}

export function referUsers(job_id, job_uuid) {
  const fromTracker = localStorage.getItem('fromTracker');
  const userId = localStorage.getItem('userId');
  return baseService.get('/refer_users', {
    params: { job_id: job_id, general_referral_org: fromTracker, user_id: userId, job_uuid },
  });
}

export function resendReferralRequest(id) {
  return baseService.post('/resend_referral', { refer_request_id: id });
}

export function submitDeclineReferral(data) {
  return baseService.post('/decline_referral', data);
}

export function createUserAccount(data) {
  return baseService.post('/create_user_of_referral', data);
}

export function sendInvite(data) {
  return baseService.post('/send_invite', data);
}

export function validateUserExistance(data) {
  return baseService.post('/users/validate_user_existance', data);
}

export function copyReferralLink(data) {
  return baseService.post('/copy_referral', data);
}

export function getReferDataByUserAndJob(data) {
  return baseService.get('/refer_requests/refer_request_by_user_job', { params: data });
}

export function getReferralLink(data) {
  return baseService.post('/referral_links', data);
}

export const getUserDetailsForRefererProvider = (id) => {
  return Axios.create({
    baseURL: apiSeverUrl,
    headers: { 'from-web-app': Platform.OS == 'web' },
  }).get(`api/v10/user_profiles/${id}`);
};
