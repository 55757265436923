export const GET_FOLLOW_ORGANIZATIONS = 'GET_FOLLOW_ORGANIZATIONS';
export const GET_FOLLOW_ORGANIZATIONS_SUCCESS = 'GET_FOLLOW_ORGANIZATIONS_SUCCESS';
export const GET_FOLLOW_ORGANIZATIONS_ERROR = 'GET_FOLLOW_ORGANIZATIONS_ERROR';

export const FOLLOW_ORGANIZATION = 'FOLLOW_ORGANIZATION';
export const FOLLOW_ORGANIZATION_SUCCESS = 'FOLLOW_ORGANIZATION_SUCCESS';
export const FOLLOW_ORGANIZATION_ERROR = 'FOLLOW_ORGANIZATION_ERROR';

export const UNFOLLOW_ORGANIZATION = 'UNFOLLOW_ORGANIZATION';
export const UNFOLLOW_ORGANIZATION_SUCCESS = 'UNFOLLOW_ORGANIZATION_SUCCESS';
export const UNFOLLOW_ORGANIZATION_ERROR = 'UNFOLLOW_ORGANIZATION_ERROR';

export const GET_ORGANIZATIONS_INVITES = 'GET_ORGANIZATIONS_INVITES';
export const GET_ORGANIZATIONS_INVITES_SUCCESS = 'GET_ORGANIZATIONS_INVITES_SUCCESS';
export const GET_ORGANIZATIONS_INVITES_ERROR = 'GET_ORGANIZATIONS_INVITES_ERROR';

export const SUBMIT_ORGANIZATION_INVITE = 'SUBMIT_ORGANIZATION_INVITE';
export const SUBMIT_ORGANIZATION_INVITE_SUCCESS = 'SUBMIT_ORGANIZATION_INVITE_SUCCESS';
export const SUBMIT_ORGANIZATION_INVITE_ERROR = 'SUBMIT_ORGANIZATION_INVITE_ERROR';

export const GET_ORGANIZATIONS_TAB = 'GET_ORGANIZATIONS_TAB';
export const GET_ORGANIZATIONS_TAB_SUCCESS = 'GET_ORGANIZATIONS_TAB_SUCCESS';
export const GET_ORGANIZATIONS_TAB_ERROR = 'GET_ORGANIZATIONS_TAB_ERROR';

export const GET_ORGANIZATION_DETAILS = 'GET_ORGANIZATION_DETAILS';
export const GET_ORGANIZATION_DETAILS_SUCCESS = 'GET_ORGANIZATION_DETAILS_SUCCESS';
export const GET_ORGANIZATION_DETAILS_ERROR = 'GET_ORGANIZATION_DETAILS_ERROR';

export const GET_ORGANIZATION_JOBS = 'GET_ORGANIZATION_JOBS';
export const GET_ORGANIZATION_JOBS_SUCCESS = 'GET_ORGANIZATION_JOBS_SUCCESS';
export const GET_ORGANIZATION_JOBS_ERROR = 'GET_ORGANIZATION_JOBS_ERROR';

export const GET_ORGANIZATION_ACTIVE_JOBS = 'GET_ORGANIZATION_ACTIVE_JOBS';
export const GET_ORGANIZATION_ACTIVE_JOBS_SUCCESS = 'GET_ORGANIZATION_ACTIVE_JOBS_SUCCESS';
export const GET_ORGANIZATION_ACTIVE_JOBS_ERROR = 'GET_ORGANIZATION_ACTIVE_JOBS_ERROR';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS';
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR';

export const GET_INDUSTRIES = 'GET_INDUSTRIES';
export const GET_INDUSTRIES_SUCCESS = 'GET_INDUSTRIES_SUCCESS';

export const CHECK_USER_EMPLOYER = 'CHECK_USER_EMPLOYER';

export const COMPANY_EXISTS = 'COMPANY_EXISTS';

export const GET_DROPDOWN_VALUES_SUCCESS = 'GET_DROPDOWN_VALUES_SUCCESS';
export const GET_PERKS_AND_BENEFITS_SUCCESS = 'GET_PERKS_AND_BENEFITS_SUCCESS';

export const GET_SOURCES_DROPDOWN_VALUES_SUCCESS = 'GET_SOURCES_DROPDOWN_VALUES_SUCCESS';
export const SET_ORGANIZATION_ID = "SET_ORGANIZATION_ID";
