import { change } from 'redux-form';
import { notification } from '../helperFunctions';
import * as globalService from '../services/globalService';

export function uploadImage(file, type, form, stopLoader, handleFormSubmit) {
    return dispatch => {
        return globalService.uploadImage(file, type).then(response => {
            if(response.data.response_code === 12){
            return  notification.ref.show({
                    message: response.data.error,
                    isError: true,
                  });
            }
            if(response.data.response_code === 0){
                  dispatch(change(form, type, response.data.payload.image));
                  return handleFormSubmit()
                }
        }).finally(() => {
            stopLoader();
        });
    };
}
