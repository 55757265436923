const newMemberAppUrls = {
  development: 'http://localhost:3005',
  staging: 'https://staging-member.goboon.co',
  demo: 'https://demo-member.goboon.co',
  production: 'https://member.goboon.co',
};

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

export default newMemberAppUrls[environment];
