import React, {Component} from 'react';
import { Button } from '@ant-design/react-native';
import { View } from 'react-native';
import { connect } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import { drawerElement, formLayout } from '../../generalStyles';
import Text from '../Text/Text';
import { closeDrawer } from '../../DrawerActions.js';
import Actions from '../../RouteActions'

class DrawerHeader extends Component {
    render() {
        return(
            <View style={[drawerElement.headerStyle, this.props.headerStyle]}>
                <View style={drawerElement.drawerHeader, formLayout.row}>
                    <Text style={drawerElement.headerText}>
                        { this.props.title }
                    </Text>
                    <Button
                      size="small"
                      onPress={this.props.handleCloseDrawer || this.props.onDrawerClose}
                      style={{ borderWidth: 0, padding: 0 }}
                    >
                        <Text style={drawerElement.headerCloseButtonText}>
                            <CloseIcon />
                        </Text>
                    </Button>
                </View>
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDrawerClose: () => {
            dispatch(closeDrawer());
        }
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DrawerHeader);
