class PermissionsWeb {
  static async request(_) {
    // throw 1;
  };

  static async checkMultiple(_) {
    // throw 1;
  };
};

PermissionsWeb.contacts = false;
PermissionsWeb.location = false;
PermissionsWeb.notification = false;

export default PermissionsWeb
