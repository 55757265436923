import React, { Component } from 'react';
import { TouchableOpacity, Image } from 'react-native';

//components
import Text from '../Text/Text';

//styles
import styles from './styles';

export default class ListElementButton extends Component {
  render() {
    const {
      style,
      textStyle,
      children,
      secondary,
      onPress,
      disabled,
      icon,
      endIcon,
      customIconSize,
      userPermitted = true,
    } = this.props;
    const buttonStyles = [
      styles.buttonStyle,
      secondary ? styles.secondary : {},
      disabled ? styles.disabled : {},
      style
    ];

    const textStyles = [
      styles.text,
      secondary ? styles.textSecondary : {},
      disabled ? styles.textForDisabled : {},
      textStyle
    ];

    return (
      <TouchableOpacity
        onPress={disabled ? null : onPress}
        style={buttonStyles}
      >
        {icon && (
          <Image source={icon} style={customIconSize ? customIconSize : styles.icon} resizeMode="contain" />
        )}
        <Text style={textStyles}>{children}</Text>
        {endIcon && userPermitted && (
          <Image source={endIcon} style={styles.endIcon} resizeMode="contain" />
        )}
      </TouchableOpacity>
    );
  }
}
