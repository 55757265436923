import { View, Image, Platform, Linking } from 'react-native';
import React, { Component } from 'react';
import {connect} from 'react-redux';
import { withGetScreen } from 'react-native-getscreen';

//components
import Text from '../../../../components/Text/Text';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';

//utils
import * as AsyncStorage from '../../../../AsyncStorage';
import Alert from '../../../../wrappers/Alert/Alert';

//Actions
import * as actions from '../../../Settings/actions';
import { getCurrentUserStats } from '../../../Home/components/Stats/actions';

//Icons
import facebookColorIcon from '../../../../assets/img/facebook_color_icon.png';
import facebookGreyIcon from '../../../../assets/img/facebook_grey_icon.png';

import twitterColorIcon from '../../../../assets/img/twitter_color_icon.png';
import twitterGreyIcon from '../../../../assets/img/twitter_grey_icon.png';

import linkedinColorIcon from '../../../../assets/img/linkedIn_green.png';
import linkedinGreyIcon from '../../../../assets/img/linkedin_grey_icon.png';

// Styles
import styles from './styles';
import { listElement } from '../../../../generalStyles';

//Services
import { apiSeverUrl } from '../../../../services/baseService';


class ManageAccounts extends Component {
    componentDidMount(){
        this.props.getUserStats();
    }

    handleLinkedInConnectPress = () => {
        const { linkedinConnected, unsubscribeLinkedin } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(resp => {
                if (linkedinConnected) {
                    Alert.alert('Disconnect from Linkedin?', 'You won\'t be able to see your new Linkedin connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeLinkedin()
                        }
                    ]);
                }
                else {
                    const url = `${apiSeverUrl}/sign_in_linkedin?device_fingerprint=${response}&` +
                                `access_token=${resp}&web_app=${!mobile}&permission_page=true`
                    if (mobile) {
                        Actions.web({ title: 'Authenticate LinkedIn', url: url });
                    } else {
                        Linking.openURL(url)
                    }
                }
            });
        })
    }

    handleFacebookConnectPress = () => {
        const { facebookConnected, unsubscribeFacebook } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (facebookConnected) {
                    Alert.alert('Disconnect from Facebook?', 'You won\'t be able to see your new Facebook connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeFacebook(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Facebook', url: `${apiSeverUrl}/sign_in_facebook?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/facebook?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    handleTwitterConnectPress = () => {
        const { twitterConnected, unsubscribeTwitter } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (twitterConnected) {
                    Alert.alert('Disconnect from Twitter?', 'You won\'t be able to see your new Twitter connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeTwitter(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Twitter', url: `${apiSeverUrl}/sign_in_twitter?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/twitter?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    render() {
        const { linkedinConnected, facebookConnected, twitterConnected } = this.props;
        const web = !this.props.isMobile();

        return (
            <View style={styles.manageAccountsWrapper}>
                <View style={styles.manageAccounts}>
                    <View style={styles.syncNetworkContainer}>
                        <View style={[web && styles.buttonsWrapper, !web && {marginTop: 30}]}>
                            <Text style={styles.syncNetworkText}>Manage Social Accounts</Text>
                            <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                                <View style={listElement.imageWrapper}>
                                    <Image style={styles.icon} source={linkedinConnected ? linkedinColorIcon : linkedinGreyIcon} />
                                </View>
                                <View style={[listElement.elementBody, listElement.last]}>
                                    <Text style={[styles.elementTitle, linkedinConnected && styles.elementTitleConnected]}>
                                        LinkedIn
                                    </Text>
                                </View>
                                <PrimaryButton
                                    onPress={this.handleLinkedInConnectPress}
                                    style={[styles.button,
                                            linkedinConnected && styles.disconnectButton,
                                            this.props.isMobile() && styles.mobileButton]}
                                >
                                    {
                                        linkedinConnected ?
                                        <Text>Disconnect</Text>
                                        :
                                        <Text>Connect</Text>
                                    }
                                </PrimaryButton>
                            </View>
                            <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                                <View style={listElement.imageWrapper}>
                                    <Image style={styles.icon} source={facebookConnected ? facebookColorIcon : facebookGreyIcon} />
                                </View>
                                <View style={[listElement.elementBody, listElement.last]}>
                                    <Text style={[styles.elementTitle, facebookConnected && styles.elementTitleConnected]}>
                                        Facebook
                                    </Text>
                                </View>
                                <PrimaryButton
                                    onPress={this.handleFacebookConnectPress}
                                    style={[styles.button,
                                            facebookConnected && styles.disconnectButton,
                                            this.props.isMobile() && styles.mobileButton]}
                                >
                                    {
                                        facebookConnected ?
                                        <Text>Disconnect</Text>
                                        :
                                        <Text>Connect</Text>
                                    }
                                </PrimaryButton>
                            </View>
                            <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                                <View style={listElement.imageWrapper}>
                                    <Image style={styles.icon} source={twitterConnected ? twitterColorIcon : twitterGreyIcon} />
                                </View>
                                <View style={[listElement.elementBody, listElement.last]}>
                                    <Text style={[styles.elementTitle, twitterConnected && styles.elementTitleConnected]}>
                                        Twitter
                                    </Text>
                                </View>
                                <PrimaryButton
                                    onPress={this.handleTwitterConnectPress}
                                    style={[styles.button,
                                            twitterConnected && styles.disconnectButton,
                                            this.props.isMobile() && styles.mobileButton]}
                                >
                                    {
                                        twitterConnected ?
                                        <Text>Disconnect</Text>
                                        :
                                        <Text>Connect</Text>
                                    }
                                </PrimaryButton>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        linkedinConnected: state.authReducer.currentUser.linkedin_authenticated,
        facebookConnected: state.authReducer.currentUser.facebook_authenticated,
        twitterConnected: state.authReducer.currentUser.twitter_authenticated,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        unsubscribeLinkedin: () => {
            dispatch(actions.unsubscribeLinkedin());
        },
        unsubscribeFacebook: accessToken => {
            dispatch(actions.unsubscribeFacebook(accessToken));
        },
        unsubscribeTwitter: accessToken => {
            dispatch(actions.unsubscribeTwitter(accessToken));
        },
        getUserStats: () => {
            dispatch(getCurrentUserStats());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ManageAccounts));
