import React, { Component } from 'react';
import { View, Image } from 'react-native';
import { connect } from 'react-redux';
import { reduxForm, Field, change, initialize } from 'redux-form';
import Spinner from 'react-native-loading-spinner-overlay';
import Alert from '../../wrappers/Alert/Alert';
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';
//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Text from '../../components/Text/Text';
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import FormInputCheckbox from '../../components/FormInputCheckbox/FormInputCheckbox';
import FormInput from '../../components/FormInput/FormInput';
import { withGetScreen } from 'react-native-getscreen';
//styles
import styles from './styles';
import declineStyles from '../PublicJobDecline/styles';
import JobSummaryDetails from '../JobPost/JobSummaryDetails/JobSummaryDetails';
import ReferrerDetails from '../JobPost/ReferrerDetails/ReferrerDetails';
import { openReferFormDrawer } from '../../DrawerActions.js';
import { SET_REFER_FEEDBACK_STATUS } from '../../constants/actions/referActions';
import * as refersActions from '../../globalActions/refersActions';
import * as actions from './actions';
import Actions from '../../RouteActions';
import { formLayout } from '../../generalStyles';
import { notification } from '../../helperFunctions';
class ReferredJobPost extends Component {
  state = {
    showNothanks: false,
  };
  componentDidMount() {
    const { getData, referRequestId, getReasons } = this.props;
    this.props.setFeedbackStatus(false);
    getReasons();
    if (referRequestId) {
      getData(referRequestId);
    }
  }
  componentWillUnmount() {
    this.props.setFeedbackStatus(false);
  }
  onHandleReason = (e) => {
    this.props.setFieldValue('reason', e);
  };
  submitForm = (e) => {
    e.preventDefault();
    const { formValues, referredJobData } = this.props;
    formValues.refer_token = referredJobData.refer_request.refer_token;
    if (!formValues.reason || !formValues.comments) {
      notification.ref.show({ message: 'Please fill all mandatory fields', isError: true });
    } else {
      this.props.onSubmit(formValues);
    }
  };
  noThanksRender = () => {
    const mobile = this.props.isMobile();
    const { signUpLoading, formValues, declineReasons } = this.props;
    const firstHeading = 'Help us help you';
    const secondHeading = 'Where was the mismatch?';
    const thirdHeading = 'Your feedback improves the quality and relevance of future referrals.';
    return (
      <DashboardBase>
        <View style={[declineStyles.body]}>
          <View style={declineStyles.flexCenter}>
            <View>
              <Text style={mobile ? declineStyles.mobileSignupText : styles.firstHeading}>{firstHeading}</Text>
            </View>
            <Text style={mobile ? declineStyles.mobileBoonHeading : styles.secondHeading}>{secondHeading}</Text>
            <View>
              <Text style={mobile ? declineStyles.mobileSignupText : styles.thirdHeading}>{thirdHeading}</Text>
            </View>
          </View>
          <View style={[declineStyles.flexCenter, { zIndex: 200 }]}>
            <View style={mobile ? declineStyles.mobileSignUpForm : declineStyles.signUpForm}>
              <View>
                {declineReasons && (
                  <View style={formLayout.row}>
                    <OrganizationStatus
                      selected={declineReasons[0]}
                      onChange={this.onHandleReason}
                      name="reasons"
                      placeholder="Reason(s) *"
                      dropdownValues={declineReasons}
                      styles={[formLayout.fullWidth, { marginTop: 9 }]}
                      formSubmitted={() => {}}
                      labelField={'label'}
                      valueField={'value'}
                      multi={true}
                      customColor={'#2DB581'}
                    />
                  </View>
                )}
                <View style={[formLayout.row, { zIndex: -9 }]}>
                  <Field
                    component={FormInput}
                    name="comments"
                    placeholder="Comments *"
                    wrapperStyle={[formLayout.fullWidth, { zIndex: -9 }]}
                    returnKeyType="next"
                    autoFocus
                  />
                </View>
              </View>
              <View style={[formLayout.row, { justifyContent: 'center' }]}>
                <PrimaryButton onPress={this.submitForm}>Submit</PrimaryButton>
              </View>
            </View>
          </View>
        </View>
        <Spinner visible={signUpLoading} />
      </DashboardBase>
    );
  };
  jobPostRender = () => {
    const mobile = this.props.isMobile();
    const { referredJobData } = this.props;
    const ref_job_data = referredJobData && referredJobData.job;
    let refer_obj;
    if (ref_job_data) {
      refer_obj = {
        job_id: {
          id: referredJobData.job.id,
          title: referredJobData.job.title,
        },
        organization_id: {
          id: referredJobData.organization.id,
          name: referredJobData.organization.name,
          logo_image_url: referredJobData.organization.logo_image_thumb,
        },
      };
    }

    return (
      <DashboardBase title={'Refered Job'}>
        <View style={[{ flex: 1, overflow: 'scroll' }, { padding: 10 }, false && { maxHeight: 'calc(110vh - 83px)' }]}>
          <Text style={styles.title}>Job Summary </Text>
          <JobSummaryDetails
            jobDetails={referredJobData.job}
            employer={referredJobData.organization}
            tagStyle={styles.tag}
          />
          <Text style={styles.title}>Referer Details</Text>
          <ReferrerDetails
            user={referredJobData.referrer}
            refer_request={referredJobData && referredJobData.refer_request}
          />
          <View style={styles.actionButtonsContainer}>
            <PrimaryButton
              style={[styles.actionButton, styles.interestedButton]}
              onPress={() =>
                Actions.dashboardPublicJobApplication({
                  showCreds: false,
                  refer_request: referredJobData && referredJobData.refer_request,
                })
              }
            >
              I'm Interested
            </PrimaryButton>
            <PrimaryButton
              style={[styles.actionButton, styles.interestedButton]}
              onPress={() => this.props.onReferClick(refer_obj)}
            >
              Refer
            </PrimaryButton>
            <PrimaryButton small style={styles.noThanksButton} onPress={() => this.setState({ showNothanks: true })}>
              <Text style={styles.noThanksText}>No Thanks</Text>
            </PrimaryButton>
          </View>
        </View>
      </DashboardBase>
    );
  };
  feedbackRedirect = (target) => {
    const { setFeedbackStatus, onReferClick, referredJobData } = this.props;
    const refer_obj = {
      job_id: {
        id: referredJobData.job.id,
        title: referredJobData.job.title,
      },
      organization_id: {
        id: referredJobData.organization.id,
        name: referredJobData.organization.name,
        logo_image_url: referredJobData.organization.logo_image_thumb,
      },
    };
    setFeedbackStatus(false);
    Actions.tracker();
    if (target) {
      onReferClick(refer_obj);
    }
  };
  feedbackRender() {
    Alert.alert(
      'Thank you!',
      'Your feedback has been recieved.',
      [
        {
          text: 'Refer a friend',
          onPress: () => {
            this.feedbackRedirect(true);
          },
        },
        {
          text: 'Cancel',
          style: 'cancel',
          onPress: () => {
            this.feedbackRedirect();
          },
        },
      ],
      { cancelable: false }
    );
  }
  render() {
    const { feedbackCompleted } = this.props;
    let child = null;
    if (this.state.showNothanks) {
      child = this.noThanksRender();
    } else if (!this.state.showNothanks && !feedbackCompleted) {
      child = this.jobPostRender();
    }
    if (this.state.showNothanks && feedbackCompleted) {
      this.feedbackRender();
    }
    return child;
  }
}
ReferredJobPost = reduxForm({ form: 'decline_referral', enableReinitialize: true })(withGetScreen(ReferredJobPost));
const mapStateToProps = (state) => {
  return {
    referredJobData: state.refersReducer.referredJobData,
    declineReasons: state.refersReducer.declineReasons,
    formValues:
      state.form.decline_referral && state.form.decline_referral.values ? state.form.decline_referral.values : {},
    feedbackCompleted: state.refersReducer.feedbackCompleted,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (field, value) => {
      dispatch(change('decline_referral', field, value));
    },
    getData: (token) => {
      dispatch(actions.getData(token));
    },
    onReferClick: (initialValues) => {
      dispatch(openReferFormDrawer(initialValues));
    },
    getReasons: () => {
      return dispatch(refersActions.getDeclineReasons());
    },
    onSubmit: (formValues) => {
      dispatch(refersActions.submitDeclineReferral(formValues, false));
    },
    setFeedbackStatus: (data) => {
      dispatch({ type: SET_REFER_FEEDBACK_STATUS, payload: data });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ReferredJobPost));
