import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  rootCont: {
    display: 'flex',
    flexDirection: 'column',
  },
  loading: {
    borderWidth: 0.5,
    borderColor: '#c2c2c2',
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: 10,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#ffffff',
  },
  loadingText: {
    fontSize: 15,
    color: 'rgb(153, 153, 153)',
  },
  suggestionsCont: {
    borderWidth: 1,
    borderColor: '#c2c2c2',
    borderStyle: 'solid',
    borderRadius: 4,
    marginTop: 10,
    position: 'absolute',
    width: '100%',
    backgroundColor: '#ffffff',
    zIndex: 10,
  },
  suggestionItem: {
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#ffffff',
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    borderRadius: 4,
  },
  suggestionItemActive: {
    backgroundColor: 'rgb(222, 235, 255)',
    cursor: 'pointer',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
  },
});
