import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { withRouter } from 'react-router-dom';

const PageTracker = ({ location, children }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname + location.search,
        title: document.title,
      });
    }
  }, [location]);

  return children;
};

export default withRouter(PageTracker);
