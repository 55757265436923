import Actions from '../../RouteActions';

import * as appActionsConstants from '../../constants/actions/appActions';
import * as authService from '../../services/authService';
import { notification } from '../../helperFunctions';

export const enableLinkedInSSO = (payload) => (dispatch) => {
  authService.enableLinkedInSSO(payload).then((response) => {
    if (!!response.data.error) {
      notification.ref.show({ message: 'Could not authenticate with LinkedIn', isError: true });
    }
    window.location.href = response.data.redirect_uri;
  });
};
