import * as stepsService from '../services/stepsService';
import RouteActions from '../RouteActions.js';
import baseService from '../services/baseService';

export function redirect(params = {}) {
    const token = baseService.defaults.headers.common['X-User-Token'];
    return (dispatch) => {
        if (token) {
            stepsService.getCurrentStep().then((response) => {
                switch (response.data.payload) {
                    case 'signup':
                        RouteActions.signUp();
                        break;
                    case 'invite':
                        RouteActions.invitations();
                        break;
                    case 'create_password':
                        RouteActions.resetForgetPassword();
                        break;
                    case 'phone_number':
                        RouteActions.confirmPhone();
                        break;
                    case 'confirm_email':
                        RouteActions.magicLink();
                        break;
                    case 'account_approval':
                        RouteActions.waitForAccountApproval();
                        break;
                    case 'location':
                        RouteActions.signUpLocation();
                        break;
                    case 'request_access':
                        RouteActions.signUpRequestAccess();
                        break;
                    case 'home':
                        RouteActions.home({ params: params });
                        break;
                    default:
                        RouteActions.base();
                    // case 'role_identification':
                    //     RouteActions.signUpGeneralIfo();
                }
            });
        }
    };
}
