import React, {Component} from 'react';
import { View, ScrollView } from 'react-native';
import { List } from '@ant-design/react-native';
import { drawerElement } from '../../generalStyles';
import { connect } from 'react-redux';
import Text from '../Text/Text';
import * as actions from '../../screens/Home/actions';
import { closeDrawer } from '../../DrawerActions.js';

class UserOrganizationsDrawer extends Component {
    getListItems() {
        const { actionSheetOptions, changeSelectedOrganization, organizations } = this.props;
        const itemArr = actionSheetOptions.map((action, index) => {
            return (
                <List.Item
                    key={index}
                    onPress={() => changeSelectedOrganization(index, actionSheetOptions, organizations)}
                    style={drawerElement.listItemStyle}
                >
                    <Text style={drawerElement.itemTextStyle}>{action}</Text>
                </List.Item>
            );
        });
        return itemArr;
    }

    render() {
        const itemArr = this.getListItems();
        let component = <List style={drawerElement.listStyle}>{itemArr}</List>
        let scrollStyle = drawerElement.scrollElement;
        const sidebar = (
            <ScrollView style={scrollStyle} >
                {component}
            </ScrollView>
        );

        return <View>{sidebar}</View>
    }
}

const mapStateToProps = state => {
    const organizationsWhereImMember = state.organizationsReducer.userOrganizations.filter(organization => {
        return organization.role !== null && organization.status === 1
    });

    return {
        organizations: organizationsWhereImMember,
        actionSheetOptions: [
            'Dashboard',
            ...organizationsWhereImMember.map(organizationObj => organizationObj.organization.name),
            'Cancel'
        ],
        selectedOrganization: state.activityReducer.selectedOrganization
    }
};

const mapDispatchToProps = dispatch => {
    return {
        changeSelectedOrganization: (index, actionSheetOptions, organizations) => {
            if (index === 0) {
                dispatch(actions.changeSelectedOrganization({name: 'Dashboard'}));
            } else if (index !== actionSheetOptions.length - 1) {
                dispatch(actions.changeSelectedOrganization(organizations[index  - 1].organization));
            }
            dispatch(closeDrawer());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOrganizationsDrawer);
