import { StyleSheet } from "react-native";

export default StyleSheet.create({
  buttonStyle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: "#CCCCCC",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    height: 10,
    width: 10,
  },
});
