import React, { Component } from 'react';
import { View, Image } from 'react-native';
import { connect } from 'react-redux';
//component
import LocationMap from '../../../components/LocationMap/LocationMap'
import defaultOrgAvatar from '../../../assets/img/default_org_avatar.png'
import Text from '../../../components/Text/Text';
//images
import { withGetScreen } from 'react-native-getscreen';
//styles
import styles from './styles';
import { formLayout, listElement } from '../../../generalStyles';
//utils
import * as redirectActions from '../../../globalActions/redirectActions';

class JobSummaryDetails extends Component {
    render() {
        const mobile = this.props.isMobile();
        const { jobDetails, employer, tagStyle, hideMap } = this.props;
        const image = employer && employer.logo_image_thumb ? { uri: employer && employer.logo_image_thumb } : defaultOrgAvatar;
        return (
            <View style={[styles.detailsWrapper]}>
                {Boolean(employer && jobDetails) &&
                    <View style={formLayout.noSpaceRow}>
                    <View style={[listElement.imageWrapper, {alignItems: 'center'}]}>
                        <Image style={styles.image} source={image} resizeMode="contain" />
                    </View>
                    <View style={mobile ? styles.detailsContainerMobile : styles.detailsContainer}>
                        <Text style={styles.company}>{employer.name}</Text>
                        <Text style={styles.position}>{jobDetails.title}</Text>
                        <View style={[styles.tagsWrapper]}>
                            {jobDetails.skills.map((tag) =>
                                <View style={[styles.skillTag, tagStyle]} key={tag.id}>
                                    <Text style={[styles.skillTagText, tagStyle]}>{tag.name}</Text>
                                </View>
                            )}
                        </View>
                    </View>
                </View>
                }
                <View>
                    {Boolean(jobDetails && !jobDetails.location) && <Text style={styles.locationText}>No location</Text>}
                    {
                        Boolean(jobDetails && jobDetails.location) &&
                        <View>
                            <View style={mobile ? styles.full_addressMobile : styles.full_address}>
                                <Text style={styles.title}>Location: </Text>
                                <Text style={styles.description}>{jobDetails && jobDetails.location.full_address}</Text>
                            </View>
                            {!hideMap && <View style={mobile ? styles.mapMobile : styles.map}>
                                <LocationMap lat={jobDetails && jobDetails.location.lat} lon={jobDetails && jobDetails.location.lon}></LocationMap>
                            </View>}
                        </View>
                    }
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return { };
};

const mapDispatchToProps = dispatch => {
    return {
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(JobSummaryDetails));
