import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {
    View,
    Image
} from 'react-native';

//actions
import { getOrganizationsBySort } from './actions';
import * as actions from '../../screens/OrganizationProfile/actions';
import { getSuggestions } from '../AutoSuggestComponent/actions';
import { openBrowseFiltersDrawer } from '../../DrawerActions';
import * as organizationsActions from '../../globalActions/organizationsActions';
import { withGetScreen } from 'react-native-getscreen';

// styles
import { listElement, $green } from '../../generalStyles';
import styles from './styles';

// images
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

// Components
import Text from '../../components/Text/Text';
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import OrganizationJobListElement from './OrganizationJobListElement.js';
import ModalDropdown from '../../wrappers/modalDropdown/modalDropdown';
import LocationMap from '../../components/LocationMap/LocationMap'

// Helpers
import { locationObjToStr } from '../../helperFunctions';


const orgDetail = {
    image: {
        width: 60,
        height: 60,
        marginRight: 12,
        borderRadius: 5,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        paddingBottom: 10
    },
    header: {
        fontSize: 14,
        paddingBottom: 3,
        fontWeight: 'bold'
    },
    description: {
        fontSize: 14,
    },
    salary: {
        fontSize: 30,
        fontWeight: 'bold',
    },
    tagsWrapper: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap'
    },
    tag: {
        marginBottom: 8,
        alignSelf: 'stretch',
        width: 'auto',
        paddingHorizontal: 8
    },
    skillTag: {
        width: 74,
        borderRadius: 3,
        paddingVertical: 5,
        marginRight: 8,
        backgroundColor: '#EBF0F3'
    },
    skillTagText: {
        fontSize: 12,
        color: '#AAAAAA',
        textAlign: 'center',
        fontWeight: 'bold'
    },
    recentJobText: {
        paddingLeft: 20,
        paddingTop: 10,
        fontSize: 12,
        color: '#a3adac'
    },
    seeAllText: {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#0FBC71',
        paddingLeft: 20
    },
    buttonsWrapper: {
        flexDirection: 'row',
        marginVertical: 5
    },
    map: {
        width: '100%',
        height: 300,
        marginTop: 10
    },
    mapMobile: {
        width: '100%',
        height: 200,
        marginTop: 10
    },
    locationText: {
        fontSize: 16
    },
    full_address: {
        width: '15%',
        marginTop: 10
    },
    full_addressMobile: {
        width: '50%',
        marginTop: 10
    }
};

class OrganizationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobList: [],
            showAllJobsClicked: false
        }
    }

    componentDidMount() {
        const { firstOrganization, getOrganizationDetails, match, organizationDetails, organizationActiveJobsPreview } = this.props;
        const urlOrgId = match.params.organizationId;
        const orgId = urlOrgId > 0 ? urlOrgId : firstOrganization.id;
        if (orgId || !organizationDetails) {
            getOrganizationDetails(orgId);
        }
        this.setState({ jobList: organizationActiveJobsPreview });
    }

    componentWillReceiveProps(props) {
        this.setState({ jobList: props.organizationActiveJobsPreview, showAllJobsClicked: false })
    }


    actionButtons() {
        const followButtonStyle = {
            borderRadius: 5,
            height: 40,
            width: 150,
            backgroundColor: '#FFB32F'
        };
        const { organizationDetails } = this.props;
        return (
            <View style={[orgDetail.buttonsWrapper, { flexDirection: 'row', paddingLeft: 100 }]}>
                {organizationDetails.is_following ?
                    <ListElementButton
                        textStyle={{ color: 'white' }}
                        style={followButtonStyle}
                        onPress={() => this.props.unfollowOrganization(organizationDetails.id)}
                    >
                        Unfollow
                    </ListElementButton>
                    :
                    <ListElementButton
                        textStyle={{ color: 'white' }}
                        style={followButtonStyle}
                        onPress={() => this.props.followOrganization(organizationDetails.name)}
                    >
                        + Follow
                    </ListElementButton>
                }
            </View>
        );
    }

    // filterActionButtons() {
    //     const filterButtonStyle = {
    //         borderRadius: 3,
    //         height: 40,
    //         width: 80,
    //         backgroundColor: '#FFB32F'
    //     };

    //     return (
    //         <View style={[orgDetail.buttonsWrapper, { flexDirection: 'row', marginBottom: 10 }]}>
    //             <ModalDropdown
    //                 onSelect={(selectedOption, val) => {
    //                     let defaultValue = typeof (selectedOption) == "object" ? selectedOption.value : val
    //                     this.props.onSortChange(defaultValue)
    //                     switch (defaultValue) {
    //                         case 'Size':
    //                             this.props.getOrganizationsBySort('size', this.props.queryString);
    //                             break;
    //                         case 'Location':
    //                             this.props.getOrganizationsBySort('location', this.props.queryString);
    //                             break;
    //                         case 'Number of Open Jobs':
    //                             this.props.getOrganizationsBySort('open_jobs', this.props.queryString);
    //                             break;
    //                     }
    //                 }}
    //                 defaultValue={this.props.sortDefaultValue}
    //                 placeholder='Sort By'
    //                 options={['Size', 'Location', 'Number of Open Jobs']}
    //                 stylingClass='modal-width'
    //             >
    //             </ModalDropdown>
    //         </View>
    //     );
    // }

    showAllJobs() {
        this.setState({ jobList: this.props.organizationActiveJobs, showAllJobsClicked: true })
    }

    render() {
        const { organizationDetails, organizationActiveJobs, organizationActiveJobsPreview, showFilters } = this.props;
        const image = organizationDetails.logo_image_thumb ? { uri: organizationDetails.logo_image_thumb } : defaultOrgAvatar;
        const mainContainer = {
            backgroundColor: 'white',
            flex: 1
        };
        const jobCountStyle = {
            fontSize: 40,
            color: $green
        };
        const isMobile = this.props.isMobile();
        return (
            <View style={{ justifyContent: 'center' }}>
                {/* {showFilters && <View style={[styles.row, { justifyContent: 'flex-end' }]}>{this.filterActionButtons()}
                </View>} */}
                <View style={[{ width: '100%', zIndex: -1 }, mainContainer]}>
                    <View style={[listElement.elementWrapperTopAligned]}>
                        <View style={styles.col}>
                            <View style={listElement.imageWrapper}>
                                <Image style={orgDetail.image} source={image} resizeMode="contain" />
                            </View>
                        </View>
                        <View style={listElement.infoWrapper}>
                            <Text style={[orgDetail.title]} numberOfLines={1}>{organizationDetails.name}</Text>
                            {Boolean(organizationDetails.locations) && <Text style={orgDetail.description} numberOfLines={1}>{locationObjToStr(organizationDetails.locations[0])}</Text>}
                        </View>
                        <View style={[listElement.rightBlock, { paddingTop: 10, paddingRight: 10 }]}>
                            <View style={{ alignItems: 'center' }}>
                                <Text style={jobCountStyle}>{organizationActiveJobs && organizationActiveJobs.length}</Text>
                                <Text style={listElement.description}>Job Openings</Text>
                            </View>
                        </View>
                    </View>
                    {showFilters && this.actionButtons()}
                    <View>
                        <View style={{ paddingTop: 30, borderBottomColor: '#dadada', borderBottomWidth: 1, width: '100%' }} />
                        {this.state.jobList && this.state.jobList.length > 0 &&
                            <View>
                                <View>
                                    <Text style={orgDetail.recentJobText}>Recent Job Openings</Text>
                                {this.state.jobList.map(activeJob => <OrganizationJobListElement key={activeJob.id} job={activeJob} isMobile={isMobile}/>)}
                                </View>
                                {!this.state.showAllJobsClicked && <Text style={orgDetail.seeAllText} onPress={() => this.showAllJobs()}>See All...</Text>}
                            </View>
                        }
                    </View>
                    <View style={{ padding: 30 }}>
                        <View>
                            <Text style={orgDetail.title}>About Company</Text>
                            <Text id='ql-editor' dangerouslySetInnerHTML={{__html: organizationDetails.overview}}></Text>
                        </View>
                        <Text style={orgDetail.title}>Location</Text>
                        {
                            Boolean(organizationDetails.organization_headquarter) &&
                            <View>
                                 {isMobile ? <View /> :
                                    <View style={isMobile ? orgDetail.mapMobile : orgDetail.map}>
                                        <LocationMap lat={organizationDetails.organization_headquarter.lat} lon={organizationDetails.organization_headquarter.lon}></LocationMap>
                                    </View>
                                    }
                                <View style={isMobile ? orgDetail.full_addressMobile : orgDetail.full_address}>
                                    <Text style={orgDetail.description}>{organizationDetails.organization_headquarter.full_address}</Text>
                                </View>
                            </View>
                        }

                    </View>
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        organizationDetails: state.organizationsReducer.organizationDetails,
        organizationJobs: state.organizationsReducer.organizationJobs,
        organizationActiveJobs: state.organizationsReducer.organizationActiveJobs,
        organizationActiveJobsPreview: state.organizationsReducer.organizationActiveJobsPreview,
        queryString: state.suggestionsReducer.queryString
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationDetails: organizationId => {
            dispatch(actions.getOrganizationDetails(organizationId));
        },
        getOrganizationJobs: (organizationId, params) => {
            dispatch(actions.getOrganizationJobs(organizationId, params));
        },
        followOrganization: organizationName => {
            dispatch(organizationsActions.followOrganization(organizationName));
        },
        unfollowOrganization: organizationId => {
            dispatch(organizationsActions.unfollowOrganization(organizationId));
        },
        submitInvite: organizationId => {
            dispatch(organizationsActions.submitInvite(organizationId));
        },
        openFilterDrawer: () => {
            dispatch(openBrowseFiltersDrawer())
        },
        // getOrganizationsBySort: (sortBy, queryString) => {
        //     if (queryString && queryString.length > 0) {
        //         dispatch(getSuggestions(queryString, 'globalSearch', 'results', null, null, 'globalSearch', 'organizations', sortBy))
        //     } else {
        //         dispatch(getOrganizationsBySort(sortBy));
        //     }
        // }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGetScreen(OrganizationDetail)));
