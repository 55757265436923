import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    linkWrapper: {
        position: 'relative'
    },
    deleteButton: {
        backgroundColor: $green,
        position: 'absolute',
        height: 20,
        width: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
        right: 0
    },
    deleteButtonText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 20,
        textAlign: 'center'
    },
    addButtonStyle: {
        backgroundColor: '#fff',
        height: 30,
        width: 145,
        marginTop: 24
    }
});