import baseService from './baseService.js';

export function getUserContacts(params) {
    return baseService.get('/user_contacts', {params});
}

export function updateUserContacts(user_contacts) {
    return baseService.put('/user_contacts', {
        user_contacts
    });
}

export function postUserContacts(user_contacts) {
    return baseService.put('/user_contacts/update_async', {
        user_contacts
    });
}

export function getUserConnections(params) {
    return baseService.get('/connections', {params});
}

export function requestUserConnections(ids, invertedSelection) {
    const params = {
        ids
    };

    if (invertedSelection) {
        params.select_all = true;
    }

    return baseService.post('/connections/request_batch/', params);
}

export function inviteUsers(ids, invertedSelection) {
    const params = {
        ids
    };

    if (invertedSelection) {
        params.select_all = true;
    }
    return baseService.post('/invitations', params);
}

export function requestConnection(user_id) {
    return baseService.post('/connections', {user_id});
}

export function getUserById(userId) {
    return baseService.get(`/users/${userId}/`);
}

export function acceptConnection(connectionId) {
    return baseService.post(`/connections/${connectionId}/accept`);
}

export function rejectConnection(connectionId) {
    return baseService.post(`/connections/${connectionId}/reject`);
}

export function checkLinkedInContactsDataArchive(user_id) {
    return baseService.post(`/connections/check_linkedin_contacts_data_archive`, { user_id });
}
