export const GET_USER_CONNECTIONS = 'GET_USER_CONNECTIONS';
export const GET_USER_CONNECTIONS_SUCCESS = 'GET_USER_CONNECTIONS_SUCCESS';
export const GET_USER_CONNECTIONS_ERROR = 'GET_USER_CONNECTIONS_ERROR';

export const CLEAR_USER_CONNECTIONS = 'CLEAR_USER_CONNECTIONS';

export const REQUEST_USER_CONNECTIONS = 'REQUEST_USER_CONNECTIONS';
export const REQUEST_USER_CONNECTIONS_SUCCESS = 'REQUEST_USER_CONNECTIONS_SUCCESS';
export const REQUEST_USER_CONNECTIONS_ERROR = 'REQUEST_USER_CONNECTIONS_ERROR';

export const GET_USER_CONTACTS = 'GET_USER_CONTACTS';
export const GET_USER_CONTACTS_SUCCESS = 'GET_USER_CONTACTS_SUCCESS';
export const GET_USER_CONTACTS_ERROR = 'GET_USER_CONTACTS_ERROR';

export const UPDATE_USER_CONTACTS = 'UPDATE_USER_CONTACTS';
export const UPDATE_USER_CONTACTS_SUCCESS = 'UPDATE_USER_CONTACTS_SUCCESS';
export const UPDATE_USER_CONTACTS_ERROR = 'UPDATE_USER_CONTACTS_ERROR';

export const INVITE_USERS = 'INVITE_USERS';
export const INVITE_USERS_SUCCESS = 'INVITE_USERS_SUCCESS';
export const INVITE_USERS_ERROR = 'INVITE_USERS_ERROR';

export const REQUEST_CONNECTION = 'REQUEST_CONNECTION';
export const REQUEST_CONNECTION_SUCCESS = 'REQUEST_CONNECTION_SUCCESS';
export const REQUEST_CONNECTION_ERROR = 'REQUEST_CONNECTION_ERROR';

export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_ERROR = 'GET_USER_BY_ID_ERROR';

export const ACCEPT_CONNECTION = 'ACCEPT_CONNECTION';
export const ACCEPT_CONNECTION_SUCCESS = 'ACCEPT_CONNECTION_SUCCESS';
export const ACCEPT_CONNECTION_ERROR = 'ACCEPT_CONNECTION_ERROR';

export const REJET_CONNECTION = 'REJET_CONNECTION';
export const REJET_CONNECTION_SUCCESS = 'REJET_CONNECTION_SUCCESS';
export const REJET_CONNECTION_ERROR = 'REJET_CONNECTION_ERROR';

export const IMPORT_LINKEDIN_CONTACTS = 'IMPORT_LINKEDIN_CONTACTS';
export const IMPORT_LINKEDIN_CONTACTS_SUCCESS = 'IMPORT_LINKEDIN_CONTACTS_SUCCESS';
