import React, { Component } from 'react';
import { View, TouchableOpacity, Image, Keyboard, Linking, Dimensions } from 'react-native';
import { ActionSheetCustom as ActionSheet } from 'react-native-actionsheet';
import { connect } from 'react-redux';

//styles
import { listElement } from '../../generalStyles';
import styles from './styles';

//components
import ListElementButton from '../../components/ListElementButton/ListElementButton'
import Text from '../../components/Text/Text';

//images
import networkIcon from '../../assets/img/boon_network_icon.png';
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
import optionsIcon from '../../assets/img/options-icon.png';
import shareIcon from '../../assets/img/share_icon.png';
import linkedIn from '../../assets/img/linkedin_blue.svg';

//actions
import * as networkActions from '../../globalActions/networkActions';
import Actions from '../../RouteActions';
import { openReferFormDrawer } from '../../DrawerActions';

//utils
import { getCurrentOrganizationForUser } from '../../helperFunctions';
import * as contactProfileActions from '../../screens/ContactProfile/actions';

//constants
const actionReferSheetOptionsCollection = {
    requested: ['Refer', 'Request pending', 'Cancel'],
    pending: ['Refer', 'Request to connect', 'Cancel']
};

class ContactListElement extends Component {
    constructor(props) {
        super(props);

        this.actionSheet = null;
        this.actionSheetRefer = null;

        this.handleReferPress = this.handleReferPress.bind(this);
        this.handleReferButtonPress = this.handleReferButtonPress.bind(this);
    }

    handleReferPress(item) {
        Keyboard.dismiss();

        const { currentUserOrganization } = this.props;
        if (item.status === 'connected') {
            Actions.refer({
                initialValues: {
                    name: `${item.first_name} ${item.last_name}`,
                    email: item.email,
                    phone_number: item.phone_number,
                    user_id: item.to_user_id,
                    organization_id: currentUserOrganization
                }
            });
        } else {
            this.actionSheetRefer.show();
        }
    }

    handleReferButtonPress(buttonIndex) {
        const { requestConnection, inviteUser, user, currentUserOrganization } = this.props;

        const initialValues = {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            emails: user.emails,
            phone_number: user.phone_number,
            phone_numbers: user.phone_numbers,
            organization_id: currentUserOrganization
        };

        let functionsArray = [];

        switch (user.status) {
            case undefined:
                functionsArray = [() => Actions.refer({
                    initialValues
                }),
                () => {
                    inviteUser(user);
                }
                ];
                break;
            case 'requested':
            case 'pending':
                functionsArray = [() => Actions.refer({
                    initialValues: {
                        user_id: user.to_user_id,
                        ...initialValues
                    }
                }), () => {
                    requestConnection(user.to_user_id);
                }];
                break;
        }

        if (functionsArray[buttonIndex]) {
            functionsArray[buttonIndex]();
        }
    }

    render() {
        const { user, getUserById, contactId, openReferDrawer, currentUserOrganization } = this.props;
        const showName = user.first_name || user.last_name;
        const image = user.user_contact_infos && (user.avatar || user.user_contact_infos[0] != undefined
             && user.user_contact_infos[0].avatar) ?
              { url: (user.avatar || user.user_contact_infos[0] != undefined &&
                 user.user_contact_infos[0].avatar) } : defaultUserAvatar;
        const orgImage = user.org_logo ? { url: user.org_logo } : defaultOrgAvatar;

        const WrapperElement = user.status ? TouchableOpacity : View;

        const actionReferSheetOptions = actionReferSheetOptionsCollection[user.status] ? actionReferSheetOptionsCollection[user.status] : ['Refer and Invite to Boon', 'Invite only', 'Cancel'];
        const disabledIndexes = user.status === 'requested' ? [1] : [];
        let style = listElement.elementWrapperTopAligned;

        // if (contactId) {
        //     const activeContact = parseInt(contactId) === user.to_user_id;
        //     style = activeContact ? [listElement.elementWrapperTopAligned, listElement.activeListElement] : style;
        // }

        const initialValues = {
            name: `${user.first_name} ${user.last_name}`,
            email: user.email,
            phone_number: user.phone_number,
            user_id: user.id,
            organization_id: currentUserOrganization
        };
        let showLinkedIn = false
        let link = 'https://linkedin.com/'
        if (user.user_contact_infos && user.user_contact_infos.length == 1 && user.user_contact_infos[0] != undefined && user.user_contact_infos[0].linkedin != null) {
            showLinkedIn = true
            link = link.concat(`${user.user_contact_infos[0].linkedin}`)
        }
        else if (user.user_contact_infos && user.user_contact_infos.length > 1) {
            user.user_contact_infos.map((info) => {
                if (info.linkedin != null) {
                    showLinkedIn = true
                    link = link.concat(`${info.linkedin}`)
                }
            })
        }
        let isMobile = Math.round(Dimensions.get('window').width) < 750

        return (
            <WrapperElement
                key={`user_${user.id}`}
                style={[style, isMobile ? styles.mainWrapperMobile : styles.mainWrapper]}
                onPress={user.to_user_id ? () => Actions.contactProfile({ userId: user.to_user_id }) : null}
            >
                <View style={isMobile ? styles.contactContainerMobile : styles.contactContainer}>
                    <View style={listElement.imageWrapper}>
                        <Image style={[listElement.image, listElement.imageRounded]} source={image} resizeMode="contain" />
                        <Image style={[listElement.networkIcon, { borderRadius: 5 }]} source={defaultOrgAvatar} resizeMode="contain" />
                    </View>
                    <View style={styles.infoWrapper}>
                        <View style={{ flexDirection: 'row' }}>
                            {Boolean(showName) && (
                                <React.Fragment>
                                    {(user.first_name || user.last_name ) && user.source ? (
                                        <Text style={styles.title} numberOfLines={1}>{user.first_name} {user.last_name} ({user.source.split("_")[0]})</Text>
                                    ) : (
                                        <Text style={styles.title} numberOfLines={1}>{user.phone_number || user.email} </Text>
                                    )}
                                </React.Fragment>
                            )
                            }
                            {showLinkedIn &&
                                <TouchableOpacity onPress={() => Linking.openURL(link)}>
                                    <Image style={[{ marginLeft: 5, height: 17, width: 17 }, listElement.imageRounded]} source={linkedIn} resizeMode="contain" />
                                </TouchableOpacity>
                            }
                        </View>
                        <View>
                            {Boolean(user.user_contact_infos && user.user_contact_infos[0] != undefined) && <Text style={isMobile ? styles.descriptionMobile : styles.description} numberOfLines={1}>{user.user_contact_infos && user.user_contact_infos[0].location}</Text>}
                            <View style={{ flexDirection: 'row' }}>
                                {Boolean(user.user_contact_infos && user.user_contact_infos[0] != undefined) && <Text style={isMobile ? styles.descriptionMobile : styles.description} numberOfLines={1}>{user.user_contact_infos && user.user_contact_infos[0].job_title}</Text>}
                                {Boolean(user.user_contact_infos && user.user_contact_infos[0] != undefined && user.user_contact_infos && user.user_contact_infos[0].organization) && <Text style={[isMobile ? styles.descriptionMobile : styles.description, { textTransform: 'none' }]} numberOfLines={1}>&nbsp;at&nbsp;</Text>}
                                {Boolean(user.user_contact_infos && user.user_contact_infos[0] != undefined) && <Text style={isMobile ? [styles.descriptionMobile, { opacity: 0.7 }] : [styles.description, { opacity: 0.7 }]} numberOfLines={1}>{user.user_contact_infos && user.user_contact_infos[0].organization}</Text>}
                            </View>
                        </View>
                    </View>
                </View>

                {/* <View style={styles.employerContainer}>
                    <View style={listElement.imageWrapper}>
                        <Image style={[styles.orgImage]} source={orgImage} resizeMode="contain" />
                    </View>
                    <View style={styles.infoWrapper}>
                        {Boolean(user.user_contact_infos[0] != undefined) && <Text style={styles.companyPosition} numberOfLines={1}>{user.user_contact_infos[0].job_title}</Text>}
                        {Boolean(user.user_contact_infos[0] != undefined) && <Text style={styles.orgName} numberOfLines={1}>{user.user_contact_infos[0].organization}</Text>}
                    </View>
                </View> */}

                <View style={isMobile ? { display: 'none' } : styles.infoContainer}>
                    <View style={styles.informationWrapper}>
                        {Boolean(user.email) && <Text style={styles.contact} numberOfLines={1}>{user.email}</Text>}
                        {Boolean(user.phone_number) && <Text style={styles.contact} numberOfLines={1}>{user.phone_number}</Text>}
                    </View>
                </View>
                {isMobile ?
                    <TouchableOpacity
                        onPress={() => openReferDrawer(initialValues)}
                    >
                        <Image style={styles.shareIconMobile} source={shareIcon} resizeMode="contain" />
                    </TouchableOpacity>
                    :
                    <ListElementButton textStyle={{ color: '#0FBC71' }} style={styles.referButtonStyle} icon={shareIcon}
                        onPress={() => openReferDrawer(initialValues)}>
                        Refer
                </ListElementButton>
                }
                {/* <View style={listElement.imageWrapper}>
                    <Image style={[listElement.image, listElement.imageRounded]} source={image} resizeMode="contain"/>
                    {Boolean(user.status === 'connected') &&
                    <Image style={listElement.networkIcon} source={networkIcon} resizeMode="contain"/>
                    }
                </View>
                <View style={listElement.elementBody}>
                    <View style={listElement.infoWrapper}>
                        {Boolean(showName) &&
                        <Text style={listElement.title} numberOfLines={1}>{user.first_name} {user.last_name}</Text> ||
                        <Text style={listElement.title} numberOfLines={1}>{user.phone_number || user.email}</Text>
                        }
                        {Boolean(user.organization_name) && <Text style={listElement.description} numberOfLines={1}>{user.organization_name}</Text>}
                        {Boolean(user.company_position) && <Text style={listElement.description} numberOfLines={1}>{user.company_position}</Text>}
                    </View>
                    <TouchableOpacity
                        onPress={() => this.handleReferPress(user)}
                        style={styles.optionsButton}
                    >
                        <Image source={optionsIcon} style={styles.optionsIcon}/>
                    </TouchableOpacity>
                </View> */}
                <ActionSheet
                    ref={ref => this.actionSheetRefer = ref}
                    options={actionReferSheetOptions}
                    cancelButtonIndex={2}
                    disabledIndexes={disabledIndexes}
                    onPress={this.handleReferButtonPress}
                />
            </WrapperElement>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUserOrganization: getCurrentOrganizationForUser(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestConnection: connectionId => {
            dispatch(networkActions.requestConnection(connectionId));
        },
        inviteUser: (user) => {
            dispatch(networkActions.inviteUsers([user.id]));
        },
        getUserById: (userId) => {
            Actions.myContactDetail({ contactId: userId });
            dispatch(contactProfileActions.getUserById(userId));
        },
        openReferDrawer: initialValues => {
            dispatch(openReferFormDrawer(initialValues))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactListElement);
