import * as locationsActionsConstants from '../constants/actions/locationsActions';

const initialState = {
    countries: [{ label: 'Country', value: '' }],
};

const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case locationsActionsConstants.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countries: action.payload.map(countryArray => ({ label: countryArray[1], value: countryArray[0] }))
            };
        default:
            return state;
    }
};

export default locationsReducer;
