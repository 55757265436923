import { StyleSheet, PixelRatio, Platform } from 'react-native';

export default StyleSheet.create({
    header: {
        position: 'absolute',
        width: '100%',
        right: 0,
        zIndex: 1,
        height: 55,
        flex: 1,
        flexGrow: 0,
        backgroundColor: 'white',
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        marginTop: Platform.OS === 'web' ? 0 : 3,
    },
    topBanner: {
        flex: 1,
        flexGrow: 0,
        backgroundColor: '#FFDDDD',
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    topBannerHeader: {
        color: '#000',
        fontSize: 14,
        fontWeight: '500',
        flex: 1,
        alignItems: 'center',
    },
    mobileHeader: {
        flexBasis: 240 / PixelRatio.get(),
        paddingTop: 20,
    },
    notificationsButtonImage: {
        height: 20,
        width: 20
    },
    headerTitle: {
        color: '#0fbc71',
        fontSize: 16,
        fontWeight: 'bold',
        flex: 1
    },
    rightSide: {
        flex: 1,
        alignItems: 'flex-end',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingTop: 15,
    },
    leftSide: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        paddingRight: 8
    },
    children: {
        flexGrow: 1,
        flexShrink: 1,
        maxWidth: 1400,
        margin: '60px auto 0 auto',
    },
    skip: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold'
    },
    dashboardBase: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: "#fff",
        height: '100%'
    },
    dashBoardContent: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        // overflow: 'auto',
        height: "100%",
        backgroundColor: '#f7f7f9'
    },
    logoutButtonStyle: {
        marginLeft: '10px'
    },
    fullWidth: {
        width: "100%"
    },
    headCont: {
        gap: 30,
        paddingLeft: 5,
        paddingRight: 20,
    },
    actionBtn: {
        width: 80
    }
});
