import * as browseService from '../services/browseService';
import * as refersService from '../services/refersService';
import * as referActionsConstants from '../constants/actions/referActions';
import * as browseActionConstants from '../constants/actions/browseActions';
import * as appActionsConstants from '../constants/actions/appActions';
import { notification } from '../../src/helperFunctions';
import { closeDrawer } from '../../src/DrawerActions.js';
import Actions from '../../src/RouteActions';



export function getDeclineReasons() {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return refersService.declineReasons().then(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: referActionsConstants.REFER_REASONS_SUCCESS, payload: response.data.payload });
        });
    };
}

export function getReferUsers(job_id, job_uuid) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return refersService.referUsers(job_id, job_uuid).then(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: referActionsConstants.REFER_USERS_SUCCESS, payload: response.data.payload });
        }).catch((e) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        });
    };
}

export function resendReferralRequest(id) {
    return dispatch => {
        return refersService.resendReferralRequest(id).then(response => {
            notification.ref.show({ message: response.data.message });
        }).catch((response) => {
            notification.ref.show({ message: response.data.payload.error, isError: true });
        });
    };
}

export function submitDeclineReferral(data, setPayload = true) {
    return dispatch => {
        dispatch({ type: referActionsConstants.SET_REFER_FEEDBACK_STATUS, payload: false });
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return refersService.submitDeclineReferral(data).then(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            let dataCandidate = {
                userId: data.current_user,
                jobId: data.job_id
            }
            browseService.getCandidateData(dataCandidate).then(response => {
                dispatch({ type: browseActionConstants.GET_CANDIDATE_BROWSE_DATA_SUCCESS, payload: response.data.payload });
            })
            refersService.getReferDataByUserAndJob(dataCandidate).then((response) => {
                dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                dispatch({ type: referActionsConstants.GET_REFER_JOB_SUCCESS, payload: response.data.payload });
            })
            dispatch(closeDrawer());

            if (setPayload) {
                dispatch({ type: referActionsConstants.REFER_DECLINE_FORM_SUCCESS, payload: response.data.payload });
            } else {
                dispatch({ type: referActionsConstants.SET_REFER_FEEDBACK_STATUS, payload: true });
            }

            Actions.tracker();
        }).catch((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        });
    };
}

export function createUserAccount(data) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return refersService.createUserAccount(data).then(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            notification.ref.show({ message: response.data.payload });
        });
    };
}
