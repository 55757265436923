export const GET_BROWSE_ALL = 'GET_BROWSE_ALL';
export const GET_BROWSE_ALL_SUCCESS = 'GET_BROWSE_ALL_SUCCESS';
export const GET_BROWSE_ALL_ERROR = 'GET_BROWSE_ALL_ERROR';

export const GET_BROWSE_DETAILS = 'GET_BROWSE__DETAILS';
export const GET_BROWSE_DETAILS_SUCCESS = 'GET_BROWSE__DETAILS_SUCCESS';
export const GET_BROWSE_DETAILS_ERROR = 'GET_BROWSE__DETAILS_ERROR';

export const SORT_BROWSE = 'SORT_BROWSE';

export const GET_CANDIDATE_BROWSE_DATA = 'GET_CANDIDATE_BROWSE_DATA';
export const GET_CANDIDATE_BROWSE_DATA_SUCCESS = 'GET_CANDIDATE_BROWSE_DATA_SUCCESS';
export const GET_CANDIDATE_BROWSE_DATA_ERROR = 'GET_CANDIDATE_BROWSE_DATA_ERROR';
export const UPDATE_CANDIDATE_BROWSE_DATA = 'UPDATE_CANDIDATE_BROWSE_DATA';

export const SET_BROWSE_FILTERS = 'SET_BROWSE_FILTERS';
export const CLEAR_BROWSE_FILTERS = 'CLEAR_BROWSE_FILTERS';
