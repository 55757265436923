import { StyleSheet } from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    contactsList: {
        maxHeight: '80%',
    },
    wrapper: {
        flex: 1
    },
    searchBlock: {
        padding: 10,
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 2,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 4
    },
    searchBlockBack: {
        marginRight: 24,
        marginLeft: -16,
        flexGrow: 0
    },
    searchBlockInputWrapper: {
        position: 'relative',
        flexGrow: 1
    },
    searchBlockInput: {
        fontSize: 16,
        fontFamily: 'Roboto-Regular',
        paddingLeft: 15,
        width: '100%'
    },
    searchBlockClearButton: {
        position: 'absolute',
        top: 5,
        right: 0
    },
    searchBlockClearButtonImage: {
        height: 16,
        width: 16
    },
    resultsWrapper: {
        shadowRadius: 1,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        flexShrink: 1
    },
    spinner: {
        marginTop: 16
    },
    emptyListWrapper: {
        alignItems: 'center',
        paddingTop: 30,
        paddingBottom: 30,
        backgroundColor: '#FFF',
    },
    emptyListText: {
        color: '#444444',
        fontSize: 13
    },
    suggestHeader: {
        color: '#000',
        fontSize: 15,
        paddingTop: 16,
        paddingBottom: 16
    },
    simpleStyles: {
        paddingBottom: 2,
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 2,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottomColor: '#aeaeae',
        borderBottomWidth: 1
    },
    referStyles: {
        paddingBottom: 15,
        paddingRight: 15,
        paddingTop: 15,
        position: 'relative',
        zIndex: 2,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#CCCCCC',
        backgroundColor: '#FFFFFF',
        maxHeight: 40
    },
    searchBlockInputSimple: {
        fontSize: 16,
        fontFamily: 'Roboto-Regular',
        paddingBottom: 5,
        width: '100%'
    },
    largeFontSizeInput: {
        fontSize: 19,
        fontFamily: 'Roboto-Regular',
        paddingLeft: 15,
        width: '100%'
    },
});
