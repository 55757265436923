import React, { useEffect, useState, useMemo, useRef } from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withGetScreen } from 'react-native-getscreen';
import { isEmpty } from 'lodash-es';
import { View, Platform, ScrollView, TouchableOpacity, Image } from 'react-native';
import * as authActionsConstants from '../../constants/actions/auth';

import { Drawer } from '@ant-design/react-native';
import styles from './styles';
import notificationsIcon from '../../assets/img/notifications_icon.png';
import settingsIconGrey from '../../assets/img/settings_icon_grey.png';
import history from '../../RouteHistory';
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
import { common as commonStyles, drawerElement } from '../../generalStyles';

let Pusher;
Pusher = Platform.OS === 'web' && require('pusher-js');

//components
import Text from '../Text/Text';
import BackButton from '../BackButton/BackButton';
import Actions from '../../RouteActions';
import * as stepsActions from '../../globalActions/stepsActions.js';
import * as actions from '../../screens/Refer/actions';
import { closeDrawer, openNotificationsDrawer, openSettingsDrawer } from '../../DrawerActions.js';
import { formLayout, dashboardButton } from '../../generalStyles';

import TabBarComponent from '../TabBarComponent/TabBarComponent';
import SideBar from '../SideBar/SideBar';
import { drawerComponents } from '../../drawerComponents.js';
import { notification } from '../../helperFunctions';
import ListElementButton from '../ListElementButton/ListElementButton';

//actions
import * as notificationsActions from '../../screens/Notifications/actions';
import { setAccessTokenRedirect } from '../../actions/authActions.js';
import * as settingActions from '../../screens/Settings/actions';
import baseService from '../../services/baseService';
import { getPusherCluster, getPusherKey } from '../../services/pusherSecretsService';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import Spinner from 'react-native-loading-spinner-overlay';
import { getOrganizationDetailsByGeneralSettingId } from '../../services/organizationsService.js';

const DashboardBase = (props) => {
  const {
    children,
    currentUser,
    notify,
    isProtectedRoute = false,
    isMobile,
    isTablet,
    title,
    leftHeader,
    hasBackButton,
    currentVisibility,
    drawerComponentName,
    previousVisibility,
    renderLeftBlock,
    renderRightBlock,
    history,
    drawerPosition,
    drawerInitialProps,
    drawerBackgroundColor,
    flexBasis,
    width,
    unreadNotifications,
    getNotificationsList,
    onBackButtonPress,
    openNotificationDrawer,
    closeDrawer,
    logout,
    organization,
    getCurrentUserStep,
    onGetAccessToken,
    step,
    isRedirectUserToAnotherUrl,
    setIsRedirectUserToAnotherUrlFalse,
  } = props;
  const [shouldChangeOrg, setShouldChangeOrg] = useState(() => {
    const query = new URLSearchParams(window.location.search);

    return query.has('orgUuid');
  });
  const [showSideBar, setShowSideBar] = useState(undefined);
  const web = Platform.OS === 'web';
  const tabScreen = !web || isMobile() || isTablet();
  const headerStyle = !web ? styles.mobileHeader : null;
  const path = history.location.pathname;
  const isEmptyCurrentUser = isEmpty(currentUser);
  const drawerRef = useRef(null);
  const [dynamicOrgData, setDynamicOrgData] = useState(null);

  useEffect(() => {
    const env = process.env.REACT_APP_ENVIRONMENT || 'development';
    const token = Cookies.get(`X-User-Token-${env}`, {
      domain: env === 'development' ? 'localhost' : '.goboon.co',
    });
    if (!token && Object.entries(currentUser).length) {
      onGetAccessToken();
    }
  }, []);
  useEffect(() => {
    if (isRedirectUserToAnotherUrl) {
      setTimeout(() => {
        setIsRedirectUserToAnotherUrlFalse();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (step !== 'home' && !isEmptyCurrentUser) {
      getCurrentUserStep();
    }
    if (Platform.OS === 'web') {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        Pusher.logToConsole = false;
      }
      const key = getPusherKey();
      const cluster = getPusherCluster();
      const pusher = new Pusher(key, {
        cluster: cluster,
        forceTLS: true,
      });
      const channel = pusher.subscribe('bell_notification');
      channel.bind('trigger_get_bell_notification', function (data) {
        if (currentUser && currentUser.id === data['user_id']) {
          notification.ref.show({
            message: 'You have a new notification',
          });
          getNotificationsList();
        }
      });
    }
  }, []);

  useEffect(() => {
    if (notify) {
      openNotificationDrawer();
    }
  }, [notify]);

  useEffect(() => {
    if (!isProtectedRoute) return;
    if (isEmptyCurrentUser) {
      Actions.base();
    }
  }, [isEmptyCurrentUser, isProtectedRoute]);

  useEffect(() => {
    if (sessionStorage.getItem('hideSidebar') === 'true') {
      setShowSideBar(true);
    } else {
      setShowSideBar(false);
    }
  }, [showSideBar]);

  const onDrawerChange = (open) => {
    if (!open) {
      closeDrawer();
    }
  };

  const removeSsoAuthFromLocalStorage = () => {
    localStorage.removeItem('LOGGED_IN_VIA_GOOGLE_SSO');
    logout();
  };

  const renderHeaderButtons = useMemo(() => {
    const isGoogleSSOLogin = localStorage.getItem('LOGGED_IN_VIA_GOOGLE_SSO');
    const tabScreen = isMobile() || isTablet();
    if (isEmptyCurrentUser) {
      return (
        <View style={[dashboardButton.buttonWrapper, formLayout.row, { width: tabScreen ? 170 : 'unset' }]}>
          <ListElementButton textStyle={{ color: '#0FBC71' }} onPress={() => Actions.signUp()} style={styles.actionBtn}>
            Sign Up
          </ListElementButton>
          <ListElementButton
            textStyle={{ color: '#0FBC71' }}
            style={[styles.logoutButtonStyle, { borderColor: '#0FBC71' }, styles.actionBtn]}
            onPress={() => Actions.login()}
          >
            Log In
          </ListElementButton>
        </View>
      );
    }
    if (!path || path.indexOf('/my') !== 0) return null;
    if (path.includes('tracker')) return null;
    return (
      <View style={[dashboardButton.buttonWrapper, formLayout.row, { width: tabScreen ? 190 : 'unset' }]}>
        <ListElementButton textStyle={{ color: '#9B9B9B' }} onPress={() => Actions.myBoon()}>
          View Profile
        </ListElementButton>
        <ListElementButton textStyle={{ color: '#E88268' }} style={styles.logoutButtonStyle} onPress={() => logout()}>
          Logout
        </ListElementButton>
      </View>
    );
  }, [isEmptyCurrentUser, isMobile, isTablet, path]);

  const renderNotificationAndSettingIcon = useMemo(() => {
    if (isEmptyCurrentUser) return null;
    return (
      <View style={(dashboardButton.buttonWrapper, formLayout.row)}>
        <TouchableOpacity onPress={() => openNotificationDrawer()} style={dashboardButton.buttonRight}>
          <Image
            source={notificationsIcon}
            resizeMode="contain"
            style={[dashboardButton.image, styles.notificationsButtonImage]}
          />
        </TouchableOpacity>
        {Boolean(unreadNotifications) && (
          <View style={dashboardButton.unreadCountWrapper}>
            <Text style={dashboardButton.unreadCount}>{unreadNotifications}</Text>
          </View>
        )}

        {/* <TouchableOpacity
              onPress={() => openSettingsDrawer()}
              style={dashboardButton.buttonRight}>
              <Image
                source={settingsIconGrey}
                resizeMode="contain"
                style={[dashboardButton.image, styles.notificationsButtonImage]} />
          </TouchableOpacity> */}
      </View>
    );
  }, [isEmptyCurrentUser, unreadNotifications, openNotificationDrawer]);

  const getDynamicOrgData = async (dynamicOrg) => {
    try {
      const {
        data: { payload },
      } = await getOrganizationDetailsByGeneralSettingId(dynamicOrg);
      console.log(payload);
      return payload;
    } catch (error) {
      console.log('error', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sessionStorage.getItem('dynamicOrg')) {
        const res = await getDynamicOrgData(sessionStorage.getItem('dynamicOrg'));
        setDynamicOrgData(res);
      }
    };

    fetchData();
  }, []);

  const renderLeftHeader = useMemo(() => {
    if (dynamicOrgData) {
      const avatar =
        dynamicOrgData && dynamicOrgData.organization && dynamicOrgData.organization.logo_image_thumb
          ? { uri: dynamicOrgData.organization.logo_image_thumb }
          : defaultOrgAvatar;

      return (
        <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.fullWidth]}>
          <Image source={avatar} style={{ width: 30, height: 30, marginRight: 12 }} resizeMethod="contain" />
          <Text
            style={{
              color: '#9B9B9B',
              fontSize: 14,
              fontWeight: '500',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {(dynamicOrgData && dynamicOrgData.organization && dynamicOrgData.organization.name) || ''}
          </Text>
        </View>
      );
    } else if (isEmpty(currentUser)) {
      const avatar = organization.logo_image_thumb ? { uri: organization.logo_image_thumb } : defaultOrgAvatar;
      return (
        <View style={[commonStyles.row, commonStyles.alignItemsCenter, styles.fullWidth]}>
          <Image source={avatar} style={{ width: 30, height: 30, marginRight: 12 }} resizeMethod="contain" />
          <Text
            style={{
              color: '#9B9B9B',
              fontSize: 14,
              fontWeight: '500',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {organization.name}
          </Text>
        </View>
      );
    }
    return null;
  }, [currentUser, organization, getDynamicOrgData]);

  const renderSidebar = useMemo(() => {
    if (drawerComponentName) {
      return <ScrollView>{drawerComponents[drawerComponentName](drawerInitialProps)}</ScrollView>;
    }
    return <ScrollView></ScrollView>;
  }, [drawerComponentName]);

  if (isProtectedRoute && isEmptyCurrentUser) {
    return null;
  }

  const setLeftHeader = leftHeader ? leftHeader : renderLeftHeader;

  if (isRedirectUserToAnotherUrl)
    return (
      <AuthLayout>
        <Spinner visible />
      </AuthLayout>
    );
  return (
    <Drawer
      sidebar={renderSidebar}
      open={currentVisibility}
      position={drawerPosition}
      drawerRef={(el) => (drawerRef.current = el)}
      onOpenChange={onDrawerChange}
      drawerBackgroundColor={drawerBackgroundColor}
      drawerWidth={width}
    >
      <View style={[styles.dashboardBase, web && tabScreen && { marginBottom: '58px' }]}>
        {!tabScreen && !isEmptyCurrentUser && !showSideBar && (
          <SideBar currentPath={history.location.pathname} setShouldChangeOrg={setShouldChangeOrg} />
        )}

        {!shouldChangeOrg && (
          <View style={styles.dashBoardContent}>
            <View style={[styles.header, headerStyle, tabScreen && { marginVertical: 5 }, styles.headCont]}>
              <View style={styles.leftSide}>
                {hasBackButton && <BackButton grey onPress={onBackButtonPress} style={{ marginLeft: 0 }} />}
                {renderLeftBlock}
                <Text style={styles.headerTitle} numberOfLines={1}>
                  {showSideBar ? renderLeftHeader : path.includes('home') ? 'Dashboard' : setLeftHeader || title}
                </Text>
              </View>
              <View style={styles.rightSide}>
                {renderRightBlock}
                <View style={{ flexDirection: 'row' }}>
                  {renderHeaderButtons}
                  {renderNotificationAndSettingIcon}
                </View>
              </View>
            </View>
            <div
              style={{
                flexGrow: 1,
                flexShrink: 1,
                width: '100%',
                maxWidth: 1400,
                margin: '60px auto 0 auto',
                overflow: 'auto',
              }}
            >
              {children}
            </div>
            {tabScreen && <TabBarComponent currentPath={history.location.pathname} />}
          </View>
        )}
      </View>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    notify: state.appReducer.notify,
    currentUser: state.authReducer.currentUser,
    isRedirectUserToAnotherUrl: state.authReducer.isRedirectUserToAnotherUrl,
    currentVisibility: state.drawerReducer.currentVisibility,
    drawerComponentName: state.drawerReducer.name,
    drawerPosition: state.drawerReducer.position,
    drawerInitialProps: state.drawerReducer.initialValues,
    drawerBackgroundColor: state.drawerReducer.backgroundColor,
    unreadNotifications: state.notificationsReducer.unreadNotifications,
    width: state.drawerReducer.width,
    organization: state.organizationsReducer.organizationDetails,
    step: state.stepsReducer.step,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCurrentUserStep: () => {
      dispatch(stepsActions.getCurrentStep());
    },
    getNotificationsList: (params) => {
      dispatch(notificationsActions.getNotificationsList(params));
    },
    closeDrawer: () => {
      dispatch(closeDrawer());
      dispatch(actions.referContinue());
    },
    openNotificationDrawer: () => dispatch(openNotificationsDrawer()),
    openSettingsDrawer: () => dispatch(openSettingsDrawer()),
    logout: () => {
      dispatch(settingActions.logout());
      baseService.removeAuthToken();
    },
    onGetAccessToken: () => {
      setAccessTokenRedirect();
    },
    setIsRedirectUserToAnotherUrlFalse: () => {
      dispatch({
        type: authActionsConstants.SET_REDIRECT_URL_LOADER_FALSE,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(withRouter(DashboardBase)));
