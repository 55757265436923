import * as authActionsConstants from '../../../../constants/actions/auth';
import * as authService from '../../../../services/authService';

export function getCurrentUserStats() {
    return dispatch => {
        dispatch({type: authActionsConstants.GET_CURRENT_USER_STATS});
        authService.getCurrentUserStats().then(response => {
            dispatch({type: authActionsConstants.GET_CURRENT_USER_STATS_SUCCESS, payload: response.data.payload.stats});
            if (response.data.payload.stats.subscriptions.google) {
                dispatch({ type: authActionsConstants.SUBSCRIBE_GOOGLE });
            } else {
                dispatch({ type: authActionsConstants.UNSUBSCRIBE_GOOGLE_SUCCESS });
            }

            if(response.data.payload.stats.subscriptions.linkedin) {
                dispatch({ type: authActionsConstants.SUBSCRIBE_LINKEDIN });
            } else {
                dispatch({ type: authActionsConstants.UNSUBSCRIBE_LINKEDIN });
            }

            if(response.data.payload.stats.subscriptions.outlook) {
                dispatch({ type: authActionsConstants.SUBSCRIBE_OUTLOOK });
            } else {
                dispatch({ type: authActionsConstants.UNSUBSCRIBE_OUTLOOK });
            }

            if(response.data.payload.stats.subscriptions.facebook) {
                dispatch({ type: authActionsConstants.SUBSCRIBE_FACEBOOK });
            } else {
                dispatch({ type: authActionsConstants.UNSUBSCRIBE_FACEBOOK });
            }

            if(response.data.payload.stats.subscriptions.twitter) {
                dispatch({ type: authActionsConstants.SUBSCRIBE_TWITTER });
            } else {
                dispatch({ type: authActionsConstants.UNSUBSCRIBE_TWITTER });
            }
        }).catch(() => {
            dispatch({type: authActionsConstants.GET_CURRENT_USER_STATS_ERROR});
        });
    };
}
