import { StyleSheet } from 'react-native';
import { $grey2, $green } from '../../generalStyles';

export default StyleSheet.create({
    newWrapperStyles: {
        marginBottom: 7,
        borderRadius: 5,
        marginTop: 5
    },
    userNameStyle: {
        fontWeight: 'bold'
    },
    byStyles: {
        fontWeight: 'normal'
    },
    countStyles: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: 14,
        color: $green
    },
    countWrapper: {
        alignItems: 'center',
        width: 20,
        height: 20,
        borderRadius: 8,
        borderColor: $grey2,
        borderWidth: 1
    },
    bodyStyles: {
        width: 180,
        paddingRight: 5,
        minHeight: 100
    }
});
