import React, { Component } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import Actions from '../../RouteActions';
import moment from 'moment';

//styles
import { listElement } from '../../generalStyles';
import styles from './styles';

//components
import Text from '../../components/Text/Text';
import ArchivedStatusLabel from '../../screens/Tracker/components/ArchivedStatusLabel/ArchivedStatusLabel';
import InterviewStatusLabel from '../../screens/Tracker/components/InterviewStatusLabel/InterviewStatusLabel';
import IneligibleStatusLabel from '../../screens/Tracker/components/IneligibleStatusLabel/IneligibleStatusLabel';

//utils

//images
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';

//constants
import archivedStatuses from '../../constants/archivedStatuses';
import responsiveStyles from '../../responsiveStyles';

class MyOpportunityElement extends Component {
    render() {
        const { opportunity, currentUser, status } = this.props;
        const image = opportunity.logo_image_url ? { uri: opportunity.logo_image_url } : defaultOrgAvatar;
        const firstReferral = opportunity.referrals[0];
        if(!firstReferral) {
            return null;
        }

        const wrapperStyles = [listElement.elementWrapper, styles.newWrapperStyles];

        let avatar = defaultUserAvatar;
        let daysLeft = null;

        const detailsInfo = {
            jobId: opportunity.uuid,
            direction: 'my'
        };
        let referralsLength;
        if (firstReferral) {
            if (firstReferral.avatar_thumb) {
                avatar = { url: firstReferral.avatar_thumb }
            }

            referralsLength = opportunity.referrals.length - 1
            daysLeft = moment(firstReferral.referral_expires_at).fromNow(true);

            if (firstReferral.candidate_status === 'offer') {
                wrapperStyles.push({ backgroundColor: '#f4db96' });
            }

            detailsInfo.candidateStatus = firstReferral.candidate_status;
        }
        return (
            <TouchableOpacity
                style={wrapperStyles}
                onPress={() => Actions.opportunityDetails(detailsInfo)}
            >
                <View style={listElement.imageWrapper}>
                    <Image style={[listElement.image, { paddingLeft: 0 }]} source={image} resizeMode="contain" />
                </View>
                <View style={[listElement.elementBody, styles.bodyStyles]}>
                    <View style={listElement.infoWrapper}>
                        {(!opportunity.user_reward_eligibility && status == 'archived')}
                        <Text style={[listElement.title, listElement.bold, {paddingTop: 3}]} numberOfLines={1}>{opportunity.title}</Text>
                        <Text style={listElement.description} numberOfLines={1}>{opportunity.organization_name}</Text>
                        <Text style={[listElement.description, styles.userNameStyle, responsiveStyles.justifyFlexStart]} numberOfLines={1}>
                            <Image style={listElement.subSmallImage} source={avatar} />
                            <Text style={styles.byStyles}>By </Text>
                            {firstReferral.id === currentUser.id ? 'Applied without provider' : firstReferral.full_name}
                        </Text>
                        <Text style={[listElement.description, styles.userNameStyle, responsiveStyles.justifyFlexStart, styles.byStyles]}>
                            {(referralsLength) ? `      + ${referralsLength} others.` : ''}
                        </Text>
                        {(archivedStatuses.indexOf(firstReferral.candidate_status) !== -1) && <ArchivedStatusLabel opportunity={opportunity} direction="my" />}
                        {(firstReferral.candidate_status.includes('interview')) && <InterviewStatusLabel opportunity={opportunity} direction="my" />}
                        { firstReferral.id != currentUser.id &&
                            <View style={styles.countWrapper}>
                                <Text style={styles.countStyles}>
                                    {opportunity.referrals.length}
                                </Text>
                            </View>
                        }
                    </View>
                    {(firstReferral.candidate_status === 'new') &&
                        <View style={listElement.rightBlock}>
                            <View style={[listElement.timestampWrapper, { paddingBottom: 60 }]}>
                                <Text style={listElement.timestampRed}>{daysLeft} left</Text>
                            </View>
                        </View>
                    }
                </View>
            </TouchableOpacity>
        );
    }
}

export default MyOpportunityElement;
