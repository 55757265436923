import * as refersActionsConstants from '../constants/actions/referActions';
import * as trackerActionsConstants from '../constants/actions/trackerActions';

const initialState = {
  referFormSubmitting: false,
  referFormContinue: false,
  referredJobData: {},
  declineReasons: [],
  referUsers: [],
  userPDFs: [],
  userProfileLinks: [],
  thankYouForm: false,
  referrerExists: null,
  referInviteSent: null,
  requestCompleted: true,
  feedbackCompleted: true,
  userRefererProvider: null,
  linkedinSkills: {},
};

const refersReducer = (state = initialState, action) => {
  switch (action.type) {
    case refersActionsConstants.REFER:
      return {
        ...state,
        referFormSubmitting: true,
      };
    case refersActionsConstants.REFER_SUCCESS:
      return {
        ...state,
        referFormSubmitting: false,
        referFormContinue: true,
        linkedinSkills: action.payload,
      };
    case refersActionsConstants.REFER_REASONS_SUCCESS:
      return {
        ...state,
        declineReasons: action.payload.map((reasonsArray) => ({ label: reasonsArray[1], value: reasonsArray[0] })),
      };
    case refersActionsConstants.REFER_USERS_SUCCESS:
      return {
        ...state,
        referUsers: action.payload.users.map((usersArray) => ({
          label: usersArray.full_name,
          value: usersArray.id,
          email: usersArray.email,
        })),
        userPDFs: action.payload.user_resume,
        userProfileLinks: action.payload.link_urls,
      };
    case refersActionsConstants.REFER_USERS_ERROR:
      return {
        ...state,
        referUsers: [],
      };
    case refersActionsConstants.REFER_REASONS_ERROR:
      return {
        ...state,
        declineReasons: [],
      };
    case refersActionsConstants.REFER_ERROR:
      return {
        ...state,
        referFormSubmitting: false,
        referFormContinue: false,
      };
    case refersActionsConstants.REFER_CLOSE:
      return {
        ...state,
        referFormSubmitting: false,
        referFormContinue: false,
      };
    case refersActionsConstants.REFER_AGAIN:
      return {
        ...state,
        referFormContinue: false,
      };
    case refersActionsConstants.GET_REFER_JOB_SUCCESS:
      return {
        ...state,
        referredJobData: action.payload,
      };
    case refersActionsConstants.REFER_DECLINE_FORM_SUCCESS:
      return {
        ...state,
        thankYouForm: true,
      };
    case refersActionsConstants.GET_REFER_JOB_ERROR:
      return {
        ...state,
        referredJobData: {},
      };
    case refersActionsConstants.SET_REFERRER_STATUS:
      return {
        ...state,
        referrerExists: action.payload,
        referInviteSent: action.payload,
        requestCompleted: true,
      };
    case refersActionsConstants.SET_REFER_INVITE_STATUS:
      return {
        ...state,
        referInviteSent: action.payload,
      };
    case refersActionsConstants.SET_API_CALL_STATUS:
      return {
        ...state,
        requestCompleted: action.payload,
      };
    case refersActionsConstants.SET_REFER_FEEDBACK_STATUS:
      return {
        ...state,
        feedbackCompleted: action.payload,
      };
    case trackerActionsConstants.HIDE_PDF_FROM_USER_SUCCES:
      const newPdfsArray = state.userPDFs.filter((pdf) => pdf.id !== action.payload);
      return {
        ...state,
        userPDFs: newPdfsArray,
      };
    case refersActionsConstants.SET_USER_REFERER:
      return {
        ...state,
        userRefererProvider: action.payload,
      };

    default:
      return state;
  }
};

export default refersReducer;
