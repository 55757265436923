// ###### WARNING ######
// DON'T UPDATE LINE:13 export default apiUrls['development']
// As it is used for deployment. In case of change in line number
// update packages/web/package.json build script build-staging and build-prod with new line number
// ###### WARNING ######

const apiUrls = {
  development: process.env.REACT_APP_API_URL || "http://localhost:3000",
  staging: "https://api-staging.goboon.co",
  production: "https://api.goboon.co",
  demo: "https://api-demo.goboon.co/",
};

const environment = process.env.REACT_APP_ENVIRONMENT || "development";

export default apiUrls[environment] || apiUrls["development"];
