import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FlatList, View, Dimensions, Image } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import * as DOMPurify from 'dompurify';

//components
import Text from '../../../../components/Text/Text';

//actions
import * as termsAndConditionsActions from './actions';
import * as statsActions from '../Stats/actions';

import ringCentralPromoImg from '../../../../assets/img/ring_central_promo.jpg';

//styles
import styles from './styles';

const RewardTermsAndConditions = (props) => {
  const { currentSelectedOrg, getTermsAndConditions, termsAndConditions, showLeaderBoard } = props;
  const showRingCentralDefault =
    currentSelectedOrg.name == 'RingCentral' && !termsAndConditions.referral_program_image;
  useEffect(() => {
    if (currentSelectedOrg && currentSelectedOrg.id) {
      getTermsAndConditions(currentSelectedOrg.id);
    }
  }, [currentSelectedOrg]);

  const tabScreen = Dimensions.get('window').width < 750;

  return (
    <>
      {termsAndConditions &&
        (!!termsAndConditions.referral_program_details ||
          !!termsAndConditions.referral_program_image ||
          showRingCentralDefault) && (
          <View style={styles.root}>
            <View>
              {!tabScreen ? (
                <View>
                  <Text style={styles.title}>Referral Program Details</Text>
                </View>
              ) : (
                <View />
              )}
              <View>
                <Text
                  id="ql-editor"
                  style={styles.container}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(termsAndConditions.referral_program_details),
                  }}
                />
                {(termsAndConditions.referral_program_image || showRingCentralDefault) && (
                  <View style={styles.promoImageContainer}>
                    <Image
                      style={styles.promoImage}
                      source={showRingCentralDefault ? ringCentralPromoImg : termsAndConditions.referral_program_image}
                    />
                  </View>
                )}
              </View>
            </View>
          </View>
        )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedOrganization: state.activityReducer.selectedOrganization,
    currentUserId: state.authReducer.currentUser.id,
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
    termsAndConditions: state.activityReducer.termsAndConditions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTermsAndConditions: (organizationId) => {
      dispatch(statsActions.getCurrentUserStats());
      dispatch(termsAndConditionsActions.getTermsAndConditions(organizationId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(RewardTermsAndConditions));
