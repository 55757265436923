import { StyleSheet } from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    screenWrapper: {
        padding: 8
    },
    permissionWrapper: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        marginBottom: 10,
        borderRadius: 5,
        alignItems: 'center'
    },
    permissionWrapperDisabled: {
        opacity: 0.4
    },
    permissionIcon: {
        height: 30,
        width: 30,
        marginRight: 15
    },
    permissionTitle: {
        color: $green,
        marginBottom: 3,
        fontSize: 14,
        fontWeight: 'bold'
    },
    permissionDescription: {
        fontSize: 12
    },
    col: {
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    linkedinImportButton: {
        color: '#0FBC71',
        backgroundColor: '#F5F7F9',
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        width: 'fit-content',
        padding: 10
    },
    checkLinkedInbutton: {
        backgroundColor: '#0E76A8',
        color: '#fff',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
        width: 'fit-content',
    },
    checkLinkedInbuttonText: {
        color: '#fff',
    },
    linkedInText: {
        fontSize: 10,
    },
    image: {
        width: 60,
        height: 60,
        marginRight: 12,
        borderRadius: 5,
        border: '1px solid #D1D8DC'
    },
    title: {
        fontSize: 15,
        fontWeight: 'bold',
    },
    description: {
        fontSize: 14,
        lineHeight: 20,
        opacity: 0.6,
        color: '#303030'
    },
    textRight: {
        width: 'fit-content',
        float: 'right'
    },
    textLeft: {
        width: 'fit-content',
        float: 'left'
    },
    leftAlign: {
        display: 'inline',
        width: 'fit-content',
        float: 'left'
    },
    linkedinTags: {
        backgroundColor: '#EAEAEA',
        width: 'fit-content',
        padding: 5,
        display: 'inline',
        marginLeft: 5,
        borderRadius: 5
    }
});
