import EStyleSheet from 'react-native-extended-stylesheet';
import { $green, $background, $greenish } from '../../generalStyles';


export default EStyleSheet.create({
    basicInfo: {
        flexWrap: 'wrap',
        display: 'flex',
        width: '100%',
        paddingTop: 20,
        paddingHorizontal: 10,
        paddingBottom: 15,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    userAvatar: {
        height: 66,
        width: 66,
        borderRadius: 33
    },
    userAvatarMobile: {
        height: 40,
        width: 40,
        borderRadius: 15
    },
    userInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 20,
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 20,
    },
    nameWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    name: {
        fontSize: 24,
        fontWeight: 'bold',
    },
    nameMobile: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    editProfileButton: {
        padding: 5
    },
    editIcon: {
        height: 16,
        width: 16,
        borderRadius: 5,
        backgroundColor: $greenish
    },
    organization: {
        fontSize: 12,
        fontWeight: 'bold'
    },
    position: {
        fontSize: 12,
        opacity: 0.8,
        marginTop: 4
    },
    stats: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginVertical: 10,
        width: '100%'
    },
    widthFitContent: {
        width: 'fit-content'
    },
    statBox: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingHorizontal: 10,
        marginBottom: 10,
    },
    statTitle: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    statValue: {
        color: $green,
        fontSize: 22,
        marginBottom: 5,
        fontWeight: 'bold',
    },
    buttonsWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    listButton: {
        backgroundColor: '#fff',
        height: 35
    },
    listButtonDisabled: {
        backgroundColor: '#b8bdc4',
        height: 35
    },
    listButtonDisabledText: {
        color: 'rgba(255,255,255,0.6)',
    },
    additionalInfoWrapper: {
        padding: 20,
        display: 'flex',
        flexDirection: 'column'
    },
    whiteBlock: {
        marginHorizontal: 30,
        marginVertical: 25,
        borderBottomWidth: 0.5,
        paddingBottom: 25,
        borderColor: $background
    },
    whiteBlockButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginHorizontal: 30,
        paddingVertical: 10
    },
    bioWrapper: {
        marginBottom: 16
    },
    emptyBioWrapper: {
        flexDirection: 'row'
    },
    bioTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#343A4E',
        marginBottom: 8
    },
    bioText: {
        fontSize: 12,
        color: '#000',
        opacity: 0.6
    },
    bioLink: {
        fontSize: 12,
        color: $green,
        marginLeft: 3
    },
    buttonText: {
        color: $green,
        fontSize: 14,
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 10,
    },
    linkIcon: {
        height: 20,
        width: 20
    },
    about: {
        paddingVertical: 20,
        fontSize: 22,
        borderTopWidth: 0.5,
        borderColor: $background
    }
});
