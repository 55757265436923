import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    View,
    ImageBackground,
    Platform
} from 'react-native';
import CSVReader from 'react-csv-reader';
import { withGetScreen } from 'react-native-getscreen';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { notification } from '../../helperFunctions';

//components
import Text from '../../components/Text/Text';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';

//images
import dragDropFileIcon from '../../assets/img/drag_and_drop_file_icon.svg';

//styles
import styles from './styles';
import { listElement } from '../../generalStyles';

//actions
import { closeDrawer, openConnectContactsDrawer } from '../../DrawerActions.js';
import * as actions from './actions';



class ConnectLinkedin extends Component {
    handleReDownloadPress = (e) => {
        e.preventDefault();
        this.props.openConnectContactsDrawerWithCountDown();
        window.open('https://www.linkedin.com/psettings/member-data');
    }

    parseOptions = {
        dynamicTyping: true,
        skipEmptyLines: true,
    };

    handleCSVData = (data, fileInfo, file) => {
        if (data.length === 0) {
            notification.ref.show({ message: 'Given file is empty', isError: true });
        }
        else if (fileInfo.type === 'text/csv') {
            if (data[0][0] == "Notes:") {
                data = data.slice(2);
            }
            let requestData = {
                csvData: data,
                selectedFirstNameVal: 'First Name',
                selectedLastNameVal: 'Last Name',
                selectedEmailVal: 'Email Address',
                source: 'linkedin',
            };
            this.props.importFields(requestData);
        }
        else {
            notification.ref.show({ message: 'Please upload files with .csv extension, only CSV files are allowed.', isError: true });
        }
    };

    render() {
        const web = Platform.OS === 'web';
        const { minutesElapsed } = this.props;

        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Import your LinkedIn file' />
                <View style={{ ...listElement.groupWrapper, width: '80%', alignSelf: 'center' }}>
                    <View style={styles.elementWrapper}>
                        <Text style={styles.text}>Drag and drop the LinkedIn .zip file and Boon will add your network.</Text>
                    </View>
                    <View style={styles.imageWrapper}>
                        <ImageBackground style={styles.image} source={dragDropFileIcon}>
                            <CSVReader
                                onFileLoaded={this.handleCSVData}
                                parserOptions={this.parseOptions}
                                inputStyle={{ height: 180, opacity: 0 }}
                            />
                        </ImageBackground>
                    </View>
                    <View style={[styles.elementWrapper, { alignSelf: 'center' }]}>
                        {/* <BrowserLinkText
                            url='https://www.linkedin.com/psettings/member-data'
                            openConnectContactsDrawer={this.props.openConnectContactsDrawerWithCountDown}
                            >
                            <View style={styles.button}>
                                <Text style={styles.buttonText}>Go to LinkedIn</Text>
                            </View>
                        </BrowserLinkText> */}
                        {(minutesElapsed !== null && minutesElapsed >= 0 && minutesElapsed < 20 && 
                            <Text>
                                You can re-download your file after {20 - minutesElapsed} minutes
                            </Text>) ||
                            (<Text>
                                You can <TouchableOpacity onPress={this.handleReDownloadPress}>
                                <Text style={styles.link}>re-download your file from LinkedIn</Text>
                                </TouchableOpacity>
                            </Text>)
                        }   
                    </View>

                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        minutesElapsed: state.drawerReducer.minutesElapsed
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
        openConnectContactsDrawerWithCountDown: () => {
            dispatch(openConnectContactsDrawer(true));
        },
        importFields: (data) => {
            dispatch(actions.importFields(data));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ConnectLinkedin));
