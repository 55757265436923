import { AppRegistry } from 'react-native'

import AppWeb from './AppWeb'
import ReactGA from 'react-ga4';

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
}

AppRegistry.registerComponent('BoonApp(Web)', () => AppWeb)
AppRegistry.runApplication('BoonApp(Web)', {
  rootTag: document.getElementById('root'),
})
