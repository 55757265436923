import { StyleSheet, PixelRatio } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  root: {
    paddingTop: 16,
    paddingHorizontal: 12,
    width: "100%",
    maxWidth: 985,
    margin: "auto",
  },
  leftSide: {
    flex: 1,
  },
  rightSide: {
    width: 375,
  },
  filterButtonImage: {
    height: 16,
    width: 16,
  },
  tabBar: {
    backgroundColor: "#fff",
    zIndex: 2,
  },
  tabButtonStyle: {
    width: "50%",
    alignItems: "center",
    paddingVertical: 13,
  },
  tabButtonActive: {
    borderBottomColor: "#fff",
    borderBottomWidth: 3,
  },
  tabButtonTextStyle: {
    color: "#fff",
    fontSize: 15,
    opacity: 0.6,
  },
  tabButtonTextActive: {
    opacity: 1,
  },
  gridHalf: {
    minWidth: 500 / PixelRatio.get(),
  },
  untabbedTitles: {
    marginTop: 17,
    marginLeft: 17,
    marginBottom: 10,
    fontSize: 18,
    fontWeight: "bold",
  },
});
