import { merge } from 'lodash-es';

export const dashboardUrls = {
  home: '/home',
};

export const browseUrls = {
  browse: '/browse',
  browseEmployers: '/browse/employers',
  browseEmployer: '/p/:customizableName',
  browseAll: '/browse/all',
  browseAllType: '/browse/all/:type/:id',
  browseJobs: '/browse/jobs',
  browseJob: '/browse/jobs/:id',
  browseDepartments: '/browse/departments',
  browseDepartment: '/browse/departments/:id',
  contactProfile: '/contact-profile',
  followCompanies: '/follow-companies',
  browseCompnay: '/:companyName/browse',
};

export const referUrls = {
  refer: '/refer',
};

export const trackerUrls = {
  tracker: '/tracker/my',
  sentJobs: '/tracker/sent',
  trackerSearch: '/tracker-search',
};

export const myUrls = {
  myBoon: '/my',
  myCompanies: '/my/organizations',
  myCompany: '/my/organizations/:organizationId',
  myContacts: '/my/network',
  myContact: '/my/network/:contactId',
  myProfile: '/my/profile',
  myStats: '/my/stats',
  yourNetwork: '/your-network',
  settings: '/profile/settings',
  changePassword: '/change-password',
  mySettings: '/my/settings',
  manageAccounts: '/my/accounts/manage',
};

export const authUrls = {
  // SignIn related
  signIn: '/signin',
  signInSSO: '/signin-sso',
  forgetPassword: '/forgetpassword',
  signInPassword: '/signin/password',
  login: '/login',
  requestAccess: '/request_access',
  linkedIn: '/linkedin',
  orgCreation: '/create-organization',
  postLogin: '/postLogin/authenticate',

  // SignUp related
  signUp: '/create-account',
  signUpOrgCreation:'/create-account/organization',
  generalInfo: '/general-info',
  location: '/location',
  signUpPassword: '/create-account/password',
  confirmPhone: '/account/confirm-phone-number',
  confirmCode: '/account/confirm-code',
  confirmationEmailSent: '/create-account/confirmation-email-sent',
  publicJobApplication: '/public-job-application',
  dashboardPublicJobApplication: '/referred-job-application',

  // Special URLs
  googleContactSyncSuccess: '/google_contacts_sync_success',
  confirmEmail: '/confirm-email-tokens',
  referRecordCreated: '/refer-record-created',
  confirmChangePassword: '/confirm-change-password',
  resetForgetPassword: '/reset-forget-password',
  publicReferralForm: '/public-referral-form',
  setPassword: '/set-password',
};

export const publicJobUrls = {
  jobDecline: '/decline-referral',
};

export const independantUrls = {
  //independant
  userForm: '/edit-profile',
  opportunityDetails: '/opportunity/details',
  completeProfile: '/complete-profile',
  notifications: '/notifications',
  invitations: '/invitations',
  grantPermissions: '/grant-permissions',
  web: '/magic-web',
  networkInvite: '/network-invite',
  networkConnect: '/network-connect',
  recommendations: '/recommendations',
  initialScreen: '/initial',
  applyJob: '/apply-job',
  confirmEmailTokens: '/confirm-email-tokens',
  magicLinkSent: '/magic-link-sent',
  resetPassword: '/reset-password',
  successScreen: '/success',
  invite: '/invite',
  publicReferral: '/public-referral',
  referDetail: '/refer-details',
  referredJobPost: '/referred-job-post',
  thankyouForYourInterest: '/thankyou-for-your-interest',
  generalSignup: '/t/:identifier/signup',
  loginRequest: '/login-request',
  getJobDetail: '/job/:openingId/:openingType',
  unsubscribeMessage: '/unsubscribe',
};

export const linkedinImportUrls = {
  linkedinConnect: 'https://www.linkedin.com/psettings/member-data',
};

export const urls = merge(
  {},
  dashboardUrls,
  browseUrls,
  referUrls,
  trackerUrls,
  myUrls,
  authUrls,
  independantUrls,
  publicJobUrls,
  linkedinImportUrls
);
