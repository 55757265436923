import React, { Component } from 'react';
import { View } from 'react-native';
import GoogleMap from 'google-map-react';
import {googleApiKey} from '../../constants/webKeys'
class LocationMap extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        const center = [this.props.lat, this.props.lon]
        const zoom = 14
        return (
                <GoogleMap
                    bootstrapURLKeys={{
                        key: googleApiKey,
                    }}
                    center={center}
                    zoom={zoom}
                    >
                </GoogleMap>
        );
    }
}

export default LocationMap;

// import React, { Component } from 'react';
// import { Platform, View } from 'react-native';
// import GoogleMap from 'google-map-react';
// import { googleApiKey } from '../../constants/webKeys'
// import MapView from 'react-native-maps';

// class LocationMap extends Component {

//     constructor(props) {
//         super(props);
//     }

//     mobileMap = () => {
//         return (
//             <MapView
//                 style={{ flex: 1 }}
//                 initialRegion={{
//                     latitude: this.props.lat,
//                     longitude: this.props.lon
//                 }}
//             />
//         );
//     }

//     webMap = () => {
//         const center = [this.props.lat, this.props.lon]
//         const zoom = 14
//         return (
//             <GoogleMap
//                 bootstrapURLKeys={{
//                     key: googleApiKey,
//                 }}
//                 center={center}
//                 zoom={zoom}
//             >
//             </GoogleMap>
//         );
//     }

//     render() {
//         const web = Platform.OS === 'web'
//         return (
//             <View>
//                 {web ? this.webMap() : this.mobileMap()}
//             </View>
//         );
//     }
// }

// export default LocationMap;
