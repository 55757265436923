import * as userService from '../../services/usersService';
import * as authActionConstants from '../../constants/actions/auth';
import {
    SET_SPINNER_VISIBLE,
    SET_SPINNER_HIDDEN
} from '../../constants/actions/appActions';
import { notification } from '../../helperFunctions';
import history from '../../RouteHistory';
import { urls } from '../../urls.js';
import { redirectToCurrentStep } from '../../services/stepsService';

export function getInviteData(token) {
    return (dispatch) => {
        dispatch({ type: authActionConstants.GET_USER_INVITE_DATA });
        if (token) {
            dispatch({ type: SET_SPINNER_VISIBLE });
            userService
                .getInviteData(token)
                .then((response) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_SUCCESS,
                        payload: response.data.payload
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: authActionConstants.GET_USER_INVITE_DATA_ERROR
                    });
                    notification.ref.show({
                        message: e.data.error,
                        isError: true
                    });
                })
                .finally(() => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                });
        }
    };
}

export function updateUserStatus(userId, orgName) {
    return (dispatch) => {
        if (userId && orgName) {
            userService
                .setUserStatus(userId, orgName)
                .then((response) => {
                    if (response.data.payload.active) {
                        history.push(urls.signIn);
                    } else {
                        history.push(urls.signUp);
                    }
                })
                .catch((e) => {
                    notification.ref.show({
                        message: e.data.error,
                        isError: true
                    });
                });
        }
    };
}

export function createOrgAccessRequest(data) {
    return (dispatch) => {
        if (data) {
            userService
                .setOrgAccessRequest(data)
                .then((response) => {
                    redirectToCurrentStep('home');
                })
                .catch((e) => {
                });
        }
    };
}

export function cancelOrgAccessRequest(data) {
    return (dispatch) => {
        if (data) {
            userService.cancelOrgAccessRequest(data)
            .then((response) => {
                if(response.data.response_code === responseCodes.OK) {
                    redirectToCurrentStep('home');
                } else {
                    notification.ref.show(response.data.error);
                }
            })
            .catch((e) => {
                notification.ref.show('Something went wrong')
            });
        }
    };
}
