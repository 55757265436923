import { PixelRatio } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { $background } from '../../../../generalStyles';

export default EStyleSheet.create({
    settingsWrapper: {
        width: "100%",
        display: 'flex',
        padding: 20,
        maxWidth: 1600 / PixelRatio.get(),
    },
    settings: {
        backgroundColor: 'white'
    },
    field: {
        marginBottom: 16,
    },
    changePasswordContainer: {
        padding: 20
    },
    changePasswordText: {
        paddingVertical: 20,
        fontSize: 22,
        borderTopWidth: 0.5,
        borderColor: $background
    },
    formWrapper: {
        maxWidth: 385,
    },
    integrationsContainer: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingHorizontal: 20,
    },
    integrationHeading: {
        fontWeight: 400,
        fontSize: 20,
        color: '#000000',
    },
    slackIntegrationContainer: {
        marginTop: 20,
        marginBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    addToSlackBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content',
        padding: 10,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#C7CACD',
        borderRadius: 4,
        cursor: 'pointer'
    },
    addToSlackText: {
        fontSize: 16,
        marginLeft: 8,
    },
    authorizedText: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 16,
        color: '#0FBC71',
        marginRight: 6,
    }
});
