import EStyleSheet from 'react-native-extended-stylesheet';
import { $green } from '../../generalStyles';

export default EStyleSheet.create({
    notificationWrapper: {
        position: 'absolute',
        left: 16,
        backgroundColor: '#fff',
        zIndex: 100000,
        width: '100% - 32',
        paddingHorizontal: 16,
        paddingVertical: 21,
        borderRadius: 5,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    notificationBody: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1
    },
    notificationText: {
        fontSize: 12
    },
    statusIcon: {
        height: 20,
        width: 20,
        marginRight: 22
    },
    closeButtonImage: {
        height: 14,
        width: 14
    },
});