import { View, Image } from "react-native";
import React from "react";

//components
import Text from "../../Text/Text";

// Styles
import styles from "./styles";

import earnSvg from "../../../assets/svg/earn.svg";
import referSvg from "../../../assets/svg/refer.svg";
import trackSvg from "../../../assets/svg/track.svg";

const items = [
  { img: referSvg, text: "Refer great talent within your network" },
  { img: trackSvg, text: "Track the progress of every referral you send." },
  {
    img: earnSvg,
    text: "Earn rewards and recognition for successful referrals.",
  },
];
const RewardUsage = () => {
  return (
    <View style={styles.root}>
      {items.map((item, index) => (
        <View style={styles.item} key={`item-${index}`}>
          <Text style={styles.itemNo}>{index}</Text>
          <Image style={styles.itemImg} source={item.img} />
          <Text style={styles.itemText}>{item.text}</Text>
        </View>
      ))}
    </View>
  );
};

export default RewardUsage;
