import { StyleSheet } from "react-native";
import { $green, $greish } from "../../generalStyles";

export default StyleSheet.create({
  wrapperStyle: {
    paddingTop: 15,
    position: "relative",
  },
  backgroundView: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: -1,
  },
  labelStyle: {
    fontSize: 16,
    color: $greish,
    fontWeight: "bold",
    marginLeft: 12,
    paddingHorizontal: 4,
    top: 22,
    left: 0,
    position: "absolute",
  },
  labelStyleFocused: {
    fontSize: 10,
    color: $green,
    top: 0,
  },
  inputStyle: {
    fontSize: 16,
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 16,
    backgroundColor: '#FFFFFF',
    borderBottomWidth: 1,
    borderBottomColor: $greish,
  },
  visibilityToggleButton: {
    position: "absolute",
    bottom: 5,
    right: 3,
  },
  visibilityToggleButtonOutlined: {
    position: "absolute",
    bottom: 20,
    right: 10,
  },
  visibilityIcon: {
    height: 12,
    width: 22,
  },
  maxLength: {
    position: "absolute",
    bottom: 10,
    right: 10,
    fontSize: 8,
    color: $greish,
  },
  disabledText: {
    color: "#918f8f",
  },
});
