import React, { Component, createRef } from 'react';
import CodeInput from 'react-native-confirmation-code-field';
import {$green, $greish} from '../../generalStyles';

export default class ConfirmationCodeInput extends Component {
    constructor(props) {
        super(props);
        this.state = { code: '' };
        this.pinInput = createRef();
        this.handleFulfill = this.handleFulfill.bind(this);
        this.cellProps = this.cellProps.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleFulfill(code) {
        const { onFulfill } = this.props;
        onFulfill(code)
    }

    handleChange(code) {
        const { onChange, codeLength } = this.props;
        onChange(code.length !== codeLength);
        return code
    }

    _clear() {
        this.pinInput.current.clear()
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
    }

    cellProps = ({ /*index, isFocused,*/ hasValue }) => {
        const { cellProps } = this.props;
        if (hasValue) {
            return {
                style: [cellProps, { borderBottomColor: $green }],
            }
        }

        return {
            style: cellProps
        }
    }


    render() {
        const { codeLength, autoFocus, containerProps, keyboardType, variant } = this.props;

        return (
            <CodeInput
                ref={this.pinInput}
                variant={variant}
                blurOnSubmit={false}
                codeLength={codeLength}
                keyboardType={keyboardType}
                autoFocus={autoFocus}
                cellProps={this.cellProps}
                containerProps={{style: containerProps}}
                onFulfill={this.handleFulfill}
                normalizeCode={this.handleChange}
                activeColor={$green}
                inactiveColor={$greish}
            />
        );
    }
}
