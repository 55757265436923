import React from 'react'
import ReferralSuccessIcon from '../../assets/svg/ReferralSuccessIcon'
import history from "../../RouteHistory";
import { urls } from '../../urls.js';
import Button from '@material-ui/core/Button';


const ReferUserAgain = (
  {progressValues, handleCompleteReferStrenthForm, resetForm, onDrawerClose, hideCompleteReferStrengthQuestion}) => {
  return (
    <div className="Strengthen-refer-form-container ">
          {hideCompleteReferStrengthQuestion && progressValues < 100 &&
            <button onClick={()=> handleCompleteReferStrenthForm()} className='complete-refer-strength-form-button'>
              Get your referral strength to 100%
            </button>
              }
            <div className="success-image-conatiner">
              <ReferralSuccessIcon />
              <div className="success-heading">{progressValues === 100 ? 'Amazing!' : 'Success!'}</div>
              <div className="success-text">
                {progressValues === 100
                  ? 'You got your Referral strength to 100%!'
                  : 'Your Referral has been completed successfully!'}
              </div>
            </div>
              <div className="actions">
            <Button
              variant="outlined"
              className="btn-refer"
              onClick={() => resetForm()}
              disabled={false}
            >
             Send another referral
            </Button>
            <Button
              variant="contained"
              className={'btn-apply'}
              onClick={() =>{ onDrawerClose(); history.push(urls.sentJobs)}}
            >
              View in Tracker
            </Button>
          </div>
        </div>
  )
}

export default ReferUserAgain
