/* eslint-disable no-underscore-dangle */
import React, { useMemo, useState } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';

import './ReduxFormAsyncSelect.css';

const customStyles = {
  control: (base) => ({
    ...base,
    border: '1px solid #c4c4c4',
    paddingLeft: '8px',
    paddingRight: '8px',
    boxShadow: 'none',
    '&:hover': { border: '1px solid #c4c4c4' },
  }),
  menuList: (base) => ({
    ...base,
    backgroundColor: 'white',
  }),
  option: (base, { isFocused }) => ({
    ...base,
    backgroundColor: isFocused ? 'rgba(0, 109, 228, 0.1)' : '#fff',
    color: '#343741',
    textDecoration: isFocused ? 'underline' : 'none',
  }),
  multiValue: () => ({ display: 'none' }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 0,
    fontSize: '14px',
    lineHeight: '16px',
    span: {
      position: 'absolute',
      top: '6px',
      fontSize: '15px',
      paddingLeft: 3,
    },
  }),
};

const CustomOption = (props) => {
  const { isSelected, data } = props;
  return (
    <components.Option {...props}>
      <div className="CustomOption__container">
        <div className="option-checkbox">{isSelected ? <CheckIcon fontSize="small" /> : null}</div>
        <div className="information">
          <span className="name">{data.__isNew__ ? data.label : (data && data.name) || ''}</span>
        </div>
      </div>
    </components.Option>
  );
};

const ReduxFormAsyncSelectLatest = (props) => {
  const {
    options,
    labelKey,
    valueKey,
    loadOptions,
    backspaceRemovesValue,
    label = '',
    isCreatable,
    isMulti = false,
    placeholder = '',
    closeMenuOnSelect,
    hideSelectedOptions,
    value,
    onChange,
    skillLimit = null,
    showChips = true,
    ...rest
  } = props;
  const [focused, setFocused] = useState(false);
  const Component = useMemo(() => (isCreatable ? AsyncCreatableSelect : AsyncSelect), [isCreatable]);

  const validateInput = (inputValue) => {
    if (inputValue && inputValue.length > 30) {
      return { isValid: false };
    }
    return { isValid: true };
  };

  const validateNewOption = (inputValue, selectValue, selectOptions) => {
    const cleanedInputValue = inputValue.trim().replace(/\.$/, ''); // Remove dot from the end
    return (
      cleanedInputValue.length > 0 &&
      cleanedInputValue.length <= 30 &&
      !selectOptions.some((option) => option[labelKey].toLowerCase() === cleanedInputValue.toLowerCase())
    );
  };

  const borderColor = (selectProps, base) => {
    let borderColor = '#c4c4c4';
    if (selectProps.validateInput) {
      const { isValid } = selectProps.validateInput(selectProps.inputValue);
      borderColor = isValid ? '#c4c4c4' : 'red';
    }
    return {
      ...base,
      border: `1px solid ${borderColor}`,
      paddingLeft: '8px',
      paddingRight: '8px',
      boxShadow: 'none',
      '&:hover': { border: `1px solid ${borderColor}` },
    };
  };

  return (
    <div className="redux-select-latest-container">
      {Boolean(label) && (
        <label htmlFor="skills" className={classNames('select-label', { multi: isMulti, focused })}>
          {label}
        </label>
      )}
      <Component
        value={value}
        onChange={onChange}
        onBlur={() => {
          setFocused(false);
        }}
        createOptionPosition="first"
        onFocus={() => setFocused(true)}
        loadOptions={loadOptions}
        backspaceRemovesValue={backspaceRemovesValue}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        isMulti
        getOptionLabel={(option) => option[labelKey]}
        getOptionValue={(option) => option[valueKey]}
        components={{
          Option: CustomOption,
          // Control,
          MultiValueLabel: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
        options={options}
        placeholder={
          focused ? (
            ''
          ) : (
            <>
              <SearchIcon />
              <span style={{ width: '200px' }}>{placeholder}</span>
            </>
          )
        }
        validateInput={validateInput}
        isValidNewOption={validateNewOption}
        styles={{
          ...customStyles,
          control: (base, { isDisabled, isValidating, selectProps }) => {
            return borderColor(selectProps, base)
          },
        }}
        isDisabled={!skillLimit ? false : value.length >= skillLimit}
        {...rest}
      />
      {isMulti && showChips && Boolean(value.length) && (
        <div className="Select-multi-value-wrapper">
          {value.map((item, i) => (
            <div key={`selected-item-${item.id}`} className="Select-value">
              <span
                className="Select-value-icon"
                aria-hidden="true"
                onClick={() => onChange(value.filter((v, j) => j !== i))}
              >
                ×
              </span>
              <span className="Select-value-label" role="option" aria-selected="true" id="react-select-3--value-0">
                {item.name || ''}
                <span className="Select-aria-only">&nbsp;</span>
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReduxFormAsyncSelectLatest;
