export default {
    'OK': 0,
    'NO_ERROR': 0,
    'UNSUPPORTED': 10,
    'INVALID_ARGUMENTS': 11,
    'UPDATE_ERROR': 12,
    'RECORD_ALREADY_EXISTS': 13,
    'INTERNAL_ERROR': 30,
    'INVALID_DEVICE_ID': 40,
    'SUCCESS': 200,
    'DEPRECATED': 500,
    'UNSUPPORTED_PROTOCOL_VERSION': 20,
    'GENERAL_ERROR': 1000,
    'INVALID_AUTH_TOKEN': 402,
    'INVALID_ACCESS_TOKEN': 403,
    'ENTITY_NOT_FOUND': 404,
    'INVALID_ACTION': 407,
    'USER_BLOCKED': 408,
    'AUTH_FAILED': 410,
    'PASSWORD_CONFIRMATION_REQUIRED': 411,
    'ACCOUNT_IS_NOT_CONFIRMED': 413,
    'PHONE_NUMBER_REQUIRED': 416,
    'TOO_MANY_REQUESTS': 429
};
