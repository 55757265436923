import baseService from './baseService.js';

export function getJobsTracker(params) {
  return baseService.get('/applicants/jobs_tracker', { params });
}
export function getSkills(organizationId) {
  return baseService.get(`/organizations/${organizationId}/skills/`);
}
export function applyJob(organizationId, params) {
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const requestData = new FormData();
  params.files.forEach((e, index) => {
    requestData.append(`files[]`, e);
  });
  requestData.append('refer_data', JSON.stringify(params));
  return baseService.post(`/organizations/${organizationId}/candidates`, requestData, config);
}
export const updateApplyJob = (data) => baseService.put('/general_question', data);

export function declineJob(job_id) {
  return baseService.post('applicants/decline_job', { job_id });
}

export function setSeen(source, refer_requests_ids) {
  return baseService.put('/update_tracker_seen_status/', { source, refer_requests_ids });
}

export function getTrackerDetails(params) {
  return baseService.get('/applicants/jobs_tracker_details', { params });
}

export function getReferralQuestions(referRequestId, reStrengthenReferral) {
  return baseService.get(`/strength_questions/${referRequestId}`, {
    params: { re_strengthen_referral: reStrengthenReferral },
  });
}

export function updatePDFStatus(pdfId) {
  return baseService.patch(`/pdf_files/${pdfId}`);
}

export function getInterviewStages(organizationId) {
  return baseService.get(`/organizations/${organizationId}/fetch_interview_stages`);
}
