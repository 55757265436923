import React from 'react';

export default function () {
  return (
    <svg width="153" height="136" viewBox="0 0 153 136" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M60.6252 95.5052V132.481C60.6252 135.85 64.933 137.286 66.9526 134.589L85.0147 110.512L90.5206 107.691L150.137 23.3983L151.246 16.8438L141.56 22.1966L60.6055 92.4728L60.6252 95.5052Z"
        fill="#1A9D64"
      />
      <path
        d="M147.634 16.2804C147.475 16.3439 34.5937 73.0193 34.4404 73.0961C31.9787 74.3268 31.8273 77.7893 34.1692 79.2346L60.6222 95.515L151.243 16.8536C150.322 16.0554 148.999 15.7146 147.634 16.2804Z"
        fill="#0FBC71"
      />
      <path
        d="M151.367 17.0163C151.316 16.9668 151.3 16.8902 151.246 16.8438L150.558 17.8188L85.0147 110.512L125.578 135.475C127.691 136.773 130.425 135.535 130.876 133.13L152.443 20.1637C152.672 18.9714 152.189 17.8166 151.367 17.0163Z"
        fill="#0FBC71"
      />
      <path
        d="M17 49C20.2 46.2 20 30 21.5 30C23 30 22.516 45.1256 24 47.5C26.5 51.5 37 51.5 37 53.5C37 55.5 25.357 54.8576 23.5 59.5C21.5 64.5 23.5 88 21.5 88C19.5 88 21.3 66.2201 18.5 59.5C16 53.5 4.5 55.5 4.5 53.5C4.5 51.5 13.8 51.8 17 49Z"
        fill="#FFC12E"
      />
      <path
        d="M89.2692 8.51724C90.7462 7.26207 90.6538 0 91.3462 0C92.0385 0 91.8151 6.78044 92.5 7.84483C93.6538 9.63793 98.5 9.63793 98.5 10.5345C98.5 11.431 93.1263 11.1431 92.2692 13.2241C91.3461 15.4655 92.2692 26 91.3462 26C90.4231 26 91.2539 16.2366 89.9615 13.2241C88.8077 10.5345 83.5 11.431 83.5 10.5345C83.5 9.63793 87.7923 9.77241 89.2692 8.51724Z"
        fill="#FFC12E"
      />
      <path
        d="M68 35.5C68 36.8807 66.8807 38 65.5 38C64.1193 38 63 36.8807 63 35.5C63 34.1193 64.1193 33 65.5 33C66.8807 33 68 34.1193 68 35.5Z"
        fill="#FFC12E"
      />
      <path
        d="M33 109C33 111.209 31.2091 113 29 113C26.7909 113 25 111.209 25 109C25 106.791 26.7909 105 29 105C31.2091 105 33 106.791 33 109Z"
        fill="#FFC12E"
      />
      <path
        d="M8 30C8 32.2091 6.20914 34 4 34C1.79086 34 0 32.2091 0 30C0 27.7909 1.79086 26 4 26C6.20914 26 8 27.7909 8 30Z"
        fill="#FFC12E"
      />
    </svg>
  );
}
