import React from 'react';

export default function () {
  return (
<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 0.919922C3.1398 0.919922 0 4.05972 0 7.91993C0 11.7801 3.1398 14.9199 7 14.9199C10.8602 14.9199 14 11.7801 14 7.91993C14 4.05972 10.8602 0.919922 7 0.919922ZM6.51075 1.97791V4.1705H4.81671C5.2134 3.05261 5.79195 2.23391 6.51075 1.97791ZM9.18202 4.1705C8.78533 3.05261 8.20678 2.23391 7.48798 1.97791V4.1705H9.18202ZM4.74646 2.33391C4.38863 2.83993 4.08259 3.4609 3.84094 4.17039H2.28425C2.92707 3.36194 3.77291 2.72598 4.74646 2.33391ZM11.7149 4.17039C11.0721 3.36194 10.2263 2.72598 9.2527 2.33391C9.61054 2.83993 9.91658 3.4609 10.1582 4.17039H11.7149ZM1.65325 5.14716H3.56095C3.39725 5.8537 3.2962 6.62305 3.26589 7.42623H0.996995C1.06305 6.60946 1.29498 5.83858 1.65325 5.14716ZM6.51138 5.14716H4.53246C4.37012 5.85042 4.27182 6.6266 4.24249 7.42623H6.51138V5.14716ZM7.48798 5.14716H9.46691C9.62924 5.85042 9.72754 6.6266 9.75688 7.42623H7.48798V5.14716ZM12.3467 5.14716H10.439C10.6027 5.8537 10.7037 6.62305 10.7341 7.42623H13.003C12.9369 6.60946 12.705 5.83858 12.3467 5.14716ZM0.996995 8.40317H3.26589C3.29533 9.20811 3.39752 9.97446 3.56095 10.6822H1.64816C1.29142 9.99056 1.06166 9.21974 0.996995 8.40317ZM6.51138 8.40317H4.24249C4.27148 9.20309 4.37037 9.97853 4.53246 10.6822H6.51138V8.40317ZM7.48798 8.40317H9.75688C9.72788 9.20309 9.62899 9.97853 9.46691 10.6822H7.48798V8.40317ZM13.003 8.40317H10.7341C10.7046 9.20811 10.6024 9.97446 10.439 10.6822H12.3518C12.7085 9.99056 12.9383 9.21974 13.003 8.40317ZM2.28005 11.6592H3.83673C4.07893 12.3724 4.38772 12.998 4.74734 13.5058C3.77104 13.1127 2.92352 12.4709 2.28005 11.6592ZM6.51163 11.6592H4.8125C5.20923 12.7801 5.79156 13.6004 6.51163 13.8569V11.6592ZM7.48798 11.6592H9.18711C8.79037 12.7801 8.20805 13.6004 7.48798 13.8569V11.6592ZM11.72 11.6592H10.1633C9.92112 12.3724 9.61233 12.998 9.2527 13.5058C10.229 13.1127 11.0765 12.4709 11.72 11.6592Z" fill="#C2C2C2"/>
</svg>

  );
}
