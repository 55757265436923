import * as userService from '../../services/usersService';
import {
    SET_SPINNER_VISIBLE,
    SET_SPINNER_HIDDEN
} from '../../constants/actions/appActions';
import { notification } from '../../helperFunctions';

export function postLoginRequest(email, successCallback) {
    return (dispatch) => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        userService
            .loginRequest(email)
            .then((response) => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                if (response.data.response_code === 0) {
                    successCallback();
                }
                else {
                    notification.ref.show({
                        message: response.data.error,
                        isError: true
                    });
                }
            })
            .catch((e) => {    
                notification.ref.show({
                    message: 'Error occured',
                    isError: true
                });
            });
    };
}
