import React, { Component } from 'react';
import { Dimensions, Keyboard, Platform, Image, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import { SceneMap, TabView } from 'react-native-tab-view';
import { connect } from 'react-redux';
//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import Text from '../../components/Text/Text';
import Actions from '../../RouteActions';
import BrowseList from './components/Browse/BrowseList';
import jobIcon from '../../assets/img/default_job_avatar.svg';
import departmentIcon from '../../assets/svg/people-group.svg';
import GlobalSearch from '../GlobalSearch/GlobalSearch';
import * as actions from './actions.js';
import { getEmployer } from '../EmployerProfile/actions';

//styles
import styles from './styles';
import LocationSelect from '../../components/LocationSelect/LocationSelect';

const initialLayout = {
  height: 0,
  width: Dimensions.get('window').width,
};
const BROWSE_TABS = ['all', 'jobs', 'departments'];
class Browse extends Component {
  _renderScene = SceneMap({
    all: () =>
      this.props.initTabIndex === 0 ? (
        <BrowseList params={this.props.match.params} location={this.props.location} type="all" />
      ) : (
        <View />
      ),
    jobs: () =>
      this.props.initTabIndex === 1 ? (
        <BrowseList params={this.props.match.params} location={this.props.location} type="job" />
      ) : (
        <View />
      ),
    departments: () =>
      this.props.initTabIndex === 2 ? (
        <BrowseList params={this.props.match.params} location={this.props.location} type="department" />
      ) : (
        <View />
      ),
  });

  onOpen = () => {
    this.drawer && this.drawer.openDrawer();
  };

  constructor(props) {
    super(props);

    const { employerProfile, location } = this.props;
    if ((!employerProfile || Object.keys(employerProfile).length == 0) && location && location.search) {
      props.getEmployer(/[?|&]org=([^&]+)/.exec(props.location.search)[1]);
    }

    if (this.props.hideSidebar === true) {
      sessionStorage.setItem('hideSidebar', 'true');
      sessionStorage.setItem('dynamicOrg', props.companyName);
    }

    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.renderHeader = this.renderHeader.bind(this);

    this.state = {
      index: props.initTabIndex ? props.initTabIndex : 0,
      routes: [
        { key: 'all', title: 'All' },
        { key: 'jobs', title: 'Jobs' },
        { key: 'departments', title: 'Departments' },
      ],
      org: props.org,
      user: props.user,
    };
  }

  componentDidMount() {
    const { setLocation } = this.props;
    setLocation({});
  }

  componentWillReceiveProps(newProps) {
    if (newProps.initTabIndex !== this.props.initTabIndex) {
      this.handleIndexChange(newProps.initTabIndex);
    }
  }

  handleIndexChange = (index) => {
    Keyboard.dismiss();
    this.setState({ index });
    this.handleTabChange(BROWSE_TABS[index]);
  };

  handleTabChange = (_key) => {
    const { employerProfile, currentUser, org, user } = this.props;
    const orgId = employerProfile.general_setting_identifier || org;
    const currUser = currentUser || user;
    switch (_key) {
      case 'all':
        Actions.browseAll(orgId, currUser);
        break;
      case 'jobs':
        Actions.browseJobs(orgId, currUser);
        break;

      case 'departments':
        Actions.browseDepartments(orgId, currUser);
        break;

      default:
        Actions.browseAll();
        break;
    }
  };

  renderHeader(props) {
    const {
      navigationState: { index, routes },
    } = props;
    const borderStyle = {
      borderBottomColor: '#dadada',
      borderBottomWidth: 1,
      width: '100%',
      zIndex: -1,
    };
    const web = Platform.OS === 'web';
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    const isMobile = window.screen.width < 500;
    const { location, setLocation } = this.props;

    return (
      <View style={{ flexGrow: 0, zIndex: 999 }}>
        <View style={styles.searchCont}>
          <GlobalSearch
            searchModel="jobs"
            placeholder="Job title, keywords, or company"
            hideBackBtn={true}
            hideNoResults={true}
            width={isMobile ? '100%' : '64%'}
            addMarginBottom={isMobile}
          />
          <LocationSelect
            disableAutoFetch
            noBorder
            onChange={setLocation}
            value={location}
            width={isMobile ? '100%' : '35%'}
            iconPosition="start"
          />
        </View>
        <View style={styles.tabBar}>
          {routes.map((route, routeIndex) => {
            const tabButtonStyles = [styles.tabButtonStyle, tabScreen && styles.tabButtonMobileStyle];
            const tabActiveButtonStyle = [styles.tabButtonTextStyle];

            if (index === routeIndex) {
              tabButtonStyles.push(styles.tabButtonActive);
              tabActiveButtonStyle.push({
                fontWeight: 'bold',
                color: '#9B9B9B',
              });
            }

            return (
              <TouchableOpacity onPress={() => this.handleTabChange(route.key)} key={route.key} style={tabButtonStyles}>
                {route.key === 'jobs' && (
                  <Image style={{ width: 16, height: 16, marginRight: 8 }} source={jobIcon} resizeMode="contain" />
                )}
                {route.key === 'departments' && (
                  <Image
                    style={{ width: 16, height: 16, marginRight: 8 }}
                    source={departmentIcon}
                    resizeMode="contain"
                  />
                )}
                <Text style={tabActiveButtonStyle} numberOfLines={1}>
                  {route.title}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={borderStyle} />
      </View>
    );
  }

  render() {
    const web = Platform.OS === 'web';
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    return (
      <DashboardBase title="Browse" style={{ maxWidth: 1400 }} hideSidebar={this.props.hideSidebar}>
        <View style={[!tabScreen && { height: '100%', padding: 30, position: 'relative' }]}>
          <TabView
            navigationState={this.state}
            renderScene={this._renderScene}
            renderTabBar={this.renderHeader}
            onIndexChange={this.handleIndexChange}
            initialLayout={initialLayout}
            swipeEnabled={false}
          />
        </View>
      </DashboardBase>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentSelectedOrg: state.authReducer.currentSelectedOrg,
    employerProfile: state.organizationsReducer.organizationDetails,
    currentUser: state.authReducer.currentUser,
    location: state.suggestionsReducer.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearSuggestions: () => {
      dispatch(actions.clearSuggestions());
    },
    setLocation: (location) => {
      dispatch(actions.setLocation(location));
    },
    getEmployer: (id) => {
      dispatch(getEmployer(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Browse));
