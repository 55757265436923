import React from 'react';
import BoonTextField from '../Basic/BoonTextField';

const PositionFormInput = (props) => {
    const { input, ...inputProps } = props;

    return (
        <BoonTextField
            {...inputProps}
            onChange={input.onChange}
            value={input.value ? input.value.title : ''}
        />
    );
}

export default PositionFormInput;
