import React, { useEffect, useState } from "react";
import { Text, Animated, Image, View } from "react-native";
import {useDropzone, setFiles} from 'react-dropzone';
import { notification } from "../../helperFunctions";

export default function DropzoneWithoutKeyboard(props) {
  const {
    image,
    imageStyle,
    imageIndicatorProps,
    wrapperStyle,
    onSelectionStart,
    afterSelectPhoto,
    includeBase64
  } = props;

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    multiple: false,
    maxSize: 4000000,
    accept: 'image/*',
    noDrag: true,
    onDrop: acceptedFiles => {
      if(acceptedFiles.length !== 0)
        {      
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
        }
      else
        {
          return(
          notification.ref.show({
            message: "Image should be less than 4MB",
            isError: true,
          }))
        }
    },
    onDropAccepted: acceptedFiles => {
      let file = acceptedFiles[0];
      if(includeBase64) {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => afterSelectPhoto(Object.assign(file, {data: window.btoa(reader.result)}));
      } else {
        afterSelectPhoto(file);
      }
    }
  });
  let file_url = typeof(image) === "string" ? image : image.uri;
  // console.warn(image.uri);
  // if(acceptedFiles.length) {
  //   file_url = acceptedFiles[0].preview;
  // }

  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container" onClick={onSelectionStart} style={{cursor: "pointer"}}>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <Animated.Image
          source={{ uri: file_url }}
          style={imageStyle}
          indicatorProps={imageIndicatorProps} />
      </div>
    </section>
  );
}
