import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

const StrengthBar = ({progressValues, showtitle = true}) => {
    const BorderLinearProgress = withStyles(() => ({
        root: { height: 8, borderRadius: 8 },
        colorPrimary: { backgroundColor: '#D3DAE6' },
        bar: { backgroundColor: '#0FBC71' },
      }))(LinearProgress);
  return (
        <div className={showtitle ? '' : "Strengthen-refer-form-container "}>
            <div className="strength-bar">
                {showtitle && <>
                    <hr className='hr-footer'/>
                    <div className="strength-name">Make your referral stand out with more details!</div>
                </>
                }
                    <div className="strength-sub">
                    Referral strength
                    <span className="progress-bar">{Math.trunc( progressValues )}%</span>
                    </div>
                    <BorderLinearProgress variant="determinate" value={progressValues} />    
            </div>
        </div>
  )
}

export default StrengthBar
