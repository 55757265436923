import { StyleSheet } from 'react-native';
import { $green, $gre, $greish } from '../../generalStyles';

export default StyleSheet.create({
    secondHeading: {
        fontSize: 40,
        color: $green,
        fontFamily: 'Roboto-Regular',
        marginBottom: 15
    },
    firstHeading: {
        fontSize: 19,
        color: $green,
        marginBottom: 20
    },
    thirdHeading: {
        fontSize: 19,
        color: $green,
        marginBottom: 20,
        width: 300,
        textAlign: 'center'
    },
    mainWrapper: {
        backgroundColor: 'white',
        width: '95%',
        borderRadius: 15,
        marginVertical: 15,
        padding: 15
    },
    buttonsWrapper: {
        justifyContent: 'flex-end',
        width: '100%',
        alignItems: 'flex-end'
    },
    loginButton: {
        color: '#7d8d8a',
        marginRight: 10,
        borderColor: $greish,
        borderWidth: 1,
        borderRadius: 5,
        textAlign: 'center',
        width: 'fit-content',
        paddingHorizontal: 15
    },
    loginButtonText: {
        fontSize: 14,
        fontFamily: 'Roboto-Regular'
    },
    joinButton: {
        borderColor: $greish,
        borderWidth: 1,
        borderRadius: 5,
        textAlign: 'center'
    },
    title: {
        marginTop: 20,
        fontSize: 20,
        fontWeight: 'bold',
    },
    actionButtonsContainer : {
        alignItems: 'center'
    },
    actionButton: {
        width: '20%',
        marginBottom: 5
    },
    interestedButton : {
        // marginTop: 5
    },
    noThanksButton: {
        width: '15%',
        backgroundColor: '#fff',
        borderColor: 'lightgrey',
        borderWidth: 1
    },
    noThanksText: {
        fontSize: 14,
        color: 'lightgrey',
    },
    tag: {
        color: 'black',
        backgroundColor: 'lightgrey',
        fontWeight: '500'
    }
});
