import React, {Component} from 'react';
import { View, StyleSheet } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';

import styles from './styles';

import { $green } from '../../generalStyles';

const pickerSelectStyles = StyleSheet.create({
    inputIOS: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingTop: 6,
        paddingHorizontal: 15,
        color: $green
    },
    icon: {
        position: 'absolute',
        backgroundColor: 'transparent',
        borderTopWidth: 10,
        borderTopColor: $green,
        borderRightWidth: 5,
        borderRightColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftColor: 'transparent',
        width: 0,
        height: 0,
        top: 13,
        right: 5,
    }
});


class FormSelect extends Component {
    constructor(props) {
        super(props);
        this.inputRefs = {};
    }

    render() {
        const { options, input, wrapperStyle, inputStyle, ...inputProps } = this.props;

        return (
            <View style={[styles.wrapperStyle, wrapperStyle]}>
                <View style={{}}>
                    <RNPickerSelect
                        {...inputProps}
                        style={{...pickerSelectStyles}}
                        onValueChange={input.onChange}
                        onBlur={input.onBlur}
                        onFocus={input.onFocus}
                        value={input.value}
                        items={options}
                        ref={(el) => {
                            this.inputRefs.picker = el;
                        }}
                        placeholder={inputProps.placeholder ? inputProps.placeholder : {}}
                    />
                </View>
            </View>
        );
    }
}

export default FormSelect;
