import React, { useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';
import { TouchableOpacity, Linking } from 'react-native';
import linkedinSSO from '../../assets/svg/linkedinsso.svg';
import styles from '../SignInEmail/styles';
import memberAppUrl from '../../services/memberAppUrl';

const LinkedInSsoLogin = () => {
    let clientId = process.env.REACT_APP_LINKDIN_CLIENT_ID;
    const redirectUri = `${memberAppUrl}/linkedin`;
    const scope = 'r_emailaddress+r_liteprofile';
    const handleClick = () => {
        Linking.openURL(
            `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=foobar&scope=${scope}`
        );
    };
    const logoStyles = {
        width: 20, 
        height: 20 
    }

    return (
        <TouchableOpacity
            style={styles.iconButton}
            onPress={() => handleClick()}
        >
            <Image
                source={linkedinSSO}
                resizeMode="contain"
                style={logoStyles}
            />
        </TouchableOpacity>
    );
};
export default (LinkedInSsoLogin);
