import React, { useEffect } from 'react';
import ReduxFormAsyncSelectLatest from '../../../components/ReactAsyncSelect/ReduxFormAsyncSelect';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import PdfIcon from '../../../assets/svg/pdf-icon.jsx';
import { isEmpty } from 'lodash-es';
import { normalizeCandidateLinkedinData } from '../../../helperFunctions.js';

const StrengthQuestionsStepOne = ({
  strengthQuestions: {
    skills,
    candidateRating,
    workWithClient,
    openToNewOppertunities,
    isDiversityHire,
    licenseAvailable,
    knowThePerson,
    isGoodFit
  },
  defaultOptions,
  loadSuggestions,
  handleSetSkills,
  handleStrengthChange,
  strengthQuestionsSettings: {
    how_good_are_they,
    know_the_person_level,
    worked_with_person,
    open_for_new_opportunities_status,
    diversity_hire,
    license_available,
    know_the_person_level_radio,
    why_are_they_good_fit
  },
  handleSkipStrengthSteps,
  handleHideToggleButtons,
  linkedinSkills,
}) => {
  useEffect(() => {
    if (
      !how_good_are_they &&
      !know_the_person_level &&
      !worked_with_person &&
      !open_for_new_opportunities_status &&
      !diversity_hire &&
      !know_the_person_level_radio &&
      !why_are_they_good_fit
    ) {
      handleHideToggleButtons();
      return handleSkipStrengthSteps();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(linkedinSkills)) {
      handleSetSkills(linkedinSkills);
    }
  }, [linkedinSkills]);

  return (
    <div className="strength_form_step_2_wrapper">
      {how_good_are_they && (
        <>
          <div className="question-text">What are they good at?</div>
          <ReduxFormAsyncSelectLatest
            name="skills"
            className="light max-width list multi"
            value={skills || []}
            isCreatable={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti
            defaultOptions={defaultOptions}
            isClearable={false}
            onChange={(e) => {
              handleSetSkills(e);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Tab' || e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            placeholder="Search skills to add"
            simpleValue
            labelKey="name"
            valueKey="id"
            skillLimit={0}
            loadOptions={loadSuggestions}
          />
        </>
      )}
      {know_the_person_level && (
        <>
          <div className="question-text">How well do you know this person?</div>
          <div className="candidateRating">
            <RadioGroup onClick={(e) => handleStrengthChange(e, 'candidateRating')} value={Number(candidateRating)}>
              {[1, 2, 3, 4, 5].map((rating) => (
                <FormControlLabel
                  label={rating}
                  value={rating}
                  control={
                    <Radio
                      style={Number(candidateRating) === rating ? { color: '#0FBC71' } : { color: '#bcbcbc' }}
                      size="small"
                    />
                  }
                />
              ))}
            </RadioGroup>
          </div>
          <div className="candidateRating-types">
            <span>Not at all </span>
            <span>Acquaintance</span>
            <span> Very well </span>
          </div>
        </>
      )}
      {worked_with_person && (
        <>
          <div className="question-text">Have you ever worked directly with this person?</div>
          <div>
            <RadioGroup
              onClick={(e) => handleStrengthChange(e, 'workWithClient')}
              value={workWithClient}
              className="worked-with-person-directly"
            >
              {['Yes', 'No'].map((val) => (
                <FormControlLabel
                  label={val}
                  value={val}
                  control={
                    <Radio style={workWithClient === val ? { color: '#0FBC71' } : { color: '#bcbcbc' }} size="small" />
                  }
                />
              ))}
            </RadioGroup>
          </div>
        </>
      )}
      {open_for_new_opportunities_status && (
        <>
          <div className="question-text">How open are they to new opportunities?</div>
          <Box sx={{ minWidth: 120, border: '1px solid #c4c4c4', borderRadius: '5px', marginTop: 10 }}>
            <FormControl fullWidth>
              <Select
                value={openToNewOppertunities}
                onChange={(e) => handleStrengthChange(e, 'openToNewOppertunities')}
                disableUnderline
              >
                <MenuItem value="Not Open">Not Open</MenuItem>
                <MenuItem value="Unsure">Unsure</MenuItem>
                <MenuItem value="Passively Looking">Passively Looking</MenuItem>
                <MenuItem value="Actively Looking">Actively Looking</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>
      )}

      {license_available && (
        <>
          <div className="question-text">
            Is the candidate professionally certified/licensed (e.g., Certified Teacher, Registered Nurse, IT Certification)?
          </div>
          <Box sx={{ minWidth: 120, border: '1px solid #c4c4c4', borderRadius: '5px', marginTop: 10 }}>
            <FormControl fullWidth>
              <Select
                value={licenseAvailable}
                onChange={(e) => handleStrengthChange(e, 'licenseAvailable')}
                disableUnderline
              >
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </>
      )}

      {diversity_hire && (
        <>
          <div className="question-text">Would you consider yourself a diversity hire?</div>
          <Checkbox
            color="secondary"
            name="diversityHire"
            id="diversityHire"
            checked={Boolean(isDiversityHire)}
            onClick={(e) => handleStrengthChange(e, 'isDiversityHire')}
            style={Boolean(isDiversityHire) ? { color: '#0FBC71' } : { color: '#bcbcbc' }}
          />
          <span className="diversityName">Yes</span>
        </>
      )}

      {know_the_person_level_radio && (
        <>
        <div className="question-text">Do you know this person professionally or personally?</div>
        <div>
          <RadioGroup
            onClick={(e) => handleStrengthChange(e, 'knowThePerson')}
            value={knowThePerson}
            className="know-professionally-personally"
          >
            {['Professionally', 'Personally'].map((val) => (
              <FormControlLabel
                label={val}
                value={val}
                control={
                  <Radio style={knowThePerson === val ? { color: '#0FBC71' } : { color: '#bcbcbc' }} size="small" />
                }
              />
            ))}
          </RadioGroup>
        </div>
      </>
      )}

     {why_are_they_good_fit && (
        <>
        <div className="question-text">Why do you think they are a good fit?</div>
        <div>
        <TextField
          id="outlined-multiline-static"
          className="text-input"
          size="small"
          InputProps={{
            style: {
              fontWeight: 400,
              color: '#000000',
              backgroundColor: '#ffffff',
            },
          }}
          variant="outlined"
          value={isGoodFit}
          onChange={(e) => handleStrengthChange(e, 'isGoodFit')}
        />
        </div>
      </>
      )}
      
    </div>
  );
};

export default StrengthQuestionsStepOne;
