import EStyleSheet from 'react-native-extended-stylesheet';
import { $activeBackgroundSidebar, $green, $background } from '../../generalStyles';

export default EStyleSheet.create({
  activeNavLink: {
    backgroundColor: '#F0F0F0',
  },

  sideMenu: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 'auto',
    flexDirection: 'column',
    backgroundColor: 'white',
    width: 200,
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },

  navLink: {
    width: '100%',
  },

  adminAppWrapper: {
    paddingHorizontal: 15,
    paddingVertical: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  adminAppTextStyle: {
    marginTop: 0,
  },

  adminApp: {
    backgroundColor: $activeBackgroundSidebar,
  },

  adminAppWrapper: {
    marginTop: 'auto',
    marginBottom: 15,
  },

  adminAppInnerWrapper: {
    backgroundColor: $activeBackgroundSidebar,
    borderRadius: 5,
  },

  elementWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },

  imageWrapper: {
    paddingVertical: 5,
    position: 'relative',
    justifyContent: 'center',
  },

  navMenuWrapper: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
  },

  navMenu: {
    width: 110,
    display: 'flex',
    justifyContent: 'center',
  },

  usernameText: {
    color: 'black',
    fontSize: 16,
    paddingHorizontal: 10,
  },

  linkWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },

  image: {
    height: 44,
    width: 44,
    borderRadius: 22,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#AEAEAE',
  },

  imageRounded: {
    borderRadius: 32,
  },

  homeLink: {
    height: 70,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#349f71',
  },

  logoWrapper: {
    flexShrink: 1,
    flexGrow: 0,
    padding: 19,
    backgroundColor: '#349f71',
    position: 'relative',
    width: '100%',
    height: '100%',
    alignItems: 'center',
  },

  logo: {
    width: 155,
    height: 35,
    flexGrow: 0,
    flexShrink: 1,
    resizeMode: 'contain',
  },

  navLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 25,
    paddingTop: 10,
    paddingBottom: 15,
  },

  textStyle: {
    color: '#9B9B9B',
    fontSize: 12,
    marginTop: 6,
    fontWeight: 'bold',
    marginLeft: 7,
  },

  unSelectedPage: {
    color: '#9B9B9B',
  },

  selectedPage: {
    color: '#0FBC71',
  },

  referText: {
    marginTop: 0,
    color: 'white',
  },

  referTextWrapper: {
    paddingVertical: 10,
    paddingHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
  },

  button: {
    borderRadius: 5,
  },

  refer: {
    backgroundColor: '#0FBC71',
    width: '100%',
  },

  actions: {
    marginVertical: 5,
    paddingVertical: 5,
    marginHorizontal: 15,
    alignItems: 'center',
  },

  referWrapper: {
    marginTop: 15,
    marginLeft: 30,
    marginRight: 30,
  },

  actionImage: {
    height: 15,
    width: 15,
  },

  btnTextStyle: {
    marginTop: 0,
    marginLeft: 0,
  },

  adminPanel: {
    borderTopWidth: 1,
    borderTopColor: '#E2E2E2',
    paddingTop: 0,
  },
  dropdownStyle: {
    backgroundColor: 'transparent',
    borderRadius: 5,
    // fontSize: 18,
    // color: '#18332F',
    // fontWeight: 'normal',
    marginLeft: 5,
  },
  linkCont: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 25,
    alignItems: 'center',
    paddingTop: 10,
  },
  linkIcon: {
    height: 15,
    width: 15,
  },
  linkTitle: {
    color: 'rgb(155, 155, 155)',
    fontWeight: 'bold',
    fontSize: 12,
    marginLeft: 6,
  },
  logoutIcon: {
    height: 20,
    width: 20,
  },
  logoutCont: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  logoutLinkCont: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 25,
    marginBottom: 15,
  },
  orgName: {
    fontSize: 13,
    color: 'rgb(155, 155, 155)',
    alignSelf: 'center',
    paddingLeft: 10
  },
});
