import { StyleSheet } from "react-native";
import { $green } from "../../../../generalStyles";

export default StyleSheet.create({
  root: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingBottom: 20,
    maxWidth: 375,
  },
  topPadding: {
    paddingTop: 17,
  },
  title: {
    color: "#18332F",
    fontSize: 20,
    fontWeight: "700",
    lineHeight: 23,
  },
  container: {
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#EEEEEE",
    borderBottomStyle: "solid",
    flexDirection: "row",
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 13,
    paddingRight: 17,
    alignItems: "center",
    marginTop: 20,
  },
  promoImageContainer: {
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#EEEEEE",
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBottom: -30,
    alignItems: "center",
  },
  promoImage: {
    height: 200,
    width: 300,
    marginTop: -15,
    marginBottom: 20,
    borderRadius: 8,
}
});
