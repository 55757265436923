import { StyleSheet } from "react-native";

export default StyleSheet.create({
  root: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#F9F9F9",
    zIndex: 1,
    paddingTop: 28,
    paddingHorizontal: 20,
  },
  header: {
    position: "relative",
  },
  backButtonStyle: {
    width: 24,
    height: 24,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    borderColor: "#CCCCCC",
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
  },
  fromWrapper: {
    backgroundColor: "transparent",
    flex: 1,
    paddingTop: 20,
  },
});
