import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Image,
    TextInput,
    TouchableOpacity,
    View,
    FlatList,
    Keyboard,
    ScrollView,
    Linking,
    Platform,
    Dimensions
} from 'react-native';
import moment from 'moment';
import qs from 'qs';
import history from '../../RouteHistory';

import { debounce, isEmpty } from 'lodash-es';
import Permissions from '../../wrappers/permission/permission';
import * as AsyncStorage from '../../AsyncStorage';
import Alert from '../../wrappers/Alert/Alert';
import { apiSeverUrl } from '../../services/baseService';

//components
import ContactListElement from '../ContactListElement/ContactListElement';
import Text from '../Text/Text';
import ListElementButton from '../../components/ListElementButton/ListElementButton'
import OrganizationStatus from '../../wrappers/organizationStatus/organizationStatus';

//actions
import * as networkActions from '../../globalActions/networkActions';
import * as statsActions from '../../screens/Home/components/Stats/actions';
import * as actions from './actions';

import { listElement } from '../../generalStyles';

//images
import closeIcon from '../../assets/img/close_icon.png';
import searchIcon from '../../assets/img/search_icon.png';
import searchIconBlack from '../../assets/img/search_icon_black.svg';
import shareIcon from '../../assets/img/share_icon_white.png';
import shareIconBlack from '../../assets/img/share_icon_black.svg';
import shareIconGreen from '../../assets/img/share_icon.png';
import downIcon from '../../assets/img/down_icon.svg';
import filterIcon from '../../assets/img/filter_icon.png';
import emptyIcon from '../../assets/img/empty_contacts.svg';
import { openConnectContactsDrawer } from '../../DrawerActions';

//styles
import styles from './styles';
import responsiveStyles from '../../responsiveStyles';
import ContactProfile from '../../screens/ContactProfile/ContactProfile';
import { googleSSOUrl, notification } from '../../helperFunctions';

class NetworkTab extends Component {
    constructor(props) {
        super(props);

        this.renderItem = this.renderItem.bind(this);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.filterList = this.filterList.bind(this);
        this.renderEmptyList = this.renderEmptyList.bind(this);

        this.scrollRef = null;

        this.state = {
            query: '',
            filter_type: '',
            isSearchFocused: false
        };

        // this.filter_type = ['google', 'phone', 'connections'];

        this.debouncedSearch = debounce((params) => {
            props.getUserConnections(params);
        }, 500);
    }

    componentDidMount() {
        const { currentUser, getUserStats } = this.props;
        const serachQuery = new URLSearchParams(location.search);
        const provider = serachQuery.get('provider');
        this.props.getUserSyncedHistory({ user_id: currentUser.id });
        if (history.location.search != '') {
            getUserStats()
            notification.ref.show({ message: `${provider} connected successfully` });
        }
        // this.props.setClick && this.props.setClick(this.filterList);
    }

    componentWillMount() {
        const { networkLoading, getUserConnections } = this.props;
        if (!networkLoading) {
            getUserConnections({ filter_type: '' });
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.googleConnectedSuccess && newProps.googleConnectedSuccess !== this.props.googleConnectedSuccess) {
            this.props.subscribeGoogle();
        }
    }

    handleSearchInputChange(query) {
        this.setState({
            query
        });

        this.debouncedSearch({ query, filter_type: this.state.filter_type });
    }

    filterList(filterIndex) {
        if (filterIndex < this.filter_type.length) {
            this.setState({
                filter_type: this.filter_type[filterIndex],
                query: ''
            });

            this.debouncedSearch({ filter_type: this.filter_type[filterIndex] });
        }
    }

    handlePermissionClick() {
        Permissions.request('contacts').then(response => {
            if (response === 'denied') {
                Permissions.openSettings();
            }
        });
    }

    renderItem({ item }) {
        const { params, network } = this.props;
        const contactId = isEmpty(params) ? network[0].to_user_id : params.contactId;
        return (
            <ContactListElement user={item} contactId={contactId} />
        )
    }

    handleGoogleConnectPress = () => {
        const { googleConnected, unsubscribeGoogle } = this.props;
        AsyncStorage.getAccessToken().then(accessToken => {
            if (googleConnected) {
                Alert.alert('Disconnect from Google?', 'You won\'t be able to see your new Google connections on Boon anymore', [
                    { text: 'Cancel', style: 'cancel' },
                    {
                        text: 'Disconnect',
                        onPress: () => unsubscribeGoogle(accessToken)
                    }
                ]);
            } else {
                googleSSOUrl(accessToken);
            }
        })
    }


    renderEmptyList() {
        const { permissions, networkLoading, network } = this.props;
        const { query } = this.state;
        const emptyString = query ? 'No contacts match your search parameters' : 'You haven’t added any contacts yet ';
        let isMobile = Math.round(Dimensions.get('window').width) < 750
        return !networkLoading && (
            <View style={isMobile ? listElement.emptyListWrapperMobile : listElement.emptyListWrapper}>
                <Image style={[styles.emptyImage, listElement.imageRounded]} source={emptyIcon} resizeMode="contain" />
                <Text style={styles.emptyTitle}>{emptyString}</Text>
                {network.length == 0 &&
                    <TouchableOpacity style={styles.googleWrapper} onPress={() => this.props.openConnectContactsForm()}>
                        <View style={styles.googleTextWrapper}>
                            <Image style={[{ width: 15, height: 15 }]} source={shareIconGreen} resizeMode="contain" />
                            <Text style={styles.googleText}>&nbsp;&nbsp;Import contacts</Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>
        )
    }

    handleFilterChange = (e) => {
        this.setState({ filter_type: e[0].value }, () => {
            this.props.getUserConnections(this.state)
        })
    }

    render() {
        const { network, networkLoading, getUserConnections, isLastPage, googleConnected, params, startLinkedinExport, showCountDown, userSources, showSearchBlock } = this.props;

        const { query, isSearchFocused } = this.state;

        const searchInputStyles = [styles.searchBlockInput];

        if (isSearchFocused) {
            searchInputStyles.push(styles.searchBlockInputActive);
        }
        const contactId = isEmpty(params) ? network[0] && network[0].to_user_id : params.contactId;
        let minutesElapsed = startLinkedinExport ? moment().diff(moment(startLinkedinExport.time), 'minutes') : null;
        let isMobile = Math.round(Dimensions.get('window').width) < 750
        return (
            <React.Fragment>
                <View style={{ flex: 1 }}>
                    <ScrollView ref={(el) => this.scrollRef = el} style={{ flex: 1 }}>
                        <View style={[
                            { marginTop: Platform.OS === 'web' ? (isMobile ? 5 : 20) : -230 },
                            // responsiveStyles.flexRowReverse,
                            // responsiveStyles.flexWrap,
                            // responsiveStyles.justifyCenter
                        ]}>
                            {/* <View style={[styles.gridHalf, responsiveStyles.flexColumn, { paddingBottom: 30 }]}>
                                {network.length > 0 && <ContactProfile userId={contactId} networkTab={true} />}
                            </View> */}
                            <View style={isMobile ? styles.gridHalfMobile : styles.gridHalf}>
                                {isMobile &&
                                    <View style={{ width: '80%', alignItems: showSearchBlock ? 'unset' : 'flex-end' }}>
                                        {showSearchBlock ?
                                            <View style={[isMobile ? styles.searchBlockMobile : styles.searchBlock, responsiveStyles.justifyCenter]}>
                                                <Image style={styles.searchIcon} source={searchIcon} />
                                                <View style={styles.searchBlockInputWrapper}>
                                                    <TextInput
                                                        style={searchInputStyles}
                                                        placeholderTextColor='#cccccc'
                                                        onChangeText={this.handleSearchInputChange}
                                                        value={query}
                                                        placeholder="Search"
                                                        onFocus={() => this.setState({ isSearchFocused: true })}
                                                        onBlur={() => {
                                                            this.setState({ isSearchFocused: false })
                                                            this.props.toggleSearchBlock(false)
                                                        }}
                                                    />
                                                    {Boolean(query) &&
                                                        <TouchableOpacity style={styles.searchBlockClearButton} onPress={() => {
                                                            this.handleSearchInputChange('')
                                                        }}>
                                                            <Image source={closeIcon} style={styles.searchBlockClearButtonImage} resizeMode="contain" />
                                                        </TouchableOpacity>
                                                    }
                                                </View>
                                            </View>
                                            :
                                            <TouchableOpacity
                                                onPress={() => this.props.toggleSearchBlock(true)}
                                            >
                                                <Image style={styles.searchIconMobile} source={searchIconBlack} resizeMode="contain" />
                                            </TouchableOpacity>
                                        }
                                    </View>
                                }
                                {!isMobile &&
                                    <View style={[isMobile ? styles.searchBlockMobile : styles.searchBlock, responsiveStyles.justifyCenter]}>
                                        <Image style={styles.searchIcon} source={searchIcon} />
                                        <View style={styles.searchBlockInputWrapper}>
                                            <TextInput
                                                style={searchInputStyles}
                                                placeholderTextColor='#cccccc'
                                                onChangeText={this.handleSearchInputChange}
                                                value={query}
                                                placeholder="Search"
                                                onFocus={() => this.setState({ isSearchFocused: true })}
                                                onBlur={() => this.setState({ isSearchFocused: false })}
                                            />
                                            {Boolean(query) &&
                                                <TouchableOpacity style={styles.searchBlockClearButton} onPress={() => {
                                                    this.setState({ showSearchBlock: false })
                                                    this.handleSearchInputChange('')
                                                }}>
                                                    <Image source={closeIcon} style={styles.searchBlockClearButtonImage} resizeMode="contain" />
                                                </TouchableOpacity>
                                            }
                                        </View>
                                    </View>
                                }
                                <View style={isMobile ? styles.buttonsContainerMobile : styles.buttonsContainer}>
                                    {isMobile ?
                                        <TouchableOpacity
                                            onPress={() => this.props.openConnectContactsForm()}
                                        >
                                            <Image style={styles.shareIconMobile} source={shareIconBlack} resizeMode="contain" />
                                        </TouchableOpacity>
                                        :
                                        <ListElementButton textStyle={{ color: '#ffffff' }} style={styles.addContacts} icon={shareIcon}
                                            onPress={() => this.props.openConnectContactsForm(showCountDown, minutesElapsed)}>
                                            Add Contacts
                                        </ListElementButton>}
                                    <View style={[isMobile ? { display: 'none' } : { width: 170, zIndex: 1000 }]}>
                                        <OrganizationStatus
                                            onChange={(e) => this.handleFilterChange(e)}
                                            searchable={false}
                                            name='contacts_filter'
                                            placeholder='Filter'
                                            dropdownValues={[{ label: 'CSV Contacts', value: 'csv_upload' }, { label: 'Outlook Contacts', value: 'outlook_contact' }, { label: 'Google Contacts', value: 'google' }, { label: 'All contacts', value: '' }]}
                                            styles={{ backgroundColor: '#D1D8DC', paddingRight: 10, borderRadius: 5 }}
                                            labelField={'label'}
                                            valueField={'value'}
                                        />
                                    </View>
                                </View>
                            </View>
                            <View style={isMobile ? styles.listHeaderMobile : styles.listHeader}>
                                <View style={isMobile ? styles.contactContainerMobile : styles.contactContainer}>
                                    <Text style={styles.contact}>CONTACTS ({network.length})</Text>
                                </View>
                                {/* <View style={styles.employerContainer}>
                                        <Text style={styles.employer}>EMPLOYER</Text>
                                    </View> */}
                                <View style={isMobile ? styles.infoContainerMobile : styles.infoContainer}>
                                    {isMobile ?
                                        <View style={[{ width: 100, zIndex: 1000 }]}>
                                            <OrganizationStatus
                                                onChange={(e) => this.handleFilterChange(e)}
                                                searchable={false}
                                                name='contacts_filter'
                                                placeholder='Filter'
                                                dropdownValues={[{ label: 'CSV Contacts', value: 'csv_upload' }, { label: 'Outlook Contacts', value: 'outlook_contact' }, { label: 'Google Contacts', value: 'google' }, { label: 'All contacts', value: '' }]}
                                                styles={styles.dropdownStyleMobile}
                                                labelField={'label'}
                                                valueField={'value'}
                                            />
                                        </View>
                                        :
                                        <Text style={styles.info}>INFO</Text>}
                                </View>
                            </View>
                            <View style={{ zIndex: -9 }}>
                                <FlatList
                                    contentContainerStyle={{}}
                                    onRefresh={() => getUserConnections(this.state)}
                                    refreshing={networkLoading}
                                    data={network}
                                    renderItem={this.renderItem}
                                    ListEmptyComponent={this.renderEmptyList}
                                    keyExtractor={(item, index) => `${item.first_name}_${item.last_name}_${index}`}
                                    onEndReached={() => {
                                        if (!isLastPage && !networkLoading) {
                                            getUserConnections({ ...this.state, offset: network.length });
                                        }
                                    }}
                                    keyboardShouldPersistTaps="always"
                                    onScrollBeginDrag={Keyboard.dismiss}
                                />
                            </View>
                        </View>
                    </ScrollView>
                    {/* <TouchableOpacity style={styles.filterButton} onPress={() => this.props.onOpen()}>
                        <Image source={filterIcon} style={styles.filterButtonImage} resizeMode="contain" />
                    </TouchableOpacity> */}
                </View>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        network: state.connectionsReducer.network,
        networkLoading: state.connectionsReducer.networkLoading,
        isLastPage: state.connectionsReducer.isLastPage,
        googleConnected: state.authReducer.currentUser.sync_google_people_contacts,
        permissions: state.authReducer.permissions,
        showCountDown: state.drawerReducer.showCountDown,
        startLinkedinExport: state.authReducer.startLinkedinExport,
        currentUser: state.authReducer.currentUser,
        userSources: state.authReducer.userSources,
        showSearchBlock: state.authReducer.showSearchBlock
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserConnections: (params) => {
            return dispatch(networkActions.getUserConnections(params));
        },
        unsubscribeGoogle: accessToken => {
            dispatch(actions.unsubscribeGoogle(accessToken));
        },
        openConnectContactsForm: (showCountDown, minutesElapsed) => {
            dispatch(openConnectContactsDrawer(showCountDown, minutesElapsed));
        },
        subscribeGoogle: () => {
            dispatch(actions.subscribeGoogle());
        },
        getUserSyncedHistory: (user) => {
            dispatch(actions.getUserSyncedHistory(user));
        },
        toggleSearchBlock: (data) => {
            dispatch(actions.showSearchBlock(data));
        },
        getUserStats: () => {
            dispatch(statsActions.getCurrentUserStats());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkTab);
