import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    View,
    TextInput,
    Platform,
    Image,
    ActivityIndicator,
    ScrollView
} from 'react-native';
import _debounce from 'lodash/debounce';

import * as actions from './actions';

import BoonTextField from '../../components/Basic/BoonTextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';

//components
import BackButton from '../BackButton/BackButton';
import Text from '../Text/Text';

//images
import search from '../../assets/img/search_icon.png';
import jobsIcon from '../../assets/svg/jobs.svg';
import organizationIcon from '../../assets/svg/organization.svg';

//styles
import { $green, $greish } from '../../generalStyles';
import styles from './styles';

const searchStyle = {
    height: 20,
    width: 20,
    paddingRight: 10
};

const scrollElement = {
    flex: 1,
    borderRadius: 0,
    maxHeight: 300
};

const scrollReferStyles = {
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#c3c3c3',
    maxHeight: 'unset'
};

const AutoSuggestComponent = (props) => {
    const {
        initialValue,
        showAllOnLoad,
        getSuggestions,
        suggestionsKey,
        service,
        params,
        clearSuggestions,
        emptySearch,
        onChangeQuery,
        suggestElement,
        suggestHeader,
        addNewElement,
        placeholder,
        keyField,
        resultsWrapperStyle,
        suggestionsLoading,
        onBackButtonPress,
        skipNavOnBackPress,
        referDrawer,
        searchModel,
        showAllOnFocus,
        suggestions,
        page,
        hideNoResults,
        generalReferrals
    } = props;

    const [inputValue, setInputValue] = useState(initialValue || '');

    useEffect(() => {
        if (showAllOnLoad) {
            getSuggestions('', service, suggestionsKey, showAllOnLoad, params);
        } else {
            clearSuggestions();
        }
        return () => {
            clearSuggestions();
        };
    }, []);

    const debouncedInputChange = useCallback(
        _debounce(async (newValue) => {
            if (onChangeQuery) {
                onChangeQuery(newValue);
            }
            getSuggestions(
                newValue,
                service,
                suggestionsKey,
                showAllOnLoad,
                params
            );
        }, 500),
        []
    );

    const onInputChange = (newValue) => {
        const trimmedValue = newValue.trim();
        setInputValue(newValue);
        debouncedInputChange(trimmedValue);
    };

    const handleTextInputFocus = () => {
        if (showAllOnFocus) {
            getSuggestions('', service, suggestionsKey, showAllOnFocus, params);
        }
    };

    const clearInput = () => {
        if (emptySearch) {
            setInputValue('');
        }
    };

    const web = Platform.OS == 'web';
    const placeholderText = placeholder ? placeholder : 'Search job or company';
    const marginStyle = referDrawer && !web ? { marginTop: 30 } : {};
    const hideIcons = page === 'general-info' || page === 'refer';

    let pageStyle;
    if (hideIcons) {
        const referPage = page === 'refer';
        pageStyle = referPage ? styles.referStyles : styles.simpleStyles;
    }

    let textStyle = [styles.searchBlockInput];
    if (page == 'general-info') {
        textStyle = [styles.searchBlockInputSimple];
    }

    return (
        <View style={styles.wrapper}>
            {page === 'refer' ? (
                <View>
                    <BoonTextField
                        variant="outlined"
                        id="refer-sugesstions"
                        size="small"
                        onChange={(e) => onInputChange(e.target.value)}
                        onFocus={(e) => handleTextInputFocus(e.target.value)}
                        value={inputValue}
                        label={placeholderText}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {placeholderText === 'Search Openings' ? (
                                        <img
                                            src={jobsIcon}
                                            style={{ height: '18px' }}
                                        />
                                    ) : (
                                        <img
                                            src={organizationIcon}
                                            style={{ height: '18px' }}
                                        />
                                    )}
                                </InputAdornment>
                            ),
                            style: { fontSize: 14, backgroundColor: '#FFFFFF' }
                        }}
                    />
                </View>
            ) : (
                <View
                    style={
                        page != 'general-info' && page != 'refer'
                            ? styles.searchBlock
                            : pageStyle
                    }
                >
                    <View
                        style={[
                            web && styles.searchBlockInputWrapper,
                            { flexDirection: 'row' },
                            marginStyle
                        ]}
                    >
                        {!hideIcons && (
                            <Image source={search} style={searchStyle} />
                        )}
                        <TextInput
                            style={textStyle}
                            onChangeText={onInputChange}
                            onFocus={handleTextInputFocus}
                            value={inputValue}
                            placeholder={placeholderText}
                            placeholderTextColor={'#757575'}
                        />
                    </View>
                </View>
            )}

            {suggestHeader && (
                <View>
                    <Text style={styles.suggestHeader}>{suggestHeader}</Text>
                </View>
            )}
            <View style={[styles.resultsWrapper, resultsWrapperStyle]}>
                {suggestionsLoading && (
                    <ActivityIndicator
                        size="large"
                        color={$green}
                        style={styles.spinner}
                    />
                )}
                {Boolean(
                    !suggestionsLoading &&
                        (suggestionsKey === 'jobs'
                            ? suggestions.length &&
                              !suggestions[0].jobs.length &&
                              !generalReferrals
                            : !suggestions.length) &&
                        (showAllOnLoad || inputValue) &&
                        !addNewElement &&
                        !hideNoResults
                ) && (
                    <View style={styles.emptyListWrapper}>
                        <Text style={styles.emptyListText}>
                            No results. Try again.
                        </Text>
                    </View>
                )}
                <ScrollView
                    style={[
                        scrollElement,
                        page === 'refer' && suggestions.length > 0
                            ? scrollReferStyles
                            : null
                    ]}
                >
                    <KeyboardAwareFlatList
                        keyboardShouldPersistTaps="always"
                        data={suggestions}
                        renderItem={suggestElement}
                        keyExtractor={(item, index) =>
                            `${item[keyField ? keyField : 'name']}_${index}`
                        }
                    />
                </ScrollView>
                <View style={page === 'refer' ? scrollReferStyles : null}>
                    {Boolean(
                        addNewElement && inputValue && !suggestionsLoading
                    ) && addNewElement(inputValue)}
                </View>
            </View>
        </View>
    );
};

const mapStateToProps = (state, props) => {
    return {
        suggestions: state.suggestionsReducer[props.searchType].suggestions,
        suggestionsLoading:
            state.suggestionsReducer[props.searchType].suggestionsLoading,
        generalReferrals: state.organizationsReducer.generalReferrals
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        getSuggestions: (
            newValue,
            service,
            suggestionsKey,
            showAllOnLoad,
            params
        ) => {
            dispatch(
                actions.getSuggestions(
                    newValue,
                    service,
                    suggestionsKey,
                    showAllOnLoad,
                    params,
                    props.searchType,
                    props.searchModel,
                    props.filters
                )
            );
        },
        clearSuggestions: () => {
            dispatch(actions.clearSuggestions(props.searchType));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutoSuggestComponent);
