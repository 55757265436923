import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    buttonStyle: {
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'row',
        height: 47
    },
    opacityWidth: {
        width: '100%'
    },
    textStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 16.41
    },
    active: {
        backgroundColor: $green
    },
    disabled: {
        backgroundColor: '#F2F2F2'
    },
    textForActive: {
        color: '#fff'
    },
    textForDisabled: {
        color: '#808080'
    }
});
