import React from 'react';
import styles from '../../screens/SignUp/styles.js';
import { View, Image, TouchableOpacity, Modal } from 'react-native';
import unionIcon from '../../assets/svg/union-icon.svg';
import CloseButton from '../../components/CloseButton/CloseButton';
import Text from '../../components/Text/Text';
import Actions from '../../RouteActions';

const SSOModal = ({ showSsoModal, setShowSsoModal }) => {
  return (
    <Modal transparent={true} visible={showSsoModal} onRequestClose={() => setShowSsoModal(!showSsoModal)}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#E7E7E796',
          marginTop: 22,
        }}
      >
        <View
          style={{
            backgroundColor: 'white',
            minWidth: 375,
            borderRadius: 5,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
          }}
        >
          <CloseButton
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              cursor: 'pointer',
            }}
            onPress={() => setShowSsoModal(!showSsoModal)}
          />
          <View
            style={{
              marginTop: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 'auto',
              width: 290,
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <Text
              style={{
                marginVertical: 20,
                textAlign: 'center',
              }}
            >
              Your organization has enabled Single Sign-On. Please click on the key to login with SSO.
            </Text>
            <TouchableOpacity style={styles.iconButton} onPress={() => Actions.signInSSO()}>
              <Image source={unionIcon} resizeMode="contain" style={{ width: 19, height: 10 }} />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default SSOModal;
