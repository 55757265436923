import Actions from '../../RouteActions';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as authService from '../../services/authService';
import { notification } from '../../helperFunctions';
import { SET_VIEW_SIGN_IN_FORM } from '../../constants/actions/auth';
import { Platform } from 'react-native';

export function resetPassword(email, path, errorCallback) {
    return dispatch => {
        let data = {
            email: email
        }
        if (Platform.OS != 'web') {
            data['fromApp'] = true
        } else {
            data['fromApp'] = false
        }
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.getresetPasswordToken(data).then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            if(response.data.response_code === 0){
                notification.ref.show({message: 'Password reset email has been sent.'});
                if (path) {
                    dispatch({type: SET_VIEW_SIGN_IN_FORM, payload: true});
                    Actions.login();
                }
                else
                    Actions.signInEmail();
            }
            else {
                if(errorCallback) {
                    errorCallback(response.data.error);
                }
                else {
                    notification.ref.show({ message: res.data.error, isError: true });
                }
            }
        }).catch(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            notification.ref.show({ message: response.data.error, isError: true });
        });
    }
}
