import React, { useCallback, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import _debounce from 'lodash/debounce';
import { View, TextInput, Platform, Image } from 'react-native';
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import BoonTextField from "../../components/Basic/BoonTextField";

//images
import searchIcon from '../../assets/img/search_icon.png';

//styles
import styles from './styles';

import * as actions from './actions';

const searchStyle = {
  height: 20,
  width: 20,
  paddingRight: 10,
};

const AutoSuggestSearchBox = ({
  placeholder,
  filters,
  params,
  showAllOnFocus,
  showAllOnLoad,
  suggestionsKey,
  service,
  referDrawer,
  searchType,
  searchModel,
  page,
  clearSuggestions,
  getSuggestions,
  onChangeInput = () => {},
  setNetworkSuggestionsLoading
}) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState('');
  const [focused, setFocus] = useState(false);

  useEffect(() => {
    if (showAllOnLoad) {
      debouncedGetSuggestions('');
    } else {
      clearSuggestions();
    }
  }, [showAllOnLoad]);

  const debouncedGetSuggestions = useCallback(
    _debounce(async (newValue) => {
      getSuggestions(
        newValue,
        service,
        suggestionsKey,
        showAllOnLoad,
        params,
        searchType,
        searchModel,
        filters
      );
    }, 500),
    [
      service,
      suggestionsKey,
      showAllOnLoad,
      params,
      searchType,
      searchModel,
      filters,
    ]
  );

  const handleInputChange = (e) => {
    setValue(e.target.value);
    onChangeInput(e.target.value);
    debouncedGetSuggestions(e.target.value);
  };

  useEffect(()=>{
    setNetworkSuggestionsLoading(searchType);
  },[])

  const handleTextInputFocus = () => {
    if (showAllOnFocus) {
      getSuggestions('');
    }
    setFocus(true);
  };

  const handleTextInputBlur = () => {
    setFocus(false);
  };

  const web = Platform.OS == 'web';
  const marginStyle = referDrawer && !web ? { marginTop: 30 } : {};

  let pageStyle;
  if (page === 'general-info' || page === 'refer') {
    pageStyle = page === 'refer' ? styles.referStyles : styles.simpleStyles;
  }

  let textStyle = [styles.searchBlockInput];
  if (page == 'general-info') {
    textStyle = [styles.searchBlockInputSimple];
  }

  return (
    <BoonTextField
      label={placeholder || 'Search job or company'}
      variant="outlined"
      size="small"
      value={value}
      fullWidth
      onChange={handleInputChange}
      onFocus={handleTextInputFocus}
      onBlur={handleTextInputBlur}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            style={{ color: "#CCCCCC" }}
          >
            <PersonOutlineIcon style={{ color: focused ? '#0FBC71': '' }} />
          </InputAdornment>
        ),
        style: {backgroundColor: "#fff", height: 44}
      }}
      InputLabelProps={{style:{marginTop:2}}}
    />
  );
};

const mapStateToProps = (state, props) => {
  return {
    suggestions: state.suggestionsReducer[props.searchType].suggestions,
    suggestionsLoading:
      state.suggestionsReducer[props.searchType].suggestionsLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSuggestions: (
      newValue,
      service,
      suggestionsKey,
      showAllOnLoad,
      params
    ) => {
      dispatch(
        actions.getSuggestions(
          newValue,
          service,
          suggestionsKey,
          showAllOnLoad,
          params,
          props.searchType,
          props.searchModel,
          props.filters
        )
      );
    },
    clearSuggestions: () => {
      dispatch(actions.clearSuggestions(props.searchType));
    },
    setNetworkSuggestionsLoading: () => {
      dispatch(actions.setNetworkSuggestionsLoading(props.searchType));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoSuggestSearchBox);
