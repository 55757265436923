import * as authActionConstants from '../../constants/actions/auth';

export const setCurrentOrg = (org) => (dispatch) => {
    dispatch({
        type: authActionConstants.SET_CURRENT_SELECTED_ORG,
        payload: org
    });

    setTimeout(() => {
        window.location.href = window.location.origin + '/home';
    }, 1000);
};
