import * as activityActionsConstants from '../../../../constants/actions/activityActions';
import * as activityService from '../../../../services/activityService';

export function getLeaderBoardList(params) {
    return dispatch => {
        dispatch({type: activityActionsConstants.GET_LEADER_BOARD_LIST});
        activityService.getLeaderBoardList(params).then(response => {
            dispatch({
                type: activityActionsConstants.GET_LEADER_BOARD_LIST_SUCCESS,
                payload: response.data.payload
            });
        }).catch(() => {
            dispatch({type: activityActionsConstants.GET_LEADER_BOARD_LIST_ERROR});
        });
    };
}