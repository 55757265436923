import { StyleSheet } from "react-native";
import { $green } from "../../../../generalStyles";

export default StyleSheet.create({
  root: {
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  titleText: {
    fontSize: 12,
    fontWeight: "600",
    letterSpacing: 1,
    textTransform: "uppercase",
  },
  linkBox: {
    backgroundColor: "#fff",
    borderRadius: 5,
  },
  linkContent: {
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  linkText: {
    opacity: 0.35,
  },
  logoImg: {
    width: 24,
    height: 24,
    marginRight: 14,
  },
  copyButton: {
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: $green,
    width: 40,
    height: 40,
  },
  copyImg: {
    width: 12,
    height: 12,
  },
});
