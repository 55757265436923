import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  logoWrapper: {
    paddingVertical: 50,
    paddingHorizontal: 60
  },
  logoWrapperMobile: {
    paddingVertical: 20,
    alignItems: 'center'
  },
  logo: {
    width: 94,
    height: 0,
    top: 0,
    resizeMode: 'contain'
  },
  logoMobile: {
    width: 100,
    height: 80,
    top: 0,
    resizeMode: 'contain'
  },
  boonHeading: {
    fontSize: 50,
    color: '#fff',
    fontFamily: 'Roboto-Regular',
    marginBottom: 15
},
});
