import { useEffect } from 'react';
import { connect } from 'react-redux';
import Survicate from '@survicate/survicate-web-package/survicate_widget';

const config = { workspaceKey: process.env.REACT_APP_SURVICATE_WORKSPACE_KEY };

const SurvicateTracker = ({ currentUser }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      if (Object.keys(currentUser).length > 0 && currentUser && currentUser.email) {
        Survicate.setVisitorTraits({ user_id: currentUser.email });
        Survicate.retarget();
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production' && currentUser) {
      Survicate.init(config);
    }
  }, [currentUser]);
  return null;
};

const mapStateToProps = (state) => ({
  currentUser: state.authReducer.currentUser,
});

export default connect(mapStateToProps, null)(SurvicateTracker);
