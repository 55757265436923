import React, { Component } from 'react';
import { FlatList, View, Image, TouchableOpacity, Platform } from 'react-native';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash-es';
import { format } from 'timeago.js';
import Actions from '../../RouteActions';

//components
import Text from '../../components/Text/Text';
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import { notification } from '../../helperFunctions';

//actions
import * as actions from './actions';
import { getUserJobReferData, getCandidateData } from '../Browse/components/Browse/actions';
import * as networkActions from '../../globalActions/networkActions';
import * as organizationsActions from '../../globalActions/organizationsActions';
import { getDetails } from '../../globalActions/browserActions';

//styles
import { listElement, mar10, titleFont } from '../../generalStyles';
import respStyles from '../../responsiveStyles';
import styles from './styles';

//images
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';
import defaultUserAvatar from '../../assets/img/default_profile_image.svg';
import ImageWithFallback from '../../components/ImagewithFallback.js'

import { closeDrawer } from '../../DrawerActions.js';
class Notifications extends Component {
    constructor(props) {
        super(props);
        this.connection_requested = this.connection_requested.bind(this);
        this.organization_invite = this.organization_invite.bind(this);

        this.handleViewableItemsChanged = this.handleViewableItemsChanged.bind(this)
        this.viewabilityConfig = { viewAreaCoveragePercentThreshold: 1000 }
    }

    componentDidMount() {
        this.props.getNotificationsList();
    }

    connection_requested(item) {
        const { acceptConnection, rejectConnection } = this.props;
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;

        let description = 'Wants to connect';

        if (item.status) {
            description = item.status === 'accepted' ? 'You are now connected' : 'You reject this contact';
        }
        const web = Platform.OS === 'web';
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => Actions.contactProfile({ userId: item.extra.user_id })}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            {description}.
                        </Text>
                        {Boolean(item.status) &&
                            <View style={listElement.tagsWrapper}>
                                {item.status === 'accepted' &&
                                    <ListElementButton style={styles.actionButton} secondary>Accepted</ListElementButton> ||
                                    <ListElementButton style={styles.actionButton} secondary>Rejected</ListElementButton>
                                }
                            </View> ||
                            <View style={listElement.tagsWrapper}>
                                <ListElementButton style={styles.actionButton} onPress={() => acceptConnection(item.sender_id)}>Accept</ListElementButton>
                                <ListElementButton style={styles.actionButton} onPress={() => rejectConnection(item.sender_id)}>Reject</ListElementButton>
                            </View>
                        }
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    connection_connected(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.contactProfile({ userId: item.extra.user_id })
                    this.props.closeDrawer();
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            You are now connected.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    connection_disconnected(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => Actions.contactProfile({ userId: item.extra.user_id })}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            You are now disconnected.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_liked(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;

        return (
            <View style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            Liked a referral.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </View>
        );
    }

    referred_you(item) {

        const { closeDrawer, currentUser, getUserJobReferData, getJobDetails, getCandidateData } = this.props;
        const userAvatar = item.extra.referral_provider_image_url ? { uri: item.extra.referral_provider_image_url } : defaultUserAvatar;
        const transition = () => {
            closeDrawer();
            Actions.browseJobDetail({ id: item.extra.job.uuid });
            let data = { userId: currentUser.id, jobId: item.extra.job.id }
            getJobDetails(item.extra.job.id)
            getUserJobReferData(data)
            getCandidateData(data)
        };

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={transition}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            referred you for a position.
                        </Text>
                        <View style={listElement.tagsWrapper}>
                            <ListElementButton style={styles.actionButton} onPress={transition}>View Referral</ListElementButton>
                        </View>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    applied_refer(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker({ direction: 'their', index: 1 });
                    setTimeout(() => {
                        Actions.tracker();
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            referralId: item.extra.applicant.uuid,
                            requestId: item.extra.refer_request.uuid,
                            direction: 'their'
                        });
                        this.props.closeDrawer();
                    }, 200)
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            applied to your referral.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    applied_without_refer(item) {
        const { closeDrawer, currentUser, getUserJobReferData, getJobDetails, getCandidateData } = this.props;
        const userAvatar = item.extra && item.extra.applicant ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        const transition = () => {
            this.props.closeDrawer();
            Actions.browseJobDetail({ id: item.extra.job.uuid });
            let data = { userId: currentUser.id, jobId: item.extra.job.id }
            getJobDetails(item.extra.job.id)
            getUserJobReferData(data)
            getCandidateData(data)
        };

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={transition}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.extra.applicant.name} `}</Text>
                            applied to your job.
                        </Text>
                        <View style={listElement.tagsWrapper}>
                            <ListElementButton style={styles.actionButton} onPress={transition}>View Job</ListElementButton>
                        </View>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    employer_job_applied(item) {
        const { closeDrawer, currentUser, getUserJobReferData, getJobDetails, getCandidateData } = this.props;
        const userAvatar = item.extra && item.extra.applicant ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        const transition = () => {
            closeDrawer();
            Actions.browseJobDetail({ id: item.extra.job.uuid });
            let data = { userId: currentUser.id, jobId: item.extra.job.id }
            getJobDetails(item.extra.job.id)
            getUserJobReferData(data)
            getCandidateData(data)
        };

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={transition}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.extra.applicant.name} `}</Text>
                            applied to your job
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                        </Text>
                        <View style={listElement.tagsWrapper}>
                            <ListElementButton style={styles.actionButton} onPress={transition}>View Job</ListElementButton>
                        </View>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_after_interview_candidate(item) {
        const userAvatar = item.extra.applicant.image_url ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your application moved from interview to passed for
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            by Admin
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_after_interview_referral(item) {
        const userAvatar = item.extra.applicant.image_url ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your referral
                            <Text style={styles.title}>{` ${item.extra.applicant.name} `}</Text>
                            moved from interview to passed for
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            by Admin
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_after_applied_candidate(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your application moved from applied to passed
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            by Admin
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_after_applied_referral(item) {
        const userAvatar = item.extra.applicant.image_url ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your referral
                            <Text style={styles.title}>{` ${item.extra.applicant.name} `}</Text>
                            moved from applied to passed for
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            by Admin
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_refer(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.referral_provider_image_url ? { uri: item.extra.referral_provider_image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.sentJobs({ direction: "their", index: 1 })
                    this.props.closeDrawer();
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            declined a refer for the position of<Text style={styles.title}>{` ${item.extra.job.name || item.extra.job.title} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    role_changed(item) {
        const orgAvatar = item.extra.image_url ? { uri: item.extra.image_url } : defaultOrgAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    notification.ref.show({ message: 'You have to visit your Admin App' });
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.extra.changed_by} `}</Text>
                            Changed your role as
                            <Text style={styles.title}>{` ${item.extra.role_name} `}</Text>
                            in
                            <Text style={styles.title}>{` ${item.extra.org_name} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    pending_referral_reminder(item) {
        const userAvatar = item.extra.referral_provider_image_url ? { uri: item.extra.referral_provider_image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization_image_url ? { uri: item.extra.organization_image_url } : defaultOrgAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job_id,
                            direction: 'my'
                        });
                    });
                    this.props.closeDrawer();
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            You have a pending referral from
                            <Text style={styles.title}>{` ${item.name} `}</Text>
                            against
                            <Text style={styles.title}>{` ${item.extra.job_title} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    pending_referral_about_to_expire(item) {
        const userAvatar = item.extra.referral_provider_image_url ? { uri: item.extra.referral_provider_image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization_image_url ? { uri: item.extra.organization_image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job_id,
                            direction: 'my'
                        });
                    });
                    this.props.closeDrawer();
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your pending referral from
                            <Text style={styles.title}>{` ${item.name} `}</Text>
                            against
                            <Text style={styles.title}>{` ${item.extra.job_title} `}</Text>
                            is about to expire.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    pending_referral_has_expired(item) {
        const userAvatar = item.extra.referral_provider_image_url ? { uri: item.extra.referral_provider_image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization_image_url ? { uri: item.extra.organization_image_url } : defaultOrgAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    notification.ref.show({ message: 'You can\'t see expire referrals' });
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Your pending referral from
                            <Text style={styles.title}>{` ${item.name} `}</Text>
                            against
                            <Text style={styles.title}>{` ${item.extra.job_title} `}</Text>
                            has been expired.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    pending_referral_has_expired_for_referral_provider(item) {
        const userAvatar = item.extra.candidate_image_url ? { uri: item.extra.candidate_image_url } : defaultUserAvatar;
        const orgAvatar = item.extra.organization_image_url ? { uri: item.extra.organization_image_url } : defaultOrgAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    notification.ref.show({ message: 'You can\'t see expire referrals', isError: true });
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            The referral request for
                            <Text style={styles.title}>{` ${item.extra.job_title} `}</Text>
                            , to
                            <Text style={styles.title}>{` ${item.name} `}</Text>
                            has been expired.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    organization_invite(item) {
        const { submitInvite } = this.props;
        const orgAvatar = item.image_url ? { uri: item.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            added you as a {`${item.extra.role} `}.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    will_interview_you(item) {
        const orgAvatar = item.image_url ? { uri: item.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    this.props.closeDrawer();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            direction: 'my'
                        });
                    })
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Congrats! <Text style={styles.title}>{`${item.extra.organization.title} `}</Text>
                            wants to interview you!
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    will_interview_him(item) {
        const { currentUser, closeDrawer } = this.props;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    closeDrawer();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            referralId: item.extra.applicant.uuid,
                            requestId: item.extra.refer_request.uuid,
                            direction: 'their'
                        });
                    })
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${currentUser.first_name} `}</Text>
                            Your referral scored
                            <Text style={styles.title}>{` ${item.name} `}</Text>
                            and interview at
                            <Text style={styles.title}>{` ${item.extra.organization.title}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    offered(item) {
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    this.props.closeDrawer();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            direction: 'my'
                        });
                    })
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>Congrats! </Text>
                            You have an offer for the
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            at
                            <Text style={styles.title}>{` ${item.extra.organization.title}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_got_offer(item) {
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;
        const userAvatar = item.extra.applicant.image_url ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    this.props.closeDrawer();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            referralId: item.extra.applicant.uuid,
                            requestId: item.extra.refer_request.uuid,
                            direction: 'their'
                        });
                    })
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>Congrats! </Text>
                            You referral
                            <Text style={styles.title}>{` ${item.extra.applicant.name} `}</Text>
                            got an offer for the
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            at
                            <Text style={styles.title}>{` ${item.extra.organization.title}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    you_were_hired(item) {        
        const orgAvatar = item.image_url ? { uri: item.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.tracker();
                    this.props.closeDrawer();
                    setTimeout(() => {
                        Actions.opportunityDetails({
                            jobId: item.extra.job.uuid,
                            direction: 'my'
                        });
                    });
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>Congratulations! </Text>
                            You've been hired by
                            <Text style={styles.title}>{` ${item.name}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    employer_hired_candidate(item) {
        const userAvatar = item.extra.applicant.image_url ? { uri: item.extra.applicant.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                    Actions.browseJobDetail({ id: item.extra.job.uuid });
                    this.props.closeDrawer();
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.orgAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{` ${item.extra.applicant.name} `}</Text>
                            has been hired for
                            <Text style={styles.title}>{` ${item.extra.job.name} `}</Text>
                            at
                            <Text style={styles.title}>{` ${item.extra.organization.title}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    one_got_hired(item) {
        const userAvatar = item.image_url ? { uri: item.image_url } : defaultOrgAvatar;
        const orgAvatar = item.extra.organization.image_url ? { uri: item.extra.organization.image_url } : defaultOrgAvatar;

        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]} onPress={() => {
                Actions.tracker();
                this.props.closeDrawer();
                setTimeout(() => {
                    Actions.opportunityDetails({
                        jobId: item.extra.job.uuid,
                        referralId: item.extra.applicant.uuid,
                        requestId: item.extra.refer_request.uuid,
                        direction: 'their'
                    });
                });
            }}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                <ImageWithFallback
                        imageSource={orgAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.userAvatar]}
                    />
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultOrgAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, styles.subImage]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.name} `}</Text>
                            Got hired by
                            <Text style={styles.title}>{` ${item.extra.organization.title}`}</Text>
                            .
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    bounty_amount_changed(item) {
        const userAvatar = item.extra.image_url ? { uri: item.extra.image_url } : defaultUserAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{` ${item.extra.changed_by} `}</Text>has changed <Text style={styles.title}>{` ${item.extra.name} `}</Text>value from <Text style={styles.title}>{` ${item.extra.old_amount} `}</Text>to <Text style={styles.title}>{` ${item.extra.new_amount}`}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    bounty_period_changed(item) {
        const userAvatar = item.extra.image_url ? { uri: item.extra.image_url } : defaultUserAvatar;

        return (
            <TouchableOpacity
                style={[listElement.elementWrapper, styles.elementWrapper]}
                onPress={() => {
                }}
            >
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{` ${item.extra.changed_by} `}</Text>has changed<Text style={styles.title}>{` ${item.extra.name} `}</Text>payment period from<Text style={styles.title}>{` ${item.extra.old_period} `}</Text>to<Text style={styles.title}>{` ${item.extra.new_period}`}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referred_on_job(item) {
        const userAvatar = item && item.extra.referral_provider_image_url ? { uri: item && item.extra.referral_provider_image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.extra.referral_provider_name} `}</Text>has sent a referral on your job <Text style={styles.title}>{`${item.extra.job.title} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    applied_on_job(item) {
        const userAvatar = item && item.extra.organization.image_url ? { uri: item && item.extra.organization.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            You successfully applied on <Text style={styles.title}>{`${item.extra.job.name} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    declined_refer_for_employer(item) {
        const userAvatar = item && item.extra.applicant.image_url ? { uri: item && item.extra.applicant.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            <Text style={styles.title}>{`${item.extra.applicant.name} `}</Text>has decline a position of <Text style={styles.title}>{`${item.extra.job.name} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_bonus_payment_due_six_day(item) {
        const userAvatar = item && item.image_url ? { uri: item && item.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Referral bonus payment for <Text style={styles.title}>{`${item.extra.referral_provider} `}</Text> is due in <Text style={styles.title}>6</Text> days for <Text style={styles.title}>{`${item.extra.candidate_name} `}</Text> hiring on <Text style={styles.title}>{`${item.extra.job_title} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_bonus_payment_due_one_day(item) {
        const userAvatar = item && item.image_url ? { uri: item && item.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Referral bonus payment for <Text style={styles.title}>{`${item.extra.referral_provider} `}</Text> is due in <Text style={styles.title}>1</Text> days for <Text style={styles.title}>{`${item.extra.candidate_name} `}</Text> hiring on <Text style={styles.title}>{`${item.extra.job_title} `}</Text>
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_bonus_payment_paid(item) {
        const userAvatar = item && item.image_url ? { uri: item && item.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Referral bonus amount has been approved for you referral of <Text style={styles.title}>{`${item.extra.candidate_name}`}</Text> for <Text style={styles.title}>{`${item.extra.job_title}`}</Text>.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }

    referral_bonus_payment_past_due_date(item) {
        const userAvatar = item && item.image_url ? { uri: item && item.image_url } : defaultUserAvatar;
        return (
            <TouchableOpacity style={[listElement.elementWrapper, styles.elementWrapper]}>
                <View style={[listElement.imageWrapper, styles.imageWrapper]}>
                    <ImageWithFallback
                        imageSource={userAvatar}
                        fallbackSrc={defaultUserAvatar}
                        resizeMode="contain"
                        imageStyles={[listElement.image, listElement.imageRounded, styles.userAvatar]}
                    />
                </View>
                <View style={[listElement.elementBody]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.description}>
                            Payment of referral bonus amount of <Text style={styles.title}>{`${item.extra.candidate_name}`}</Text> for <Text style={styles.title}>{`${item.extra.job_title}`}</Text> has past it’s due date.
                        </Text>
                    </View>
                </View>
                {this.renderTimestamp(item)}
            </TouchableOpacity>
        );
    }
    renderTimestamp(item) {
        return (
            <View style={[listElement.timestampWrapper, styles.timestampWrapper]}>
                <Text style={listElement.timestampText}>{format(item.created_at)}</Text>
            </View>
        );
    }

    handleViewableItemsChanged({ viewableItems, changed }) {
        const { setRead } = this.props;

        const notViewedList = changed.filter(element => element.isViewable && !element.item.read_at).map(element => element.item.id);

        if (notViewedList.length) {
            setRead(notViewedList);
        }
    }

    render() {
        const { notificationsList, notificationsListLoading, isLastPage, getNotificationsList } = this.props;
        const web = Platform.OS === 'web';
        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Notifications' headerStyle={styles.elementWrapper} />
                {(isEmpty(notificationsList) && !notificationsListLoading)
                    ? <Text style={[mar10, titleFont]}>You don't have any notifications.</Text>
                    : <FlatList
                        onRefresh={() => getNotificationsList()}
                        refreshing={notificationsListLoading}
                        style={[styles.screenWrapper, Platform.OS === 'web' && { height: 'calc(100vh - 60px)', paddingLeft: 7 }]}
                        data={notificationsList}
                        renderItem={({ item, index }) => this[item.notification_type](item, index)}
                        keyExtractor={(item, index) => `${item.sender_id}_${index}`}
                        onEndReached={() => {
                            if (!isLastPage && !notificationsListLoading) {
                                getNotificationsList({ offset: notificationsList.length });
                            }
                        }}
                        onViewableItemsChanged={this.handleViewableItemsChanged}
                        viewabilityConfig={this.viewabilityConfig}
                    />
                }
            </View>
        );
    }
}
const mapStateToProps = state => {
    return {
        notificationsList: state.notificationsReducer.notificationsList,
        notificationsListLoading: state.notificationsReducer.notificationsListLoading,
        isLastPage: state.notificationsReducer.isLastPage,
        currentUser: state.authReducer.currentUser
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getNotificationsList: params => {
            dispatch(actions.getNotificationsList(params));
        },
        setRead: params => {
            dispatch(actions.setRead(params));
        },
        acceptConnection: connectionId => {
            dispatch(networkActions.acceptConnection(connectionId));
        },
        rejectConnection: connectionId => {
            dispatch(networkActions.rejectConnection(connectionId));
        },
        submitInvite: organizationId => {
            dispatch(organizationsActions.submitInvite(organizationId));
        },
        closeDrawer: () => {
            dispatch(closeDrawer());
        },
        getJobDetails: (jobId) => {
            dispatch(getDetails('job', jobId, null));
        },
        getUserJobReferData: (data) => {
            dispatch(getUserJobReferData(data));
        },
        getCandidateData: (data) => {
            dispatch(getCandidateData(data));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
