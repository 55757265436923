import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    selectAllWrapper: {
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 16,
        paddingRight: 16,
        paddingLeft: 32,
        shadowOffset: {
            height: 2,
            width: 0
        },
        shadowRadius: 4,
        shadowColor: '#000',
        shadowOpacity: 0.2,
        position: 'relative',
        zIndex: 2
    },
    connectionsWrapper: {
        flex: 1
    },
    inviteButton: {
        borderRadius: 0
    }
});