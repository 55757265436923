import React, { Component } from 'react';
import { View } from 'react-native';

//components
import Text from '../../../../components/Text/Text';

//styles
import { listElement } from '../../../../generalStyles';
import style from './styles';

class IneligibleStatusLabel extends Component {
    render() {
        return (
            <View style={[listElement.rightBlock]}>
                <View
                    style={[
                        style.statusLabelWrapper,
                        { backgroundColor: '#EBEEF1' }
                    ]}
                >
                    <Text
                        style={[style.statusLabelTitle, { color: '#6A7383' }]}
                    >
                        Ineligible
                    </Text>
                </View>
            </View>
        );
    }
}

export default IneligibleStatusLabel;
