import { StyleSheet, PixelRatio } from 'react-native';

export default StyleSheet.create({
    emptyListText: {
        paddingTop: 32,
        paddingBottom: 16,
        textAlign: 'center',
        color: '#828587',
        fontSize: 16
    },
    organizationWrapper: {
        marginBottom: 32,
        alignItems: 'center',
        flex: 1,
        paddingHorizontal: 8
    },
    organizationImage: {
        height: 61,
        width: 61,
        borderRadius: 5
    },
    organizationName: {
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'center',
        flexGrow: 1,
        marginTop: 6
    },
    organizationJobs: {
        fontSize: 12,
        color: '#9fa3a5',
        marginBottom: 4
    },
    button: {
        height: 30
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    col: {
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    cell: {
        width: '47%',
        marginBottom: 24
    },
    gridHalf: {
        width: '45%',
        padding: 10,
        flexGrow: 1,
        maxWidth: 1100 / PixelRatio.get(),
        minWidth: 600 / PixelRatio.get()
    }
});
