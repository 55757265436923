import React from 'react';
import BoonTextField from '../Basic/BoonTextField';

import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

export default function UserFormInput(props) {
  const { input, wrapperStyle, inputStyle, labelStyle, ...inputProps } = props;

  const image = input.value && input.value.logo_image_url ? { uri: input.value.logo_image_url } : defaultOrgAvatar;
  return <BoonTextField {...inputProps} value={(input.name && input.name.trim()) || ''} />;
}
