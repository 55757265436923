import Actions from '../../RouteActions';
import { reset } from 'redux-form';

import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import responseCodes from '../../constants/responseCodes';
import * as authService from '../../services/authService';
import baseService from '../../services/baseService';
import { redirectToCurrentStep } from '../../services/stepsService'
import { getDeviceFingerprint, getPushToken, setAccessToken } from '../../AsyncStorage';
import { openNotificationsDrawer } from '../../DrawerActions.js';
import { notification } from '../../helperFunctions';
import Cookies from 'js-cookie';
import history from '../../RouteHistory';
import { urls } from '../../urls';

export function signIn(formData, params) {
    return (dispatch, getState) => {
        const { organizationDetails } = getState().organizationsReducer;
        dispatch({ type: authActionsConstants.SIGN_IN });
        getDeviceFingerprint().then(deviceFingerprint => {
            getPushToken().then(pushToken => {
                authService.login({
                    ...formData,
                    'device_fingerprint': deviceFingerprint,
                    'push_token': pushToken
                }).then(response => {
                    if(response.data.response_code == responseCodes.AUTH_FAILED){
                        notification.ref.show({message: response.data.error, isError: true});
                        dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
                    }
                    else{
                        if (response.data.payload.current_incomplete_step === 'email_confirmed_logout'){
                            notification.ref.show({ message: "Your Email has been confirmed. Login to continue.", isError: false })
                            dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
                            return history.push(urls.signIn);
                        }
                        baseService.addAuthToken(response.data.payload.access_token).then(() => {
                            let isUserAuthorizedToRedirectToAdminApp = true
                            const env = process.env.REACT_APP_ENVIRONMENT || 'development';
                            const URL = Cookies.get(`PREVIOUS-URL-${env}`, { domain: env === 'development' ? 'localhost' : '.goboon.co' })
                            if (URL) {
                                if (
                                    !response.data.payload.is_any_org_admin &&
                                    !URL.includes('slack/link-organization') &&
                                    !URL.includes('/company')
                                ) {
                                    isUserAuthorizedToRedirectToAdminApp = false
                                } else {
                                    dispatch({type : authActionsConstants.SET_REDIRECT_URL_LOADER_TRUE})
                                }
                            }
                            dispatch(reset('signIn'));
                            dispatch({ type: authActionsConstants.SIGN_IN_SUCCESS, payload: response.data.payload });
                            if (response.data.payload.access_token){
                                setAccessToken(response.data.payload.access_token);
                            }
                            if (organizationDetails.id) {
                                let currentSelectedOrg = { label: organizationDetails.name, value: organizationDetails.name, id: organizationDetails.id, logo: organizationDetails.logo_image_thumb}
                                dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                            } else if (response.data.payload.organizations.length > 0) {
                                let currentSelectedOrg = { 
                                    label: response.data.payload.organizations[0].organization.name,
                                    value: response.data.payload.organizations[0].organization.name, 
                                    id: response.data.payload.organizations[0].organization.id, 
                                    logo: response.data.payload.organizations[0].organization.logo_image_thumb,
                                    uuid: response.data.payload.organizations[0].organization.uuid
                                };
                                const selectedOrgIdentifier =response.data.payload.organizations[0].general_setting_identifier;
                                Cookies.set(`currentOrgIdentifier-${env}`, selectedOrgIdentifier, {
                                    domain: env === 'development' ? 'localhost' : '.goboon.co',
                                });
                                dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                            }
                            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                            dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                            if (response.data.payload.current_incomplete_step && !Boolean(formData.refer_signin)) {
                                redirectToCurrentStep(response.data.payload.current_incomplete_step, params)
                                if (formData.notify === 'true')
                                    dispatch({ type: appActionsConstants.SET_NOTIFY_TRUE, payload: true });
                            }
                            else if (Boolean(formData.public_job_application)) {
                                Actions.setPassword();
                            }
                            else if (Boolean(formData.refer_signin)) {
                                setTimeout(() => {
                                    Actions.tracker();
                                }, 1000)
                            }
                            else {
                                redirectToCurrentStep(response.data.payload.current_incomplete_step);
                            }
                            if(isUserAuthorizedToRedirectToAdminApp && URL){
                                setTimeout(()=>{
                                    window.location.href = URL
                            },3000)
                        }
                            Cookies.remove(`PREVIOUS-URL-${env}`, { domain: env === 'development' ? 'localhost' : '.goboon.co' })
                        });
                    }
                }).catch(response => {
                    if (response.data && response.data.response_code === responseCodes.PHONE_NUMBER_REQUIRED) {
                        if (response.data.payload.user) {
                            const { user: { first_name, last_name, email, workplace, company_position } } = response.data.payload;
                            Actions.confirmPhone({
                                initialValues: {
                                    company_position,
                                    follow_company_name: workplace,
                                    first_name,
                                    last_name,
                                    email,
                                    password: formData.password,
                                    phoneNumberRequired: true
                                }
                            });
                        }
                    }
                    if (response.data && response.data.response_code === responseCodes.ACCOUNT_IS_NOT_CONFIRMED) {
                        if (response.data.payload.user) {
                            const { user: { first_name, last_name, email, workplace, company_position } } = response.data.payload;
                            Actions.signUp({
                                initialValues: {
                                    company_position,
                                    follow_company_name: workplace,
                                    first_name,
                                    last_name,
                                    email,
                                    password: formData.password,
                                    continueSignup: true
                                }
                            });
                        } else {
                            Actions.magicLink({
                                hideBackLink: true,
                                email: formData.email,
                                skipInitialSending: true,
                                confirmationFlow: true
                            });
                        }
                        dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
                        dispatch({ type: authActionsConstants.LOGIN_FORM_ERROR, payload: true });
                    } else {
                        dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
                    }
                    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                });
            });
        })
    };
}

export function loginUserWithToken(token) {
    return (dispatch) => {
        dispatch({ type: authActionsConstants.SIGN_IN });
        authService.getUserWithToken({
            'token': token
        }).then(response => {
            baseService.addAuthToken(response.data.payload.access_token).then(() => {
                dispatch({ type: authActionsConstants.SIGN_IN_SUCCESS, payload: response.data.payload });
                if (response.data.payload.access_token){
                    setAccessToken(response.data.payload.access_token);
                }
                if (response.data.payload.organizations.length > 0){
                    let currentSelectedOrg = { label: response.data.payload.organizations[0].organization.name, value: response.data.payload.organizations[0].organization.name, id: response.data.payload.organizations[0].organization.id, logo: response.data.payload.organizations[0].organization.logo_image_thumb}
                    dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                }
                dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                Actions.home({}, true);
            });
        }).catch(response => {
            dispatch({ type: authActionsConstants.SIGN_IN_ERROR });
            notification.ref.show({ message: response.data.payload.error, isError: true });
        });
    };
}

export function resetPassword(email) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.getresetPasswordToken(email).then(() => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            notification.ref.show({ message: 'Password reset email has been sent.' });
            Actions.signInEmail();
        }).catch(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            notification.ref.show({ message: response.data.error, isError: true });
        });
    }
}
