import * as settingActionsConstants from '../constants/actions/settingAction';

const initialState = {
  defaultValues: {},
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case settingActionsConstants.SET_DEFAULT_SETTINGS:
      return {
        ...state,
        defaultValues: action.payload.permissions,
      };
    
    default:
      return state;
  }
};

export default settingsReducer;
