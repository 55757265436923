import React from 'react';
import {
  Image,
  View
} from 'react-native';

import PrimaryButton from '../PrimaryButton/PrimaryButton';
import styles from './styles';
import Text from '../../components/Text/Text';
import Actions from '../../RouteActions';

const Header = () => {

  return (
    <View style={[styles.publicHeader]}>
      <View style={[styles.organizationDetailsContainer]}>
        <Image source={{
          uri: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAEXRFWHRTb2Z0d2FyZQBwbmdjcnVzaEB1SfMAAABQSURBVGje7dSxCQBACARB+2/ab8BEeQNhFi6WSYzYLYudDQYGBgYGBgYGBgYGBgYGBgZmcvDqYGBgmhivGQYGBgYGBgYGBgYGBgYGBgbmQw+P/eMrC5UTVAAAAABJRU5ErkJggg==',
        }} style={styles.logoImage} />
        <Text style={styles.organizationName}>
            Organization name
        </Text>
      </View>

      <View />

      <View style={[styles.buttonsContainer, styles.buttonsContainerFlex]}>
        <PrimaryButton
            style={styles.commonButton}
            onPress={() => Actions.signUp()}
            small={true}
            white={true}
        >
            Sign Up
        </PrimaryButton>
        <PrimaryButton
            onPress={() => Actions.signInEmail()}
            style={[styles.loginButton, styles.commonButton]}
            small={true}
            white={true}
        >
            Log In
        </PrimaryButton>
      </View>
    </View>
  );
};

export default Header;
