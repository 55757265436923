import React, { Component } from 'react';
import {
    View,
} from 'react-native';

import Actions from '../../RouteActions';


//components
import FinishOnboardingBase from '../../components/FinishOnboardingBase/FinishOnboardingBase';
import Text from '../../components/Text/Text';
import FollowCompaniesComponent from '../../components/FollowCompaniesComponent/FollowCompaniesComponent';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

//styles
import styles from './styles';

//images

class FollowCompanies extends Component {
    render() {
        return (
            <FinishOnboardingBase title="Follow Companies">
                <View style={styles.childrenWrapper}>
                    <Text style={styles.pageTitle}>
                        Follow companies to learn {'\n'}about their latest openings
                    </Text>
                    <FollowCompaniesComponent hideEmptyListNotification />
                    <PrimaryButton style={styles.doneButton} onPress={Actions.home}>Done</PrimaryButton>
                </View>
            </FinishOnboardingBase>
        );
    }
}

export default FollowCompanies;
