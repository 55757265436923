import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';
import Cookies from 'js-cookie';
import baseService from '../../services/baseService';
import {getUserConnections} from '../../globalActions/networkActions';
import { notification } from '../../helperFunctions';
import { SET_SPINNER_HIDDEN } from '../../constants/actions/appActions';

export function unsubscribeGoogle(accessToken) {
    return (dispatch) => {
        dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE});
        authService.unsubscribeGoogle(accessToken).then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE_SUCCESS});
        }).catch(error => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_GOOGLE_ERROR});
        });
    };
}

export function unsubscribeLinkedin() {
    return (dispatch) => {
        authService.unsubscribeLinkedin().then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_LINKEDIN});
            notification.ref.show({message: 'Linkedin disconnected succesfully'});
        }).catch(error => {
            notification.ref.show({message: error, isError: true});
        });
    };
}

export function unsubscribeOutlook(accessToken) {
    return (dispatch) => {
        authService.unsubscribeOutlook(accessToken).then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_OUTLOOK});
            notification.ref.show({message: 'Outlook disconnected succesfully'});
        }).catch(error => {
            notification.ref.show({message: error, isError: true});
        });
    };
}

export function unsubscribeFacebook(accessToken) {
    return (dispatch) => {
        authService.unsubscribeFacebook(accessToken).then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_FACEBOOK});
            notification.ref.show({message: 'Facebook disconnected succesfully'});
        }).catch(error => {
            notification.ref.show({message: error, isError: true});
        });
    };
}

export function unsubscribeTwitter(accessToken) {
    return (dispatch) => {
        authService.unsubscribeTwitter(accessToken).then(() => {
            dispatch({type: authActionsConstants.UNSUBSCRIBE_TWITTER});
            notification.ref.show({message: 'Twitter disconnected succesfully'});
        }).catch(error => {
            notification.ref.show({message: error, isError: true});
        });
    };
}

export function subscribeGoogle() {
    return (dispatch) => {
        dispatch({type: authActionsConstants.SUBSCRIBE_GOOGLE});
    };
}

export function logout() {
    return (dispatch) => {
        dispatch({type: authActionsConstants.LOGOUT});
        baseService.removeAuthToken()
        const env = process.env.REACT_APP_ENVIRONMENT || 'development';
        Cookies.remove(`X-User-Token-${env}`);
        Cookies.remove(`currentOrgIdentifier-${env}`);
        Cookies.remove('expirationDate');
    };
}

export const setSpinnerHidden = () => {
    return (dispatch) => {
        dispatch({ type: SET_SPINNER_HIDDEN });
    }
}
