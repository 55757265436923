import { StyleSheet, PixelRatio } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  publicFooter: {
    height: 65,
    backgroundColor: "#FFF",
    boxShadow: "0px -2px 4px rgba(147, 157, 177, 0.1)",
    width: 1440,
    marginTop: 20,
    marginRight: "auto",
    marginBottom: 0,
    display: "grid",
    gridTemplateColumns: "20% 60% 20%",
    marginLeft: "auto",
  },
  buttonText: {
    fontFamily: "Roboto-Regular",
    fontSize: 13,
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.5)",
  },
  commonButton: {
    borderRadius: 0,
    borderColor: "transparent",
  },
  buttonsContainerFlex: {
    paddingRight: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  logoImage: {
    height: 30,
    width: 50,
  },
  copyrightContainer: {
    paddingLeft: 20,
    flexDirection: "column",
    justifyContent: "center",
  },
  copyrightText: {
    fontFamily: "Roboto-Regular",
    fontWeight: "500",
    fontSize: 13,
    color: "rgba(0, 0, 0, 0.5)",
  },
});
