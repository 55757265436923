import React, { Component } from 'react';
import {
    View,
    TouchableOpacity,
    Image
} from 'react-native';

import styles from './styles';

//components
import Text from '../Text/Text';
import GlobalGradient        from "../../components/GlobalGradient/GlobalGradient";
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

export default class FinishOnboardingBase extends Component {
    render() {
        const { children, mobile, mainHeading, subHeading } = this.props;

        return (
            <GlobalGradient  gradients={['#32CF6C', '#2AAA89']} angle='90deg'>
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={[styles.pageWrapper]}
                    enableAutomaticScroll>
                    <View style={[styles.body]}>
                      <View style={styles.flexCenter}>
                        <View style={mobile ? styles.mobileLogoWrapper : styles.logoWrapper}>
                        </View>
                        <Text style={mobile ? styles.mobileBoonHeading : styles.boonHeading}>{mainHeading}</Text>
                        <View>
                            <Text style={mobile ? styles.mobileSignupText : styles.signupText}>{subHeading}</Text>
                        </View>
                      </View>
                      <View style={styles.flexCenter}>
                        <View style={mobile ? styles.mobileSignUpForm : styles.signUpForm}>
                          {this.props.children}
                        </View>
                      </View>
                    </View>
                </KeyboardAwareScrollView>
            </GlobalGradient>
        );
    }
}
