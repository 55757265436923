import React from 'react';
import UserOrganizationsDrawer from './components/Drawer/UserOrganizationsDrawer';
import BrowseFiltersDrawer from './components/Drawer/BrowseFilters';
import Refer from './screens/Refer/Refer.js';
import Settings from './screens/Settings/Settings.js';
import ApplyJob from './screens/ApplyJob/ApplyJob.js';
import ConnectContacts from './screens/ConnectContacts/ConnectContacts.js';
import Notifications  from './screens/Notifications/Notifications';
import ChangePassword from './screens/ChangePassword/ChangePassword.js';
import EditProfile from './screens/EditProfile/EditProfile.js';
import DeclineJobDrawer from './components/Drawer/DeclineJobDrawer';
import UploadCSVContacts from './screens/UploadCSVContacts/UploadCSVContacts';
import ConnectLinkedin from './screens/ConnectLinkedin/ConnectLinkedin';
import UploadLinkedInContacts from './screens/UploadLinkedInContacts/UploadLinkedInContacts';
import ImportCSVContacts from './screens/ImportCSVContacts/ImportCSVContacts'

export const drawerComponents = {
    browseFiltersDrawer: (props) => <BrowseFiltersDrawer initialValues={props} />,
    userOrganizationsDrawer: (props) => <UserOrganizationsDrawer {...props} />,
    referFormDrawer: (props) => <Refer initialValues={props} />,
    settingsDrawer: (props) => <Settings {...props} />,
    changePasswordDrawer: (props) => <ChangePassword {...props} />,
    jobApplyDrawer: (props) => <ApplyJob initialValues={props} />,
    connectContactsDrawer: (props) => <ConnectContacts {...props} />,
    notificationsDrawer: (props) => <Notifications {...props} />,
    userProfileDrawer: (props) => <EditProfile {...props}/>,
    declineJobDrawer: (props) => <DeclineJobDrawer {...props}/>,
    importContactsDrawer: (props) => <UploadCSVContacts {...props}/>,
    connectLinkedInDrawer: (props) => <ConnectLinkedin {...props} />,
    uploadLinkedInContactsDrawer: (props) => < UploadLinkedInContacts {...props} />,
    csvContactsDrawer: (props) => <ImportCSVContacts {...props} />,
};
