import * as activityActionsConstants from '../../constants/actions/activityActions';
import * as authActionsConstants from '../../constants/actions/auth';

export function changeSelectedOrganization(organizationId) {
    return dispatch => {
        dispatch({type: activityActionsConstants.CHANGE_SELECTED_ORGANIZATION_HOME, payload: organizationId});
    };
}

export function changeUserPermission(changedPermission) {
    return dispatch => {
        dispatch({type: authActionsConstants.CHANGE_PERMISSION_STATUS, payload: changedPermission});
    };
}