import React from 'react';
import { View, Image, ImageBackground, ScrollView, Dimensions } from 'react-native';
import { connect } from 'react-redux';

//images
import logo from '../../assets/img/logo.png';

//styles
import styles from './styles';

const AuthLayout = (props) => {
    const { children } = props;
    const isMobile = Math.round(Dimensions.get('window').width) < 750

    return (
        <ScrollView keyboardShouldPersistTaps="handled">
            <View style={styles.root}>
                <View
                    style={{
                        background:
                            'linear-gradient(135deg, #32CF6C 1.88%, #2AAA89 100%)',
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}
                ></View>
                <ImageBackground
                    source={require('../../assets/img/auth_background.png')}
                    style={isMobile? styles.imageBackgroundMobile : styles.imageBackground}
                >
                    <Image source={logo} style={isMobile ? styles.logoMobile : styles.logoWeb} />
                    {children}
                    <View></View>
                </ImageBackground>
            </View>
        </ScrollView>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
