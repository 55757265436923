import { StyleSheet } from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    userAvatar: {
        height: 50,
        width: 50,
        borderRadius: 20
    },
    orgAvatar: {
        height: 50,
        width: 50,
        borderRadius: 20
    },
    subImage: {
        height: 20,
        width: 20,
        position: 'absolute',
        bottom: 4,
        right: 4,
        borderRadius: 8
    },
    actionButton: {
        height: 30,
        marginRight: 8,
        paddingHorizontal: 8,
        paddingVertical: 0,
        width: 'auto'
    },
    elementWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: '#DFE0E2'
    },
    imageWrapper: {
        paddingVertical: 0,
        paddingLeft: 5,
        position: 'relative',
        justifyContent: 'center'
    },
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        color: 'black',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display:'block'
    },
    timestampWrapper: {
        paddingHorizontal: 10
    }
});
