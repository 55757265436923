import { StyleSheet, Image } from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    childrenWrapper: {
        paddingHorizontal: 16,
        paddingTop: 50
    },
    pageTitle: {
        textAlign: 'center',
        fontSize: 20
    },
    image: {
        height: 100,
        width: 180,
        alignSelf: 'center',
        marginTop: 32,
        marginBottom: 16
    },
    description: {
        fontSize: 12,
        textAlign: 'center',
        color: '#748582',
        marginBottom: 55
    }
});