import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    newCompany: {
        borderBottomWidth: 0
    },
    parent:{
        width: '100%',
        marginBottom: 10
    }
});
