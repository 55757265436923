import { GET_SIGNED_IN_USER } from '../../constants/actions/auth';
import Actions from '../../RouteActions';
import * as authService from '../../services/authService';
import baseService from '../../services/baseService';
import { redirectToCurrentStep } from '../../services/stepsService'
import { getDeviceFingerprint, getPushToken, setAccessToken } from '../../AsyncStorage';
import responseCodes from '../../constants/responseCodes';
import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';

export const getSignedInUser = (token) => (dispatch, getState) => {
    dispatch({type: GET_SIGNED_IN_USER});
    const { organizationDetails } = getState().organizationsReducer;

    getDeviceFingerprint().then(deviceFingerprint => {
        getPushToken().then(() => {
            authService.getSignedInUser({
                'token': token,
                'device_fingerprint': deviceFingerprint
            }).then(response => {
                const { access_token, organizations, current_incomplete_step} = response.data.payload;
                baseService.addAuthToken(access_token).then(() => {
                    dispatch({ type: authActionsConstants.SIGN_IN_SUCCESS, payload: response.data.payload });
                    if (response.data.payload.access_token){
                        setAccessToken(response.data.payload.access_token);
                    }
                    if (organizationDetails.id) {
                        const currentSelectedOrg = {
                            label: organizationDetails.name,
                            value: organizationDetails.name,
                            id: organizationDetails.id,
                            logo: organizationDetails.logo_image_thumb
                        };
                        dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                    } else if (organizations.length > 0) {
                        const org = organizations[0].organization;
                        const currentSelectedOrg = { label: org.name, value: org.name, id: org.id, logo: org.logo_image_thumb };
                        dispatch({ type: authActionsConstants.SET_CURRENT_SELECTED_ORG, payload: currentSelectedOrg })
                    }

                    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                    dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: current_incomplete_step });
                    Actions.home({}, true);
                });
            })
        });
    })
};
