import React from 'react';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';

import './style.css';
class PhoneNumberInput extends React.Component {
    render() {
        const {
            textProps,
            onChangePhoneNumber,
            value,
            autoFocus,
            initialCountry,
            label
        } = this.props;
        return (
            <PhoneInput
                specialLabel={label}
                value={value}
                autoFocus={autoFocus}
                onChange={onChangePhoneNumber}
                onFocus={textProps.onFocus}
                onBlur={textProps.onBlur}
                country={initialCountry}
                enableSearch
                inputStyle={{
                    width: '100%',
                    borderStyle: 'none',
                }}
                containerStyle={{
                    borderStyle: 'none',
                    width: '100%'
                }}
                searchStyle={{
                    border: 'none',
                }}
                searchPlaceholder={null}
                searchNotFound={null}
            />
        );
    }
}

export default PhoneNumberInput;
