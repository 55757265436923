import moment from 'moment';
import React, { Component } from 'react';
import {
    Image,
    Platform, View
} from 'react-native';
//services
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
//images
import linkedinConnections from '../../assets/img/get_linkedin_connections.svg';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
//components
import Text from '../../components/Text/Text';
//actions
import { closeDrawer, openConnectContactsDrawer } from '../../DrawerActions.js';
import { importLinkedInContacts } from '../../globalActions/networkActions.js';
import { listElement } from '../../generalStyles';
import * as actions from './actions';
//styles
import styles from './styles';


class ConnectLinkedin extends Component {
    constructor(props) {
        super(props);
    }

    handleButtonPress = (e) => {
        e.preventDefault();
        this.props.openConnectContactsDrawerWithCountDown(this.props.currentUser.id);

        window.open('https://www.linkedin.com/psettings/member-data');
    }

    render() {
        const web = Platform.OS === 'web';

        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Adding your LinkedIn connections' />
                <View style={{ ...listElement.groupWrapper, width: '80%', alignSelf: 'center' }}>
                    <View style={styles.elementWrapper}>
                        <Text style={styles.text}>Select “Connections” from the "Pick and choose" option.</Text>
                    </View>
                    <View style={styles.imageWrapper}>
                        <Image style={styles.image} source={linkedinConnections} />
                    </View>
                    <View style={styles.elementWrapper}>
                        <Text style={styles.text}>Click “Request Archive” and the download will be ready in about 10 minutes.</Text>
                    </View>
                    <View style={styles.elementWrapper}>
                        <Text style={styles.text}>We will notify you when your download is ready. You can continue to use Boon while waiting.</Text>
                    </View>
                    <View style={[styles.elementWrapper, { paddingHorizontal: "25%" }]}>
                        <PrimaryButton style={styles.button} onPress={this.handleButtonPress}>
                            <View>
                                <Text style={styles.buttonText}>Go to LinkedIn</Text>
                            </View>
                        </PrimaryButton>
                    </View>

                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser
    }
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
        openConnectContactsDrawerWithCountDown: (id) => {
            dispatch(closeDrawer());
            dispatch(importLinkedInContacts(id))
            dispatch(actions.setDownloadTime(moment()));
            dispatch(openConnectContactsDrawer(true));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ConnectLinkedin));
