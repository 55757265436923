import { StyleSheet, Image } from 'react-native';

import { $green } from '../../../generalStyles';

export default StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: 50
    },
    image: {
        height: 47,
        width: 47,
        marginTop: -7
    }
});
