import React from 'react';
import BoonTextField from '../Basic/BoonTextField';

export default function FormInputNew(props) {
  const { input, ...inputProps } = props;

  return (
    <BoonTextField
      {...inputProps}
      onChange={input.onChange}
      value={input.value ? input.value.name ? input.value.name : input.value.label : ''}
    />
  );
}
