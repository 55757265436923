import React, {useEffect} from 'react'
import {useDropzone} from 'react-dropzone'
import Uploadimage from '../../assets/svg/uploadimage.jsx';
import { notification } from '../../helperFunctions.js';
import './ReactDropZone.css'

const ReactDropZone = ({setFilesArray,filesArray}) => {
  const {getRootProps, getInputProps , acceptedFiles} = useDropzone(
    {accept: 'application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword'})
  
  useEffect(() => {
    let totalFiles = [...filesArray, ...acceptedFiles]
    if(totalFiles.length > 5){
      setFilesArray(totalFiles.slice(0,5),'filesUploaded')
    return notification.ref.show({ message: 'You are not allowed to upload more than 5 files', isError: true });
    }
      setFilesArray(totalFiles,'filesUploaded')
  }, [acceptedFiles])
  

  return (
    <div className='react-dropzone-wrapper' {...getRootProps()}>
      <input {...getInputProps()} />
      <Uploadimage />
    </div>
  )
}

export default ReactDropZone
