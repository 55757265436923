import { StyleSheet, Dimensions, PixelRatio } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    container: {
        flex: 1
    },
    tabBar: {
        flexDirection: 'row',
        width: 350 / PixelRatio.get(),
    },
    tabButtonMobileStyle: {
        width: Dimensions.get('window').width / 3,
        backgroundColor: '#fff'

    },
    tabButtonStyle: {
        paddingVertical: 15,
        paddingHorizontal: 10,
        marginHorizontal: 0,
        flexGrow: 1,
        flexShrink: 0,
    },
    tabButtonActive: {
        borderBottomColor: $green,
        borderBottomWidth: 2,
    },
    tabButtonTextStyle: {
        color: '#B7B7B7',
        fontSize: 14,
        textAlign: 'center'
    },
    settingsIcon: {
        height: 20,
        width: 20
    },
    dropdownStyleMobile: {
        backgroundColor: 'transparent',
        paddingRight: 10,
        borderRadius: 5,
        fontSize: 18,
        color: '#18332F',
        fontWeight: 'normal',
    },
});
