import * as authService from '../../services/authService';
import { getDeviceFingerprint } from '../../AsyncStorage';
import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import * as redirectActions from '../../globalActions/redirectActions';
import Actions from '../../RouteActions';
import { notification } from '../../helperFunctions';
import { Platform } from 'react-native';
import { redirectToCurrentStep } from '../../services/stepsService';
import * as responseCode from '../../constants/responseCodes';

export function requestMagicLink(email, successCallback, errorCallback) {
    return (dispatch) => {
        let from_mobile = undefined;
        if (Platform.OS != 'web') {
            from_mobile = true;
        } else {
            from_mobile = false;
        }
        dispatch({ type: authActionsConstants.GET_MAGIC_LINK });
        getDeviceFingerprint().then((deviceFingerprint) => {
            authService
                .getMagicLink({
                    device_fingerprint: deviceFingerprint,
                    email: email,
                    from_app: 'true',
                    from_mobile: from_mobile
                })
                .then((res) => {
                    if (res.data.response_code === 0) {
                        if (successCallback) {
                            successCallback();
                        }
                        dispatch({
                            type: authActionsConstants.SET_MAGIC_LINK_DATA,
                            payload: res.data
                        });
                    } else {
                        if (errorCallback) {
                            errorCallback(res.data.error);
                        } else {
                            notification.ref.show({
                                message: res.data.error,
                                isError: true
                            });
                        }
                    }
                })
                .catch((error) => {
                    dispatch({
                        type: authActionsConstants.SET_MAGIC_LINK_DATA,
                        payload: error.data
                    });
                });
        });
    };
}

export function requestResetPassword(email) {
    return (dispatch) => {
        dispatch({ type: authActionsConstants.GET_MAGIC_LINK });
        getDeviceFingerprint().then((deviceFingerprint) => {
            authService
                .getresetPasswordToken({
                    device_fingerprint: deviceFingerprint,
                    email: email,
                    from_app: 'true'
                })
                .then((res) => {
                    dispatch({
                        type: authActionsConstants.SET_MAGIC_LINK_DATA,
                        payload: res.data
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: authActionsConstants.SET_MAGIC_LINK_DATA,
                        payload: error.data
                    });
                });
        });
    };
}

export function requestConfirmationLink(email) {
    return (dispatch) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        dispatch({ type: authActionsConstants.GET_MAGIC_LINK });
        getDeviceFingerprint().then((deviceFingerprint) => {
            authService
                .resendAccountConfirmation({
                    device_fingerprint: deviceFingerprint,
                    email: email,
                    from_app: 'true'
                })
                .then((res) => {
                    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                    dispatch({
                        type: authActionsConstants.SET_MAGIC_LINK_DATA,
                        payload: res.data
                    });
                })
                .catch((error) => {
                    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                    dispatch({
                        type: authActionsConstants.SET_MAGIC_LINK_DATA,
                        payload: error.data
                    });
                });
        });
    };
}

export function setSpinnerVisible() {
    return (dispatch) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    };
}

export function setSpinnerHidden() {
    return (dispatch) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
    };
}

export function submitConfirmationCode(
    formValues,
    membership_approval_required
) {
    return (dispatch) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        getDeviceFingerprint().then((deviceFingerprint) => {
            authService
                .submitConfirmationCode({
                    device_fingerprint: deviceFingerprint,
                    verification_code: formValues,
                    from_app: 'true'
                })
                .then((res) => {
                    dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
                    let accessFlag = true;
                    if (
                        res.data.response_code ===
                        responseCode.default.AUTH_FAILED
                    ) {
                        accessFlag = false
                        notification.ref.show({
                            message: 'Invalid confirmation code',
                            isError: true
                        });
                    } else {
                        notification.ref.show({
                            message:
                                'Verification code has been submitted successfully',
                            isError: false
                        });
                    }

                    if (accessFlag && membership_approval_required && res.data.payload.current_incomplete_step === 'home') {
                        redirectToCurrentStep('request_access');
                        dispatch({type: stepsActionsConstants.GET_STEP_SUCCESS, payload: 'process completed'})
                    } else {
                        if (
                            res.data.payload.current_incomplete_step &&
                            res.data.payload.current_incomplete_step !==
                                'confirm_email'
                        ) {
                            dispatch({type: stepsActionsConstants.GET_STEP_SUCCESS, payload: res.data.payload.current_incomplete_step})
                            redirectToCurrentStep(
                                res.data.payload.current_incomplete_step
                            );
                        }
                    }
                })
                .catch((response) => {
                    notification.ref.show({
                        message: response.data.error,
                        isError: true
                    });
                });
        });
    };
}

export function updateStepsOnBackButton(params) {
    return (dispatch) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.updateStepsOnBackButton(params).then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({
                type: stepsActionsConstants.GET_STEP_SUCCESS,
                payload: response.data.payload.current_incomplete_step
            });
            redirectToCurrentStep(
                response.data.payload.current_incomplete_step
            );
        });
    };
}
