import {StyleSheet} from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    buttonStyle: {
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
        borderWidth: 1,
        paddingVertical: 7,
        borderColor: '#D1D8DC',
        flexDirection: 'row'
    },
    secondary: {
        borderColor: '#D1D8DC',
        backgroundColor: '#f7f9fa',
        borderWidth: 1,
    },
    text: {
        fontSize: 14,
        fontWeight: 'bold',
        color: $green
    },
    textSecondary: {
        color: '#AAAAAA'
    },
    icon: {
        marginRight: 5,
        height: 16,
        width: 16,
    },
    endIcon: {
        marginLeft: 5,
        height: 16,
        width: 16,
    }
});
