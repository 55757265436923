import React from 'react';
import { View, Image } from 'react-native';

import Text from '../../../../components/Text/Text';

import UnavailableIcon from '../../../../assets/svg/unavailable-icon.svg';

const styles = {
  container: {
    display: "flex",
    height: "40vh",
    justifyContent: "center",
    gap: "20px",
    backgroundColor: "white"
  },
  content: {
    display: "flex",
    alignItems: "center",
    gap: "20px"
  },
  header: {
    fontSize: "16px",
    fontWeight: "600"
  },
  details: {
    textAlign: "center"
  }
}

const OpeningUnavailable = () => {

  return (
    <View style={styles.container}>
      <View>
        <Image
          style={{ height: 42 }}
          source={UnavailableIcon}
          resizeMode="contain"
        />
      </View>
      <View style={styles.content}>
        <Text style={styles.header}>
          Sorry, this page couldn&apos;t be found
        </Text>
        <Text style={styles.details}>
          The opening may no longer be available -{'\n'}
          employers hire 2x faster with Boon!
        </Text>
      </View>
    </View>
  );
};

export default OpeningUnavailable;
