import React, { Component } from 'react';
import { TouchableOpacity } from 'react-native';
import { getAccessToken } from '../../AsyncStorage';
import baseService from '../../services/baseService';

class BrowseLink extends Component {
    saveToken = (url) => {
        getAccessToken().then((token) => {
            baseService.addAuthToken(token).then(() => {
            window.open(url)
            });
        })
    }
    render() {
        const { url, wrapperStyle, children } = this.props;
        let wrapperStyles = wrapperStyle ? wrapperStyle : {}

        wrapperStyles = {
            textDecoration: 'none',
            ...wrapperStyles
        }

        return (
            <TouchableOpacity>
                <a style={wrapperStyles} onClick={() => this.saveToken(url)}>
                    {children}
                </a>
            </TouchableOpacity>
        );
    }
}

export default BrowseLink;
