import * as browseActionsConstants from '../../../../constants/actions/browseActions';
import * as browseService from '../../../../services/browseService';
import { notification } from '../../../../helperFunctions';
import * as appActionsConstants from '../../../../constants/actions/appActions';
import * as referActionsConstants from '../../../../constants/actions/referActions';
import * as refersService from '../../../../services/refersService';
import RouteHistory from '../../../../RouteHistory';
import { urls } from '../../../../urls';
import { getOrganizationDetailsByGeneralSettingId } from '../../../../services/organizationsService';

export function getAll(params, organization, init) {
  return async (dispatch, getState) => {
    const state = getState();
    if (!organization) {
      organization = state.organizationsReducer.organizationDetails;
    }

    let organizationId = null;

    dispatch({ type: browseActionsConstants.GET_BROWSE_ALL, payload: { init, type: params.type } });

    const dynamicOrg = sessionStorage.getItem('dynamicOrg');

    if (dynamicOrg) {
      try {
        const {
          data: { payload },
        } = await getOrganizationDetailsByGeneralSettingId(dynamicOrg);
        organizationId = payload.organization.id;
      } catch (error) {
        dispatch({ type: browseActionsConstants.GET_BROWSE_ALL_ERROR });
      }
    } else {
      const checkUser = Object.keys(state.authReducer.currentUser);
      organizationId = checkUser.length > 0 ? state.authReducer.currentSelectedOrg.id : organization && organization.id;
    }
    const { type, search_term, location, selectFirstOpening } = params;

    browseService
      .getJobs(params, organizationId)
      .then((response) => {
        const jobs = response.data.payload.result;
        dispatch({
          type: browseActionsConstants.GET_BROWSE_ALL_SUCCESS,
          payload: response.data.payload,
        });
        if ((search_term || location.place_id || selectFirstOpening) && jobs.length > 0) {
          const firstJob = jobs[0];
          if (type === 'all') {
            RouteHistory.push(`${urls.browseAll}/${firstJob.category}s/${firstJob.uuid}`);
          } else {
            RouteHistory.push(`/browse/${firstJob.category}s/${firstJob.uuid}`);
          }
        }
      })
      .catch(() => {
        dispatch({ type: browseActionsConstants.GET_BROWSE_ALL_ERROR });
      });
  };
}

export function getAllBySort(sortBy, filters) {
  return (dispatch, getState) => {
    const state = getState();
    let checkUser = Object.keys(state.authReducer.currentUser);
    const organizationId =
      checkUser.length > 0
        ? state.authReducer.currentSelectedOrg.id
        : state.organizationsReducer.organizationDetails.id;
    dispatch({ type: browseActionsConstants.SORT_JOBS });
    browseService
      .getAllBySort(sortBy, filters, organizationId)
      .then((response) => {
        dispatch({
          type: browseActionsConstants.GET_BROWSE_ALL_SUCCESS,
          payload: response.data.payload,
        });
      })
      .catch(() => {
        dispatch({ type: browseActionsConstants.GET_BROWSE_ALL_ERROR });
      });
  };
}

export function getCandidateData(data) {
  return (dispatch) => {
    dispatch({ type: browseActionsConstants.GET_CANDIDATE_BROWSE_DATA });
    browseService
      .getCandidateData(data)
      .then((response) => {
        if (response.data.response_code === 0) {
          dispatch({
            type: browseActionsConstants.GET_CANDIDATE_BROWSE_DATA_SUCCESS,
            payload: response.data.payload,
          });
        }
      })
      .catch((response) => {
        dispatch({ type: browseActionsConstants.GET_CANDIDATE_BROWSE_DATA_ERROR });
        notification.ref.show({
          message: response.data.error,
          isError: true,
        });
      });
  };
}

export function getUserJobReferData(data) {
  return (dispatch) => {
    refersService
      .getReferDataByUserAndJob(data)
      .then((response) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({
          type: referActionsConstants.GET_REFER_JOB_SUCCESS,
          payload: response.data.payload,
        });
      })
      .catch((e) => {
        dispatch({
          type: referActionsConstants.GET_REFER_JOB_ERROR,
          payload: e.data.error,
        });
        notification.ref.show({ message: e.data.error, isError: true });
      });
  };
}

export function setBrowseFilter(type, filters) {
  dispatch({ type: SET_BROWSE_FILTERS, payload: filters });
}

export function clearBrowseFilter(type) {
  dispatch({ type: CLEAR_BROWSE_FILTERS, payload: {} });
}

export const getSocialShareLink = (openingId) => async () => {
  try {
    const response = await browseService.getSocialShareLink(openingId);
    if (response.data.response_code === 0) {
      return response.data.payload;
    }
  } catch(e) {
    notification.ref.show({ message: e.data.error, isError: true });
  }
};
