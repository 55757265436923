import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    FlatList,
    View,
    ScrollView,
    RefreshControl,
    Platform
} from 'react-native';

//components
import TheirOpportunityElement from '../../../../components/TheirOpportunityElement/TheirOpportunityElement';
import MyOpportunityElement from '../../../../components/MyOpportunityElement/MyOpportunityElement';
import Text from '../../../../components/Text/Text';

//actions
import * as trackerActions from '../../actions';

//styles
import styles from './styles';

//utils
import { getCurrentOrganizationForUser } from '../../../../helperFunctions';

class TrackerList extends PureComponent {
    constructor(props) {
        super(props);
        this.renderEmptyList = this.renderEmptyList.bind(this);
    }

    renderEmptyList() {
        const { getJobsTracker, status, direction } = this.props;

        const listLoading = this.props[`${status}ListLoading`];

        return direction === 'my' ? (
            <ScrollView
                refreshControl={
                    <RefreshControl
                        refreshing={listLoading}
                        onRefresh={() => getJobsTracker({ status, direction })}
                    />
                }
                contentContainerStyle={styles.emptyBox}
            >
                <Text style={styles.emptyTitle}>Nothing yet</Text>

            </ScrollView>
        ) : (
                <ScrollView
                    refreshControl={
                        <RefreshControl
                            refreshing={listLoading}
                            onRefresh={() => getJobsTracker({ status, direction })}
                        />
                    }
                    contentContainerStyle={styles.emptyBox}
                >
                    <Text style={styles.emptyTitle}>Nothing yet</Text>

                </ScrollView>
            );
    }

    render() {
        const { getJobsTracker, status, direction, currentUser } = this.props;
        const list = this.props[`${status}List`];
        const listLoading = this.props[`${status}ListLoading`];
        const isLastPage = this.props[`${status}IsLastPage`];
        const jobs = this.props[`${status}Jobs`]
        return (
            <View style={{ flex: 1 }}>
                {!list.length && this.renderEmptyList() ||
                    <FlatList
                        style={Platform.OS === 'web' && styles.trackerListContainer}
                        onRefresh={() => getJobsTracker({ status, direction })}
                        refreshing={listLoading}
                        data={list}
                        renderItem={({ item }) => direction === 'my' ? <MyOpportunityElement status={status} opportunity={item} currentUser={currentUser} /> : <TheirOpportunityElement status={status} opportunity={item} />}
                        keyExtractor={(item, index) => `${item.id}_${index}`}
                        onEndReached={() => {
                            if (!isLastPage && !listLoading) {
                                getJobsTracker({ status, direction, offset: jobs.length });
                            }
                        }}
                    />
                }
            </View>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        currentUser: state.authReducer.currentUser,
        currentUserOrganization: getCurrentOrganizationForUser(state),
        [`${props.status}List`]: state.trackerReducer[`${props.status}List`],
        [`${props.status}ListLoading`]: state.trackerReducer[`${props.status}ListLoading`],
        [`${props.status}IsLastPage`]: state.trackerReducer[`${props.status}IsLastPage`],
        [`${props.status}Jobs`]: state.trackerReducer[`${props.status}Jobs`],
        currentTrackerTab: state.trackerReducer.currentTrackerTab
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getJobsTracker: params => {
            dispatch(trackerActions.getJobsTracker(params));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackerList);
