import React, {Component} from 'react';
import Spinner            from 'react-native-loading-spinner-overlay';
import {connect}          from 'react-redux';
import Actions            from '../../RouteActions';

//components
import OnboardingBase from '../../components/OnboardingBase/OnboardingBase';

//actions
import * as actions from './actions';

//utils
import baseService       from '../../services/baseService';
import * as AsyncStorage from '../../AsyncStorage';

class InitialScreen extends Component {
    componentWillMount() {
        const { getCurrentUser } = this.props;
        AsyncStorage.getAccessToken().then(response => {
            baseService.addAuthToken(response).then(() => {
                getCurrentUser();
            });
        });
    }

    render() {
        const {signInLoading} = this.props;
        return (
            <OnboardingBase>
                <Spinner visible={signInLoading} />
            </OnboardingBase>
        );
    }
}

const mapStateToProps = state => {
    return {
        signInLoading: state.authReducer.signInLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentUser: () => {
            dispatch(actions.getCurrentUser()).then(() => {
                Actions.home();
            }).catch(() => {
                Actions.base();
            });
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InitialScreen);
