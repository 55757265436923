import React from 'react';
import { View, Image } from 'react-native';
import Text from '../../../../components/Text/Text';
import { CURRENCIES } from '../../../../constants/currenciesConstants';
import ReactTooltip from 'react-tooltip';
import { jobListElement } from '../../../../generalStyles';
import infoIcon from '../../../../assets/svg/info-icon.svg';
import PaymentsIcon from '../../../../assets/svg/PaymentsIcon.svg';
import GiftIcon from '../../../../assets/svg/GiftIcon.svg';
import { referralRowStyles } from './referralrewardrowstyles';

const ReferralRewardsRow = ({ installments, templateDescription }) => {
  return (
    <>
      {installments.map((installment) => (
        <div key={installment.id} style={referralRowStyles.flexContainer}>
          {/* Monetary Rewards section */}
          {installment.monetary_reward_attributes && (
            <div style={referralRowStyles.subContainer}>
              <div style={referralRowStyles.monetaryCircle}>
                <Image style={jobListElement.RewardIcon} source={PaymentsIcon} />
              </div>
              <div style={referralRowStyles.flexColumn}>
                <Text>
                  {installment.monetary_reward_attributes.currency_name &&
                    CURRENCIES[installment.monetary_reward_attributes.currency_name]}
                  {installment.monetary_reward_attributes.reward_value}
                </Text>
                <Text style={referralRowStyles.rewardValue}>{installment.guarantee_period}</Text>
              </div>
              {templateDescription && (
                <div
                  data-tip
                  data-iscapture="true"
                  data-for={installment.id.toString()}
                  style={referralRowStyles.iconColumn}
                >
                  <Image style={jobListElement.winnerIcon} source={infoIcon} />
                </div>
              )}
              <ReactTooltip
                id={installment.id.toString()}
                place="bottom"
                effect="solid"
                border
                borderColor="#A8A8A8"
                type="light"
                className="rewards-description-tooltip"
              >
                {templateDescription.split(/\r?\n/).map((line, index) => line && <li key={index}> {line}</li>)}
              </ReactTooltip>
            </div>
          )}
          {/* experiential Rewards section */}
          {installment.experiential_rewards_attributes.map((exp_reward) => (
            <div key={exp_reward.id} style={referralRowStyles.expRewardSection}>
              <div style={referralRowStyles.expCircle}>
                <Image style={jobListElement.RewardIcon} source={GiftIcon} />
              </div>
              <div style={referralRowStyles.flexColumn}>
                <Text>{exp_reward.name}</Text>
                <Text style={referralRowStyles.rewardValue}>{installment.guarantee_period}</Text>
              </div>
              {exp_reward.description && (
                <div
                  data-tip
                  data-iscapture="true"
                  data-for={exp_reward.id.toString()}
                  style={referralRowStyles.iconColumn}
                >
                  <Image style={jobListElement.winnerIcon} source={infoIcon} />
                </div>
              )}
              <ReactTooltip
                id={exp_reward.id.toString()}
                effect="solid"
                place="bottom"
                border
                borderColor="#A8A8A8"
                className="rewards-description-tooltip"
                type="light"
              >
                <ul>
                  {exp_reward.description.split(/\r?\n/).map((line, index) => line && <li key={index}> {line}</li>)}
                </ul>
              </ReactTooltip>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default ReferralRewardsRow;
