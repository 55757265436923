import React, { useEffect, useState } from 'react';
import { Image, Text, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import Spinner from 'react-native-loading-spinner-overlay';
import { connect } from 'react-redux';
import { parse } from 'search-params';

import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import logo from '../../assets/img/logo.png';
import { formLayout } from '../../generalStyles';
import Actions from '../../RouteActions';

import { redirectToCurrentStep } from '../../services/stepsService';

import * as actions from './actions';

import styles from './styles';

const Invite = ({
  inviteData,
  location,
  match,
  spinnerVisible,
  isMobile,
  getInviteData,
  onUpdateStatus,
  current_user,
  isAllowed,
  createAccessRequest,
  cancelAccessRequest,
  organization_id,
  currentUser,
  orgLogo,
  organizationName,
}) => {
  const mobile = isMobile();
  const identifier = (match && match.params && match.params.identifier) || '';
  const [continued_clicked, set_continued_clicked] = useState(false);
  const orgName =
    Object.keys(inviteData).length > 0 && inviteData.organization_name ? inviteData.organization_name : identifier;
  const invitedByAdmin = Object.keys(inviteData).length > 0 && inviteData.invited_by_name !== 'Administrator';
  useEffect(() => {
    const params = parse(location.search);
    getInviteData((params && params.invitation_token) || '');
  }, [location.search]);

  const handleSignUp = () => {
    if (invitedByAdmin) {
      if (location.search.includes('invitation_token') && Object.keys(inviteData).length > 0 && inviteData.user_data) {
        onUpdateStatus(inviteData.user_data.id, inviteData.organization_name);
      } else {
        Actions.signUp();
      }
    } else {
      Actions.signUp({
        orgName: identifier,
      });
    }
  };
  const handleAccessSubmit = () => {
    const data = {
      user_id: currentUser.id,
      organization_id: organization_id,
    };
    createAccessRequest(data);
  };

  const handleNeverMind = () => {
    const data = {
      user_id: currentUser.id,
    };
    cancelAccessRequest(data);
    redirectToCurrentStep('home');
  };

  return (
    <View style={mobile ? styles.elementWrapperMobile : styles.elementWrapper}>
      <View style={styles.headerBar}>
        {orgLogo ? (
          <Image source={orgLogo} style={styles.orgLogo} />
        ) : (
          <Text style={styles.companyInitials}>{organizationName ? organizationName[0] : ''}</Text>
        )}
        <Text style={styles.headerText}>{organizationName}</Text>
      </View>
      <View style={mobile ? styles.containerMobile : styles.container}>
        <View>
          <View style={styles.textContainer}>
            <Text style={[formLayout.row, mobile ? styles.inviteTextMobile : styles.inviteText]}>
              Sorry {current_user && current_user.full_name}, you don&apos;t have access to this referral community.
            </Text>
          </View>
          <View style={mobile ? styles.actionButtonNewMindContainerMobile : styles.actionButtonNewMindContainer}>
            <View style={mobile ? styles.buttonNeverMindWrapperMobile : styles.buttonNeverMindWrapper}>
              <TouchableOpacity
                //   onPress={() => Actions.signUp({ orgName: identifier })}
                onPress={handleNeverMind}
              >
                <Text style={mobile ? styles.acceptNeverMindTextMobile : styles.acceptNeverMindText}>Nevermind</Text>
              </TouchableOpacity>
            </View>

            <View style={mobile ? styles.buttonWrapperMobile : styles.buttonWrapper}>
              <TouchableOpacity onPress={() => handleAccessSubmit()}>
                <Text style={mobile ? styles.acceptTextMobile : styles.acceptText}>Request Access</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
      <Spinner visible={spinnerVisible} />
    </View>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: (state.form.invite && state.form.invite.values) || {},
    inviteData: state.authReducer.inviteData,
    spinnerVisible: state.appReducer.spinnerVisible,
    current_user: state.authReducer.currentUser,
    isAllowed: true,
    organization_id:
      (state.organizationsReducer.organizationId && state.organizationsReducer.organizationId.organization_id) ||
      (state.organizationsReducer &&
        state.organizationsReducer.organizationDetails &&
        state.organizationsReducer.organizationDetails.id),
    currentUser: state.authReducer.currentUser,
    orgLogo: state.organizationsReducer.organizationId
      ? state.organizationsReducer.organizationId.organization_logo
      : '',
    organizationName: state.organizationsReducer.organizationId
      ? state.organizationsReducer.organizationId.organization_name
      : '',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getInviteData: (token) => {
      dispatch(actions.getInviteData(token));
    },
    onUpdateStatus: (userId, orgName) => {
      dispatch(actions.updateUserStatus(userId, orgName));
    },
    createAccessRequest: (data) => {
      dispatch(actions.createOrgAccessRequest(data));
    },
    cancelAccessRequest: (data) => {
      dispatch(actions.cancelOrgAccessRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Invite));
