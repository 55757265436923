import { StyleSheet, PixelRatio } from 'react-native';

export default StyleSheet.create({
    logoWrapper: {
        flexShrink: 1,
        flexGrow: 0,
        paddingTop: 18,
        position: 'relative',
        width: '100%',
        alignItems: 'center'
    },
    logo: {
        width: 165,
        height: 48,
        flexGrow: 0,
        flexShrink: 1
    },
    backButton: {
        position: 'absolute',
        top: 40,
        left: 32
    },
    childrenWrapper: {
        flexGrow: 1,
        flexShrink: 1,
        paddingHorizontal: 16,
        paddingVertical: 36,
        width: '100%',
        justifyContent: 'center'
    },
    keyboardAwareContainer: {
        alignItems: 'stretch',
        justifyContent: 'center',
        flex: 1
    }
});
