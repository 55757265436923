import React, { useMemo, useState } from 'react';
import styles from './styles';
import {
    ImageBackground,
    View,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';
import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import FormInput from '../../components/FormInput/FormInput';
import { Field, reduxForm } from 'redux-form';
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
import { notification, validateEmail } from '../../helperFunctions';
import { formLayout } from '../../generalStyles';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import * as actions from './actions';
import BoonLogo from '../../assets/img/boon_logo.svg';

let LoginRequest = ({ formValues, onSubmit }) => {
    const [displaySuccess, setDisplaySuccess] = useState(false);

    const disabled = useMemo(
        () => !validateEmail(formValues.email),
        [formValues.email]
    );

    const handleContinue = () => {
        if (!formValues.email) {
            notification.ref.show({
                message: 'Email is required',
                isError: true
            });
        } else if (!validateEmail(formValues.email)) {
            notification.ref.show({
                message: 'Please enter valid email',
                isError: true
            });
        }
    };

    const successCallback = () => {
        setDisplaySuccess(true);
    };

    const submitForm = (e) => {
        e.preventDefault();
        onSubmit(formValues, successCallback);
    };

    const handleBack = () => {
        window.open('https://goboon.co', '_self');
    };

    return (
        <ImageBackground
            source={backgroundBoxImage}
            style={styles.elementWrapper}
        >
            <Image source={BoonLogo} style={styles.boonLogo} />
            <View style={styles.contentCont}>
                {displaySuccess ? (
                    <>
                        <Text style={styles.heading}>Got it!</Text>
                        <Text style={styles.subHeading}>
                            If your company has an active Boon account, we’ll
                            send you the link.
                        </Text>
                    </>
                ) : (
                    <>
                        <Text style={styles.heading}>
                            Find your company referral portal
                        </Text>
                        <Text style={styles.subHeading}>
                            We can email you the link.
                        </Text>
                        <Field
                            placeholder="Work Email"
                            name="email"
                            component={FormInput}
                            keyboardType="email-address"
                            labelStyle={styles.labelStyle}
                            inputStyle={styles.inputStyle}
                            onSubmitEditing={handleContinue}
                            wrapperStyle={[
                                formLayout.fullWidth,
                                styles.inputWrapper
                            ]}
                        />
                        <PrimaryButton
                            onPress={submitForm}
                            disabled={disabled}
                            style={
                                disabled ? styles.disabledButton : styles.button
                            }
                            textStyle={
                                disabled
                                    ? styles.disabledButtonText
                                    : styles.buttonText
                            }
                        >
                            Submit
                        </PrimaryButton>
                    </>
                )}
                <TouchableOpacity onPress={handleBack}>
                    <Text style={styles.backButton}>
                        {displaySuccess ? 'Home' : 'Back'}
                    </Text>
                </TouchableOpacity>
            </View>
        </ImageBackground>
    );
};

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.loginRequest && state.form.loginRequest.values
                ? state.form.loginRequest.values
                : {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values, successCallback) => {
            dispatch(actions.postLoginRequest(values.email, successCallback));
        }
    };
};

LoginRequest = reduxForm({ form: 'loginRequest' })(LoginRequest);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withGetScreen(LoginRequest));
