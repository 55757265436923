import EStyleSheet from 'react-native-extended-stylesheet';

export const $green = '#0FBC71';
export const $grey2 = '#AAAAAA';
export const $greish = '#AEAEAE';
export const $background = '#EAF0F3';
export const $greenish = '#0FBC71';
export const $activeBackground = '#989898';
export const $navyBlue = '#13517f';
export const $activeBackgroundSidebar = '#1a8557';

export const mainBody = {
  flex: 1,
  backgroundColor: $background,
};

export const common = {
  row: {
    flexDirection: 'row',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  justifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  flex1: {
    flex: 1,
  },
  flexWrap: {
    flexWrap: 'wrap',
  },
  textEllipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  w100: {
    width: '100%',
  },
  linkWrapper: {
    alignSelf: 'flex-start',
  },
};

export const hr = {
  borderBottomColor: $greish,
  borderBottomWidth: 1,
};

export const whiteBlock = {
  backgroundColor: '#fff',
  borderRadius: 8,
  shadowOffset: { height: 0, width: 30 },
  shadowRadius: 40,
  shadowColor: '#000',
  shadowOpacity: 0.15,
  width: '100%',
  padding: 16,
};

export const activityFlatListContainer = {
  maxHeight: 'calc(100vh - 82px - 48px - 58px)',
};

export const paddingLeftRightTwenty = {
  paddingLeft: 20,
  paddingRight: 20,
};

export const flatListContainer = {
  maxHeight: 'calc(100vh - 82px - 48px - 58px)',
};

export const headerFooterContainer = {
  maxHeight: 'calc(100vh - 82px - 58px)',
};

export const hidden = {
  display: 'none',
};

export const pt0 = {
  paddingTop: 0,
};

export const mar15 = {
  marginRight: 15,
};

export const mar10 = {
  margin: 10,
};

export const titleFont = {
  fontSize: 16,
  fontWeight: 'bold',
};

export const dashboardButton = {
  buttonWrapper: {
    flex: 1,
    position: 'relative',
    alignItems: 'flex-end',
  },
  linkText: {
    color: '#3d8ab1',
    fontWeight: 'bold',
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    marginVertical: -16,
    marginLeft: -32,
    marginRight: 8,
    flex: 0,
  },
  buttonRight: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    marginTop: -10,
    marginBottom: -16,
    marginLeft: 8,
    marginRight: -24,
  },
  image: {
    height: 15,
    width: 9,
  },
  unreadCountWrapper: {
    height: 18,
    paddingHorizontal: 5,
    borderRadius: 9,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: -4,
    width: 'auto',
    right: -15,
    backgroundColor: '#FFBF0D',
    borderWidth: 1,
    borderColor: '#0FBC71',
  },
  unreadCount: {
    fontSize: 10,
    color: '#fff',
    textAlign: 'right',
  },
};

export const drawerElement = {
  scrollElement: {
    flex: 1,
    paddingTop: 50,
    backgroundColor: '#22c66f',
    borderRadius: 0,
  },
  scrollReferElement: {
    flex: 1,
    paddingTop: 30,
    backgroundColor: $background,
    borderRadius: 0,
  },
  itemTextStyle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#fff',
  },
  listItemStyle: {
    backgroundColor: '#22c66f',
  },
  listStyle: {
    borderRadius: 0,
  },
  drawerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: $background,
  },
  titleText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  headerCloseButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  headerStyle: {
    position: 'relative',
    zIndex: 2,
    paddingTop: 20,
  },
};

export const listElement = {
  activeListElement: {
    border: '1px solid #0FBC71',
  },
  timestampText: {
    color: $greish,
  },
  emptyListWrapper: {
    alignItems: 'center',
    marginLeft: -200,
  },
  emptyListWrapperMobile: {
    alignItems: 'center',
  },
  emptyListImage: {
    height: 100,
    width: 100,
    marginTop: 36,
  },
  emptyTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#616161',
    marginTop: 28,
    marginBottom: 5,
  },
  emptyDescription: {
    fontSize: 12,
    color: '#616161',
    width: 230,
    textAlign: 'center',
    marginBottom: 36,
  },
  groupWrapper: {
    marginTop: 32,
  },
  groupTitle: {
    fontSize: 14,
    color: '#768987',
    fontWeight: 'bold',
    marginBottom: 7,
    paddingLeft: 16,
  },
  elementWrapper: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'stretch',
  },
  elementWrapperTopAligned: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    borderRadius: 5,
  },
  elementWrapperBackground: {
    backgroundColor: $background,
  },
  elementWrapperWithMinHeight: {
    minHeight: 80,
  },
  imageWrapper: {
    paddingVertical: 8,
    paddingLeft: 16,
    position: 'relative',
    justifyContent: 'center',
  },
  image: {
    height: 40,
    width: 40,
    marginRight: 12,
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#d8d8d8',
  },
  imageTransparent: {
    opacity: 0.7,
  },
  imageRounded: {
    borderRadius: 20,
  },
  subSmallImage: {
    height: 20,
    width: 20,
    borderRadius: 9,
    borderWidth: 2,
    borderColor: '#fff',
    zIndex: 2,
  },
  networkIcon: {
    height: 16,
    width: 16,
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  elementBody: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 16,
  },
  infoWrapper: {
    paddingBottom: 5,
    paddingTop: 5,
    paddingRight: 8,
    flex: 1,
  },
  jobInfoWrapper: {
    paddingTop: 0,
    paddingBottom: 5,
    paddingRight: 8,
    flex: 1,
  },
  last: {
    borderBottomColor: 'transparent',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
    color: '#505050',
  },
  description: {
    fontSize: 13,
    color: '#18332F',
    margin: 1,
  },
  subDescription: {
    fontSize: 10,
    color: '#a3adac',
  },
  tagsWrapper: {
    flexDirection: 'row',
    paddingTop: 5,
    paddingLeft: 18,
  },
  tag: {
    borderRadius: 3,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    backgroundColor: '#F2F2F2',
  },
  tagText: {
    fontSize: 12,
    color: '#444444',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  line: {
    borderColor: '#EEEEEE',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
  },
  referralRewardsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    paddingTop: 8,
    width: '100%',
    overflow: 'hidden',
  },
  winnerIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  referralReward: {
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    borderColor: '#B040C2',
    borderStyle: 'solid',
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 9,
    marginLeft: 4,
    marginRight: 4,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    maxWidth: 'calc(100% - 8px)',
  },
  referralRewardText: {
    fontSize: 12,
    color: '#000000',
    textAlign: 'center',
    maxWidth: '100%',
  },
  referralRewardPrice: {
    borderColor: '#0FBC72',
  },
  rightBlock: {
    alignItems: 'flex-end',
  },
  salaryChangedWrapper: {
    flexDirection: 'row',
  },
  salary: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
  },
  salaryGrey: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#D1D8DC',
  },
  salaryCrossed: {
    textDecorationLine: 'line-through',
  },
  timestampWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  timestampIcon: {
    height: 9,
    width: 9,
    marginRight: 4,
  },
  timestamp: {
    fontSize: 10,
    color: '#999999',
  },
  timestampRed: {
    fontSize: 12,
    color: '#E8818D',
    fontWeight: 'bold',
  },
  headquarterLocation: {
    opacity: 0.6,
  },
  nameAndSource: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
};

export const openingsListElement = {
  activeListElement: {
    border: '1px solid #0FBC71',
  },
  listItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fff',
    borderRadius: 5,
    paddingHorizontal: 10,
  },
  listItemBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '60px',
  },
  elementWrapperBackground: {
    backgroundColor: $background,
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  imageWrapper: {
    position: 'relative',
    justifyContent: 'center',
  },
  image: {
    height: 32,
    width: 32,
    marginRight: 10,
  },
  waterMark: {
    height: 40,
    width: 40,
    marginRight: 10,
    borderWidth: 0,
  },
  jobInfoWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#18332F',
  },
  last: {
    borderBottomColor: 'transparent',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
    color: '#505050',
  },
  description: {
    fontWeight: '500',
    fontSize: 12,
    lineHeight: 14,
    color: '#444444',
    opacity: '0.6',
  },
  subDescription: {
    fontSize: 10,
    color: '#a3adac',
  },
  tagsWrapper: {
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 18,
  },
  tag: {
    borderRadius: 3,
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    backgroundColor: '#F2F2F2',
  },
  tagText: {
    fontSize: 12,
    color: '#444444',
    textAlign: 'center',
  },
  hr: {
    borderColor: '#EEEEEE',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    width: '100%',
  },
  referralRewardsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    width: '100%',
    overflow: 'hidden',
  },
  winnerIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  referralReward: {
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    borderColor: '#B040C2',
    borderStyle: 'solid',
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 9,
    marginLeft: 4,
    marginRight: 4,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    maxWidth: 'calc(100% - 8px)',
  },
  referralRewardText: {
    fontSize: 12,
    color: '#000000',
    textAlign: 'center',
    maxWidth: '100%',
  },
  referralRewardPrice: {
    borderColor: '#0FBC72',
  },
  timestamp: {
    fontSize: 10,
    color: '#999999',
  },
};

export const jobListElement = {
  activeListElement: {
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: '#959595',
    borderWidth: 1,
  },
  listItem: {
    backgroundColor: '#fff',
    alignItems: 'flex-start',
    borderRadius: 5,
    padding: 10,
  },
  listItemIneligible: {
    backgroundColor: '#D9D9D9',
    alignItems: 'center',
    borderRadius: 5,
    padding: 10,
  },
  listItemBody: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  },
  elementWrapperBackground: {
    backgroundColor: $background,
  },
  opportunityWrapperBackground: {
    backgroundColor: 'white',
  },
  imageWrapper: {
    position: 'relative',
    justifyContent: 'center',
  },
  image: {
    height: 52,
    width: 52,
    marginRight: 10,
    borderRadius: 5,
    overflow: 'hidden',
  },
  waterMark: {
    height: 40,
    width: 40,
    marginRight: 10,
    borderWidth: 0,
  },
  jobInfoWrapper: {
    paddingTop: 0,
    paddingRight: 8,
    flex: 1,
  },
  last: {
    borderBottomColor: 'transparent',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  ineligibletext: {
    fontSize: 12,
  },
  bold: {
    fontWeight: 'bold',
    color: '#505050',
  },
  description: {
    fontSize: 13,
    color: '#18332F',
    margin: 1,
  },
  subDescription: {
    fontSize: 10,
    color: '#a3adac',
  },
  tagsWrapper: {
    flexDirection: 'row',
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 18,
    flexWrap: 'wrap',
    gap: 8,
  },
  tag: {
    borderRadius: 3,
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: '#F2F2F2',
  },
  tagText: {
    fontSize: 12,
    color: '#000000',
    textAlign: 'center',
  },
  hr: {
    borderColor: '#EEEEEE',
    borderTopStyle: 'solid',
    borderTopWidth: 1,
    width: '100%',
  },
  line: {
    borderColor: '#EEEEEE',
    borderTopStyle: 'solid',
    borderTopWidth: 0,
    width: '100%',
  },
  referralRewardsWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 8,
    width: '100%',
    overflow: 'hidden',
  },
  winnerIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  RewardIcon: {
    width: 22,
    height: 22,
  },
  referralReward: {
    backgroundColor: '#FFFFFF',
    borderRadius: 3,
    borderColor: '#B040C2',
    borderStyle: 'solid',
    borderWidth: 1,
    paddingVertical: 3,
    paddingHorizontal: 9,
    marginLeft: 4,
    marginRight: 4,
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    maxWidth: 'calc(100% - 8px)',
  },
  referralRewardText: {
    fontSize: 12,
    color: '#000000',
    textAlign: 'center',
    maxWidth: '100%',
  },
  referralRewardPrice: {
    borderColor: '#0FBC72',
  },
  timestamp: {
    fontSize: 10,
    color: '#999999',
  },
  organization_name: {
    fontWeight: 600,
    opacity: 0.6,
    color: 'black',
    fontSize: 12,
  },
};

export const formLayout = EStyleSheet.create({
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 16,
    position: 'relative',
    zIndex: -1,
  },
  noSpaceelementWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 16,
    position: 'relative',
  },
  borderStyles: {
    borderRadius: 5,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#CCCCCC',
  },
  marginStyles: {
    marginTop: 15,
  },
  zIndexedL1: {
    zIndex: 40,
  },
  zIndexedL2: {
    zIndex: 39,
  },
  zIndexedL3: {
    zIndex: 38,
  },
  noMarginRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: -10,
    position: 'relative',
  },
  coverButton: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: 10,
  },
  halfWidthLeft: {
    width: '45%',
    flexGrow: 0,
    flexShrink: 1,
  },
  halfWidthRight: {
    width: '45%',
    flexGrow: 0,
    flexShrink: 1,
  },
  fullWidth: {
    width: '100%',
  },
  signUpButton: {
    marginTop: 32,
  },
  textAreaWrapper: {
    marginBottom: 16,
  },
  textArea: {
    borderBottomWidth: 0,
    paddingTop: 16,
    paddingBottom: 16,
    paddingHorizontal: 16,
    minHeight: 104,
  },
  textAreaStyling: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#ccc',
    minHeight: 104,
  },
  textAreaBackground: {
    backgroundColor: $background,
    borderRadius: 5,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 37,
    marginBottom: 8,
    paddingLeft: 16,
    color: '#6c7f7d',
  },
  accordianListItem: {
    backgroundColor: $background,
  },
});
