import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    screenWrapper: {
        paddingHorizontal: 8
    },
    row: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        marginLeft: 30
    },
    webStyles: {
        paddingTop: 20,
        paddingBottom: 20,
        marginVertical: 10,
        marginHorizontal: 'auto',
        width: '50%'
    },
    screenDescription: {
        paddingHorizontal: 8,
        paddingTop: 18,
        paddingBottom: 30
    },
    permissionWrapper: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingLeft: 16,
        paddingRight: 16,
        backgroundColor: '#fff',
        marginBottom: 10,
        borderRadius: 5,
    },
    permissionIcon: {
        height: 30,
        width: 30,
        marginRight: 15
    },
    permissionTitle: {
        color: $green,
        marginBottom: 3,
        fontSize: 14
    },
    permissionDescription: {
        fontSize: 12,
    },
    confirmButton: {
        alignSelf: 'center',
        marginTop: 35,
    },
    importButtonContainer: {
        marginTop: 5,
        flexDirection: 'column',
    },
    skipButton: {
        fontSize: 16,
        color: 'grey',
    },
    inviteText: {
        fontSize: 14
    },
    buttons: {
        paddingHorizontal: 20,
        alignItems: 'flex-end'
    }
});
