import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    childrenWrapper: {
        flex: 1,
    },
    pageTitle: {
        fontSize: 20,
        padding: 16,
        fontWeight: 'bold'
    },
    doneButton: {
        borderRadius: 0,
    }
});