import React, {Component} from 'react';
import {Animated, Image, TouchableOpacity, View, Platform} from 'react-native';
import HTMLView from 'react-native-htmlview';

//components
import Text from '../Text/Text';

//styles
import styles from './styles';
import {$green} from '../../generalStyles';

//images
import CheckIcon from '../../assets/img/check_icon.png';
import ErrorIcon from '../../assets/img/error_icon.png';
import CloseIcon from '../../assets/img/close_icon.png';
import EStyleSheet from 'react-native-extended-stylesheet';

const notificationStyles = EStyleSheet.create({
    span: {
        color: $green,
        fontWeight: 'bold'
    }
});

export default class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            message: '',
            onPress: null,
            isError: false,
            htmlContent: null
        };

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.onBodyPress = this.onBodyPress.bind(this);

        this.timeout = null;
        this.timeoutConst = 5000;

        this.animatedPosition = new Animated.Value(-100);
    }

    show({message, htmlContent, timeout, onPress, isError}) {
        const timeoutValue = timeout ? timeout : this.timeoutConst;

        this.setState({show: true, message, htmlContent, onPress, isError: Boolean(isError)});

        Animated.timing(this.animatedPosition, {
            toValue: 25,
            duration: 200
        }).start();

        if (timeout !== 0) {
            this.timeout = setTimeout(this.hide, timeoutValue);
        }
    }

    hide() {
        Animated.timing(this.animatedPosition, {
            toValue: -100,
            duration: 200
        }).start(() => {
            this.setState({show: false});
        });
        clearTimeout(this.timeout);
    }

    onBodyPress() {
        const { onPress } = this.state;

        if (onPress) {
            onPress();
        }
        this.hide();
    }

    render() {
        const { show, message, isError, htmlContent } = this.state;
        return show && (
            <Animated.View
                style={[styles.notificationWrapper, {
                    top: 30,
                    position: Platform.OS === 'web' ? 'fixed' : 'absolute'
                }]}
            >
                <TouchableOpacity style={styles.notificationBody} onPress={this.onBodyPress}>
                    <Image source={isError ? ErrorIcon : CheckIcon} style={styles.statusIcon}/>
                    { htmlContent && <HTMLView style={{flex: 1}} value={htmlContent} stylesheet={notificationStyles}/> || <Text style={{flex: 1}}>{message && message.message ? message.message : message}</Text>}
                </TouchableOpacity>
                <TouchableOpacity onPress={this.hide}>
                    <Image source={CloseIcon} style={styles.closeButtonImage}/>
                </TouchableOpacity>
            </Animated.View>
        );
    }
}
