import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';

export function submitPermissions() {
    return dispatch => {
        dispatch({ type: authActionsConstants.SUBMIT_PERMISSIONS });
        return authService.submitPermissions().then(() => {
            dispatch({ type: authActionsConstants.SUBMIT_PERMISSIONS_SUCCESS });
        });
    };
}

export function update_invite_contact_option(invite) {
    return dispatch => {
        dispatch({ type: authActionsConstants.INVITE_CONTACTS });
        return authService.update_invite_contact_option(invite).then((response) => {
            dispatch({ type: authActionsConstants.USER_INVITE_CONTACT, payload: response.data.payload });
        });
    };
}
