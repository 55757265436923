import React from 'react';

export default function () {
  return (
<svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6942 1.69842C17.7784 1.54685 17.8939 1.5 18 1.5C18.1061 1.5 18.2216 1.54685 18.3058 1.69842L33.936 29.8196C34.025 29.9798 34.0175 30.1549 33.936 30.3016C33.8559 30.4457 33.7446 30.5 33.6302 30.5H2.36983C2.25545 30.5 2.14409 30.4457 2.06401 30.3016C1.98249 30.1549 1.97501 29.9798 2.06402 29.8196L17.6942 1.69842Z" stroke="#F58231" stroke-width="3"/>
<path d="M19.1099 21.911H16.8808L16.565 13H19.4257L19.1099 21.911ZM17.9954 23.3064C18.4474 23.3064 18.8096 23.433 19.082 23.6861C19.3607 23.9393 19.5 24.2631 19.5 24.6576C19.5 25.0462 19.3607 25.3671 19.082 25.6202C18.8096 25.8734 18.4474 26 17.9954 26C17.5495 26 17.1873 25.8734 16.9087 25.6202C16.6362 25.3671 16.5 25.0462 16.5 24.6576C16.5 24.269 16.6362 23.9481 16.9087 23.695C17.1873 23.4359 17.5495 23.3064 17.9954 23.3064Z" fill="#F58231"/>
</svg>


  );
}
