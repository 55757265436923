const googleClientId = {
    development: process.env.REACT_APP_GOOGLE_CLIENT_ID_LOCAL,
    staging: process.env.REACT_APP_GOOGLE_CLIENT_ID_STAGING,
    demo: process.env.REACT_APP_GOOGLE_CLIENT_ID_DEMO,
    production: process.env.REACT_APP_GOOGLE_CLIENT_ID_PRODUCTION,
  };

  const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

  export default googleClientId[environment];
