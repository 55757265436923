import React, { Component } from 'react';
import { View } from 'react-native';
import {connect} from 'react-redux';


//components
import AutoSuggestComponent from '../../components/AutoSuggestComponent/AutoSuggestComponent';
import Text from '../../components/Text/Text';
import TheirOpportunityElement from '../../components/TheirOpportunityElement/TheirOpportunityElement';
import MyOpportunityElement from '../../components/MyOpportunityElement/MyOpportunityElement';

//styles
import styles from './styles';
import {listElement} from '../../generalStyles';

class TrackerSearch extends Component {
    constructor(props) {
        super(props);

        this.renderSuggestElement = this.renderSuggestElement.bind(this);
        this.myRender = this.myRender.bind(this);

        this.typeTitles = {
            their_jobs: {
                title: 'Their Jobs',
                renderComponent: this.theirRender
            },
            my_jobs: {
                title: 'My Jobs',
                renderComponent: this.myRender
            },
        };
    }

    renderSuggestElement({item}) {
        return (
            <View style={listElement.groupWrapper}>
                <Text style={listElement.groupTitle}>{this.typeTitles[item.type].title}</Text>
                {this.typeTitles[item.type].renderComponent(item.data)}
            </View>
        )
    }

    theirRender(data) {
        return data.map(item => <TheirOpportunityElement opportunity={item} key={item.id}/>);
    }

    myRender(data) {
        return data.map(item => <MyOpportunityElement opportunity={item} key={item.id} currentUser={this.props.currentUser}/>);
    }

    render() {
        return (
            <AutoSuggestComponent
                suggestElement={this.renderSuggestElement}
                service="autocompleteTracker"
                suggestionsKey="results"
                keyField="type"
                wrapperStyle={styles.wrappedStyle}
                searchType="trackerSuggestions"
                placeholder='Search tracker suggestions'
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackerSearch);
