import { StyleSheet } from 'react-native';
import { $greish } from '../../generalStyles';

export default StyleSheet.create({
    text: {
        textAlign: 'center',
    },
    elementWrapper: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        alignItems: 'center',
        alignContent: 'center',
        marginTop: 20,
    },
    image: {
        height: 180,
        width: '100%',
        marginRight: 12,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#d8d8d8'
    },
    imageWrapper: {
        paddingVertical: 8,
        paddingLeft: 15,
        position: 'relative',
        justifyContent: 'center',
        marginTop: 20,
        width: '100%',
    },
    fileInput: {
        height: 180,
        width: '100%',
    },
    button: {
        width: '80%',
        backgroundColor: '#0E76A8',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
        width: 150,
    },
    buttonText: {
        color: '#fff',
    },
    link: {
        textDecorationLine: 'underline',
        color: '#0000EE',
    },
    fileContainer: {
        flexDirection: 'row',
        width: '100%',
        height: 50,
        backgroundColor: '#F5F7F9',
        borderRadius: 5,
        borderStyle: 'dashed',
        borderWidth: 0.6,
        borderColor: '#cccccc',
        paddingTop: 17,
        justifyContent: 'space-between'
    },
    csvButton: {
        marginTop: 32,
        alignSelf: 'center'
    },
    csvUploadContainer: {
        fontFamily: 'Roboto-Regular',
        textAlign: 'center',
        padding: 15,
        margin: '10px auto',
        borderColor: $greish,
        borderWidth: 1,
        borderRadius: 5,
        marginTop: 20,
        marginRight: 20,
        marginLeft: 20,
    },
    fieldsWrapper: {
        paddingLeft: 20,
        paddingRight: 20,
        marginTop: 10
    },
    labelContainer: {
        width: '30%', paddingTop: 25
    },
    descriptionText: {
        padding: 20,
        textAlign: 'center'
    },
    dropDownFieldStyle: {
        marginTop: 9,
        borderWidth: 1,
        borderColor: '#aeaeae',
        paddingRight: 10,
        borderRadius: 5
    }
});
