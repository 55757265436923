import React, { useRef, useEffect } from "react";
import { reduxForm, Field, change, initialize } from "redux-form";
import { Image, View, TouchableOpacity, Keyboard } from "react-native";
import Spinner from "react-native-loading-spinner-overlay";

import Actions from "../../RouteActions";
import { connect } from "react-redux";

//layout
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";

//component
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import FormInput from "../../components/FormInput/FormInput";
import Text from "../../components/Text/Text";

//images
import { withGetScreen } from "react-native-getscreen";
import unionIcon from "../../assets/svg/union-icon.svg";

//styles
import styles from "./styles";
import { formLayout } from "../../generalStyles";

import * as actions from "./actions";

//utils
import { notification, validateEmail } from "../../helperFunctions";

const SignInSSO = (props) => {
  const { formValues, initialValues, signInLoading, handleSubmit } = props;

  const submitForm = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleBack = (e) => {
    e.preventDefault();
    history.back();
  };

  const disabled =
    signInLoading || !formValues.psid;

  return (
    <AuthLayout>
      <View>
        <View style={styles.formWrapper}>
          <View style={styles.header}>
            <View style={styles.iconWrapper}>
              <Image
                source={unionIcon}
                resizeMode="contain"
                style={styles.iconImg}
              />
            </View>
            <Text style={styles.headerText}>Sign in with SSO</Text>
            <Text style={styles.subHeaderText}>
              Use your work psid to access Boon via SSO
            </Text>
          </View>
          <View>
            <View style={styles.formFieldWrapper}>
              <Field
                component={FormInput}
                name="psid"
                disabled={Boolean(initialValues && initialValues.email)}
                placeholder="PSID *"
                inputStyle={styles.inputStyle}
                labelStyle={styles.labelStyle}
                wrapperStyle={[formLayout.fullWidth]}
                returnKeyType="next"
                keyboardType="numeric"
              />
            </View>
          </View>
          <View style={[styles.formFieldWrapper, {marginTop: 20}]}>
            <PrimaryButton
              onPress={submitForm}
              disabled={disabled}
              style={disabled ? styles.disabledButton : styles.button}
              textStyle={
                disabled ? styles.disabledButtonText : styles.buttonText
              }
            >
              Continue
            </PrimaryButton>
          </View>
          <View style={[styles.formFieldWrapper, {marginTop: 20}]}>
            <PrimaryButton
              onPress={handleBack}
              style={{ height: 'auto', width: 27, margin: 'auto', backgroundColor: 'none'}}
              textStyle={[styles.disabledButtonText, {textDecorationLine: "underline",}]}
            >
              Back
            </PrimaryButton>
          </View>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Text style={{ color: "#FFFFFF", fontSize: 12 }}>
            Already have an account?&nbsp;
          </Text>
          <TouchableOpacity onPress={() => Actions.signInEmail()}>
            <Text
              style={{
                color: "#FFFFFF",
                fontSize: 12,
                textDecorationLine: "underline",
              }}
            >
              <b>Login</b>
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    formValues: (state.form.signInSSO && state.form.signInSSO.values) || {},
    signInLoading: state.authReducer.signInLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (field, value) => {
      dispatch(change("signUp", field, value));
    },
    onSubmit: (values) => {
      Keyboard.dismiss();
      if (!values.psid) {
        notification.ref.show({
          message: "PSID is required",
          isError: true,
        });
      } else {
        dispatch(actions.signIn(values));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: "signInSSO", enableReinitialize: true })(
    withGetScreen(SignInSSO)
  )
);
