import axios from 'axios';
import { Platform } from 'react-native';
import baseService, { apiSeverUrl } from './baseService';

export function login(data) {
    return baseService.post('/applicants/sign_in', data);
}

export function ssoLogin(data) {
    return axios.get(`${apiSeverUrl}/market_source/saml`, {
        params: data
    });
}

export function updatePassword(data) {
    return baseService.post('/applicants/update_password', data);
}

export function setPassword(data) {
    return baseService.post('/applicants/set_password', data);
}

export function logout() {
    return baseService.post('users/sign_out');
}

export function skipMobileVerification() {
    return baseService.post('users/setup_mobile_verification_later');
}

export function updateStepsOnBackButton(data) {
    return baseService.post('users/update_steps_on_back_button', data);
}

export function updateSignupParams(data) {
    return baseService.post('users/update_signup_params', data);
}
export function signUpThroughPublicJobApp(data) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const userObj = {
        name: data.name,
        email: data.email,
        message: data.message,
        refer_request_id: data.refer_request_id,
        terms_of_service: data.terms_of_service,
        links_json: data.links_json,
        public_job_application: data.public_job_application,
        device_fingerprint: data.device_fingerprint,
    }
    const requestData = new FormData();
    requestData.append('file', data.file);
    requestData.append('user_data', JSON.stringify(userObj));

    return baseService.post('/applicants/sign_up', requestData, config);
}

export function dashboardPublicJobApp(data) {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const userObj = {
        message: data.message,
        email: data.email,
        refer_request_id: data.refer_request_id,
        terms_of_service: data.terms_of_service,
        links_json: data.links_json,
        public_job_application: data.public_job_application,
        device_fingerprint: data.device_fingerprint,
    }
    const requestData = new FormData();
    requestData.append('file', data.file);
    requestData.append('user_data', JSON.stringify(userObj));

    return baseService.post('/applicants/dashboard_public_job_app', requestData, config);
}

export function signUp(data) {
    return baseService.post('/applicants/sign_up', data);
}

export function signUpLinkedIn(data) {
    return baseService.post('/applicants/sign_up_linkedin', data);
}


export function signUpInvite(data) {
    return baseService.post('/applicants/sign_up_invite', data);
}

export function getNumberConfirmation(data) {
    return baseService.put('/applicants/request_confirmation', data);
}

export function confirmNumber(data) {
    return baseService.get('/applicants/check_confirmation', {
        params: data
    });
}

export function getCurrentUser() {
    return baseService.get('/users/current');
}

export function getSignedInUser(data) {
    return baseService.get('/signed_in_user', {params: {token: data.token, device_fingerprint: data.device_fingerprint}});
}

export function getCurrentUserStats() {
    return baseService.get('/users/current_user_stats');
}

export function updatePushToken(data) {
    return baseService.post('/users/update_push_token', data);
}

export function submitPermissions() {
    return baseService.post('applicants/permissions_passed/');
}

export function resendAccountConfirmation(data) {
    return baseService.post('/users/resend_confirmation', data);
}

export function submitConfirmationCode(data) {
    return baseService.post('/applicants/sign_in', data);
}

export function confirm(data) {
    return baseService.post('/users/confirm', data);
}

export function getMagicLink(data) {
    return baseService.post('/users/reset_password', data);
}

export function getresetPasswordToken(data) {
    return baseService.post('/users/reset_password_token', data);
}

export function validateInvitation(data) {
    return baseService.post('/users/validate_invitation', data);
}

export function changePassword(password) {
    return baseService.put('/applicants/change_password', { password });
}

export function forgetPassword(password) {
    return baseService.post('/applicants/change_password', { password });
}

export function editCurrentUser(formValues) {
    return baseService.post('/users/update', formValues);
}

export function unsubscribeGoogle(accessToken) {
    return baseService.patch(`/users/disconnect_google?access_token=${accessToken}`);
}

export function unsubscribeLinkedin() {
    return baseService.post('/users/disconnect_linkedin');
}

export function unsubscribeOutlook(accessToken) {
    return baseService.patch(`/users/disconnect_outlook?access_token=${accessToken}`);
}

export function unsubscribeFacebook(accessToken) {
    return baseService.patch(`/users/disconnect_facebook?access_token=${accessToken}`);
}

export function unsubscribeTwitter(accessToken) {
    return baseService.patch(`/users/disconnect_twitter?access_token=${accessToken}`);
}

export function validateInvitationToken(invitation_token) {
    return baseService.post('/users/validate_invitation_token', { invitation_token });
}

export function confirmPhone(data) {
    return baseService.post('/users/confirm_phone', {confirmation_code: data.confirmation_code, phone_number:  data.phone_number});
}

export function update_invite_contact_option(data) {
    return baseService.post('users/update_invite_contact_option', data);
}

export function validateUserInviteToken(invite_token) {
    return baseService.post('/users/validate_user_invite_token', { invite_token });
}

export function getUserWithToken(data) {
    return baseService.post('/users/token_user', data );
}

export function getUserSyncedHistory(data) {
    return baseService.get('/applicants/user_synced_contact_details', { params: data } );
}

export function verifySsoEnabled(data) {
    return axios.get(`${apiSeverUrl}/saml?email=${encodeURIComponent(data.email)}`);
}
export function enableGoogleSSO(params) {
    return axios
      .create({
        baseURL: apiSeverUrl,
        headers: {'from-web-app': Platform.OS == 'web'}
      })
      .get('/google_single_sign_on', { params });
  }

export function enableLinkedInSSO(params) {
    return axios
      .create({
        baseURL: apiSeverUrl,
        headers: {'from-web-app': Platform.OS == 'web'}
      })
      .get('/linked_in_single_sign_on', { params });
  }
