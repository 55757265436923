import * as userService from '../../services/usersService';
import {notification} from '../../helperFunctions';
import { closeDrawer } from '../../DrawerActions.js';

export function importFields(data) {
    return (dispatch) => {
        userService.importFields(data).then(() => {
            dispatch(closeDrawer());
            notification.ref.show({message: 'Contacts will be created shortly.', isError: false});
        }).catch(error => {
            console.error(error);
        });
    };
}
