import React, { Component } from 'react';
import {
    View,
    Image,
    TouchableWithoutFeedback
} from 'react-native';
import Permissions from '../../wrappers/permission/permission';;
import Actions from '../../RouteActions';
import {connect} from 'react-redux';

import styles from './styles';

//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import Text from '../../components/Text/Text';

//images
import syncContactsIcon from '../../assets/img/sync_contacts_icon.png';
import locationIcon from '../../assets/img/location_icon.png';
import notificationsIcon from '../../assets/img/notifications_icon.png';
import candidateIcon from '../../assets/img/candidate_icon.png';
import permissionsCheckIcon from '../../assets/img/permissions_check_icon.png';

//actions
import * as actions from './actions';
import ProfileCompleteBar from '../../components/ProfileCompleteBar/ProfileCompleteBar';

//utils
import {calcPermissionPercentage, calcProfilePercentage, getUserFormParams} from '../../helperFunctions';

class CompleteProfile extends Component {
    handlePermissionClick(permissionType) {
        Permissions.request(permissionType).then(response => {
            if (response === 'denied') {
                Permissions.openSettings();
            }
        });
    }

    componentWillUpdate(nextProps) {
        const { permissions, currentUser } = nextProps;
        if (calcPermissionPercentage(permissions) + calcProfilePercentage(currentUser) === 100) {
            Actions.pop();
        }
    }

    render() {
        const { permissions, currentUser } = this.props;

        const profilePercentage = calcProfilePercentage(currentUser);
        const permissionPercentage = calcPermissionPercentage(permissions);

        const contactIconImg = permissions.contacts ? permissionsCheckIcon : syncContactsIcon;
        const locationIconImg = permissions.location ? permissionsCheckIcon : locationIcon;
        const notificationsIconImg = permissions.notification ? permissionsCheckIcon : notificationsIcon;

        const profileIconImage = profilePercentage === 30 ? permissionsCheckIcon : candidateIcon;

        const getWrapperStyles = permission => permissions[permission] ? [styles.permissionWrapper, styles.permissionWrapperDisabled] :  styles.permissionWrapper;
        const getProfileWrapperStyles = profilePercentage === 30 ? [styles.permissionWrapper, styles.permissionWrapperDisabled] :  styles.permissionWrapper;

        const completeProfileTransition = (currentUser && !currentUser.sync_google_people_contacts && (permissionPercentage + profilePercentage === 90)) ? () => Actions.settings() : () => Actions.userForm(getUserFormParams(currentUser));

        return (
            <DashboardBase title="My Profile" hasBackButton>
                <ProfileCompleteBar/>
                <View style={styles.screenWrapper}>
                    <TouchableWithoutFeedback onPress={() => this.handlePermissionClick('contacts')}>
                        <View style={getWrapperStyles('contacts')}>
                            <Image source={contactIconImg} style={styles.permissionIcon} resizeMode="contain"/>
                            <View style={{flex: 1}}>
                                <Text style={styles.permissionTitle}>Enable access to your contacts</Text>
                                <Text style={styles.permissionDescription}>Get highly relevant recommendations and updates based on the contacts in your network.</Text>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => this.handlePermissionClick('notification')}>
                        <View style={getWrapperStyles('notification')}>
                            <Image source={notificationsIconImg} style={styles.permissionIcon} resizeMode="contain"/>
                            <View style={{flex: 1}}>
                                <Text style={styles.permissionTitle}>Enable push notifications</Text>
                                <Text style={styles.permissionDescription}>Get intuitive notifications. Notifications may include alerts, sounds and icon badges.</Text>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={profilePercentage === 30 ? null : completeProfileTransition}>
                        <View style={getProfileWrapperStyles}>
                            <Image source={profileIconImage} style={styles.permissionIcon} resizeMode="contain"/>
                            <View style={{flex: 1}}>
                                <Text style={styles.permissionTitle}>Complete your profile</Text>
                                <Text style={styles.permissionDescription}>Complete profiles receive higher quality referrals and are more visible.</Text>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback onPress={() => this.handlePermissionClick('location')}>
                        <View style={getWrapperStyles('location')}>
                            <Image source={locationIconImg} style={styles.permissionIcon} resizeMode="contain"/>
                            <View style={{flex: 1}}>
                                <Text style={styles.permissionTitle}>Enable location services</Text>
                                <Text style={styles.permissionDescription}>Get tailored content based on your location and contacts around you.</Text>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </DashboardBase>
        );
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authReducer.permissions,
        currentUser: state.authReducer.currentUser
    };
};

const mapDispatchToProps = dispatch => {
    return {
        submitPermissions: () => {
            return dispatch(actions.submitPermissions());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);
