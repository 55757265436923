import React from 'react';

export default function () {
  return (
<svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.77633 0.330933H1.50033C0.764992 0.330933 0.166992 0.929599 0.166992 1.66427V12.3309C0.166992 13.0663 0.764992 13.6643 1.50033 13.6643H9.50033C10.2357 13.6643 10.8337 13.0663 10.8337 12.3309V3.38827L7.77633 0.330933ZM9.50099 12.3309H1.50033V1.66427H6.83366V4.33093H9.50033L9.50099 12.3309Z" fill="black"/>
<path d="M6.8335 6.33093H2.8335V7.66427H6.8335V6.33093Z" fill="black"/>
<path d="M8.16683 8.99609H2.8335V10.3294H8.16683V8.99609Z" fill="black"/>
</svg>


  );
}
