import React, { useMemo, useEffect, useState } from 'react';
import styles from './styles';
import {
    ImageBackground,
    View,
    Text,
    TouchableOpacity,
    Image
} from 'react-native';
import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import BoonLogo from '../../assets/img/boon_logo.svg';
import FormInput from '../../components/FormInput/FormInput';
import { Field, reduxForm } from 'redux-form';
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { notification, validateEmail } from '../../helperFunctions';
import { formLayout } from '../../generalStyles';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Actions from '../../RouteActions';
import * as actions from './actions';
import * as magicLinkActions from '../MagicLink/actions';
import * as signupActions from '../SignUp/actions';
import * as AsyncStorage from '../../AsyncStorage';
import * as redirectActions from '../../globalActions/redirectActions';
import MagicLinkSuccess from './MagicLinkSuccess';
import useWindowSize from '../../hooks/useWindowResize';
import SSOModal from '../../components/SSOModal/SSOModal';

let ForgetPassword = ({
    formValues,
    redirect,
    onSubmit,
    requestMagicLink,
    verifySsoEnabled
}) => {
    const size = useWindowSize();
    const [emailError, setEmailError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [magicLinkSent, setMagicLinkSent] = useState(false);
    const [SsoEmail , setSsoEmail] = useState(false);
    const [showSsoModal, setShowSsoModal] = useState(false);

    const disabled = useMemo(
        () => !validateEmail(formValues.email) || SsoEmail,
        [formValues.email , SsoEmail]
    );
    useEffect(() => {
        const debouncedVerify = debounce(() => {
            verifySsoEnabled({ email: formValues.email }, () => setSsoEmail(true), () => setSsoEmail(false));
        }, 500); 
    
        debouncedVerify();
    
        return () => {
            debouncedVerify.cancel(); 
        };
    }, [formValues.email]);

    useEffect(() => {
        AsyncStorage.getAccessToken().then((token) => {
            if (token) {
                redirect();
                return null;
            }
        });
    }, []);

    const handleContinue = () => {
        if (!formValues.email) {
            notification.ref.show({
                message: 'Email is required',
                isError: true
            });
        } else if (!validateEmail(formValues.email)) {
            notification.ref.show({
                message: 'Please enter valid email',
                isError: true
            });
        }
    };

    const handleBack = () => {
        Actions.signInEmail();
    };

    const successCallback = () => {
        setMagicLinkSent(true);
    };

    const handleMagicLink = () => {
        if (!formValues.email) {
            setErrorMessage('Email is required');
            setEmailError(true);
        } else if (!validateEmail(formValues.email)) {
            setErrorMessage('Provide valid email address');
            setEmailError(true);
        } else {
            setEmailError(false);
            setShowSsoModal(disabled)
        }
    };

    const getInputStyles = useMemo(() => {
        if (!emailError) {
            return styles.inputStyle;
        } else {
            return [styles.inputStyle, styles.inputError];
        }
    }, [emailError]);

    const getLogoStyles = useMemo(() => {
        if (size.width > 550) {
            return styles.boonLogo;
        } else {
            return styles.mobileBoonLogo;
        }
    }, [size]);

    const errorCallback = (error) =>{
        setEmailError(true);
        setErrorMessage(error);
    }

    const handleSubmit = ()=>{
        verifySsoEnabled(
            { email: formValues.email },
            () => {
                notification.ref.show({
                    message: 'Please contact the support team',
                    isError: true
                });
            },
            () => onSubmit(formValues, errorCallback)
          );
    }

    return (
        <ImageBackground
            source={backgroundBoxImage}
            style={styles.elementWrapper}
        >
            <Image source={BoonLogo} style={getLogoStyles} />
            {magicLinkSent ? (
                <MagicLinkSuccess
                    email={formValues.email}
                    handleBack={handleBack}
                />
            ) : (
                <View>
                    <View style={styles.contentCont}>
                        <Text style={styles.heading}>Forgot Password</Text>
                        <Field
                            placeholder="Email"
                            name="email"
                            component={FormInput}
                            keyboardType="email-address"
                            labelStyle={styles.labelStyle}
                            inputStyle={getInputStyles}
                            onSubmitEditing={handleContinue}
                            wrapperStyle={[
                                formLayout.fullWidth,
                                styles.inputWrapper
                            ]}
                            onFocus={() => setEmailError(false)}
                            onBlur={() => {
                                if (validateEmail(formValues.email)) verifySsoEnabled({ email: formValues.email }, ()=>{setShowSsoModal(true)
                                  }, ()=>{});
                              }}
                        />
                        {emailError && (
                            <View style={styles.errorMessageCont}>
                                <Text style={styles.errorMessage}>
                                    {errorMessage}
                                </Text>
                            </View>
                        )}
                        <PrimaryButton
                            onPress={handleSubmit}
                            disabled={disabled}
                            style={
                                disabled ? styles.disabledButton : styles.button
                            }
                            textStyle={
                                disabled
                                    ? styles.disabledButtonText
                                    : styles.buttonText
                            }
                        >
                            Submit
                        </PrimaryButton>
                        <TouchableOpacity onPress={handleBack}>
                            <Text style={styles.backButton}>Back</Text>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity onPress={handleMagicLink}>
                        <Text style={styles.magicLink}>
                            Or send a magic link
                        </Text>
                    </TouchableOpacity>
                    <SSOModal showSsoModal={showSsoModal} setShowSsoModal={setShowSsoModal}/>
                </View>
            )}
        </ImageBackground>
    );
};

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.forgetPassword && state.form.forgetPassword.values
                ? state.form.forgetPassword.values
                : {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (params, errorCallback) => {
            dispatch(actions.resetPassword(params,null, errorCallback));
        },
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
        requestMagicLink: (email, successCallback, errorCallback) => {
            dispatch(magicLinkActions.requestMagicLink(email, successCallback, errorCallback));
        },
        verifySsoEnabled: (params, successCallback, errorCallback) => {
            dispatch(signupActions.verifySsoEnabled(params, successCallback, errorCallback));
        },
    };
};

ForgetPassword = reduxForm({ form: 'forgetPassword' })(ForgetPassword);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withGetScreen(ForgetPassword));
