import baseService from "./baseService.js";

export function getAllOrganization() {
  return baseService.get("/organization_invitations");
}

export function getOrganizationsBySort(sortBy) {
  return baseService.get("/organization_invitations", {
    params: { sort_by: sortBy },
  });
}

export function getOrganizationsList() {
  return baseService.get("/organizations");
}

export function getOrganizationsTab() {
  return baseService.get("/tabs/organizations");
}

export function getOrganizationDetails(organizationId) {
  return baseService.get(`/organizations/${organizationId}/`);
}

export function getOrganizationDetailsByGeneralSettingId(
  general_setting_identifier
) {
  return baseService.get(
    `/organizations/${general_setting_identifier}/retrieve_employer_profile`
  );
}

export function getOrganizationJobs(organization_id, params) {
  return baseService.get(`/global_index/jobs/`, {
    params: { ...params, organization_id },
  });
}

export function getOrganizationActiveJobs(organization_id, params) {
  return baseService.post(
    `/organizations/${organization_id}/get_published_jobs`
  );
}

export function submitInvite(organizationId) {
  return baseService.post(`/organizations/${organizationId}/accept_invitation`);
}

export function requestAccess() {
  return baseService.post("/organizations/request_access");
}

export function followOrganization(organizationName) {
  return baseService.post("/organization_invitations/follow_organization/", {
    name: organizationName,
  });
}

export function unfollowOrganization(organizationId) {
  return baseService.post("/organization_invitations/unfollow_organization/", {
    id: organizationId,
  });
}

export function getOrganizationsInvites() {
  return baseService.get("/organization_invitations/invitations_index/");
}

export function getIndustries() {
  return baseService.get("/industries");
}

export function checkName(name) {
  return baseService.get("/organizations/check_name", {
    params: { name: name },
  });
}

export function getDropdownValues() {
  return baseService.get("/organizations/new");
}

export function createOrganization(data) {
  return baseService.post("/organizations", data);
}

export function getPerksAndBenefitsValues() {
  return baseService.get("/organizations/tags");
}
