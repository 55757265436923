import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import { View, TouchableOpacity } from 'react-native';
import { connect } from 'react-redux';
import * as redirectActions from '../../globalActions/redirectActions';
//component
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SignUpButton from '../../components/SignupButton/SignupButton';
import FormInput from '../../components/FormInput/FormInput';
import Text from '../../components/Text/Text';
import SignUpBase from '../SignUpBase/SignUpBase';
import ConfirmationCodeInput from '../../components/CodeInput/ConfirmationCodeInput';
//styles
import styles from './styles';
//utils
import { withGetScreen } from 'react-native-getscreen';
import { notification } from '../../helperFunctions';
//actions
import * as phoneNumberActions from '../PhoneNumber/actions';
import * as actions from './actions';
import Actions from '../../RouteActions';

class SignUpPhone extends Component {
    constructor(props) {
        super(props);
        this.state = { code: '', isDisabled: true };
    }
    componentDidMount() {
        const {
            userCurrentStep,
            redirect,
            phoneNumber,
            phoneConfirmationData
        } = this.props;
        if (userCurrentStep != 'phone_number') {
            redirect();
        }
    }

    handleConfirmationSubmit = (e) => {
        e.preventDefault();
        const { onSubmitConfirmNumber, formValues, phoneConfirmationData } =
            this.props;
        formValues.phone_number = phoneConfirmationData.phone_number;
        if (formValues.confirmation_code) {
            onSubmitConfirmNumber(formValues);
        } else {
            notification.ref.show({
                message: 'Please enter confirmation code',
                isError: true
            });
        }
        this.child._clear();
    };

    renderSignUpPhoneForm = () => {
        const mobile = this.props.isMobile();
        const { isDisabled } = this.state;
        const {
            formValues,
            phoneConfirmationSent,
            phoneConfirmationData,
            resendCode,
            submitConfirmationCode
        } = this.props;
        const mainHeading = 'Welcome to Boon';
        const subHeading = 'Please confirm your phone number to continue';

        return (
            <AuthLayout>
                <View>
                    <View style={styles.formWrapper}>
                        <View style={styles.header}>
                            <Text style={styles.headerText}>
                                Check your phone and enter the code below {':)'}
                            </Text>
                        </View>
                        <Field
                            component={FormInput}
                            type="hidden"
                            name="phone_number"
                            wrapperStyle={{ display: 'none' }}
                        />
                        <Field
                            component={FormInput}
                            type="hidden"
                            name="confirmation_code"
                            wrapperStyle={{ display: 'none' }}
                        />
                        <ConfirmationCodeInput
                            onRef={(ref) => (this.child = ref)}
                            codeLength={4}
                            cellProps={styles.inputStyle}
                            containerProps={styles.inputsWrapper}
                            keyboardType="numeric"
                            variant="border-b"
                            onFulfill={(value) => submitConfirmationCode(value)}
                            onChange={(value) =>
                                this.setState({ isDisabled: value })
                            }
                            autoFocus
                        />
                        <View>
                            <Text style={styles.numberTextWrapper}>
                                A code has been sent to{' '}
                                <Text>
                                    <b>{phoneConfirmationData.phone_number}</b>
                                </Text>
                            </Text>
                        </View>
                        <SignUpButton
                            onClick={this.handleConfirmationSubmit}
                            disabled={isDisabled}
                        >
                            Confirm
                        </SignUpButton>
                        <View style={styles.textPadding}>
                            <Text style={styles.resendCode}>
                                Didn't get the code?
                                <TouchableOpacity
                                    style={styles.greenLink}
                                    onPress={() =>
                                        resendCode(phoneConfirmationData)
                                    }
                                >
                                    <Text> Resend</Text>
                                </TouchableOpacity>
                                {'\n'}or{'\n'}
                                <TouchableOpacity
                                    onPress={this.props.onSetupLater}
                                    style={styles.greenLink}
                                >
                                    <Text>Setup later</Text>
                                </TouchableOpacity>
                            </Text>
                        </View>
                    </View>
                    <View style={styles.formBottomWrapper}>
                        <Text style={styles.formBottomTextWhite}>
                            Entered a wrong phone number?{'\n'}
                            <TouchableOpacity
                                onPress={() => Actions.confirmPhone()}
                            >
                                <Text style={styles.formBottomTextUnderline}>
                                    <b>Go back</b>
                                </Text>
                            </TouchableOpacity>
                        </Text>
                    </View>
                </View>
            </AuthLayout>
        );
    };

    render() {
        return this.renderSignUpPhoneForm();
    }
}

SignUpPhone = reduxForm({ form: 'signUpPhoneForm' })(
    withGetScreen(SignUpPhone)
);

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.signUpPhoneForm && state.form.signUpPhoneForm.values
                ? state.form.signUpPhoneForm.values
                : {},
        userCurrentStep: state.stepsReducer.step,
        currentUser: state.authReducer.currentUser,
        phoneConfirmationSent: state.authReducer.phoneConfirmationSent,
        phoneConfirmationData: state.authReducer.phoneConfirmationData,
        phoneNumber: state.authReducer.phoneNumber
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
        onSubmitConfirmNumber: (formValues) => {
            dispatch(actions.confirmNumber(formValues));
        },
        submitConfirmationCode: (confirmationCode) => {
            dispatch(
                change('signUpPhoneForm', 'confirmation_code', confirmationCode)
            );
        },
        onSetupLater: () => {
            dispatch(actions.sendSkipMobileVerification());
        },
        resendCode: (formValues) => {
            dispatch(
                phoneNumberActions.getNumberConfirmation(formValues, true)
            );
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPhone);
