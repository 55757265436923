import { StyleSheet } from "react-native";

export default StyleSheet.create({

  ssoButtonStyle: {
    width: 19, 
    height: 15
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
});
