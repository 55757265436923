import React from 'react';

export default function () {
  return (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.5 1.93064L13.9893 0.419922L8 6.40921L2.01071 0.419922L0.5 1.93064L6.48929 7.91992L0.5 13.9092L2.01071 15.4199L8 9.43064L13.9893 15.4199L15.5 13.9092L9.51071 7.91992L15.5 1.93064Z" fill="black" fill-opacity="0.4"/>
</svg>

  );
}
