import React, { useState, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { CheckBox, View, Image, TouchableOpacity, Modal } from 'react-native';
import CloseButton from '../../components/CloseButton/CloseButton';
import './ImageResize.css'


const ImageResize =({uploadedImage, handleImageCrop,deSelectPhoto, selectPhotoFor})=> {
const [src, setSrc] = useState(null);
const [crop, setCrop] = useState({ aspect: 16 / 9 });
const [image, setImage] = useState(null);
const [isImageCropped, setisImageCropped] = useState(false);
const [isFirstTimeUpload,setisFirstTimeUpload] = useState(true);
const cropImage=(closeModal)=>{
	setisFirstTimeUpload(false);
	cropImageNow(closeModal);
}

useEffect(() => {
	if(!!uploadedImage && !!uploadedImage.preview){
		setSrc(uploadedImage.preview)
	}
	if(selectPhotoFor === 'avatar'){
		setCrop({ aspect: 16 / 16 })
	  }
	  if(selectPhotoFor === 'cover_image'){
		setCrop({ aspect: 16 / 9 })
	  }
}, [uploadedImage,selectPhotoFor])

const dataUrlToFile = (dataurl, fileName) => {
 
	var arr = dataurl.split(','),
	mime = arr[0].match(/:(.*?);/)[1],
	bstr = atob(arr[1]), 
	n = bstr.length, 
	u8arr = new Uint8Array(n);
	
   while(n--){
	u8arr[n] = bstr.charCodeAt(n);
}

   return new File([u8arr], fileName, {type:mime});
  };
const cropImageNow = (closeModal) => {
	setisImageCropped(true)
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height,
    );3
    const base64Image = canvas.toDataURL('image/jpeg');
	if(base64Image.length === 6){
		handleImageCrop(uploadedImage,uploadedImage.preview,closeModal ? true : false);
	}else{
		const file = dataUrlToFile(base64Image, uploadedImage.name);
		handleImageCrop(file,base64Image,true)
	}
};

return (
	<Modal
	transparent={true}
	visible={true}
	onRequestClose={() => deSelectPhoto()}
>
	<View
		style={{
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: 22
		}}
	>
		<View
			style={{
				backgroundColor: 'white',
				minWidth: 375,
				borderRadius: 5,
				padding: 35,
				alignItems: 'center',
				shadowColor: '#000',
				shadowOffset: {
					width: 0,
					height: 2
				},
				shadowOpacity: 0.25,
				shadowRadius: 4,
				elevation: 5
			}}
		>
			<CloseButton
				style={{
					position: 'absolute',
					top: 10,
					right: 10,
					cursor: 'pointer'
				}}
				onPress={() => isImageCropped? deSelectPhoto(false,true) : deSelectPhoto()}
			/>
			<View
				style={{
					marginTop: 12,
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					margin: 'auto',
					width: 270
				}}
			>
				<div className="App">
				<center>
					<div>
					{src && (
						<div>
							<ReactCrop src={src} onImageLoaded={setImage}
							crop={crop} disabled={isFirstTimeUpload ? true : false} onChange={setCrop} />
						</div>
						)}
						<br />
						<br />
						<div>
						{isImageCropped ? null:<button className='crop-image-buttons'  onClick={()=>cropImage(false)}>Crop Image Before Upload</button>}
						<button className='crop-image-buttons' 
						onClick={isImageCropped ? ()=> cropImage(true)
						 : ()=> handleImageCrop(uploadedImage,uploadedImage.preview, true)}>
							{isImageCropped ? 'Crop' : 'Continue without Cropping'}</button>
						</div>
						</div>
				</center>
				</div>
			</View>
		</View>
	</View>
</Modal>
);
}

export default ImageResize;
