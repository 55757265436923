import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    wrapperStyle: {
        width: '100%',
        paddingTop: 15,
        position: 'relative'
    },

    backgroundView: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: -1
    },

    labelStyle: {
        fontSize: 14,
        color: '#c1c1c1',
        fontWeight: 'bold',
        paddingHorizontal: 16,
        top: 22,
        left: 0,
        position: 'absolute'
    },

    labelStyleFocused: {
        top: 0,
        fontSize: 10,
        color: $green
    }
});

export const addressStyle = {
    container: {
        paddingTop: 0,
        backgroundColor: '#EBF0F3 !important'
    },

    textInputContainer: {
        borderTopWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#c1c1c1',
        backgroundColor: '#EBF0F3 !important'
    },

    textInput: {
        height: 22,
        fontSize: 14,
        fontWeight: 'bold',
        backgroundColor: '#EBF0F3 !important'
    },

    predefinedPlacesDescription: {
        color: '#1faadb'
    },

    poweredContainer: {
        display: 'none'
    },

    powered: {
        display: 'none'
    }
};
