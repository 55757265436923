import { PixelRatio } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";
import { $green } from "../../../../generalStyles";

export default EStyleSheet.create({
  root: {
    width: "100%",
  },
  description: {
    marginTop: 6,
    opacity: 0.6,
  },
});
