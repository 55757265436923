import { StyleSheet } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  formWrapper: {
    borderRadius: 4,
    backgroundColor: "#FFFFFF",
    width: 375,
    margin: "auto",
    paddingTop: 60,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 60,
  },
  header: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20
  },
  headerText: {
    color: "#0FBC71",
    fontSize: 20,
    fontWeight: "bold",
    letterSpacing: "0.8px",
  },
  formFieldWrapper: {
    marginTop: 25,
  },
  labelStyle: {
    marginTop: 10,
    backgroundColor: "#FFFFFF",
  },
  inputStyle: {
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "#CCCCCC",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 12,
  },
  iconButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
  },
  iconImg: {
    width: 16,
    height: 16,
  },
  button: {
    borderWidth: 0,
    backgroundColor: $green,
  },
  disabledButton: {
    borderWidth: 0,
    backgroundColor: "#F2F2F2",
  },
  buttonText: {
    color: "#FFFFFF",
    fontSize: 12,
  },
  disabledButtonText: {
    color: "#808080",
    fontSize: 12,
  },
});
