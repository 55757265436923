export const OPEN_ORG_DRAWER = 'OPEN_ORG_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';
export const OPEN_REFER_FORM_DRAWER = 'OPEN_REFER_FORM_DRAWER';
export const OPEN_SETTINGS_DRAWER = 'OPEN_SETTINGS_DRAWER';
export const OPEN_CHANGE_PASSWORD_DRAWER = 'OPEN_CHANGE_PASSWORD_DRAWER';
export const OPEN_JOB_APPLY_DRAWER = 'OPEN_JOB_APPLY_DRAWER'
export const OPEN_CONNECT_CONTACTS_DRAWER = 'OPEN_CONNECT_CONTACTS_DRAWER'
export const OPEN_BROWSE_FILTERS_DRAWER = 'OPEN_BROWSE_FILTERS_DRAWER';
export const OPEN_NOTIFICATIONS_DRAWER = 'OPEN_NOTIFICATIONS_DRAWER';
export const OPEN_USER_PROFILE_DRAWER = 'OPEN_USER_PROFILE_DRAWER';
export const OPEN_DECLINE_JOB_DRAWER = 'OPEN_DECLINE_JOB_DRAWER';
export const OPEN_IMPORT_CONTACT_DRAWER = 'OPEN_IMPORT_CONTACT_DRAWER';
export const OPEN_CONNECT_LINKEDIN_DRAWER = 'OPEN_CONNECT_LINKEDIN_DRAWER';
export const OPEN_UPLOAD_LINKEDIN_CONTACTS_DRAWER = 'OPEN_UPLOAD_LINKEDIN_CONTACTS_DRAWER';
export const OPEN_CSV_CONTACTS_DRAWER = 'OPEN_CSV_CONTACTS_DRAWER';
