import React, {Component} from 'react';
import {
    TouchableOpacity,
    Image
} from 'react-native';

//components
import Text from '../Text/Text';

//styles
import styles from './styles';

export default class PrimaryButton extends Component {
    render() {
        const { style, textStyle, children, white, small, onPress, disabled=false, icon } = this.props;
        const buttonStyles = [
            styles.buttonStyle,
            white ? styles.white : styles.green,
            small ? styles.small : styles.big,
            disabled ? styles.disabled : {},
            style
        ];

        const textStyles = [
            styles.textStyle,
            white ? styles.textForWhite : styles.textForGreen,
            disabled ? styles.textForDisabled : {},
            textStyle
        ];

        return (
            <TouchableOpacity
                onPress={onPress}
                style={buttonStyles}
                disabled={disabled}
            >
                {icon && <Image source={icon} style={styles.icon} resizeMode="contain"/>}
                <Text style={textStyles}>{children}</Text>
            </TouchableOpacity>
        );
    }
}
