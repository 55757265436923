import { PixelRatio } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { $green } from '../../../../generalStyles';

export default EStyleSheet.create({
    profileWrapper: {
        width: "100%",
    },
    profile: {
        backgroundColor: 'white'
    },
    userInfoWrapper: {
        width: '100%',
        height: 250,
        backgroundColor: '#ffffff'
    },
    coverWrapper: {
        width: '100%',
        height: 180
    },
    userCover: {
        width: '100%',
        height: '100%',
        opacity: 0.8
    },
    infoWrapper: {
        marginTop: -120,
        marginLeft: 60,
        height: 170,
        width: '70%',
        flexDirection: 'row'
    },
    avatar: {
        width: 170,
        height: '100%',
        borderRadius: 120,
        borderWidth: 2,
        borderColor: '#ffffff',
        boxShadow: '0px 2px 7px rgba(116, 113, 113, 0.317499)'
    },
    nameWrapper: {
        paddingLeft: 20,
        paddingTop: 30
    },
    name: {
        fontSize: 25,
        fontWeight: 'bold',
        color: '#ffffff'
    },
    organization: {
        fontSize: 16,
        color: '#ffffff',
        marginVertical: 1
    },
    position: {
        fontSize: 16,
        marginVertical: 1,
        color: '#ffffff'
    },
    statsWrapper: {
        marginTop: -50,
        marginLeft: 240,
        height: 70,
        width: '50%',
        flexDirection: 'row'
    },
    statContainer: {
        width: 150,
        height: '100%',
        padding: 12
    },
    count: {
        fontSize: 25,
        fontWeight: 'bold',
        color: $green
    },
    description: {
        fontSize: 12,
        opacity: 0.6
    }
});
