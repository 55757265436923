import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  newCompany: {
    borderBottomWidth: 0,
  },
  contactButtons: {
    height: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '20px',
  },
  text: {
    paddingTop: '3px',
    paddingLeft: '10px',
  },
  buttonView: {
    backgroundColor: '#FFF',
    borderBottomColor: '#e5e5e5',
    borderBottomWidth: 1,
  },
});
