import Actions from '../../RouteActions';
import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';
import { redirectToCurrentStep } from '../../services/stepsService';
import baseService from '../../services/baseService';
import { SET_SPINNER_VISIBLE, SET_SPINNER_HIDDEN } from '../../constants/actions/appActions';
import { getDeviceFingerprint } from '../../AsyncStorage';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';

export function resetPassword(formValues) {
    return dispatch => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        if (formValues.step != 'create_password') {
            authService.updatePassword({ ...formValues }).then(response => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                if(response.data.response_code === 0){
                    Actions.signInEmail();
                    notification.ref.show({ message: 'Your password has been changed.' });
                }
                else{
                    notification.ref.show({ message: response.data.error, isError: true });
                }
            }).catch(response => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                notification.ref.show({ message: response.data.error, isError: true });
            });
        }
        else {
            getDeviceFingerprint().then(deviceFingerprint => {
                authService.setPassword({ ...formValues, 'device_fingerprint': deviceFingerprint }).then(response => {
                    baseService.addAuthToken(response.data.payload.access_token).then(() => {
                        dispatch({ type: SET_SPINNER_HIDDEN });
                        dispatch({ type: authActionsConstants.SIGN_UP_SUCCESS, payload: response.data.payload });
                        dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
                        notification.ref.show({ message: 'Your password has been created successfully.' });
                        redirectToCurrentStep(response.data.payload.current_incomplete_step);
                    })
                }).catch(response => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                    notification.ref.show({ message: response.data.error, isError: true });
                });
            })
        }
    }
}
