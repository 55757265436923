import Actions from '../../RouteActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import _ from 'lodash';
import * as organizationsActionsConstants from '../../constants/actions/organizationsActions';
import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as userService from '../../services/usersService';
import { redirectToCurrentStep } from '../../services/stepsService';

export function checkUserEmployer() {
    return dispatch => {
        return userService.checkUserEmployer().then(response => {
            dispatch({ type: organizationsActionsConstants.CHECK_USER_EMPLOYER, payload: response.data.payload });
            return response;
        });
    };
}

export function createInvitationStep() {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        return userService.createInvitationStep().then(response => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: stepsActionsConstants.GET_STEP_SUCCESS, payload: response.data.payload.current_incomplete_step });
            if(response.data.payload.current_incomplete_step === 'confirm_email') {
                dispatch({type: authActionsConstants.SET_MAGIC_LINK_DATA, payload: {response_code: 0}});
                Actions.magicLink({
                    email: response.data.payload.email,
                    skipInitialSending: true,
                    confirmationFlow: true
                });
            } else {
                redirectToCurrentStep(response.data.payload.current_incomplete_step);
            }
            return response;
        });
    };
}

export const setLoader = (flag) => {
    return dispatch => {
        if(flag) {
            dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        }
        else {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        }
    }
}
