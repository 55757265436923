import React, { useEffect } from 'react';
import { Image, ImageBackground, Text, TouchableOpacity, View } from 'react-native';
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
import { parse } from 'search-params';
import { formLayout } from '../../generalStyles';
import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import logo from '../../assets/img/logo.png';
import styles from './styles';
import * as actions from './actions';

const Unsubscribe = (props) => {
    const {
        isMobile,
        location,
        spinnerVisible,
        makeUnsubscribe
    } = props;

    const mobile = isMobile();

    useEffect(() => {
      const params = parse(location.search);
      const { token } = params;
      makeUnsubscribe(token);
    }, []);


  const RootComp = ({ children }) => {
    return (
      <ImageBackground
        source={backgroundBoxImage}
        style={mobile ? styles.elementWrapperMobile : styles.elementWrapper}
      >
        { children }
      </ImageBackground>
    );
  };

    return <RootComp>
      <View style={mobile ? styles.logoWrapperMobile : styles.logoWrapper}>
          <Image source={logo} style={mobile ? styles.logoMobile : styles.logo} />
        </View>
      {!spinnerVisible && 
        (<View style={mobile ? styles.containerMobile : styles.container}>
            <View>    
              <View style={styles.textContainer}>
                <Text
                  style={[formLayout.row, mobile ? styles.inviteTextMobile : styles.inviteText, styles.textSecondary]}
                >
                 You’ve unsubscribed
                </Text>
              </View>
              <View style={styles.actionButtonContainer}>
                <Text style={[styles.gainAccessText, styles.textSecondary]}>You’ll no longer receive roundup emails from Boon.</Text>
              </View>
            </View>   
      </View>)
      }
    </RootComp>;
};

const mapStateToProps = (state) => {
    return {
      spinnerVisible: state.appReducer.spinnerVisible
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      makeUnsubscribe: (token) => {
        dispatch(actions.unsubscribeRefrralRoundup(token));
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Unsubscribe));
