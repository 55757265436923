import { StyleSheet } from 'react-native';
import { $green, $greish } from '../../generalStyles';

export default StyleSheet.create({
    wrapperStyle: {
         borderColor: 'rgba(0, 0, 0, .1)',
         borderWidth: 2,
         borderRadius: 4,
    },
    inputStyle: {
        fontSize: 14,
        height: 53,
        fontWeight: 'bold',
        width: '100%',
        borderBottomColor: '#c1c1c1',
        opacity: 0.7,
    },
    labelStyle: {
        fontSize: 10,
        color: $greish,
        fontWeight: 'bold',
        paddingHorizontal: 10,
        backgroundColor: '#fff'
    },
    labelStyleFocused: {
        color: $green
    }
});
