import baseService from './baseService.js';

export function getNotificationsList(params) {
    return baseService.get('/applicants/notifications', {params});
}

export function setRead(ids) {
    return baseService.post('/applicants/notifications/read/', {
        notification_ids: ids
    });
}
