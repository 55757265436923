import React, {Component} from 'react';
import App from 'components/src/App'
import { setUpSecrets } from 'components/src/helpers/secureKeyStore';
import { Helmet } from 'react-helmet';

import packageJson from '../../../package.json';

export default class AppWeb extends Component {
    constructor(props) {
        super(props);

        // add env's to key chain
        setUpSecrets().then(_ => {
            console.log('secrets setup success');
        }).catch(err =>
            console.error('Keystore setup error', err)
        );
        if(window.top !== window.self){
            document.body.innerHTML = "Access Denied";
        }
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <meta name="boon-version" content={packageJson.version}/>
                </Helmet>
                <App/>
            </React.Fragment>
        );
    }
}
