import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    buttonStyle: {
        backgroundColor: '#FFFFF',
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'row',
        height: 47
    },
    opacityWidth: {
        width: '100%'
    },
    textStyle: {
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '75%',
        color: '#808080',
        textDecorationLine: 'underline'
    }
});
