import React, {Component} from 'react';
import { View, TouchableOpacity } from 'react-native';

import styles   from './styles';
import Checkbox from '../../components/Checkbox/Checkbox';

class FormInputCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: false };
    }

    render() {
        const {
            textComponent, wrapperStyle, checkboxStyle,
            onSubmitEditing, onValueChange, checked, ...inputProps
        } = this.props;

        return (
            <View style={[styles.wrapperStyle, wrapperStyle]}>
                <Checkbox
                    style={[{marginRight: 10}, checkboxStyle]}
                    checked={checked}
                    onPress={() => onValueChange()}
                    {...inputProps}/>
                {textComponent()}
            </View>
        );
    }
}

export default FormInputCheckbox;
