import React, { useMemo } from 'react';
import { View } from 'react-native';
import { isEmpty } from 'lodash-es';

//components
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import { CANDIDATE_PROGRESS_VALUES } from '../../../constants/strengthenQuestionsConstants';
import newMemberAppUrl from '../../../services/newMemberAppUrl';

//styles
import styles from './styles';

const CandidateProgressBar = ({ data = {} }) => {
  const { status, uuid } = data;
  if (isEmpty(data) || status === 'archived' || status == 'hired') {
    return null;
  }

  const progress = useMemo(() => {
    const { links_json, resume_file_links, questions, skills } = data;
    let progressPercentage = 20;
    CANDIDATE_PROGRESS_VALUES.forEach((value) => {
      if (value === 'skills' && !isEmpty(skills)) {
        progressPercentage += 10;
      } else if (value === 'resume_file_links' && !isEmpty(resume_file_links)) {
        progressPercentage += 10;
      } else if (value === 'links_json' && !isEmpty(links_json) && !isEmpty(links_json[0].value)) {
        progressPercentage += 10;
      } else if (value === 'diversity_hire' && questions[value]) {
        progressPercentage += 10;
      } else if (!isEmpty(questions[value])) {
        progressPercentage += 10;
      }
    });
    return progressPercentage;
  }, [data]);

  return (
    <View style={styles.strengthenWrapper}>
      <View style={styles.strengthenText}>
        <span>Application Strength</span>
        <span style={{ color: '#0FBC71' }}>{progress}%</span>
      </View>
      <View style={styles.progressBar}>
        <View
          style={[StyleSheet.absoluteFill, { borderRadius: 8, backgroundColor: '#0FBC71', width: `${progress}%` }]}
        />
      </View>
      <View style={styles.strengthenButton}>
        <PrimaryButton
          style={styles.secondaryButton}
          small
          disabled={progress === 100}
          onPress={() => {
            window.open(`${newMemberAppUrl}/strengthen-questions/candidate?uuid=${uuid}`, '_self');
          }}
        >
          Strengthen Application
        </PrimaryButton>
      </View>
    </View>
  );
};

export default CandidateProgressBar;
