import { StyleSheet } from "react-native";

export default StyleSheet.create({
  root: {
    height: "100%",
    width: "100%",
  },
  item: {
    borderRadius: 4,
    borderColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 1,
    flexDirection: "row",
    padding: 8,
    marginTop: 4,
    alignItems: "center",
  },
  itemNo: {
    fontSize: 16,
    fontWeight: "700",
    opacity: 0.6,
    marginHorizontal: 8,
  },
  itemImg: {
    width: 30,
    height: 30,
    marginHorizontal: 8,
  },
  itemText: {
    marginHorizontal: 8,
  },
});
