import { googleApiKey } from '../constants/webKeys';
import { GOOGLE_PLACES_API_KEY } from '../constants/secureKeyStore';

// For storing key
let addSecret = async (key, value) => {
    localStorage.setItem(key, value);
}

// For retrieving key
export const getSecret = async (name) => {
    try {
        return localStorage.getItem(name)
    } catch (error) {
        return false;
    }
}


let removeSecret = async (name) => {
    try {
        return localStorage.removeItem(name)
    } catch (error) {
        return false;
    }
}

export const setUpSecrets = async () => {
    Promise.all([
        removeSecret(GOOGLE_PLACES_API_KEY),
        addSecret(GOOGLE_PLACES_API_KEY, googleApiKey),
    ])
}
