import React, { useState, useEffect } from 'react';
import { Animated, View, TouchableOpacity, Image, Linking, Platform } from 'react-native';
import { Button } from 'react-native-material-ui';
import { connect } from 'react-redux';

import * as AsyncStorage from '../../AsyncStorage';
import { apiSeverUrl } from '../../services/baseService';
import Alert from '../../wrappers/Alert/Alert';
import Avatar from '@material-ui/core/Avatar';

//components
import AutoSuggestSearchBox from '../../components/AutoSuggestComponent/AutoSuggestSearchBox';
import AutoSuggestSearchResult from '../../components/AutoSuggestComponent/AutoSuggestSearchResult';
import Text from '../../components/Text/Text';
import CloseButton from '../../components/CloseButton/CloseButton';

//images
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import networkIcon from '../../assets/img/boon_network_icon.png';

//styles
import { listElement, common, $green } from '../../generalStyles';
import googleIconSvg from '../../assets/svg/google-icon.svg';
import outlookIconSvg from '../../assets/svg/outlook-icon.svg';
import RenderMultipleEmails from './RenderMultipleEmails';
import ChevronUpIcon from '../../assets/svg/chevron-up.svg';
import ChevronDownIcon from '../../assets/svg/chevron-down.svg';
import { googleSSOUrl } from '../../helperFunctions';
import { isEmpty } from 'lodash-es';
import AddIcon from '@material-ui/icons/Add';
import SyncIcon from '@material-ui/icons/Sync';
import styles from './styles';

const defaultPlaceholder = 'Search contact name to refer';
const renderElementWrap = {
  flexDirection: 'row',
  alignItems: 'stretch',
};

const lineStyles = {
  borderBottomWidth: 1,
  borderColor: '#00000020',
  backgroundColor: '#fff',
};

const contactHeaderCont = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const ChevronIconStyle = {
  width: 25,
  height: 18,
};

const ChevronCont = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 5,
  paddingLeft: 3,
};

const avatarBgColors = [
  '#705335',
  '#781F19',
  '#464531',
  '#FFA420',
  '#82898F',
  '#2F4538',
  '#1E1E1E',
  '#999950',
  '#CC0605',
  '#1B5583',
  '#ED760E',
  '#3B3C36',
  '#287233',
  '#015D52',
  '#102C54',
  '#193737',
  '#474A51',
];

const CONTACT_SOURCES = {
  google_people: 'Google',
  outlook: 'Outlook',
  phone_book: 'Phone',
  manual: 'Boon',
  csv_import: 'CSV',
  vcf_import: 'VCF',
  linkedin: 'LinkedIn',
  boon_contact: 'Boon',
};

const SelectUser = (props) => {
  const {
    googleConnected,
    unsubscribeGoogle,
    outlookConnected,
    unsubscribeOutlook,
    suggestionsLoading,
    customPlaceholder,
    onEndUserSelect,
    page,
    isMobile,
    showAllOnLoad,
    suggestions,
    clearState,
  } = props;
  const [openAddNetwork, setOpenAddNetwork] = useState(false);
  const [accordianStates, setAccordianStates] = useState([]);
  const [inputValue, setInputValue] = useState();

  const onChangeInput = (newValue) => {
    setInputValue(newValue.trim());
  };

  const handleToggle = (id) => {
    let newAccordianStates = accordianStates.map((el) => {
      if (el.id === id) {
        return {
          id: id,
          open: !el.open,
        };
      } else {
        return el;
      }
    });
    setAccordianStates(newAccordianStates);
  };

  const isAccordianOpen = (id) => {
    if (accordianStates.length > 0) {
      let accordianElement = accordianStates.find((el) => el.id === id);
      return isEmpty(accordianElement) ? false : accordianElement.open;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let newAccordianStates = [];
    suggestions.forEach((el) => {
      if (el.emails.length > 1) {
        newAccordianStates.push({
          id: el.id,
          open: false,
        });
      }
    });
    setAccordianStates(newAccordianStates);
  }, [suggestions]);

  const getFirstLetter = (item) => {
    if (item.full_name) {
      return item.full_name[0].toUpperCase();
    } else if (item.first_name) {
      return item.first_name[0].toUpperCase();
    } else {
      return '';
    }
  };

  const renderSuggestElement = ({ item, index }) => {
    const showName = item.full_name || item.first_name;
    const showNumber = item.phone_numbers[item.phone_numbers.length - 1] || item.phone_number;
    const image = item.avatar ? { url: item.avatar } : defaultUserAvatar;
    const elementWrapper = page == 'refer' ? renderElementWrap : listElement.elementWrapper;
    const sources = item.source.map((source) => CONTACT_SOURCES[source]);
    const uniqueSources = [...new Set(sources)].join('/');

    return (
      <TouchableOpacity
        style={[elementWrapper, page === 'refer' ? lineStyles : null]}
        onPress={() => onEndUserSelect(item)}
      >
        <View style={{ ...listElement.imageWrapper, justifyContent: 'flex-start' }}>
          <Avatar style={{ marginRight: 10, backgroundColor: `${avatarBgColors[index % 17]}` }}>{`${getFirstLetter(
            item
          )}${item.last_name ? item.last_name[0].toUpperCase() : ''}`}</Avatar>
        </View>
        <View style={listElement.elementBody}>
          <View style={listElement.infoWrapper}>
            <View style={contactHeaderCont}>
              <View style={listElement.nameAndSource}>
                {(Boolean(showName) && (
                  <Text style={listElement.title} numberOfLines={1}>
                    {item.full_name ? item.full_name : `${item.first_name} ${item.last_name}`}
                  </Text>
                )) || (
                  <Text style={listElement.title} numberOfLines={1}>
                    {item.phone_number || item.email}
                  </Text>
                )}
                {Boolean(uniqueSources) && (
                  <>
                    <Text> </Text>
                    <Text style={listElement.description} numberOfLines={1}>
                      ({uniqueSources})
                    </Text>
                  </>
                )}
              </View>
              {item.emails.length > 1 && (
                <TouchableOpacity onPress={() => handleToggle(item.id)} style={ChevronCont}>
                  <Text>+{item.emails.length - 1} more</Text>
                  {item.emails.length > 1 && isAccordianOpen(item.id) ? (
                    <img src={ChevronUpIcon} style={ChevronIconStyle} />
                  ) : (
                    <img src={ChevronDownIcon} style={ChevronIconStyle} />
                  )}
                </TouchableOpacity>
              )}
            </View>
            {Boolean(showNumber) && (
              <Text style={listElement.description} numberOfLines={1}>
                {showNumber}
              </Text>
            )}
            {Boolean(item.email) && (
              <Text style={listElement.description} numberOfLines={1}>
                {item.email}
              </Text>
            )}

            <Text style={listElement.description} numberOfLines={1}>
              {item.organization_name}
            </Text>
            <Text style={listElement.description} numberOfLines={1}>
              {item.company_position}
            </Text>
            {item.emails.length > 1 && isAccordianOpen(item.id) && <RenderMultipleEmails item={item} />}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const handleGoogleConnectPress = () => {
    AsyncStorage.getAccessToken().then((accessToken) => {
      if (googleConnected) {
        Alert.alert('Disconnect from Google?', "You won't be able to see your new Google connections on Boon anymore", [
          { text: 'Cancel', style: 'cancel', onPress: () => null },
          {
            text: 'Disconnect',
            onPress: () => unsubscribeGoogle(accessToken),
          },
        ]);
      } else {
        googleSSOUrl(accessToken);
      }
    });
  };

  const handleOutlookConnectPress = () => {
    const mobile = isMobile ? isMobile() : false;

    AsyncStorage.getDeviceFingerprint().then((response) => {
      AsyncStorage.getAccessToken().then((accessToken) => {
        if (outlookConnected) {
          Alert.alert(
            'Disconnect from Outlook?',
            "You won't be able to see your new Outlook connections on Boon anymore",
            [
              { text: 'Cancel', style: 'cancel', onPress: () => null },
              {
                text: 'Disconnect',
                onPress: () => unsubscribeOutlook(accessToken),
              },
            ]
          );
        } else {
          if (mobile) {
            // Actions.web({ title: 'Authenticate Outlook', url: `${apiSeverUrl}/sign_in_outlook?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
          } else {
            let form = document.createElement('form');
            form.method = 'POST';
            form.action = `${apiSeverUrl}/auth/microsoft_graph_auth?access_token=${accessToken}&from_web_app=${
              Platform.OS == 'web'
            }`;
            document.body.appendChild(form);
            form.submit();
          }
        }
      });
    });
  };

  return (
    <View style={common.flex1}>
      <AutoSuggestSearchBox
        placeholder={customPlaceholder ? customPlaceholder : defaultPlaceholder}
        onChangeInput={onChangeInput}
        suggestionsKey="contacts"
        service="autocompleteNetwork"
        referDrawer={false}
        searchType="networkSuggestions"
        showAllOnLoad
        page="refer"
      />

      <AutoSuggestSearchResult
        keyField="first_name"
        suggestElement={renderSuggestElement}
        loading={suggestionsLoading}
        showAllOnLoad
        page="refer"
      />
      <View style={styles.buttonView}>
        <TouchableOpacity
          onPress={() =>
            onEndUserSelect({
              first_name: '',
              last_name: '',
              phone_number: '',
              linkedin_link: '',
              email: '',
              user_id: '',
              name: '',
            })
          }
          style={styles.contactButtons}
        >
          <View style={styles.button}>
            <AddIcon />
            <Text style={styles.text}>Add new contact</Text>
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.buttonView}>
        <TouchableOpacity onPress={() => setOpenAddNetwork(true)} style={styles.contactButtons}>
          <View style={styles.button}>
            <SyncIcon />
            <Text style={styles.text}>Connect your network</Text>
          </View>
        </TouchableOpacity>
      </View>

      {openAddNetwork && (
        <View
          style={{
            position: 'fixed',
            bottom: 0,
            top: 0,
            left: 0,
            right: 0,
          }}
        >
          <View
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.25)',
              cursor: 'default',
            }}
          ></View>
          <Animated.View
            style={{
              borderTopLeftRadius: 12,
              borderTopRightRadius: 12,
              backgroundColor: '#FFFFFF',
              height: 284,
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: 24,
            }}
          >
            <CloseButton
              style={{ position: 'absolute', right: 16, bottom: 238 }}
              onPress={() => setOpenAddNetwork(false)}
            />
            <Text style={{ fontSize: 14, fontWeight: 600, textAlign: 'center' }}>Add Your Network</Text>
            <Text style={{ fontSize: 14, textAlign: 'center', marginTop: 20 }}>
              We won&apos;t contact anyone without your consent. You can remove your contacts from Boon at any time.
            </Text>
            <View style={[common.row, common.justifyContentCenter, { marginTop: 48 }]}>
              <View style={[common.alignItemsCenter, { padding: 10, width: 100, height: 100, marginRight: 12 }]}>
                <Image source={googleIconSvg} resizeMode="contain" style={{ width: 34, height: 34 }} />
                <Text style={{ fontSize: 12, marginTop: 4 }}>Google</Text>
                <Button
                  raised
                  disabled={googleConnected}
                  text={googleConnected ? 'Added' : 'Add'}
                  upperCase={false}
                  onPress={() => handleGoogleConnectPress()}
                  style={{
                    container: {
                      backgroundColor: googleConnected ? '#DDDDDD' : '#01A67D',
                      borderRadius: 10,
                      marginTop: 12,
                      height: 16,
                      width: 78,
                    },
                    text: {
                      fontSize: 10,
                      color: googleConnected ? '#01A67D' : '#FFFFFF',
                    },
                  }}
                />
              </View>
              <View style={[common.alignItemsCenter, { padding: 10, width: 100, height: 100, marginLeft: 12 }]}>
                <Image source={outlookIconSvg} resizeMode="contain" style={{ width: 34, height: 34 }} />
                <Text style={{ fontSize: 12, marginTop: 4 }}>Outlook</Text>
                <Button
                  raised
                  disabled={outlookConnected}
                  text={outlookConnected ? 'Added' : 'Add'}
                  upperCase={false}
                  onPress={() => handleOutlookConnectPress()}
                  style={{
                    container: {
                      backgroundColor: outlookConnected ? '#DDDDDD' : '#01A67D',
                      borderRadius: 10,
                      marginTop: 12,
                      height: 16,
                      width: 78,
                    },
                    text: {
                      fontSize: 10,
                      color: outlookConnected ? '#01A67D' : '#FFFFFF',
                    },
                  }}
                />
              </View>
            </View>
          </Animated.View>
        </View>
      )}
    </View>
  );
};

const mapStateToProps = (state, props) => {
  return {
    googleConnected: state.authReducer.currentUser.sync_google_people_contacts,
    outlookConnected: state.authReducer.currentUser.outlook_authenticated,
    unsubscribeGoogle: (accessToken) => {
      dispatch(settingActions.unsubscribeGoogle(accessToken));
    },
    unsubscribeOutlook: (accessToken) => {
      dispatch(settingActions.unsubscribeOutlook(accessToken));
    },
    suggestions: state.suggestionsReducer['networkSuggestions'].suggestions,
    suggestionsLoading: state.suggestionsReducer['networkSuggestions'].suggestionsLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectUser);
