import React, {Component} from 'react';
import {
    View,
    Image,
    TouchableOpacity,
    Platform
} from 'react-native';
import AnimatedCircularProgress from '../../wrappers/circularProgress/animatedCircularProgress';
import Actions     from '../../RouteActions';
import { connect } from 'react-redux';

//components
import Text from '../Text/Text';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

//styles
import {$green} from '../../generalStyles';
import styles   from './styles';

//images
import userIcon from '../../assets/img/user_icon.png';

//utils
import {calcPermissionPercentage, calcProfilePercentage} from '../../helperFunctions';

class ProfileCompleteBar extends Component {
    render() {
        const { permissions, currentUser, signInLoading } = this.props;
        const web = Platform.OS == 'web';

        const percentage = calcPermissionPercentage(permissions) + calcProfilePercentage(currentUser);

        return (!web && percentage < 100 && !signInLoading) ? (
            <TouchableOpacity onPress={() => web ? null : Actions.completeProfile()}>
                <View style={styles.componentWrapper}>
                    <View style={styles.infoWrapper}>
                        <View style={styles.fillProgressWrapper}>
                            <AnimatedCircularProgress
                                size={34}
                                width={2}
                                fill={percentage}
                                rotation={0}
                                tintColor={$green}
                                backgroundColor="#ebebeb"
                            />
                            <Image source={userIcon} style={styles.userIcon}/>
                        </View>
                        <Text styles={styles.infoText}>
                            Your profile is {percentage}% complete
                        </Text>
                    </View>
                    {!web &&
                    <PrimaryButton
                        small
                        style={styles.button}
                        onPress={() => Actions.completeProfile()}>
                        Complete
                    </PrimaryButton>}
                </View>
            </TouchableOpacity>
        ) : null;
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.authReducer.permissions,
        currentUser: state.authReducer.currentUser,
        signInLoading: state.authReducer.signInLoading
    }
};

export default connect(mapStateToProps)(ProfileCompleteBar);
