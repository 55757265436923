import React, { Component } from 'react';
import { reduxForm, Field, change } from 'redux-form';
import {
    View,
    Image,
    TouchableOpacity,
    Text,
    Keyboard,
    Picker,
    PickerItem,
    Button
} from 'react-native';
import Actions from '../../RouteActions';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import { RESET_CONFIRM_NUMBER } from '../../constants/actions/auth';
import * as actions from './actions';
import * as locationActions from '../../globalActions/locationsActions';
import * as redirectActions from '../../globalActions/redirectActions';

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import SignUpButton from '../../components/SignupButton/SignupButton';
import FormInput from '../../components/FormInput/FormInput';
import FormInputNew from '../../components/FormInputNew/FormInputNew';
import ListElementButton from '../../components/ListElementButton/ListElementButton';
import SignUpBase from '../SignUpBase/SignUpBase';
import SelectCompany from '../SelectCompany/SelectCompany';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import ModalDropdown from '../../wrappers/modalDropdown/modalDropdown';
import GoogleAddress from '../../components/GoogleAddress/GoogleAddress';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';

//images
import editIcon from '../../assets/img/edit_icon.png';
import defaultAvatar from '../../assets/img/default_avatar.png';

//styles
import styles from './styles';
import { formLayout, $green } from '../../generalStyles';
import { withGetScreen } from 'react-native-getscreen';

//utils
import { notification } from '../../helperFunctions';
import responsiveStyles from '../../responsiveStyles';
import LocationSelect from '../../components/LocationSelect/LocationSelect';
import AuthLayout from '../../layouts/AuthLayout/AuthLayout';

class SignUpLocation extends Component {
    constructor(props) {
        super(props);
        this.zip_codeRef = null;

        this.state = {
            zip_code: '',
            pickerSelectedVal: '',
            flag: true,
            location: null,
        };
    }

    componentDidMount() {
        const { getCountriesList, userCurrentStep, redirect, currentUser, saveCurrentStep } =
            this.props;
        if (userCurrentStep != 'location') {
            return redirect();
        }
        getCountriesList();
        saveCurrentStep();
        if (currentUser.place_id) {
            this.setState({
                location: {
                    city: currentUser.city,
                    country_iso_code: currentUser.country_iso_code,
                    country_name: currentUser.country_name,
                    full_address: currentUser.full_address,
                    lat: '',
                    lon: '',
                    place_id: currentUser.place_id,
                    state: currentUser.state
                },
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.flag) {
            this.setState({ flag: false });
            nextProps.setFieldValue('country', nextProps.countries[0].value);
        }
    }

    submitForm = (e) => {
        e.preventDefault();
        const { formValues, invitations } = this.props;
        if (!this.state.location) {
            notification.ref.show({
                message: 'Please select a location.',
                isError: true
            });
        } else {
            formValues.invitations = invitations.length >= 1;
            Object.keys(this.state.location).map((key) => {
                formValues[key] = this.state.location[key];
            });
            this.props.onSubmit(formValues);
        }
    };

    dropDownMobile = () => {
        return (
            <View style={styles.mobileDropdownContainer}>
                <Picker
                    selectedValue={this.state.pickerSelectedVal}
                    onValueChange={(item) => {
                        this.props.setFieldValue('country', item);
                        this.setState({ pickerSelectedVal: item });
                    }}
                >
                    {this.props.countries.map((item, i) => {
                        return (
                            <Picker.Item
                                label={item.label}
                                value={item.label}
                                key={i}
                            />
                        );
                    })}
                </Picker>
            </View>
        );
    };

    dropDownWeb = () => {
        return (
            <View style={styles.selectStyles}>
                <Select
                    options={this.props.countries}
                    labelField="label"
                    valueField="value"
                    onChange={(value) => {
                        this.props.setFieldValue(
                            'country',
                            value[0] && value[0].value
                        );
                    }}
                    color="#c1c1c1"
                    name="country"
                    placeholder="* Select Country"
                    searchable={true}
                    clearable={true}
                    style={styles.dropDownStyle}
                />
            </View>
        );
    };

    handleChange = (values) => {
        this.props.change('zip_code', values['zip_code']);
        this.setState({
            zip_code: values['zip_code']
        });
    };

    handleChangeLocation = (location) => {
        this.setState({ location });
    };

    renderSignUpLocationForm = () => {
        const mobile = this.props.isMobile();
        const { signUpLoading, currentUser } = this.props;
        const { location } = this.state;
        const mainHeading = 'Welcome to Boon';
        const subHeading = 'Please fill the following form to proceed';

        return (
            <AuthLayout>
                <View>
                    <View style={styles.formWrapper}>
                        <View style={styles.header}>
                            <Text style={styles.headerText}>Your Location</Text>
                        </View>
                        <View style={styles.textPadding}>
                            <LocationSelect
                                value={location}
                                onChange={this.handleChangeLocation}
                                locationPresent={!!currentUser.place_id}
                            />
                        </View>
                        <View style={styles.buttonWrapper}>
                            <SignUpButton
                                onClick={(e) => {
                                    this.submitForm(e);
                                }}
                                disabled={!location}
                            >
                                Confirm
                            </SignUpButton>
                        </View>
                    </View>
                </View>
            </AuthLayout>
        );
    };

    render() {
        if (this.props.countries.length > 1) {
            return this.renderSignUpLocationForm();
        } else {
            return <View />;
        }
    }
}

SignUpLocation = reduxForm({ form: 'signUpLocationForm' })(
    withGetScreen(SignUpLocation)
);

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.signUpLocationForm &&
            state.form.signUpLocationForm.values
                ? state.form.signUpLocationForm.values
                : {},
        countries: state.locationsReducer.countries,
        currentUser: state.authReducer.currentUser,
        userCurrentStep: state.stepsReducer.step,
        invitations: state.organizationsReducer.userOrganizations
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('signUpLocationForm', field, value));
        },
        onSubmit: (formValues) => {
            dispatch(actions.submitForm(formValues));
        },
        getCountriesList: () => {
            dispatch(locationActions.getCountries());
        },
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
        saveCurrentStep: () => {
            dispatch({type: stepsActionsConstants.GET_STEP_SUCCESS, payload: 'location'})
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpLocation);
