import React, { Component } from 'react';
import {
    View,
    TouchableOpacity,
    Image
} from 'react-native';
import {connect}   from 'react-redux';
import {change}    from 'redux-form';

import AutoSuggestComponent from '../../components/AutoSuggestComponent/AutoSuggestComponent';
import Text                 from '../../components/Text/Text';
import { withGetScreen }    from 'react-native-getscreen';

//images
import orgDefaultAvatar from '../../assets/img/default_org_avatar.png';

//styles
import { listElement, common }    from '../../generalStyles';
import respStyles         from '../../responsiveStyles';
import * as generalStyles from '../../generalStyles';
import styles             from './styles';
import RouteHistory from '../../RouteHistory';

class SelectCompany extends Component {
    constructor(props) {
        super(props);
        this.renderSuggestElement = this.renderSuggestElement.bind(this);
        this.renderAddNewElement  = this.renderAddNewElement.bind(this);
    }

    renderSuggestElement({item}) {
        const { onSelectCompany, selectFullObj } = this.props;
        const image = item.logo_image_url ? {uri: item.logo_image_url} : orgDefaultAvatar;

        const renderElementWrap = {
            flexDirection: 'row',
            alignItems: 'stretch',
            backgroundColor: '#fff'
        };

        const lineStyles = {
            borderBottomWidth: 1,
            borderColor: '#c3c3c3'
        };

        const elementWrapper = this.props.page == "refer" ? renderElementWrap : listElement.elementWrapper;
        return (
            <TouchableOpacity
                style={[elementWrapper, this.props.page === 'refer' ? lineStyles : null]}
                onPress={() => onSelectCompany(selectFullObj ? item : item.name)}>
                <View style={listElement.imageWrapper}>
                    <Image style={listElement.image} source={image} resizeMode="contain" />
                </View>
                <View style={listElement.elementBody}>
                    <View style={listElement.infoWrapper}>
                        <Text style={listElement.title}>{item.name}</Text>
                        <Text style={listElement.description}>{item.industry_name}</Text>
                        <Text style={listElement.headquarterLocation}>{item.headquarter_location}</Text>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    renderAddNewElement(newCompanyName) {
        const { onSelectCompany } = this.props;
        return (
            <TouchableOpacity
                style={listElement.elementWrapper}
                onPress={() => onSelectCompany({name: newCompanyName})}>
                <View style={listElement.imageWrapper}>
                    <Image style={listElement.image} source={orgDefaultAvatar} resizeMode="contain" />
                </View>
                <View style={listElement.elementBody}>
                    <View style={[listElement.infoWrapper, styles.newCompany]}>
                        <Text style={listElement.title}>Other</Text>
                        <Text style={listElement.description}>Add as new company</Text>
                    </View>
                </View>
            </TouchableOpacity>
        )
    }

    render() {
        const mobile = this.props.isMobile();
        const { page, showAllOnLoad } = this.props;

        return (
            <View style={common.flex1}>
                <View style={!mobile && [respStyles.flexCenter, respStyles.height100, respStyles.justifyFlexStart]}>
                    <View  style={styles.parent}>
                        <AutoSuggestComponent
                            skipNavOnBackPress={this.props.skipNavOnBackPress}
                            onBackButtonPress={this.props.onBackButtonPress}
                            initialValue={this.props.selectedCompany ? this.props.selectedCompany.name : ''}
                            suggestElement={this.renderSuggestElement}
                            addNewElement={this.props.creatable && this.renderAddNewElement}
                            service="autocompleteOrganizations"
                            suggestionsKey="organizations"
                            searchType="organizationsSuggestions"
                            suggestHeader="Suggested Organizations"
                            placeholder={ page && page.includes('general-info') ? 'Search or add recent company' : 'Organization'}
                            referDrawer={this.props.referDrawer}
                            emptySearch={this.props.emptySearch}
                            showAllOnLoad
                            page={page}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

export default withGetScreen(SelectCompany);
