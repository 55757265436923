import React, { Component } from 'react';
import {
    View,
    Image,
    TouchableOpacity,
    Keyboard
} from 'react-native';
import Actions from '../../../RouteActions';
import { connect } from 'react-redux';
import { parse } from 'search-params'

//component
import LocationMap from '../../../components/LocationMap/LocationMap'
import defaultUserAvatar from '../../../assets/img/default_user_avatar.png'
import Text from '../../../components/Text/Text';

//images
import { withGetScreen } from 'react-native-getscreen';

//styles
import styles from './styles';
import { formLayout, listElement } from '../../../generalStyles';


//utils
import { notification } from '../../../helperFunctions';
import * as redirectActions from '../../../globalActions/redirectActions';

class ReferrerDetails extends Component {
    render() {
        const mobile = this.props.isMobile();
        const { user, refer_request } = this.props;
        const image = user && user.avatar_thumb ? { uri: user && user.avatar_thumb } : defaultUserAvatar;
        return (
            <View style={[styles.detailsWrapper]}>
                {Boolean(user && refer_request) &&
                    <View style={formLayout.noSpaceRow}>
                        <View style={[listElement.imageWrapper, { alignItems: 'center' }]}>
                            <Image style={styles.image} source={image} resizeMode="contain" />
                        </View>
                        <View style={{ padding: 5, width: '80%' }}>
                        <Text style={styles.fullname}>{user.full_name}</Text>
                        <Text style={styles.position}>{user.company_position}</Text>
                        <Text style={styles.position}>{user.workplace_as_a_string}</Text>
                            <Text style={styles.description}>Message:</Text>
                        <Text style={styles.message}>{refer_request.message}</Text>
                        </View>
                    </View>}
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        redirect: () => {
            dispatch(redirectActions.redirect());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(ReferrerDetails));
