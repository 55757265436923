import baseService from './baseService.js';

export function autocompleteOrganizations(query) {
    return baseService.get('/autocomplete/organizations', {
        params: {
            query,
            list_type: 'long'
        }
    });
}

export function autocompleteJobs(query, params) {
    return baseService.get('/autocomplete/jobs', {
        params: {
            query,
            ...params
        }
    });
}

export function globalSearch(query, params, searchModel = null, sortBy = null, filters = {}) {
    return baseService.get('/global_search', {
        params: {
            query,
            searchModel: searchModel,
            sortBy: sortBy,
            filters: filters,
            ...params
        }
    });
}

export function autocompleteNetwork(query, params) {
    return baseService.get('/user_contacts', {
        params: {
            query,
            target_connection: 'refer_connections',
            ...params
        }
    });
}

export function autocompleteTracker(query) {
    return baseService.get('/applicants/jobs_tracker_search', {
        params: {
            query
        }
    });
}
