import React, { Component } from 'react';
import {
    View,
    Image,
    ScrollView,
    Text,
} from 'react-native';

import * as postLoginAction from './actions';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import GlobalGradient        from "../../components/GlobalGradient/GlobalGradient";
import styles from '../SignUp/styles';
import ownStyles from './styles';
import logo from '../../assets/img/logo.png';
import Spinner from 'react-native-loading-spinner-overlay';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Actions from '../../RouteActions';
class PostLogin extends Component {
    componentDidMount(){
       let params = new URLSearchParams(location.search);
       this.props.getSignedInUser(params.get('token'));
    }

    render() {
        const { mobile, signedInUser } = this.props;
        return (
          <ScrollView>
            <GlobalGradient  gradients={['#32CF6C', '#2AAA89']} angle='90deg'>
              <KeyboardAwareScrollView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={[styles.pageWrapper]}
                enableAutomaticScroll>
                <View style={[styles.body]}>
                    <Image source={logo} style={mobile ? styles.mobileLogo : ownStyles.logo}/>
                    <Text style={{textAlign:'center',fontSize:'30px', color:'white',fontWeight:'700', marginLeft:'20px', marginBottom:'100px'}}>
                        Authenticating  ...
                    </Text>
                </View>
                <Spinner visible={true} />
              </KeyboardAwareScrollView>
            </GlobalGradient>
          </ScrollView>
        )
    }
}

const mapStateToProps = (state) => ({
  signedInUser: state.authReducer.signedInUser,
});

const mapDispatchToProps = (dispatch) => ({
  getSignedInUser: (token) => {
      dispatch(postLoginAction.getSignedInUser(token));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PostLogin));
