import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    wrappedStyle: {
        paddingTop: 30
    },
    wrapperStyle: {
        marginVertical: 16,
        marginHorizontal: 0
    },
    jobTitle: {
        fontWeight: 'bold'
    },
    viewJobButton: {
        height: 40,
        padding: '0px 20px',
        width: '137px',
        marginBottom: 5,
        backgroundColor: '#0FBC71'
    }
});
