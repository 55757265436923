import React, { useMemo, useState, useEffect } from 'react';
import styles from './styles';
import { ImageBackground, View, Text, Image } from 'react-native';
import backgroundBoxImage from '../../assets/img/bg-invite-image.svg';
import BoonLogo from '../../assets/img/boon_logo.svg';
import FormInput from '../../components/FormInput/FormInput';
import { Field, reduxForm } from 'redux-form';
import { withGetScreen } from 'react-native-getscreen';
import { connect } from 'react-redux';
import { formLayout } from '../../generalStyles';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import * as actions from './actions';
import * as redirectActions from '../../globalActions/redirectActions';
import useWindowSize from '../../hooks/useWindowResize';

let ResetPassword = ({
    formValues,
    onSubmit,
    currentUser,
    public_job_application,
    initialValues,
    userCurrentStep,
    signup,
    redirect
}) => {

    const size = useWindowSize();
    const [error, setError] = useState(false);

    const disabled = useMemo(() => {
        return (
            !formValues.password ||
            formValues.password.length < 8 ||
            !formValues.confirm_password ||
            formValues.password !== formValues.confirm_password
        );
    }, [formValues.password, formValues.confirm_password]);

    const submitForm = () => {
        if (!currentUser.email && userCurrentStep == 'create_password') {
            formValues.email = initialValues && initialValues.email;
            formValues.step = userCurrentStep;
        } else {
            formValues.email = initialValues && initialValues.email;
            formValues.step = userCurrentStep;
        }
        if (public_job_application) {
            formValues.public_job_application = true;
        }
        if (initialValues === undefined) {
            formValues.email = currentUser.email;
        }
        onSubmit(formValues);
    };

    const getLogoStyles = useMemo(() => {
        if (size.width > 550) {
            return styles.boonLogo;
        } else {
            return styles.mobileBoonLogo;
        }
    }, [size]);

    useEffect(() => {
      if(userCurrentStep !== 'create_password'){
        redirect();
      }
    }, [userCurrentStep])

    const getInputStyles = useMemo(() => {
        if (!error) {
            return styles.inputStyle;
        } else {
            return [styles.inputStyle, styles.inputError];
        }
    }, [error]);

    const verifyPasswords=(e)=>{
        let enteredVal = e.target.value;
        if (enteredVal !== formValues.password) {
            setError(true);
        }
        else{
            setError(false);
        }
    };

    return (
        <ImageBackground
            source={backgroundBoxImage}
            style={styles.elementWrapper}
        >
            <Image source={BoonLogo} style={getLogoStyles} />
            <View>
                <View style={styles.contentCont}>
                    <Text style={styles.heading}>Reset Password</Text>
                    <Field
                        placeholder="New password"
                        name="password"
                        component={FormInput}
                        wrapperStyle={[
                            formLayout.fullWidth,
                            styles.inputWrapper
                        ]}
                        secureTextEntry
                        labelStyle={styles.labelStyle}
                        inputStyle={styles.inputStyle}
                        inputVariant="outlined"
                    />
                    <Field
                        placeholder="Confirm password"
                        name="confirm_password"
                        component={FormInput}
                        wrapperStyle={[formLayout.fullWidth, styles.confirmPasswordWrapper]}
                        secureTextEntry
                        labelStyle={styles.labelStyle}
                        inputStyle={getInputStyles}
                        inputVariant="outlined"
                        onChange={verifyPasswords}
                    />
                    {error && (
                            <View style={styles.errorMessageCont}>
                                <Text style={styles.errorMessage}>
                                    Check your password
                                </Text>
                            </View>
                        )}
                    <PrimaryButton
                        onPress={submitForm}
                        disabled={disabled}
                        style={disabled ? styles.disabledButton : styles.button}
                        textStyle={
                            disabled
                                ? styles.disabledButtonText
                                : styles.buttonText
                        }
                    >
                        {signup || userCurrentStep === 'create_password'
                            ? 'Complete sign up'
                            : 'Reset password'}
                    </PrimaryButton>
                </View>
            </View>
        </ImageBackground>
    );
};

const mapStateToProps = (state) => {
    return {
        formValues:
            state.form.resetPassword && state.form.resetPassword.values
                ? state.form.resetPassword.values
                : {},
        currentUser: state.authReducer.currentUser,
        userCurrentStep: state.stepsReducer.step
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (params) => {
            params.oldAccount = 1;
            dispatch(actions.resetPassword(params));
        },
        redirect: () => {
            dispatch(redirectActions.redirect());
        }
    };
};

ResetPassword = reduxForm({ form: 'resetPassword' })(ResetPassword);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withGetScreen(ResetPassword));
