// ###### WARNING ######
// DON'T UPDATE LINE:13 export default memberAppUrl['development']
// As it is used for deployment. In case of change in line number
// update packages/web/package.json build script build-staging and build-prod with new line number
// ###### WARNING ######

const memberAppUrl = {
    development: process.env.REACT_APP_API_URL || 'http://localhost:5000',
    staging: 'https://staging-app.goboon.co',
    production: 'https://app.goboon.co',
    demo: 'https://demo-app.goboon.co'
};

const environment = process.env.REACT_APP_ENVIRONMENT || 'development';

export default memberAppUrl[environment] || memberAppUrl['development'];
