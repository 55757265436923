import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    formWrapper: {
        padding: 16
    },
    field: {
        marginBottom: 16
    },
    submitButton: {
        marginTop: 32
    },
    backButton: {
        marginTop: 20,
        backgroundColor: '#afafaf'
    }
});
