import React, { Component } from 'react';
import { find } from 'lodash-es';
import ReactHtmlParser from 'react-html-parser';
import {
    View, Platform,
    ScrollView,
    Image,
    TouchableOpacity, Linking
} from 'react-native';
import {connect} from 'react-redux';

//images
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import editIcon from '../../assets/img/edit_icon.png';
import shareIcon from '../../assets/img/share_icon.png';
import linkedInIcon from '../../assets/img/linkedIn_green.png';
import resumeIcon from '../../assets/img/link_green.png';
import portfolioIcon from '../../assets/img/portfolio_green.png';
import websiteIcon from '../../assets/img/internet_green.png';

// Styles
import styles from './styles';

//components
import Text from '../../components/Text/Text';
import ListElementButton from '../../components/ListElementButton/ListElementButton';

//constants
import linksTypes from '../../constants/linksTypes';

//actions
import * as networkActions from '../../globalActions/networkActions';
import { openUserProfileDrawer, openReferFormDrawer } from '../../DrawerActions';

//utils
import {getCurrentOrganizationForUser, validateUrl} from '../../helperFunctions';
class UserProfile extends Component {

    renderlinks(links){
        return(
            links.map((link, index) => {
                let linkValue = link.value && link.value.includes('http') ? link.value : `http://${link.value}`;
                linkValue = linkValue.trim();
                const link_text = find(linksTypes, { value: link.type });
                const label = link_text ? link_text.label : 'Other Link'
                let icon = resumeIcon;
                switch (label) {
                    case 'LinkedIn':
                        icon = linkedInIcon;
                        break;
                    case 'Personal Website':
                    case 'Other Link':
                        icon = websiteIcon;
                        break;
                    case 'Portfolio':
                        icon = portfolioIcon;
                        break;
                }

                return (
                    Boolean(linkValue) && validateUrl(linkValue, label) &&
                    <TouchableOpacity
                        style={[styles.whiteBlockButton]}
                        onPress={() => Linking.openURL(linkValue)}
                        key={`${linkValue}_${index}`}
                    >
                        <Image source={icon} style={styles.linkIcon}/>
                        <Text style={styles.buttonText}>{label}</Text>
                    </TouchableOpacity>
                )
            })
        )
    }
    render() {
        const { selectedUser, userFormParams, requestConnection, currentUser, acceptConnection, currentUserOrganization,
                openProfileDrawer, openReferDrawer } = this.props;

        const avatar = selectedUser.avatar_thumb ? {uri: selectedUser.avatar_thumb} : defaultUserAvatar;
        const links = selectedUser.links_json ? JSON.parse(selectedUser.links_json) : [{'type': 'ln'}];
        const basicInfoStyles = [styles.basicInfo];

        const initialValues = {
            name: `${selectedUser.first_name} ${selectedUser.last_name}`,
            email: selectedUser.email,
            phone_number: selectedUser.phone_number,
            user_id: selectedUser.id,
            organization_id: currentUserOrganization
        };

        if (selectedUser.id !== currentUser.id) {
            basicInfoStyles.push(styles.basicInfoTall);
        }

        let statsStyle = [styles.stats];
        let address = '';

        if (!selectedUser.country_iso_code || (!selectedUser.state && !selectedUser.city)) {
            address = `${selectedUser.full_address}`
        }
        else {
            if (selectedUser.city) {
                address += selectedUser.city;
            }
            if (selectedUser.state) {
                address +=", "+ selectedUser.state;
            }
            if (selectedUser.country_iso_code) {
                address +=", "+ selectedUser.country_iso_code;
            }
        }

        if(Platform.OS === 'web') statsStyle.push(styles.widthFitContent)

        return (
            <ScrollView>
                <View style={basicInfoStyles}>
                    <View style={styles.userInfoWrapper}>
                        <Image source={avatar} style={Platform.OS !== 'web' ? styles.userAvatarMobile : styles.userAvatar}/>
                        <View style={styles.userInfo}>
                            <View style={styles.nameWrapper}>
                                <Text style={Platform.OS !== 'web' ? styles.nameMobile : styles.name}>{selectedUser.first_name} {selectedUser.last_name}</Text>
                                {userFormParams &&
                                    <TouchableOpacity style={styles.editProfileButton}
                                        onPress={() => openProfileDrawer(userFormParams)}>
                                    <Image source={editIcon} style={styles.editIcon}/>
                                </TouchableOpacity>
                                }
                            </View>
                            {selectedUser.workplace &&
                                <Text style={styles.organization}>{selectedUser.workplace.name}</Text>
                            }
                            <Text style={styles.position}>{selectedUser.company_position}</Text>
                            {
                                selectedUser.address &&
                                <Text style={styles.position}>{selectedUser.address}</Text>
                            }
                        </View>
                    </View>

                    <View style={statsStyle}>
                        <View style={styles.statBox}>
                            <Text style={styles.statValue}>{selectedUser.user_referrals_sent_count || 0}</Text>
                            <Text style={styles.statTitle}>Referrals Sent</Text>
                        </View>
                        <View style={styles.statBox}>
                            <Text style={styles.statValue}>{selectedUser.user_referrals_received_count || 0}</Text>
                            <Text style={styles.statTitle}>Referrals Received</Text>
                        </View>
                        <View style={styles.statBox}>
                            <Text style={styles.statValue}>{selectedUser.user_connections_count || 0}</Text>
                            <Text style={styles.statTitle}>People in Network</Text>
                        </View>
                        <View style={styles.statBox}>
                            <Text style={styles.statValue}>{selectedUser.user_companies_count || 0}</Text>
                            <Text style={styles.statTitle}>Companies</Text>
                        </View>
                    </View>

                    {
                        selectedUser.id !== currentUser.id &&
                        <View style={styles.buttonsWrapper}>
                            {
                                Boolean(!selectedUser.status || (selectedUser.status === 'pending')) &&
                                <ListElementButton style={styles.listButton} onPress={() => requestConnection(selectedUser.id)}>
                                    Connect
                                </ListElementButton>
                            }
                            {
                                selectedUser.status === 'requested' &&
                                <ListElementButton style={styles.listButtonDisabled} textStyle={styles.listButtonDisabledText}>
                                    Requested
                                </ListElementButton>
                            }
                            {
                                selectedUser.status === 'connected' &&
                                <ListElementButton style={styles.listButtonDisabled} textStyle={styles.listButtonDisabledText}>
                                    Connected
                                </ListElementButton>
                            }
                            {
                                selectedUser.status === 'awaiting' &&
                                <ListElementButton style={styles.listButton} onPress={() => acceptConnection(selectedUser.connectionId)}>
                                    Accept
                                </ListElementButton>
                            }
                            <ListElementButton
                                icon={shareIcon}
                                onPress={() => openReferDrawer(initialValues)}
                                style={[styles.listButton, { marginLeft: 8 }]}
                            >
                                Refer
                            </ListElementButton>
                        </View>
                    }
                </View>

                <View style={styles.additionalInfoWrapper}>
                    {Boolean(userFormParams) && <Text style={styles.about}>About</Text>}
                    {
                        Boolean(userFormParams || selectedUser.bio) &&
                        <View style={[styles.whiteBlock, styles.bioWrapper]}>
                            {
                                selectedUser.bio &&
                                <Text style={styles.bioText}> { ReactHtmlParser(selectedUser.bio)} </Text> ||
                                <View style={styles.emptyBioWrapper}>
                                    <Text style={styles.bioText}>
                                        Present yourself to the Boon network
                                    </Text>
                                    <TouchableOpacity onPress={() => openProfileDrawer(userFormParams)}>
                                        <Text style={[styles.bioLink]}> add a bio. </Text>
                                    </TouchableOpacity>
                                </View>
                            }
                        </View>
                    }
                    {this.renderlinks(links)}
                </View>

            </ScrollView>
        )
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        currentUserOrganization: getCurrentOrganizationForUser(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        requestConnection: userId => {
            dispatch(networkActions.requestConnection(userId));
        },
        acceptConnection: connectionId => {
            dispatch(networkActions.acceptConnection(connectionId));
        },
        openProfileDrawer: initialValues => {
            dispatch(openUserProfileDrawer(initialValues))
        },
        openReferDrawer: initialValues => {
            dispatch(openReferFormDrawer(initialValues))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
