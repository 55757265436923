import { $green } from '../../generalStyles';
import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
    screenWrapper: {
        position: 'relative',
        flex: 1,
        padding: 16,
        justifyContent: 'space-between'
    },
    bgImage: {
        height: '100% + 32',
        width: '100% + 32',
        position: 'absolute'
    },
    successIcon: {
        marginTop: 120,
        marginLeft: '37%',
        height: 160,
        width: 160
    },
    successTitle: {
        textAlign: 'center',
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 28
    },
    successDescription: {
        textAlign: 'center',
        color: '#fff',
        fontSize: 16,
        paddingHorizontal: 16
    },
    secondaryBtn: {
        marginTop: 8
    }
});
