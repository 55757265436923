import { StyleSheet } from "react-native";

export default StyleSheet.create({
  root: {
    height: "100%",
    width: "100%",
  },
  item: {
    borderRadius: 4,
    borderColor: "#f2f2f2",
    borderStyle: "solid",
    borderWidth: 1,
    flexDirection: "row",
    marginTop: 4,
    alignItems: "center",
    paddingHorizontal: 10,
    paddingVertical: 12,
  },
});
