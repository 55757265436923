import React, { Component } from 'react';
import { StyleSheet } from 'react-native';

import { Gradient } from 'react-gradient';

const styles = StyleSheet.create({
    container: {
        flex: 1,
        position: 'relative',
        paddingTop: 20,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

export default class GlobalGradient extends Component {
    render() {
        return (
            <Gradient
                gradients={[this.props.gradients]}
                property="background"
                gradientType="linear"
                angle={this.props.angle}
                style={Object.assign({}, styles.container, this.props.style)}>
                {this.props.children}
            </Gradient>
        )
    }
}
