import React, { useMemo } from 'react';
import { Button } from '@ant-design/react-native';
import {
  Image,
  View
} from 'react-native';

import styles from './styles';
import logo from '../../assets/img/green-logo.svg';
import Text from '../Text/Text';
import Actions from '../../RouteActions';

const Footer = () => {

  const displayButtons = useMemo(() => {
    return ['Press', 'Terms', 'Security', 'Privacy'].map((buttonText) =>
      <Button style={styles.commonButton} onPress={() => Actions.signUp()}>
      <Text style={styles.buttonText}>
          {buttonText}
      </Text>
      </Button>
    );
    },[]);

  return (
    <View style={[styles.publicFooter]}>
      <View style={[styles.copyrightContainer]}>
        <Image source={logo} style={styles.logoImage} resizeMode="center" />
        <Text style={styles.copyrightText}>
          Copyright © {(new Date().getFullYear())} Boon. All rights reserved.
        </Text>
      </View>
      <View />
      <View style={[styles.buttonsContainerFlex]}>
        {displayButtons}
      </View>
    </View>
  );
};

export default Footer;
