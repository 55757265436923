import { PixelRatio } from "react-native";
import EStyleSheet from "react-native-extended-stylesheet";

export default EStyleSheet.create({
  root: {
    width: "100%",
    maxWidth: 1000,
    padding: 30,
    paddingBottom: 0,
    margin: "auto",
  },
  section: {
    marginTop: 8,
    backgroundColor: "#FFFFFF",
    borderRadius: 4,
    overflow: "hidden",
  },
  sectionHead: {
    paddingHorizontal: 30,
    paddingTop: 34,
  },
  subSection: {
    marginTop: 20,
  },
  subTitleText: {
    color: "#959595",
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 18,
    marginBottom: 8,
  },
  text: {
    color: "#959595",
    fontSize: 16,
    lineHeight: 18,
  },
  tagsWrapper: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tag: {
    borderColor: "#959595",
    borderStyle: "solid",
    borderRadius: 4,
    borderWidth: 1,
    paddingVertical: 4,
    paddingHorizontal: 10,
    marginRight: 4,
    marginTop: 4,
  },
  tagText: {
    color: "#959595",
    fontSize: 12,
  },
  leftHeader: {
    width: '100%'
  }
});
