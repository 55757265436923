import Actions from '../../RouteActions';

import * as authService from '../../services/authService';
import * as authActionsConstants from '../../constants/actions/auth';
import { closeDrawer } from '../../DrawerActions';
import * as appActionsConstants from '../../constants/actions/appActions';

import { notification } from '../../../src/helperFunctions';

export function editCurrentUser(formValues) {
    return dispatch => {
        dispatch({ type: authActionsConstants.EDIT_CURRENT_USER });
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        authService.editCurrentUser(formValues).then(response => {
            Actions.myBoon();
            notification.ref.show({
                message: 'User details successfully changed'
            });
            dispatch({ type: authActionsConstants.EDIT_CURRENT_USER_SUCCESS, payload: response.data.payload.user });
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch(closeDrawer());

        }).catch(() => {
            notification.ref.show({
                message: 'Please fill all fields',
                isError: true,
              });
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        });
    };
}
