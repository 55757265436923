import React, { Component } from 'react';
import {
    View,
    TouchableOpacity,
} from 'react-native';
import Actions from '../../RouteActions';
import { connect } from 'react-redux';
import { withGetScreen } from 'react-native-getscreen';
import { parse } from 'search-params'

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Text from '../../components/Text/Text';
import OnBoardingBase from "../../components/OnboardingBase/OnboardingBase";
import JobSummaryDetails from './JobSummaryDetails/JobSummaryDetails';
import ReferrerDetails from './ReferrerDetails/ReferrerDetails';

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';

//utils
import * as actions from './actions';
import { notification } from '../../helperFunctions';
import PublicReferredJobDetails from '../../components/PublicReferredJobDetails/PublicReferredJobDetails';

class JobPost extends Component {
    componentDidMount() {
        const { getData, location, currentUser, referredJobData } = this.props;
        let params = parse(location.search);
        const { refer_token } = params

        if (!refer_token || refer_token === undefined) {
            notification.ref.show({ message: 'Invalid refer token', isError: true })
            Actions.home();
        }
        if (refer_token && currentUser.email) {
            getData(refer_token, true);
        } else if (refer_token) {
            getData(refer_token, false);
        }
    }

    handleNoThanks = () => {
        const { location } = this.props;
        let params = parse(location.search);
        const { refer_token } = params
        Actions.referDecline({ refer_token: refer_token });
    }

    render() {
        const mobile = this.props.isMobile();
        const { referredJobData } = this.props;
        return (
            <OnBoardingBase width={mobile ? {} : { width: '70%' }} childrenWrapperStyles={styles.mainWrapper}>
                <View >
                    <View style={[formLayout.row, styles.buttonsWrapper]}>
                        <TouchableOpacity style={[styles.loginButton]} onPress={() => Actions.signInEmail()}>
                            <Text style={styles.loginButtonText}>Login</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.loginButton} onPress={() => Actions.signUp()}>
                            <Text style={styles.loginButtonText}>Join</Text>
                        </TouchableOpacity>
                    </View>
                    <Text style={styles.title}>Job Summary </Text>
                    {referredJobData.job &&<PublicReferredJobDetails referralObj={referredJobData} />}
                    <Text style={[styles.title, styles.topBorder]}>Referrer Details</Text>
                    <ReferrerDetails user={referredJobData && referredJobData.referrer} refer_request={referredJobData && referredJobData.refer_request} />
                    <View style={[styles.actionButtonsContainer, formLayout.noSpaceRow]}>
                        <PrimaryButton
                            style={[styles.actionButton]}
                            onPress={() => Actions.publicJobApplication({
                                refer_request: referredJobData && referredJobData.refer_request,
                                showCreds: true
                            })}
                        >
                            I'm Interested
                        </PrimaryButton>
                        {/* <PrimaryButton
                            style={[styles.actionButton, styles.noThanksButton]}
                            onPress={() => { this.handleNoThanks() }}>
                            <Text style={styles.noThanksText}>No Thanks</Text>
                        </PrimaryButton>
                        <PrimaryButton style={[styles.actionButton]} onPress={() => Actions.publicReferral()}>Refer</PrimaryButton> */}
                    </View>
                </View>
            </OnBoardingBase>
        );
    }
}

const mapStateToProps = state => {
    return {
        referredJobData: state.refersReducer.referredJobData,
        currentUser: state.authReducer.currentUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getData: (token, userExist) => {
            dispatch(actions.getData(token, userExist));
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(JobPost));
