import React, { Component } from 'react';
import {
    Image,
    View,
    Text
} from 'react-native';

import icon from './assets/browse.png';
import iconActive from './assets/browse_icon_active.png';
import iconWhite from './assets/browse_icon_new.png';

import defaultStyles from "./styles";

export default class BrowseIcon extends Component {
    render() {
        const { focused, containerStyle, textStyle, isWhiteLogo } = this.props;
        const textStyles = [textStyle ? textStyle : defaultStyles.text];
        const icn = focused ? iconActive : (isWhiteLogo ? iconWhite : icon);

        if (focused) {
            textStyles.push(defaultStyles.textSelected)
        }

        let cntnrStyle = containerStyle ? containerStyle : defaultStyles.container;

        return (
            <View style={cntnrStyle}>
                <Image source={icn} style={defaultStyles.image} resizeMode="contain"/>
                <Text style={textStyles}>Browse</Text>
            </View>
        );
    }
}
