import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    buttonStyle: {
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '100%',
        flexDirection: 'row'
    },
    green: {
        backgroundColor: $green,
    },
    white: {
        borderColor: '#d1d8dc',
        backgroundColor: '#fff',
        borderWidth: 1
    },
    big: {
        width: '100%',
        height: 50
    },
    small: {
        width: 172,
        height: 40
    },
    textStyle: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    textForGreen: {
        color: '#fff'
    },
    textForWhite: {
        color: $green
    },
    disabled: {
        backgroundColor: '#F1F1F1',
        borderColor: '#aaaaaa',
        borderWidth: 1
    },
    textForDisabled: {
        color: '#aaaaaa'
    },
    icon: {
        marginRight: 5,
        height: 16,
        width: 16,
    }
});
