import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

//styles
import styles from './styles';

const SignUpBackButton = ({ disabled = false, children, onClick }) => (
    <View style={styles.buttonStyle}>
        <TouchableOpacity
            onPress={onClick}
            disabled={disabled}
            style={styles.opacityWidth}
        >
            <Text style={styles.textStyle}>{children}</Text>
        </TouchableOpacity>
    </View>
);

export default SignUpBackButton;
