import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    formWrapper: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        margin: 'auto',
        paddingHorizontal: 20,
        paddingVertical: 50,
        position: 'relative'
    },
    formWrapperToolTip: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        height: 305,
        margin: 'auto',
        paddingHorizontal: 20,
        paddingVertical: 50,
        position: 'relative'
    },
    header: {
        textAlign: 'center',
        paddingHorizontal: 40,
        paddingBottom: 15
    },
    headerText: {
        color: '#0FBC71',
        fontSize: 20,
        fontWeight: 'bold',
        letterSpacing: '0.8px',
        paddingBottom: 20
    },
    formFieldWrapper: {},
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF'
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)'
    },
    iconImg: {
        width: 16,
        height: 16
    },
    checkboxWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 41,
        marginBottom: 19,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 280
    },
    checkbox: {
        borderRadius: 4
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2'
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: 12
    },
    disabledButtonText: {
        color: '#808080',
        fontSize: 12
    },
    termAndConditionsParent: {
        marginLeft: 12,
        flexDirection: 'row'
    },
    termAndConditionsChild: {
        color: '#666666',
        fontSize: 12,
        width: 158,
        lineHeight: '18px'
    },
    termAndConditionsText: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
    },
    tooltip: {
        width: 20,
        height: 20,
        position: 'absolute',
        marginBottom: 50,
        position: 'absolute',
        top: 10,
        right: 10
    },
    signUpButton: {
        marginVertical: 32
    },
    centerAlign: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    descriptionWrapper: {
        textAlign: 'center',
        color: '#fff',
        opacity: 0.6,
        lineHeight: 24,
        fontWeight: 400,
        fontSize: 16
    },
    descriptionText: {
        paddingHorizontal: 35
    },
    inputsWrapper: {
        paddingBottom: 20
    },
    inputStyle: {
        fontSize: 30,
        fontWeight: 'normal',
        textAlign: 'center',
        borderBottomWidth: 3,
        color: '#000',
        opacity: 0.6,
        borderBottomColor: '#000',
        width: '15%',
        paddingBottom: 5,
        height: 60
    },
    numberTextWrapper: {
        paddingBottom: 30,
        paddingTop: 10,
        color: '#000',
        opacity: 0.4,
        fontSize: 12,
        fontStyle: 'italic',
        textAlign: 'center'
    },
    numberTextBold: {
        opacity: 0.4,
        fontWeight: 800
    },
    resendCode: {
        textAlign: 'center',
        fontWeight: 400,
        fontSize: 14,
        color: '#000',
        opacity: 0.6,
        lineHeight: '20px'
    },
    greenLink: {
        color: '#0FBC71',
        fontWeight: 800,
        opacity: 1,
        textDecorationLine: 'underline'
    },
    textPadding: {
        paddingTop: 15
    },
    formBottomWrapper: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    formBottomTextWhite: {
        color: '#FFFFFF',
        fontSize: 14,
        textAlign: 'center'
    },
    formBottomTextUnderline: {
        paddingTop: 8,
        textDecorationLine: 'underline'
    }
});
