import * as skillService from "../../../services/skillService";
import responseCodes from "../../../constants/responseCodes";

export const loadDefaultSkillOptions = (organizationID) => {
  const params = organizationID ? `?organization_id=${organizationID}` : '';
  return skillService.getSkills(params).then((response) => {
    if (response.data.response_code === responseCodes.OK) {
      return response.data.payload.skills;
    }
  });
};
