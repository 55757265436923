import React, { Component } from 'react';
import {
    View,
    Image,
    Linking,
    Platform
} from 'react-native';
import Permissions from '../../wrappers/permission/permission';
import Actions from '../../RouteActions';
import { reduxForm, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { withGetScreen } from 'react-native-getscreen';
import { getDeviceFingerprint, getAccessToken } from '../../AsyncStorage';
import { isUndefined } from 'lodash-es';
import { apiSeverUrl } from '../../services/baseService';

//styles
import styles from './styles';

//components
import FinishOnboardingBase from '../../components/FinishOnboardingBase/FinishOnboardingBase';
import Text from '../../components/Text/Text';
import FormInputCheckbox from '../../components/FormInputCheckbox/FormInputCheckbox';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

//images
import syncContactsIcon from '../../assets/img/sync_contacts_icon.png';
import locationIcon from '../../assets/img/location_icon.png';

//actions
import * as actions from './actions';
import * as initialScreenActions from '../InitialScreen/actions'
import { TouchableOpacity } from 'react-native-gesture-handler';
import { $green } from '../../generalStyles';
import { googleSSOUrl } from '../../helperFunctions';

class GrantPermissions extends Component {
    constructor(props) {
        super(props);

        this.requestPermissions = this.requestPermissions.bind(this);
    }

    componentDidMount() {
        const { updateCurrentUser } = this.props;
        updateCurrentUser();
    }

    requestPermissions() {
        const { updateCurrentUser, skipPermission } = this.props;
        Permissions.request('contacts').then(contactsResponse => {
            Permissions.request('notification').then(() => {
                Permissions.request('location', { type: 'whenInUse' }).then(() => {
                    updateCurrentUser().then(() => {
                        skipPermission().then(() => {
                            this.props.isMobile() ? Actions.followCompanies() : Actions.home()
                        });
                    });
                })
            })
        })
    }

    authenticateLinkedIn = () => {
        const mobile = this.props.isMobile();
        const { currentUser } = this.props;
        if (!currentUser.linkedin_authenticated) {
            getDeviceFingerprint().then(response => {
                getAccessToken().then(resp => {
                    if (mobile) {
                        Actions.web({ title: 'Authenticate LinkedIn', url: `${apiSeverUrl}/sign_in_linkedin?device_fingerprint=${response}&access_token=${resp}&web_app=false&permission_page=true` });
                    } else {
                        Linking.openURL(`${apiSeverUrl}/sign_in_linkedin?device_fingerprint=${response}&access_token=${resp}&web_app=true&permission_page=true`)
                    }
                });
            })
        }
        else return
    }

    onClickGoogleImport = () => {
        const { currentUser } = this.props;
        if (!currentUser.sync_google_people_contacts) {
            getAccessToken().then(accessToken => {
                googleSSOUrl(accessToken);
            });
        }
        else return
    }

    render() {
        const mobile = this.props.isMobile();
        const { formValues, update_invite_contact_option, currentUser, userInviteContact } = this.props;
        formValues.invite = userInviteContact
        return (
            <FinishOnboardingBase title="Grant permissions" showLogo={true}>
                <View style={styles.screenWrapper}>
                    <View style={styles.screenDescription} />
                    <View style={[styles.permissionWrapper, !mobile && styles.webStyles]}>
                        <Image source={syncContactsIcon} style={styles.permissionIcon} resizeMode="contain" />
                        <View style={mobile ? { width: '80%' } : { width: '90%' }}>
                            <Text style={styles.permissionTitle}>Sync your contacts</Text>
                            <Text style={styles.permissionDescription}>Get smart recommendations and relevant info based on the Contacts you know</Text>
                            <View style={styles.importButtonContainer}>
                                <View style={{ flexDirection: 'row' }}>
                                    <View style={{ width: '65%' }}></View>
                                    <View style={{ width: '35%' }}>
                                        <PrimaryButton
                                            onPress={this.onClickGoogleImport}
                                            style={[styles.buttons, { marginBottom: 10 }]}
                                            disabled={currentUser.sync_google_people_contacts}
                                        >
                                            Import Google contacts
                                            </PrimaryButton>
                                        <PrimaryButton
                                            onPress={this.authenticateLinkedIn}
                                            style={styles.buttons}
                                            disabled={currentUser.linkedin_authenticated}
                                        >
                                            Authenticate LinkedIn
                                        </PrimaryButton>
                                        <Field
                                            component={FormInputCheckbox}
                                            name='invite'
                                            checked={userInviteContact}
                                            onValueChange={() => {
                                                this.props.setFieldValue('invite', !formValues.invite)
                                                const data = { invite: !formValues.invite, user_id: currentUser.id }
                                                update_invite_contact_option(data)
                                            }}
                                            textComponent={() => {
                                                return (
                                                    <Text style={styles.inviteText}>Invite contacts</Text>
                                                );
                                            }}
                                        />
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>


                    {currentUser.sync_google_people_contacts && currentUser.linkedin_authenticated ?
                        <PrimaryButton small style={styles.confirmButton} onPress={this.requestPermissions}>
                            <Text style={[styles.skipButton, { color: '#fff' }]}>Continue</Text>
                        </PrimaryButton>
                        :
                        <TouchableOpacity small style={styles.confirmButton} onPress={this.requestPermissions}>
                            <Text style={styles.skipButton}>Skip</Text>
                        </TouchableOpacity>}

                </View>
            </FinishOnboardingBase >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formValues: state.form.grantPermissions && state.form.grantPermissions.values ? state.form.grantPermissions.values : {},
        currentUser: state.authReducer.currentUser,
        userInviteContact: state.authReducer.userInviteContact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('grantPermissions', field, value));
        },
        updateCurrentUser: () => {
            return dispatch(initialScreenActions.getCurrentUser())
        },
        skipPermission: () => {
            return dispatch(actions.submitPermissions());
        },
        update_invite_contact_option: (data) => {
            dispatch(actions.update_invite_contact_option(data));
        },
    };
};

GrantPermissions = reduxForm({ form: 'grantPermissions' })(GrantPermissions);


export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(GrantPermissions));
