import React, { Component, Fragment } from 'react';
import {connect} from 'react-redux';
import { ActivityIndicator, View, Text } from 'react-native';

//components
import UserProfile from '../../components/UserProfile/UserProfile';

//actions
import * as actions from './actions';
import {$green} from '../../generalStyles';
import DashboardBase from '../../components/DashboardBase/DashboardBase';

class ContactProfile extends Component {
    componentDidMount() {
        const { selectedUser, userId, getUserById } = this.props;
        if(userId) {
            getUserById(userId);
        }
    }

    renderPage = () => {
        const { selectedUser, selectedUserLoading } = this.props;
        return (
            <View title="Contact profile" hasBackButton>
                {selectedUserLoading &&
                    <ActivityIndicator style={{ marginTop: 20 }} size="large" color={$green} />
                    ||
                    <View style={{backgroundColor: 'white'}}>
                        <UserProfile selectedUser={selectedUser} />
                    </View>
                }
            </View>
        )
    }
    render() {
        const { selectedUser, selectedUserLoading, networkTab } = this.props;
        return (
            <Fragment>
                {
                    networkTab &&
                        <Fragment> { this.renderPage() } </Fragment>
                        ||
                        <DashboardBase
                            title={selectedUser.full_name ? `${selectedUser.full_name} Profile` : ''}
                            hasBackButton>
                            { this.renderPage() }
                        </DashboardBase>

                }

            </Fragment>

        );
    }
}

const mapStateToProps = state => {
    const { selectedUser, selectedUserLoading } = state.connectionsReducer;
    return {
        selectedUser,
        selectedUserLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getUserById: (userId) => {
            dispatch(actions.getUserById(userId));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactProfile);
