import React, { Component } from "react";
import { reduxForm, Field, change } from "redux-form";
import { View, Image, Platform } from "react-native";
import { connect } from "react-redux";
//component
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import FormInput from "../FormInput/FormInput";
import Text from "../Text/Text";
import GlobalGradient from "../GlobalGradient/GlobalGradient";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "react-native-loading-spinner-overlay";
import OrganizationStatus from "../../wrappers/organizationStatus/organizationStatus";
import * as refersActions from "../../globalActions/refersActions";
import FormInputCheckbox from "../FormInputCheckbox/FormInputCheckbox";
//images
import { withGetScreen } from "react-native-getscreen";
import logo from "../../assets/img/logo.png";
//styles
import styles from "../../screens/PublicJobDecline/styles";
import { formLayout } from "../../generalStyles";
import DrawerHeader from "./drawerHeader.js";
import { notification } from "../../helperFunctions";

class DeclineJobDrawer extends Component {
  state = {
    selectedReasonVal: "",
    selectedUserVal: "",
  };
  componentDidMount() {
    const {
      getReasons,
      formValues,
      refer_token,
      getUsers,
      setFieldValue,
      referUsers,
    } = this.props;
    const jobId = this.props.job_id && this.props.job_id.id;
    getReasons();
    getUsers(jobId);
    formValues.share_refer = true;
    setTimeout(() => {
      setFieldValue("user", [referUsers[0]]);
    }, 1000);
  }

  submitForm = (e) => {
    e.preventDefault();
    const { formValues, refer_token, job_id, currentUser } = this.props;
    formValues.refer_token = refer_token;
    formValues.job_id = job_id.id;
    formValues.current_user = currentUser && currentUser.id;

    if (formValues.reason.length === 0 || !formValues.comments) {
      notification.ref.show({
        message: "Please fill all mandatory fields",
        isError: true,
      });
    } else {
      this.props.onSubmit(formValues);
    }
  };

  onHandleReason = (e) => {
    const opt = this.props.declineReasons.find((op) => {
      if(e.length > 0){
        return op.value === e[0].value;
      }
    });
    this.setState((state) => {
      state.selectedReasonVal = opt ? opt.value : {};
      return state;
    });
    this.props.setFieldValue("reason", e);
  };

  onHandleUser = (e) => {
    const opt = this.props.referUsers.find((op) => {
      return op.value === e[0].value;
    });
    this.setState((state) => {
      state.selectedUserVal = opt.value;
      return state;
    });
    this.props.setFieldValue("user", e);
  };

  renderReferDeclineForm() {
    const mobile = this.props.isMobile();
    const web = Platform.OS === "web";
    const { signUpLoading, formValues, referUsers } = this.props;
    const secondHeading = "Where was the mismatch?";
    const thirdHeading =
      "Your feedback improves the quality and relevance of future referrals.";
    const reasons = this.props.declineReasons;
    return (
      <KeyboardAwareScrollView
        style={[{ flex: 1 }, !web && { marginTop: 30 }]}
        keyboardShouldPersistTaps="always"
        enableAutomaticScroll
        extraScrollHeight={20}
      >
        <DrawerHeader title="Decline Job" />
        <View style={styles.flexCenter}>
          <Text style={styles.headingDrawer}>{secondHeading}</Text>
          <Text style={styles.subHeadingDrawer}>{thirdHeading}</Text>
        </View>
        <View style={{ zIndex: 200 }}>
          <View>
            <View>
              {!web && <Text style={styles.labelHeading}>User *: </Text>}
              <View
                style={[formLayout.row, styles.paddingDrawer, { zIndex: 100 }]}
              >
                <OrganizationStatus
                  onChange={(e) => this.onHandleUser(e)}
                  selected={this.state.selectedUserVal}
                  name="user"
                  placeholder="Select User *"
                  dropdownValues={referUsers}
                  styles={[formLayout.fullWidth, { marginTop: 9 }]}
                  formSubmitted={() => {}}
                  labelField={"label"}
                  valueField={"value"}
                  customColor={"#2DB581"}
                />
              </View>
              {!web && <Text style={styles.labelHeading}>Reason *: </Text>}
              {reasons && (
                <View
                  style={[formLayout.row, styles.paddingDrawer, { zIndex: 50 }]}
                >
                  <OrganizationStatus
                    selected={this.state.selectedReasonVal}
                    onChange={(e) => this.onHandleReason(e)}
                    name="reasons"
                    placeholder="Reason(s) *"
                    dropdownValues={reasons}
                    styles={[formLayout.fullWidth, { marginTop: 9 }]}
                    formSubmitted={() => {}}
                    labelField={"label"}
                    valueField={"value"}
                    multi={true}
                    customColor={"#2DB581"}
                  />
                </View>
              )}

              <View
                style={[formLayout.row, styles.paddingDrawer, { zIndex: -9 }]}
              >
                <Field
                  component={FormInput}
                  name="comments"
                  placeholder="Comments *"
                  wrapperStyle={[formLayout.fullWidth, { zIndex: -9 }]}
                  onSubmitEditing={() => this.lastNameRef.focus()}
                  returnKeyType="next"
                  autoFocus
                />
              </View>
            </View>
            <View
              style={[
                formLayout.row,
                styles.paddingDrawer,
                { justifyContent: "center", zIndex: -9 },
              ]}
            >
              <PrimaryButton onPress={this.submitForm}>Submit</PrimaryButton>
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
    );
  }

  render() {
    return this.renderReferDeclineForm();
  }
}

DeclineJobDrawer = reduxForm({
  form: "decline_referral",
  enableReinitialize: true,
})(withGetScreen(DeclineJobDrawer));

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form.decline_referral && state.form.decline_referral.values
        ? state.form.decline_referral.values
        : {},
    declineReasons: state.refersReducer.declineReasons,
    referUsers: state.refersReducer.referUsers,
    currentUser: state.authReducer.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setFieldValue: (field, value) => {
      dispatch(change("decline_referral", field, value));
    },
    onSubmit: (formValues) => {
      dispatch(refersActions.submitDeclineReferral(formValues));
    },
    getReasons: () => {
      return dispatch(refersActions.getDeclineReasons());
    },
    getUsers: (job_id) => {
      return dispatch(refersActions.getReferUsers(job_id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeclineJobDrawer);
