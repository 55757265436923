import React, { useEffect } from 'react'
import ReactDropZone from '../../../components/ReactDropZone/ReactDropZone.web'
import { View } from 'react-native';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import BoonTextField from '../../../components/Basic/BoonTextField';
import Text from '../../../components/Text/Text';
import InputAdornment from "@material-ui/core/InputAdornment";
import LinkIcon from '@material-ui/icons/Link';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { Button } from '@ant-design/react-native';
import styles from '../../ApplyJob/styles';
import XIcon from '../../../assets/svg/icon-x';
import WorldIcon from '../../../assets/svg/world-icon';


import './StrengthQuestionsSteps.css'

const StrengthQuestionsStepTwo = ({handleUploadUserFiles,handleUploadUserLinks, hideStrengthQuestions,
    strengthQuestions : { filesUploaded, userLinksArray, filesUploadedLinks},
     strengthQuestionsSettings : 
     {attach_file , attach_url ,
        how_good_are_they ,
        know_the_person_level,
        worked_with_person,
        open_for_new_opportunities_status,
        diversity_hire,
        know_the_person_level_radio,
        why_are_they_good_fit,
    }, handleHideToggleButtons}) => {
        const dropDownOptions = [
            {value:'re',name: 'Resume'},
            {value:'po',name: 'Portfolio'},
            {value:'pw',name: 'Website'}
        ]

     useEffect(() => {
        if (userLinksArray.length === 0){
            handleUploadUserLinks([{ type: 'pw', value: '' }])
        }
        if((!how_good_are_they &&
            !know_the_person_level_radio &&
            !why_are_they_good_fit &&
           !know_the_person_level &&
           !worked_with_person &&
           !open_for_new_opportunities_status &&
           !diversity_hire) || (!attach_file && !attach_url)
         ){
            return handleHideToggleButtons()
         }
       }, [])
     
            
    const handleDeleteLink = (index) => {
        let linksArray = userLinksArray
           if (index !== 0 && linksArray.length > 1) {
               let links = [...linksArray].filter((item,i) => !(i === index));
               handleUploadUserLinks(links)
           }
       };
     const  handleLinkChange = (index, e) => {
        let value = e.target.value.trim();
        let links = [...userLinksArray];
        links[index].value = value;
        handleUploadUserLinks(links)
    };
    const handleDropdownLinkChange = (index, e) => {
        let links = [...userLinksArray];
        links[index]['type'] = e.target.value;
        handleUploadUserLinks(links)
    };
    if(hideStrengthQuestions){
        return null
    }else{
      return (
        <div className='strength_form_step_1_wrapper'>
            {attach_file && 
                <>
                    <h3>Attach File</h3>
                    <ReactDropZone setFilesArray={handleUploadUserFiles} filesArray={filesUploaded}/>
                    {filesUploaded.length > 0 && filesUploaded.map((file,index)=> {
                        return <div key={file.name} className='files_upload_wrapper'>
                            <div className='files_upload_child'>
                                <DescriptionOutlinedIcon />
                                <span>{file.name && file.name.length > 25 ? `${file.name.substring(0,24)}...` : file.name}</span>
                                    </div>
                                        <div> 
                                            <DeleteOutlineOutlinedIcon  
                                                onClick={()=>handleUploadUserFiles([...filesUploaded].filter((e,i)=>i !== index),'filesUploaded')}/>
                                        </div>
                                    </div>
                            })}
                    {filesUploadedLinks.length > 0 && filesUploadedLinks.map((file,index)=> {
                            return <div key={file.name} className='files_upload_wrapper'>
                                <div className='files_upload_child'>
                                    <DescriptionOutlinedIcon />
                                    <span>{file.name && file.name.length > 25 ? `${file.name.substring(0,24)}...` : file.name}</span>
                                        </div>
                                            <div> 
                                                <DeleteOutlineOutlinedIcon  
                                                    onClick={()=>handleUploadUserFiles([...filesUploadedLinks].filter((e,i)=>i !== index),'filesUploadedLinks')}/>
                                            </div>
                                        </div>
                                    })}
                            <hr/>
                </>
            }
            {
                attach_url && 
                <>
                
                    <h4>Additional URLs</h4>
                        <View >
                        {userLinksArray.length > 0 && userLinksArray.map((link, index) => {
                            return (
                                <div name={`link-${index}`} className="links-div">
                                    <View style={{width:'30%' , marginBottom:'10px'}}>
                                           <Select
                                            style={{color:'#959595', width:'100px'}}
                                            value={link.type}
                                            disableUnderline
                                            IconComponent={(props) => <KeyboardArrowDownIcon {...props}/>}
                                            onChange={(e) => handleDropdownLinkChange(index, e)}
                                            >
                                            {dropDownOptions
                                            .map(menuItem =>
                                            <MenuItem key={menuItem.name} value={menuItem.value}>{menuItem.name}</MenuItem> )}
                                        </Select>
                                    </View>
                                    <div className='boon_field_parent boon_field_wrapper'>

                                    <BoonTextField
                                    label={link.value ? 'URL' : 'Type or paste url'}
                                    variant="outlined"
                                    fullWidth
                                    style={index < 1 ? {width: '100%'} : {width: '85%'} }
                                    onChange={(e) => handleLinkChange(index, e)}
                                    value={link.value}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            style={{ color: "rgb(15, 188, 113)" }}
                                            >
                                            <WorldIcon/>
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                                        {index > 0 &&
                                <IconButton onClick={() => handleDeleteLink(index)}>
                                    <XIcon style={{ color: '#CCCCCC' }}/>
                                </IconButton>
                                        }
                                    </div>
                                </div>
                                );
                            })}
                        <div className="link-button-main">
                            <Button
                                style={styles.linkBtn}
                                onPress={()=>handleUploadUserLinks([...userLinksArray,{ type: dropDownOptions[0].value, value: '' } ])}
                                >
                                <Text style={{color: '#aaa', fontSize: 14}}>Add More</Text>
                            </Button>
                        </div>
                    </View>
                    </>
                }
        </div>
  )}
}

export default StrengthQuestionsStepTwo
