import { StyleSheet } from "react-native";
import { $green } from "../../../../generalStyles";

export default StyleSheet.create({
  container: {
    flex: 1,
  },
  screenWrapper: {
    padding: 8,
    flex: 1,
  },
  listElement: {
    borderRadius: 5,
    backgroundColor: "#fff",
    overflow: "hidden",
    marginBottom: 5,
    marginTop: 5,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 14,
    paddingBottom: 3,
  },
  groupListElement: {
    borderRadius: 5,
    backgroundColor: "#fff",
    overflow: "hidden",
    marginBottom: 5,
    marginTop: 0,
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 0,
    paddingBottom: 3,
  },
  groupListElement: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  topSection: {
    marginBottom: 14,
  },
  topSectionText: {
    color: "#444444",
    fontSize: 14,
  },
  textBold: {
    fontWeight: "700",
  },
  timestamp: {
    color: "#959595",
    fontSize: 11,
  },
  activityContent: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 5,
    paddingRight: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  groupActivityContent: {
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 5,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent:'space-between',
  },
  buttonsWrapper: {
    flexDirection: "row",
  },
  buttonStyle: {
    flex: 1,
    alignItems: "flex-start",
    backgroundColor: "#fff",
    borderWidth: 0,
    margin: 7,
  },
  referButtonStyle: {
    flex: 1,
    alignItems: "flex-start",
    backgroundColor: "#fff",
    borderWidth: 0,
    margin: 7,
    height:'fit-content',
    width:'fit-content',
  },
  buttonTextStyle: {
    paddingTop: 2,
  },
  imageWrapper: {
    paddingLeft: 8,
  },
  elementBody: {
    paddingRight: 8,
  },
  elementBodyMoved: {
    marginLeft: 60,
  },
  paddingLeftFiftyRightTwenty: {
    paddingLeft: 60,
    paddingRight: 20,
  },
  sectionBreaker: {
    borderTopColor: "#dadada",
    borderTopWidth: 1,
    paddingBottom: 4,
  },
  infoWrapperMoved: {
    marginLeft: -52,
  },
  bottomElementBody: {
    borderBottomWidth: 0,
  },
  openingTopElementBody: {
    paddingBottom: 8,
    paddingLeft: 8,
  },
  permissionWrapper: {
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: "#fff",
    borderRadius: 5,
    alignItems: "center",
  },
  permissionIcon: {
    height: 30,
    width: 30,
    marginRight: 15,
  },
  permissionTitle: {
    color: $green,
    marginBottom: 3,
    fontSize: 14,
    fontWeight: "bold",
  },
  permissionDescription: {
    fontSize: 12,
    opacity: 0.7,
    paddingRight: 80,
  },
  screenWrapper: {
    flex: 1,
  },
  titlesWrapper: {
    padding: 16,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  members: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#6c7f7d",
  },
  thisMonth: {
    fontSize: 12,
    color: "#6f8281",
  },
  elementWrapper: {
    backgroundColor: "transparent",
  },
  elementWrapperLeader: {
    backgroundColor: "#fff",
  },
  currentUserAvatar: {
    borderWidth: 1,
    borderColor: $green,
  },
  polygon: {
    position: "absolute",
    height: 48,
    width: 48,
  },
  positionWrapper: {
    flex: 0,
    borderRadius: 20,
    height: 40,
    minWidth: 40,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  wrapperStylesForList: {
    paddingTop: "auto",
    alignSelf: "center",
    paddingVertical: 13,
    paddingRight: 8,
    flex: 1,
  },
  positionText: {
    fontSize: 16,
    color: $green,
    fontWeight: "bold",
  },
  positionTextLeader: {
    color: "#fff",
  },
  infoWrapper: {
    paddingTop: "auto",
    alignSelf: "center",
  },
  rightBlock: {
    paddingVertical: 16,
  },
  listContainerPadingTop: {
    paddingTop: 11,
  },
  loadMoreButton: {
    height: 28,
    width: 100,
    backgroundColor: "#4f4f4f",
  },
  orgImageTransparent: {
    width: 52,
    height: 52,
    borderRadius: 5,
    borderColor: "#CCCCCC",
    borderWidth: 1,
    borderStyle: "solid",
    overflow: "hidden",
  },
  orgLogoTransparent: {
    width: 52,
    height: 52,
    borderRadius: 5,
    borderColor: "#CCCCCC",
    borderWidth: 1,
    borderStyle: "solid",
  },
  activityContainer: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'space-between', 
    flex:1,
  },
  jobTitleContainer: {
    paddingLeft: 10, 
    paddingRight: 10
  },
  jobTitleText: {
    fontSize: 16, 
    fontWeight: "600"
  },
  jobLocationStyling: {
    color: "#959595",
    fontSize: 12, 
    marginTop: 2
  },
  jobOrgName: {
    fontSize: 12, 
    opacity: 0.6, 
    marginTop: 2
  },
  jobType: {
    fontSize: 12,
    fontWeight:'normal'
  },
  jobNumber: {
    fontWeight:600
  },
  customIconSize: {
    height: 40,
    width: 40,
  },
  showMore: {
    display: 'flex',
    justifyContent:'center',
    alignItems: 'center',
    padding: 10,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    color: '#0FBC71',
    cursor:'pointer'
  }
});
