import {StyleSheet} from 'react-native';
import { $green, $grey2 } from '../../generalStyles';

export default StyleSheet.create({
    fromWrapper: {
        paddingTop: 16,
    },
    fromWrapperStrength: {
        paddingTop: 0,
        paddingBottom: 16,
        paddingLeft: 0,
        paddingRight: 0,
    },
    row: {
        position: 'relative',
        backgroundColor: 'red'
    },
    formBlock: {
        display:'flex',
        flexDirection:'row',

    },
    coverButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 10
    },
    messageRow: {
        marginBottom: 0
    },
    textArea: {
        backgroundColor: '#fff',
        borderBottomWidth: 0,
        borderRadius: 5,
        paddingTop: 16,
        paddingBottom: 16,
        paddingHorizontal: 16,
        minHeight: 104
    },
    submitButton: {
        marginTop: 24
    },
    applyButton: {
        marginTop: 15,
        backgroundColor: '#0FBC71'
    },
    applyDisable: {
        marginTop: 15,
        backgroundColor: '#E1E1E1'
    },
    applyText: {
        color: '#FFFFFF',
        fontSize: 14
    },
    applyTextDisable: {
        color: '#808080',
        fontSize: 14
    },
    referButton: {
        color: '#0FBC71',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#0FBC71',
        borderRadius: 4,
        width: '49%',
    },
    skipButton: {        
        borderWidth: 0.5,
        borderStyle: 'solid',
        borderColor: '#aaa',
        borderRadius: 4,
        width: '49%',
    },
    saveButton: {
        color: '#ffffff',
        backgroundColor: '#0FBC71',
        borderRadius: 4,
        width: '49%',
    },
    disableButton: {
        backgroundColor: '#e5e5e5',
        color: '#959595',
        borderRadius: 4,
        width: '49%',
    },
    deleteFile: {
        backgroundColor: $green,
        position: 'absolute',
        height: 20,
        width: 20,
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        bottom: 10,
        right: 0
    },
    deleteButtonText: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 20,
        textAlign: 'center'
    },
    fileUpload: {
        width: '90%',
        padding: 5,
        borderRadius: 5,
        marginTop: 10,
        borderWidth: 1,
        borderColor: "#22c76e",
    },
    referrerWrapper: {
        flexDirection: 'row',
        padding: 16,
        justifyContent: 'center',
    },
    referrerHeading: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    referrerText: {
        fontSize: 18,
    },
    referrerEmail: {
        fontSize: 15,
        color: $grey2,
        alignSelf: 'center',
    },
    linkBtn :{
        cursor: 'pointer',
        color: '#aaaaaa',
        fontWeight: 'normal',
        fontSize: 14,
        borderBottom: 1,
        borderBottomColor: '#aaaaaa',
        borderBottomStyle: 'dotted',
        paddingLeft: 0,
        marginTop: 10,
        paddingRight: 0,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderRadius: 0,
        width: '18%',
        margin: 0,
        height: 17
      },
      '&:hover linkBtn': {
        color: '#0fbc71',
        borderBottom: 1,
        borderColor: '#0fbc71',
      }
});
