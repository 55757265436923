import * as unsubscribeService from '../../services/unsubscribeService.js';
import {
    SET_SPINNER_VISIBLE,
    SET_SPINNER_HIDDEN
} from '../../constants/actions/appActions';
import { notification } from '../../helperFunctions';


export function unsubscribeRefrralRoundup(token) {
    return (dispatch) => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        if (token) {
            unsubscribeService
                .unsubscribeRefrralRoundup(token).then(() => {
                    dispatch({ type: SET_SPINNER_HIDDEN });
                });
        }
    };
}