import Actions from '../../RouteActions';
import * as authActionsConstants from '../../constants/actions/auth';
import * as authService from '../../services/authService';
import { SET_SPINNER_VISIBLE, SET_SPINNER_HIDDEN } from '../../constants/actions/appActions';
import { getDeviceFingerprint } from '../../AsyncStorage';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';
import baseService from '../../services/baseService';

export function resetPassword(formValues) {
    return dispatch => {
        getDeviceFingerprint().then(deviceFingerprint => {
            dispatch({ type: SET_SPINNER_VISIBLE });
            authService.setPassword({ ...formValues, 'device_fingerprint': deviceFingerprint }).then(response => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                baseService.addAuthToken(response.data.payload.access_token).then(() => {
                    if (response.data.response_code === responseCodes.OK) {
                        Actions.home();
                        notification.ref.show({ message: 'Your password has been created successfully.' });
                    }
                });
            }).catch(response => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                notification.ref.show({ message: response.data.error, isError: true });
            });
        })
    }
}
