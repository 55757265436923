import { StyleSheet } from "react-native";
import { $green } from "../../generalStyles";

export default StyleSheet.create({
  pageWrapper: {
    justifyContent: "space-between",
  },
  signUpForm: {
    padding: 70,
    paddingVertical: 35,
    backgroundColor: "#fff",
    borderRadius: 8,
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  boonHeading: {
    fontSize: 40,
    fontFamily: "Roboto-Regular",
    marginBottom: 10,
    fontWeight: "bold",
  },
  signupText: {
    fontSize: 14,
    marginBottom: 10,
  },
  logoWrapper: {
    flexShrink: 1,
    flexGrow: 0,
    position: "relative",
    width: "100%",
    height: "100%",
    alignItems: "flex-start",
    height: 80,
  },
  logo: {
    width: 300,
    height: 40,
    flexGrow: 0,
    flexShrink: 1,
    resizeMode: "contain",
    marginTop: 30,
  },
  checkMark: {
    height: 80,
    flexGrow: 0,
    flexShrink: 1,
    resizeMode: "contain",
    marginTop: 60,
    width: "100%",
    justifyContent: "center",
  },
  inputWrapper: {
    marginVertical: 32,
  },
  childrenWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 16,
    paddingVertical: 36,
    width: "100%",
    justifyContent: "center",
    width: "60%",
    height: 300,
  },
  keyboardAwareContainer: {
    alignItems: "stretch",
    justifyContent: "center",
    flex: 1,
  },
  textLines: {
    marginTop: 30,
    fontSize: 20,
    color: "#18C279",
    fontWeight: "500",
    textAlign: "center",
  },
  elementWrapper: {
    backgroundColor: $green,
    overflow: "hidden",
    height: "100%",
    width: "100%",
  },
});
