import Actions from '../../RouteActions';
import * as stepsActionsConstants from '../../constants/actions/stepsActions';
import { SET_SPINNER_VISIBLE, SET_SPINNER_HIDDEN} from '../../constants/actions/appActions';
import * as authActionsConstants from '../../constants/actions/auth';
import { getDeviceFingerprint } from '../../AsyncStorage';
import _ from 'lodash';
import * as authService from '../../services/authService';
import * as usersService from '../../services/usersService';
import { notification } from '../../helperFunctions';
import responseCodes from '../../constants/responseCodes';
import baseService from '../../services/baseService';

export function submitForm(formValues) {
    return dispatch => {
        dispatch({ type: SET_SPINNER_VISIBLE });
        getDeviceFingerprint().then(deviceFingerprint => {
            authService.dashboardPublicJobApp({ ...formValues, 'device_fingerprint': deviceFingerprint }).then(response => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                Actions.tracker();
                notification.ref.show({ message: 'You have successfully applied for this job', isError: false });
            }).catch((response) => {
                dispatch({ type: SET_SPINNER_HIDDEN });
                notification.ref.show({ message: response.data.error, isError: true });
            });
        });
    };
}
