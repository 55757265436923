import { each } from 'lodash-es';
import * as organizationsActionsConstants from '../constants/actions/organizationsActions';
import * as authActionsConstants from '../constants/actions/auth';

const initialState = {
    followOrganizations: [],
    organizationId: {},
    followOrganizationsLoading: false,
    userOrganizations: [],
    userOrganizationsLoading: false,
    organizationsTab: [],
    organizationsTabLoading: false,
    organizationDetails: {},
    organizationDetailsLoading: false,
    organizationJobs: [],
    organizationJobsLoading: false,
    organizationActiveJobs: [],
    organizationActiveJobsPreview: [],
    organizationActiveJobsLoading: false,
    industriesList: [],
    isMember: false,
    mostRecentEmployer: '',
    organizationExist: false,
    dropdownValues: [],
    perksAndBenefitsList: [],
    sourcesDropdownValues: [],
    generalReferrals: false,
    boonView: false,
};

const organizationsReducer = (state = initialState, action) => {
    const arrays = {
        followOrganizations: [],
        userOrganizations: [],
        organizationsTab: []
    };

    switch (action.type) {
        case authActionsConstants.SIGN_IN_SUCCESS:
        case authActionsConstants.SIGN_UP_SUCCESS:
            return {
                ...state,
                userOrganizations: action.payload.organizations,
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS:
            return {
                ...state,
                userOrganizationsLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                userOrganizations: action.payload.organizations,
                boonView: action.payload.boon_view,
                userOrganizationsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_ERROR:
            return {
                ...state,
                userOrganizationsLoading: false
            };
        case organizationsActionsConstants.GET_FOLLOW_ORGANIZATIONS:
            return {
                ...state,
                followOrganizations: [],
                followOrganizationsLoading: true
            };
        case organizationsActionsConstants.GET_FOLLOW_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                followOrganizations: action.payload,
                followOrganizationsLoading: false
            };
        case organizationsActionsConstants.GET_FOLLOW_ORGANIZATIONS_ERROR:
            return {
                ...state,
                followOrganizationsLoading: false
            };
        case organizationsActionsConstants.FOLLOW_ORGANIZATION_SUCCESS:
            arrays.followOrganizations = state.followOrganizations.slice();
            arrays.userOrganizations = state.userOrganizations.slice();
            arrays.organizationsTab = state.organizationsTab.slice();
            each(arrays, (array, index) => {
                arrays[index] = array.map(organizationObj => {
                    let objToReturn = organizationObj;
                    if (organizationObj.name === action.payload) {
                        objToReturn = { ...organizationObj, is_following: true };
                    }

                    return objToReturn;
                });
            });

            return {
                ...state,
                followOrganizations: arrays.followOrganizations,
                userOrganizations: arrays.userOrganizations,
                organizationsTab: arrays.organizationsTab,
                organizationDetails: { ...state.organizationDetails, is_following: true }
            };
        case organizationsActionsConstants.UNFOLLOW_ORGANIZATION_SUCCESS:
            arrays.followOrganizations = state.followOrganizations.slice();
            arrays.userOrganizations = state.userOrganizations.slice();
            arrays.organizationsTab = state.organizationsTab.slice();
            each(arrays, (array, index) => {
                arrays[index] = array.map(organizationObj => {
                    let objToReturn = organizationObj;
                    if (organizationObj.id === action.payload) {
                        objToReturn = { ...organizationObj, is_following: false };
                    }

                    return objToReturn;
                });
            });

            return {
                ...state,
                followOrganizations: arrays.followOrganizations,
                userOrganizations: arrays.userOrganizations,
                organizationsTab: arrays.organizationsTab,
                organizationDetails: { ...state.organizationDetails, is_following: false }
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_INVITES:
            return {
                ...state,
                userOrganizations: [],
                userOrganizationsLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_INVITES_SUCCESS:
            return {
                ...state,
                userOrganizations: action.payload,
                userOrganizationsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_TAB:
            return {
                ...state,
                organizationsTabLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATIONS_TAB_SUCCESS:
            return {
                ...state,
                organizationsTab: action.payload,
                organizationsTabLoading: false
            };
        case organizationsActionsConstants.SUBMIT_ORGANIZATION_INVITE_SUCCESS:
            arrays.followOrganizations = state.followOrganizations.slice();
            arrays.userOrganizations = state.userOrganizations.slice();
            arrays.organizationsTab = state.organizationsTab.slice();
            each(arrays, (array, index) => {
                arrays[index] = array.map(organizationObj => {
                    let objToReturn = organizationObj;
                    if (organizationObj.id === action.payload.organizationId) {
                        objToReturn = { ...organizationObj, status: 1 };
                    }

                    return objToReturn;
                });

                arrays.userOrganizations = action.payload.userOrganizations;
            });

            return {
                ...state,
                followOrganizations: arrays.followOrganizations,
                userOrganizations: arrays.userOrganizations,
                organizationsTab: arrays.organizationsTab,
                organizationDetails: { ...state.organizationDetails, status: 1 }
            };
        case organizationsActionsConstants.GET_ORGANIZATION_DETAILS:
            return {
                ...state,
                organizationDetailsLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATION_DETAILS_SUCCESS:
            return {
                ...state,
                organizationDetails: action.payload.organization,
                generalReferrals: action.payload.general_referrals,
                organizationDetailsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATION_DETAILS_ERROR:
            return {
                ...state,
                organizationDetailsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATION_JOBS:
            return {
                ...state,
                organizationJobs: [],
                organizationJobsLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATION_JOBS_SUCCESS:
            return {
                ...state,
                organizationJobs: action.payload,
                organizationJobsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATION_JOBS_ERROR:
            return {
                ...state,
                organizationJobsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS:
            return {
                ...state,
                organizationActiveJobsLoading: true
            };
        case organizationsActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS_SUCCESS:
            return {
                ...state,
                organizationActiveJobsPreview: action.payload.jobs.slice(0, 3),
                organizationActiveJobs: action.payload.jobs,
                organizationActiveJobsLoading: false
            };
        case organizationsActionsConstants.GET_ORGANIZATION_ACTIVE_JOBS_ERROR:
            return {
                ...state,
                organizationActiveJobsLoading: false
            };
        case organizationsActionsConstants.GET_INDUSTRIES:
            return {
                ...state,
                industriesList: []
            };

        case organizationsActionsConstants.GET_INDUSTRIES_SUCCESS:
            return {
                ...state,
                industriesList: action.payload
            };
        case organizationsActionsConstants.CHECK_USER_EMPLOYER:
            return {
                ...state,
                isMember: action.payload.isMember,
                mostRecentEmployer: action.payload.organizationName
            };
        case organizationsActionsConstants.COMPANY_EXISTS:
            return {
                ...state,
                organizationExist: action.payload.organizationExist
            };
        case organizationsActionsConstants.GET_DROPDOWN_VALUES_SUCCESS:
            return {
                ...state,
                dropdownValues: action.payload
            };

        case organizationsActionsConstants.GET_PERKS_AND_BENEFITS_SUCCESS:
            return {
                ...state,
                perksAndBenefitsList: action.payload
            };
            case organizationsActionsConstants.GET_SOURCES_DROPDOWN_VALUES_SUCCESS:
                return {
                    ...state,
                    sourcesDropdownValues: action.payload
                };
        case organizationsActionsConstants.SET_ORGANIZATION_ID:
            return {
                    ...state,
                    organizationId: action.payload
                };
        default:
            return state;
    }
};

export default organizationsReducer;
