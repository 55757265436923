export const CANDIDATE_PROGRESS_VALUES = [
  'current_employer',
  'current_location',
  'current_title',
  'joining_date',
  'skills',
  'diversity_hire',
  'resume_file_links',
  'links_json',
];
