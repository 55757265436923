import * as appActionsConstants from '../../constants/actions/appActions';
import * as referActionsConstants from '../../constants/actions/referActions';
import * as refersService from '../../services/refersService';
import { notification } from '../../helperFunctions';

export function getData(id) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        refersService.getReferDataById(id).then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: referActionsConstants.GET_REFER_JOB_SUCCESS, payload: response.data.payload });
        }).catch((e) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: referActionsConstants.GET_REFER_JOB_ERROR, payload: e.data.error });
            notification.ref.show({ message: e.data.error, isError: true });
        });
    };
}
