export const referralRowStyles = {
  monetaryCircle: {
    display: 'inline-flex',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    backgroundColor: '#22c76f47',
  },
  expCircle: {
    display: 'inline-flex',
    width: '36px',
    height: '36px',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '1rem',
    backgroundColor: '#f5c25c5c',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    flexWrap: 'wrap',
    gap: '30px',
  },
  subContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    marginLeft: '10px',
    width: '5rem',
  },
  iconColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
    marginLeft: '20px',
  },
  rewardValue: {
    color: '#aaa',
    marginTop: '3px',
  },
  tooltipHover: {
    fontSize: '12px',
    pointerEvents: 'auto',
    '&:hover': {
      visibility: 'visible',
      opacity: '1 !important',
    },
  },
  expRewardSection: {
    display: 'flex',
    alignItems: 'center',
  },
};
