import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import authReducer from './authReducer';
import suggestionsReducer from './suggestionsReducer';
import connectionsReducer from './connectionsReducer';
import organizationsReducer from './organizationsReducer';
import refersReducer from './refersReducer';
import browseReducer from './browseReducer';
import trackerReducer from './trackerReducer';
import activityReducer from './activityReducer';
import notificationsReducer from './notificationsReducer';
import locationsReducer from './locationsReducer';
import appReducer from './appReducer';
import drawerReducer from './drawerReducer';
import stepsReducer from './stepsReducer';
import settingsReducer from './settingReducer'
import Cookies from 'js-cookie';

import { LOGOUT } from '../constants/actions/auth';
import storage from '../wrappers/reduxStorage/reduxStorage';

const combinedReducer = combineReducers({
  authReducer: authReducer,
  appReducer: appReducer,
  drawerReducer: drawerReducer,
  suggestionsReducer: suggestionsReducer,
  connectionsReducer: connectionsReducer,
  organizationsReducer: organizationsReducer,
  refersReducer: refersReducer,
  browseReducer: browseReducer,
  trackerReducer: trackerReducer,
  activityReducer: activityReducer,
  notificationsReducer: notificationsReducer,
  locationsReducer: locationsReducer,
  stepsReducer: stepsReducer,
  settingsReducer: settingsReducer,
  form: formReducer,
});

const rootReducer = (state, action) => {
  const env = process.env.REACT_APP_ENVIRONMENT || 'development';
  if (action.type === LOGOUT) {
    storage.removeItem('persist:root');
    storage.removeItem('@AsyncStore:deviceFingerprint');
    storage.removeItem('@AsyncStore:accessToken');
    Cookies.remove(`X-User-Token-${env}`, { domain: env === 'development' ? 'localhost' : '.goboon.co' });
    Cookies.remove(`currentOrgIdentifier-${env}`);
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
