import React, {Component} from 'react';
import {
    Text
} from 'react-native';

export default class CustomText extends Component {
    render() {
        const { style, children } = this.props;
        const basicStyle = {
            fontFamily: style && style.fontWeight === 'bold' ? 'Roboto-Bold' : 'Roboto-Regular',
        };
        return (
            <Text {...this.props} style={[basicStyle, style]}>{children}</Text>
        );
    }
}
