import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    header: {
        backgroundColor: $green,
        paddingTop: 38,
        paddingBottom: 20,
        paddingLeft: 32,
        paddingRight: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerTitle: {
        color: '#fff',
        fontSize: 20,
        fontWeight: 'bold'
    },
    children: {
        flexGrow: 1,
    },
    skip: {
        color: '#fff',
        fontSize: 16,
        fontWeight: 'bold'
    },
    logo: {
        width: 135,
        height: 30,
        flexGrow: 0,
        flexShrink: 1,
        resizeMode: 'contain'
    },
    pageWrapper: {
        justifyContent: 'space-between'
    },
    mobileLogoWrapper: {
        paddingTop: 80,
        paddingBottom: 80,
        alignItems: 'center',
    },
    logoWrapper: {
        flexShrink: 1,
        flexGrow: 0,
        paddingTop: 100,
        paddingBottom: 80,
        position: 'relative',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    mobileBoonHeading: {
        fontSize: 40,
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        marginBottom: 15
    },
    boonHeading: {
        fontSize: 70,
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        marginBottom: 15
    },
    mobileSignupText: {
        fontSize: 14,
        color: '#fff',
        marginBottom: 80
    },
    signupText: {
        fontSize: 19,
        color: '#fff',
        marginBottom: 80
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileSignUpForm: {
        width: '90%',
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    signUpForm: {
        width: '42%',
        paddingHorizontal: 70,
        paddingVertical: 35,
        backgroundColor: '#fff',
        borderRadius: 8,
    }
});
