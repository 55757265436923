import EStyleSheet from 'react-native-extended-stylesheet';

export default EStyleSheet.create({
    componentWrapper: {
        padding: 16,
        backgroundColor: '#fff',
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: {
            height: 2
        },
        shadowRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    infoWrapper: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoText: {
        fontSize: 14
    },
    button: {
        paddingHorizontal: 10,
        flex: 0,
        height: 30,
        width: 'auto'
    },
    fillProgressWrapper: {
        position: 'relative',
        marginRight: 10
    },
    userIcon: {
        position: 'absolute',
        height: 16,
        width: 16,
        top: 8,
        left: 9
    }
});
