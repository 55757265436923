import React, { Component } from 'react';
import { reduxForm, Field, change, FieldArray } from 'redux-form';
import {
    View,
    Text,
    TouchableOpacity
} from 'react-native';
import { connect } from 'react-redux';
import * as actions from './actions';
import Links from '../../components/Links/Links';

//component
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import FormInput from '../../components/FormInput/FormInput';
import FormInputCheckbox from '../../components/FormInputCheckbox/FormInputCheckbox';

//styles
import styles from './styles';
import { formLayout } from '../../generalStyles';
import { withGetScreen } from 'react-native-getscreen';
import { notification } from '../../helperFunctions';

class PublicJobApplicationChild extends Component {
    state = {
        file: null
    }
    removePDF = () => {
        this.props.formValues.file = null;
        document.getElementById('file_upload').value = '';
        this.setState({ file: null });
    }
    handleFileChange = (file) => {
        const { formValues } = this.props;
        formValues.file = file[0];
        this.setState({ file: file[0] });
    }
    handleChildSubmit = () => {
        const { submitForm, setFieldValue, formValues, dashboardBase, referredJobData } = this.props;
        setFieldValue('email', referredJobData.subject_user.email)
        setFieldValue('name', referredJobData.subject_user.full_name)
        if (!dashboardBase && (!formValues.name || !formValues.email)) {
            return notification.ref.show({ message: 'Please fill out all the mandatory fields.', isError: true });
        }
        if ((!formValues.file && !formValues.links_json) || (!formValues.file && formValues.links_json.filter(obj => obj.value).length === 0)) {
            return notification.ref.show({ message: 'You need to add a link or upload resume before proceeding.', isError: true });
        }
        setFieldValue('file', this.state.file)
        submitForm(formValues, this.state.file);
    }

    render() {
        const mobile = this.props.isMobile();
        const { formValues, userExist, refer_request, showCreds, handleBackButton, setFieldValue, dashboardBase, referredJobData } = this.props;
        setTimeout(() => {
            if (referredJobData && referredJobData.subject_user && referredJobData.subject_user.email) {
                setFieldValue('email', referredJobData.subject_user.email)
                setFieldValue('name', referredJobData.subject_user.full_name)
            }
        }, 1000)
        return (
            <View style={dashboardBase && styles.dashboardPadding}>
                <View>
                    {
                        showCreds &&
                        <View>
                            <View style={formLayout.row}>
                                <Field
                                    component={FormInput}
                                    name='name'
                                    placeholder='Name *'
                                    wrapperStyle={[formLayout.fullWidth]}
                                    onSubmitEditing={() => this.lastNameRef.focus()}
                                    disabled={referredJobData && referredJobData.subject_user && referredJobData.subject_user.full_name}
                                    returnKeyType="next"
                                    autoFocus
                                />
                            </View>
                            <View style={formLayout.row}>
                                <Field
                                    component={FormInput}
                                    name='email'
                                    placeholder='Email *'
                                    wrapperStyle={[formLayout.fullWidth]}
                                    inputRef={ref => this.emailRef = ref}
                                    disabled={referredJobData && referredJobData.subject_user && referredJobData.subject_user.email}
                                    returnKeyType="next"
                                    keyboardType="email-address"
                                />
                            </View>
                        </View>
                    }
                    <View style={formLayout.row}>
                        <Field
                            component={FormInput}
                            name='message'
                            placeholder='Message'
                            wrapperStyle={formLayout.fullWidth}
                        />
                    </View>

                    <FieldArray
                        component={Links}
                        name="links_json"
                    />
                    {mobile ?
                        <View />
                        :
                        <View style={formLayout.noSpaceRow}>
                            <View style={[, styles.fileUpload]}>
                                <input
                                    type="file"
                                    onChange={(e) => this.handleFileChange(e.target.files)}
                                    id="file_upload"
                                />
                            </View>
                            {this.state.file &&
                                <PrimaryButton style={styles.deleteFile} onPress={() => this.removePDF()}>
                                    X
                                </PrimaryButton>
                            }
                        </View>
                    }
                </View>
                <View style={formLayout.row}>
                    <Field
                        component={FormInputCheckbox}
                        name='terms_of_service'
                        checked={true}
                        onValueChange={() => {
                            this.props.setFieldValue('terms_of_service', !formValues.terms_of_service)
                        }}
                        textComponent={() => {
                            return (
                                <Text style={mobile ? styles.checkBoxTextMobile : styles.checkBoxText}>I accept the terms of services.</Text>
                            );
                        }}
                    />
                </View>
                <View style={[formLayout.row, { justifyContent: 'center', zIndex: -9 }]}>
                    <PrimaryButton style={styles.saveNextButton} onPress={this.handleChildSubmit}>
                        Submit
                    </PrimaryButton>
                </View>
                <TouchableOpacity small style={[formLayout.row, { justifyContent: 'center', zIndex: -9 }]}>
                    <PrimaryButton style={styles.goBackButton} onPress={handleBackButton}>
                        Back
                    </PrimaryButton>
                </TouchableOpacity>
            </View>
        );
    }
}

PublicJobApplicationChild = reduxForm({ form: 'publicJobApplicationForm' })(withGetScreen(PublicJobApplicationChild));

const mapStateToProps = state => {
    return {
        formValues: state.form.publicJobApplicationForm && state.form.publicJobApplicationForm.values ? state.form.publicJobApplicationForm.values : {},
        userExist: state.authReducer.userExist,
        referredJobData: state.refersReducer.referredJobData,
        userCurrentStep: state.stepsReducer.step,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('publicJobApplicationForm', field, value));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(PublicJobApplicationChild));
