import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    optionsButton: {
        paddingVertical: 12,
        paddingHorizontal: 18
    },
    optionsIcon: {
        height: 16,
        width: 4
    },
    mainWrapper: {
        paddingTop: 15,
        paddingBottom: 12,
        borderBottomColor: '#cccccc',
        borderBottomWidth: 0.5,
        width: '70%'
    },
    mainWrapperMobile: {
        paddingTop: 15,
        paddingBottom: 12,
        borderBottomColor: '#cccccc',
        borderBottomWidth: 0.5,
        width: '100%'
    },
    contactContainer: {
        marginLeft: 30,
        width: '34%',
        flexDirection: 'row'
    },
    contactContainerMobile: {
        marginLeft: 5,
        width: '80%',
        flexDirection: 'row'
    },
    infoWrapper: {
        justifyContent: 'center',
        width: '90%'
    },
    title: {
        fontSize: 15,
        fontWeight: 'bold',
        textTransform: 'capitalize',
        width: '90%'
    },
    description: {
        fontSize: 13,
        fontWeight: '500',
        textTransform: 'capitalize'
    },
    descriptionMobile: {
        fontSize: 12,
        fontWeight: '500',
        textTransform: 'capitalize'
    },
    employerContainer: {
        // borderColor: 'black',
        // borderWidth: 1,
        marginLeft: 50,
        width: '25%',
        flexDirection: 'row'
    },
    orgImage: {
        height: 50,
        width: 50,
        marginRight: 12,
        borderRadius: 4,
        borderWidth: 1,
        borderColor: '#d8d8d8'
    },
    orgName: {
        fontSize: 13,
        fontWeight: '500',
    },
    companyPosition: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    infoContainer: {
        width: '45%',
        flexDirection: 'row'
    },
    informationWrapper: {
        paddingTop: 17,
        paddingLeft: 70,
        width: '80%'
    },
    contact: {
        fontSize: 13,
        fontWeight: '600',
        textTransform: 'lowercase',
        color: '#808080',
        padding: 2
    },
    referButtonStyle: {
        borderRadius: 3,
        height: 40,
        width: 100,
        marginTop: 10,
        marginLeft: 20
    },
    shareIconMobile: {
        width: 20,
        height: 20,
        marginTop: 15,
        marginBottom: 18,
        marginLeft: 22,
        marginRight: 18,
    }
})
