// Sign up constants
export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';
export const SIGN_UP_CURRENT_USER_ID = 'SIGN_UP_CURRENT_USER_ID';
export const USER_INVITE_CONTACT = 'USER_INVITE_CONTACT';

export const GET_NUMBER_CONFIRMATION = 'GET_NUMBER_CONFIRMATION';
export const GET_NUMBER_CONFIRMATION_SUCCESS = 'GET_NUMBER_CONFIRMATION_SUCCESS';
export const GET_NUMBER_CONFIRMATION_ERROR = 'GET_NUMBER_CONFIRMATION_ERROR';

export const CONFIRM_NUMBER = 'CONFIRM_NUMBER';
export const CONFIRM_NUMBER_SUCCESS = 'CONFIRM_NUMBER_SUCCESS';
export const CONFIRM_NUMBER_ERROR = 'CONFIRM_NUMBER_ERROR';
export const RESET_CONFIRM_NUMBER = 'RESET_CONFIRM_NUMBER';

// Sign in constants
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';
export const GET_SIGNED_IN_USER = 'GET_SIGNED_IN_USER'

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const EMAIL_CONFIRMATION_SENT = 'EMAIL_CONFIRMATION_SENT';
export const EMAIL_CONFIRMATION_SENT_SUCCESS = 'EMAIL_CONFIRMATION_SENT_SUCCESS';
export const EMAIL_CONFIRMATION_SENT_ERROR = 'EMAIL_CONFIRMATION_SENT_ERROR';

export const GET_MAGIC_LINK = 'GET_MAGIC_LINK';
export const SET_MAGIC_LINK_DATA = 'SET_MAGIC_LINK_DATA';
export const CLEAR_MAGIC_LINK_DATA = 'CLEAR_MAGIC_LINK_DATA';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_ERROR = 'GET_CURRENT_USER_ERROR';

export const GET_CURRENT_USER_STATS = 'GET_CURRENT_USER_STATS';
export const GET_CURRENT_USER_STATS_SUCCESS = 'GET_CURRENT_USER_STATS_SUCCESS';
export const GET_CURRENT_USER_STATS_ERROR = 'GET_CURRENT_USER_STATS_ERROR';

export const EDIT_CURRENT_USER = 'EDIT_CURRENT_USER';
export const EDIT_CURRENT_USER_SUCCESS = 'EDIT_CURRENT_USER_SUCCESS';
export const EDIT_CURRENT_USER_ERROR = 'EDIT_CURRENT_USER_ERROR';

export const SUBMIT_PERMISSIONS = 'SUBMIT_PERMISSIONS';
export const SUBMIT_PERMISSIONS_SUCCESS = 'SUBMIT_PERMISSIONS_SUCCESS';
export const SUBMIT_PERMISSIONS_ERROR = 'SUBMIT_PERMISSIONS_ERROR';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';

export const SUBSCRIBE_GOOGLE_SUCCESS = 'SUBSCRIBE_GOOGLE_SUCCESS';

export const SUBSCRIBE_GOOGLE = 'SUBSCRIBE_GOOGLE';
export const SUBSCRIBE_LINKEDIN = 'SUBSCRIBE_LINKEDIN';
export const UNSUBSCRIBE_LINKEDIN = 'UNSUBSCRIBE_LINKEDIN';

export const SUBSCRIBE_OUTLOOK = 'SUBSCRIBE_OUTLOOK';
export const UNSUBSCRIBE_OUTLOOK = 'UNSUBSCRIBE_OUTLOOK';

export const SUBSCRIBE_FACEBOOK = 'SUBSCRIBE_FACEBOOK';
export const UNSUBSCRIBE_FACEBOOK = 'UNSUBSCRIBE_FACEBOOK';

export const SUBSCRIBE_TWITTER = 'SUBSCRIBE_TWITTER';
export const UNSUBSCRIBE_TWITTER = 'UNSUBSCRIBE_TWITTER';

export const UNSUBSCRIBE_GOOGLE = 'UNSUBSCRIBE_GOOGLE';
export const UNSUBSCRIBE_GOOGLE_SUCCESS = 'UNSUBSCRIBE_GOOGLE_SUCCESS';
export const UNSUBSCRIBE_GOOGLE_ERROR = 'UNSUBSCRIBE_GOOGLE_ERROR';

export const CHANGE_PERMISSION_STATUS = 'CHANGE_PERMISSION_STATUS';

export const GET_USER_INVITE_DATA = 'GET_USER_INVITE_DATA';
export const GET_USER_INVITE_DATA_SUCCESS = 'GET_USER_INVITE_DATA_SUCCESS';
export const GET_USER_INVITE_DATA_ERROR = 'GET_USER_INVITE_DATA_ERROR';

export const INVITE_CONTACTS = 'INVITE_CONTACTS';
export const LOGIN_FORM_ERROR = 'LOGIN_FORM_ERROR';
export const SET_ANY_ORG_ADMIN = 'SET_ANY_ORG_ADMIN';

export const SET_VIEW_SIGN_IN_FORM = 'SET_VIEW_SIGN_IN_FORM';

export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';

export const SET_START_DOWNLOAD_TIME = 'SET_START_DOWNLOAD_TIME';
export const SET_USER_SOURCES = 'SET_USER_SOURCES';

export const SET_ACCOUNT_CURRENT_TAB = 'SET_ACCOUNT_CURRENT_TAB';
export const SHOW_SEARCH_BLOCK = 'SHOW_SEARCH_BLOCK';

export const SET_CURRENT_SELECTED_ORG = 'SET_CURRENT_SELECTED_ORG';

export const UPDATE_USER_SLACK_ID_SUCCESS = "UPDATE_USER_SLACK_ID_SUCCESS";

export const GET_SLACK_ID_SUCCESS = "GET_SLACK_ID_SUCCESS";

export const SET_REDIRECT_URL_LOADER_TRUE = 'SET_REDIRECT_URL_LOADER_TRUE'

export const SET_REDIRECT_URL_LOADER_FALSE = 'SET_REDIRECT_URL_LOADER_FALSE'
