import React, { Component } from 'react';
import { reduxForm, change } from 'redux-form';
import {
    View,
    Text,
} from 'react-native';
import Actions from '../../RouteActions';
import { connect } from 'react-redux';
import * as actions from './actions';
import routeActions from '../../RouteActions';

//component
import SignUpBase from "../SignUpBase/SignUpBase";
import JobSummaryDetails from '../JobPost/JobSummaryDetails/JobSummaryDetails';
import PublicJobApplicationChild from '../PublicJobApplicationChild/PublicJobApplicationChild';
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import { ScrollView } from 'react-native';

//styles
import styles from './styles';
import { withGetScreen } from 'react-native-getscreen';

//utils
import { notification } from '../../helperFunctions';

class DashboardPublicJobApplication extends Component {
    componentDidMount = () => {
        const { refer_request } = this.props;
        if (!refer_request || refer_request === undefined) {
            routeActions.home();
        }
    }

    handleBackButton = () => {
        Actions.pop();
    }

    submitForm = (formValues, file) => {
        const { onSubmit, currentUser, setFieldValue } = this.props;
        formValues.refer_request_id = this.props.refer_request.id;
        if (currentUser) {
            formValues.email = currentUser.email
            formValues.file = file
            onSubmit(formValues);
        }
        else {
            notification.ref.show({ message: 'Please fill all mandatory fields', isError: true });
        }
    }

    render() {
        const mobile = this.props.isMobile();
        const { signUpLoading, refer_request, referredJobData, showCreds } = this.props;
        const subHeading = 'Please fill the following public job application form.'
        return (
            <DashboardBase title={"Referred Job Application Form"}>
                <ScrollView>
                    <View>
                        <Text style={[styles.title, styles.dashboardPadding]}>Job Summary </Text>
                        <JobSummaryDetails jobDetails={referredJobData && referredJobData.job} employer={referredJobData && referredJobData.organization}></JobSummaryDetails>
                        <Text style={[styles.title, styles.dashboardPadding]}>Application Form </Text>
                        <PublicJobApplicationChild dashboardBase={true} submitForm={this.submitForm} handleBackButton={this.handleBackButton} showCreds={showCreds} refer_request={refer_request}></PublicJobApplicationChild>
                    </View>
                </ScrollView>
            </DashboardBase>
        );
    }
}

DashboardPublicJobApplication = reduxForm({ form: 'dashboardPublicJobApplicationForm' })(withGetScreen(DashboardPublicJobApplication));

const mapStateToProps = state => {
    return {
        formValues: state.form.dashboardPublicJobApplicationForm && state.form.dashboardPublicJobApplicationForm.values ? state.form.dashboardPublicJobApplicationForm.values : {},
        signUpLoading: state.authReducer.signUpLoading,
        currentUser: state.authReducer.currentUser,
        referredJobData: state.refersReducer.referredJobData,
        userCurrentStep: state.stepsReducer.step,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFieldValue: (field, value) => {
            dispatch(change('dashboardPublicJobApplicationForm', field, value));
        },
        onSubmit: (formValues) => {
            formValues.public_job_application = true
            dispatch(actions.submitForm(formValues));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(DashboardPublicJobApplication));
