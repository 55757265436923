import EStyleSheet from 'react-native-extended-stylesheet';
import {$green} from '../../generalStyles';

export default EStyleSheet.create({
    container: {
        backgroundColor: $green,
        paddingTop: 40,
        flex: 1
    },
    carouselContainer: {
        paddingVertical: 16
    },
    slide: {
        height: 340,
        paddingTop: 70,
        paddingBottom: 50,
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        shadowOffset: {height: 5, width: 0},
        shadowRadius: 3,
        shadowColor: $green,
        shadowOpacity: 0.7,
        flexDirection: 'row',
        position: 'relative',
        justifyContent: 'space-between'
    },
    info: {
        alignItems: 'center'
    },
    slideColumn: {
        width: '42%',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        height: 64,
        width: 64,
        borderRadius: 5,
        marginBottom: 24
    },
    imageRounded: {
        borderRadius: 32
    },
    button: {
        width: '100%'
    },
    title: {
        color: '#18332F',
        fontSize: 16,
        fontWeight: 'bold'
    },
    description: {
        color: '#748582',
        fontSize: 12,
    },
    link: {
        color: $green,
        fontSize: 12,
    },
    emptyWrapper: {
        alignItems: 'center',
        marginTop: 80
    },
    emptyImage: {
        height: 100,
        width: 100,
        marginBottom: 24
    },
    emptyText: {
        fontWeight: 'bold',
        fontSize: 12,
        textAlign: 'center',
        color: '#fff',
        marginBottom: 70
    },
    emptyButton: {
        backgroundColor: '#fff',
        width: 'auto',
        paddingHorizontal: 16
    }
});
