import React from 'react';
import { View, Modal } from 'react-native';
import CloseButton from '../../components/CloseButton/CloseButton';
import Warning from '../../assets/svg/warning';
import styles from './styles';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import Text from '../../components/Text/Text';

const WarningModal = ({ isOpen, hideModal, onSave }) => {
  return (
    <Modal
      transparent={true}
      visible={true}
      onRequestClose={hideModal}
    >
      <View style={styles.modalWrapper}>
        <View style={styles.modalChildWrapper}>
          <View style={styles.modalHeader}>
            <Text style={styles.heading}>Decline this opportunity?</Text>
            <CloseButton
                style={styles.modalCloseButton}
                onPress={hideModal}
            />
          </View>
          <View style={styles.modalBodyWrapper}>
            <Warning/>
            <Text style={styles.modalBodyText}>Are you sure you want to decline this opportunity?</Text>
            <Text style={styles.modalBodyText}>
              This cannot be undone
            </Text>
            <View style={styles.actionButtons}>
              <PrimaryButton
                style={styles.cencelButton}
                onPress={hideModal}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton
                white
                style={[styles.cencelButton, {border: "1px solid #959595"}]}
                onPress={onSave}
              >
                Confirm
              </PrimaryButton>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default WarningModal;
