import {find, findIndex} from 'lodash-es';
import moment from 'moment';
import * as notificationsActionsConstants from '../constants/actions/notificationsActions';
import * as connectionsActionsConstants from '../constants/actions/connectionsActions';
import * as companiesActionsConstants from '../constants/actions/organizationsActions';

const initialState = {
    notificationsList: [],
    unreadNotifications: 0,
    notificationsListLoading: false,
    isLastPage: false,
};

const activityReducer = (state = initialState, action) => {
    let notificationToEditIndex = null;
    let notificationsList = [];
    switch (action.type) {
        case notificationsActionsConstants.GET_NOTIFICATIONS_LIST:
            return {
                ...state,
                notificationsListLoading: !action.payload.isPagination,
                notificationsList: action.payload.isPagination ? state.notificationsList : [],
            };
        case notificationsActionsConstants.GET_NOTIFICATIONS_LIST_SUCCESS:
            notificationsList = action.payload.isPagination ? state.notificationsList.concat(action.payload.notifications) : action.payload.notifications;
            return {
                ...state,
                notificationsList,
                isLastPage: action.payload.notifications.length !== 50,
                notificationsListLoading: false,
                unreadNotifications: action.payload.unreadNotifications
            };
        case notificationsActionsConstants.GET_NOTIFICATIONS_LIST_ERROR:
            return {
                ...state,
                notificationsListLoading: false
            };
        case notificationsActionsConstants.SET_READ_SUCCESS:
            notificationsList = state.notificationsList.slice();
            action.payload.forEach(id => {
                const notification = find(notificationsList, notification => notification.id === id);
                notification.read_at = moment().format();
            });
            return {
                ...state,
                notificationsListLoading: false,
                notificationsList,
                unreadNotifications: state.unreadNotifications - action.payload.length
            };
        case connectionsActionsConstants.ACCEPT_CONNECTION_SUCCESS:
            notificationsList = state.notificationsList.slice();

            notificationToEditIndex = findIndex(notificationsList, notification => notification.notification_type === 'connection_requested' && notification.sender_id === action.payload)

            if (notificationToEditIndex !== -1) {
                notificationsList[notificationToEditIndex].status = 'accepted';
            }

            return {
                ...state,
                notificationsList
            };
        case connectionsActionsConstants.REJET_CONNECTION_SUCCESS:
            notificationsList = state.notificationsList.slice();

            notificationToEditIndex = findIndex(notificationsList, notification => notification.notification_type === 'connection_requested' && notification.sender_id === action.payload);

            if (notificationToEditIndex !== -1) {
                notificationsList[notificationToEditIndex].status = 'rejected';
            }

            return {
                ...state,
                notificationsList
            };
        case companiesActionsConstants.SUBMIT_ORGANIZATION_INVITE_SUCCESS:
            notificationsList = state.notificationsList.slice();

            notificationToEditIndex = findIndex(notificationsList, notification => notification.notification_type === 'organization_invite' && notification.sender_id === action.payload.organizationId);

            if (notificationToEditIndex !== -1) {
                notificationsList[notificationToEditIndex].status = 'accepted';
            }

            return {
                ...state,
                notificationsList
            };
        default:
            return state;
    }
};

export default activityReducer;
