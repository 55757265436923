export const GET_JOBS_TRACKER = 'GET_JOBS_TRACKER';
export const GET_JOBS_TRACKER_SUCCESS = 'GET_JOBS_TRACKER_SUCCESS';
export const GET_JOBS_TRACKER_ERROR = 'GET_JOBS_TRACKER_ERROR';
export const GET_CANDIDATE_ID = 'GET_CANDIDATE_ID';
export const CLEAR_JOBS_TRACKER = 'CLEAR_JOBS_TRACKER';

export const GET_NEW_REFERS_COUNT = 'GET_NEW_REFERS_COUNT';
export const GET_NEW_REFERS_COUNT_SUCCESS = 'GET_NEW_REFERS_COUNT_SUCCESS';
export const GET_NEW_REFERS_COUNT_ERROR = 'GET_NEW_REFERS_COUNT_ERROR';

export const APPLY_JOB = 'APPLY_JOB';
export const APPLY_JOB_SUCCESS = 'APPLY_JOB_SUCCESS';
export const APPLY_JOB_ERROR = 'APPLY_JOB_ERROR';

export const DECLINE_JOB = 'DECLINE_JOB';
export const DECLINE_JOB_SUCCESS = 'DECLINE_JOB_SUCCESS';
export const DECLINE_JOB_ERROR = 'DECLINE_JOB_ERROR';

export const GET_OPPORTUNITY_DETAILS = 'GET_OPPORTUNITY_DETAILS';
export const GET_OPPORTUNITY_DETAILS_SUCCESS = 'GET_OPPORTUNITY_DETAILS_SUCCESS';
export const GET_OPPORTUNITY_DETAILS_ERROR = 'GET_OPPORTUNITY_DETAILS_ERROR';

export const CLEAR_OPPORTUNITY_DETAILS = 'CLEAR_OPPORTUNITY_DETAILS';

export const SET_CURRENT_TRACKER_TAB = 'SET_CURRENT_TRACKER_TAB';

export const SET_SEEN = 'SET_SEEN';

export const SET_FILTERED_TRACKER_DATA = 'SET_FILTERED_TRACKER_DATA';

export const HIDE_PDF_FROM_USER_REQUEST = 'HIDE_PDF_FROM_USER_REQUEST';
export const HIDE_PDF_FROM_USER_SUCCES = 'HIDE_PDF_FROM_USER_SUCCES';
export const HIDE_PDF_FROM_USER_FAILURE = 'HIDE_PDF_FROM_USER_FAILURE';

export const GET_INTERVIEW_STAGES = 'GET_INTERVIEW_STAGES';
export const GET_INTERVIEW_STAGES_SUCCESS = 'GET_INTERVIEW_STAGES_SUCCESS';
export const GET_INTERVIEW_STAGES_ERROR = 'GET_INTERVIEW_STAGES_ERROR';

// Get job skills values
export const GET_SKILLS = 'GET_SKILLS';
export const GET_SKILLS_SUCCESS = 'GET_SKILLS_SUCCESS';
export const GET_SKILLS_ERROR = 'GET_SKILLS_ERROR';

export const GET_STRENGTH_QUESTIONS = 'GET_STRENGTH_QUESTIONS';
export const GET_STRENGTH_QUESTIONS_SUCCESS = 'GET_STRENGTH_QUESTIONS_SUCCESS';
export const GET_STRENGTH_QUESTIONS_ERROR = 'GET_STRENGTH_QUESTIONS_ERROR';

export const GET_CANDIDATE_STRENGTH_QUESTIONS_SUCCESS = 'GET_CANDIDATE_STRENGTH_QUESTIONS_SUCCESS';
