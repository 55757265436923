import * as drawerActionsConstants from './constants/actions/drawerActions';
import * as appActionsConstants from './constants/actions/appActions';
import * as actions from '../src/screens/Refer/actions';

export function openOrgDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_ORG_DRAWER, payload: {} });
    };
}

export function openBrowseFiltersDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_BROWSE_FILTERS_DRAWER, payload: {} });
    };
}

export function closeDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.CLOSE_DRAWER, payload: {} });
        dispatch({ type: appActionsConstants.SET_NOTIFY_FALSE, payload: false });
    };
}

export function openReferFormDrawer(initialValues) {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_REFER_FORM_DRAWER, payload: initialValues });
        dispatch(actions.referContinue());

    };
}

export function openSettingsDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_SETTINGS_DRAWER, payload: {} });
    };
}

export function openChangePasswordDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_CHANGE_PASSWORD_DRAWER, payload: {} });
    };
}

export function openJobApplyDrawer(initialValues) {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_JOB_APPLY_DRAWER, payload: initialValues });
    };
}

export function openConnectContactsDrawer(showCountDown = false, minutesElapsed = 0) {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_CONNECT_CONTACTS_DRAWER, payload: { showCountDown, minutesElapsed } });
    };
}

export function openNotificationsDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_NOTIFICATIONS_DRAWER });
    };
}

export const openUserProfileDrawer = (initialValues) => {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_USER_PROFILE_DRAWER, payload: initialValues });
    };
}

export const openDeclineJobDrawer = (initialValues) => {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_DECLINE_JOB_DRAWER, payload: initialValues });
    };
}

export function openImportContactsDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_IMPORT_CONTACT_DRAWER, payload: {} });
    };
}

export function openConnectLinkedInDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_CONNECT_LINKEDIN_DRAWER, payload: {} });
    };
}

export function openUploadLinkedInContactsDrawer() {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_UPLOAD_LINKEDIN_CONTACTS_DRAWER, payload: { showCountDown: false } });
    };
}

export function openCSVContactsDrawer(drawerProps) {
    return dispatch => {
        dispatch({ type: drawerActionsConstants.OPEN_CSV_CONTACTS_DRAWER, payload: drawerProps });
    }
}
