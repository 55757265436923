import { StyleSheet } from 'react-native';
import {$green} from '../../generalStyles';

export default StyleSheet.create({
    screenWrapper: {
        padding: 8
    },
    permissionWrapper: {
        flexDirection: 'row',
        paddingVertical: 10,
        paddingHorizontal: 16,
        backgroundColor: '#fff',
        marginBottom: 10,
        borderRadius: 5,
        alignItems: 'center'
    },
    permissionWrapperDisabled: {
        opacity: 0.4
    },
    permissionIcon: {
        height: 30,
        width: 30,
        marginRight: 15
    },
    permissionTitle: {
        color: $green,
        marginBottom: 3,
        fontSize: 14,
        fontWeight: 'bold'
    },
    permissionDescription: {
        fontSize: 12
    }
});