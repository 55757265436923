import { StyleSheet } from 'react-native';
import { $green, $greish, $greenish, $navyBlue } from '../../generalStyles';

export default StyleSheet.create({
    pageWrapper: {
        justifyContent: 'space-between'
    },
    signUpForm: {
        width: '42%',
        paddingHorizontal: 70,
        paddingVertical: 35,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    dashboardPadding: {
        paddingHorizontal: 30
    },
    errorField: {
        color: 'red',
        borderBottomColor: 'red'
    },
    fileUpload: {
    padding: 5,
    width: 'fit-content',
    borderRadius: 5,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#22c76e",
    },
    checkBoxTextMobile: {
        paddingTop: 3,
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 9,
    },
    checkBoxText: {
        paddingTop: 3,
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 11,
    },
    goBackButton: {
        width: '47%',
        backgroundColor: '#afafaf'
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingTop: 45,
        paddingBottom: 15,
        backgroundColor: $green
    },
    nonMobileHeader: {
        backgroundColor: '#EBF0F3'
    },
    backButton: {
        position: 'absolute',
        top: 68,
        left: 32
    },
    avatar: {
        height: 64,
        width: 64,
        borderRadius: 32
    },

    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    boonHeading: {
        fontSize: 70,
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        marginBottom: 15
    },
    signupText: {
        fontSize: 19,
        color: '#fff',
        marginBottom: 80
    },
    body: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingBottom: 90
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: 8,
        position: 'relative'
    },
    coverButton: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 10
    },
    halfWidthLeft: {
        width: '50%',
        marginRight: 8
    },
    halfWidthRight: {
        width: '50%',
        marginLeft: 8
    },
    fullWidth: {
        width: '100%'
    },
    saveNextButton: {
        marginTop: 20,
        width: '47%',
    },
    backButtonText: {
        marginTop: 20,
    },
    backButton: {
        alignSelf: 'center'
    },
    loginButton: {
        marginTop: 20,
        width: '47%',
        backgroundColor: '#d5dbda',
        color: '#7d8d8a',
        marginRight: 20
    },
    logoWrapper: {
        flexShrink: 1,
        flexGrow: 0,
        paddingTop: 100,
        paddingBottom: 80,
        position: 'relative',
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    logo: {
        width: 165,
        height: 40,
        flexGrow: 0,
        flexShrink: 1,
        resizeMode: 'contain'
    },
    mobileLogoWrapper: {
        paddingTop: 80,
        paddingBottom: 80,
        alignItems: 'center',
    },
    mobileLogo: {
        width: 115,
        height: 30,
        flexGrow: 0,
        flexShrink: 1,
        resizeMode: 'contain'
    },
    mobileBoonHeading: {
        fontSize: 40,
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        marginBottom: 15
    },
    changeCompanyText: {
        fontSize: 14,
        fontFamily: 'Roboto-Regular',
        paddingTop: 20
    },
    mobileSignUpForm: {
        width: '90%',
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: '#fff',
        borderRadius: 8,
    },
    image: {
        height: 20,
        width: 20,
        backgroundColor: $green,
        borderRadius: 5,
        marginTop: 15,
    },
    width90: {
        width: '90%'
    },
    width70: {
        width: '70%'
    },
    avatarWrapper: {
        alignSelf: 'flex-start'
    },
    avatar: {
        height: 80,
        width: 80,
        borderRadius: 32
    },
    selectStyles: {
        marginVertical: 10,
        width: '98%',
        paddingTop: 10,
    },
    mobileDropdownContainer: {
        width: '100%',
        marginVertical: 20,
        borderBottomColor: '#aeaeae',
        borderBottomWidth: 1
    },
    deleteFile: {
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: $green,
        marginLeft: '25px',
        color: '#fff',
        marginTop: '15px',
        padding: '3px'
    },
    title: {
        marginTop: 20,
        fontSize: 20,
        fontWeight: 'bold',
    },

});
