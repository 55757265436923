import * as activityActionsConstants from '../../constants/actions/activityActions';
import * as activityService from '../../services/activityService';

export function passRecommendation(recommendationId) {
    return dispatch => {
        dispatch({type: activityActionsConstants.PASS_RECOMMENDATION});
        activityService.passRecommendation(recommendationId).then(() => {
            dispatch({type: activityActionsConstants.PASS_RECOMMENDATION_SUCCESS, payload: recommendationId});
        }).catch(() => {
            dispatch({type: activityActionsConstants.PASS_RECOMMENDATION_ERROR});
        });
    };
}