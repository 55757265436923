import React from 'react';
import { connect } from 'react-redux';
import { View, ImageBackground } from 'react-native';
import Text from '../../../components/Text/Text';
import styles from './styles';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import Vector from '../../../assets/img/Vector.png';
import Cross from '../../../assets/img/cross.svg';

const AlreadyReferUserDetails = ({
  resetForm,
  candidate_name,
  candidate_email,
  closeSideBar,
  currentUser,
  org_name,
  genRefFrequency,
  refErrorType,
}) => (
  <View style={[styles.fromWrapper, styles.engagedContainer]}>
    <View style={styles.darnItInfo}>
      <img src={refErrorType === 'referral_time_limit' ? Cross : Vector} width="70px" />
      <Text style={styles.darnItHeader}>{refErrorType === 'referral_time_limit' ? 'Uh-oh!' : 'Darn It'}</Text>
      <Text style={styles.darnItText}>
        {currentUser.email === candidate_email.toLowerCase() ? (
          <Text> You cannot refer yourself. {'\n'}Please refer someone else instead.</Text>
        ) : refErrorType === 'referral_time_limit' ? (
          <Text>
            Looks like you've already sent a general referral for this contact recently.{'\n'}The policy set by&nbsp;
            {org_name}
            &nbsp;limits general referrals being sent to the same contact within {genRefFrequency} months.{'\n'}We'll be
            ready for your next referral after this period. Thanks for your contribution!
          </Text>
        ) : (
          <Text>
            <Text style={{ fontWeight: 600 }}>{candidate_name}</Text> has already considered this opening.
            {'\n'} Thanks for thinking of them{'\n'} It looks like you&apos;re on the right track.
          </Text>
        )}
      </Text>
    </View>
    <View>
      <View style={styles.actionButtons}>
        <PrimaryButton white style={{ width: '49%', border: '1px solid #0FBC71' }} onPress={() => closeSideBar()}>
          Close
        </PrimaryButton>
        <PrimaryButton style={{ width: '49%' }} onPress={() => resetForm()}>
          Send another referral
        </PrimaryButton>
      </View>
      <ImageBackground
        source={require('../../../assets/img/city-line-illustration.png')}
        style={styles.backgroundImage}
      />
    </View>
  </View>
);

const mapStateToProps = (state) => {
  return {
    currentUser: state.authReducer.currentUser,
  };
};

export default connect(mapStateToProps, null)(AlreadyReferUserDetails);
