import React, { Component } from 'react';
import { View, Platform } from 'react-native';
import Actions from '../../RouteActions';
import { openReferFormDrawer } from '../../DrawerActions';
import { dashboardUrls, browseUrls, referUrls, trackerUrls, myUrls } from '../../urls.js';
import { connect } from 'react-redux';

import BottomNavigation, { FullTab } from 'react-native-material-bottom-navigation';
import newMemberAppUrl from '../../services/newMemberAppUrl';

//icons
import HomeIcon from '../../assets/tabsIcons/HomeIcon/HomeIcon';
import BrowseIcon from '../../assets/tabsIcons/BrowseIcon/BrowseIcon';
import MyBoonIcon from '../../assets/tabsIcons/MyBoonIcon/MyBoonIcon';
import TrackerIcon from '../../assets/tabsIcons/TrackerIcon/TrackerIcon';
import UploadIcon from '../../assets/tabsIcons/UploadIcon/UploadIcon';

//utils
import { getCurrentOrganizationForUser } from '../../helperFunctions';

const icons = {
  home: HomeIcon,
  browse: BrowseIcon,
  myBoon: MyBoonIcon,
  tracker: TrackerIcon,
};

class TabBarComponent extends Component {
  tabs = [
    {
      key: 'home',
      icon: HomeIcon,
      action: () => Actions.home(),
      barColor: '#FFFFFF',
      label: '',
      pressColor: 'rgba(255, 255, 255, 0.16)',
      activePaths: Object.values(dashboardUrls),
    },
    {
      key: 'browse',
      icon: BrowseIcon,
      action: () => Actions.browseJobs(),
      barColor: '#FFFFFF',
      label: '',
      pressColor: 'rgba(255, 255, 255, 0.16)',
      activePaths: Object.values(browseUrls),
    },
    {
      key: 'refer',
      icon: UploadIcon,
      action: (currentUserOrganization) => {
        this.props.openReferForm({ organization_id: currentUserOrganization });
      },
      label: 'Refer',
      barColor: '#FFFFFF',
      pressColor: 'rgba(255, 255, 255, 0.16)',
      activePaths: Object.values(referUrls),
    },
    {
      key: 'tracker',
      icon: TrackerIcon,
      action: () => Actions.sentJobs(),
      barColor: '#FFFFFF',
      label: '',
      pressColor: 'rgba(255, 255, 255, 0.16)',
      activePaths: Object.values(trackerUrls),
    },
    {
      key: 'myBoon',
      icon: MyBoonIcon,
      action: () => window.open(`${newMemberAppUrl}/account/profile`, '_blank'),
      barColor: '#FFFFFF',
      label: '',
      pressColor: 'rgba(255, 255, 255, 0.16)',
      activePaths: Object.values(myUrls),
    },
  ];

  render() {
    const { currentUserOrganization, currentPath } = this.props;
    const footerBottom = {
      position: 'fixed',
      bottom: '0px',
      overflow: 'hidden',
      width: '100%',
      height: '58px',
    };

    return (
      <View style={Platform.OS === 'web' && footerBottom}>
        <BottomNavigation
          renderTab={this.renderTab}
          tabs={this.tabs}
          useLayoutAnimation={true}
          onTabPress={(newTab) => newTab.action(currentUserOrganization)}
          style={{ height: 100 }}
        />
      </View>
    );
  }

  renderTab = ({ tab, isActive }) => {
    return (
      <FullTab
        key={tab.key}
        isActive={tab.activePaths.indexOf(this.props.currentPath) !== -1}
        label={tab.label}
        renderIcon={this.renderIcon(tab)}
      />
    );
  };

  renderIcon =
    (tab) =>
    ({ isActive }) => {
      const Icon = tab.icon;
      return <Icon focused={isActive} />;
    };
}

const mapStateToProps = (state) => {
  return {
    currentUserOrganization: getCurrentOrganizationForUser(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openReferForm: (initialValues) => {
      dispatch(openReferFormDrawer(initialValues));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TabBarComponent);
