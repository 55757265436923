import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    statusLabelWrapper: {
        flexDirection: 'row',
        paddingHorizontal: 8,
        paddingVertical: 2,
        borderRadius: 3,
        alignItems: 'center'
    },
    statusLabelIcon: {
        height: 12,
        width: 12,
        marginRight: 4
    },
    statusLabelTitle: {
        fontSize: 12,
        color: '#fff',
        fontWeight: 'bold'
    },
    statusLabelDescription: {
        fontSize: 12,
        color: '#fff'
    }
});
