import React, { Component } from 'react';
import {
    Image,
    View
} from 'react-native';

import icon from './assets/upload.png';
import styles from "./styles";

export default class UploadIcon extends Component {
    render() {
        return (
            <View style={styles.container}>
                <Image source={icon} style={styles.image}/>
            </View>
        );
    }
}