import Actions from '../RouteActions';
import * as browseActionsConstants from '../constants/actions/browseActions';
import * as appActionsConstants from '../constants/actions/appActions';
import * as trackerActionsConstants from '../constants/actions/trackerActions';
import * as browseService from '../services/browseService';
import * as trackerService from '../services/trackerService';
import { getJobsTracker } from '../screens/Tracker/actions';
import { notification } from '../helperFunctions';
import history from '../RouteHistory';
import { trackerUrls } from '../urls';

export function getDetails(type, id, organizationId, fallbackId) {
  return (dispatch) => {
    dispatch({
      type: browseActionsConstants.GET_BROWSE_DETAILS,
      payload: { type },
    });
    browseService
      .getDetails(type, id, organizationId, fallbackId)
      .then((response) => {
        dispatch({
          type: browseActionsConstants.GET_BROWSE_DETAILS_SUCCESS,
          payload: {
            type,
            details: response.data.payload.job || response.data.payload,
            employer: response.data.payload.organization,
          },
        });

        if (response.data.response_code !== responseCodes.OK && response.data.payload.message) {
          notification.ref.show({
            message: response.data.payload.message,
            isError: true,
          });
        }
      })
      .catch(() => {
        dispatch({
          type: browseActionsConstants.GET_BROWSE_DETAILS_ERROR,
        });
      });
  };
}

export function declineJob(id, status, direction) {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    dispatch({ type: trackerActionsConstants.DECLINE_JOB });
    trackerService
      .declineJob(id)
      .then(() => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({ type: trackerActionsConstants.DECLINE_JOB_SUCCESS });
        history.push(trackerUrls.tracker);
        dispatch(getJobsTracker({ status, direction }));
        dispatch(getJobsTracker({ status: 'archived', direction }));
      })
      .catch(() => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({ type: trackerActionsConstants.DECLINE_JOB_ERROR });
      });
  };
}

export function getTrackerDetails(params) {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    dispatch({ type: trackerActionsConstants.GET_OPPORTUNITY_DETAILS });
    trackerService
      .getTrackerDetails(params)
      .then((response) => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({
          type: trackerActionsConstants.GET_OPPORTUNITY_DETAILS_SUCCESS,
          payload: response.data.payload,
        });
        dispatch({
          type: trackerActionsConstants.GET_CANDIDATE_STRENGTH_QUESTIONS_SUCCESS,
          payload: response.data.payload,
        });
      })
      .catch(() => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        dispatch({
          type: trackerActionsConstants.GET_OPPORTUNITY_DETAILS_ERROR,
        });
      });
  };
}

export function getReferralQuestions(referRequestId, reStrengthenReferral) {
  return (dispatch) => {
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    dispatch({ type: trackerActionsConstants.GET_STRENGTH_QUESTIONS });
    trackerService
      .getReferralQuestions(referRequestId, reStrengthenReferral)
      .then((response) => {
        dispatch({
          type: trackerActionsConstants.GET_STRENGTH_QUESTIONS_SUCCESS,
          payload: response.data.payload,
        });
      })
      .catch(() => {
        dispatch({
          type: trackerActionsConstants.GET_STRENGTH_QUESTIONS_ERROR,
        });
      })
      .finally(() => {
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
      });
  };
}

export function clearOpportunityDetails() {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.CLEAR_OPPORTUNITY_DETAILS });
  };
}
