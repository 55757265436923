import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    text: {
        textAlign: 'center',
    },
    elementWrapper: {
        flexDirection: 'row',
        backgroundColor: '#fff',
        alignItems: 'center',
        alignContent: 'center',
        marginTop: 20,
    },
    image: {
        height: 180,
        width: '100%',
        marginRight: 12,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#d8d8d8'
    },
    imageWrapper: {
        paddingVertical: 8,
        paddingLeft: 16,
        position: 'relative',
        justifyContent: 'center',
        marginTop: 20,
        width: '100%',
    },
    fileInput: {
        height: 180,
        width: '100%',
    },
    button: {
        width: '80%',
        backgroundColor: '#0E76A8',
        padding: 10,
        alignItems: 'center',
        borderRadius: 5,
        width: 150,
    },
    buttonText: {
        color: '#fff',
    },
    link: {
        textDecorationLine: 'underline',
        color: '#0000EE',
    },
});
