import React, { Component } from 'react';
import {connect} from 'react-redux';
import Alert from '../../wrappers/Alert/Alert';
import { View, Image, Platform } from 'react-native';
import { reduxForm, Field } from 'redux-form';

//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import FormInput from '../../components/FormInput/FormInput';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';
import SignIn        from '../../components/SignIn/SignIn';

//images
import styles from './styles';

//actions
import * as actions from './actions';
import { closeDrawer, openSettingsDrawer } from '../../DrawerActions.js';

//utils
import {notification} from '../../helperFunctions';

class SetPassword extends Component {

    submitForm = () => {
        const { formValues, userCurrentStep, handleSubmit, currentUser } = this.props;
        formValues.step = userCurrentStep;
        formValues.email = currentUser.email;
        handleSubmit(formValues);
    }
    render() {
        const { goBack, signup, userCurrentStep } = this.props;
        const web = Platform.OS === 'web';
        return (
            <SignIn setPassword={true} signupProcess={(signup || userCurrentStep === 'create_password') ? true : false}>
                <View style={[styles.formWrapper, !web && {marginTop: 30}]}>
                    <View>
                        <Field
                            placeholder="Password"
                            name={'password'}
                            component={FormInput}
                            wrapperStyle={styles.field}
                            secureTextEntry
                        />
                        <Field
                            placeholder="Confirm password"
                            name={'confirm_password'}
                            component={FormInput}
                            wrapperStyle={styles.field}
                            secureTextEntry
                        />
                        <PrimaryButton onPress={() => this.submitForm()} style={styles.submitButton}>
                            Save password
                        </PrimaryButton>
                    </View>
                </View>
            </SignIn>
        );
    }
}

const mapStateToProps = state => {
    return {
        formValues: state.form.setPassword && state.form.setPassword.values ? state.form.setPassword.values : {},
        currentUser: state.authReducer.currentUser,
        userCurrentStep: state.stepsReducer.step,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleSubmit: data => {
            if (data.password !==
                data.confirm_password) {
                notification.ref.show({message: 'Passwords are not the same', isError: true});
            } else {
                data.oldAccount = 1;
                data.publicJobApplication = true;
                dispatch(actions.resetPassword(data));
            }
        },
        goBack: () => {
            dispatch(closeDrawer());
            dispatch(openSettingsDrawer());
        }
    }
};

SetPassword = reduxForm({ form: 'setPassword' })(SetPassword);

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
