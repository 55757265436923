import React, { Component } from "react";
import { connect } from "react-redux";
import {
  View,
  Dimensions,
  TouchableOpacity,
  Keyboard,
  Platform,
  ScrollView,
} from "react-native";
import { Drawer, List } from "@ant-design/react-native";
import Actions from "../../RouteActions";
import { TabView, SceneMap } from "react-native-tab-view";
import { withGetScreen } from "react-native-getscreen";

//components
import DashboardBase from "../../components/DashboardBase/DashboardBase";
import FollowCompaniesComponent from "../../components/FollowCompaniesComponent/FollowCompaniesComponent";
import Text from "../../components/Text/Text";
import Network from "../../components/NetworkTab/NetworkTab";
import Profile from "./components/Profile/Profile";
import Settings from "./components/Settings/Settings";
import ManageAccounts from "./components/ManageAccounts/ManageAccounts";
import OrganizationStatus from "../../wrappers/organizationStatus/organizationStatus";

import styles from "./styles";
import { drawerElement } from "../../generalStyles";
import UserProfileSection from "./components/UserProfileSection/UserProfileSection";

import * as actions from "./actions";

const initialLayout = {
  height: 0,
  width: Dimensions.get("window").width,
};

const tabs = [
  { label: "Profile", value: "profile" },
  { label: "My Network", value: "network" },
  // { label: 'Manage Accounts', value: 'manageAccounts' },
  { label: "Settings", value: "settings" },
];

class MyBoon extends Component {
  _renderScene = SceneMap({
    network: () => (
      <Network
        onOpen={this.onOpen}
        params={this.props.match.params}
        setClick={(click) => (this.clickChild = click)}
      />
    ),
    // organizations: () => this.props.initTabIndex === 2 ? <FollowCompaniesComponent
    //     wrapperStyles={{ paddingTop: 16 }} origin={'my'} params={this.props.match.params} /> : <View />,
    profile: Profile,
    settings: Settings,
    manageAccounts: ManageAccounts,
  });

  onOpen = () => {
    this.drawer && this.drawer.openDrawer();
  };

  constructor(props) {
    super(props);

    this.state = {
      index: props.initTabIndex ? props.initTabIndex : 0,
      routes: [
        { key: "profile", title: "Profile" },
        { key: "network", title: "My Network" },
        // { key: 'organizations', title: 'My Organizations' },
        { key: "settings", title: "Settings" },
        // { key: 'manageAccounts', title: 'Manage Accounts'},
      ],
    };
    this.scrollRef = null;
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  static onEnter() {
    Actions.refresh({
      onEnterFlag: new Date(),
    });
  }

  handleIndexChange(index) {
    Keyboard.dismiss();
    this.setState({ index });
  }

  handleTabChange = (_jumpTo, key) => {
    switch (key) {
      case "profile":
        Actions.myProfile();
        break;
      // case 'organizations':
      //     Actions.myCompanies();
      //     break;
      case "settings":
        Actions.mySettings();
        break;
      case "manageAccounts":
        Actions.manageAccounts();
        break;
      default:
        // network
        Actions.myContacts();
    }

    // jumpTo(key)
  };

  renderHeader(props) {
    const {
      navigationState: { index, routes },
      jumpTo,
    } = props;
    const borderStyle = {
      borderBottomColor: "#dadada",
      borderBottomWidth: 1,
      width: "100%",
    };
    const web = Platform.OS === "web";
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    return (
      <View style={{ flexGrow: 0 }}>
        <View style={styles.tabBar}>
          {routes.map((route, routeIndex) => {
            const tabButtonStyles = [
              styles.tabButtonStyle,
              tabScreen && styles.tabButtonMobileStyle,
            ];
            const tabActiveButtonStyle = [styles.tabButtonTextStyle];

            if (index === routeIndex) {
              tabButtonStyles.push(styles.tabButtonActive);
              tabActiveButtonStyle.push({
                fontWeight: "bold",
                color: "#9B9B9B",
              });
            }

            return (
              <TouchableOpacity
                onPress={() => this.handleTabChange(jumpTo, route.key)}
                key={route.key}
                style={tabButtonStyles}
              >
                <Text style={tabActiveButtonStyle} numberOfLines={1}>
                  {route.title}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={borderStyle} />
      </View>
    );
  }

  getListItems() {
    const itemArr = ["Gmail", "Phonebook", "Connections", "Cancel"].map(
      (action, index) => {
        return (
          <List.Item
            key={index}
            onPress={() => this.handleDrawerItemClick(index)}
            style={drawerElement.listItemStyle}
          >
            <Text style={drawerElement.itemTextStyle}>{action}</Text>
          </List.Item>
        );
      }
    );
    return itemArr;
  }

  handleDrawerItemClick = (index) => {
    this.drawer && this.drawer.closeDrawer();
    this.clickChild(index);
  };

  handleTabsChange = (item) => {
    switch (item[0].value) {
      case "network":
        this.props.setTab("network");
        Actions.myContacts();
        break;
      case "settings":
        this.props.setTab("settings");
        Actions.mySettings();
        break;
      case "manageAccounts":
        this.props.setTab("manageAccounts");
        Actions.manageAccounts();
        break;
      default:
        // profile
        this.props.setTab("profile");
        Actions.myProfile();
    }
  };

  render() {
    const itemArr = this.getListItems();
    const sidebar = (
      <ScrollView style={[drawerElement.scrollElement]}>
        <List>{itemArr}</List>
      </ScrollView>
    );
    const web = Platform.OS === "web";
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    const { width, showSearchBlock, myAccountCurrentTab } = this.props;
    let children = <Profile />;
    switch (myAccountCurrentTab) {
      case "network":
        children = (
          <Network
            onOpen={this.onOpen}
            params={this.props.match.params}
            setClick={(click) => (this.clickChild = click)}
          />
        );
        break;
      case "settings":
        children = <Settings />;
        break;
      case "manageAccounts":
        children = <ManageAccounts />;
        break;
      default:
        children = <Profile />;
    }
    return (
      <Drawer
        sidebar={sidebar}
        open={false}
        drawerRef={(el) => (this.drawer = el)}
        onOpenChange={this.onOpenChange}
        drawerBackgroundColor="#fff"
        drawerWidth={width}
      >
        <DashboardBase title="My Account">
          <UserProfileSection />
          <ScrollView
            ref={(el) => (this.scrollRef = el)}
            style={[!tabScreen && { flex: 1, padding: 30 }]}
          >
            {tabScreen ? (
              <View
                style={[
                  tabScreen
                    ? {
                        width: 150,
                        zIndex: 1,
                        marginTop: 10,
                        marginLeft: 10,
                        marginBottom:
                          myAccountCurrentTab == "network" ? -50 : 0,
                        display: showSearchBlock ? "none" : "block",
                      }
                    : { display: "none" },
                ]}
              >
                <OrganizationStatus
                  onChange={(e) => this.handleTabsChange(e)}
                  selected={myAccountCurrentTab}
                  searchable={false}
                  name="tab_changer"
                  placeholder="Current tab"
                  dropdownValues={tabs}
                  styles={styles.dropdownStyleMobile}
                  labelField={"label"}
                  valueField={"value"}
                />
              </View>
            ) : (
              <TabView
                navigationState={this.state}
                renderScene={this._renderScene}
                renderTabBar={this.renderHeader}
                onIndexChange={this.handleIndexChange}
                initialLayout={initialLayout}
              />
            )}
            {tabScreen ? children : null}
          </ScrollView>
        </DashboardBase>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    width: state.drawerReducer.width,
    myAccountCurrentTab: state.authReducer.myAccountCurrentTab,
    showSearchBlock: state.authReducer.showSearchBlock,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setTab: (tab) => {
      dispatch(actions.setCurrentTab(tab));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGetScreen(MyBoon));
``;
