import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { View, ScrollView, Dimensions, Platform } from 'react-native';
import TrackerList from '../TrackerList/TrackerList'
import responsiveStyles from '../../../../responsiveStyles';
import Text from '../../../../components/Text/Text';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import styles from './styles';
import Actions from '../../../../RouteActions';
import TrackerListSearch from './TrackerSearchBar/TrackerSearchBar'
import OrganizationStatus from '../../../../wrappers/organizationStatus/organizationStatus';
class UpperComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.scrollRef = null
        this.state = {
            myJobStatuses: [
                { label: 'Received', value: 'new' },
                { label: 'Applied', value: 'applied' },
                { label: 'Interviewing', value: 'interview' },
                { label: 'Archived', value: 'archived' },
            ],
            theirJobStatuses: [
                { label: 'Sent', value: 'new' },
                { label: 'Applied', value: 'applied' },
                { label: 'Interviewing', value: 'interview' },
                { label: 'Archived', value: 'archived' },
            ],
            myJobStatus: { label: 'Received', value: 'new' },
            theirJobStatus: { label: 'Sent', value: 'new' }
        }
    }

    myJobStatusHandler = (statusObj) => {
        this.setState({ myJobStatus: statusObj[0] })
    }

    theirJobStatusHandler = (statusObj) => {
        this.setState({ theirJobStatus: statusObj[0] })
    }

    myJobsMobile() {
        return (
            < View style={responsiveStyles.justifyCenter} >
                <View style={{ flexDirection: Dimensions.get('window').width < 750 ? 'col' : 'row', zIndex: 10 }}>
                    <TrackerListSearch direction="my" />
                    <View>
                        <OrganizationStatus
                            searchable={false}
                            selected={this.state.myJobStatus.value}
                            onChange={this.myJobStatusHandler}
                            name='my_job_list_select'
                            placeholder='Select status'
                            dropdownValues={this.state.myJobStatuses}
                            styles={styles.select}
                            labelField={'label'}
                            valueField={'value'}
                        />
                    </View>
                </View>
                < View style={styles.row} >
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>{this.state.myJobStatus.label}</Text>
                        </View>
                        <TrackerList direction="my" status={this.state.myJobStatus.value} index={0} />
                    </View>
                </View >
            </View >
        );
    }

    theirJobsMobile() {
        return (
            < View style={responsiveStyles.justifyCenter} >
                <View style={{ flexDirection: Dimensions.get('window').width < 750 ? 'col' : 'row', zIndex: 10 }}>
                    <TrackerListSearch direction="their" />
                    <View>
                        <OrganizationStatus
                            searchable={false}
                            selected={this.state.theirJobStatus.value}
                            onChange={this.theirJobStatusHandler}
                            name='their_job_list_select'
                            placeholder='Select status'
                            dropdownValues={this.state.theirJobStatuses}
                            styles={styles.select}
                            labelField={'label'}
                            valueField={'value'}
                        />
                    </View>
                </View>
                < View style={styles.row} >
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>{this.state.theirJobStatus.label}</Text>
                        </View>
                        <TrackerList direction="their" status={this.state.theirJobStatus.value} index={1} />
                    </View>
                </View >
            </View >
        );
    }

    myJobs() {
        return (
            < View style={responsiveStyles.justifyCenter} >
                <TrackerListSearch direction="my" />
                < View style={styles.row} >
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Received</Text>
                        </View>
                        <TrackerList direction="my" status="new" index={0} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Applied</Text>
                        </View>
                        <TrackerList direction="my" status="applied" index={0} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Interviewing</Text>
                        </View>
                        <TrackerList direction="my" status="interview" index={0} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Archived</Text>
                        </View>
                        <TrackerList direction="my" status="archived" index={0} />
                    </View>
                </View >
            </View >
        );
    }

    theirJobs() {
        return (
            <View style={responsiveStyles.justifyCenter}>
                <TrackerListSearch direction="their" />
                <View style={styles.row}>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Sent</Text>
                        </View>
                        <TrackerList direction="their" status="new" index={1} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Applied</Text>
                        </View>
                        <TrackerList direction="their" status="applied" index={1} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Interviewing</Text>
                        </View>
                        <TrackerList direction="their" status="interview" index={1} />
                    </View>
                    <View style={styles.col}>
                        <View>
                            <Text style={styles.headerText}>Archived</Text>
                        </View>
                        <TrackerList direction="their" status="archived" index={1} />
                    </View>
                </View>
            </View>
        );
    }

    render() {
        const { direction, lists } = this.props;
        return (
            <ScrollView ref={(el) => this.scrollRef = el} style={[Platform.OS === 'web' && {maxHeight: 'calc(100vh - 170px)'}]}>
                <ScrollView horizontal={true}>
                    {(Dimensions.get('window').width < 750) && (direction === "my" ? this.myJobsMobile() : this.theirJobsMobile())}
                    {(Dimensions.get('window').width > 750) && (direction === "my" ? this.myJobs() : this.theirJobs())}
                </ScrollView>
                {(lists.appliedList.length == 0 && lists.interviewList.length == 0 && lists.archivedList.length == 0 && lists.newList.length == 0) ?
                    <View style={styles.centered}>
                        <PrimaryButton style={[styles.browseButton,]}
                            onPress={() => Actions.browseJobs()}
                        >
                            Browse Jobs
                   </PrimaryButton>
                    </View> : <Text />
                }
            </ScrollView>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lists: state.trackerReducer
    }
};


export default connect(mapStateToProps)(UpperComponent);
