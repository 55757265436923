import React, { Component } from 'react';
import { connect } from 'react-redux';
import Alert from '../../wrappers/Alert/Alert';
import {
    View,
    Image,
    TouchableOpacity,
    Linking,
    Platform
} from 'react-native';
import Actions from '../../RouteActions';
import Spinner from 'react-native-loading-spinner-overlay';
import * as AsyncStorage from '../../AsyncStorage';

//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import Checkbox from '../../components/Checkbox/Checkbox';
import Text from '../../components/Text/Text';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';
import DrawerHeader from '../../components/Drawer/drawerHeader.js';

//images
import googleColorIcon from '../../assets/img/google_color_icon.png';
import googleGreyIcon from '../../assets/img/google_grey_icon.png';

import outlookColorIcon from '../../assets/img/outlook_color_icon.png';
import outlookGreyIcon from '../../assets/img/outlook_grey_icon.png';

import facebookColorIcon from '../../assets/img/facebook_color_icon.png';
import facebookGreyIcon from '../../assets/img/facebook_grey_icon.png';

import twitterColorIcon from '../../assets/img/twitter_color_icon.png';
import twitterGreyIcon from '../../assets/img/twitter_grey_icon.png';

import linkedinColorIcon from '../../assets/img/linkedIn_green.png';
import linkedinGreyIcon from '../../assets/img/linkedin_grey_icon.png';
import shareIcon from '../../assets/img/share_icon.png';

import lock from '../../assets/img/lock_grey.png';
import chevronLeft from '../../assets/img/chevron_left_grey.png';

//styles
import styles from './styles';
import { listElement } from '../../generalStyles';

//actions
import * as actions from './actions';
import { closeDrawer, openChangePasswordDrawer, openImportContactsDrawer } from '../../DrawerActions.js';

//services
import baseService, { apiSeverUrl } from '../../services/baseService';
import { withGetScreen } from 'react-native-getscreen';
import { googleSSOUrl, notification } from '../../helperFunctions';

class Settings extends Component {
    constructor(props) {
        super(props);

        this.handleGoogleConnectPress = this.handleGoogleConnectPress.bind(this);
    }

    handleGoogleConnectPress() {
        const { googleConnected, unsubscribeGoogle } = this.props;
        AsyncStorage.getAccessToken().then(accessToken => {
            if (googleConnected) {
                Alert.alert('Disconnect from Google?', 'You won\'t be able to see your new Google connections on Boon anymore', [
                    { text: 'Cancel', style: 'cancel', onPress: () => null },
                    {
                        text: 'Disconnect',
                        onPress: () => unsubscribeGoogle(accessToken)
                    }
                ]);
            } else {
                googleSSOUrl(accessToken);
            }
        });
    }

    handleLinkedInConnectPress = () => {
        const { linkedinConnected, unsubscribeLinkedin } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(resp => {
                if (linkedinConnected) {
                    Alert.alert('Disconnect from Linkedin?', 'You won\'t be able to see your new Linkedin connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeLinkedin()
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        Actions.web({ title: 'Authenticate LinkedIn', url: `${apiSeverUrl}/sign_in_linkedin?device_fingerprint=${response}&access_token=${resp}&web_app=false&permission_page=true` });
                    } else {
                        Linking.openURL(`${apiSeverUrl}/sign_in_linkedin?device_fingerprint=${response}&access_token=${resp}&web_app=true&permission_page=true`)
                    }
                }
            });
        })
    }

    handleOutlookConnectPress = () => {
        const { outlookConnected, unsubscribeOutlook } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (outlookConnected) {
                    Alert.alert('Disconnect from Outlook?', 'You won\'t be able to see your new Outlook connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeOutlook(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Outlook', url: `${apiSeverUrl}/sign_in_outlook?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/microsoft_graph_auth?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    handleFacebookConnectPress = () => {
        const { facebookConnected, unsubscribeFacebook } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (facebookConnected) {
                    Alert.alert('Disconnect from Facebook?', 'You won\'t be able to see your new Facebook connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeFacebook(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Facebook', url: `${apiSeverUrl}/sign_in_facebook?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/facebook?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    handleTwitterConnectPress = () => {
        const { twitterConnected, unsubscribeTwitter } = this.props;
        const mobile = this.props.isMobile();

        AsyncStorage.getDeviceFingerprint().then(response => {
            AsyncStorage.getAccessToken().then(accessToken => {
                if (twitterConnected) {
                    Alert.alert('Disconnect from Twitter?', 'You won\'t be able to see your new Twitter connections on Boon anymore', [
                        { text: 'Cancel', style: 'cancel', onPress: () => null },
                        {
                            text: 'Disconnect',
                            onPress: () => unsubscribeTwitter(accessToken)
                        }
                    ]);
                }
                else {
                    if (mobile) {
                        // Actions.web({ title: 'Authenticate Twitter', url: `${apiSeverUrl}/sign_in_twitter?device_fingerprint=${response}&access_token=${accessToken}&web_app=false&permission_page=true` });
                    } else {
                        let form = document.createElement("form");
                        form.method = "POST";
                        form.action = `${apiSeverUrl}/auth/twitter?access_token=${accessToken}&from_web_app=${Platform.OS == 'web'}`;
                        document.body.appendChild(form);
                        form.submit();
                    }
                }
            });
        })
    }

    componentWillReceiveProps(newProps) {
        if (newProps.googleConnectedSuccess && newProps.googleConnectedSuccess !== this.props.googleConnectedSuccess) {
            this.props.subscribeGoogle();
        }
    }

    render() {
        const {
            googleConnected, googleConnectionLoading, logout, linkedinConnected,
            outlookConnected, facebookConnected, twitterConnected
        } = this.props;
        const web = Platform.OS === 'web';

        return (
            <View style={!web && { marginTop: 30 }}>
                <DrawerHeader title='Settings' />
                <View style={listElement.groupWrapper}>
                    <Text style={listElement.groupTitle}>SOCIAL MEDIA</Text>
                    <View style={listElement.elementWrapper}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.smallIcon} source={googleConnected ? googleColorIcon : googleGreyIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={this.handleGoogleConnectPress}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                {googleConnected &&
                                    <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Connected with Google</Text> ||
                                    <Text style={styles.elementTitle}>Connect with Google</Text>
                                }
                            </View>
                            <Checkbox checked={googleConnected} />
                        </TouchableOpacity>
                    </View>
                    <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.smallIcon} source={linkedinConnected ? linkedinColorIcon : linkedinGreyIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={this.handleLinkedInConnectPress}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                {linkedinConnected ?
                                    <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Connected with Linkedin</Text>
                                    :
                                    <Text style={styles.elementTitle}>Connect with Linkedin</Text>
                                }
                            </View>
                            <Checkbox checked={linkedinConnected} />
                        </TouchableOpacity>
                    </View>
                    <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.smallIcon} source={outlookConnected ? outlookColorIcon : outlookGreyIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={this.handleOutlookConnectPress}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                {outlookConnected ?
                                    <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Connected with Outlook</Text>
                                    :
                                    <Text style={styles.elementTitle}>Connect with Outlook</Text>
                                }
                            </View>
                            <Checkbox checked={outlookConnected} />
                        </TouchableOpacity>
                    </View>
                    <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.smallIcon} source={facebookConnected ? facebookColorIcon : facebookGreyIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={this.handleFacebookConnectPress}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                {facebookConnected ?
                                    <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Connected with Facebook</Text>
                                    :
                                    <Text style={styles.elementTitle}>Connect with Facebook</Text>
                                }
                            </View>
                            <Checkbox checked={facebookConnected} />
                        </TouchableOpacity>
                    </View>
                    <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.smallIcon} source={twitterConnected ? twitterColorIcon : twitterGreyIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={this.handleTwitterConnectPress}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                {twitterConnected ?
                                    <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Connected with Twitter</Text>
                                    :
                                    <Text style={styles.elementTitle}>Connect with Twitter</Text>
                                }
                            </View>
                            <Checkbox checked={twitterConnected} />
                        </TouchableOpacity>
                    </View>
                    <View style={[listElement.elementWrapper, { marginTop: 10 }]}>
                        <View style={listElement.imageWrapper}>
                            <Image style={[styles.smallIcon, {height: 20}]} source={shareIcon} />
                        </View>
                        <TouchableOpacity style={[listElement.elementBody, listElement.last]} onPress={() => this.props.onImportContacts()}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                <Text style={styles.elementTitle}>Import Contacts via CSV</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={listElement.groupWrapper}>
                    <Text style={listElement.groupTitle}>PASSWORD</Text>
                    <TouchableOpacity
                        style={listElement.elementWrapper}
                        onPress={() => this.props.onPasswordChange()}>
                        <View style={listElement.imageWrapper}>
                            <Image style={styles.lockIcon} source={lock} />
                        </View>
                        <View style={[listElement.elementBody, listElement.last]}>
                            <View style={[listElement.infoWrapper, styles.titleWrapper]}>
                                <Text style={[styles.elementTitle, styles.elementTitleConnected]}>Change password</Text>
                            </View>
                            <Image style={styles.chevron} source={chevronLeft} />
                        </View>
                    </TouchableOpacity>
                </View>
                <View style={styles.logoutButton}>
                    <PrimaryButton onPress={logout}>
                        Logout
                    </PrimaryButton>
                </View>
                <Spinner visible={googleConnectionLoading} />
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
        googleConnected: state.authReducer.currentUser.sync_google_people_contacts,
        linkedinConnected: state.authReducer.currentUser.linkedin_authenticated,
        outlookConnected: state.authReducer.currentUser.outlook_authenticated,
        facebookConnected: state.authReducer.currentUser.facebook_authenticated,
        twitterConnected: state.authReducer.currentUser.twitter_authenticated,
        googleConnectionLoading: state.authReducer.googleConnectionLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        unsubscribeGoogle: accessToken => {
            dispatch(actions.unsubscribeGoogle(accessToken));
        },
        unsubscribeLinkedin: () => {
            dispatch(actions.unsubscribeLinkedin());
        },
        unsubscribeOutlook: accessToken => {
            dispatch(actions.unsubscribeOutlook(accessToken));
        },
        unsubscribeFacebook: accessToken => {
            dispatch(actions.unsubscribeFacebook(accessToken));
        },
        unsubscribeTwitter: accessToken => {
            dispatch(actions.unsubscribeTwitter(accessToken));
        },
        subscribeGoogle: () => {
            dispatch(actions.subscribeGoogle());
        },
        logout: () => {
            dispatch(actions.logout());
            baseService.removeAuthToken();
        },
        onDrawerClose: () => {
            dispatch(closeDrawer());
        },
        onPasswordChange: () => {
            dispatch(closeDrawer());
            dispatch(openChangePasswordDrawer());
        },
        importFields: (data) => {
            /* 
            // TODO: Not sure why this is here, throws warning that causes an error when building in CircleCI
            dispatch(actions.importFields(data));
            */
        },
        onImportContacts: () => {
            dispatch(closeDrawer());
            dispatch(openImportContactsDrawer());
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Settings));
