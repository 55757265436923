import React, { Component } from 'react';
import Alert from '../../wrappers/Alert/Alert';
import {View, TouchableOpacity, Image} from 'react-native';
import {connect} from 'react-redux';

//styles
import {listElement} from '../../generalStyles';

//components
import Text from '../../components/Text/Text';

//images
import defaultOrgAvatar from '../../assets/img/default_org_avatar.png';

//utils
import * as organizationsActions from '../../globalActions/organizationsActions';
import {locationObjToStr} from '../../helperFunctions';

class OrganizationListElement extends Component {
    constructor(props) {
        super(props);

        this.handleFollowingPress = this.handleFollowingPress.bind(this);
    }

    handleFollowingPress() {
        const { organization, unfollowOrganization } = this.props;
        Alert.alert(`Unfollow ${organization.name}`, 'You will no longer see updates about them. You can follow them back later.', [
            {text: 'Cancel', style: 'cancel'},
            {text: 'Unfollow', onPress: () => unfollowOrganization(organization.id)},
        ]);
    }

    render() {
        const { organization, onPress, currentOrgId, isLast, showJobCount } = this.props;
        const image = organization.logo_image_url ? { uri: organization.logo_image_url } : defaultOrgAvatar;
        const jobCountStyle = {
            fontSize: 20,
            fontWeight: 'bold'
        }
        const activeOrg = parseInt(currentOrgId) === organization.id
        const style = activeOrg ? [listElement.elementWrapperTopAligned, listElement.activeListElement] : listElement.elementWrapperTopAligned
        return (
            <TouchableOpacity style={[style, {marginBottom: 10}]} onPress={onPress}>
                <View style={listElement.imageWrapper}>
                    <Image style={listElement.image} source={image} resizeMode="contain" />
                </View>
                <View style={[listElement.elementBody, isLast ? listElement.last : {}]}>
                    <View style={listElement.infoWrapper}>
                        <Text style={[listElement.title, {fontWeight: 'bold'}]} numberOfLines={1}>{organization.name}</Text>
                        {Boolean(organization.location) && <Text style={listElement.description} numberOfLines={1}>{locationObjToStr(organization.location)}</Text>}
                    </View>
                    { showJobCount && <View style={{alignItems: 'center'}}>
                        <Text style={jobCountStyle}>{organization.jobs_count}</Text>
                        <Text style={listElement.description}>Job Openings</Text>
                    </View>}
                </View>
            </TouchableOpacity>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getOrganizationsTab: () => {
            dispatch(organizationsActions.getOrganizationsTab());
        },
        submitInvite: organizationId => {
            dispatch(organizationsActions.submitInvite(organizationId));
        },
        followOrganization: organizationName => {
            dispatch(organizationsActions.followOrganization(organizationName));
        },
        unfollowOrganization: organizationId => {
            dispatch(organizationsActions.unfollowOrganization(organizationId));
        }
    }
};

export default connect(null, mapDispatchToProps)(OrganizationListElement);
