import React from 'react'
import './AnimtedBorder.css'

const AnimatedBorder = ({children, style}) => {
  return (
    <div  id='animatedBorderID' style={style}>{children}</div>
  )
}

export default AnimatedBorder
