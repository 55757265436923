import React, { useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';
import { gapi } from 'gapi-script';
import './GoogleSSOLogin.css'
import googleClientId from '../../services/googleSsoService'

import { enableGoogleSSO } from './actions';

const GoogleSSOLogin = ({ EnableGoogleSSO }) => {
  let clientId = googleClientId
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId,
        scope: '',
      });
    };
    gapi.load('client:auth2', initClient);
  },[]);

  const onSuccess = (res) => {
    const { tokenId,accessToken } = res
    EnableGoogleSSO({tokenId,accessToken});
  };

  const onFailure = (err) => {};

  return (
    <GoogleLogin
      clientId={clientId}
      buttonText=""
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
    />
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  EnableGoogleSSO: (payload) => {
    dispatch(enableGoogleSSO(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(GoogleSSOLogin);
