import { StyleSheet } from 'react-native';
import { $green, $gre, $greish } from '../../../generalStyles';

export default StyleSheet.create({
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        margin: 10
    },
    detailsWrapper: {
        padding: 15,
        borderBottomWidth: 1,
        borderBottomColor: '#e7e7e7',
    },
    description: {
        fontSize: 14
    },
    image: {
        width: 60,
        height: 60,
        marginRight: 12,
        borderRadius: 5,
    },
    fullname: {
        fontSize: 23,
        fontFamily: 'Roboto-Regular'
    },
    message: {
        width: '80%'
    },
    position: {
        marginBottom: 4
    },
    description: {
        fontWeight: 'bold',
        marginBottom: 4
    }

});
