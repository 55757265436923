import React, { Component } from 'react';

import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';


class PublicReferralForm extends Component {

    render() {
        return (
            <>
                <Header />
                <Footer />
            </>
        );
    }
}

export default PublicReferralForm;
