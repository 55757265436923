import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

class ModalDropdown extends React.Component {
  render() {
    let dropdownStyle='modal-style '
    const { options, onSelect, defaultValue, placeholder, stylingClass } = this.props;
    return (
      <Dropdown
        options={options}
        styles={this.props.styles}
        onChange={onSelect}
        value={defaultValue}
        placeholder={placeholder}
        className={stylingClass == "" ? dropdownStyle : dropdownStyle + stylingClass }/>
      );
  }
}

export default ModalDropdown;
