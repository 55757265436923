import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    wrapperStyle: {
        paddingTop: 15,
        flexDirection: 'row'
    },
    labelStyle: {
        fontSize: 10,
        color: $green,
        fontWeight: 'bold',
        paddingHorizontal: 15
    },
    passwordToggleButton: {
        position: 'absolute',
        bottom: 5,
        right: 3
    },
    passwordToggleIcon: {
        height: 40,
        width: 40
    }
});