import * as companiesActionsConstants from "../../constants/actions/organizationsActions";
import * as companiesService from "../../services/organizationsService";
import { notification } from "../../helperFunctions";

export function getEmployer(organizationId) {
  return (dispatch) => {
    dispatch({ type: companiesActionsConstants.GET_ORGANIZATION_DETAILS });
    companiesService
      .getOrganizationDetailsByGeneralSettingId(organizationId)
      .then((response) => {
        if (response.data.payload.message) {
          notification.ref.show({
            message: response.data.payload.message,
            isError: true,
          });
        } else {
          dispatch({
            type: companiesActionsConstants.GET_ORGANIZATION_DETAILS_SUCCESS,
            payload: response.data.payload,
          });
          dispatch(getOrganizationActiveJobs(organizationId));
        }
      })
      .catch(() => {
        dispatch({
          type: companiesActionsConstants.GET_ORGANIZATION_DETAILS_ERROR,
        });
      });
  };
}
