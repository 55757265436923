import React from 'react';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';

const AppPagination = ({ page = 1, count = 0, disabled = false, onChange }) => {
    return (
        <Box sx={{ '& .MuiPagination-ul': { justifyContent: 'center' } }}>
            <Pagination
                count={count}
                page={page}
                disabled={disabled}
                onChange={(event, value) => onChange(value)}
            />
        </Box>
    );
};

export default AppPagination;
