import React, { Component } from 'react';
import {
    View,
    Image,
    ActivityIndicator
} from 'react-native';

import {connect} from 'react-redux';

import Actions from '../../RouteActions';

//components
import FinishOnboardingBase from '../../components/FinishOnboardingBase/FinishOnboardingBase';
import Text from '../../components/Text/Text';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

//actions
import * as networkActions from '../../globalActions/networkActions';

//styles
import {$green, whiteBlock} from '../../generalStyles';
import styles from './styles';

//images
import boonNetworkImage from '../../assets/img/boon_network.png';

import * as actions from '../../globalActions/networkActions';

class YourNetworkInitial extends Component {
    componentDidMount() {
        this.props.getNotConnectedConnections();
    }

    render() {
        const { connections_count, network, networkLoading } = this.props;
        const haveConnections = Boolean(connections_count);
        return (
            <FinishOnboardingBase title="Your Network">
                <View style={styles.childrenWrapper}>
                    {networkLoading && <ActivityIndicator size="large" color={$green}/> ||
                    <View style={whiteBlock}>
                        {haveConnections &&
                        <Text style={styles.pageTitle}>You already have {connections_count} {'\n'} connections in
                            Boon</Text> ||
                        <Text style={styles.pageTitle}>You don't have connections in Boon</Text>
                        }
                        <Image style={styles.image} source={boonNetworkImage}/>
                        <Text style={styles.description}>Invite{haveConnections ? ' more ' : ' '}people to unlock the
                            true power{'\n'} of referrals</Text>
                        <PrimaryButton
                            onPress={() => network.length ? Actions.yourNetworkConnect() : Actions.yourNetworkInvite()}>Let's
                            do it!</PrimaryButton>
                    </View>
                    }
                </View>
            </FinishOnboardingBase>
        );
    }
}

const mapStateToProps = state => {
    return {
        network: state.connectionsReducer.network,
        networkLoading: state.connectionsReducer.networkLoading
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getNotConnectedConnections: () => {
            dispatch(networkActions.getUserConnections({filter_type: 'not_connected_without_contacts'}));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(YourNetworkInitial);