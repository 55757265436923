import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    row: {
        flexDirection: 'row',
        // justifyContent: 'space-around',
        padding: 20
    },
    col: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 15
    },
    headerText: {
        fontWeight: 'bold'
    },
    browseButton: {
        width: 150,
        backgroundColor: '#f7b900',
    },
    centered: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    select: {
        paddingLeft: 32,
        paddingTop: 20,
        paddingRight: 20
    }
});
