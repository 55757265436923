import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    elementWrapper: {
        backgroundColor: $green,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    boonLogo: {
        position: 'absolute',
        width: 100,
        height: 41,
        top: 0,
        left: 0,
        marginTop: 30,
        marginLeft: 40
    },
    contentCont: {
        backgroundColor: 'white',
        borderRadius: 4,
        paddingTop: 60,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10,
        width: 375
    },
    heading: {
        fontWeight: 600,
        fontSize: 20,
        color: '#0FBC71',
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: 'center'
    },
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        fontWeight: 400,
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green,
        marginTop: 20
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2',
        marginTop: 20
    },
    backButton: {
        fontSize: 14,
        fontWeight: 600,
        textDecorationLine: 'underline',
        color: '#808080',
        marginTop: 20
    },
    magicLink: {
        fontSize: 14,
        fontWeight: 600,
        textDecorationLine: 'underline',
        color: 'white',
        marginTop: 20,
        textAlign: 'center'
    },
    inputWrapper: {
        marginTop: 20
    },
    inputError: {
        borderWidth: 2,
        borderBottomWidth: 2,
        borderColor: '#EA5506',
        borderBottomColor: '#EA5506'
    },
    errorMessageCont: {
        width: '100%'
    },
    errorMessage: {
        fontSize: 12,
        color: '#EA5506',
        textAlign: 'left',
        marginLeft: 13,
        marginTop: 5
    },
    magicHeading: {
        fontWeight: 700,
        fontSize: 55,
        color: 'white'
    },
    magicText: {
        fontSize: 32,
        color: 'white'
    },
    email: {
        fontWeight: 600
    },
    magicContWeb: {
        width: 400
    },
    magicContMobile: {
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15
    },
    backToLoginCont: {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
    },
    backToLogin: {
        backgroundColor: 'white',
        width: 250,
        borderRadius: 4,
        marginTop: 30
    },
    backToLoginText: {
        fontSize: 14,
        fontWeight: 500,
        textAlign: 'center',
        color: '#0FBC71',
        marginTop: 15,
        marginBottom: 15
    },
    mobileBoonLogo: {
        position: 'absolute',
        width: 100,
        height: 41,
        top: 0,
        marginTop: 30
    }
});
