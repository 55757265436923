import React, { Component } from "react";
import Alert from "../../wrappers/Alert/Alert";
import {
  FlatList,
  Image,
  TouchableOpacity,
  View,
  Platform,
  ScrollView,
} from "react-native";
import Actions from "../../RouteActions";
import { connect } from "react-redux";
import { isEmpty } from "lodash-es";
import { withGetScreen } from "react-native-getscreen";

//components
import Text from "../../components/Text/Text";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import ModalDropdown from "../../wrappers/modalDropdown/modalDropdown";

//styles
import styles from "./styles";

//images
import orgDefaultAvatar from "../../assets/img/default_org_avatar.png";

//actions
import * as actions from "./actions";
import * as organizationsActions from "../../globalActions/organizationsActions";
import { find } from "lodash-es";
import userRoles from "../../constants/userRoles";
import OrganizationListElement from "../../components/OrganizationListElement/OrganizationListElement";
import GlobalSearch from "../../screens/GlobalSearch/GlobalSearch";
import OrganizationDetail from "./OrganizationDetail";
import * as orgProfileAction from "../../screens/OrganizationProfile/actions";
import responsiveStyles from "../../responsiveStyles";
import { getOrganizationsBySort } from "./actions";

class FollowCompaniesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      organizations: [],
      dropdownOption: "Sort by",
    };

    this.scrollRef = null;
    this.handleFollowingPress = this.handleFollowingPress.bind(this);
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { getAllOrganization, origin, getOrganizationsTab } = this.props;
    if (origin === "my") {
      getOrganizationsTab();
    } else if (origin === "browse") {
      getAllOrganization();
    }
  }

  componentWillReceiveProps(newProps) {
    const { origin } = newProps;
    if (origin === "my") {
      this.setState({ organizations: newProps.organizationsTab });
    } else if (origin === "browse") {
      this.setState({ organizations: newProps.followOrganizations });
    }
  }

  handleFollowingPress(organization) {
    const { unfollowOrganization } = this.props;
    Alert.alert(
      `Unfollow ${organization.name}`,
      "You will no longer see updates about them. You can follow them back later.",
      [
        { text: "Cancel", style: "cancel" },
        {
          text: "Unfollow",
          onPress: () => unfollowOrganization(organization.id),
        },
      ]
    );
  }

  onSortChange = (value) => {
    this.setState({ dropdownOption: value });
  };

  renderItem({ item }) {
    const { followOrganization, submitInvite } = this.props;
    const image = item.logo_image_url
      ? { uri: item.logo_image_url }
      : orgDefaultAvatar;
    const role = find(userRoles, { value: item.role });

    return (
      <TouchableOpacity
        key={item.id}
        style={styles.organizationWrapper}
        onPress={() =>
          Actions.goEmployerProfile({
            employerProfileLink: item.employer_profile_link,
          })
        }
      >
        <Image source={image} style={styles.organizationImage} />
        <Text style={styles.organizationName}>{item.name}</Text>
        <Text style={styles.organizationJobs}>{item.jobs_count} jobs</Text>
        {item.is_following && item.role === null && (
          <PrimaryButton
            disabled
            onPress={() => this.handleFollowingPress(item)}
            small
            style={styles.button}
          >
            Unfollow
          </PrimaryButton>
        )}
        {!item.is_following && item.role === null && (
          <PrimaryButton
            onPress={() => followOrganization(item.name)}
            small
            style={styles.button}
          >
            Follow
          </PrimaryButton>
        )}
        {item.status === 0 && (
          <PrimaryButton
            onPress={() => submitInvite(item.id)}
            small
            style={styles.button}
          >
            Accept invite
          </PrimaryButton>
        )}
        {item.status === 1 && (
          <PrimaryButton disabled small style={styles.button}>
            {role.label}
          </PrimaryButton>
        )}
      </TouchableOpacity>
    );
  }

  orgElement({ item }) {
    const { origin, params } = this.props;
    const { organizations } = this.state;
    const currentOrgId = isEmpty(params)
      ? organizations[0].id
      : params.organizationId;
    return (
      <OrganizationListElement
        organization={item}
        showJobCount={origin === "browse"}
        currentOrgId={currentOrgId}
        onPress={() => {
          if (this.scrollRef)
            this.scrollRef.scrollTo({ x: 0, y: 0, animated: true });
          this.props.getOrganizationDetails(item.id);
          if (origin === "browse") {
            Actions.goEmployerProfile({
              employerProfileLink: item.employer_profile_link,
            });
          } else {
            Actions.myOrganizationDetail({ organizationId: item.id });
          }
        }}
      />
    );
  }

  filterActionButtons() {
    const filterButtonStyle = {
      borderRadius: 3,
      height: 40,
      width: 80,
      backgroundColor: "#FFB32F",
    };

    return (
      <View
        style={{ flexDirection: "row", marginBottom: 10, marginVertical: 5 }}
      >
        <ModalDropdown
          onSelect={(selectedOption, val) => {
            let defaultValue =
              typeof selectedOption == "object" ? selectedOption.value : val;
            this.onSortChange(defaultValue);
            switch (defaultValue) {
              case "Size":
                this.props.getOrganizationsBySort(
                  "size",
                  this.props.queryString
                );
                break;
              case "Location":
                this.props.getOrganizationsBySort(
                  "location",
                  this.props.queryString
                );
                break;
              case "Number of Open Jobs":
                this.props.getOrganizationsBySort(
                  "open_jobs",
                  this.props.queryString
                );
                break;
            }
          }}
          defaultValue={this.props.sortDefaultValue}
          placeholder="Sort By"
          options={["Size", "Location", "Number of Open Jobs"]}
          stylingClass="modal-width"
        ></ModalDropdown>
      </View>
    );
  }

  render() {
    const { followOrganizationsLoading, wrapperStyles, origin, params } =
      this.props;
    const { organizations } = this.state;
    const web = Platform.OS === "web";
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    return (
      <ScrollView
        ref={(el) => (this.scrollRef = el)}
        style={[web && { maxHeight: "calc(100vh - 83px)" }]}
      >
        <View
          style={[
            { marginTop: 20 },
            responsiveStyles.flexRowReverse,
            responsiveStyles.flexWrap,
            responsiveStyles.justifyCenter,
          ]}
        >
          {(!tabScreen || (tabScreen && params.organizationId)) && (
            <View style={[styles.gridHalf, responsiveStyles.flexColumn]}>
              {organizations.length > 0 && (
                <OrganizationDetail
                  sortDefaultValue={this.state.dropdownOption}
                  onSortChange={this.onSortChange}
                  firstOrganization={organizations[0]}
                  showFilters={origin === "browse"}
                />
              )}
            </View>
          )}
          {(!tabScreen ||
            (tabScreen && params.organizationId === undefined)) && (
            <View style={[styles.gridHalf, tabScreen && { width: "100%" }]}>
              <View
                style={[styles.row, { justifyContent: "flex-end", zIndex: 10 }]}
              >
                {this.filterActionButtons()}
              </View>
              <GlobalSearch
                searchModel="organizations"
                placeholder="Search Company"
              />
              <FlatList
                contentContainerStyle={wrapperStyles}
                onRefresh={() => this.getData()}
                refreshing={followOrganizationsLoading}
                data={organizations}
                renderItem={(item) => this.orgElement(item)}
                keyExtractor={(item) => `${item.id}`}
                showsHorizontalScrollIndicator={false}
              />
            </View>
          )}
        </View>
      </ScrollView>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    followOrganizationsLoading:
      state.organizationsReducer.followOrganizationsLoading,
    followOrganizations: state.organizationsReducer.followOrganizations,
    organizationsTab: state.organizationsReducer.organizationsTab,
    organizationsTabLoading: state.organizationsReducer.organizationsTabLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOrganization: () => {
      dispatch(actions.getAllOrganization());
    },
    getOrganizationsTab: () => {
      dispatch(organizationsActions.getOrganizationsTab());
    },
    submitInvite: (organizationId) => {
      dispatch(organizationsActions.submitInvite(organizationId));
    },
    followOrganization: (organizationName) => {
      dispatch(organizationsActions.followOrganization(organizationName));
    },
    unfollowOrganization: (organizationId) => {
      dispatch(organizationsActions.unfollowOrganization(organizationId));
    },
    getOrganizationDetails: (id) => {
      dispatch(orgProfileAction.getOrganizationDetails(id));
      dispatch(orgProfileAction.getOrganizationActiveJobs(id));
    },
    getOrganizationsBySort: (sortBy, queryString) => {
      if (queryString && queryString.length > 0) {
        dispatch(
          getSuggestions(
            queryString,
            "globalSearch",
            "results",
            null,
            null,
            "globalSearch",
            "organizations",
            sortBy
          )
        );
      } else {
        dispatch(getOrganizationsBySort(sortBy));
      }
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withGetScreen(FollowCompaniesComponent));
