import { View, Platform, Image, Dimensions } from 'react-native';
import React, { Component } from 'react';
import { connect } from 'react-redux';

//components
import Text from '../../../../components/Text/Text';
import defaultUserAvatar from '../../../../assets/img/default_user_avatar.png';
import defaultCoverImage from '../../../../assets/img/demo_cover_image.png';

// Styles
import styles from './styles';


class UserProfileSection extends Component {
    render() {
        const { currentUser } = this.props;
        const avatar = currentUser.avatar_thumb ? { uri: currentUser.avatar_thumb } : defaultUserAvatar;
        const cover_image = currentUser.cover_image ? { uri: currentUser.cover_image } : defaultCoverImage;
        let address = '';
        if (currentUser.state == null && currentUser.city == null) {
            address = `${currentUser.country_iso_code}`
        } else if (currentUser.city == null) {
            address = `${currentUser.state}, ${currentUser.country_iso_code}`
        } else if (currentUser.state == null) {
            address = `${currentUser.city}, ${currentUser.country_iso_code}`
        } else {
            address = `${currentUser.city}, ${currentUser.state}, ${currentUser.country_iso_code}`
        }
        let isMobile = Math.round(Dimensions.get('window').width) < 750

        return (
            <View style={isMobile ? {display: 'none'} : styles.profileWrapper}>
                <View style={styles.userInfoWrapper}>
                    <View style={styles.coverWrapper}>
                        <Image source={cover_image} style={Platform.OS !== 'web' ? styles.userCoverMobile : styles.userCover} />
                    </View>
                    <View style={styles.infoWrapper}>
                        <Image source={avatar} style={Platform.OS !== 'web' ? styles.avatarMobile : styles.avatar} />
                        <View style={styles.nameWrapper}>
                            <Text style={Platform.OS !== 'web' ? styles.nameMobile : styles.name}>{currentUser.first_name} {currentUser.last_name}</Text>
                            {currentUser.workplace &&
                                <Text style={styles.organization}>{currentUser.workplace.name}</Text>
                            }
                            <Text style={styles.position}>{address}</Text>
                        </View>
                    </View>
                    <View style={styles.statsWrapper}>
                        <View style={styles.statContainer}>
                            <Text style={styles.count}>{currentUser.user_referrals_sent_count || 0}</Text>
                            <Text style={styles.description}>Referrals Sent</Text>
                        </View>
                        <View style={styles.statContainer}>
                            <Text style={styles.count}>{currentUser.user_referrals_received_count || 0}</Text>
                            <Text style={styles.description}>Referrals Received</Text>
                        </View>
                        <View style={styles.statContainer}>
                            <Text style={styles.count}>{currentUser.user_connections_count || 0}</Text>
                            <Text style={styles.description}>People in Network</Text>
                        </View>
                        {/* <View style={styles.statContainer}>
                            <Text style={styles.count}>{currentUser.user_companies_count || 0}</Text>
                            <Text style={styles.description}>Companies</Text>
                        </View> */}
                    </View>
                </View>
                {/* <View style={styles.profile}>
                    <UserProfile
                        selectedUser={currentUser}
                        userFormParams={getUserFormParams(currentUser)}
                    />
                </View> */}
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.authReducer.currentUser,
    }
};

const mapDispatchToProps = dispatch => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileSection);
