import React, { useState } from 'react';

//styles
import styles from './styles';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';  
import archivedStatuses from '../../constants/archivedStatuses';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

const useStyles = makeStyles((theme) => ({
  menuItemRoot: {
    padding: 0,
  },
  customIconButton: {
    padding: 0,
    '@media (max-width: 769px)': {
      top: '-60px',
      position: "absolute",
      right: 0 
    }
  }
}));

export default function DeclineButton(props) {
  const {
    handleOnPress,
    firstReferral,
  } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ root: classes.customIconButton }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: 40,
            marginLeft: -20,
          },
        }}
        MenuListProps={{ style: { padding: 0 } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem 
        classes={{ root: classes.menuItemRoot }}
        key="copy-link">
          <PrimaryButton
            style={styles.declineButton}
            white
            small
            onPress={() => handleOnPress(archivedStatuses, firstReferral, handleClose)}
            disabled={archivedStatuses.indexOf(firstReferral.candidate_status) !== -1}
          >
            Decline
          </PrimaryButton>
        </MenuItem>
      </Menu>
    </>
  );
}
