import EStyleSheet from 'react-native-extended-stylesheet';
import { $navyBlue, $green } from '../../generalStyles';

export default EStyleSheet.create({
    elementWrapperMobile: {
        height: '100%',
        width: '100%',
        flex: 1,
        backgroundColor: 'white',
    },
    elementWrapper: {
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
    },
    headerBar: {
        width: '100%',
        borderBottomWidth: 1,
        borderBottomColor: '#F2F2F2',
        padding: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      headerText: {
        color: '#9B9B9B',
        fontSize: 14,
        fontWeight: 500,
      },
      orgLogo: {
        height: 30,
        width: 30,
        marginRight: 13,
      },
      companyInitials: {
        backgroundColor: '#F9B74E',
        color: 'white',
        height: 30,
        width: 30,
        borderRadius: 2,
        marginRight: 13,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      },
    logoWrapper: {
        paddingVertical: 50,
        paddingHorizontal: 60
    },
    logoWrapperMobile: {
        paddingVertical: 20,
        alignItems: 'center'
    },
    logo: {
        width: 94,
        height: 41,
        resizeMode: 'contain'
    },
    logoMobile: {
        width: 100,
        height: 80,
        resizeMode: 'contain'
    },
    container: {
        margin: 0,
        position: 'absolute',
        top: '30%',
        backgroundColor: 'transparent',
        width: '30%',
        alignSelf: 'center'
    },
    containerMobile: {
        margin: 0,
        position: 'absolute',
        top: '30%',
        backgroundColor: 'transparent',
        width: '80%',
        alignSelf: 'center'
    },
    textContainer: {
        margin: 1
    },
    body: {
        backgroundColor: '#fff',
        padding: 20,
        maxWidth: '80%',
        width: '60%',
        minWidth: 350,
        borderRadius: 4,
        marginBottom: 20,
    },
    bodyMobile: {
        zIndex: 10,
        backgroundColor: '#fff',
        margin: 25,
        padding: 20,
        width: '25%',
        minWidth: 300,
        borderRadius: 4,
        marginBottom: 20
    },
    buttonWrapper: {
        textAlign: 'center',
        marginTop: 12,
        alignItems: 'center',
        paddingVertical: 18,
        backgroundColor: '#fff',
        borderRadius: 5,
        marginLeft: 20
    },
    buttonNeverMindWrapper: {
        textAlign: 'center',
        marginTop: 10,
        alignItems: 'center',
        paddingVertical: 18,
        borderColor: '#fff',
        borderWidth:2,
        borderRadius: 5,
    },
    buttonNeverMindWrapperMobile: {
        textAlign: 'center',
        marginTop: 10,
        alignItems: 'center',
        paddingVertical: 18,
        borderColor: '#fff',
        borderWidth:2,
        width: '100%',
        borderRadius: 5,
        marginRight:5,
    },
    buttonWrapperMobile: {
        textAlign: 'center',
        marginTop: 12,
        alignItems: 'center',
        paddingVertical: 18,
        backgroundColor: '#fff',
        width: '100%',
        borderRadius: 5
    },
    mainText: {
        fontFamily: 'Roboto-Regular',
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: 55,
        alignSelf: 'flex-start',
        marginBottom: 10,
        fontWeight: 'bold',
        lineHeight: 80
    },
    secondaryText: {
        fontFamily: 'Roboto-Regular',
        color: '#fff',
        fontSize: 35,
        marginBottom: 20
    },
    mainTextMobile: {
        fontFamily: 'Roboto-Regular',
        backgroundColor: 'transparent',
        color: '#fff',
        fontSize: 40,
        alignSelf: 'flex-start',
        marginBottom: 10,
        fontWeight: 'bold',
        lineHeight: 50
    },
    secondaryTextMobile: {
        fontFamily: 'Roboto-Regular',
        color: '#fff',
        fontSize: 15,
        marginBottom: 20
    },
    formText: {
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 14,
    },
    formTextHeader: {
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 25,
    },
    inviteText: {
        fontSize: 32,
        fontFamily: 'Roboto-Regular',
        color: '#444444',
        marginBottom: 20,
    },

    formTextMobile: {
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 10,
    },
    formTextHeaderMobile: {
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 15,
    },
    inviteTextMobile: {
        fontSize: 25,
        fontFamily: 'Roboto-Regular',
        color: '#444444',
        marginBottom: 20,
    },
    checkBoxText: {
        paddingTop: 3,
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 11,
    },
    checkBoxTextMobile: {
        paddingTop: 3,
        fontFamily: 'Roboto-Regular',
        color: $navyBlue,
        fontSize: 9,
    },
    ackTextContainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: $green,
        minWidth: 330,
        marginBottom: 15
    },
    ackText: {
        fontSize: 10,
        fontFamily: 'Roboto-Regular',
        color: '#fff',
        width: '30%',
        textAlign: 'center'
    },
    ackTextContainerMobile: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: $green,
        minWidth: 250,
        marginBottom: 15
    },
    ackTextMobile: {
        fontSize: 9,
        fontFamily: 'Roboto-Regular',
        color: '#fff',
        width: '80%',
        textAlign: 'center'
    },
    orgImage: {
        height: 55,
        width: 55,
        borderColor: $navyBlue,
        borderWidth: 2,
        borderRadius: 25
    },
    orgImageMobile: {
        height: 50,
        width: 50,
        borderColor: $navyBlue,
        borderWidth: 2,
        borderRadius: 25,
    },
    acceptText: {
        backgroundColor: $green,
        color: 'white',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        borderRadius: 4,
        width: 193,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptNeverMindText: {
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        textAlign: 'right',
        color: '#959595',
        borderWidth: 1,
        borderColor: '#959595',
        borderRadius: 4,
        width: 148,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptNeverMindTextMobile: {
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        textAlign: 'right',
        color: '#959595',
        borderWidth: 1,
        borderColor: '#959595',
        borderRadius: 4,
        width: 148,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    acceptTextMobile: {
        backgroundColor: $green,
        color: 'white',
        fontFamily: 'Roboto-Regular',
        fontSize: 12,
        fontWeight: 'bold',
        textAlign: 'right',
        borderRadius: 4,
        width: 193,
        height: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    boldText: {
        fontWeight: 'bold'
    },
    actionButtonContainer: {
        marginTop: 20,
    },
    actionButtonNewMindContainer: {
        display:'flex!important',
        flexDirection: 'row',
        marginRight: 12,

    },
    actionButtonNewMindContainerMobile: {
        display:'flex!important',
        flexDirection: 'column',
        marginRight: 12,
    },
    gainAccessText: {
        fontSize: 16,
        color: 'white',
        margin: 2
    },
})
