import * as stepsActionsConstants from '../constants/actions/stepsActions';

const initialState = {
    step: ''
};

const stepsReducer = (state = initialState, action) => {
    switch (action.type) {
        case stepsActionsConstants.GET_STEP:
            return {
                ...state,
                step: ''
            };
        case stepsActionsConstants.GET_STEP_SUCCESS:
            return {
                ...state,
                step: action.payload
            };
        default:
            return state;
    }
};

export default stepsReducer;
