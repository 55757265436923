import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

//styles
import styles from './styles';

const SignUpButton = ({ disabled = false, children, onClick }) => {
    const buttonStyles = [
        styles.buttonStyle,
        disabled ? styles.disabled : styles.active
    ];

    const textStyles = [
        styles.textStyle,
        disabled ? styles.textForDisabled : styles.textForActive
    ];

    return (
        <View style={buttonStyles}>
            <TouchableOpacity
                onPress={onClick}
                disabled={disabled}
                style={styles.opacityWidth}
            >
                <Text style={textStyles}>{children}</Text>
            </TouchableOpacity>
        </View>
    );
};

export default SignUpButton;
