import EStyleSheet from 'react-native-extended-stylesheet';
import { $green } from '../../generalStyles';

export default EStyleSheet.create({
    elementWrapperMobile: {
        backgroundColor: $green,
        height: '100%',
        width: '100%',
        flex: 1,
        resizeMode: 'cover'
    },
    elementWrapper: {
        backgroundColor: $green,
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    contentCont: {
        backgroundColor: 'white',
        borderRadius: 4,
        paddingTop: 60,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 10,
        marginRight: 10
    },
    heading: {
        fontWeight: 600,
        fontSize: 22,
        color: '#0FBC71',
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: 'center'
    },
    subHeading: {
        fontSize: 18,
        color: 'rgba(0, 0, 0, 0.6)',
        marginTop: 15,
        paddingLeft: 25,
        paddingRight: 25,
        textAlign: 'center',
        letterSpacing: 0.15,
        lineHeight: 24,
        maxWidth: 350
    },
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF',
        fontWeight: 400
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    inputWrapper: {
        marginTop: 20
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green,
        marginTop: 20
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2',
        marginTop: 20
    },
    backButton: {
        fontSize: 14,
        fontWeight: 600,
        textDecorationLine: 'underline',
        color: '#808080',
        marginTop: 20
    },
    boonLogo: {
        position: 'absolute',
        width: 100,
        height: 41,
        top: 0,
        left: 0,
        marginTop: 30,
        marginLeft: 40
    }
});
