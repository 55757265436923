import * as trackerActionsConstants from '../constants/actions/trackerActions';

const initialState = {
  newJobs: [],
  newList: [],
  newListLoading: false,
  newListIsLastPage: false,
  appliedJobs: [],
  appliedList: [],
  appliedListLoading: false,
  appliedIsLastPage: false,
  interviewJobs: [],
  interviewList: [],
  interviewListLoading: false,
  interviewIsLastPage: false,
  archivedJobs: [],
  archivedList: [],
  archivedListLoading: false,
  archivedIsLastPage: false,
  opportunityDetails: {},
  strengthenQuestionsData: {},
  candidateStrengthenQuestionsData: {},
  candidateLinkedinData: {},
  opportunityDetailsLoading: false,
  currentTrackerTab: 0,
  newRefersCount: 0,
  interviewStages: [],
  skills: [],
  candidateId: {},
  applyJobLoading: false,
};

const trackerReducer = (state = initialState, action) => {
  let list = [],
    jobs = [];
  switch (action.type) {
    case trackerActionsConstants.GET_JOBS_TRACKER:
      return {
        ...state,
        [`${action.payload.status}ListLoading`]: !action.payload.isPagination,
      };
    case trackerActionsConstants.GET_JOBS_TRACKER_SUCCESS:
      jobs = action.payload.isPagination
        ? _.unionBy(state[`${action.payload.status}Jobs`], action.payload.jobs, 'id')
        : action.payload.jobs;

      if (action.payload.direction === 'their') {
        jobs.forEach((job) => {
          job.referrals.forEach((referral) => {
            list.push({ ...job, referrals: [referral] });
          });
        });
      } else {
        list = jobs;
      }

      return {
        ...state,
        [`${action.payload.status}Jobs`]: jobs,
        [`${action.payload.status}List`]: list,
        [`${action.payload.status}ListLoading`]: false,
        [`${action.payload.status}IsLastPage`]: jobs.length < 20,
      };
    case trackerActionsConstants.GET_JOBS_TRACKER_ERROR:
      return {
        ...state,
        [`${action.payload.status}ListLoading`]: false,
      };
    // case trackerActionsConstants.SET_SEEN:
    //     list = state[action.payload].slice();
    //
    //     list.forEach(newObj => {
    //         newObj.referrals.forEach(referralObj => {
    //             referralObj.seen = true;
    //         });
    //     });
    //     return {
    //         ...state,
    //         [action.payload]: list
    //     };
    case trackerActionsConstants.CLEAR_JOBS_TRACKER:
      return {
        ...state,
        newList: [],
        appliedList: [],
        interviewList: [],
        archivedList: [],
      };
    case trackerActionsConstants.GET_OPPORTUNITY_DETAILS:
      return {
        ...state,
        opportunityDetails: {},
        opportunityDetailsLoading: true,
      };
    case trackerActionsConstants.GET_OPPORTUNITY_DETAILS_SUCCESS:
      return {
        ...state,
        opportunityDetails: action.payload.job,
        opportunityDetailsLoading: false,
      };
    case trackerActionsConstants.GET_OPPORTUNITY_DETAILS_ERROR:
      return {
        ...state,
        opportunityDetailsLoading: false,
      };
    case trackerActionsConstants.GET_STRENGTH_QUESTIONS:
      return {
        ...state,
        strengthenQuestionsData: {},
        opportunityDetailsLoading: true,
      };
    case trackerActionsConstants.GET_STRENGTH_QUESTIONS_SUCCESS:
      return {
        ...state,
        strengthenQuestionsData: action.payload,
        opportunityDetailsLoading: false,
      };
    case trackerActionsConstants.GET_CANDIDATE_STRENGTH_QUESTIONS_SUCCESS:
      return {
        ...state,
        candidateStrengthenQuestionsData: action.payload.candidate,
      };
    case trackerActionsConstants.GET_STRENGTH_QUESTIONS_ERROR:
      return {
        ...state,
        opportunityDetailsLoading: false,
      };
    case trackerActionsConstants.CLEAR_OPPORTUNITY_DETAILS:
      return {
        ...state,
        opportunityDetails: {},
        opportunityDetailsLoading: false,
      };
    case trackerActionsConstants.SET_CURRENT_TRACKER_TAB:
      return {
        ...state,
        currentTrackerTab: action.payload,
      };
    case trackerActionsConstants.GET_NEW_REFERS_COUNT_SUCCESS:
      return {
        ...state,
        newRefersCount: action.payload,
      };
    case trackerActionsConstants.SET_FILTERED_TRACKER_DATA:
      return {
        ...state,
        newList: action.payload.newList,
        appliedList: action.payload.appliedList,
        interviewList: action.payload.interviewList,
        archivedList: action.payload.archivedList,
      };
    case trackerActionsConstants.GET_INTERVIEW_STAGES:
      return {
        ...state,
        interviewStages: [],
      };
    case trackerActionsConstants.GET_INTERVIEW_STAGES_SUCCESS:
      return {
        ...state,
        interviewStages: action.payload,
      };
    case trackerActionsConstants.GET_INTERVIEW_STAGES_ERROR:
      return {
        ...state,
        interviewStages: action.payload,
      };

    case trackerActionsConstants.GET_SKILLS_SUCCESS:
      return {
        ...state,
        skills: action.payload,
      };
    case trackerActionsConstants.GET_CANDIDATE_ID:
      return {
        ...state,
        candidateId: action.payload,
      };
    case trackerActionsConstants.APPLY_JOB:
      return {
        ...state,
        applyJobLoading: true,
      };
    case trackerActionsConstants.APPLY_JOB_SUCCESS:
      return {
        ...state,
        candidateLinkedinData: action.payload.linkedin_data,
        applyJobLoading: false,
      };
    case trackerActionsConstants.APPLY_JOB_ERROR:
      return {
        ...state,
        applyJobLoading: false,
      };
    default:
      return state;
  }
};

export default trackerReducer;
