import React, { useEffect } from 'react';
import { View, Image, ScrollView, Text } from 'react-native';
import Actions from '../../RouteActions';
import GlobalGradient from '../../components/GlobalGradient/GlobalGradient';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styles from '../SignUp/styles';
import ownStyles from '../PostSignin/styles';
import { connect } from 'react-redux';
import logo from '../../assets/img/logo.png';
import Spinner from 'react-native-loading-spinner-overlay';
import qs from 'qs';
import { enableLinkedInSSO } from './action';

const token = qs.parse(window.location.search)['?code'];

const LinkedInCallback = ({ enableLinkednSSO, mobile }) => {
    useEffect(() => {
        if (token) {
            enableLinkednSSO(token);
        } else {
            Actions.signInEmail();
        }
    }, [token]);
    return (
        <ScrollView>
            <GlobalGradient gradients={['#32CF6C', '#2AAA89']} angle="90deg">
                <KeyboardAwareScrollView
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={[styles.pageWrapper]}
                    enableAutomaticScroll
                >
                    <View style={[styles.body]}>
                        <Image
                            source={logo}
                            style={mobile ? styles.mobileLogo : ownStyles.logo}
                        />
                        <Text style={styles.authenticateText}>
                            Authenticating ...
                        </Text>
                    </View>
                    <Spinner visible={true} />
                </KeyboardAwareScrollView>
            </GlobalGradient>
        </ScrollView>
    );
};

const mapDispatchToProps = (dispatch) => ({
    enableLinkednSSO: (token) => {
        dispatch(enableLinkedInSSO(token));
    }
});

export default connect(null, mapDispatchToProps)(LinkedInCallback);
