import { StyleSheet } from 'react-native';

import { $green } from '../../../generalStyles';

export default StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: 50
    },
    image: {
        height: 15,
        width: 15,
        marginTop: 6
    },
    text: {
        color: '#AEAEAE',
        fontSize: 12,
        marginTop: 2
    },
    textSelected: {
        color: $green
    }
});
