import { StyleSheet, PixelRatio } from 'react-native';

export default StyleSheet.create({
    wide800: {
        width: 800 / PixelRatio.get()
    },
    wide1200: {
        width: 1200 / PixelRatio.get()
    },
    wide1000: {
        width: 1000 / PixelRatio.get()
    },
    wide1200: {
        width: 1200 / PixelRatio.get()
    },
    height100: {
        height: '100%'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexRowReverse: {
        display: 'flex',
        flexDirection: 'row-reverse'
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    justifySpaceBetween: {
        justifyContent: 'space-between'
    },
    justifyFlexStart: {
        justifyContent: 'flex-start'
    },
    mb20: {
        marginBottom: 20 / PixelRatio.get()
    }
});
