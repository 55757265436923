import { StyleSheet } from 'react-native';
import { $green } from '../../generalStyles';

export default StyleSheet.create({
    formWrapper: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        margin: 'auto',
        paddingHorizontal: 20,
        paddingTop: 50,
        paddingBottom: 25,
        position: 'relative'
    },
    formWrapperToolTip: {
        borderRadius: 4,
        backgroundColor: '#FFFFFF',
        width: 375,
        height: 221,
        margin: 'auto',
        paddingHorizontal: 20,
        paddingVertical: 50,
        position: 'relative'
    },
    header: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerText: {
        color: '#0FBC71',
        fontSize: 20,
        fontWeight: 500,
        paddingBottom: 20,
    },
    formFieldWrapper: {},
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF'
    },
    inputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: '#CCCCCC',
        paddingTop: 16,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 12
    },
    iconButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 36,
        height: 36,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.1)'
    },
    iconImg: {
        width: 16,
        height: 16
    },
    checkboxWrapper: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 41,
        marginBottom: 19,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 280
    },
    checkbox: {
        borderRadius: 4
    },
    button: {
        borderWidth: 0,
        backgroundColor: $green
    },
    disabledButton: {
        borderWidth: 0,
        backgroundColor: '#F2F2F2'
    },
    buttonText: {
        color: '#FFFFFF',
        fontSize: 12
    },
    disabledButtonText: {
        color: '#808080',
        fontSize: 12
    },
    termAndConditionsParent: {
        marginLeft: 12,
        flexDirection: 'row'
    },
    termAndConditionsChild: {
        color: '#666666',
        fontSize: 12,
        width: 158,
        lineHeight: '18px'
    },
    termAndConditionsText: {
        textDecorationLine: 'underline',
        fontWeight: 'bold'
    },
    tooltip: {
        width: 20,
        height: 20,
        position: 'absolute',
        marginBottom: 50,
        position: 'absolute',
        top: '5.72%',
        right: '5.6%'
    },
    signUpButton: {
        marginTop: 20,
        marginBottom: 32
    },
    centerAlign: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    descriptionWrapper: {
        textAlign: 'center',
        color: '#fff',
        opacity: 0.6,
    },
    descriptionText: {
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '24px',
        paddingTop: 30,
        paddingHorizontal: 18,
    },
    unsetPosition: {
        position: 'unset'
    }
});
