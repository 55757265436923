import Actions from '../../RouteActions';

import * as authActionsConstants from '../../constants/actions/auth';
import * as appActionsConstants from '../../constants/actions/appActions';
import * as referActionsConstants from '../../constants/actions/referActions';
import * as refersService from '../../services/refersService';
import { notification } from '../../helperFunctions';

export function getData(token, userExist) {
    return dispatch => {
        dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
        refersService.getReferData(token).then((response) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            if (userExist) {
                Actions.browseJobDetail({ id: response.data.payload.job.id })
            }
            if(!response.data.payload.refer_request.public && response.data.payload.refer_request.status !== 0) {
                notification.ref.show({ message: 'This referral link is expired.', isError: true });
                Actions.home();
            }else {
                dispatch({ type: referActionsConstants.GET_REFER_JOB_SUCCESS, payload: response.data.payload });
            }
        }).catch((e) => {
            dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
            dispatch({ type: referActionsConstants.GET_REFER_JOB_ERROR, payload: e.data.error });
            notification.ref.show({ message: e.data.error, isError: true });
        });
    };
}
