// Library Imports
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { isEmpty } from 'lodash-es';

// Icons/Assets
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import LinkedInLogo from '@material-ui/icons/LinkedIn';
import FacebookLogo from '@material-ui/icons/Facebook';
import WhatsAppLogo from '@material-ui/icons/WhatsApp';
import TwitterLogo from '@material-ui/icons/Twitter';
import { ReactComponent as ShareIcon } from '../../../../assets/svg/icon-share.svg';


// Components
import Text from '../../../../components/Text/Text';
import ListElementButton from '../../../../components/ListElementButton/ListElementButton';

// Services/Helpers
import { getReferralLink } from '../../../../services/refersService';
import { notification } from '../../../../helperFunctions';

const referButtonStyle = {
  borderRadius: 6,
  borderColor: '#D0D5DD',
  height: 40,
  width: 40,
  marginLeft: 10,
};

function ShareMenu({
  jobId,
  currentUser,
  getSocialShareLink,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isEmptyCurrentUser = isEmpty(currentUser);

  const handleCopy = () => {
    if (jobId) {
      getReferralLink({ id: jobId, referral_type: 'job' }).then((res) => {
        if (res && res.data && res.data.payload) {
          setTimeout(async () => {
            await navigator.clipboard.writeText(res.data.payload).then(
              () => {
                notification.ref.show({ message: 'Link copied.', isError: false });
              },
              () => {
                notification.ref.show({ message: 'Could not copy link.', isError: true });
              }
            );
          });
        }
        handleClose();
      });
    } else {
      handleClose();
    }
  };

  const items = useMemo(
    () => [
      {
        title: 'Copy Link',
        icon: InsertLinkIcon,
        action: handleCopy,
        type: 'copyLink',
      },
      {
        title: 'Share on LinkedIn',
        icon: LinkedInLogo,
        action: () => getSocialShareLink('linkedin'),
        type: 'linkedin',
      },
      {
        title: 'Share on Facebook',
        icon: FacebookLogo,
        action: () => getSocialShareLink('facebook'),
        type: 'facebook',
      },
      {
        title: 'Share on X/Twitter',
        icon: TwitterLogo,
        action: () => getSocialShareLink('twitter'),
        type: 'twitter',
      },
      {
        title: 'Share on WhatsApp',
        icon: WhatsAppLogo,
        action: () => getSocialShareLink('whatsapp'),
        type: 'whatsapp',
      },
    ],
    [jobId]
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    !isEmptyCurrentUser && (
      <div>
        <View aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
          <ListElementButton style={referButtonStyle}>
            <ShareIcon />
          </ListElementButton>
        </View>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              marginTop: 50,
            },
          }}
          MenuListProps={{ style: { padding: 0 } }}
        >
          {items.map(({ icon: Icon, action, title, type }) => (
            <MenuItem key={type} onClick={action}>
              <ListItemIcon style={{ minWidth: 40 }}>{Icon && <Icon />}</ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  );
}

export default ShareMenu;
