import * as companiesActionsConstants from '../../constants/actions/organizationsActions';
import * as companiesService from '../../services/organizationsService';

export function getAllOrganization() {
    return dispatch => {
        dispatch({type: companiesActionsConstants.GET_FOLLOW_ORGANIZATIONS});
        companiesService.getAllOrganization().then(response => {
            dispatch({type: companiesActionsConstants.GET_FOLLOW_ORGANIZATIONS_SUCCESS, payload: response.data.payload.organizations});
        });
    };
}

export function getOrganizationsBySort(sortBy) {
    return dispatch => {
        dispatch({type: companiesActionsConstants.GET_FOLLOW_ORGANIZATIONS});
        companiesService.getOrganizationsBySort(sortBy).then(response => {
            dispatch({type: companiesActionsConstants.GET_FOLLOW_ORGANIZATIONS_SUCCESS, payload: response.data.payload.organizations});
        });
    };
}

