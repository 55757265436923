import React, { Component } from 'react';
import {connect} from 'react-redux';
import Actions from '../../RouteActions';

//components
import NetworkList from '../../components/NetworkList/NetworkList';

//actions
import * as networkActions from '../../globalActions/networkActions';

class YourNetworkInvite extends Component {
    componentWillUnmount() {
        this.props.getUserConnections();
    }

    render() {
        const { inviteUsers } = this.props;
        return (
            <NetworkList
                title="Invite to Boon"
                selectAllTitle={'Select all'}
                submitButton={() => 'Invite'}
                onSkipPress={() => Actions.jump('home')}
                onSubmitSelected={inviteUsers}
                filter_type="no_connections_at_all"
                invite
                initialLoading
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        network: state.connectionsReducer.network
    }
};

const mapDispatchToProps = dispatch => {
    return {
        inviteUsers: (userContacts, invertedSelection) => {
            dispatch(networkActions.inviteUsers(userContacts, invertedSelection)).then(() => {
                Actions.jump('home')
            });
        },
        getUserConnections: () => {
            dispatch(networkActions.getUserConnections());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(YourNetworkInvite);