import * as drawerActionsConstants from '../constants/actions/drawerActions';
import { $background } from '../generalStyles';
import { Platform, Dimensions } from 'react-native';

const initialState = {
    name: null,
    initialValues: {},
    position: 'left',
    currentVisibility: false,
    width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
    backgroundColor: '',
    showCountDown: false,
    minutesElapsed: -1,
    drawerProps: {}
};

const drawerReducer = (state = initialState, action) => {
    switch (action.type) {
        case drawerActionsConstants.OPEN_ORG_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'left',
                name: 'userOrganizationsDrawer',
                backgroundColor: '#22c66f',
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_BROWSE_FILTERS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'browseFiltersDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.CLOSE_DRAWER:
            return {
                ...state,
                currentVisibility: false,
                name: null,
                width: 0,
            };
        case drawerActionsConstants.OPEN_REFER_FORM_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'referFormDrawer',
                initialValues: action.payload,
                backgroundColor: '#fff',
                width: Math.min(385 , Math.round(Dimensions.get('window').width)),
            };
        case drawerActionsConstants.OPEN_SETTINGS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'settingsDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_CHANGE_PASSWORD_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'changePasswordDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_JOB_APPLY_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'jobApplyDrawer',
                initialValues: action.payload,
                backgroundColor: '#fff',
                width: Math.min(385 , Math.round(Dimensions.get('window').width)),
            };
        case drawerActionsConstants.OPEN_CONNECT_CONTACTS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'connectContactsDrawer',
                backgroundColor: '#E5E5E5',
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
                showCountDown: action.payload.showCountDown,
                minutesElapsed: action.payload.minutesElapsed,
            };
        case drawerActionsConstants.OPEN_NOTIFICATIONS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'notificationsDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_USER_PROFILE_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'userProfileDrawer',
                initialValues: action.payload,
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_DECLINE_JOB_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'declineJobDrawer',
                initialValues: action.payload,
                backgroundColor: '#fff',
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_IMPORT_CONTACT_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'importContactsDrawer',
                backgroundColor: '#fff',
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
            };
        case drawerActionsConstants.OPEN_CONNECT_LINKEDIN_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'connectLinkedInDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
                backgroundColor: '#fff',
            };
        case drawerActionsConstants.OPEN_UPLOAD_LINKEDIN_CONTACTS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'uploadLinkedInContactsDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
                backgroundColor: '#fff',
                showCountDown: false,
            };
        case drawerActionsConstants.OPEN_CSV_CONTACTS_DRAWER:
            return {
                ...state,
                currentVisibility: true,
                position: 'right',
                name: 'csvContactsDrawer',
                backgroundColor: $background,
                width: Platform.OS === 'web' ? 385 : Math.round(Dimensions.get('window').width),
                backgroundColor: '#fff',
                drawerProps: action.payload
            };
        default:
            return state;
    }
};

export default drawerReducer;
