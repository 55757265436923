import React from 'react';
import {
    TextInput,
    View,
    TouchableOpacity,
    Image,
    Animated,
    Platform
} from 'react-native';

import styles from './styles';

import Text from '../../components/Text/Text';

import visibilityIcon from '../../assets/svg/visibility-icon.svg';
import visibilityDiableIcon from '../../assets/svg/visibility-disable-icon.svg';
import { $greish } from '../../generalStyles';
import './styles.module.css';

const FormInput = (props) => {
    const {
        disabled,
        page,
        input,
        placeholder,
        noBorder,
        backgroundStyle,
        inputStyle,
        labelStyle,
        visibilityIconButtonStyle,
        wrapperStyle,
        secureTextEntry,
        maxLength,
        onSubmitEditing,
        inputVariant,
        ...inputProps
    } = props;
    const inputRef = React.useRef(null);
    const [visibility, setVisibility] = React.useState(false);
    const [animatedFontSize] = React.useState(new Animated.Value(14));
    const [animatedPosition] = React.useState(new Animated.Value(22));
    const [animatedColor] = React.useState(new Animated.Value(0));

    const inputFieldStyles = React.useMemo(() => {
        const temp = [styles.inputStyle, inputStyle];
        if (disabled) {
            temp.push(styles.disabledText);
        }
        if (noBorder) {
            temp.push({ border: 'none' });
        }
        return temp;
    }, [inputStyle, disabled, noBorder]);

    const labelStyles = React.useMemo(() => {
        const temp = [
            styles.labelStyle,
            labelStyle,
            {
                fontSize: animatedFontSize,
                top: page == 'refer' ? 18 : animatedPosition,
                color: animatedColor.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#c1c1c1', '#0FBC71']
                })
            }
        ];

        if (input.value) {
            if (page === 'refer') {
                temp.push({
                    display: 'none'
                });
            } else {
                temp.push(styles.labelStyleFocused);
            }
        } else {
            if (page === 'refer') {
                temp.push({
                    fontWeight: '400',
                    fontSize: 16,
                    color: '#757575',
                    paddingTop: 2,
                    lineHeight: Platform.OS === 'web' ? 7 : 14
                });
            }
        }
        return temp;
    }, [labelStyle, disabled, input.value, page]);

    const visibilityToggleButtonStyles = React.useMemo(() => {
        if(inputVariant === 'outlined') {
            return [styles.visibilityToggleButtonOutlined, visibilityIconButtonStyle];
        }
        else{
            return [styles.visibilityToggleButton, visibilityIconButtonStyle];
        }
    }, [visibilityIconButtonStyle, disabled]);

    const onFocus = (e) => {
        if (page != 'refer') {
            Animated.timing(animatedFontSize, {
                toValue: 10,
                duration: 200
            }).start();
            Animated.timing(animatedPosition, {
                toValue: 0,
                duration: 200
            }).start();
            Animated.timing(animatedColor, {
                toValue: 1,
                duration: 200
            }).start();
        }
        input.onFocus(e);
    };

    const onBlur = (e) => {
        if (!input.value) {
            Animated.timing(animatedFontSize, {
                toValue: 14,
                duration: 200
            }).start();
            Animated.timing(animatedPosition, {
                toValue: 22,
                duration: 200
            }).start();
            Animated.timing(animatedColor, {
                toValue: 0,
                duration: 200
            }).start();
        }
        input.onBlur(e);
    };

    const handleRef = (input) => {
        inputRef.current = input;
        if (inputProps.inputRef) {
            inputProps.inputRef(input);
        }
    };

    return (
        <View style={[styles.wrapperStyle, wrapperStyle]}>
            {placeholder && (
                <Animated.Text
                    style={labelStyles}
                    onClick={(e) => inputRef.current.focus()}
                >
                    {placeholder}
                </Animated.Text>
            )}
            <TextInput
                style={inputFieldStyles}
                {...inputProps}
                onChange={input.onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                autoCompleteType="off"
                value={input.value}
                secureTextEntry={secureTextEntry && !visibility}
                maxLength={maxLength}
                ref={handleRef}
                editable={!disabled}
                onSubmitEditing={onSubmitEditing}
            />
            <View style={[styles.backgroundView, backgroundStyle]} />
            {secureTextEntry && (
                <TouchableOpacity
                    style={visibilityToggleButtonStyles}
                    onPress={() => setVisibility(!visibility)}
                >
                    <Image
                        source={
                            visibility ? visibilityDiableIcon : visibilityIcon
                        }
                        style={styles.visibilityIcon}
                    />
                </TouchableOpacity>
            )}
            {maxLength && (
                <Text style={styles.maxLength}>
                    {input.value.length}/{maxLength}
                </Text>
            )}
        </View>
    );
};

export default FormInput;
