import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        position: 'relative'
    },
    logoWeb: {
        width: 94,
        height: 41,
        resizeMode: 'contain',
        position: 'absolute',
        left: 40,
        top: 30
    },
    logoMobile: {
        width: 125,
        height: 55,
        resizeMode: 'contain',
        marginTop: 27
    },
    imageBackground: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    imageBackgroundMobile: {
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
});
