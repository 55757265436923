import {applyMiddleware, createStore}   from 'redux';
import rootReducer                      from './reducers/index';
import thunk                            from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage                          from './wrappers/reduxStorage/reduxStorage'
import { composeWithDevTools } from 'redux-devtools-extension';
import { setStore } from './services/store'

const persistConfig = {
  key: 'root',
  blacklist: ['form', 'drawerReducer', 'refersReducer'],
  storage: storage,
}

let middlewares = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  let store     = createStore(persistedReducer,
    process.env.REACT_APP_ENVIRONMENT === ('production' || 'demo')
  ? applyMiddleware(...middlewares)
  : composeWithDevTools(applyMiddleware(...middlewares)))
  let persistor = persistStore(store)
  setStore(store);
  return { store, persistor }
}
