import React, { Component } from "react";
import { View, TouchableOpacity, Platform } from "react-native";

import { connect } from "react-redux";
import { reduxForm, Field, FieldArray, change, formValues } from "redux-form";
import { extend } from "lodash-es";
import Spinner from "react-native-loading-spinner-overlay";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

//components
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import FormInput from "../../components/FormInput/FormInput";
import OrganizationFormInput from "../../components/OrganizationFormInput/OrganizationFormInput";
import Links from "../../components/Links/Links";
import ImageUpload from "../../components/ImageUpload/ImageUpload";
import DrawerHeader from "../../components/Drawer/drawerHeader.js";
import SelectCompany from "../SelectCompany/SelectCompany";
import OrganizationStatus from "../../wrappers/organizationStatus/organizationStatus";
import LocationSelect from "../../components/LocationSelect/LocationSelect";
//images
import defaultAvatar from "../../assets/img/default_avatar.png";
import editCoverPic from "../../assets/img/edit_cover_pic.png";

//styles
import styles from "./styles";
import { $green, formLayout, hidden } from "../../generalStyles";

//actions
import * as actions from "./actions";
import * as uploadActions from "../../globalActions/uploadActions";
import * as locationActions from "../../globalActions/locationsActions";
import { closeDrawer } from "../../DrawerActions";

//utils
import { notification } from "../../helperFunctions";
import ImageResize from "./ImageResize.web";

const cropSizes = {
  avatar: {
    width: 128,
    height: 128,
    cropperCircleOverlay: true,
  },
  cover_image: {
    width: 750,
    height: 100,
  },
};

class EditProfile extends Component {
  constructor(props) {
    super(props);

    this.scroll = null;
    this.selectPhoto = this.selectPhoto.bind(this);
    this._scrollToInput = this._scrollToInput.bind(this);
    this.onSelectCompany = this.onSelectCompany.bind(this);
    this.state = {
      selectPhotoFor: "avatar",
      isImageLoading: false,
      countryChanged: false,
      selectingCompany: false,
      full_address: props.initialValues.full_address,
      selectedCountry: props.initialValues.country_iso_code,
      location: {
        full_address: props.initialValues.full_address
      },
      shopCropImageComp : false,
      uploadedImage : null,
      cropedAvatar: null,
      cropedCoverPhoto: null,
      cropedAvatarPreview: null,
      cropedCoverPhotoPreview: null,
      previousAvatar: null,
      previousPreview: null
    };
  }

  stopLoader = () => {
    this.setState({ isImageLoading: false });
  }

  handleImageCrop=(image,imagePreview, closeModal) => {
    const { selectPhotoFor } = this.state;
    if(selectPhotoFor === 'avatar'){
      if(closeModal)
      {
      this.setState({cropedAvatar : image})
      this.setState({previousAvatar : image})
      this.setState({previousPreview : imagePreview})

      }
      this.setState({cropedAvatarPreview : imagePreview})

    }
    else if(selectPhotoFor === 'cover_image')
    {
      if(closeModal)
      {
        this.setState({cropedCoverPhoto : image})
        this.setState({previousAvatar : image})
        this.setState({previousPreview : imagePreview})
      }
      this.setState({cropedCoverPhotoPreview : imagePreview})
    }
    if(!!closeModal) this.setState({shopCropImageComp : false})
  }

  selectPhoto=(image) => {
   this.setState({shopCropImageComp : true})
   this.setState({uploadedImage : image})
  }

  deSelectPhoto=(clearState,isImageCropped) => {
    this.setState({shopCropImageComp : false})
    if(!!clearState) return 
    const { selectPhotoFor, cropedAvatar ,cropedAvatarPreview,cropedCoverPhoto,cropedCoverPhotoPreview} = this.state;
    if (isImageCropped)
    {
      if(selectPhotoFor === 'avatar'){
        if ( (!!cropedAvatar && cropedAvatar.preview  != cropedAvatarPreview))
        {
            this.setState({
            uploadedImage : null,
            cropedAvatarPreview: this.state.previousPreview,
            cropedAvatar: this.state.previousAvatar
          }) 
        }
      else
        {
          this.setState({
          cropedAvatarPreview: null,
          })
        }
      }
      else if(selectPhotoFor === 'cover_image')
      {
        if ( (!!cropedCoverPhoto && cropedCoverPhoto.preview  != cropedCoverPhotoPreview))
          {
            this.setState({
              uploadedImage:null,
              cropedCoverPhotoPreview : this.state.previousPreview,
              cropedCoverPhoto : this.state.previousAvatar
            })
          }
        else
        { 
          this.setState({
            cropedCoverPhotoPreview: null,
          })
        }
      }
      
    }
    else
    {
      if(selectPhotoFor === 'avatar' && !!cropedAvatar){

      this.setState({
        uploadedImage : null,
        cropedAvatarPreview: this.state.previousPreview,
        cropedAvatar: this.state.previousAvatar
      })
    }
    else if(selectPhotoFor === 'cover_image' && !!cropedAvatar){
      this.setState({
        uploadedImage: null,
        cropedCoverPhotoPreview :this.state.previousPreview,
        cropedCoverPhoto : this.state.previousAvatar
      })
    }
  }
  }

  onSelectCompany(company) {
    const { setFieldValue } = this.props;
    setFieldValue("workplace_name", company);
    this.setState({ selectingCompany: false });
  }

  _scrollToInput(reactNode) {
    let extraHeight = 156;
    this.scroll.props.scrollToFocusedInput(reactNode, extraHeight);
  }

  handleChange = (values) => {
    this.props.change("city", values["locality"]);
    this.props.change("zip", values["postal_code"]);
    this.props.change("country_iso_code", values["country"]);
    this.props.change("full_address", values["full_address"]);
    this.props.change("state", values["administrative_area_level_1"]);

    this.setState({
      full_address: values["full_address"],
    });
  };

  onStartCompanySelect = () => {
    this.setState({ selectingCompany: true });
  };

  componentDidMount() {
    this.props.getCountries();
  }

  onHandleCountry = (countryObj) => {
    const { setFieldValue } = this.props;
    if (countryObj) {
      setFieldValue("country_iso_code", countryObj[0].value);
    }
  };

  handleLocationChange = (location) => {
    const { setFieldValue } = this.props;
    this.setState({location});
    setFieldValue("country_iso_code", location);
  }

  handleFormSubmit = ()=>{
    const {cropedAvatar,cropedCoverPhoto, selectPhotoFor,uploadedImage} = this.state;
    const {onImageUpload} = this.props;

    if(cropedAvatar){
      this.setState({isImageLoading : true});
      return onImageUpload(cropedAvatar, selectPhotoFor, "userForm", this.stopLoader,this.props.handleSubmit)
    }
    if(cropedCoverPhoto){
      this.setState({isImageLoading : true});
      return onImageUpload(cropedCoverPhoto, selectPhotoFor, "userForm", this.stopLoader,this.props.handleSubmit)
    }
    return this.props.handleSubmit()

  }

  renderEdit = () => {
    const { currentAvatar, currentCoverImage } = this.props;
    const { isImageLoading ,shopCropImageComp,uploadedImage,cropedAvatarPreview,cropedCoverPhotoPreview, selectPhotoFor} = this.state;
    let avatar = currentAvatar ? { uri: currentAvatar } : defaultAvatar;
    let coverImage = currentCoverImage ? { uri: currentCoverImage }: editCoverPic;
    if(cropedAvatarPreview){
      avatar = cropedAvatarPreview
    }
    if(cropedCoverPhotoPreview){
      coverImage = cropedCoverPhotoPreview
    }
    const web = Platform.OS === "web";
    return (
      <KeyboardAwareScrollView
        style={[{ flex: 1 }, !web && { marginTop: 30 }]}
        keyboardShouldPersistTaps="always"
        extraScrollHeight={20}
        innerRef={(ref) => {
          this.scroll = ref;
        }}
      >
        <DrawerHeader title="Edit Profile" />
       {shopCropImageComp &&
        <ImageResize uploadedImage={uploadedImage}
         handleImageCrop={this.handleImageCrop}
          deSelectPhoto={this.deSelectPhoto}
          selectPhotoFor={selectPhotoFor}
          />}
        <ImageUpload
          image={coverImage}
          imageStyle={styles.coverImage}
          shopCropImageComp={shopCropImageComp}
          imageIndicatorProps={{
            size: 80,
            borderWidth: 0,
            color: "rgba(150, 150, 150, 1)",
            unfilledColor: "rgba(200, 200, 200, 0.2)",
          }}
          width={cropSizes.cover_image.width}
          height={cropSizes.cover_image.height}
          cropperCircleOverlay={cropSizes.cover_image.cropperCircleOverlay}
          onSelectionStart={() => {
            this.setState({ selectPhotoFor: "cover_image" });
          }}
          afterSelectPhoto={this.selectPhoto}
        />

        <View style={styles.formWrapper}>
          <ImageUpload
            image={avatar}
            wrapperStyle={styles.avatarWrapper}
            imageStyle={styles.avatar}
            imageIndicatorProps={{
              size: 64,
              borderWidth: 4,
              color: $green,
              strokeCap: "round",
            }}
            width={cropSizes.avatar.width}
            height={cropSizes.avatar.height}
            cropperCircleOverlay={cropSizes.avatar.cropperCircleOverlay}
            onSelectionStart={() => {
              this.setState({ selectPhotoFor: "avatar" });
            }}
            afterSelectPhoto={this.selectPhoto}
          />

          <View style={formLayout.row}>
            <Field
              component={FormInput}
              name="bio"
              placeholder="Bio"
              multiline
              wrapperStyle={[
                formLayout.textAreaWrapper,
                formLayout.fullWidth,
                styles.message,
              ]}
              backgroundStyle={formLayout.textAreaBackground}
              inputStyle={formLayout.textArea}
              maxLength={280}
              // onContentSizeChange={e => this._scrollToInput(ReactNative.findNodeHandle(e.target))}
            />
          </View>

          <View style={formLayout.row}>
            <Field
              component={FormInput}
              name="first_name"
              maxLength={20}
              placeholder="First name"
              wrapperStyle={[formLayout.halfWidthLeft]}
            />
            <Field
              component={FormInput}
              name="last_name"
              maxLength={20}
              placeholder="Last name"
              wrapperStyle={[formLayout.halfWidthRight]}
            />
          </View>

          <View style={formLayout.row}>
            <TouchableOpacity
              style={formLayout.coverButton}
              onPress={this.onStartCompanySelect}
            />
            <Field
              component={OrganizationFormInput}
              name="workplace_name"
              placeholder="Organization"
              wrapperStyle={[formLayout.fullWidth]}
            />
          </View>

          <View style={formLayout.row}>
            <Field
              component={FormInput}
              name="company_position"
              placeholder="Position (optional)"
              wrapperStyle={[formLayout.fullWidth]}
            />
          </View>
          <View style={[formLayout.row, { zIndex: 2000 }]}>

            <LocationSelect width="100%" value={this.state.location} onChange={this.handleLocationChange}/>
          </View>
          <View style={formLayout.row}>
            <Field
              component={FormInput}
              name="email"
              placeholder="Email"
              wrapperStyle={[formLayout.fullWidth, { zIndex: -9 }]}
              disabled="true"
            />
          </View>

          <FieldArray component={Links} name="links_json" />
          <PrimaryButton
            style={styles.saveButtonText}
            onPress={this.handleFormSubmit}
          >
            Save
          </PrimaryButton>
        </View>
        <Spinner visible={isImageLoading} />
      </KeyboardAwareScrollView>
    );
  };

  render() {
    const { selectingCompany } = this.state;
    if (selectingCompany) {
      return (
        <SelectCompany
          selectFullObj={true}
          creatable={true}
          skipNavOnBackPress
          onSelectCompany={this.onSelectCompany}
        />
      );
    } else {
      return this.renderEdit();
    }
  }
}

const mapStateToProps = (state) => {
  return {
    formValues:
      state.form.userForm && state.form.userForm.values
        ? state.form.userForm.values
        : {},
    countries: state.locationsReducer.countries,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onImageUpload: (file, type, form, stopLoader,handleFormSubmit) => {
      return dispatch(uploadActions.uploadImage(file, type, form, stopLoader,handleFormSubmit));
    },
    setFieldValue: (field, value) => {
      dispatch(change("userForm", field, value));
    },
    onSubmit: (formValues) => {
      const formValuesCopy = extend({}, formValues);

      formValuesCopy.links_json = JSON.stringify(formValuesCopy.links_json);
      let countryIsoExists = formValues.country_iso_code.country_iso_code;
      let cityExists = formValues.country_iso_code.city;
      let stateExists = formValues.country_iso_code.state;
      let zipExists = formValues.country_iso_code.zip_code;
      if(formValues.country_iso_code && formValues.country_iso_code.full_address) {
        Object.keys(formValues.country_iso_code).map(attr=>{
          formValuesCopy[attr] = formValues.country_iso_code[attr];
        })
      }

      delete formValuesCopy.zip;
      if(!countryIsoExists){
        formValuesCopy.country_iso_code = null;
      }
      if(!cityExists){
        formValuesCopy.city = null;
      }
      if(!stateExists){
        formValuesCopy.state = null;
      }
      if(!zipExists){
        formValuesCopy.zip_code = null;
      }

      if (formValuesCopy.workplace_name) {
        formValuesCopy.workplace_name = formValues.workplace_name.name;
      }

      if (formValuesCopy.avatar) {
        formValuesCopy.avatar_id = formValuesCopy.avatar.id;
        delete formValuesCopy.avatar;
      }

      if (formValuesCopy.cover_image) {
        formValuesCopy.cover_image_id = formValuesCopy.cover_image.id;
        delete formValuesCopy.cover_image;
      }
      dispatch(actions.editCurrentUser(formValuesCopy));
    },
    getCountries: () => {
      return dispatch(locationActions.getCountries());
    },
  };
};

EditProfile = reduxForm({ form: "userForm" })(EditProfile);

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
