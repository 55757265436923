import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    wrappedStyle: {
        paddingTop: 30
    },
    jobTitle: {
        fontWeight: 'bold'
    },
    viewJobButton: {
        marginBottom: 5
    }
});