import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('window');
const isTabletScreen = width <= 768;
const isLaptopScreen =  width > 768 &&  width < 1025;

export default EStyleSheet.create({
  bodyWrapper: {
    backgroundColor: '#fff',
    flex: 1,
  },
  statusWrapper: {
    alignItems: 'center',
    padding: 8,
  },
  statusTitle: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  statusDescription: {
    color: '#fff',
    fontSize: 12,
    textAlign: 'center',
  },
  referWrapper: {
    marginBottom: 24,
    borderBottomColor: '#dadada',
    borderBottomWidth: 1,
  },
  referredByText: {
    color: '#343A4E',
    fontSize: 10,
    fontWeight: 'bold',
    paddingHorizontal: 16,
  },
  referralsList: {
    paddingBottom: 130,
  },
  messageBlock: {
    padding: 16,
  },
  messageBlockTitle: {
    color: '#18332F',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  messageTextWrapper: {
    flexDirection: 'row',
  },
  messageTextQuoteBlock: {
    height: '100%',
    width: 6,
    borderRadius: 3,
    backgroundColor: '#EBF0F3',
    marginRight: 8,
  },
  messageText: {
    color: '#a3adac',
    fontSize: 12,
    flex: 1,
  },
  buttonsWrapper: {
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    padding: 16,
  },
  applyButton: {
    marginBottom: 16,
    width: '100%',
  },
  appliedButton: {
    marginBottom: 16,
    backgroundColor: '#d1d8dc',
    width: '100%',
  },
  secondaryButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  declineButton: {
    borderWidth: 0,
  },
  secondaryButton: {
    width: '44%',
  },
  declineButtonText: {
    color: '#E8818D',
  },
  declineButtonTextArchived: {
    color: '#D1D8DC',
  },
  bottomButtons: {
    margin: 10,
  },
  opportunityDetailsContainer: {
    width: isTabletScreen ? '100%' : isLaptopScreen ? '85%' : '70%',
    padding: isTabletScreen ? null : isLaptopScreen ? '10px' : '20px',
    flex: 1,
  },
  strengthenWrapper: {
    backgroundColor: 'white',
    marginTop: 10,
    padding: 22,
    display: 'felx',
    flexDirection: 'column',
    fontFamily: 'Roboto-regular',
  },
  strengthenText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    paddingBottom: 8,
  },
  progressBar: {
    height: 8,
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#D3DAE6',
    borderRadius: 8,
  },
  strengthenButton: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 24,
  },
  percentValue: {
    color: '#0FBC71',
  },
  actionButtons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '32px',
    paddingHorizontal: 20,
    paddingBottom: '25px'
  },
  backgroundImage: {
    width: '100%',
    height: '240px',
    display: 'flex',
    justifyContent: 'end',
  },
  modalWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#E7E7E796',
  },
  modalChildWrapper: {
    backgroundColor: 'white',
    minWidth: 650,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalCloseButton: {
    position: 'absolute',
    borderWidth: 0,
    top: 18,
    right: 10,
    cursor: 'pointer',
    color: '#000000'
  },
  modalBodyWrapper: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  },
  modalBodyText: {
    fontFamily: 'Roboto-Regular',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    color: '#444444',
    marginTop: '25px',
  },
  modalHeader: {
    borderRadius: '4 4 0 0',
    height: 66,
    backgroundColor: '#F4F5F8',
    width: '100%',
    padding: 12
  },
  heading: {
    fontWeight: '700',
    fontSize: 20,
    color: '#444444',
    marginTop: 10
  },
  modalText: {
    alignSelf: 'flex-start',
    width: '100%',
    textAlign: 'center',
    color: '#444444',
  },
  cencelButton: {
    height: '37px',
    width: "49%"
  }
});
