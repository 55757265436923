import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';

const { width } = Dimensions.get('window');
const isTabletScreen = width <= 768;
const isLaptopScreen =  width > 768 &&  width < 1025;

export default EStyleSheet.create({
  strengthenWrapper: {
    backgroundColor: 'white',
    marginTop: 10,
    padding: 22,
    display: 'felx',
    flexDirection: 'column',
    fontFamily: 'Roboto-regular',
  },
  strengthenText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: 14,
    paddingBottom: 8,
  },
  progressBar: {
    height: 8,
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#D3DAE6',
    borderRadius: 8,
  },
  strengthenButton: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingTop: 24,
  },
  percentValue: {
    color: '#0FBC71',
  },
  secondaryButton: {
    width: '44%',
  },
});
