import React, {Component} from 'react';
import {FlatList, Image, View, Platform} from 'react-native';
//components
import FinishOnboardingBase from '../FinishOnboardingBase/FinishOnboardingBase';
import Text from '../Text/Text';
import Checkbox from '../Checkbox/Checkbox';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
//styles
import {listElement, flatListContainer} from '../../generalStyles';
import styles from './styles';
//images
import defaultUserAvatar from '../../assets/img/default_user_avatar.png';
import {connect} from 'react-redux';
import * as networkActions from '../../globalActions/networkActions';

class NetworkList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedConnections: [],
            invertedSelection: false,
        };

        this.toggleAllConnections = this.toggleAllConnections.bind(this);
        this.toggleConnection = this.toggleConnection.bind(this);
        this.renderItem = this.renderItem.bind(this);
    }

    componentDidMount() {
        const { initialLoading, getUserConnections } = this.props;
        if (initialLoading) {
            getUserConnections();
        }
    }

    toggleConnection(connection) {
        const { selectedConnections } = this.state;
        const newSelectedConnections = selectedConnections.slice();
        const idIndex = selectedConnections.indexOf(connection);

        if (idIndex === -1) {
            newSelectedConnections.push(connection);
        } else {
            newSelectedConnections.splice(idIndex, 1);

        }

        this.setState({
            selectedConnections: newSelectedConnections
        });
    }

    toggleAllConnections() {
        const { selectedConnections, invertedSelection } = this.state;
        if (selectedConnections.length && invertedSelection) {
            this.setState({
                selectedConnections: []
            })
        } else {
            this.setState({
                invertedSelection: !invertedSelection,
                selectedConnections: []
            });
        }
    }

    renderItem({item, index}) {
        const { network, invite, connect } = this.props;
        const { selectedConnections, invertedSelection } = this.state;

        const avatar = item.avatar ? {uri: item.avatar} : defaultUserAvatar;

        const bodyStyles = [listElement.elementBody];

        if (index === network.length - 1) {
            bodyStyles.push(listElement.last);
        }

        const checked = invertedSelection ? selectedConnections.indexOf(item) === -1 : selectedConnections.indexOf(item) !== -1;

        const showName = item.first_name || item.last_name;

        return (
            <View style={listElement.elementWrapper}>
                <View style={listElement.imageWrapper}>
                    <Image style={[listElement.image, listElement.imageRounded]} source={avatar} resizeMode="contain" />
                </View>
                <View style={bodyStyles}>
                    {invite && <View style={listElement.infoWrapper}>
                        {Boolean(showName) && <Text style={[listElement.description, listElement.bold]}>{item.first_name} {item.last_name}</Text>}
                        {Boolean(item.email) && <Text style={[listElement.description]}>{item.email}</Text>}
                        {Boolean(item.phone_number) && <Text style={listElement.description}>{item.phone_number}</Text>}
                    </View>}
                    {connect && <View style={listElement.infoWrapper}>
                        {Boolean(showName) && <Text style={[listElement.description, listElement.bold]}>{item.first_name} {item.last_name}</Text>}
                        {Boolean(item.organization_name) && <Text style={[listElement.description]}>{item.organization_name}</Text>}
                        {Boolean(item.company_position) && <Text style={listElement.description}>{item.company_position}</Text>}
                    </View>}

                    <Checkbox checked={checked} onPress={() => this.toggleConnection(item)}/>
                </View>
            </View>
        )
    }

    render() {
        const { onSkipPress, title, network, networkLoading, onSubmitSelected, selectAllTitle, getUserConnections, isLastPage, submitButton } = this.props;
        const { selectedConnections, invertedSelection } = this.state;

        return (
            <FinishOnboardingBase title={title} onSkipPress={onSkipPress}>
                <View style={styles.selectAllWrapper}>
                    <Text>{selectAllTitle}</Text>
                    <Checkbox checked={invertedSelection && !selectedConnections.length} onPress={() => this.toggleAllConnections()}/>
                </View>
                <FlatList
                    style={[styles.connectionsWrapper, Platform.OS === 'web' && flatListContainer]}
                    onRefresh={getUserConnections}
                    refreshing={networkLoading}
                    data={network}
                    extraData={this.state}
                    renderItem={this.renderItem}
                    keyExtractor={(item, index) => `${item.first_name}_${item.last_name}_${index}`}
                    onEndReached={() => {
                        if (!isLastPage && !networkLoading) {
                            getUserConnections({offset: network.length});
                        }
                    }}
                />
                <PrimaryButton style={styles.inviteButton} onPress={() => onSubmitSelected(selectedConnections.map(connection => connection.id), invertedSelection)}>{submitButton(selectedConnections.length)}</PrimaryButton>
            </FinishOnboardingBase>
        );
    }
}

const mapStateToProps = state => {
    return {
        network: state.connectionsReducer.network,
        networkLoading: state.connectionsReducer.networkLoading,
        isLastPage: state.connectionsReducer.isLastPage
    }
};

const mapDispatchToProps = (dispatch, {filter_type}) => {
    return {
        getUserConnections: params => {
            dispatch(networkActions.getUserConnections({...params, filter_type}));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NetworkList);