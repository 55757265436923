import * as referActionConstants from '../../constants/actions/referActions';
import * as referService from '../../services/refersService';
import { notification } from '../../helperFunctions';
import RouteActions from '../../RouteActions';

export function sendInvite(data) {
    return dispatch => {
        referService.sendInvite(data).then(response => {
            dispatch({ type: referActionConstants.SET_API_CALL_STATUS, payload: true })
            if (response.status == 200) {
                dispatch({ type: referActionConstants.SET_REFER_INVITE_STATUS, payload: true })
                RouteActions.magicLink({email: data.email, referSignup: true })
            }
        }).catch(() => {
            dispatch({ type: referActionConstants.SET_API_CALL_STATUS, payload: false })
            notification.ref.show({ message: 'Error Occurred during Referral Invite', isError: true });
        });
    };
}

export function validateReferrer(data) {
    return dispatch => {
        referService.validateUserExistance(data).then(response => {
            dispatch({ type: referActionConstants.SET_API_CALL_STATUS, payload: true })
            dispatch({ type: referActionConstants.SET_REFERRER_STATUS, payload: response.data.payload.user_exist });
        }).catch((e) => {
            dispatch({ type: referActionConstants.SET_API_CALL_STATUS, payload: false })
            notification.ref.show({ message: e.data.error, isError: true });
        })
    };
}
