import React, { Component } from 'react';
import { connect } from 'react-redux';
import { View, Dimensions, TouchableOpacity, Image, Keyboard, ScrollView, Platform } from 'react-native';
import { TabView, SceneMap } from 'react-native-tab-view';
import Actions from '../../RouteActions';
import history from '../../RouteHistory';
import newMemberAppUrl from '../../services/newMemberAppUrl';
import { withGetScreen } from 'react-native-getscreen';
//components
import DashboardBase from '../../components/DashboardBase/DashboardBase';
import Text from '../../components/Text/Text';
import UpperComponent from './components/UpperComponent/UpperComponent';
//images
import searchIcon from '../../assets/img/search_icon_white.png';
//styles
import styles from './styles';
import { dashboardButton } from '../../generalStyles';

import * as actions from './actions';
const initialLayout = {
  height: 0,
  width: Dimensions.get('window').width,
};
class Tracker extends Component {
  _renderScene = SceneMap({
    my: () => (this.props.initTabIndex === 1 ? <UpperComponent direction="my" /> : <View />),
    sent: () => (this.props.initTabIndex === 0 ? <UpperComponent direction="their" /> : <View />),
  });
  onOpen = () => {
    this.drawer && this.drawer.openDrawer();
  };
  constructor(props) {
    super(props);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.state = {
      index: props.initTabIndex ? props.initTabIndex : 0,
      routes: [
        { key: 'sent', title: 'Sent Jobs' },
        { key: 'my', title: 'My Jobs' },
      ],
    };
  }
  componentDidMount() {
    const path = history.location.pathname;
    const direction = path === '/tracker/sent' ? 'their' : 'my';

    this.getTrackerDetails(direction);
    this.props.getInterviewStages(this.props.currentSelectedOrg);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.initTabIndex !== this.props.initTabIndex) {
      this.handleIndexChange(newProps.initTabIndex);
    }
  }
  handleIndexChange = (index) => {
    Keyboard.dismiss();
    this.setState({ index });
  };
  getTrackerDetails = (direction) => {
    const { getJobsTracker } = this.props;
    getJobsTracker({ direction: direction, status: 'new' }, () =>
      getJobsTracker({ direction: direction, status: 'applied' }, () =>
        getJobsTracker({ direction: direction, status: 'interview' }, () =>
          getJobsTracker({ direction: direction, status: 'archived' })
        )
      )
    );
  };
  handleTabChange = (key) => {
    switch (key) {
      case 'my':
        this.getTrackerDetails('my');
        Actions.tracker();
        break;
      case 'sent':
        this.getTrackerDetails('their');
        Actions.sentJobs();
        break;
    }
  };
  renderHeader(props) {
    const {
      navigationState: { index, routes },
    } = props;
    const borderStyle = {
      borderBottomColor: '#dadada',
      borderBottomWidth: 1,
      width: '100%',
    };
    const web = Platform.OS === 'web';
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    return (
      <View style={{ flexGrow: 0 }}>
        <View style={styles.tabBar}>
          {routes.map((route, routeIndex) => {
            const tabButtonStyles = [styles.tabButtonStyle, tabScreen && styles.tabButtonMobileStyle];
            const tabActiveButtonStyle = [styles.tabButtonTextStyle];
            if (index === routeIndex) {
              tabButtonStyles.push(styles.tabButtonActive);
              tabActiveButtonStyle.push({ fontWeight: 'bold', color: '#9B9B9B' });
            }
            return (
              <TouchableOpacity onPress={() => this.handleTabChange(route.key)} key={route.key} style={tabButtonStyles}>
                <Text style={tabActiveButtonStyle} numberOfLines={1}>
                  {route.title}
                </Text>
              </TouchableOpacity>
            );
          })}
          <TouchableOpacity
            onPress={() => window.open(newMemberAppUrl, '_blank')}
            style={[styles.tabButtonStyle, tabScreen && styles.tabButtonMobileStyle]}
          >
            <Text style={[styles.tabButtonTextStyle]} numberOfLines={1}>
              Payments
            </Text>
          </TouchableOpacity>
        </View>
        <View style={borderStyle} />
      </View>
    );
  }

  render() {
    const web = Platform.OS === 'web';
    const tabScreen = !web || this.props.isMobile() || this.props.isTablet();
    return (
      <DashboardBase title="Tracker">
        <ScrollView style={[!tabScreen && { padding: 30 }]}>
          <TabView
            navigationState={this.state}
            renderScene={this._renderScene}
            renderTabBar={this.renderHeader}
            onIndexChange={this.handleIndexChange}
            initialLayout={initialLayout}
          />
        </ScrollView>
      </DashboardBase>
    );
  }
}
const getUnseenIds = (list) => {
  const ids = [];
  list &&
    list.forEach((newObj) => {
      newObj.referrals.forEach((referralObj) => {
        if (!referralObj.seen) {
          ids.push(referralObj.request_id);
        }
      });
    });

  return ids;
};

const mapStateToProps = (state) => {
  return {
    newIdsArray: [
      getUnseenIds(state.trackerReducer.newList),
      getUnseenIds(state.trackerReducer.appliedList),
      getUnseenIds(state.trackerReducer.interviewList),
      getUnseenIds(state.trackerReducer.archivedList),
    ],
    currentTrackerTab: state.trackerReducer.currentTrackerTab,
    currentSelectedOrg: state.authReducer.currentSelectedOrg.id,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentTrackerTab: (index) => {
      dispatch(actions.setCurrentTrackerTab(index));
    },
    getJobsTracker: (params, successCallback) => {
      dispatch(actions.getJobsTracker(params, successCallback));
    },
    getInterviewStages: (organizationId) => {
      dispatch(actions.getInterviewStages(organizationId));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withGetScreen(Tracker));
