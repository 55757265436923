import { StyleSheet } from 'react-native';

export default StyleSheet.create({
    phoneInput: {
        flex: 1,
        minWidth: 0,
        border: 'hidden',
        fontSize: 17,
        paddingBottom: 10,
        paddingLeft: 10
        // marginTop: 10
    },
    title: {
        fontSize: 25,
        fontWeight: 500,
        marginBottom: 55,
        color: '#0FBC71'
    },
    inputStyle: {
        fontSize: 30,
        paddingHorizontal: 0
    },
    wrapperStyle: {
        borderBottomWidth: 3
    },
    wrapperStyleMobile: {
        borderBottomWidth: 0
    },
    sendButton: {
        marginTop: 30
    },
    inputsWrapper: {
        paddingBottom: 60
    },
    inputStyle: {
        fontSize: 48,
        fontWeight: 'normal',
        textAlign: 'center',
        borderBottomWidth: 3,
        color: '#000',
        borderBottomColor: '#D1D8DC',
        width: '23%',
        paddingBottom: 10,
        height: 60
    },
    inputStyleMobile: {
        fontSize: 20,
        fontWeight: 'normal',
        textAlign: 'center',
        borderBottomWidth: 3,
        color: '#000',
        borderBottomColor: '#D1D8DC',
        width: '100%',
        paddingBottom: 10,
        height: 60
    },
    sendButton: {
        marginTop: 30
    },
    resendWrapper: {
        paddingVertical: 24,
        alignItems: 'center'
    },
    resendText: {
        fontSize: 14
    },
    actionImage: {
        height: 15,
        width: 15,
        color: '#AEAEAE'
    },
    toolTipText: {
        width: 250,
        backgroundColor: 'white',
        color: 'black',
        borderRadius: 6,
        borderColor: 'black',
        borderWidth: 1,
        borderStyle: 'solid',
        padding: 15,
        /* Position the toolTip */
        position: 'absolute',
        zIndex: 1,
        marginTop: -140
    },
    toolTipHeading: {
        fontWeight: 'bolder',
        fontSize: 20
    },
    toolTipBody: {
        fontWeight: 'bolder',
        fontSize: 14
    },
    setupLaterButton: {
        color: '#000000',
        opacity: 0.4,
        fontWeight: 500,
        textDecorationLine: 'underline'
    },
    labelStyle: {
        marginTop: 10,
        backgroundColor: '#FFFFFF'
    },
    toolTipMessage: {
        color: '#000000',
        opacity: 0.6,
        lineHeight: 24,
        fontSize: 16,
        fontWeight: 400,
        textAlign: 'center'
    },
    flexCenter: {
        padding: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexCenterNumber: {
        padding: 45,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    centerAlign: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    textPadding: {
        paddingTop: 15
    },
    phoneInputWrapper: {
        zIndex: 1
    },
    formBottomWrapper: {
        zIndex: -1,
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20
    },
    formBottomTextWhite: {
        color: '#FFFFFF',
        fontSize: 14,
        textAlign: 'center'
    },
    formBottomTextUnderline: {
        paddingTop: 8,
        textDecorationLine: 'underline'
    },
    addPaddingVertical: {
        paddingTop: 15,
        paddingBottom: 35
    }
});
