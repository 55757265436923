import EStyleSheet from 'react-native-extended-stylesheet';
import { $green } from '../../generalStyles';

export default EStyleSheet.create({
    coverImageWrapper: {
        width: '100%'
    },
    coverImage: {
        width: '100%',
        height: 160
    },
    formWrapper: {
        paddingHorizontal: 16,
        paddingBottom: 16
    },
    avatarWrapper: {
        marginTop: -32,
        alignSelf: 'flex-start'
    },
    avatar: {
        height: 64,
        width: 64,
        borderRadius: 32
    },
    message: {
        marginTop: 0
    },
    saveButtonText: {
        height: 40,
        fontSize: 16,
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: $green,
        marginVertical: 20,
    }
});
