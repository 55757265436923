import * as trackerActionsConstants from '../../constants/actions/trackerActions';
import * as trackerService from '../../services/trackerService';
import * as appActionsConstants from '../../constants/actions/appActions';

export function clearJobsTracker() {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.CLEAR_JOBS_TRACKER });
  };
}

export function setSeen(source, ids, list) {
  return (dispatch) => {
    return trackerService.setSeen(source, ids).then(() => {
      dispatch({ type: trackerActionsConstants.SET_SEEN, payload: list });
    });
  };
}

export function setCurrentTrackerTab(tabIndex) {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.SET_CURRENT_TRACKER_TAB, payload: tabIndex });
  };
}

export function getJobsTracker(params, successCallback = null) {
  const isPagination = Boolean(params && params.offset);
  return (dispatch) => {
    dispatch({
      type: trackerActionsConstants.GET_JOBS_TRACKER,
      payload: {
        status: params.status,
        isPagination,
      },
    });
    dispatch({ type: appActionsConstants.SET_SPINNER_VISIBLE });
    trackerService
      .getJobsTracker(params)
      .then((response) => {
        let jobs = [];
        jobs = response.data.payload.jobs;
        dispatch({
          type: trackerActionsConstants.GET_JOBS_TRACKER_SUCCESS,
          payload: {
            direction: params.direction,
            jobs: jobs,
            status: params.status,
            isPagination,
          },
        });
        dispatch({ type: appActionsConstants.SET_SPINNER_HIDDEN });
        if (successCallback) successCallback();
      })
      .catch(() => {
        dispatch({ type: trackerActionsConstants.GET_JOBS_TRACKER_ERROR, payload: { status: params.status } });
      });
  };
}

export function getNewReferralsCount() {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.GET_NEW_REFERS_COUNT });
    return trackerService
      .getJobsTracker({ direction: 'my', status: 'new' })
      .then((response) => {
        dispatch({
          type: trackerActionsConstants.GET_NEW_REFERS_COUNT_SUCCESS,
          payload: response.data.payload.jobs.length,
        });
      })
      .catch(() => {
        dispatch({ type: trackerActionsConstants.GET_NEW_REFERS_COUNT_ERROR });
      });
  };
}

export function setFilteredData(filteredData) {
  return (dispatch) => {
    dispatch({
      type: trackerActionsConstants.SET_FILTERED_TRACKER_DATA,
      payload: {
        appliedList: filteredData.appliedList,
        archivedList: filteredData.archivedList,
        interviewList: filteredData.interviewList,
        newList: filteredData.newList,
      },
    });
  };
}

export function getInterviewStages(organizationId) {
  return (dispatch) => {
    dispatch({ type: trackerActionsConstants.GET_INTERVIEW_STAGES });

    return trackerService
      .getInterviewStages(organizationId)
      .then((response) => {
        dispatch({
          type: trackerActionsConstants.GET_INTERVIEW_STAGES_SUCCESS,
          payload: response.data.payload.interview_stages,
        });
      })
      .catch(() => {
        dispatch({
          type: trackerActionsConstants.GET_INTERVIEW_STAGES_ERROR,
          payload: response.data.error,
        });
      });
  };
}
